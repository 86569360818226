import React, { Fragment, useEffect, useRef, useState } from 'react';

import { ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useDetectAdBlock } from 'adblock-detect-react';
import { Alert, Divider, Form, Input, Modal, notification } from 'antd';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import moment from 'moment';
import qs from 'query-string';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { FACEBOOK_ADS_UPGRADE, useFeatureFlag } from 'lib/configcat';
import { s } from 'lib/safe';
import { strings } from 'locales/strings';
import { useSaveCampaignOnProductAndProviderMutation } from 'stores/campaignsSlice';
import { getCurrency } from 'utils/currency';

import { FacebookAdPreview } from './FacebookAdPreview';
import { FacebookAudiencePreview } from './FacebookAudiencePreview';
import { FacebookBlockedAccount } from './FacebookBlockedAccount';
import { FacebookCTASelector } from './FacebookCTASelector';
import { FacebookEstimated } from './FacebookEstimated';
import { FacebookImageUpload } from './FacebookImageUpload';
import { FacebookNewAudienceButton } from './FacebookNewAudienceButton';
import { FacebookObjectiveSelector } from './FacebookObjectiveSelector';
import { FacebookPageSelector } from './FacebookPageSelector';
import { FacebookPerformanceGoalSelector } from './FacebookPerformanceGoalSelector';
import { FacebookPlacementsSelector } from './FacebookPlacementsSelector';
import { FacebookVideoPreview } from './FacebookVideoPreview';
import { FacebookVideoUpload } from './FacebookVideoUpload';
import { InstagramAccountsSelector } from './InstagramAccountsSelector';
import { trackedEvents } from '../../../../config/trackedEvents.config';
import { overwriteMerge, useAdsCampaign } from '../../../../hooks/useAdsCampaign';
import { generateDefaultCampaignName } from '../../../../lib/adsProvider/common';
import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { track } from '../../../../lib/analytics';
import { convToFacebookSubType, saveFacebookCreative } from '../../../../lib/facebookPlateformApi';
import { BoxShadow } from '../../../BoxShadow';
import { FormInputGroup } from '../../../common/antd/FormInputGroup';
import { CurrencyInput } from '../../../common/antd/inputs/CurrencyInput';
import { InputDateWithDisabledRange } from '../../../common/antd/inputs/InputDateWithDisabledRange';
import { RadioButtonTabs } from '../../../common/antd/RadioButtonTabs';
import { AutomaticPlacementsPlaceholder } from '../../../common/illustrations/AutomaticPlacementsPlaceholder';
import { MutedText } from '../../../common/MutedText';
import { CurrencyValue } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { AddANewButton } from '../common/AddANewButton';
import { AddDomainButton } from '../common/AddDomainButton';
import { AddPixelButton } from '../common/AddPixelButton';
import { ProductPreviewForNewCampaign } from '../common/ProductPreviewForNewCampaign';
import { Wizard } from '../common/Wizard';
import PerformanceBoosterDescription from '../Google/PerformanceBoosterDescription.jsx';
import { AdsProviderSelector } from '../NewCampaign/AdsProviderSelector';
import { DeeplinkToggle } from '../NewCampaign/DeeplinkToggle';
import { DomainSelector } from '../NewCampaign/DomainSelector';
import { TYPE_PRODUCT } from '../NewCampaign/NewProductModal';
import { PerformanceBoosterToggle } from '../NewCampaign/PerformanceBoosterToggle.jsx';
import { PixelSelector } from '../NewCampaign/PixelSelector';
import { ProductSelector } from '../NewCampaign/ProductSelector';

const { confirm } = Modal;
const Style = styled.div`
  min-width: 100%;
  min-height: 100%;
  padding: 1em;

  .ant-tabs-tabpane,
  .ant-tabs-tab-btn {
    outline: none !important;
  }

  .ant-tabs-left {
    margin-bottom: 25px;
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    margin-left: -1px;
    border-left: 1px solid #f0f0f0;
    align-items: center;
    display: flex;
    padding-left: 2em;
  }

  .ant-tabs-left .ant-tabs-tab-active {
    background-color: aliceblue;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: #595959;
  margin-right: 10px;
`;

const Paragraph = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 6px;
`;
const Value = styled.div`
  display: inline-block;
  max-width: 400px;
  font-weight: normal;
  font-size: 11px;
  color: #595959 !important;
  white-space: nowrap;
  wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;
  .ant-statistic-content {
    line-height: 0px;
    color: #595959 !important;
  }
`;
const initialState = {
  facebookCampaign: {
    ad: {
      adset: {
        targeting: {
          genders: [0],
          ageMin: 18,
          ageMax: 65,
        },
      },
    },
  },
};

function convertToCreativeType(campaign, form5, setCampaign, setCreativeType, photoOrVideo) {
  return (key) => {
    const c = deepmerge(campaign, form5.getFieldsValue(), {
      arrayMerge: overwriteMerge,
      clone: true,
    });

    switch (photoOrVideo) {
      case 'photo':
        switch (key) {
          case 'carousel':
            _.set(
              c,
              ['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'childAttachments'],
              [
                {
                  name: '',
                  description: '',
                  imageHash: '',
                  link: '',
                },
              ],
            );
            break;
          case 'collection':
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'adFormats'], ['AUTOMATIC_FORMAT']);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'images'], [{ hash: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'titles'], [{ text: '' }, { text: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'bodies'], [{ text: '' }, { text: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'callToActionTypes'], ['']);
            break;
        }
        break;
      case 'video':
        switch (key) {
          case 'carousel':
            _.set(
              c,
              ['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'childAttachments'],
              [
                {
                  name: '',
                  description: '',
                  imageHash: '',
                  link: '',
                },
              ],
            );
            break;
          case 'collection':
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'adFormats'], ['AUTOMATIC_FORMAT']);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'videos'], [{ videoId: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'linkUrls'], [{ displayUrl: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'images'], [{ hash: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'titles'], [{ text: '' }, { text: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'bodies'], [{ text: '' }, { text: '' }]);
            _.set(c, ['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'callToActionTypes'], ['']);
            break;
          default:
            break;
        }
        break;
    }

    setCampaign(c);
    form5.setFieldsValue(c);
    setCreativeType(key);
  };
}

export function FacebookNewAdsCampaignPage(props: { account: accountT, campaignId: string, productId: string }) {
  const [facebookAdsUpgradeEnabled] = useFeatureFlag(FACEBOOK_ADS_UPGRADE);
  const history = useHistory();
  const { currencyCode, currencySymbol } = getCurrency(props.account);
  const { campaign, setCampaign } = useAdsCampaign(props.campaignId, props.account.id, initialState, 'FACEBOOK');
  const productId = _.get(campaign, ['productId']);
  const [saveCampaignOnProductAndProvider] = useSaveCampaignOnProductAndProviderMutation();

  const [budgetMode, setBudgetMode] = useState('daily');
  const [loading, setLoading] = useState(false);
  const [photoOrVideo, setPhotoOrVideo] = useState('photo');
  const [creativeType, setCreativeType] = useState('single');
  const [placementType, setPlacementType] = useState('automatic');
  const [isFacebookPerformanceBoosterEnabled, setIsFacebookPerformanceBoosterEnabled] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  const wizardRef = useRef();
  const cPlacementIsManual =
    _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'targeting', 'facebookPositions', 'length']) > 0 ||
    _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'targeting', 'instagramPositions', 'length']) > 0 ||
    _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'targeting', 'publisherPlatforms', 'length']) > 0 ||
    _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'targeting', 'devicePlatforms', 'length']) > 0;
  const cType = _.get(campaign, ['facebookCampaign', 'ad', 'creative', 'type']);
  const cMedia = _.get(campaign, ['facebookCampaign', 'ad', 'creative', 'media']);
  const isDailyBudget = _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'dailyBudget']);
  const SPAConnected =
    props.account.productProviders[0].spa.connectedAt !== null &&
    props.account.productProviders[0].spa.errorAt === null;

  useEffect(() => {
    setBudgetMode(isDailyBudget ? 'daily' : 'lifetime');
  }, [isDailyBudget]);
  useEffect(() => {
    setPlacementType(cPlacementIsManual ? 'manual' : 'automatic');
  }, [cPlacementIsManual]);
  useEffect(() => {
    if (!!cMedia && cMedia !== photoOrVideo) {
      setPhotoOrVideo(cMedia);
    }
  }, [cMedia]);
  useEffect(() => {
    if (!!cType && cType !== creativeType) {
      setCreativeType(cType);
    }
  }, [cType]);
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === _.get(campaign, ['providerId']));
  const { messages } = useIntl();

  useEffect(() => {
    if (adsProvider) {
      setIsFacebookPerformanceBoosterEnabled(
        !!(adsProvider?.hasFacebookConversionApi && adsProvider?.facebookPixelId && SPAConnected),
      );
    }
  }, [adsProvider]);

  const [productForm] = Form.useForm();
  const [campaignForm] = Form.useForm();
  const [adSetForm] = Form.useForm();
  const [placementForm] = Form.useForm();
  const [adForm] = Form.useForm();
  const [reviewForm] = Form.useForm();

  const campaignRequirementsOk = campaign && campaign.providerId && campaign.productId;

  function RequirementsOk({ children }) {
    return <Fragment>{campaignRequirementsOk && children()}</Fragment>;
  }

  const stepChanged = (form) => async (values) => {
    if (!values) {
      return;
    }

    if (values.adsProvider) {
      values.providerId = values.adsProvider.id;
      values.providerType = values.adsProvider.providerType;
      delete values.adsProvider;
    }

    if (values.product) {
      values.productId = values.product.id;
      values.productExternalId = values.product.externalId;
      values.productUrl = values.product.productUrl;

      const name = generateDefaultCampaignName(values.product);

      _.set(values, ['name'], name);
      _.set(values, ['facebookCampaign', 'name'], name);
      _.set(values, ['facebookCampaign', 'ad', 'name'], name);
      _.set(values, ['facebookCampaign', 'ad', 'adset', 'name'], name);
    }

    if (values.name) {
      values.facebookCampaign.name = values.name;
    }

    const newCampaign = deepmerge(campaign, values, {
      arrayMerge: overwriteMerge,
      clone: true,
    });

    setCampaign(newCampaign);

    return newCampaign;
  };

  const saveCampaign = async (values) => {
    setLoading(true);
    const newValues = { ...values };

    try {
      newValues.start = newValues.facebookCampaign.ad.adset.start;
      newValues.end = newValues.facebookCampaign.ad.adset.end;
      newValues.budget =
        (_.get(newValues, ['facebookCampaign', 'ad', 'adset', 'dailyBudget']) ||
          _.get(newValues, ['facebookCampaign', 'ad', 'adset', 'lifetimeBudget'])) * 1000000000;
      newValues.facebookCampaign.name = newValues.name;
      const newCampaignData = (
        await saveCampaignOnProductAndProvider({
          accountId: props.account.id,
          providerId: values.product.providerId,
          productId: values.product.externalId,
          campaign: newValues,
        })
      ).data;

      setCampaign(newCampaignData.campaign);

      const redirectUrl = qs.stringifyUrl({
        url: `/${props.account.id}/adsprovider/new-facebook-campaign-success/${newCampaignData.campaign.id}`,
        query: {
          productProviderId: values.product.providerId,
          productExternalId: values.product.externalId,
        },
      });

      history.push(redirectUrl);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.response && e.response.status === 409) {
        confirm({
          okText: 'Ok',
          cancelButtonProps: { disabled: true },
          title: 'Campaign already exists',
          icon: <ExclamationCircleOutlined />,
          content: (
            <MutedText>
              Campaign {newValues.name} already exists! <br />
              Please change campaign name
            </MutedText>
          ),
          afterClose: () => setTimeout(() => wizardRef.current.forceStep(1)),
        });

        return;
      }

      const errorMessage = _.get(e, 'errorMessage');

      if (
        errorMessage &&
        errorMessage.includes(
          'Permission Error: Either the object you are trying to access is not visible to you or the action you are trying to take is restricted to certain account types.',
        )
      ) {
        confirm({
          okText: 'Ok',
          width: 600,
          cancelButtonProps: { disabled: true },
          title: 'Unable to launch your ad!',
          icon: <ExclamationCircleOutlined />,
          content: (
            <Fragment>
              <br />
              <Alert type="error" message="Your account is locked by Facebook."></Alert>
              <br />
              <MutedText>
                You can check your account{' '}
                <a target="_blank" href="https://business.facebook.com/adsmanager/manage/adsets" rel="noreferrer">
                  here
                </a>
                ,<br />
                or change ads provider on the first page.
              </MutedText>
              <br />
              <div>
                <FacebookBlockedAccount />
              </div>
            </Fragment>
          ),
          afterClose: () => setTimeout(() => wizardRef.current.forceStep(0)),
        });
      } else if (
        errorMessage &&
        errorMessage.includes(
          'Cannot change run status for the Creative in With Issues status. You cannot change the run status of Creative in With Issues status',
        )
      ) {
        notification.error({
          duration: 30,
          message: errorMessage.replace('Creative rejected by facebook please, '),
        });
      } else if (errorMessage) {
        notification.error({
          duration: 30,
          message: errorMessage.replace('Error during querying (status):', ''),
        });
      }

      throw e;
    }
  };
  const wizardSteps = [
    {
      title: 'Product',
      form: productForm,
      onChange: stepChanged(productForm),
      content: (
        <Form
          onValuesChange={stepChanged(productForm)}
          initialValues={campaign}
          requiredMark="optional"
          form={productForm}
          style={{ height: 'calc( 100% - 50px)' }}
          layout="vertical"
        >
          <AdsProviderSelector account={props.account} providerType="FACEBOOK" form={productForm} />
          <ProductSelector account={props.account} productId={props.productId} />
        </Form>
      ),
    },
    {
      title: 'Campaign settings',
      form: campaignForm,
      onChange: stepChanged(campaignForm),
      content: <RequirementsOk>{CAMPAIGN_SETTINGS_FORM}</RequirementsOk>,
    },
    {
      title: 'Ad Set',
      form: adSetForm,
      onChange: stepChanged(adSetForm),
      content: <RequirementsOk>{ADSET_FORM}</RequirementsOk>,
    },
    {
      title: 'Placement',
      form: placementForm,
      onChange: stepChanged(placementForm),
      content: <RequirementsOk>{PLACEMENT_FORM}</RequirementsOk>,
    },
    {
      title: 'Ad',
      form: adForm,
      extra: (
        <div style={{ width: 300 }}>
          <ProductPreviewForNewCampaign productId={productId} account={props.account} />
        </div>
      ),
      onChange: async (values) => {
        setLoading(true);
        values = await stepChanged(adForm)(values);
        _.set(values, ['facebookCampaign', 'ad', 'creative', 'type'], creativeType);
        _.set(values, ['facebookCampaign', 'ad', 'creative', 'media'], photoOrVideo);

        try {
          const strictTypedCampaign = await convToFacebookSubType(
            localStorage,
            axios,
            adsProvider,
            values.facebookCampaign,
            creativeType,
            photoOrVideo,
          );
          const cleanCampaign = {
            ...values,
            facebookCampaign: {
              objective: values.facebookCampaign.objective,
              ...strictTypedCampaign,
            },
          };

          let creative = _.get(strictTypedCampaign, ['ad', 'creative']);

          if (!creative.id) {
            creative.status = 'IN_PROCESS';
            creative = await saveFacebookCreative(
              localStorage,
              axios,
              adsProvider,
              creative,
              campaign.product.productUrl,
            );
          }

          _.set(cleanCampaign, ['facebookCampaign', 'ad', 'creative', 'id'], _.get(creative, 'id'));
          setCampaign(cleanCampaign);
          adForm.setFieldsValue(cleanCampaign);
          setTimeout(wizardRef.current.forceStep(5), 500);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          const errorMessage = _.get(e, 'errorMessage');

          if (
            errorMessage &&
            errorMessage.includes(
              'Permission Error: Either the object you are trying to access is not visible to you or the action you are trying to take is restricted to certain account types.',
            )
          ) {
            confirm({
              okText: 'Ok',
              width: 600,
              cancelButtonProps: { disabled: true },
              title: 'Unable to preview your ad!',
              icon: <ExclamationCircleOutlined />,
              content: (
                <Fragment>
                  <br />
                  <Alert type="error" message="Your account is locked by Facebook."></Alert>
                  <br />
                  <MutedText>
                    You can check your account{' '}
                    <a target="_blank" href="https://business.facebook.com/adsmanager/manage/adsets" rel="noreferrer">
                      here
                    </a>
                    ,<br />
                    or change ads provider on the first page.
                  </MutedText>
                  <br />
                  <div>
                    <FacebookBlockedAccount />
                  </div>
                </Fragment>
              ),
              afterClose: () => setTimeout(() => wizardRef.current.forceStep(0)),
            });

            return;
          }

          if (errorMessage) {
            notification.error({
              duration: 30,
              message: errorMessage.replace('Error during querying (status):', ''),
            });
          }

          throw e;
        }
      },
      content: <RequirementsOk>{AD_FORM}</RequirementsOk>,
    },
    {
      title: 'Review & Launch',
      form: reviewForm,
      disabled: () => !_.get(campaign, ['facebookCampaign', 'ad', 'creative', 'id']),
      onChange: () => {},
      content: <RequirementsOk>{REVIEW_LAUNCH_FORM}</RequirementsOk>,
    },
  ];

  function PLACEMENT_FORM() {
    return (
      <Form
        initialValues={campaign}
        requiredMark="optional"
        form={placementForm}
        style={{ height: 'calc( 100% - 50px)' }}
        layout="vertical"
      >
        <RadioButtonTabs
          label="Placements"
          onChange={(v) => {
            if (v === 'automatic') {
              const c = deepmerge(campaign, placementForm.getFieldsValue(), {
                arrayMerge: overwriteMerge,
                clone: true,
              });

              _.set(c, ['facebookCampaign', 'ad', 'adset', 'targeting', 'facebookPositions'], []);
              _.set(c, ['facebookCampaign', 'ad', 'adset', 'targeting', 'instagramPositions'], []);
              _.set(c, ['facebookCampaign', 'ad', 'adset', 'targeting', 'publisherPlatforms'], []);
              _.set(c, ['facebookCampaign', 'ad', 'adset', 'targeting', 'devicePlatforms'], []);
              setCampaign(c);
              placementForm.setFieldsValue(c);
            }

            setPlacementType(v);
          }}
          value={placementType}
          defaultValue={placementType}
          options={[
            {
              value: 'automatic',
              label: 'Automatic Placements (Recommended)',
              children: <AutomaticPlacementsPlaceholder />,
            },
            {
              value: 'manual',
              label: 'Manual Placements',
              children: (
                <Fragment>
                  <MutedText>
                    Manually choose the places to show your ad. The more placements you select, the more opportunities
                    you'll have to reach your target audience and achieve your business goals.
                  </MutedText>
                  <Form.Item required name={['facebookCampaign', 'ad', 'adset', 'targeting']} label="Placements">
                    <FacebookPlacementsSelector account={props.account}></FacebookPlacementsSelector>
                  </Form.Item>
                </Fragment>
              ),
            },
          ]}
        />
      </Form>
    );
  }

  function AD_FORM() {
    return (
      <div style={{ width: 800 }}>
        <Form
          initialValues={campaign}
          requiredMark="optional"
          form={adForm}
          style={{ height: 'calc( 100% - 50px)' }}
          layout="vertical"
        >
          <div style={{ width: '400px' }}>
            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'name']}
              label="Ad Name"
              rules={[{ required: true, type: 'string' }]}
            >
              <Input size="large" placeholder="Batteries lovers"></Input>
            </Form.Item>
            <Form.Item hidden required name={['facebookCampaign', 'ad', 'creative', 'id']}>
              <Input size="large"></Input>
            </Form.Item>
            <Form.Item required name="instagramAccount" label="Instagram account">
              <InstagramAccountsSelector
                adsProvider={adsProvider}
                facebookPageId={_.get(campaign, 'facebookCampaign.ad.creative.objectStorySpec.pageId')}
              />
            </Form.Item>
            {/* <editor-fold desc="Photo>*/}
            <div>
              <RadioButtonTabs
                label="Ads Design"
                value={photoOrVideo}
                onChange={setPhotoOrVideo}
                options={[
                  {
                    value: 'photo',
                    label: 'Photo',
                    children: (
                      <Fragment>
                        <RadioButtonTabs
                          topStyle={{ minWidth: 800 }}
                          label="Ads Type"
                          value={creativeType}
                          onChange={convertToCreativeType(campaign, adForm, setCampaign, setCreativeType, photoOrVideo)}
                          options={[
                            {
                              value: 'single',
                              label: 'Simple',
                              children: SINGLE_PHOTO_FORM(),
                            },
                            {
                              value: 'collection',
                              label: 'Collection',
                              children: COLLECTION_PHOTO_FORM(),
                            },
                            {
                              value: 'carousel',
                              label: 'Carousel',
                              children: CAROUSEL_PHOTO_FORM(),
                            },
                          ]}
                        />
                      </Fragment>
                    ),
                  },
                  {
                    value: 'video',
                    label: 'Video',
                    children: (
                      <div>
                        <RadioButtonTabs
                          topStyle={{ minWidth: 800 }}
                          label="Ads Type"
                          value={creativeType}
                          onChange={convertToCreativeType(campaign, adForm, setCampaign, setCreativeType, photoOrVideo)}
                          options={[
                            {
                              value: 'single',
                              label: 'Simple',
                              children: SINGLE_VIDEO_FORM(),
                            },
                            {
                              value: 'collection',
                              label: 'Collection',
                              children: COLLECTION_VIDEO_FORM(),
                            },
                            {
                              value: 'carousel',
                              label: 'Carousel',
                              children: CAROUSEL_VIDEO_FORM(),
                            },
                          ]}
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </Form>
      </div>
    );
  }

  function ADSET_FORM() {
    const adSetFormCampaign = {};

    _.set(
      adSetFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'name'],
      _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'name']),
    );
    _.set(
      adSetFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'targeting'],
      _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'targeting']),
    );
    _.set(
      adSetFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'optimizationGoal'],
      !(adsProvider?.hasFacebookConversionApi || adsProvider?.facebookPixelId)
        ? 'LINK_CLICKS'
        : _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'optimizationGoal']),
    );

    return (
      <Form
        initialValues={adSetFormCampaign}
        requiredMark="optional"
        form={adSetForm}
        onValuesChange={(values) => {
          console.log('Ad Set form', values);
        }}
        style={{ height: 'calc( 100% - 50px)' }}
        layout="vertical"
      >
        <div
          style={{
            width: 800,
            display: 'flex',
            gap: '2em',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Form.Item
              required
              style={{ width: 289 }}
              name={['facebookCampaign', 'ad', 'adset', 'name']}
              label="Ads Set Name"
              rules={[{ required: true, type: 'string' }]}
            >
              <Input size="large" placeholder="[Batterie acquisition] - Summer 2021"></Input>
            </Form.Item>

            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'adset', 'optimizationGoal']}
              label={messages.facebook.performanceGoals.title}
            >
              <FacebookPerformanceGoalSelector
                defaultGoal={!(adsProvider?.hasFacebookConversionApi || adsProvider?.facebookPixelId)}
              />
            </Form.Item>

            <Form.Item
              required
              style={{ width: 350 }}
              name={['facebookCampaign', 'ad', 'adset', 'targeting']}
              label="Audience"
              rules={[
                {
                  validator: (rules, value) =>
                    _.get(value, 'geoLocations.length') > 0
                      ? Promise.resolve()
                      : Promise.reject(new Error('Incomplete audience, should select at least a location')),
                },
              ]}
            >
              <FacebookNewAudienceButton
                onChange={(values) => {
                  stepChanged(adSetForm)(values);
                }}
                account={props.account}
                adsProvider={adsProvider}
              />
            </Form.Item>
          </div>
          <FacebookEstimated adsProvider={adsProvider} account={props.account} targeting={campaign} />
        </div>
      </Form>
    );
  }

  function REVIEW_LAUNCH_FORM() {
    return (
      <Form form={reviewForm}>
        <div>
          <div>
            <div
              style={{
                display: 'flex',
                gap: '2em',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {s(campaign).product.type === TYPE_PRODUCT && (
                  <>
                    <h3>Amazon Product</h3>
                    <Paragraph>
                      <Title>Product Title:</Title>
                      <Value>{s(s(campaign).product).name}</Value>
                    </Paragraph>
                    <Paragraph>
                      <Title>ASIN:</Title>
                      <Value>{s(s(campaign).product).externalId}</Value>
                    </Paragraph>
                  </>
                )}
                <br />
                <h3>Campaign Settings</h3>
                <Paragraph>
                  <Title>Campaign name:</Title>
                  <Value>{s(campaign).name}</Value>
                </Paragraph>
                <Paragraph>
                  <Title>Daily Budget:</Title>
                  <Value>
                    <CurrencyValue
                      amount={_.get(campaign, ['facebookCampaign', 'ad', 'adset', 'dailyBudget'], 0) / 100}
                      currency={currencySymbol}
                      suffix="/day"
                    />
                  </Value>
                </Paragraph>
                <Paragraph>
                  <Title>Lifetime Budget:</Title>
                  <Value>
                    <CurrencyValue
                      amount={_.get(campaign, ['facebookCampaign', 'ad', 'adset', 'lifetimeBudget'], 0) / 100}
                      currency={currencySymbol}
                    />
                  </Value>
                </Paragraph>
              </div>
              <div>
                <h3>Audience</h3>
                <FacebookAudiencePreview audience={_.get(campaign, ['facebookCampaign', 'ad', 'adset', 'targeting'])} />
              </div>
            </div>
            <Divider orientation="horizontal" />
            {!loading && (
              <FacebookAdPreview
                account={props.account}
                adsProvider={adsProvider}
                creativeId={_.get(campaign, ['facebookCampaign', 'ad', 'creative', 'id'])}
              />
            )}
          </div>
        </div>
      </Form>
    );
  }

  function CAMPAIGN_SETTINGS_FORM() {
    const campaignFormCampaign = {};

    _.set(
      campaignFormCampaign,
      ['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'pageId'],
      _.get(campaign, ['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'pageId']),
    );
    _.set(
      campaignFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'dailyBudget'],
      _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'dailyBudget']),
    );
    _.set(
      campaignFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'startTime'],
      _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'startTime']),
    );
    _.set(
      campaignFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'endTime'],
      _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'endTime']),
    );
    _.set(
      campaignFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'lifetimeBudget'],
      _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'lifetimeBudget']),
    );
    _.set(
      campaignFormCampaign,
      ['facebookCampaign', 'ad', 'adset', 'startTime'],
      _.get(campaign, ['facebookCampaign', 'ad', 'adset', 'startTime']),
    );
    _.set(campaignFormCampaign, ['pixelsIds'], _.get(campaign, ['pixelsIds']));
    _.set(campaignFormCampaign, ['domain'], _.get(campaign, ['domain']));
    _.set(campaignFormCampaign, ['name'], _.get(campaign, ['name']));
    _.set(campaignFormCampaign, ['useDeepLinks'], _.get(campaign, ['useDeepLinks']));
    _.set(campaignFormCampaign, ['performanceBooster'], isFacebookPerformanceBoosterEnabled);

    if (facebookAdsUpgradeEnabled) {
      _.set(
        campaignFormCampaign,
        ['facebookCampaign', 'objective'],
        _.get(campaign, ['facebookCampaign', 'objective']),
      );
    }

    return (
      <Form
        initialValues={campaignFormCampaign}
        requiredMark="optional"
        form={campaignForm}
        onValuesChange={(v) => {
          if (v.name) {
            const c = { ...campaign };

            _.set(c, ['facebookCampaign', 'name'], v.name);
            _.set(c, ['facebookCampaign', 'ad', 'name'], v.name);
            _.set(c, ['facebookCampaign', 'ad', 'adset', 'name'], v.name);
            adForm.setFieldsValue(c);
            adSetForm.setFieldsValue(c);
          }
        }}
        style={{ height: 'calc( 100% - 50px)' }}
        layout="vertical"
      >
        <Form.Item required name="name" label="Campaign Name" rules={[{ required: true, type: 'string' }]}>
          <Input size="large" placeholder="[Batterie acquisition] - Summer 2021"></Input>
        </Form.Item>

        {facebookAdsUpgradeEnabled && (
          <Form.Item name={['facebookCampaign', 'objective']} label={messages.facebook.campaignObjective.title}>
            <FacebookObjectiveSelector />
          </Form.Item>
        )}
        <Form.Item
          required
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'pageId']}
          label="Facebook Page"
          rules={[{ required: true, type: 'string' }]}
        >
          <FacebookPageSelector account={props.account} adsProviderId={campaign.providerId} />
        </Form.Item>
        <FormInputGroup>
          <RadioButtonTabs
            label="Budget Type"
            value={budgetMode}
            defaultValue={budgetMode}
            onChange={(v) => {
              const c = deepmerge(campaign, campaignForm.getFieldsValue(), {
                arrayMerge: overwriteMerge,
                clone: true,
              });

              if (v === 'daily') {
                _.set(c, ['facebookCampaign', 'ad', 'adset', 'lifetimeBudget'], 0);
              } else {
                _.set(c, ['facebookCampaign', 'ad', 'adset', 'dailyBudget'], 0);
              }

              setCampaign(c);
              campaignForm.setFieldsValue(c);
              setBudgetMode(v);
            }}
            options={[
              {
                value: 'daily',
                label: 'Daily Budget',
                children: (
                  <Fragment>
                    <Form.Item
                      required
                      style={{ width: 500 }}
                      name={['facebookCampaign', 'ad', 'adset', 'dailyBudget']}
                      label="Budget"
                      rules={[
                        {
                          required: budgetMode === 'daily',
                          type: 'number',
                          min: 5,
                        },
                      ]}
                    >
                      <CurrencyInput currencyCode={currencyCode} unit={100}></CurrencyInput>
                    </Form.Item>
                    <FormInputGroup>
                      <Form.Item
                        name={['facebookCampaign', 'ad', 'adset', 'startTime']}
                        required
                        label="Start date"
                        rules={[
                          {
                            required: budgetMode === 'daily',
                            type: 'date',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || moment(value).diff(moment(), 'days') >= 0) {
                                return Promise.resolve();
                              }

                              return Promise.reject(new Error('Start date must be in the future'));
                            },
                          }),
                        ]}
                      >
                        <InputDateWithDisabledRange startDate={new Date()} endDate={new Date(8640000000000000)} />
                      </Form.Item>
                      <Form.Item name={['facebookCampaign', 'ad', 'adset', 'endTime']} label="End date">
                        <InputDateWithDisabledRange startDate={new Date()} endDate={new Date(8640000000000000)} />
                      </Form.Item>
                    </FormInputGroup>
                  </Fragment>
                ),
              },
              {
                value: 'lifetime',
                label: 'Lifetime Budget',
                children: (
                  <Fragment>
                    <Form.Item
                      required
                      style={{ width: 500 }}
                      name={['facebookCampaign', 'ad', 'adset', 'lifetimeBudget']}
                      label="Budget"
                      rules={[
                        {
                          required: budgetMode === 'lifetime',
                          type: 'number',
                          min: 5,
                        },
                      ]}
                    >
                      <CurrencyInput currencyCode={currencyCode} unit={100}></CurrencyInput>
                    </Form.Item>
                    <FormInputGroup>
                      <Form.Item
                        name={['facebookCampaign', 'ad', 'adset', 'startTime']}
                        required
                        label="Start date"
                        rules={[
                          {
                            required: budgetMode === 'lifetime',
                            type: 'date',
                          },
                        ]}
                      >
                        <InputDateWithDisabledRange startDate={new Date()} endDate={new Date(8640000000000000)} />
                      </Form.Item>
                      <Form.Item
                        dependencies={['facebookCampaign', 'ad', 'adset', 'startTime']}
                        required
                        name={['facebookCampaign', 'ad', 'adset', 'endTime']}
                        label="End date"
                        rules={[
                          { required: budgetMode === 'lifetime', type: 'date' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                !getFieldValue(['facebookCampaign', 'ad', 'adset', 'startTime']) ||
                                moment(value).diff(moment(getFieldValue(['facebookCampaign', 'start'])), 'days') > 14
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(new Error('End date must be minimum 14 days after start date'));
                            },
                          }),
                        ]}
                      >
                        <InputDateWithDisabledRange startDate={new Date()} endDate={new Date(8640000000000000)} />
                      </Form.Item>
                    </FormInputGroup>
                  </Fragment>
                ),
              },
            ]}
          />
        </FormInputGroup>
        <PixelSelector account={props.account}></PixelSelector>
        <AddPixelButton accountId={props.account.id} />
        <DomainSelector account={props.account} />
        <AddDomainButton accountId={props.account.id} />
        <DeeplinkToggle account={props.account} />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: 500 }}>
            <PerformanceBoosterToggle
              account={props.account}
              spaConnected={SPAConnected}
              defaultChecked={isFacebookPerformanceBoosterEnabled}
              disabled={!(adsProvider?.hasFacebookConversionApi || adsProvider?.facebookPixelId)}
            />
          </div>
          {!SPAConnected && <PerformanceBoosterDescription />}
          {!(adsProvider?.hasFacebookConversionApi || adsProvider?.facebookPixelId) && (
            <div>
              Please make sure your Ads Provider has a Facebook Conversions API Token associated with it by going{' '}
              <a href={`/${props.account.id}/adsprovider/settings/accounts`} target="_blank" rel="noreferrer">
                here
              </a>
            </div>
          )}
        </div>
      </Form>
    );
  }

  function SINGLE_PHOTO_FORM() {
    return (
      <div>
        <BoxShadow
          style={{
            marginLeft: 2,
            marginRight: 2,
            width: '400px',
          }}
        >
          <div style={{ width: '100%' }}>
            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'imageHash']}
              label={
                <label>
                  Photo <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                </label>
              }
              rules={[
                {
                  required: creativeType === 'single' && photoOrVideo !== 'video',
                  type: 'string',
                },
              ]}
            >
              <FacebookImageUpload adsProvider={adsProvider} account={props.account} />
            </Form.Item>
            <Form.Item
              required
              style={{ flex: '1 1 400px', marginBottom: 5 }}
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'message']}
              rules={[
                {
                  required: creativeType === 'single',
                  type: 'string',
                  min: 1,
                  max: 9999,
                },
              ]}
              label={
                <label>
                  Primary text <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                  <div style={{ fontSize: '9px', color: 'var(--text-muted)' }}>
                    Facebook recommends up to 125 characters
                  </div>
                </label>
              }
            >
              <Input.TextArea rows={5} placeholder="Tell people what your ad is about. "></Input.TextArea>
            </Form.Item>
            <Form.Item
              required
              style={{ flex: '1 1 400px', marginBottom: 5 }}
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'name']}
              rules={[
                {
                  required: creativeType === 'single',
                  type: 'string',
                  min: 1,
                  max: 99,
                },
              ]}
              label={
                <label>
                  Headline <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                </label>
              }
            >
              <Input size="large" placeholder="Short headline"></Input>
            </Form.Item>
            <Form.Item
              style={{ flex: '1 1 400px', marginBottom: 5 }}
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'description']}
              rules={[
                {
                  required: false,
                  type: 'string',
                  min: 1,
                  max: 300,
                },
              ]}
              label="Descriptions"
            >
              <Input.TextArea rows={5}></Input.TextArea>
            </Form.Item>
            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'callToAction', 'type']}
              label={
                <label>
                  Call to action <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                </label>
              }
              rules={[
                {
                  required: creativeType === 'single',
                  type: 'string',
                },
              ]}
            >
              <FacebookCTASelector />
            </Form.Item>
          </div>
        </BoxShadow>
        <Form.Item
          style={{ width: 400 }}
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'caption']}
          label="Display link"
          rules={[
            {
              required: false,
              type: 'url',
            },
          ]}
        >
          <Input size="large" placeholder="Enter the link you want to show on your ad"></Input>
        </Form.Item>
      </div>
    );
  }

  function COLLECTION_PHOTO_FORM() {
    return (
      <div style={{ width: 400 }}>
        <BoxShadow
          style={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <div>
            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'images']}
              label={
                <label>
                  Photo <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                </label>
              }
              rules={[
                {
                  required: creativeType === 'collection',
                  type: 'array',
                  min: 1,
                  max: 1,
                },
              ]}
            >
              {(fields, { add, remove, move }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Form.Item
                      required
                      name={[name, 'hash']}
                      label=""
                      rules={[
                        {
                          required: creativeType === 'collection' && photoOrVideo !== 'video',
                          type: 'string',
                        },
                      ]}
                    >
                      <FacebookImageUpload adsProvider={adsProvider} account={props.account} />
                    </Form.Item>
                  ))}
                </div>
              )}
            </Form.List>

            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'bodies']}
              label=""
              rules={[
                {
                  required: creativeType === 'collection',
                  type: 'array',
                  min: 2,
                  max: 3,
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={`${name}_${key}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'stretch',
                        gap: '1em',
                        height: '100%',
                        minWidth: '300px',
                      }}
                    >
                      <Form.Item
                        required
                        style={{
                          flex: '1 1 400px',
                          marginBottom: 5,
                        }}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: creativeType === 'collection',
                            type: 'string',
                            min: 1,
                            max: 9999,
                          },
                        ]}
                        label={
                          <label>
                            Primary text <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                            <div style={{ fontSize: '9px', color: 'var(--text-muted)' }}>
                              Facebook recommends up to 125 characters
                            </div>
                          </label>
                        }
                        {...restField}
                      >
                        <Input.TextArea
                          rows={5}
                          size="large"
                          placeholder="Tell people what your ad is about. "
                        ></Input.TextArea>
                      </Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <AddANewButton onClick={() => add()}>+ Add a new primary text</AddANewButton>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'titles']}
              label=""
              rules={[
                {
                  required: creativeType === 'collection',
                  type: 'array',
                  min: 2,
                  max: 3,
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={`${name}_${key}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '1em',
                      }}
                    >
                      <Form.Item
                        required
                        style={{
                          flex: '1 1 400px',
                          marginBottom: 5,
                        }}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: creativeType === 'collection',
                            type: 'string',
                            min: 1,
                            max: 99,
                          },
                        ]}
                        label={
                          <label>
                            Headline <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                          </label>
                        }
                        {...restField}
                      >
                        <Input size="large" placeholder="Short headline"></Input>
                      </Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <AddANewButton onClick={() => add()}>+ Add a new headline</AddANewButton>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'descriptions']}
              label=""
              rules={[{ required: false, type: 'array', min: 0, max: 3 }]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={`${name}_${key}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '1em',
                      }}
                    >
                      <Form.Item
                        style={{ flex: '1 1 400px', marginBottom: 5 }}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: false,
                            type: 'string',
                            min: 1,
                            max: 300,
                          },
                        ]}
                        label="Descriptions"
                        {...restField}
                      >
                        <Input.TextArea rows={5} size="large"></Input.TextArea>
                      </Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <AddANewButton onClick={() => add()}>+ Add a new description</AddANewButton>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'callToActionTypes']}
              label=""
              rules={[
                {
                  required: creativeType === 'collection',
                  type: 'array',
                  min: 0,
                  max: 1,
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Form.Item
                      required
                      name={name}
                      label={
                        <label>
                          Call to action <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                        </label>
                      }
                      rules={[
                        {
                          required: creativeType === 'collection',
                          type: 'string',
                        },
                      ]}
                    >
                      <FacebookCTASelector />
                    </Form.Item>
                  ))}
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
          </div>
        </BoxShadow>
        <Form.List
          name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'linkUrls']}
          label=""
          rules={[{ required: false, type: 'array', min: 0, max: 3 }]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div style={{ width: '100%' }}>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div
                  key={`${name}_${key}`}
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    gap: '1em',
                  }}
                >
                  <Form.Item
                    style={{ width: 400 }}
                    name={[name, 'displayUrl']}
                    label="Display link"
                    rules={[
                      {
                        required: false,
                        type: 'url',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter the link you want to show on your ad"></Input>
                  </Form.Item>
                </div>
              ))}
            </div>
          )}
        </Form.List>
      </div>
    );
  }

  function CAROUSEL_PHOTO_FORM() {
    return (
      <div style={{ width: '800px' }}>
        <Form.Item
          required
          style={{ flex: '1 1 400px', marginBottom: 5 }}
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'message']}
          rules={[
            {
              required: creativeType === 'single',
              type: 'string',
              min: 1,
              max: 9999,
            },
          ]}
          label={
            <label>
              Primary text <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
              <div style={{ fontSize: '9px', color: 'var(--text-muted)' }}>
                Facebook recommends up to 125 characters
              </div>
            </label>
          }
        >
          <Input.TextArea rows={5} size="large" placeholder="Tell people what your ad is about. "></Input.TextArea>
        </Form.Item>
        <Form.List
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'childAttachments']}
          label=""
          rules={[
            {
              required: creativeType === 'carousel',
              type: 'array',
              min: 2,
              max: 10,
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div
              style={{
                display: 'flex',
                gap: '1em',
                maxWidth: '100%',
                overflow: 'auto',
                alignItems: 'center',
              }}
            >
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <BoxShadow
                  key={`${name}_${key}`}
                  style={{
                    marginLeft: 2,
                    marginRight: 2,
                    position: 'relative',
                    minWidth: 300,
                  }}
                  title={`Carousel ${key + 1}`}
                >
                  <MinusCircleOutlined
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 999,
                    }}
                    className="dynamic-delete-button"
                    onClick={() => remove(name)}
                  />
                  <div>
                    <Form.Item
                      required
                      name={[name, 'imageHash']}
                      label={
                        <label>
                          Photo <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                        </label>
                      }
                      rules={[
                        {
                          required: creativeType === 'carousel' && photoOrVideo !== 'video',
                        },
                      ]}
                    >
                      <FacebookImageUpload adsProvider={adsProvider} account={props.account} />
                    </Form.Item>
                    <Form.Item
                      style={{ flex: '1 1 400px', marginBottom: 5 }}
                      name={[name, 'name']}
                      required
                      rules={[
                        {
                          required: creativeType === 'carousel',
                          type: 'string',
                          min: 1,
                          max: 300,
                        },
                      ]}
                      label="Name"
                      {...restField}
                    >
                      <Input size="large"></Input>
                    </Form.Item>
                    <Form.Item
                      style={{ flex: '1 1 400px', marginBottom: 5 }}
                      name={[name, 'description']}
                      required
                      rules={[
                        {
                          required: creativeType === 'carousel',
                          type: 'string',
                          min: 1,
                          max: 300,
                        },
                      ]}
                      label="Descriptions"
                      {...restField}
                    >
                      <Input.TextArea rows={5} size="large"></Input.TextArea>
                    </Form.Item>
                    {/* ["facebookCampaign", "ad", "creative", "objectStorySpec", "linkData", "call_to_action", "link"] set with redirect*/}
                    <Form.Item
                      required
                      name={[name, 'callToAction', 'type']}
                      label={
                        <label>
                          Call to action <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                        </label>
                      }
                      rules={[
                        {
                          required: creativeType === 'carousel',
                          type: 'string',
                        },
                      ]}
                    >
                      <FacebookCTASelector />
                    </Form.Item>
                  </div>
                </BoxShadow>
              ))}
              <AddANewButton style={{ marginTop: '50%' }} onClick={() => add()}>
                + Add a new card
              </AddANewButton>
              <div style={{ marginTop: '50%', minWidth: 200 }}>
                <Form.ErrorList errors={errors} />
              </div>
            </div>
          )}
        </Form.List>

        <Form.Item
          style={{ width: 400 }}
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'caption']}
          label="Display link"
          rules={[
            {
              required: false,
              type: 'url',
            },
          ]}
        >
          <Input size="large" placeholder="Enter the link you want to show on your ad"></Input>
        </Form.Item>
      </div>
    );
  }

  function SINGLE_VIDEO_FORM() {
    return (
      <div style={{ width: 400 }}>
        <BoxShadow
          style={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <div>
            <Form.Item
              required
              valuePropName="src"
              label="Upload video"
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'videoData', 'imageUrl']}
            >
              <FacebookVideoPreview />
            </Form.Item>
            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'videoData', 'videoId']}
              label=""
              rules={[
                {
                  required: creativeType === 'single' && photoOrVideo === 'video',
                  type: 'string',
                },
              ]}
            >
              <FacebookVideoUpload
                adsProvider={adsProvider}
                setThumb={(url) => {
                  const c = adForm.getFieldsValue();

                  _.set(c, ['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'videoData', 'imageUrl'], url);
                  adForm.setFieldsValue(c);
                  stepChanged(adForm)(c);
                }}
                account={props.account}
              />
            </Form.Item>
            <Form.Item
              required
              style={{ flex: '1 1 400px', marginBottom: 5 }}
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'videoData', 'message']}
              rules={[
                {
                  required: creativeType === 'single',
                  type: 'string',
                  min: 1,
                  max: 9999,
                },
              ]}
              label={
                <label>
                  Primary text <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                  <div style={{ fontSize: '9px', color: 'var(--text-muted)' }}>
                    Facebook recommends up to 125 characters
                  </div>
                </label>
              }
            >
              <Input.TextArea rows={5} placeholder="Tell people what your ad is about. "></Input.TextArea>
            </Form.Item>
            <Form.Item
              required
              style={{ flex: '1 1 400px', marginBottom: 5 }}
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'videoData', 'title']}
              rules={[
                {
                  required: creativeType === 'single',
                  type: 'string',
                  min: 1,
                  max: 99,
                },
              ]}
              label={
                <label>
                  Headline <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                </label>
              }
            >
              <Input size="large" placeholder="Short headline"></Input>
            </Form.Item>
            <Form.Item
              style={{ flex: '1 1 400px', marginBottom: 5 }}
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'videoData', 'link_description']}
              rules={[
                {
                  required: false,
                  type: 'string',
                  min: 1,
                  max: 300,
                },
              ]}
              label="Descriptions"
            >
              <Input.TextArea rows={5}></Input.TextArea>
            </Form.Item>
            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'videoData', 'callToAction', 'type']}
              label={
                <label>
                  Call to action <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                </label>
              }
              rules={[
                {
                  required: creativeType === 'single',
                  type: 'string',
                },
              ]}
            >
              <FacebookCTASelector />
            </Form.Item>
          </div>
        </BoxShadow>

        <Form.Item
          style={{ width: 400 }}
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'caption']}
          label="Display link"
          rules={[
            {
              required: false,
              type: 'url',
            },
          ]}
        >
          <Input size="large" placeholder="Enter the link you want to show on your ad"></Input>
        </Form.Item>
      </div>
    );
  }

  function COLLECTION_VIDEO_FORM() {
    return (
      <div style={{ width: 400 }}>
        <BoxShadow
          style={{
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <div>
            <Form.Item
              required
              valuePropName="src"
              label="Upload video"
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', /* "videoData",*/ 'imageUrl']}
            >
              <FacebookVideoPreview />
            </Form.Item>
            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', /* "videoData",*/ 'videoId']}
              label=""
              rules={[
                {
                  required: creativeType === 'single' && photoOrVideo === 'video',
                  type: 'string',
                },
              ]}
            >
              <FacebookVideoUpload
                adsProvider={adsProvider}
                setThumb={(url) => {
                  const c = adForm.getFieldsValue();

                  _.set(
                    c,
                    ['facebookCampaign', 'ad', 'creative', 'objectStorySpec', /* "videoData",*/ 'imageUrl'],
                    url,
                  );
                  adForm.setFieldsValue(c);
                  stepChanged(adForm)(c);
                }}
                account={props.account}
              />
            </Form.Item>
            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'bodies']}
              label=""
              rules={[
                {
                  required: creativeType === 'collection',
                  type: 'array',
                  min: 2,
                  max: 3,
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={`${name}_${key}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '1em',
                      }}
                    >
                      <Form.Item
                        required
                        style={{
                          flex: '1 1 400px',
                          marginBottom: 5,
                        }}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: creativeType === 'collection',
                            type: 'string',
                            min: 1,
                            max: 300,
                          },
                        ]}
                        label={
                          <label>
                            Primary text <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                            <div style={{ fontSize: '9px', color: 'var(--text-muted)' }}>
                              Facebook recommends up to 125 characters
                            </div>
                          </label>
                        }
                        {...restField}
                      >
                        <Input.TextArea
                          rows={5}
                          size="large"
                          placeholder="Tell people what your ad is about. "
                        ></Input.TextArea>
                      </Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <AddANewButton onClick={() => add()}>+ Add a new primary text</AddANewButton>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'titles']}
              label=""
              rules={[
                {
                  required: creativeType === 'collection',
                  type: 'array',
                  min: 2,
                  max: 3,
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={`${name}_${key}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '1em',
                      }}
                    >
                      <Form.Item
                        required
                        style={{
                          flex: '1 1 400px',
                          marginBottom: 5,
                        }}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: creativeType === 'collection',
                            type: 'string',
                            min: 1,
                            max: 99,
                          },
                        ]}
                        label={
                          <label>
                            Headline <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                          </label>
                        }
                        {...restField}
                      >
                        <Input size="large" placeholder="Short headline"></Input>
                      </Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <AddANewButton onClick={() => add()}>+ Add a new headline</AddANewButton>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
            <Form.List
              name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'descriptions']}
              label=""
              rules={[{ required: false, type: 'array', min: 0, max: 3 }]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div
                      key={`${name}_${key}`}
                      style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        gap: '1em',
                      }}
                    >
                      <Form.Item
                        style={{ flex: '1 1 400px', marginBottom: 5 }}
                        name={[name, 'text']}
                        rules={[
                          {
                            required: false,
                            type: 'string',
                            min: 1,
                            max: 300,
                          },
                        ]}
                        label="Descriptions"
                        {...restField}
                      >
                        <Input.TextArea rows={5} size="large"></Input.TextArea>
                      </Form.Item>
                      <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                    </div>
                  ))}
                  <AddANewButton onClick={() => add()}>+ Add a new description</AddANewButton>
                  <Form.ErrorList errors={errors} />
                </div>
              )}
            </Form.List>
            <Form.Item
              required
              name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', /* "videoData",*/ 'callToAction', 'type']}
              label={
                <label>
                  Call to action <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                </label>
              }
              rules={[
                {
                  required: creativeType === 'collection',
                  type: 'string',
                },
              ]}
            >
              <FacebookCTASelector />
            </Form.Item>
          </div>
        </BoxShadow>
        <Form.List
          name={['facebookCampaign', 'ad', 'creative', 'assetFeedSpec', 'linkUrls']}
          label=""
          rules={[{ required: false, type: 'array', min: 0, max: 3 }]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div
                  key={`${name}_${key}`}
                  style={{
                    width: '100%',
                  }}
                >
                  <Form.Item
                    style={{ width: 400 }}
                    name={[name, 'displayUrl']}
                    label="Display link"
                    rules={[
                      {
                        required: false,
                        type: 'url',
                      },
                    ]}
                  >
                    <Input size="large" placeholder="Enter the link you want to show on your ad"></Input>
                  </Form.Item>
                </div>
              ))}
            </div>
          )}
        </Form.List>
      </div>
    );
  }

  function CAROUSEL_VIDEO_FORM() {
    return (
      <div
        style={{
          width: '800px',
          backgroundColor: 'rgb(252,252,252)',
          padding: '1em',
        }}
      >
        <Form.Item
          required
          style={{ flex: '1 1 400px', marginBottom: 5 }}
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'message']}
          rules={[
            {
              required: creativeType === 'single',
              type: 'string',
              min: 1,
              max: 9999,
            },
          ]}
          label={
            <label>
              Primary text <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
              <div style={{ fontSize: '9px', color: 'var(--text-muted)' }}>
                Facebook recommends up to 125 characters
              </div>
            </label>
          }
        >
          <Input.TextArea rows={5} size="large" placeholder="Tell people what your ad is about. "></Input.TextArea>
        </Form.Item>
        <Form.List
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'childAttachments']}
          label=""
          rules={[
            {
              required: creativeType === 'carousel',
              type: 'array',
              min: 2,
              max: 10,
            },
          ]}
        >
          {(fields, { add, remove, move }, { errors }) => (
            <div
              style={{
                display: 'flex',
                gap: '1em',
                maxWidth: '100%',
                overflow: 'auto',
                alignItems: 'stretch',
              }}
            >
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <BoxShadow
                  key={`${name}_${key}`}
                  style={{
                    marginLeft: 2,
                    marginRight: 2,
                    position: 'relative',
                    height: '100%',
                    minWidth: '300px',
                  }}
                  title={`Carousel ${key + 1}`}
                >
                  <MinusCircleOutlined
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      zIndex: 999,
                    }}
                    className="dynamic-delete-button"
                    onClick={() => remove(name)}
                  />
                  <div>
                    <Form.Item required valuePropName="src" label="Upload video" name={[name, 'picture']}>
                      <FacebookVideoPreview />
                    </Form.Item>
                    <Form.Item
                      required
                      name={[name, 'videoId']}
                      label=""
                      rules={[
                        {
                          required: creativeType === 'carousel' && photoOrVideo === 'video',
                        },
                      ]}
                    >
                      <FacebookVideoUpload
                        adsProvider={adsProvider}
                        setThumb={(url) => {
                          const c = adForm.getFieldsValue();

                          _.set(
                            c,
                            [
                              'facebookCampaign',
                              'ad',
                              'creative',
                              'objectStorySpec',
                              'linkData',
                              'childAttachments',
                              name,
                              'picture',
                            ],
                            url,
                          );
                          adForm.setFieldsValue(c);
                          stepChanged(adForm)(c);
                        }}
                        account={props.account}
                      />
                    </Form.Item>
                    <Form.Item
                      style={{ flex: '1 1 400px', marginBottom: 5 }}
                      name={[name, 'name']}
                      required
                      rules={[
                        {
                          required: creativeType === 'carousel',
                          type: 'string',
                          min: 1,
                          max: 300,
                        },
                      ]}
                      label="Name"
                      {...restField}
                    >
                      <Input size="large"></Input>
                    </Form.Item>
                    <Form.Item
                      style={{ flex: '1 1 400px', marginBottom: 5 }}
                      name={[name, 'description']}
                      required
                      rules={[
                        {
                          required: creativeType === 'carousel',
                          type: 'string',
                          min: 1,
                          max: 300,
                        },
                      ]}
                      label="Descriptions"
                      {...restField}
                    >
                      <Input.TextArea rows={5} size="large"></Input.TextArea>
                    </Form.Item>
                    {/* ["facebookCampaign", "ad", "creative", "objectStorySpec", "linkData", "call_to_action", "link"] set with redirect*/}
                    <Form.Item
                      required
                      name={[name, 'callToAction', 'type']}
                      label={
                        <label>
                          Call to action <span style={{ fontSize: '9px', fontWeight: 'bold' }}>*required</span>
                        </label>
                      }
                      rules={[
                        {
                          required: creativeType === 'carousel',
                          type: 'string',
                        },
                      ]}
                    >
                      <FacebookCTASelector />
                    </Form.Item>
                  </div>
                </BoxShadow>
              ))}
              <AddANewButton style={{ marginTop: '50%' }} onClick={() => add()}>
                + Add a new card
              </AddANewButton>
              <div style={{ marginTop: '50%', minWidth: 200 }}>
                <Form.ErrorList errors={errors} />
              </div>
            </div>
          )}
        </Form.List>
        <Form.Item
          style={{ width: 400 }}
          name={['facebookCampaign', 'ad', 'creative', 'objectStorySpec', 'linkData', 'caption']}
          label="Display link"
          rules={[
            {
              required: false,
              type: 'url',
            },
          ]}
        >
          <Input size="large" placeholder="Enter the link you want to show on your ad"></Input>
        </Form.Item>
      </div>
    );
  }

  if (adBlockDetected) {
    return (
      <Alert
        message="We are not able to contact Facebook if you are using AdBlock,"
        type="error"
        description={
          <Fragment>
            <a
              href="https://help.getadblock.com/support/solutions/articles/6000055743-how-to-disable-adblock-on-specific-sites"
              target="_blank"
              rel="noreferrer"
            >
              How do I turn off my Ad Blocker?
            </a>
          </Fragment>
        }
      ></Alert>
    );
  }

  return (
    <Style>
      {campaign && (
        <Fragment>
          <Wizard
            steps={wizardSteps}
            tRef={wizardRef}
            okText="Launch"
            width="389px"
            previousText="Back"
            nextText="Next"
            currentStep={0}
            loading={loading}
            onComplete={async () => {
              await saveCampaign(campaign);
            }}
            stepsTitle="Campaign resume"
          />
        </Fragment>
      )}
    </Style>
  );
}
