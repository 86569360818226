import React, { Fragment, useState } from 'react';

import { EyeTwoTone } from '@ant-design/icons';
import { Modal } from 'antd';
import Button from 'antd/lib/button';
import _ from 'lodash';
import styled from 'styled-components';

import { getAdsProviders } from 'lib/adsProvider/google';
import axios from 'lib/axios.factory';
import { getTiktokAdPreview } from 'lib/tiktokPlateformApi';

const Style = styled.div`
  iframe {
    -ms-zoom: 0.75;
    -moz-transform: scale(0.75);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
  }
`;

/**
 * Tiktok Ad Preview
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TiktokAdPreview(props: { account: accountT, value: tiktokCreativeT, adsProvider: adsProviderT }) {
  const [previewUrl, setPreviewUrl] = useState(undefined);
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === props.adsProvider.id);

  async function load(value) {
    try {
      const previewData = await getTiktokAdPreview(
        localStorage,
        axios,
        adsProvider,
        { accountId: props.account.id },
        value,
      );

      setPreviewUrl(previewData.previewLink);
    } catch (e) {
      throw e;
    }
  }

  return (
    <Fragment>
      <Button
        block
        size="large"
        disabled={
          !_.get(props.value, ['videoId']) ||
          !_.get(props.value, ['imageIds', 'length']) ||
          !_.get(props.value, ['adTexts', 'length']) ||
          !_.get(props.value, ['displayName'])
        }
        onClick={() => {
          load(props.value).then();
        }}
        icon={<EyeTwoTone />}
        type="primary"
        ghost
      >
        See the ad preview
      </Button>
      <Modal
        visible={!!previewUrl}
        footer={<Fragment />}
        onCancel={() => setPreviewUrl(undefined)}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'white !important',
          overflow: 'hidden',
        }}
        bodyStyle={{
          width: 'calc(100vw - 50px)',
          margin: 0,
          padding: 0,
          overflow: 'auto',
        }}
        width="100vw"
        height="100vh"
        layout="vertical"
        title="Preview"
        okText="Close"
      >
        <Style>
          <iframe
            title=""
            rel="noopener noreferrer"
            style={{
              textAlign: 'left',
              width: 'calc(135vw - 50px)',
              height: 'calc(135vh - 100px)',
            }}
            src={previewUrl}
          ></iframe>
        </Style>
      </Modal>
    </Fragment>
  );
}
