import type { axiosParams } from './api';
import { apiCall } from './api';

export function exportMetrics(params: {
  accountId: string,
  from?: string,
  to?: string,
  productId?: string,
  level?: string,
}): Promise<any> {
  return apiCall({
    secure: true,
    method: 'get',
    url: `/exports/amazon-attribution-metrics`,
    params,
  });
}

export function exportAllMetrics(params: { from?: string, to?: string }): Promise<any> {
  return apiCall({
    secure: true,
    method: 'get',
    url: `/exports/amazon-attribution-metrics-user-all`,
    params,
  });
}
