import React from 'react';

import './AdSuggestions.css';
import { Spin } from 'antd';

import { TitleWithInstructions } from '../../../common/TitleWithInstructions';

const AdSuggestionsButton = ({ value, selected, onSelect, onDeselect }) => (
  <span
    className={`ad-suggestions__btn ${selected ? 'ad-suggestions__btn--selected' : ''}`}
    onClick={() => (selected ? onDeselect(value) : onSelect(value))}
  >
    {selected ? '-' : '+'} {value}
  </span>
);

export const AdSuggestionsSection = ({ children, title }) => (
  <div className="ad-suggestions">
    <TitleWithInstructions title={title} />
    <div className="ad-suggestions__container">{children}</div>
  </div>
);

export const AdSuggestionsSubSection = ({ title, description, loading, options, selected, onSelect, onDeselect }) => (
  <div className="ad-suggestions-section">
    <div className="ad-suggestions-section__title">
      {title} <small>{description}</small>
    </div>
    <div className="ad-suggestions-section__container">
      {loading && <Spin />}
      {!loading &&
        options.map((headline) => (
          <AdSuggestionsButton
            value={headline}
            selected={selected.includes(headline)}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
        ))}
    </div>
  </div>
);
