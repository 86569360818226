import React from 'react';

import styled from 'styled-components';

function AbstractBlueTextButton(props) {
  const { children } = props;

  return <span {...props}>{children}</span>;
}

export const BlueTextButton = styled(AbstractBlueTextButton).withConfig({
  displayName: 'RedTextButton',
})`
  color: #2e89e2;
  font-size: var(--small-size);
  cursor: pointer;
`;
