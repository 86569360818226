import React, { useEffect, useState } from 'react';

import { Alert, message, notification, Select, Form } from 'antd';

import axios from 'lib/axios.factory';

import { useLoading } from '../../../../hooks/useLoading';
import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { getFacebookPages } from '../../../../lib/facebookPlateformApi';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';
import { AdsProviderReloginButton } from '../common/AdsProviderReLoginButton';

export function FacebookPageSelector(props: {
  adsProviderId: string,
  account: accountT,
  onChange: any,
  initialValue: string,
}) {
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === props.adsProviderId);
  const { doAction, loading } = useLoading(true);

  const [facebookPages, setFacebookPages] = useState([]);
  const [error, setError] = useState(<></>);

  async function load() {
    try {
      const facebookPages = await getFacebookPages(localStorage, axios, {
        id: adsProvider.externalAccountResourceName,
        accessToken: adsProvider.accessToken,
      });

      setFacebookPages(facebookPages);
    } catch (e) {
      console.error(e);
      setError(
        <Alert
          type="error"
          message={
            <div style={{ textAlign: 'center' }}>
              <bold>Unable to retrieve your Facebook Pages</bold>
              <div>Please try to reconnect Facebook</div>
              <br />
              <AdsProviderReloginButton block account={props.account} provider={adsProvider}></AdsProviderReloginButton>
              <br />
            </div>
          }
        ></Alert>,
      );
    }
  }

  useEffect(() => {
    doAction(load);
  }, []);

  if (!adsProvider) {
    return <SmallCompactPlaceholder />;
  }

  return (
    <>
      <Select
        size="large"
        loading={loading}
        defaultValue={props.initialValue}
        value={props.value}
        placeholder="Select a page"
        onChange={props.onChange}
      >
        {facebookPages.map((p) => (
          <Select.Option key={p.id} value={p.id}>
            {p.name}
          </Select.Option>
        ))}
        )
      </Select>
      {error}
    </>
  );
}
