import * as en from './en';
import * as zh from './zh';

export const localeHandler = (locale: string): ({ +i18n: (variables: any) => any, ... }) => {
  switch (locale) {
    case 'zh':
      return zh;
    default:
      return en;
  }
};

export const INIT_LOCALE = 'en';

export const fetchStrings = async (locale) => {
  const messages = await localeHandler(locale);

  return messages.i18n;
};

export const getIntlStrings = (key: string, variables: Object = {}): string => {
  try {
    const messages = localeHandler(navigator.language.split('-')[0]);

    let result = key.split('.').reduce((o, i) => o[i], messages.i18n(variables));

    if (result === undefined) {
      result = key;
    }

    return result;
  } catch (error) {
    if (error.message.indexOf('Cannot read property') !== -1) {
      return key;
    } else {
      throw error;
    }
  }
};

export const localesAllowed = ['en', 'zh'];

export const getLocale = (locale) => (localesAllowed.includes(locale) ? locale : INIT_LOCALE);
