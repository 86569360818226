import React from 'react';

import styled from 'styled-components';

import { BoxShadow } from '../../../BoxShadow';

const Style = styled.div`
  img {
    width: 182px;
  }
  opacity: ${(props) => (props.selected ? 1 : 0.5)};
`;

export function Theme({ theme, onClick, selected }) {
  return (
    <Style onClick={onClick} selected={selected}>
      <BoxShadow>
        <img src={theme.previewUrl} alt="" />
      </BoxShadow>
    </Style>
  );
}
