import './Invoices.css';

import React, { Component } from 'react';

import numeral from 'numeral';

import { invoices, upcomingInvoice } from 'lib/api';
import axios from 'lib/axios.factory';

import Box from '../../common/Box';
import { Line } from '../../common/Placeholders';

export default class Invoices extends Component<
  {
    account: accountT,
    user: userT,
  },
  {
    loading: boolean,
    invoices: Array<invoiceT>,
    upcomingInvoice: invoiceT,
  },
> {
  constructor(props: { account: accountT }) {
    super(props);
    this.state = { loading: true, invoices: [], upcomingInvoice: null };
  }
  render() {
    const { loading, upcomingInvoice } = this.state;
    const invoices = this.state.invoices.filter((i) => i.paid);

    if (loading) {
      return (
        <Box className="invoices" header="Last invoices">
          <Line />
          <Line />
        </Box>
      );
    }

    if (invoices.length === 0) {
      return (
        <Box className="invoices" header="Last invoices">
          No invoice
        </Box>
      );
    }

    return (
      <>
        {!!upcomingInvoice && (
          <Box className="invoices" header="Upcoming invoice">
            <div key={upcomingInvoice.id} className="invoice">
              <div className="invoice__number">{upcomingInvoice.number}</div>
              <div className="invoice__period">{upcomingInvoice.period}</div>
              <div className="invoice__amount">{numeral(upcomingInvoice.amountInCents / 100).format('$0,0.00')}</div>
            </div>
          </Box>
        )}
        <Box className={upcomingInvoice ? 'upcoming-invoice invoices' : 'invoices'} header="Last invoices">
          {invoices.map((invoice: invoiceT) => (
            <div key={invoice.id} className="invoice">
              <div className="invoice__number">{invoice.number}</div>
              <div className="invoice__period">{invoice.period}</div>
              <div className="invoice__amount">{numeral(invoice.amountInCents / 100).format('$0,0.00')}</div>
              <div className="invoice_download-icon">
                <a href={invoice.link} target="_blank" rel="noopener noreferrer">
                  <DownloadIcon />
                </a>
              </div>
            </div>
          ))}
        </Box>
      </>
    );
  }

  componentDidMount() {
    const { account } = this.props;

    invoices(localStorage, axios, {
      accountId: account.id,
    }).then(({ invoices }) => this.setState({ invoices, loading: false }));

    upcomingInvoice(localStorage, axios, {
      accountId: account.id,
    }).then(({ invoice }) => this.setState({ upcomingInvoice: invoice, loading: false }));
  }
}

function DownloadIcon() {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="14" height="3.88235" rx="1" transform="translate(0 7.11768)" fill="#2B98F0" />
      <path
        d="M4.2 3.23529V0H7V3.23529H11.2L5.9394 8.09804C5.74779 8.27515 5.45221 8.27515 5.2606 8.09804L0 3.23529H4.2Z"
        transform="translate(1.40039 1.29395)"
        fill="white"
      />
      <path
        d="M4.2 3.23529V0.5C4.2 0.223858 4.42386 0 4.7 0H6.5C6.77614 0 7 0.223858 7 0.5V3.23529H9.92249C10.3775 3.23529 10.596 3.79362 10.2619 4.10246L5.9394 8.09804C5.74779 8.27515 5.45221 8.27515 5.2606 8.09804L0.938115 4.10246C0.604006 3.79362 0.822526 3.23529 1.27751 3.23529H4.2Z"
        transform="translate(1.40039)"
        fill="#2B98F0"
      />
    </svg>
  );
}
