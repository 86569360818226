import React from 'react';

export function SecondBullet(props) {
  return (
    <span {...props}>
      <svg width="100%" height="100%" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1" height="1" fill="#828282" />
        <rect x="2" width="1" height="1" fill="#828282" />
        <rect x="2" y="2" width="1" height="1" fill="#828282" />
        <rect y="2" width="1" height="1" fill="#828282" />
        <rect x="2" y="4" width="1" height="1" fill="#828282" />
        <rect y="4" width="1" height="1" fill="#828282" />
      </svg>
    </span>
  );
}
