import React from 'react';

import styled from 'styled-components';

const EmptyIcon = styled.span`
  line-height: 0.75rem;
  font-size: 1.25rem;
  padding-bottom: 2px;
  color: #828282;
  ${(props) =>
    props.$disabled &&
    `
    color: #d9d9d9;
  `}
`;

const ColoredIcon = styled.svg`
  line-height: 0.75rem;
`;

export const LegendItemSymbol = ({
  color = 'transparent',
  inactive = false,
  disabled = false,
  ...props
}: {
  color?: string,
  inactive?: boolean,
  disabled?: boolean,
}) => {
  if (disabled) {
    return (
      <EmptyIcon $disabled {...props}>
        ○
      </EmptyIcon>
    );
  }

  if (inactive) {
    return <EmptyIcon {...props}>○</EmptyIcon>;
  }

  return (
    <ColoredIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 6" fill="none" {...props}>
      <circle cx="9" cy="2.5" r="2.5" fill={color} />
      <path d="M1 2.5H17" stroke={color} strokeWidth="2" strokeLinecap="square" />
    </ColoredIcon>
  );
};
