import React from 'react';

import { Button, notification } from 'antd';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sd } from '../lib/safe';

export function useUpgrade(key, message) {
  const account = useSelector((states) => {
    const acc = sd(states.accounts.accounts, [])[0];

    return acc;
  });
  const planName = useSelector((states) => states.subscription.sub.plan.name);

  const history = useHistory();
  const hideUpgrade = debounce((event) => {
    notification.close('upgrade');
  }, 2000);
  const immediateHideUpgrade = (event) => notification.close('upgrade');

  const showUpgrade = debounce((event) => {
    notification.success({
      duration: 10,
      maxCount: 1,
      key: 'upgrade',
      message: 'Upgrade your plan',
      description: (
        <div>
          <br />
          <h6>
            {planName} plan does not allow {message}.
          </h6>
          <br />
          <Button
            style={{ position: 'absolute', right: '1em' }}
            type="primary"
            onClick={() => {
              history.push(`/${account.id}/b/plans`);
            }}
          >
            Upgrade
          </Button>
          <br />
          <br />
        </div>
      ),
    });
  }, 200);

  return { showUpgrade, hideUpgrade, immediateHideUpgrade };
}
