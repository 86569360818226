import React, { useState, type Node } from 'react';

import { WarningFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

import { Alert } from 'components/common/antd/Alert';
import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';

import { InfoCampaignModal } from './InfoCampaignModal';

const StyledIconInsideButton = styled(IconInsideButton)`
  min-width: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type propsT = {
  campaign: campaignT,
};
export function CampaignAlertButton({ campaign }: propsT): Node {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <InfoCampaignModal
        campaign={campaign}
        visible={visible}
        onFinish={() => setVisible(false)}
        alert={
          <Alert
            type="warning"
            showIcon
            message="Action Required"
            description={
              <>
                <p>The Attribution Campaign may have been renamed in the Amazon Attribution console.</p>
                <p>
                  <b>To Fix:</b> Locate the campaign in Amazon Attribution and rename the campaign to the below Amazon
                  Attribution Campaign ID.
                </p>
              </>
            }
          ></Alert>
        }
      />
      <StyledIconInsideButton onClick={() => setVisible(true)}>
        <Tooltip title="Action Required" placement="bottom">
          <WarningFilled style={{ color: '#F8A51B' }} />
        </Tooltip>
      </StyledIconInsideButton>
    </>
  );
}
