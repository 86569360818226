import React, { useEffect } from 'react';

import { Spinner } from '@blueprintjs/core';
import styled from 'styled-components';

import { Avatar } from './Avatar';
import { Background } from './Background';
import { ThemesChooser } from './ThemesChooser';

const Style = styled.div`
  grid-area: builder;
  overflow-y: scroll;
`;

export function Appearance({ account, updateState, savePage, state }) {
  useEffect(() => {
    savePage(state.page);
  }, [JSON.stringify(state.page)]);

  function handleChange(page) {
    updateState((draft) => {
      draft.page = { ...state.page, ...page };
    });
  }

  if (!state.page) {
    return <Spinner />;
  }

  return (
    <Style>
      <Avatar page={state.page} handleChange={handleChange} account={account} />
      <Background page={state.page} handleChange={handleChange} account={account} />
      <ThemesChooser page={state.page} handleChange={handleChange} updateState={updateState} />
    </Style>
  );
}
