import { useEffect, useState } from 'react';

import { message } from 'antd';
import deepmerge from 'deepmerge';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';

import axios from 'lib/axios.factory';

import { getCampaignClone, getEmptyCampaign } from '../lib/adsPlateformApi';

export function overwriteMerge(destinationArray, sourceArray, options) {
  if (sourceArray) {
    return sourceArray;
  } else {
    return destinationArray;
  }
}
export function arrayContentMerge(destinationArray, sourceArray, options) {
  // loop through source array and merge each child object
  if (sourceArray) {
    sourceArray.forEach((sourceChild, index) => {
      if (destinationArray[index]) {
        destinationArray[index] = deepmerge(destinationArray[index], sourceChild);
      } else {
        destinationArray[index] = sourceChild;
      }
    });
  }

  return destinationArray;
}
function campaignToValues(campaign) {
  const result = cloneDeep(campaign);

  if (campaign.providerId) {
    result.adsProvider = {
      id: campaign.providerId,
      providerType: campaign.providerType,
    };
  }

  if (campaign.productId !== undefined) {
    result.product = {
      id: campaign.productId,
      externalId: campaign.productExternalId,
      productUrl: campaign.productUrl,
    };
  }

  if (campaign.googleCampaign) {
    if (campaign.googleCampaign.start) {
      if (moment().diff(result.googleCampaign.start) > 0) {
        result.googleCampaign.start = undefined;
      }
    }

    if (campaign.googleCampaign.end) {
      if (moment().diff(result.googleCampaign.end) > 0) {
        result.googleCampaign.end = undefined;
      }
    }

    if (campaign.googleCampaign.geoCriteria) {
      result.googleCampaign.geoCriteria = campaign.googleCampaign.geoCriteria.filter((g) => g).filter((g) => g.id);
    }

    result.googleCampaign.geoCriteria =
      result.googleCampaign.geoCriteria &&
      result.googleCampaign.geoCriteria.map((g) => ({
        ...g,
        criteriaId: `${g.id}`,
      }));

    if (campaign.googleCampaign.languagesCriteria) {
      result.googleCampaign.languagesCriteria =
        campaign.googleCampaign.languagesCriteria && campaign.googleCampaign.languagesCriteria.map((l) => l.id);
    }

    if (campaign.googleCampaign.budgetMicros !== undefined) {
      result.googleCampaign.budget = (campaign.googleCampaign.budgetMicros || 0) / 1000000;
    }

    if (campaign.googleCampaign.adGroup) {
      result.googleCampaign.budgetCpc = (campaign.googleCampaign.adGroup.cpcBidMicros || 0) / 1000000;
    }
  }

  _.set(result, ['id'], undefined);

  return result;
}

export function useAdsCampaign(campaignId: string, accountId: string, initialValues: any, adsProviderType: string) {
  const [campaign, setCampaign] = useState(undefined);
  const [campaignLoading, setCampaignLoading] = useState(true);

  async function loadCampaign() {
    setCampaignLoading(true);

    try {
      const campaignsResponse = campaignId
        ? await getCampaignClone(localStorage, axios, { accountId }, campaignId)
        : await getEmptyCampaign(localStorage, axios, { accountId }, adsProviderType);

      const c = campaignToValues(campaignsResponse.campaign);

      // need to set product to be able to construct product provider url to save campaign
      c.product = campaignsResponse.product;
      setCampaign(c);
      setCampaignLoading(false);
    } catch (e) {
      console.error('Unable to load campaign to duplicate');
      console.error(e);
      setCampaignLoading(false);
    }
  }

  const needRefresh = !campaign;

  useEffect(() => {
    if (needRefresh) {
      loadCampaign().then();
    }
  }, [needRefresh]);

  return { campaign, setCampaign, campaignLoading };
}
