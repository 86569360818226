import React from 'react';

import C6Logo from '../../layout/C6Logo';
import NewLogo from '../../layout/NewLogo';

export const gtmButtonStyles = {
  'sign-up-c6': { background: '#1AFFEE', borderColor: '#1AFFEE', color: '#2E0077' },
  'gtm': { background: '#AFFF00', borderColor: '#AFFF00', color: '#2E0077' },
};

export function C6Header() {
  return (
    <>
      <C6Logo />
      <a href="https://www.carbon6.io/" style={{ color: '#fff', fontSize: '20px' }}>
        Back to Carbon6.io
      </a>
    </>
  );
}

export const Title = ({ children }) => (
  <>
    <div style={{ marginBottom: '30px' }}>
      <NewLogo />
    </div>
    {children}
  </>
);
