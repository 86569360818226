import React from 'react';

export function SignupSuccessV2() {
  return (
    <svg
      width="287"
      height="258"
      viewBox="0 0 287 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto' }}
    >
      <g clipPath="url(#clip0_908_14469)">
        <rect y="101" width="295" height="163" fill="white" />
        <path
          d="M284.482 98.664C284.38 98.6641 284.28 98.6327 284.195 98.574L144.945 1.45796C144.522 1.16399 144.018 1.00687 143.503 1.00776C142.987 1.00866 142.484 1.16752 142.061 1.46296L3.81441 98.573C3.73288 98.6305 3.63609 98.6624 3.53635 98.6648C3.4366 98.6671 3.33841 98.6398 3.25426 98.5862C3.17011 98.5326 3.1038 98.4552 3.06376 98.3638C3.02372 98.2724 3.01177 98.1712 3.02941 98.073C3.05215 97.9413 3.12625 97.824 3.23541 97.747L141.482 0.63696C142.074 0.223376 142.779 0.00107667 143.5 3.90109e-06C144.222 -0.00106887 144.927 0.219136 145.52 0.63096L284.77 97.746C284.858 97.8076 284.924 97.8955 284.959 97.997C284.994 98.0986 284.995 98.2085 284.963 98.311C284.932 98.4136 284.868 98.5033 284.781 98.5669C284.695 98.6304 284.59 98.6645 284.482 98.664Z"
          fill="#212529"
        />
        <path
          d="M11.7148 102.108L143.64 4.19507L276.566 109.074L150.437 183.952L81.9598 168.321L11.7148 102.108Z"
          fill="#E6E6E6"
          fillOpacity="0.4"
        />
        <path
          d="M1 99.9999V105H285.5V99.9999C240 67.8332 148.6 3.0999 147 1.4999C145.4 -0.100102 143 0.833231 142 1.4999L1 99.9999Z"
          fill="#DDE0E9"
        />
        <path
          d="M87.7392 230.2H22.5632C22.1628 230.201 21.766 230.123 21.396 229.97C21.0259 229.817 20.6899 229.592 20.4072 229.308C19.9802 228.881 19.6895 228.338 19.572 227.746C19.4545 227.154 19.5155 226.54 19.7472 225.983C20.0568 225.236 20.6503 224.642 21.3972 224.333C21.7672 224.18 22.1632 224.101 22.5632 224.102H87.7372C88.1376 224.101 88.5343 224.179 88.9044 224.332C89.2744 224.485 89.6105 224.709 89.8932 224.993C90.3199 225.419 90.6104 225.963 90.7282 226.554C90.846 227.146 90.7856 227.759 90.5549 228.317C90.3241 228.874 89.9332 229.351 89.4317 229.686C88.9302 230.021 88.3405 230.2 87.7372 230.2H87.7392ZM45.9472 217.091H22.5642C21.7557 217.091 20.9803 216.77 20.4082 216.199C19.9816 215.772 19.691 215.229 19.5733 214.637C19.4555 214.046 19.5158 213.432 19.7466 212.875C19.9774 212.318 20.3682 211.841 20.8698 211.506C21.3713 211.171 21.961 210.992 22.5642 210.992H45.9472C46.7557 210.992 47.5311 211.313 48.1032 211.884C48.3869 212.167 48.6118 212.503 48.765 212.873C48.9183 213.243 48.9969 213.64 48.9962 214.041C48.9957 214.85 48.6743 215.625 48.1027 216.197C47.531 216.769 46.7558 217.09 45.9472 217.091Z"
          fill="#1AFFEE"
        />
        <path
          d="M145.495 156.642C144.999 156.643 144.508 156.546 144.049 156.359L62.6875 122.551V16.727C62.6885 15.7913 63.0603 14.8941 63.7215 14.232C64.3816 13.5707 65.2772 13.1985 66.2115 13.197H222.301C223.235 13.1997 224.131 13.5726 224.792 14.2342C225.453 14.8957 225.824 15.7921 225.826 16.727V122.625L225.673 122.69L146.984 156.342C146.512 156.54 146.006 156.642 145.495 156.642Z"
          fill="white"
        />
        <path
          d="M145.495 156.894C144.966 156.895 144.443 156.792 143.955 156.592L62.4355 122.719V16.7271C62.4367 15.7246 62.8351 14.7634 63.5435 14.0541C64.2511 13.3453 65.2111 12.9464 66.2125 12.9451H222.3C223.302 12.9461 224.261 13.3451 224.969 14.0551C225.676 14.7644 226.075 15.725 226.077 16.7271V122.791L147.082 156.574C146.579 156.786 146.04 156.895 145.495 156.894ZM63.4425 122.046L144.339 155.66C145.093 155.966 145.938 155.961 146.689 155.646L225.07 122.126V16.7271C225.07 15.9921 224.778 15.287 224.259 14.767C223.739 14.247 223.035 13.9546 222.3 13.9541H66.2125C65.8488 13.9544 65.4886 14.0263 65.1527 14.1658C64.8167 14.3053 64.5115 14.5096 64.2545 14.7671C63.7353 15.2873 63.4434 15.9921 63.4425 16.7271V122.046Z"
          fill="#212529"
        />
        <path
          d="M283.475 97.656H283.375L225.572 122.373L146.486 156.192C145.862 156.454 145.159 156.457 144.532 156.202L62.939 122.303L3.716 97.697L3.626 97.657H3.525C2.5901 97.6577 1.69374 98.0296 1.033 98.691C0.372155 99.3533 0.000703085 100.25 0 101.186L0 254.471C0.00158286 255.406 0.373347 256.302 1.03396 256.963C1.69456 257.625 2.59023 257.997 3.525 258H283.475C283.938 258 284.397 257.908 284.825 257.731C285.253 257.554 285.641 257.294 285.969 256.966C286.296 256.638 286.556 256.249 286.733 255.821C286.91 255.393 287 254.934 287 254.471V101.186C287.001 100.25 286.629 99.3531 285.968 98.6911C285.307 98.0291 284.411 97.6568 283.475 97.656ZM285.993 254.471C285.992 255.139 285.727 255.779 285.255 256.252C284.783 256.724 284.143 256.99 283.475 256.992H3.525C2.85715 256.99 2.21716 256.724 1.7452 256.252C1.27324 255.779 1.00779 255.139 1.007 254.471V101.186C1.00872 100.535 1.26143 99.9095 1.71255 99.4399C2.16366 98.9703 2.77845 98.6928 3.429 98.665L62.939 123.392L144.145 157.135C145.024 157.493 146.009 157.488 146.884 157.12L225.572 123.468L283.576 98.665C284.226 98.693 284.839 98.97 285.289 99.44C285.739 99.91 285.991 100.535 285.993 101.186V254.471Z"
          fill="#212529"
        />
        <path
          d="M144.681 126.636C141.676 126.641 138.752 125.668 136.35 123.864L136.201 123.752L104.827 99.6969C103.372 98.5817 102.152 97.1909 101.236 95.6039C100.319 94.0169 99.7245 92.2649 99.4855 90.4479C99.0012 86.779 99.9916 83.0677 102.24 80.1279C104.487 77.1907 107.809 75.2663 111.475 74.7779C113.29 74.5373 115.134 74.6571 116.903 75.1305C118.672 75.6039 120.329 76.4217 121.782 77.5369L142.104 93.1409L190.127 30.4219C192.375 27.4861 195.698 25.5636 199.364 25.0769C201.179 24.8372 203.023 24.9577 204.792 25.4317C206.56 25.9056 208.218 26.7236 209.67 27.8389L209.372 28.2449L209.678 27.8449C212.608 30.1016 214.525 33.4278 215.009 37.0949C215.492 40.7633 214.503 44.4739 212.258 47.4149L155.77 121.188C154.464 122.887 152.785 124.263 150.862 125.208C148.938 126.153 146.823 126.641 144.681 126.636Z"
          fill="#1AFFEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_908_14469">
          <rect width="287" height="258" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
