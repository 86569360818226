import React, { useEffect } from 'react';

import { Card, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import { navigate } from 'hookrouter';

import axios from 'lib/axios.factory';

import { FakeAdsProviders } from './components/FakeAdsProviders';
import { FakePixel } from './components/FakePixel';
import { LabelledCopyPasteRedirect } from './components/LabelledCopyPasteRedirect';
import { LinkTTL } from './components/LinkTTL';
import { SkipNextButton } from './components/SkipNextButton';
import { addPixel, updateRedirect } from '../../../lib/api';
import { sd } from '../../../lib/safe';
import Pixel from '../../common/Pixel';

// @ts-ignore

export function LinkPixelForm({ account, state, updateState }) {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(state.redirect);
  }, [JSON.stringify(state.redirect)]);

  async function onChange(changes) {
    changes.id = changes._id;
    const pixel = {
      ...state.pixel,
      ...changes,
    };

    updateState((draft) => {
      draft.pixel = pixel;
    });
  }

  async function saveLink(pixel) {
    pixel.id = pixel._id;
    let accounts = { account };

    if (state.redirect.pixels.length === 0 && pixel.name && pixel.id) {
      accounts = await addPixel(
        localStorage,
        axios,
        { accountId: account.id },
        {
          providerKey: 'fb',
          name: pixel.name,
          key: pixel.id,
        },
      );
    }

    await updateRedirect(
      localStorage,
      axios,
      {
        accountId: account.id,
      },
      {
        redirectId: state.redirect.id,
        ...state.redirect,
        pixelsIds: accounts.account.pixels.length > 0 ? [accounts.account.pixels[0].id] : [],
      },
    );
    navigate(`/easy/signup`);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={state.pixel}
      defaultValue={state.pixel}
      onValuesChange={onChange}
      onFinish={saveLink}
    >
      <Card className="card" title="Add a Facebook pixel to your page" extra={<LinkTTL redirect={state.redirect} />}>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <div
              style={{
                gap: '15px',
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              <Form.Item
                initialValue="fb"
                name="providerId"
                label="Ad platform"
                style={{ minWidth: '100px', flex: '0' }}
              >
                <div style={{ position: 'absolute' }}>
                  <FakeAdsProviders></FakeAdsProviders>
                </div>
              </Form.Item>
              <Form.Item hidden={true} initialValue="fb" name="providerId" label="Ad platform">
                <Select>
                  {sd(state.providers, []).map((item) => (
                    <Select.Option key={item.id} value={item.id} disabled={item.id !== 'facebook'}>
                      <div
                        style={{
                          padding: '1em',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Pixel provider={item.id} />
                        <span>{item.value}</span>
                      </div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="name" label="Name your pixel">
                <Input placeholder="My pixel name" />
              </Form.Item>
              <Form.Item name="_id" label="Pixel ID" initialValue="">
                <Input placeholder="276395355658903" />
              </Form.Item>

              <Form.Item label="Your short link">
                <div style={{ margin: 'auto', display: 'flex' }}>
                  <LabelledCopyPasteRedirect redirect={state.redirect} />
                </div>
              </Form.Item>
            </div>
            <div style={{ marginLeft: '100px', textAlign: 'left' }}>
              <span>
                Want to find your Facebook pixel ID?{' '}
                <a target="_blank" href="https://www.facebook.com/events_manager2/list/pixel/" rel="noreferrer">
                  Follow this link.
                </a>
              </span>
              <br />
              <FakePixel />
            </div>
          </div>
        </div>
      </Card>
      <SkipNextButton account={account} step={4} finish />
    </Form>
  );
}
