import React, { type Node } from 'react';

import { message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import { Copy } from 'components/common/icons';
import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';

const CampaignName = styled.div`
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const AlertContainer = styled.div`
  margin-bottom: 1rem;
`;

type propsT = {
  campaign: campaignT,
  visible: boolean,
  onFinish: () => any,
  alert: Node,
};
export const InfoCampaignModal = ({ campaign, visible, onFinish, alert }: propsT): Node => (
  <Modal
    visible={visible}
    title={
      <>
        Campaign Info
        <CampaignName>{campaign.name}</CampaignName>
      </>
    }
    width={600}
    okText="OK"
    onOk={onFinish}
    onCancel={onFinish}
    cancelButtonProps={{ style: { display: 'none' } }}
  >
    <AlertContainer>{alert}</AlertContainer>
    <b>Amazon Attribution Campaign ID</b>
    <br />
    <div>
      Copy the Amazon Campaign ID in order to search in the Amazon console:
      <br />
      {campaign.externalId}
      <IconInsideButton>
        <CopyToClipboard
          text={campaign.externalId}
          onCopy={() => message.success(`${campaign.externalId} copied to clipboard.`)}
        >
          <Copy />
        </CopyToClipboard>
      </IconInsideButton>
    </div>
    <br />
    <p>
      <b>WARNING: Do not </b> edit the original Campaign ID in the Amazon console as this will break the Amazon
      Attribution API connection. We're aware the campaigns have hard to find names, but we're following the protocol
      laid out by Amazon Attribution for working with them via API.
    </p>
    <br />

    {campaign.tiktokAd && campaign.macroUrl && (
      <>
        <b>To create more ads and ad groups:</b>
        <br />
        <div>
          Create them in TikTok as usual & use this link as the destination url: <br />
          {campaign.macroUrl}
          <IconInsideButton>
            <CopyToClipboard text={campaign.macroUrl} onCopy={() => message.success('URL copied to clipboard.')}>
              <Copy />
            </CopyToClipboard>
          </IconInsideButton>
        </div>

        <b>To edit ads and ad groups:</b>
        <br />
        <p>Make edits in TikTok as you normally would in TikTok. The changes will be reflected in PixelMe.</p>
        <br />
        <p>
          <b>Do not</b> modify PixelMe's destination url as this can interfere with Amazon Attribution measurement.
        </p>

        <a href="#" target="_blank">
          Learn More
        </a>
        <br />
        <br />
      </>
    )}
  </Modal>
);
