import { WorkerAction } from '../../framework/workerAction';

export default {
  search: new WorkerAction({ name: 'api.audienceBuilder.redirect.search' }),
  sort: new WorkerAction({ name: 'api.audienceBuilder.redirect.sort' }),
  loadNext: new WorkerAction({ name: 'api.audienceBuilder.redirect.loadNext' }),
  loadPrev: new WorkerAction({ name: 'api.audienceBuilder.redirect.loadPrev' }),
  load: new WorkerAction({ name: 'api.audienceBuilder.redirect.load' }),
  delete: new WorkerAction({ name: 'api.audienceBuilder.redirect.delete' }),
  duplicate: new WorkerAction({
    name: 'api.audienceBuilder.redirect.duplicate',
  }),
  edit: new WorkerAction({ name: 'api.audienceBuilder.redirect.edit' }),
  export: new WorkerAction({ name: 'api.audienceBuilder.redirect.export' }),
  // postPreview: new WorkerAction({ name: "api.audienceBuilder.redirect.post" }),
  stats: {
    export: new WorkerAction({
      name: 'api.audienceBuilder.redirect.stats.export',
    }),
  },
  editSelected: new WorkerAction({
    name: 'api.audienceBuilder.redirect.editSelected',
  }),
};
