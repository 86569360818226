import React, { Fragment, useState } from 'react';

import { Checkbox, Form, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { debounce } from 'lodash/function';

import { FacebookAudiencePreview } from './FacebookAudiencePreview';
import { FacebookEstimated } from './FacebookEstimated';
import { FacebookGeoTargetSelector } from './FacebookGeoTargetSelector';
import { FacebookLanguagesSelector } from './FacebookLanguagesSelector';
import { FacebookTargetingSelector } from './FacebookTargetingSelector';
import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { BoxShadow } from '../../../BoxShadow';
import { FormInputGroup } from '../../../common/antd/FormInputGroup';
import { AddANewButton } from '../common/AddANewButton';

export function FacebookNewAudienceButton(props: {
  adsProvider: adsProviderT,
  account: accountT,
  onChange: any,
  visible: boolean,
  value: any,
}) {
  const [showModal, setShowModal] = useState(false);
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === props.adsProvider.id);

  const value = { ...(props.value || {}) };

  return (
    <Fragment>
      {!showModal && (
        <Fragment>
          <BoxShadow style={{ width: 'fit-content' }} onClick={() => setShowModal(true)}>
            <FacebookAudiencePreview audience={props.value} />
          </BoxShadow>
          <AddANewButton style={{ paddingLeft: 15 }} onClick={() => setShowModal(true)}>
            Setup Audience
          </AddANewButton>
        </Fragment>
      )}
      <NewAudienceForm
        account={props.account}
        adsProvider={adsProvider}
        visible={showModal}
        onChange={props.onChange}
        initialValues={props.initialValues}
        value={value}
        onFinish={(values) => {
          props.onChange(values);
          setShowModal(false);
        }}
      />
    </Fragment>
  );
}

/**
 * Facebook New Audience form
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function NewAudienceForm(props: {
  adsProvider: adsProviderT,
  account: accountT,
  onFinish: any,
  visible: boolean,
  initialValues: any,
  value: any,
}) {
  const [form] = useForm();
  const [values, setValues] = useState({});

  const debouncedSetValues = debounce(setValues, 500);

  const formRequirementsOk = form && (form.getFieldsError() || []).flatMap((e) => e.errors).length === 0;

  return (
    <Fragment>
      <Modal
        visible={props.visible}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'white !important',
        }}
        bodyStyle={{ maxHeight: 'calc( 100vh - 110px)', overflow: 'auto' }}
        width="100vw"
        height="100vh"
        layout="vertical"
        title="Create a new Audience"
        okText="Save your audience"
        onCancel={() => {
          props.onFinish();
        }}
        onOk={() => {
          form.submit();
        }}
      >
        <div style={{ display: 'flex', gap: '3em' }}>
          <div style={{ minWidth: 600 }}>
            <Form
              initialValues={props.value}
              requiredMark="optional"
              form={form}
              layout="vertical"
              onValuesChange={async (changedValues: any, values: Values) => {
                debouncedSetValues(values);
              }}
              onFinish={(values) => {
                props.onFinish(values);
              }}
            >
              <Form.Item name="geoLocations" label="Location" required rules={[{ required: true, type: 'array' }]}>
                <FacebookGeoTargetSelector account={false} adsProvider={props.adsProvider} />
              </Form.Item>
              <Form.Item
                name="genders"
                label="Gender"
                initialValue={0}
                required
                rules={[{ required: true, type: 'array', min: 1 }]}
              >
                <Checkbox.Group size="large" multiple>
                  <Checkbox value={0}>All</Checkbox>
                  <Checkbox value={1}>Men</Checkbox>
                  <Checkbox value={2}>Women</Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <div style={{ width: '200px' }}>
                <FormInputGroup>
                  <Form.Item
                    style={{ width: '50%' }}
                    name="ageMin"
                    label="Age"
                    required
                    rules={[{ required: true, type: 'number' }]}
                  >
                    <Select size="large">
                      {[...Array(66).keys()].splice(13, 66).map((e) => (
                        <Select.Option key={e} value={e}>
                          {e}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ width: '50%' }}
                    name="ageMax"
                    label="Age"
                    required
                    rules={[{ required: true, type: 'number' }]}
                  >
                    <Select size="large">
                      {[...Array(66).keys()].splice(13, 66).map((e) => (
                        <Select.Option key={e} value={e}>
                          {e}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FormInputGroup>
              </div>
              <Form.Item
                name="includedDetailedTargets"
                label="Detailed Targeting - Include"
                rules={[{ required: false, type: 'array' }]}
              >
                <FacebookTargetingSelector
                  account={props.account}
                  provider={props.provider}
                  adsProvider={props.adsProvider}
                />
              </Form.Item>
              <Form.Item
                name="excludedDetailedTargets"
                label="Detailed Targeting - Exclude"
                rules={[{ required: false, type: 'array' }]}
              >
                <FacebookTargetingSelector
                  account={props.account}
                  provider={props.provider}
                  adsProvider={props.adsProvider}
                />
              </Form.Item>
              <Form.Item name="languages" label="Languages" rules={[{ required: false, type: 'array' }]}>
                <FacebookLanguagesSelector account={props.account} adsProvider={props.adsProvider} />
              </Form.Item>
            </Form>
          </div>
          {formRequirementsOk && (
            <FacebookEstimated adsProvider={props.adsProvider} account={props.account} targeting={values} />
          )}
        </div>
      </Modal>
    </Fragment>
  );
}
