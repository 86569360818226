import { useEffect, useState } from 'react';

import Highcharts from 'highcharts';

const mouseListener = (e) => {
  for (let i = 0; i < Highcharts.charts.length; i = i + 1) {
    const chart = Highcharts.charts[i];

    if (!chart || !chart.series || chart.series[0] === undefined) {
      continue;
    }

    // Find coordinates within the chart
    const event = chart.pointer.normalize(e);
    // Get the hovered point
    const point = chart.series[0].searchPoint(event, true);

    if (point) {
      try {
        point.highlight(e);
      } catch (e) {
        // ignore undefined "series" which occurs if there are no points for specific date
      }
    }
  }
};

/**
 * Sync mouse move over each chart to align crosshairs and tooltips
 */
export const useChartMouseEvents = ({ ref, manager, loading }) => {
  useEffect(() => {
    if (ref?.current && !loading) {
      ref.current.container.current.addEventListener('mousemove', mouseListener);
    }

    return () => {
      if (ref?.current && !loading) {
        ['mousemove', 'touchmove', 'touchstart'].forEach((eventType) => {
          ref.current.container.current.removeEventListener(eventType, mouseListener);
        });
      }
    };
  }, [ref, loading]);

  /**
   * Simulate mouse over when metrics are toggled to update tooltip.
   * Sometimes chart.hoverPoints becomes null so they are saved for use next time
   */
  const [prevPoints, setPrevPoints] = useState(null);

  useEffect(() => {
    if (ref?.current && manager.data.length) {
      const points = ref.current.chart.hoverPoints;

      if (points) {
        points.forEach((p) => p.onMouseOver());
        setPrevPoints(points);
      } else if (prevPoints) {
        prevPoints.forEach((p) => p.onMouseOver());
      }

      if (!manager.selected.length) {
        ref.current.chart.tooltip.hide();
      }
    }
  }, [ref, manager.selected.length, manager.data.length, manager.actives.length]);
};
