import { ComposerTitle } from '../ComposerTitle';

import { Folder } from 'components/common/icons/Folder';

import React, { Fragment, useState } from 'react';

import { DeleteFilled, PlusOutlined } from '@ant-design/icons';

// For AntDesign widgets only:
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { Form } from 'antd';
import Button from 'antd/lib/button';
import { useForm } from 'antd/lib/form/Form';
import Popconfirm from 'antd/lib/popconfirm';
import { Utils as QbUtils } from 'react-awesome-query-builder';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { ComposerEditDynamicRedirect } from './ComposerEditDynamicRedirect';
import { InputUrl } from '../../../../../common/antd/inputs/InputUrl';

const DynamicUrlContainer = styled.div.withConfig({
  displayName: 'DynamicUrlContainer',
})`
  background-color: rgba(251, 251, 251, 0.8);
  border: 1px solid #eee;
  padding: 0.7em;
  margin-bottom: 0.5em;
`;

const ComposerEditDynamicRedirectsContainer = styled.div.withConfig({
  displayName: 'ComposerEditDynamicRedirects',
})`
  max-width: 600px;
  position: relative;
  .delete-btn {
    position: absolute;
    z-index: 99;
    right: 0.5em;
    width: 24px;
    height: 24px;
    padding: 0px 0;
    font-size: 14px;
    border-radius: 2px;
  }
`;

export function ComposerEditDynamicRedirects(props: { redirect: any, onChange: Function }) {
  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const [state, updateState] = useImmer({
    values: { dynamicUrls: props.redirect.dynamicUrls },
  });
  const [form] = useForm();

  function onChange(values: any, allValues: any) {
    console.log('values', values, allValues);
    updateState((draft) => {
      draft.values = form.getFieldsValue();
    });
    const redirect = { ...props.redirect };

    redirect.dynamicUrls = form.getFieldsValue().dynamicUrls;
    props.onChange({ redirect });
  }

  return (
    <ComposerEditDynamicRedirectsContainer>
      <div
        className="composer__line composer__dropdowns"
        // style={{ borderBottom: "0px", marginBottom: "0px" }}
      >
        <ComposerTitle
          renderIcon={() => <Folder />}
          title={getIntlStrings('composer.dynamicRedirect.title')}
          help={getIntlStrings('composer.dynamicRedirect.help')}
          setDropDownOpen={setDropDownOpen}
          showToggle={true}
        />
        {isDropDownOpen && (
          <>
            <br />
            <div className="composer__line_grid">
              <Form onValuesChange={onChange} form={form} initialValues={state.values} layout="vertical">
                <Form.List name="dynamicUrls">
                  {(fields, { add, remove }, { errors }) => (
                    <Fragment>
                      {fields.map((field, index) => (
                        <DynamicUrlContainer key={field.key}>
                          <Popconfirm
                            okType="danger"
                            title="Delete"
                            onConfirm={() => {
                              remove(field.name);
                            }}
                            okText="Ok"
                            cancelText="Cancel"
                          >
                            <Button danger={true} className="delete-btn" type="primary">
                              <DeleteFilled />
                            </Button>
                          </Popconfirm>
                          <Form.Item noStyle>
                            <Form.Item
                              label={getIntlStrings('composer.dynamicRedirect.url')}
                              fieldKey={field.fieldKey}
                              name={[field.name, 'url']}
                            >
                              <InputUrl />
                            </Form.Item>
                            <Form.Item
                              label={getIntlStrings('composer.dynamicRedirect.rule')}
                              fieldKey={field.fieldKey}
                              name={[field.name, 'rule']}
                            >
                              <ComposerEditDynamicRedirect />
                            </Form.Item>
                          </Form.Item>
                        </DynamicUrlContainer>
                      ))}
                      <Button
                        type="dashed"
                        onClick={() =>
                          add({
                            rule: {
                              json: { id: QbUtils.uuid(), type: 'group' },
                              query: '',
                            },
                          })
                        }
                        icon={<PlusOutlined />}
                      >
                        {getIntlStrings('composer.dynamicRedirect.addRule')}
                      </Button>
                    </Fragment>
                  )}
                </Form.List>
              </Form>
            </div>
          </>
        )}
      </div>
    </ComposerEditDynamicRedirectsContainer>
  );
}
