import React from 'react';

import styled from 'styled-components';

import Notices from '../../common/Notices';
import { LogoText } from '../../layout/LogoText';

const Style = styled.div`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  background: #0e5396;

  color: #ffffff;
  height: 100%;
  width: 100%;
  overflow: auto;
  .center-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .description {
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 66px;
      font-size: 16px;
      line-height: 28px;
      font-weight: 600;
    }
    .center {
      max-width: 1000px;
    }
    .title {
      font-weight: 600;
      font-size: 43px;
      line-height: 59px;
      margin-bottom: 50px;
      text-align: center;
      color: #ffffff;
    }

    .card {
      .ant-card-head-title {
        text-align: left;
      }
      margin-bottom: 50px;
      padding: 43px;
    }

    .ant-input,
    .ant-select,
    button {
      height: 45px;
      min-width: 200px;
    }

    .ant-form-vertical {
      gap: 25px;
    }

    .ant-select-selector {
      height: 100% !important;
    }

    .ant-form {
      width: 100%;
      margin: auto;
    }

    .ant-card-head {
      margin-top: -30px;
    }

    .ant-card-body {
      padding: 0;
      padding-top: 23px;
    }

    .ant-btn-primary {
      background: #0e5396;
      border-color: #0e5396;
      min-width: 205px;
    }

    .ant-form-item-explain {
      font-weight: 600;
      font-size: 14px;
      color: #0e5396;
    }

    button {
      border-radius: 3px;
    }
  }
  .exit {
    color: white !important;
  }
  .header {
    padding: 50px !important;
    justify-content: space-between;
    display: flex;
    .right {
      float: right;
    }
  }
`;

export function AmazonOAuthErrorContainer({ children, title, description }) {
  return (
    <Style>
      <Notices />
      <div className="header">
        <div style={{ height: '43px', width: '160px' }}>
          <LogoText />
        </div>
      </div>
      <div className="center-container">
        <div className="center">
          <h1 className="title">{title}</h1>
          <div className="description">{description}</div>
          {children}
        </div>
      </div>
    </Style>
  );
}
