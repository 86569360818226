import { storeVersion } from './store';

export function version(state: stateT, action: actionT): stateT {
  // STORE
  const initialFrontState: state = {
    version: storeVersion,
  };

  if (!state) {
    state = initialFrontState;
  }

  return state;
}
