import React from 'react';

export function SignupSuccess() {
  return (
    <svg
      style={{ margin: 'auto' }}
      xmlns="http://www.w3.org/2000/svg"
      width="287"
      height="258"
      fill="none"
      viewBox="0 0 287 258"
    >
      <path
        fill="#3F3D56"
        d="M284.482 98.664a.5.5 0 01-.287-.09L144.945 1.458a2.523 2.523 0 00-2.884.005L3.814 98.573a.503.503 0 01-.785-.5.504.504 0 01.206-.326L141.482.637a3.532 3.532 0 014.038-.006l139.25 97.115a.507.507 0 01.193.565.501.501 0 01-.481.353z"
      ></path>
      <path
        fill="#E6E6E6"
        d="M11.714 102.108L143.639 4.195l132.926 104.879-126.129 74.878-68.477-15.631-70.245-66.213z"
      ></path>
      <path
        fill="#1A21FF"
        d="M87.74 230.2H22.564a3.032 3.032 0 01-2.156-.892 3.046 3.046 0 01-.66-3.325 3.05 3.05 0 011.65-1.65c.37-.153.766-.232 1.166-.231h65.174a3.032 3.032 0 012.156.891 3.05 3.05 0 01-2.156 5.207zM45.948 217.091H22.565a3.05 3.05 0 01-2.156-.892 3.05 3.05 0 012.156-5.207h23.383a3.051 3.051 0 012.156.892 3.04 3.04 0 01.893 2.157 3.052 3.052 0 01-3.049 3.05z"
      ></path>
      <path
        fill="#fff"
        d="M145.494 156.642a3.811 3.811 0 01-1.445-.283l-81.362-33.808V16.727a3.536 3.536 0 011.034-2.495 3.526 3.526 0 012.49-1.035H222.3a3.535 3.535 0 013.525 3.53v105.898l-.153.065-78.689 33.652a3.84 3.84 0 01-1.488.3z"
      ></path>
      <path
        fill="#3F3D56"
        d="M145.494 156.894a4.048 4.048 0 01-1.54-.302l-81.519-33.873V16.727a3.789 3.789 0 011.108-2.673 3.778 3.778 0 012.669-1.109h156.087c1.002.001 1.961.4 2.669 1.11a3.793 3.793 0 011.108 2.672v106.064l-78.995 33.783a4.061 4.061 0 01-1.587.32zm-82.052-34.848l80.896 33.614a3.08 3.08 0 002.35-.014l78.381-33.52V16.727a2.772 2.772 0 00-2.77-2.773H66.212a2.77 2.77 0 00-1.958.813 2.779 2.779 0 00-.812 1.96v105.319z"
      ></path>
      <path
        fill="#3F3D56"
        d="M283.475 97.656h-.1l-57.803 24.717-79.086 33.819a2.558 2.558 0 01-1.954.01l-81.593-33.899L3.716 97.697l-.09-.04h-.101a3.526 3.526 0 00-2.492 1.034A3.536 3.536 0 000 101.186v153.285A3.535 3.535 0 003.525 258h279.95a3.526 3.526 0 003.525-3.529V101.186a3.528 3.528 0 00-3.525-3.53zm2.518 156.815a2.524 2.524 0 01-2.518 2.521H3.525a2.524 2.524 0 01-2.518-2.521V101.186a2.53 2.53 0 012.422-2.521l59.51 24.727 81.206 33.743a3.58 3.58 0 002.739-.015l78.688-33.652 58.004-24.803c.65.028 1.263.305 1.713.775.45.47.702 1.095.704 1.746v153.285z"
      ></path>
      <path
        fill="#1A21FF"
        d="M144.681 126.636a13.831 13.831 0 01-8.331-2.772l-.149-.112-31.374-24.055a13.948 13.948 0 01-5.341-9.249 13.978 13.978 0 012.754-10.32 13.947 13.947 0 019.235-5.35 13.918 13.918 0 0110.307 2.759l20.322 15.604 48.023-62.719a13.947 13.947 0 019.237-5.345 13.925 13.925 0 0110.306 2.762l-.298.406.306-.4a13.98 13.98 0 015.331 9.25 13.993 13.993 0 01-2.751 10.32l-56.488 73.773a13.937 13.937 0 01-11.089 5.448z"
      ></path>
    </svg>
  );
}
