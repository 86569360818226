import styled from 'styled-components';

import { useLocale } from 'locales/LocalizationWrapper';

const Style = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const PageNotAvailable = () => {
  const { getIntlStrings } = useLocale();

  return <Style>{getIntlStrings('asinAudit.pageNotAvailable')}</Style>;
};
