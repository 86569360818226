import React, { Fragment, useEffect, useState } from 'react';

import { Select, Tag } from 'antd';
import _, { debounce } from 'lodash';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { sd } from 'lib/safe';

import { searchFacebookLocale } from '../../../../lib/facebookPlateformApi';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

const HideSearch = styled.div`
  .ant-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 250px;
  }

  .ant-select-item-option-active {
  }
`;

/**
 * Facebook Language selector
 * @param account
 * @param value
 * @param onChange
 * @param adsProvider
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookLanguagesSelector(props: {
  account: accountT,
  value: string[],
  onChange: any,
  adsProvider: adsProviderT,
}) {
  const [options, setOptions] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(undefined);

  async function onChange(keys) {
    const selected = keys
      .map((key) => (allLanguages || []).filter((o) => !!o).find((p) => `${p.key}` === `${key}`))
      .filter((o) => !!o);

    setSelected(selected);
    props.onChange(selected);
  }
  const debouncedOnChange = debounce(onChange, 1000);

  async function load(v) {
    setLoading(true);

    try {
      const languages = await searchFacebookLocale(localStorage, axios, props.adsProvider, v);
      const newOptions = [...languages, ...selected];

      setAllLanguages(_.uniqBy([...allLanguages, ...newOptions], 'key'));

      if (Array.isArray(languages)) {
        setOptions(_.uniqBy(newOptions, 'key'));
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }

    setLoading(false);
  }

  const debouncedLoad = debounce(load, 2000);

  useEffect(() => {
    load('').then();

    if (allLanguages.length === 0) {
      setAllLanguages([...allLanguages, ...sd(props.value, []).filter((v) => !!v)]);
      setOptions([...options, ...sd(props.value, []).filter((v) => !!v)]);
      setDefaultValue(
        sd(props.value, [])
          .filter((v) => !!v)
          .map((v) => v.key),
      );
      setSelected(
        sd(props.value, [])
          .filter((v) => !!v)
          .map((v) => v.key),
      );
    }
  }, [props.value]);

  return (
    <Fragment>
      {defaultValue && (
        <Select
          disabled={loading}
          size="large"
          mode="tags"
          placeholder="Select at least one location"
          allowClear
          loading={loading}
          defaultValue={defaultValue}
          showSearch={false}
          onChange={debouncedOnChange}
          dropdownRender={(c) => <HideSearch>{c}</HideSearch>}
          tagRender={(v) => {
            const newLanguage = (options.filter((o) => !!o).find((o) => `${o.key}` === `${v.value}`) || {}).name;

            if (!newLanguage) {
              return <Fragment></Fragment>;
            }

            return <Tag closable={false}>{newLanguage}</Tag>;
          }}
          onClear={() => {
            setOptions([]);
            setAllLanguages([]);
          }}
          filterOption={false}
          onSearch={debouncedLoad}
        >
          {loading && <SmallCompactPlaceholder />}
          {!loading && (
            <Fragment>
              {_.uniqBy(options, 'key')
                .filter((l) => !!l)
                .map((l) => (
                  <Select.Option key={l.key} id={l.key} className="visible" value={l.key}>
                    {l.name}
                  </Select.Option>
                ))}
            </Fragment>
          )}
        </Select>
      )}
    </Fragment>
  );
}
