import React, { Component } from 'react';

import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';

import { Actions } from 'actions';
import { Delete, Edit } from 'components/common/icons';
import { Plus } from 'components/common/icons/Plus';
import { withDragDropContext } from 'lib/dnd';
import { s } from 'lib/safe';
import { store } from 'stores';

import CampaignMenu from './CampaignMenu';
import { CampaignRedirectsCount } from './CampaignRedirectsCount';
import CampaignRedirectsDragDrop, { DropIndicator } from './CampaignRedirectsDragDropTarget';
import { CampaignName } from './CampaignsName';
import { Buttonize, CampaignContainer, CampaignToggle, IconInsideButton } from './Common';
import { SubCampaignsList } from './SubCampaignList';
import { ALL_REDIRECTS } from '../../../../../consts/consts';

type internalPropsT = {
  campaigns: campaignT[],
  loadCampaigns: Function,
  reOrderCampaigns: Function,
};
type internalStateT = {
  showSubCampaigns: boolean,
};
const defaultState: internalStateT = { showSubCampaigns: true };

const CampaignContainerHeader = styled.span.withConfig({
  displayName: 'CampaignContainerHeader',
})`
  margin-left: ${(props) => (props.big ? '10px' : '0')};
  display: flex;
  align-items: center;
  height: 33px;
  width: 203px;
  margin-right: 6px;
  justify-content: start;
`;

class CampaignTreeElementNoStyle extends Component<internalPropsT, internalStateT> {
  state = { ...defaultState };
  static TYPE = 'CampaignTreeElement';

  render() {
    const {
      campaign,
      hideSubCampaigns,
      selectedCampaignId,
      selectedSubCampaignId,
      reOrderCampaigns,
      toggleTree,
      displayIcon,
    } = this.props;

    const renderIconPlus = () => {
      if (!displayIcon) {
        return null;
      }

      return (
        <>
          {campaign.id === 'ALL' && (
            <IconInsideButton>
              <Plus
                onClick={() =>
                  store.dispatch(
                    Actions.front.audienceBuilder.campaigns.add.show.request({
                      parent: campaign,
                    }),
                  )
                }
              />
            </IconInsideButton>
          )}
        </>
      );
    };

    return (
      <CampaignContainer>
        <CampaignRedirectsDragDrop
          campaign={campaign}
          subCampaign={undefined}
          renderChildren={(isOver, canDrop) => (
            <>
              <CampaignContainerHeader>
                {!!isOver && canDrop && <DropIndicator />}
                {!(isOver && canDrop) && campaign.id !== ALL_REDIRECTS && (
                  <Buttonize>
                    <CampaignToggle
                      style={{
                        opacity: campaign.subCampaigns.length > 0 ? 1 : 0.2,
                      }}
                      onClick={toggleTree}
                      hideSubCampaigns={hideSubCampaigns}
                    />
                  </Buttonize>
                )}
                <CampaignName
                  big={campaign.id === ALL_REDIRECTS}
                  campaign={campaign}
                  renderIcon={() => renderIconPlus()}
                />

                <CampaignRedirectsCount
                  campaignId={campaign.id}
                  selectedCampaignId={selectedCampaignId}
                  selectedSubCampaignId={selectedSubCampaignId}
                />

                {campaign.id && campaign.id !== 'ALL' && (
                  <CampaignMenu visible={campaign.id === ALL_REDIRECTS}>
                    <IconInsideButton>
                      <Plus
                        onClick={() =>
                          store.dispatch(
                            Actions.front.audienceBuilder.campaigns.add.show.request({
                              parent: campaign,
                            }),
                          )
                        }
                      />
                    </IconInsideButton>
                    {campaign.id && campaign.id !== ALL_REDIRECTS && (
                      <>
                        {/* <IconInsideButton>*/}
                        {/* <Analytics*/}
                        {/* onClick={() =>*/}
                        {/* store.dispatch(*/}
                        {/* Actions.front.analytics.navigate.request({ campaign })*/}
                        {/* )*/}
                        {/* }*/}
                        {/* />*/}
                        {/* </IconInsideButton>*/}
                        <IconInsideButton>
                          <Edit
                            onClick={() =>
                              store.dispatch(
                                Actions.front.audienceBuilder.campaigns.edit.show.request({
                                  campaignOrSub: campaign,
                                }),
                              )
                            }
                          />
                        </IconInsideButton>
                        <IconInsideButton>
                          <Delete
                            onClick={() =>
                              store.dispatch(
                                Actions.front.audienceBuilder.campaigns.delete.show.request({
                                  campaignOrSub: campaign,
                                }),
                              )
                            }
                          />
                        </IconInsideButton>
                      </>
                    )}
                  </CampaignMenu>
                )}
                {campaign.id === 'ALL' && <CampaignMenu />}
              </CampaignContainerHeader>
            </>
          )}
        />
        {!hideSubCampaigns && (
          <SubCampaignsList
            distance={10}
            campaign={campaign}
            subCampaigns={campaign.subCampaigns}
            onSortEnd={({ oldIndex, newIndex }) => {
              const sortedSubCampaigns = arrayMove(campaign.subCampaigns, oldIndex, newIndex);

              sortedSubCampaigns.forEach((subCampaign, index) => {
                subCampaign.position = index;
              });
              campaign.subCampaigns = sortedSubCampaigns;
              this.setState({
                subCampaigns: sortedSubCampaigns,
              });
              reOrderCampaigns(campaign);
            }}
            axis="y"
            lockAxis="y"
            useDragHandle={true}
          />
        )}
      </CampaignContainer>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT, ownProps) => {
  const result = {
    hideSubCampaigns: s(s(state.front.campaignsTreeConfig).configs[ownProps.campaign.id]).hideSubCampaigns,
  };

  return result;
};

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  toggleTree: () =>
    dispatch(
      Actions.front.audienceBuilder.campaigns.toggleTree.request({
        campaignId: ownProps.campaign.id,
      }),
    ),
});

const reduxWrapper = connect(stateToProps, dispatchToProps, null);

export default reduxWrapper(withDragDropContext(CampaignTreeElementNoStyle));
