import { WorkerAction } from '../framework/workerAction';

export default {
  form: {
    update: new WorkerAction({ name: 'FRONT_SIGNUP_FORM_UPDATE' }, {}),
    successfulCaptcha: new WorkerAction({
      name: 'FRONT_SIGNUP_FORM_SUCCESSFUL-CAPTCHA',
    }),
  },
};
