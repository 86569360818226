import produce from 'immer';

import { Actions } from 'actions';
import { mustBeDisplayAccordingToFilters } from 'lib/redirects';
import { s, sd } from 'lib/safe';

import { ALL_REDIRECTS_FOR_CAMPAIGN } from '../../consts/consts';

export type filterT = {
  tags?: Array<string>,
  searchField?: string,
  search?: string,
  sort?: string,
  from?: string,
  to?: string,
  start?: number,
  limit?: number,
  cookieConsent?: 'any' | 'true' | 'false',
};

type stateT = {
  +asError: boolean,
  +errorMessage: string,
  +loading: boolean,
  +filter: filter,
  +redirects: Array<redirectT>,
  +selected: Array<string>,
  +nbRedirects: number,
  +nbClicks: number,
  +currentStart: number,
};

// ACTIONS

type dataT = {
  redirects: Array<redirectT>,
  total: number,
  totalClicks: number,
};
type errorT = { errorMessage: string };
export type actionT =
  | { type: 'LOAD_REDIRECTS', filter: filter }
  | { type: 'LOAD_MORE_REDIRECTS' }
  | {
      type: 'LOAD_REDIRECTS_SUCCESS',
      data: data,
      options: { merge?: boolean, append?: boolean },
    }
  | { type: 'LOAD_REDIRECTS_ERROR', error: error }
  | {
      type: 'UPDATE_REDIRECTS_CLICKS',
      clicks: Array<{ id: string, clicks: number }>,
    }
  | { type: 'TOGGLE_REDIRECT', id: string, allowMulti: boolean }
  | { type: 'UPDATE_REDIRECT', redirect: redirectT }
  | { type: 'DELETE_REDIRECTS', redirectsIds: Array<string> };

export function loadRedirects(filter: filterT): action {
  return {
    type: 'LOAD_REDIRECTS',
    filter,
  };
}

export function loadMoreRedirects(): action {
  return {
    type: 'LOAD_MORE_REDIRECTS',
  };
}

export function loadRedirectsSuccess(data: dataT, options: { merge?: boolean, append?: boolean } = {}): action {
  return {
    type: 'LOAD_REDIRECTS_SUCCESS',
    data,
    options,
  };
}

export function loadRedirectsError(error: errorT): action {
  return {
    type: 'LOAD_REDIRECTS_ERROR',
    error,
  };
}

export function toggleRedirect(id: string, allowMulti: boolean): action {
  return {
    type: 'TOGGLE_REDIRECT',
    id,
    allowMulti,
  };
}

export function updateRedirect(redirect: redirectT): action {
  return {
    type: 'UPDATE_REDIRECT',
    redirect,
  };
}

export function abRedirects(state: stateT, action: actionT): state {
  // STORE
  const initialRedirectsState: state = {
    asError: false,
    errorMessage: '',
    loading: false,
    filter: {},
    redirects: [],
    selected: [],
    nbClicks: -1,
    nbRedirects: -1,
    currentStart: 0,
  };

  if (!state) {
    state = initialRedirectsState;
  }

  switch (action.type) {
    case Actions.api.audienceBuilder.redirect.load.request().type:
      return produce(state, (draft) => {
        draft.loading = true;
      });

    case Actions.api.audienceBuilder.redirect.load.success().type:
    case 'LOAD_REDIRECTS_SUCCESS':
      const { payload } = action;

      let selected = [];

      const eventsEntries = Object.entries(s(payload.events));
      const events = eventsEntries.map((e) => ({ name: e[0], value: e[1] })).sort((a, b) => b.value - a.value);

      selected = state.selected;

      return {
        ...initialRedirectsState,
        loading: false,
        redirects: payload.redirects,
        selected,
        nbRedirects: payload.total,
        nbClicks: payload.totalClicks,
        events,
        revenues: payload.revenues,
        adsBudgets: payload.adsBudgets,
        filter: state.filter,
        currentStart: payload.length,
      };

    case 'LOAD_REDIRECTS_ERROR':
      return {
        ...state,
        loading: false,
        asError: true,
        errorMessage: action.error.errorMessage,
      };

    case 'UPDATE_REDIRECT':
      const index = state.redirects.map((l) => l.id).indexOf(action.redirect.id);

      if (index >= 0) {
        const newRedirects = state.redirects.slice(0);

        // Don't override clicks
        if (action.redirect.clicks === -1) {
          newRedirects.splice(index, 1, {
            ...action.redirect,
            clicks: newRedirects[index].clicks,
          });
        } else {
          newRedirects.splice(index, 1, action.redirect);
        }

        return {
          ...state,
          redirects: newRedirects,
        };
      }

      return state;
    case Actions.api.audienceBuilder.redirect.delete.success().type: {
      const nbDeleteRedirects = (action.payload.redirectsIds || [action.payload.redirect.id]).length;
      const newRedirects = state.redirects.slice(0);

      (action.payload.redirectsIds || [action.payload.redirect.id]).forEach((redirectId) => {
        const indexD = newRedirects.map((l) => l.id).indexOf(redirectId);

        newRedirects.splice(indexD, 1);
      });

      return {
        ...state,
        nbRedirects: state.nbRedirects - nbDeleteRedirects,
        redirects: newRedirects,
        selected: [],
      };
    }

    case Actions.api.audienceBuilder.campaigns.addRedirects.success().type:
      return {
        ...state,
        redirects: [],
        selected: [],
      };

    // Update redirect displayed on the list by the one changed on the composer

    case Actions.api.audienceBuilder.redirect.editSelected.success().type: {
      const { redirects } = action.payload;

      if (!redirects) {
        throw new Error('Unable to update current list of redirects on front state because redirects is undefined');
      }

      return produce(state, (draft) => {
        const newRedirects = state.redirects.slice(0);

        for (const redirect of redirects) {
          const index = state.redirects.map((l) => l.id).indexOf(redirect.id);

          if (index >= 0) {
            // if campaign changed, we reload all redirects
            if (mustBeDisplayAccordingToFilters(state, redirect)) {
              newRedirects.splice(index, 1, redirect);
            } else {
              newRedirects.splice(index, 1);
            }
          } else {
            newRedirects.push(redirect);
          }
        }

        draft.redirects = newRedirects;
      });
    }
    case Actions.api.audienceBuilder.redirect.edit.success().type: {
      const { redirect } = action.payload;

      if (!redirect) {
        throw new Error('Unable to update current redirect on front state because redirect is undefined');
      }

      const index = state.redirects.map((l) => l.id).indexOf(redirect.id);
      const newRedirects = state.redirects.slice(0);

      if (index >= 0) {
        // if campaign changed, we reload all redirects
        if (mustBeDisplayAccordingToFilters(state, redirect)) {
          newRedirects.splice(index, 1, redirect);
        } else {
          newRedirects.splice(index, 1);
        }
      } else {
        newRedirects.push(redirect);
      }

      return {
        ...state,
        redirects: newRedirects,
      };
    }

    case Actions.api.audienceBuilder.redirect.sort.request().type: {
      const { filter } = state;

      return {
        ...state,
        filter: {
          ...filter,
          start: 0,
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.loadNext.success().type: {
      const { filter } = state;
      const { start } = action.payload;

      return {
        ...state,
        filter: {
          ...filter,
          start,
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.loadPrev.success().type: {
      const { filter } = state;
      const { start } = action.payload;

      return {
        ...state,
        filter: {
          ...filter,
          start,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.filter.update.request().type: {
      const filter = action.payload;

      return {
        ...state,
        filter: {
          ...state.filter,
          ...filter,
        },
      };
    }

    case Actions.front.audienceBuilder.campaigns.select.request().type:
      const { filter } = state;

      return {
        ...state,
        filter: {
          ...filter,
          sort: '',
          from: undefined,
          to: undefined,
          selectedCampaignId: action.payload.campaignId,
          selectedSubCampaignId: action.payload.subCampaignId
            ? action.payload.subCampaignId
            : ALL_REDIRECTS_FOR_CAMPAIGN,
        },
      };

    default:
      return state;
  }
}

// Selectors
export function selectedRedirects(state: stateT): Array<redirectT> {
  state.redirects = sd(state.redirects, []);

  return state.redirects.filter((redirect) => state.selected.indexOf(redirect.id) >= 0);
}
