import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import type { actionT } from 'stores/accounts';
import { displayTrialHeader } from 'stores/subscription';

import { DataOutdatedBanner } from './Banners/DataOutdatedBanner';
import { Impersonated } from './Banners/Impersonate';
import PixelDisabledHeader from './Banners/PixelDisabledHeader';
import TrialHeader from './Banners/TrialHeader';
import Bar from './Bar/Bar';
import { LeftSidePanel } from './LeftSidePanel';
import NewLogoIconWhite from './NewLogoIconWhite';
import Top from './Top';
import DeleteModal from '../common/modals/DeleteModal';
import Notices from '../common/Notices';

const LogoContainer = styled.div.withConfig({ displayName: 'LogoContainer' })`
  .logo {
    background: var(--bar-color) !important;
  }
  .logo > svg {
    height: 32px;
  }

  grid-area: logo;
`;

const BarContainer = styled.div.withConfig({ displayName: 'BarContainer' })`
  grid-area: bar;
`;

const Style = styled.div`
  display: grid;
  grid-template-columns: 4rem min-content 1fr;
  grid-template-rows: 4rem 1fr;
  grid-template-areas:
    'logo left top'
    'bar left content';

  ${(props) =>
    props.$showBanners &&
    `
    grid-template-rows: min-content 4rem 1fr;
    grid-template-areas:
      'banner banner banner'
      'logo left top'
      'bar left content';
  `}
`;

const TopContainer = styled.div.withConfig({ displayName: 'TopContainer' })`
  grid-area: top;
`;

const ContentContainer = styled.div.withConfig({
  displayName: 'ContentContainer',
})`
  grid-area: content;
  overflow: auto;
  height: 100%;
  background: var(--bg-color);
`;

const ContentContainerWitLeft = styled.div.withConfig({
  displayName: 'ContentContainer',
})`
  grid-area: content;
  overflow: auto;
  height: 100%;
  background: var(--bg-color);
  > .content {
    padding: 0;
  }
`;

const BannersContainer = styled.div`
  grid-area: banner;
`;

export function TopContent({
  user,
  children,
  title,
  accounts,
  account,
  displayModal,
  signOut,
  switchAccount,
  leftPanelContent,
  renderSubMenu,
  isBeta,
  limitations,
  hideUsage,
  hideAccountSwitcher,
}: {
  user: userT,
  children: Node,
  title: string,
  isBeta: boolean,
  renderSubMenu: Function,
  accounts: Array<accountT>,
  account: accountT,
  displayModal: boolean,
  signOut: () => void,
  switchAccount: (string) => actionT,
  leftPanelContent: any,
  limitations: any,
  +hideUsage: boolean,
  +hideAccountSwitcher: boolean,
}) {
  const { sub, impersonated, displayPixelHeader, trialHeaderDisplayed } = useSelector((state) => {
    const userState = state.user;
    const subState = state.subscription;

    let displayPixelHeader;

    if (state.subscription.sub) {
      displayPixelHeader = !!(!state.accounts.usage.firingPixels && state.accounts.usage.pixels > 0);
    }

    return {
      trialHeaderDisplayed: displayTrialHeader(subState),
      sub: subState.sub,
      impersonated: userState.impersonated,
      displayPixelHeader,
    };
  });
  const location = useLocation();

  const leftPanelVisible = location.pathname.indexOf('/ab/campaigns') >= 0;
  const showDataOutdatedBanner = location.pathname.indexOf('/adsprovider') >= 0;
  const showBanners = impersonated || displayPixelHeader || trialHeaderDisplayed || showDataOutdatedBanner;

  return (
    <Style className="main-layout" $showBanners={showBanners}>
      {showBanners && (
        <BannersContainer>
          {!displayPixelHeader && (
            <TrialHeader displayTrialHeader={trialHeaderDisplayed} sub={sub} account={account} compact={false} />
          )}
          <PixelDisabledHeader account={account} displayPixelHeader={displayPixelHeader} />
          {showDataOutdatedBanner && <DataOutdatedBanner />}
          {impersonated && <Impersonated />}
        </BannersContainer>
      )}
      <LogoContainer className="logoContainer">
        <NewLogoIconWhite style={{ justifyContent: 'center', alignItems: 'center' }} />
      </LogoContainer>
      <BarContainer className="barContainer">
        <Bar signOutTemp={signOut} account={account} user={user} sub={sub} limitations={limitations} />
      </BarContainer>
      <DeleteModal account={account} />
      <Notices />
      <TopContainer>
        <Top
          user={user}
          displayModal={displayModal}
          title={title}
          isBeta={isBeta}
          renderSubMenu={renderSubMenu}
          accounts={accounts}
          account={account}
          signOut={signOut}
          switchAccount={switchAccount}
          hideUsage={hideUsage}
          hideAccountSwitcher={hideAccountSwitcher}
        />
      </TopContainer>
      {leftPanelVisible ? (
        <>
          <ContentContainerWitLeft>{children}</ContentContainerWitLeft>
          <LeftSidePanel title={title}>{leftPanelContent}</LeftSidePanel>
        </>
      ) : (
        <ContentContainer>{children}</ContentContainer>
      )}
    </Style>
  );
}
