import './ListView.css';

import React from 'react';

import numeral from 'numeral';
import { Link } from 'react-router-dom';

export default function ListView({
  title,
  total,
  values,
}: {
  title: string,
  total: number,
  values: Array<{
    id: string,
    label: string,
    value: number,
    uniqueValue: number,
    to?: string,
  }>,
}) {
  return (
    <div className="list-view">
      <div className="list-view__header">
        <div className="list-view__header__title">{title}</div>
        <div className="list-view__header__clicks">Total</div>
        <div className="list-view__header__uclicks">Unique</div>
      </div>
      <div className="list-view__content">
        {values.map((valueItem, index) => {
          const { id, label, value, uniqueValue, to } = valueItem;

          let classes = 'list-view__line';

          if (index % 2 === 1) {
            classes = `${classes} list-view__line--odd`;
          }

          return (
            <div key={id} className={classes}>
              <div className="list-view__number">{index + 1}.</div>
              {to && (
                <div className="list-view__label">
                  <Link to={to}>{label}</Link>
                </div>
              )}
              {!to && <div className="list-view__label">{label}</div>}
              <div className="list-view__clicks">{numeral(value).format('0[.]0a')}</div>
              <div className="list-view__uclicks">
                {numeral(uniqueValue).format('0[.]0a')}
                <span className="list-view__percent">
                  ({numeral((uniqueValue * 100) / total).format('0')}
                  %)
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
