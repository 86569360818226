import styled from 'styled-components';

export const StatsContainer = styled.div.withConfig({
  displayName: 'StatsContainer',
})`
  font-size: var(--normal-size);
  display: flex;
  align-items: center;
  color: #5c5c5c;
  flex-shrink: 0;
  flex-grow: 1;
`;
