import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from '../../actions';
import {
  ALL_REDIRECTS,
  ALL_REDIRECTS_FOR_CAMPAIGN,
  UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN,
  UNCLASSIFIED_REDIRECTS,
} from '../../consts/consts';
import { getCampaignFromId, getSubCampaignFromId } from '../../lib/campaigns';
import { LocaleContext } from '../../locales/LocalizationWrapper';

const selectedStyle = {
  color: '#071629',
};
const unSelectedStyle = {};

const CampaignBreadCrumbContainer = styled.ul.withConfig({
  displayName: 'CampaignBreadCrumbContainer',
})`
  width: 100%;
`;
const CampaignBreadCrumbElement = styled.li.withConfig({
  displayName: 'CampaignBreadCrumbElement',
})`
  cursor: pointer;
  margin-right: 10px;
  color: var(--text-muted);
`;
const SubCampaignBreadCrumbElement = styled.li.withConfig({
  displayName: 'CampaignBreadCrumbElement',
})`
  cursor: pointer;
  margin-right: 10px;
  ul {
    height: 100%;
  }
  color: var(--text-muted);
`;
const SubCampaignBreadCrumbSeparator = styled.span.withConfig({
  displayName: 'SubCampaignBreadCrumbSeparator',
})`
  margin-right: 10px !important;
  :after {
    content: '>';
  }
`;

type internalPropsT = {
  value: string,
  title: string,
  placeholder: string,
  displayLabel: boolean,
};
type internalActionsT = {
  onChange: (string) => void,
};
type internalStateT = {};
const defaultState: internalStateT = {};

class AbstractCampaignBreadCrumb extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = defaultState;

  getStyle(campaignId: string, subCampaignId: string) {
    const { selectedSubCampaignId, selectedCampaignId } = this.props;

    return selectedCampaignId === campaignId && selectedSubCampaignId === subCampaignId
      ? selectedStyle
      : unSelectedStyle;
  }

  static contextType = LocaleContext;

  render() {
    const { getIntlStrings } = this.context;
    const { selectedCampaignId, selectedSubCampaignId, selectCampaign } = this.props;
    const selectedCampaign = getCampaignFromId(selectedCampaignId);
    const selectedSubCampaign = getSubCampaignFromId(selectedSubCampaignId);
    const selectedCampaignName =
      selectedCampaign.name === getIntlStrings('campaigns.allCampaigns')
        ? getIntlStrings('campaigns.allLinks')
        : selectedCampaign.name;
    const selectedSubCampaignName = selectedSubCampaign.name;

    return (
      <CampaignBreadCrumbContainer>
        {!!selectedCampaignName && (
          <CampaignBreadCrumbElement
            style={this.getStyle(selectedCampaignId, ALL_REDIRECTS)}
            onClick={() => selectCampaign(selectedCampaignId, ALL_REDIRECTS)}
          >
            {selectedCampaignName}
          </CampaignBreadCrumbElement>
        )}
        {!!selectedSubCampaignName && <SubCampaignBreadCrumbSeparator />}
        {!!selectedCampaignName && !!selectedSubCampaignName && (
          <SubCampaignBreadCrumbElement
            style={this.getStyle(selectedCampaignId, selectedSubCampaignId)}
            onClick={() => selectCampaign(selectedCampaignId, selectedSubCampaignId)}
          >
            {selectedSubCampaignName}
          </SubCampaignBreadCrumbElement>
        )}
        {!!selectedCampaignName && !selectedSubCampaignName && (
          <SubCampaignBreadCrumbElement
            onClick={() =>
              selectCampaign(
                selectedCampaignId ? selectedCampaignId : ALL_REDIRECTS,
                UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN,
              )
            }
            style={this.getStyle(selectedCampaignId, UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN)}
          >
            {getIntlStrings('redirects.unclassified')}
          </SubCampaignBreadCrumbElement>
        )}
        {!selectedCampaignName && (
          <>
            <SubCampaignBreadCrumbElement
              style={this.getStyle(ALL_REDIRECTS, ALL_REDIRECTS_FOR_CAMPAIGN)}
              onClick={() => selectCampaign(ALL_REDIRECTS, ALL_REDIRECTS_FOR_CAMPAIGN)}
            >
              {getIntlStrings('redirects.allRedirects')}
            </SubCampaignBreadCrumbElement>
            <SubCampaignBreadCrumbElement
              style={this.getStyle(UNCLASSIFIED_REDIRECTS, UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN)}
              onClick={() => selectCampaign(UNCLASSIFIED_REDIRECTS, UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN)}
            >
              {getIntlStrings('redirects.unclassified')}
            </SubCampaignBreadCrumbElement>
          </>
        )}
      </CampaignBreadCrumbContainer>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  selectedCampaignId: state.campaigns.selectedCampaignId,
  selectedSubCampaignId: state.campaigns.selectedSubCampaignId,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  selectCampaign: (campaignId, subCampaignId) =>
    dispatch(
      Actions.front.audienceBuilder.campaigns.select.request({
        campaignId,
        subCampaignId,
      }),
    ),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) =>
  _.isEqual(prev.campaigns.selectedCampaignId, next.campaigns.selectedCampaignId) &&
  _.isEqual(prev.campaigns.selectedSubCampaignId, next.campaigns.selectedSubCampaignId);

// noinspection JSUnusedGlobalSymbols
export const CampaignBreadCrumb = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
})(AbstractCampaignBreadCrumb);
