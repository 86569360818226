import React from 'react';

export const TitleWithInstructions = ({ title, instructions }) => (
  <>
    <div
      style={{
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#262626',
        marginBottom: '10px',
      }}
    >
      {title}
    </div>

    {instructions && (
      <div
        style={{
          backgroundColor: '#fafafa',
          padding: '10px',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '11px',
          lineHeight: '14px',
          color: '#000000',
          marginBottom: '10px',
        }}
      >
        {instructions}
      </div>
    )}
  </>
);
