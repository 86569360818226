import './BoxSwitcher.css';

import React from 'react';

import toggleLeft from '../../../images/toggle/toggle-left.svg';
import toggleRight from '../../../images/toggle/toggle-right.svg';

export type status = 'chart' | 'list' | 'map';

export default function BoxSwitcher({
  nextStatus,
  handleSwitchStatus,
}: {
  nextStatus: status,
  handleSwitchStatus: () => void,
}) {
  let toggle = toggleLeft;

  if (nextStatus === 'list') {
    toggle = toggleRight;
  }

  return (
    <div className="box-switcher" onClick={handleSwitchStatus}>
      <div className="box-switcher__label">Switch to {nextStatus} view</div>
      <div className="box-switcher__toggle">
        <img src={toggle} alt="toggle" />
      </div>
    </div>
  );
}
