import React from 'react';

import styled from 'styled-components';

import { Plus } from 'components/common/icons/Plus';
import { getColumnPosition, isVisible } from 'lib/columns';

import { RedirectsListLineCell } from './RedirectsListLineCell';

export const Drag = styled(getCell('Drag', '13px', '16px', true))`
  text-align: center;
  transition: opacity 200ms ease-in-out;
  margin-left: 10px;
`;
export const Check = getCell('Check', '15px', '15px', true);
export const FavIcon = getCell('FavIcon', '20px');
export const Title = styled(getCell('Title', '220px'))`
  display: flex;
  align-items: center;
  span {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Url = styled(getCell('Url', '250px'))``;
export const Shorten = styled(getCell('Shorten', '225px'))`
  color: var(--pixelme-color);
`;
export const PixelCol = getCell('Pixels', '100px');
export const Clicks = getCell('Clicks', '100px');
export const Source = getCell('UtmsSource', '112px');
export const Medium = getCell('UtmsMedium', '112px');
export const Term = getCell('UtmsTerm', '112px');
export const Content = getCell('UtmsContent', '112px');
export const Campaign = getCell('UtmsCampaign', '112px');
export const Tags = styled(getCell('Tags'))`
  div {
    text-align: left !important;
  }
`;
export const Domain = getCell('Domain');
export const Slug = getCell('Slug');
export const CookieConsent = getCell('CookieConsent', '105px');
export const CreatedAt = getCell('CreatedAt', '105px');

/**
 * Return a Datatable cell that can be an header cell or a content cell
 * name: Unique name for the cell, must be the same name used inside the dropdown that allow to choose or order columns
 * width: optional width of the cell default 100px
 * height: optional height of the cell default auto
 * forceDisplay: optional if true this cell is always displayed, it ccan't be unchecked from the dropdown that allow to choose or order columns
 *
 * It returns an AbstractCopiableCell (see available parameters)
 *
 * Example of use:
 * Title = styled(getCell("Title", "220px"))`
 *
 * <Title config={config} sortable sortKey={"title"}>
 * {strings.get("redirects.redirect.title")}
 * </Title>
 */
export function getCell(name: string, width: string = '100px', height: string = 'auto', forceDisplay: boolean = false) {
  const result = styled(RedirectsListLineCell)
    .attrs({ name, forceDisplay, key: name })
    .withConfig({ displayName: name })`
    display: ${(props) => (isVisible(props) ? 'auto' : 'none')};
    order:  ${(props) => getColumnPosition(props)};
    color:  ${(props) => props.color};
    width: ${width};
    height: ${height};
    white-space: nowrap;
    text-overflow: ellipsis;
    align-self: center;
    overflow: hidden;
    align-items: center;
  `;

  result.name = name;

  return result;
}

function ButtonizeNoStyle({ children, className }) {
  return <span className={className}>{children}</span>;
}

export const IconInsideButton = styled(ButtonizeNoStyle).withConfig({
  displayName: 'IconInsideButton',
})`
  background: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 4px;
  cursor: pointer;
  :hover {
    background: rgba(46, 137, 226, 0.12);
    border-color: var(--pixelme-color);
  }
`;

export const DropIcon = styled(Plus).withConfig({
  displayName: 'DropIcon',
})`
  position: absolute;
`;
