import React from 'react';

import IconContainer from './IconContainer';

export function Plus(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <circle cx="5.5" cy="5.5" r="5.5" fill="#2E89E2" />
          <path d="M5.52441 2.30176L5.47687 9.00295" stroke="white" />
          <path d="M8.85059 5.6709L2.14933 5.6348" stroke="white" />
        </g>
      </svg>
    </IconContainer>
  );
}
