import React, { type Node } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CancelButton } from 'components/common/Button';
import { unImpersonate } from 'lib/auth';
import axios from 'lib/axios.factory';

import { BaseBanner } from './common';

const Container = styled(BaseBanner).withConfig({
  displayName: 'ContainerImpersonated ',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: red;
  background: pink;
`;

export const Impersonated = (): Node => {
  const user = useSelector((state) => state.user.user);

  return (
    <Container>
      {moment().format('DD/MM')} WARNING You are impersonating: {user.fullname} ({user.email})
      <CancelButton
        handleClick={() => {
          unImpersonate(localStorage, axios).then(() => {
            window.location = '/';
          });
        }}
      >
        Back to yourself
      </CancelButton>
    </Container>
  );
};
