import { apiSlice, TAGS } from './apiSlice';

const { ALERTS } = TAGS;

// $FlowFixMe
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAlerts: builder.query({
      query: ({ page, pageSize, status }) => ({
        url: `/alerts?page=${page}&pageSize=${pageSize}&status=${status}`,
        method: 'get',
      }),
      extraOptions: { includeAccountId: false },
      providesTags: (result) =>
        result
          ? [...result.items.map(({ id }) => ({ type: ALERTS, id })), { type: ALERTS, id: 'LIST' }]
          : [{ type: ALERTS, id: 'LIST' }],
    }),
    getAlertsCount: builder.query({
      query: ({ status }) => ({ url: `/alerts/count?status=${status}`, method: 'get' }),
      extraOptions: { includeAccountId: false },
      providesTags: (result) => (result ? [{ type: ALERTS, id: 'COUNT' }] : []),
    }),
    updateAlert: builder.mutation({
      query: ({ id, ...data }) => ({ url: `/alerts/${id}`, method: 'patch', data }),
      extraOptions: { includeAccountId: false },
      invalidatesTags: (result, error, { id }) => [
        { type: ALERTS, id: 'LIST' },
        { type: ALERTS, id: 'COUNT' },
      ],
    }),
  }),
});

export const { useGetAlertsQuery, useGetAlertsCountQuery, useUpdateAlertMutation } = extendedApiSlice;
