import React, { Fragment } from 'react';

import { Button, Divider, message, Modal } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';
import { Copy } from '../../../../common/icons';
import { IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';

export function NewAdGroupButton({ account, campaign }) {
  return (
    <Fragment>
      {campaign.macroUrl && (
        <Button
          type="primary"
          ghost={true}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            Modal.confirm({
              title: 'Create or Edit TikTok Ads and Ad Groups',
              cancelText: '',
              width: 450,
              closable: true,
              bordered: true,
              cancelButtonProps: { style: { display: 'none' } },
              okButtonProps: { size: 'large' },
              icon: <Fragment />,
              content: <CampaignInfo campaign={campaign} account={account} />,
              okText: 'OK',
              onOk() {},
            });

            return false;
          }}
        >
          New Ad Group
        </Button>
      )}
    </Fragment>
  );
}

function CampaignInfo(props: { campaign: adsCampaignT, account: accountT }) {
  return (
    <Fragment>
      <Divider
        orientation="left"
        style={{
          marginTop: 0,
          marginLeft: '-24px',
          width: 'calc( 100% + 48px)',
        }}
      ></Divider>
      <b>Campaign:</b>
      {` ${props.campaign.name}`}
      <br />
      <Divider orientation="left"></Divider>
      <b>To create more ads and ad groups:</b>
      <br />
      <div style={{ marginBottom: '1em' }}>
        Create them in TikTok as usual & use this link as the destination url: <br />
        {props.campaign.macroUrl}
        <IconInsideButton>
          <CopyToClipboard text={props.campaign.macroUrl} onCopy={() => message.success('URL copied to clipboard.')}>
            <Copy />
          </CopyToClipboard>
        </IconInsideButton>
      </div>

      <b>To edit ads and ad groups:</b>
      <br />
      <div style={{ marginBottom: '1em' }}>
        Make edits in TikTok as you normally would in TikTok. The changes will be reflected in PixelMe.
      </div>
      <div style={{ marginBottom: '1em' }}>
        <b>Do not</b> modify PixelMe's destination url as this can interfere with Amazon Attribution measurement.
      </div>

      <a href="#" target="_blank">
        Learn More
      </a>
      <br />
      <br />
    </Fragment>
  );
}
