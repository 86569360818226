import { WorkerAction } from '../../framework/workerAction';

export default {
  filter: {
    toggle: new WorkerAction({
      name: 'front.audienceBuilder.redirect.filter.toggle',
    }),
    update: new WorkerAction({
      name: 'front.audienceBuilder.redirect.filter.update',
    }),
    date: {
      toggle: new WorkerAction({
        name: 'front.audienceBuilder.redirect.filter.date.toggle',
      }),
    },
  },
  editPreview: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.redirect.editPreview.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.redirect.editPreview.hide',
    }),
  },
  create: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.redirect.create.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.redirect.create.hide',
    }),
  },
  edit: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.redirect.edit.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.redirect.edit.hide',
    }),
  },
  delete: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.redirect.delete.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.redirect.delete.hide',
    }),
  },
  bulkEdit: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.redirect.bulkEdit.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.redirect.bulkEdit.hide',
    }),
  },
  duplicate: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.redirect.duplicate.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.redirect.duplicate.hide',
    }),
  },
  url: {
    update: new WorkerAction({
      name: 'front.audienceBuilder.redirect.url.update',
    }),
  },
  update: new WorkerAction({ name: 'front.audienceBuilder.redirect.update' }),
  /* column: {
    update: new WorkerAction({ name: "front.audienceBuilder.redirect.column.update" }, {})
  },*/
  export: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.redirect.export.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.redirect.export.hide',
    }),
    updateSelected: new WorkerAction({
      name: 'front.audienceBuilder.redirect.export.updateSelected',
    }),
    feedback: {
      update: new WorkerAction({
        name: 'front.audienceBuilder.redirect.export.feedback.update',
      }),
      hide: new WorkerAction({
        name: 'front.audienceBuilder.redirect.export.feedback.hide',
      }),
    },
  },
};
