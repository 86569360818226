import React, { Fragment, useEffect, useState } from 'react';

import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';

import axios from 'lib/axios.factory';
import { YOUTUBE_CAMPAIGNS, useFeatureFlag } from 'lib/configcat';

import { AdsProviderSelector } from './AdsProviderSelector.jsx';
import { DeeplinkToggle } from './DeeplinkToggle';
import { DomainSelector } from './DomainSelector';
import { PerformanceBoosterToggle } from './PerformanceBoosterToggle.jsx';
import { PixelSelector } from './PixelSelector';
import { ProductSelector } from './ProductSelector';
import { checkCampaignName, createCampaignLink } from '../../../../lib/adsPlateformApi';
import { AddDomainButton } from '../common/AddDomainButton';
import { AddPixelButton } from '../common/AddPixelButton';
import { GenericCampaignLink } from '../GenericAdsProvider/GenericCampaignLink';

export function NewLinkButton({ account, product, style, type, ghost, size }) {
  const [visible, setVisible] = useState(false);

  const [form] = useForm();

  const { messages } = useIntl();

  const [creating, setCreating] = useState(false);
  const [rawCampaign, setRawCampaign] = useState(null);

  const [platform, setPlatform] = useState('');
  const [platforms, setPlatforms] = useState(account.platformValues.platforms);
  const [medium, setMedium] = useState('');
  const [mediums, setMediums] = useState(account.platformValues.mediums);

  const [platformSearch, setPlatformSearch] = useState('');
  const [mediumSearch, setMediumSearch] = useState('');
  const [isYoutubeAdsEnabled] = useFeatureFlag(YOUTUBE_CAMPAIGNS);
  const SPAConnected =
    account?.productProviders[0]?.spa?.connectedAt !== null && account?.productProviders[0]?.spa?.errorAt === null;

  const performanceBoosterCurrentValue = useWatch('performanceBooster', form);

  const finByKey = (arr: string[], key: string) => arr.find((i) => i.toLowerCase() === key.toLowerCase());

  const onPlatformSelect = (v) => {
    let existing = finByKey(platforms, v);

    // If platform is new, push it to the available options
    if (existing === undefined) {
      existing = v;
      setPlatforms([existing, ...platforms]);
    }

    setPlatformSearch('');
    setPlatform(existing);
  };

  const onMediumSelect = (v) => {
    let existing = finByKey(mediums, v);

    // If medium is new, push it to the available options
    if (existing === undefined) {
      existing = v;
      setMediums([existing, ...mediums]);
    }

    setMediumSearch('');
    setMedium(existing);
  };

  const handlePlatformSearch = (v) => {
    setPlatformSearch(finByKey(platforms, v) === undefined ? v : '');
  };

  const handleMediumSearch = (v) => {
    setMediumSearch(finByKey(mediums, v) === undefined ? v : '');
  };

  const nameUniquenessValidator = async (rule, value) => {
    if (!value) {
      throw new Error('Name is required');
    } else {
      await checkCampaignName(localStorage, axios, { accountId: account.id }, { name: value }, product.providerId)
        .then((response) => {})
        .catch((err) => {
          if (err.response.status === 409) {
            throw new Error('Campaign name is already taken');
          }
        });
    }
  };

  const handleFormSubmission = (values) => {
    setCreating(true);

    createCampaignLink(
      localStorage,
      axios,
      {
        accountId: account.id,
      },
      {
        // Required params
        name: values.name,
        // Optional params
        platform: platform ? platform : null,
        medium: medium ? medium : null,
        pixelsIds: values.pixelsIds ? values.pixelsIds : null,
        domain: values.domain ? values.domain : null,
        useDeepLinks: Boolean(values.useDeepLinks),
        performanceBooster: Boolean(values.performanceBooster),
        performanceBoosterAdsProviderId: values.adsProvider?.id ? values.adsProvider.id : undefined,
      },
      values.product.providerId,
      values.product.externalId,
    ).then((response) => {
      setCreating(false);
      setRawCampaign(response.rawCampaign);
    });
  };

  const handleClose = () => {
    if (rawCampaign) {
      window.location.reload();
    } else {
      setCreating(false);
      setRawCampaign(null);
      setPlatformSearch('');
      setPlatform('');
      setMediumSearch('');
      setMedium('');

      setVisible(false);
    }
  };

  // Update campaign name, whenever product, platform or medium change
  useEffect(() => {
    const campaignName = [product.externalId, platform, medium].filter(Boolean).join(' - ');

    form.setFieldsValue({ name: campaignName });
  }, [product, platform, medium]);

  return (
    <Fragment>
      <Modal
        onCancel={handleClose}
        footer={
          <>
            {rawCampaign == null && (
              <Button key="cancel" disabled={creating} onClick={handleClose}>
                Cancel
              </Button>
            )}
            <Button
              key="submit"
              type="primary"
              loading={creating}
              disabled={creating}
              onClick={rawCampaign == null ? form.submit : handleClose}
            >
              {rawCampaign == null ? 'Create' : 'OK'}
            </Button>
          </>
        }
        visible={visible}
        title={rawCampaign == null ? 'New custom link' : 'Campaign launched'}
        icon={<ExclamationCircleOutlined />}
      >
        {rawCampaign == null && (
          <Form layout="vertical" form={form} onFinish={handleFormSubmission} initialValues={{ useDeepLinks: true }}>
            <ProductSelector account={account} productId={product.id} />
            <br />

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Platform" name="platform">
                  <Select
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    style={{ width: '100%' }}
                    placeholder="Select Platform"
                    value={platform}
                    onSearch={handlePlatformSearch}
                    onSelect={onPlatformSelect}
                  >
                    {platformSearch && <Select.Option key={platformSearch}>+ Add "{platformSearch}"</Select.Option>}
                    {platforms.map((p) => (
                      <Select.Option key={p}>{p}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item label="Medium" name="medium">
                  <Select
                    showSearch={true}
                    suffixIcon={<SearchOutlined />}
                    style={{ width: '100%' }}
                    placeholder="Select Medium"
                    value={medium}
                    onSearch={handleMediumSearch}
                    onSelect={onMediumSelect}
                  >
                    {mediumSearch && <Select.Option key={mediumSearch}>+ Add "{mediumSearch}"</Select.Option>}
                    {mediums.map((m) => (
                      <Select.Option key={m}>{m}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <PixelSelector account={account} />
            <AddPixelButton accountId={account.id} />
            <DomainSelector account={account} disabledForPerformanceBooster={performanceBoosterCurrentValue} />
            <AddDomainButton accountId={account.id} disabledForPerformanceBooster={performanceBoosterCurrentValue} />
            <DeeplinkToggle account={account} defaultChecked />
            {isYoutubeAdsEnabled && (
              <>
                <PerformanceBoosterToggle account={account} spaConnected={SPAConnected} />
                {performanceBoosterCurrentValue && (
                  <AdsProviderSelector account={account} providerType="GOOGLE" form={form} />
                )}
              </>
            )}

            <Form.Item
              required
              rules={[{ validator: nameUniquenessValidator, validateTrigger: 'onSubmit' }]}
              label="Campaign name"
              name="name"
            >
              <Input name="name" />
            </Form.Item>
          </Form>
        )}
        {rawCampaign !== null && <GenericCampaignLink campaign={rawCampaign} />}
      </Modal>
      <Button
        style={style}
        type={type || 'primary'}
        ghost={ghost}
        size={size}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setVisible(true);

          return false;
        }}
      >
        {messages.locales.campaigns.newLink}
      </Button>
    </Fragment>
  );
}
