import React, { useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, notification, Select, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import _ from 'lodash';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { getAdsProviders } from '../../../../lib/adsProvider/google';
import { getFacebookImages } from '../../../../lib/facebookPlateformApi';
import { MutedText } from '../../../common/MutedText';

const Style = styled.div`
  .ant-upload-select-text,
  .ant-upload,
  .ant-btn {
    width: 100%;
  }
`;

const PhotoSelect = styled(Select)`
  height: fit-content !important;

  .ant-select-selector {
    height: fit-content !important;
  }

  .ant-select-selector img {
    width: 128px !important;
  }

  .ant-select-selection-item {
    padding: 1em !important;
  }
`;

export function FacebookImageUpload(props: {
  data: (file: UploadFile<T>) => { access_token: *, filename: string },
  account: accountT,
  adsProvider: undefined,
  onChange: any,
}) {
  const [loading, setLoading] = useState(false);
  const [fbImages, setFbImages] = useState(undefined);
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === props.adsProvider.id);

  async function load() {
    if (!fbImages && adsProvider) {
      const response = await getFacebookImages(localStorage, axios, adsProvider);

      setFbImages(response);
    }
  }

  useEffect(() => {
    load().then();
  }, [adsProvider]);

  function onChange(e: any) {
    const { file } = e;

    if (file.status === 'uploading') {
      // notification.info({message: `${file.name} file uploading.`});
    } else if (file.status === 'error') {
      setLoading(false);
      notification.warn({
        message: `${file.name} error: ${_.get(file, 'response.message')}`,
      });
    } else if (file.status === 'done' || file.status === 'success') {
      notification.success({ message: `${file.name} file uploaded.` });
      props.onChange(file.response.images[file.name].hash);
      setLoading(false);
    }
  }

  return (
    <Style>
      <PhotoSelect value={props.value} style={{ marginBottom: 24 }} onChange={(hash) => props.onChange(hash)}>
        {fbImages &&
          fbImages.map((i) => (
            <Select.Option key={i.hash} value={i.hash}>
              <img src={i.url128} alt="" />
            </Select.Option>
          ))}
      </PhotoSelect>
      <Upload
        accept="image/bmp,image/dib,image/heic,image/heif,image/iff,image/jfif,image/jp2,image/jpe,image/jpeg,image/jpg,image/png,image/psd,image/tif,image/tiff,image/wbmp,image/webp,image/xbm"
        multiple={false}
        showUploadList={false}
        loading={loading}
        onChange={onChange}
        data={async (file: UploadFile<T>) => ({
          filename: `@${file.name}`,
          access_token: adsProvider.accessToken,
        })}
        action={`https://graph.facebook.com/v19.0/${adsProvider.externalAccountId}/adimages`}
      >
        <Button icon={<UploadOutlined />}>Or Upload</Button>
      </Upload>
      <MutedText>
        <span style={{ fontSize: 9 }}>
          <b>Recommended image specs:</b>
          <br />
          1080 x 1080 pixels, 1:1 image ratio
          <br />
          By default your ad will use the square (1:1) or landscape (1.91:1) mode according to the ratio of the image
          you select.
          <br />
          To use a square image but have a landscape (1.91:1) ad, please crop the image using the image editor.
          <br />
        </span>
      </MutedText>
    </Style>
  );
}
