import React, { useEffect, useState } from 'react';

import { Alert, Button, Divider, Empty, message, Modal, Result } from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { amazonSellerCentralUrls } from 'assets/amazon';
import { useFeatureFlag, PERFORMANCE_BOOSTER } from 'lib/configcat';

import { AmazonLoginButton } from './AmazonLoginPage';
import { AmazonLogoutButton } from './AmazonLogoutButton';
import './ProductProviderAccounts.css';
import { NewProductProviderButton } from './common/NewProductProviderButton';
import { BoxShadow } from '../../BoxShadow';
import { Amazon } from '../../common/icons/Amazon';
import { MutedText } from '../../common/MutedText';
import { useQueryParams } from 'hooks/useQueryParams';
import { WIDGET_API_CONNECTION } from 'consts/consts';

const Container = styled.div`
  width: 100%;
  margin-left: -1em;
  max-width: 760px;
`;

const colors = {
  green: '#2EC1B3',
  red: '#FF0000',
};

const SpaSuccess = (props: { accountID: string }) => {
  const history = useHistory();

  const redirect = () => history.push(`/${props.accountID}/adsprovider`);

  const widgetFromLocalStorage = localStorage.getItem('hasSPAWidgetWorkFlowStarted');

  useEffect(() => {
    if (widgetFromLocalStorage) {
      message.success('Amazon Seller Central account linked successfully, please return to the Widget', 3);
      localStorage.removeItem('hasSPAWidgetWorkFlowStarted');
    }
  }, [widgetFromLocalStorage]);

  return (
    <>
      <Alert
        message={
          <>
            Account Successfully connected.{' '}
            <>
              <span className="alert__link" onClick={redirect}>
                Create a campaign
              </span>{' '}
              in the Ads Provider
            </>
          </>
        }
        type="success"
        showIcon
      />
      <br />
    </>
  );
};

export function ProductProviderAccounts(props: {
  providers: productProviderT[],
  account: accountT,
  spaFlowStep?: string,
}) {
  const [showFlowError, setShowFlowError] = useState(false);
  const [showFlowSucceeded, setShowFlowSucceeded] = useState(false);
  const query = useQueryParams();
  const widgetStartType = query.get(WIDGET_API_CONNECTION);
  const [spaEnabled] = useFeatureFlag(PERFORMANCE_BOOSTER);
  const widgetFromLocalStorage = localStorage.getItem('hasWidgetWorkFlowStarted');

  useEffect(() => {
    setShowFlowError(props.spaFlowStep === 'error');
    setShowFlowSucceeded(props.spaFlowStep === 'ok');
  }, [props.spaFlowStep]);

  useEffect(() => {
    if (widgetFromLocalStorage) {
      message.success('Amazon Attribution account linked successfully, please return to the Widget', 3);
      localStorage.removeItem('hasWidgetWorkFlowStarted');
    }
  }, [widgetFromLocalStorage]);

  return (
    <div style={{ width: '100%', padding: '1em' }}>
      {showFlowSucceeded && <SpaSuccess accountID={props.account.id} />}
      <h1>Amazon Authorizations</h1>
      <Container>
        {props.providers !== undefined &&
          props.providers.length > 0 &&
          props.providers.map((provider: providerT) => (
            <>
              <ProviderBox
                account={props.account}
                providerName="Amazon Attribution API"
                apiAccountName={`${provider.externalAccountDescriptiveName} (${provider.externalAccountId})`}
                connected={true}
                lostConnection={provider.loginNeeded}
                footer={<AmazonLogoutButton accountId={props.account.id} provider={provider} />}
                marketplaceId={provider.amazonMarketplaceId}
                widgetStartType={widgetStartType}
              />
              {spaEnabled && (
                <ProviderBox
                  account={props.account}
                  providerName="Seller Central API"
                  apiAccountName={provider.spa.externalAccountId}
                  connectedAdvertisingAccount={`${provider.externalAccountDescriptiveName} (${provider.externalAccountId})`}
                  connected={provider.spa.connectedAt !== null}
                  lostConnection={provider.spa.errorAt !== null}
                  marketplaceId={provider.amazonMarketplaceId}
                  widgetStartType={widgetStartType}
                />
              )}
            </>
          ))}
        {props.providers.length === 0 && <Empty />}
        <br />

        <NewProductProviderButton
          account={props.account}
          limitationTest={(limitations, usage) => usage.amazonProductProvider < limitations.LimitAmazonProductProvider}
        />
      </Container>

      <Modal visible={showFlowError} onCancel={() => setShowFlowError(false)} footer={null}>
        <Result
          status="error"
          extra={[
            <b>Failed to connect to Amazon Seller Central API.</b>,
            <div>Please retry by clicking the button below</div>,
            <MutedText>If this error persists, please contact us for support.</MutedText>,
            <Button key="back" onClick={() => setShowFlowError(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                try {
                  // This will work as long as we only support one product provider per account (which we do not plan to change in near future)
                  const domain = amazonSellerCentralUrls[props.providers[0].amazonMarketplaceId];

                  window.location.href = `${domain}/apps/authorize/consent?application_id=amzn1.sellerapps.app.84d6a234-3b4f-4d84-bcfc-92a95146a124&redirect_uri=https%3A%2F%2Fpixelmeapps.com%2Fsp_success&state=${redirectState(
                    props.account.id,
                  )}`;
                } catch (e) {}
              }}
            >
              Retry
            </Button>,
          ]}
        />
      </Modal>
    </div>
  );
}

function ProviderBox(props: {
  account: accountT,
  providerName: string,
  apiAccountName: string,
  connectedAdvertisingAccount?: string,
  connected: boolean,
  lostConnection: boolean,
  footer?: any,
  marketplaceId: string,
  widgetStartType: string,
}) {
  const confirmation = () => {
    const domain = amazonSellerCentralUrls[props.marketplaceId];

    Modal.confirm({
      icon: null,
      okText: 'Continue',
      width: '480px',
      destroyOnClose: true,
      onOk: () =>
        window
          .open(
            `${domain}/apps/authorize/consent?application_id=amzn1.sellerapps.app.84d6a234-3b4f-4d84-bcfc-92a95146a124&redirect_uri=https%3A%2F%2Fpixelmeapps.com%2Fsp_success&state=${redirectState(
              props.account.id,
            )}`,
            '_blank',
          )
          .focus(),
      content: (
        <Result
          status="warning"
          extra={[
            <b>Make sure you use the same email and Merchant ID as the current Amazon Ads account</b>,
            <MutedText style={{ marginBottom: -50 }}>
              Current Account: {props.connectedAdvertisingAccount || props.account.name}
            </MutedText>,
          ]}
        />
      ),
    });
  };

  useEffect(() => {
    if (props.widgetStartType === 'amazon_spa' && props.providerName === 'Seller Central API') {
      localStorage.setItem('hasSPAWidgetWorkFlowStarted', 'true');
      confirmation();
    }
  }, [props.widgetStartType]);

  return (
    <BoxShadow style={{ width: '100%', display: 'block' }}>
      <div className="provider-box">
        <Amazon style={{ width: '20px' }} />
        <span>{props.providerName}</span>
        <Status connected={props.connected} lostConnection={props.lostConnection} />
      </div>
      <Divider orientation="horizontal" style={{ margin: 12 }}></Divider>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          {props.connected ? (
            <>
              <b>{props.lostConnection ? 'Lost Connection to account' : 'Account connected'}:</b>
              {props.apiAccountName}
            </>
          ) : (
            <b>No account connected</b>
          )}

          {props.footer || <></>}
        </div>
        <div>
          {!props.connected && (
            <Button type="primary" onClick={confirmation}>
              Connect
            </Button>
          )}
          {props.connectedAdvertisingAccount && props.lostConnection && (
            <Button type="primary" onClick={confirmation}>
              Re-Connect
            </Button>
          )}
          {!props.connectedAdvertisingAccount && props.lostConnection && (
            <AmazonLoginButton accountId={props.account.id} primary={true} title="Re-connect" />
          )}
        </div>
      </div>
    </BoxShadow>
  );
}

function Status(props: { connected: boolean, lostConnection: boolean }) {
  const state = {
    text: 'Connected',
    error: false,
  };

  if (!props.connected) {
    state.text = 'Not Connected';
    state.error = true;
  } else if (props.lostConnection) {
    state.text = 'Lost Connection';
    state.error = true;
  }

  return (
    <div
      className={classNames('product-provider-accounts-status', {
        'product-provider-accounts-status--error': state.error,
      })}
    >
      <div className="product-provider-accounts-status__badge">
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="4" fill={state.ok ? colors.green : colors.red} />
        </svg>
        <span>{state.text}</span>
      </div>
      <div className="product-provider-accounts-status__description">
        Data is automatically updated by Amazon every day
      </div>
    </div>
  );
}

function redirectState(accountId) {
  return encodeURIComponent(`${accountId}|${window.location.origin}`);
}
