import React from 'react';

import styled from 'styled-components';

import './FloatingKeywordCellAction.css';

const Style = styled.div`
  -webkit-order: 900;
  -ms-flex-order: 900;
  order: 900;
  position: absolute;
  opacity: 0;
  width: 0px;
  right: 0px;
  height: 50px;
  line-height: 50px;
  background-color: #f4f9fd;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 10px;
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
  .floating-row-action {
    display: flex;
    align-items: center;
    margin-left: 180px;
    transition: margin-left 0.3s ease-in-out;
  }
`;

export function FloatingKeywordCellAction({ children }) {
  return (
    <Style
      className="floating-keywork-cell-action-container"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="floating-keywork-cell-action">{children}</div>
    </Style>
  );
}
