import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import type { adsProviderT } from '../../../../../flow-typed/pixelme/adsPlateform';
import { trackedEvents } from '../../../../config/trackedEvents.config';
import { track } from '../../../../lib/analytics';
import { LimitedButton } from '../../../common/antd/LimitedButton';
import { AdsProviderLogo } from '../common/AdsProviderLogo';
import { ADS_PROVIDERS } from '../constants';

const ProviderButton = styled(LimitedButton)`
  height: 51px;
  padding: 1em;
  display: flex;
  gap: 1em;
`;

const Style = styled.div`
  a {
    text-decoration: none !important;
  }
  .btn-text {
    width: 180px;
  }
`;

/**
 * Facebook Login Button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookLoginButton(props: {
  account: accountT,
  history: any,
  onConnected: any,
  +adsProvider: adsProviderT,
  +type: string,
  +danger: boolean,
  block: boolean,
  widgetStartType: string,
}) {
  const { messages } = useIntl();

  const handleClick = () => {
    FB.login(
      (response) => {
        if (response.authResponse) {
          const adsProvider = props.adsProvider || {};

          localStorage.setItem('adsProvider', JSON.stringify({ ...adsProvider, ...response }));
          track(trackedEvents.connectGoogle);
          setTimeout(() => {
            props.history.push(`/${props.account.id}/adsprovider/facebook/${adsProvider.externalAccountId || ''}`);
          }, 500);
          props.onConnected();
        }
      },
      {
        scope:
          'ads_management,public_profile,business_management,pages_show_list,read_insights,pages_read_engagement,pages_manage_ads',
        auth_type: 'rerequest',
      },
    );
  };

  useEffect(() => {
    if (props.widgetStartType === 'facebook') {
      handleClick();
    }
  }, [props.widgetStartType]);

  return (
    <Style>
      <a>
        <ProviderButton
          limitationTest={(limitations, usage) => usage.facebookAdsProvider < limitations.limitFacebookAdsProvider}
          feature={messages.facebookAds.title}
          block={props.block}
          type={props.type}
          danger={props.danger}
          icon={<AdsProviderLogo providerType={ADS_PROVIDERS.FACEBOOK} style={{ height: 22 }} />}
          onClick={handleClick}
        >
          <p className="btn-text">
            {props.adsProvider ? messages.facebookAds.reconnect : messages.facebookAds.connect}
          </p>
        </ProviderButton>
      </a>
    </Style>
  );
}
