// allow to wrap nullable object and avoid undefined is not a function or other
export function s(value: Object) {
  return value || {};
}

export function sn(value: Object, key: string, def: {}) {
  const keys = key.split('.');

  for (let i = 0; i < keys.length; i++) {
    value = value[keys[i]];

    if (value === undefined) {
      return def;
    }
  }

  return value;
}

export function sd(value: Object, def: any) {
  return value || def;
}

export function sArray(value: Object) {
  value = (value instanceof Object ? Object.values(value) : Array.isArray(value) ? value : value ? [value] : []).filter(
    (f) => !!f,
  );

  return value;
}
