import type { axiosParams } from './api';
import { apiCall } from './api';

export function getAgencyAccountData(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { from: string, to: string },
): Promise<any> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `/accounts/metrics`,
    params,
  });
}
