import { WorkerAction } from '../../framework/workerAction';

export default {
  search: new WorkerAction({ name: 'API_AB-LINK_SEARCH_LINK' }, {}),
  sort: new WorkerAction({ name: 'API_AB-LINK_SORT_LINKS' }, {}),
  loadNext: new WorkerAction({ name: 'API_AB-LINK_LOAD_NEXT_LINKS' }, {}),
  loadPrev: new WorkerAction({ name: 'API_AB-LINK_LOAD_PREV_LINKS' }, {}),
  load: new WorkerAction({ name: 'API_AB-LINK_LOAD_LINKS' }, {}),
  delete: new WorkerAction({ name: 'API_AB-LINK_DELETE_LINK' }, {}),
  duplicate: new WorkerAction({ name: 'API_AB-LINK_DUPLICATE_LINK' }, {}),
  edit: new WorkerAction({ name: 'API_AB-LINK_EDIT_LINK' }, {}),
  export: new WorkerAction({ name: 'API_AB-SHOW_EXPORT_LINK' }, {}),
};
