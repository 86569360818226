import React from 'react';

import IconContainer from './IconContainer';

export function Search(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.3944 2.61232C12.5441 4.76207 12.5441 8.24621 10.3944 10.3945C8.24464 12.5429 4.76058 12.5429 2.61228 10.3945C0.462572 8.24621 0.462572 4.76207 2.61228 2.61232C4.76058 0.462561 8.24464 0.462561 10.3944 2.61232Z"
            stroke="#071629"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4349 10.2168C11.1226 10.2168 10.8092 10.3351 10.5726 10.5729C10.0982 11.0461 10.0982 11.823 10.5726 12.2974L14.9192 16.6441C15.1558 16.8807 15.4692 16.999 15.7814 16.999C16.0936 16.999 16.4071 16.8807 16.6437 16.6441C17.1181 16.1685 17.1181 15.3928 16.6437 14.9184L12.2983 10.5729C12.0605 10.3351 11.7471 10.2168 11.4349 10.2168Z"
            fill="#071629"
          />
        </g>
      </svg>
    </IconContainer>
  );
}
