import type { productT } from '../../../flow-typed/pixelme/adsPlateform';
import { TYPE_PRODUCT } from '../../components/pages/AdsProviders/NewCampaign/NewProductModal';

export const reportsRanges = ['LAST_7D', 'LAST_30D'];

/**
 *
 * @param adProvider
 * @returns {boolean}
 */
export function adsAlreadyProcessed(adProvider: adsProviderT) {
  return adProvider.lastProcessedAt !== '0001-01-01T00:00:00Z';
}

/**
 * Return true if it's a new profile
 * @param adProvider
 * @returns {boolean}
 */
export function adsNeverProcessed(adProvider: adsProviderT) {
  return adProvider.lastProcessedAt === '0001-01-01T00:00:00Z';
}

export function generateDefaultCampaignName(aProduct: productT) {
  const productFirstWords = (aProduct.name || '').split(' ').slice(0, 3).join(' ');

  let name = `[Pixelme] - ${productFirstWords}`;

  if (aProduct.type === TYPE_PRODUCT) {
    name += ` - ${aProduct.externalId}`;
  }

  return name;
}

export function getAllowedDomains(domains) {
  const custom = domains.filter((d) => d.custom);

  if (custom.length > 0) {
    return custom.filter((d) => d.name !== 'pxle.me' && d.name !== 'pxlme.me');
  }

  return domains.filter((d) => d.name !== 'pxle.me' && d.name !== 'pxlme.me');
}
