import React, { Component } from 'react';

import styled from 'styled-components';

import Input from 'components/common/Input';

type internalPropsT = {
  name: string,
  value: string,
  title: string,
  pattern: string,
  placeholder: string,
  preInput: ?string,
  displayLabel: ?boolean,
  isBig: ?boolean,
  disabled: ?boolean,
};
type internalActionsT = {
  onChange: (string) => void,
  onMouseUp?: (e) => void,
};
type internalStateT = {};
const defaultState: internalStateT = {};

const FakeInput = styled.div.withConfig({
  displayName: 'InputContainerPreInput',
})`
  border-image-repeat: stretch;
  border-image-source: none;
  border: 1px solid rgb(229, 229, 229);
  box-sizing: border-box;
  border-right: 0;
  text-align: right;
  border-radius: 0.2rem 0 0 0.2rem;
  padding: 7px 10px;
  padding-right: 0 !important;
  color: var(--text-muted);
`;
const InputContainerInput = styled(Input).withConfig({
  displayName: 'InputContainerInput',
})`
  flex: 1;
  ${(props) =>
    props.haspreinput
      ? ` input:focus {
     padding-left: 5px !important;
     border-radius: 0 0.2rem 0.2rem 0;
     border-left: 0;
     }
  }`
      : ''};

  ${(props) =>
    props.haspreinput
      ? ` input {
     padding-left: 0px !important;
     border-radius: 0 0.2rem 0.2rem 0;
     border-left: 0;
     }
  }`
      : ''};
`;

export const InputContainer = styled.div.withConfig({
  displayName: 'InputContainer',
})`
  height: 100%;
  display: block;
  margin-top: 9px;
  align-items: center;
  flex-direction: column;
  color: #6d859e;
`;

export const InputContainerHeader = styled.div.withConfig({
  displayName: 'InputContainerHeader',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  width: 100%;
  margin-bottom: 10px;
  font-size: ${(props) => (props.isBig ? 'var(--normal-size)' : 'var(--small-size)')};
`;

const LockContainer = styled.div.withConfig({
  displayName: 'LockContainer',
})`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  position: relative;
  top: -24px;
  height: 0px;
  padding-right: 10px;
`;

export class InputWithLabel extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const {
      displayLabel,
      onChange,
      title,
      placeholder,
      value,
      name,
      isBig,
      preInput,
      disabled,
      onBlur,
      onMouseUp,
      style,
    } = this.props;

    if (!title) {
      throw new Error('Missing title');
    }

    if (placeholder === undefined) {
      throw new Error(`Missing placeholder for ${title}`);
    }

    if (!onChange || typeof onChange !== 'function') {
      throw new Error(`Missing onChange for ${title}`);
    }

    return (
      <InputContainer style={style}>
        {displayLabel && (
          <InputContainerHeader isBig={isBig}>
            <label htmlFor={name}>{title}</label>
          </InputContainerHeader>
        )}
        <div style={{ display: 'flex' }}>
          {!!preInput && <FakeInput> {preInput} </FakeInput>}
          <InputContainerInput
            onMouseUp={onMouseUp}
            id={name}
            value={value}
            haspreinput={preInput}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            onBlur={onBlur}
          />
        </div>
      </InputContainer>
    );
  }
}

export function Lock() {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.24999 4.5H8.49999V3.49999C8.49999 1.57008 6.92994 0 5 0C3.07006 0 1.50001 1.57008 1.50001 3.49999V4.5H0.750008C0.61182 4.5 0.5 4.61182 0.5 4.75001V11C0.5 11.5515 0.948477 12 1.50001 12H8.50002C9.05152 12 9.5 11.5515 9.5 11V4.75001C9.5 4.61182 9.38818 4.5 9.24999 4.5ZM5.74855 9.72241C5.75635 9.79296 5.73366 9.86377 5.6863 9.91676C5.63893 9.96973 5.57105 10 5.50002 10H4.50001C4.42897 10 4.36109 9.96973 4.31373 9.91676C4.26636 9.86379 4.24365 9.79298 4.25148 9.72241L4.40919 8.30421C4.15309 8.11793 4.00002 7.82325 4.00002 7.5C4.00002 6.94849 4.44849 6.49999 5.00002 6.49999C5.55155 6.49999 6.00003 6.94847 6.00003 7.5C6.00003 7.82325 5.84696 8.11793 5.59086 8.30421L5.74855 9.72241ZM6.99999 4.5H3.00001V3.49999C3.00001 2.39721 3.89722 1.5 5 1.5C6.10278 1.5 6.99999 2.39721 6.99999 3.49999V4.5Z"
        fill="#B0B0B0"
      />
    </svg>
  );
}

export class LockedInputWithLabel extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const { displayLabel, onChange, title, placeholder, value, name, isBig } = this.props;

    if (!title) {
      throw new Error('Missing title');
    }

    if (placeholder === undefined) {
      throw new Error(`Missing placeholder for ${title}`);
    }

    return (
      <InputContainer>
        {displayLabel && (
          <InputContainerHeader isBig={isBig}>
            <label htmlFor={name}>{title}</label>
          </InputContainerHeader>
        )}
        <InputContainerInput id={name} value={value} placeholder={placeholder} onChange={onChange} disabled />
        <LockContainer>
          <Lock />
        </LockContainer>
      </InputContainer>
    );
  }
}
