import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useImmer } from 'use-immer';

import Box from '../../../common/Box';
import { ImgUpload } from '../imgUpload';

const Style = styled.div`
  overflow: auto;
  grid-area: builder;
  text-align: center;

  .ant-upload-select-picture-card,
  .ant-upload-select-picture-card .img {
    border-radius: 50px !important;
    overflow: hidden;
  }
`;

export function Avatar({ account, page, handleChange }) {
  const [state, updateState] = useImmer({ page });

  useEffect(() => {
    handleChange(state.page);
  }, [JSON.stringify(state.page)]);

  return (
    <Style>
      <Box className="" header="">
        <div style={{ maxWidth: '400px', marginLeft: '1em' }}>
          <ImgUpload
            account={account}
            label="Profile image"
            previewSize="100px"
            value={state.page.avatar || ''}
            onChange={(url) => {
              if (url) {
                updateState((draft) => {
                  draft.page.avatar = url;
                });
              }
            }}
          />
        </div>
      </Box>
    </Style>
  );
}
