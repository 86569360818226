import React from 'react';

import { Button, Popconfirm } from 'antd';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { trackedEvents } from '../../../../config/trackedEvents.config';
import { deleteAdsProvider } from '../../../../lib/adsPlateformApi';
import { track } from '../../../../lib/analytics';

export function AdsProviderLogoutButton(props: { account: accountT, provider: adsProviderT }) {
  const [state, updateState] = useImmer({ loading: false });

  async function onDeleteAdsProvider(adsProviderId) {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      await deleteAdsProvider(
        localStorage,
        axios,
        {
          accountId: props.account.id,
        },
        adsProviderId,
      );
      track(trackedEvents.disconnectAdsProvider);
      window.location.reload();
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  }

  return (
    <Popconfirm
      title={"Are you sure to delete all your ads provider's data?"}
      onConfirm={() => onDeleteAdsProvider(props.provider.id)}
    >
      <Button type="text" loading={state.loading} style={{ padding: 0, color: '#AC1C1E' }}>
        Disconnect and remove all data
      </Button>
    </Popconfirm>
  );
}
