import { useEffect, useState, type Node, useMemo } from 'react';

import { EditOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { Button, message } from 'antd';
import _ from 'lodash';
import { useToggle } from 'react-use';
import styled from 'styled-components';

import { useQueryParams } from 'hooks/useQueryParams';
import { useLocale } from 'locales/LocalizationWrapper';
import { useGetAuditQuery, useGetPublicAuditQuery } from 'stores/auditsSlice';

import { AuditResultsSingleAsin } from './AuditResultsSingleAsin';
import { AuditResultsTable } from './AuditResultsTable';
import { BookCallButton } from './BookCallButton';
import { AUDIT_STATUS, BOOK_CALL_URLS, CONTAINER_PADDING, GridCell } from './constants';
import { GenericBadges } from './GenericBadges';
import { NewAuditView } from './NewAuditView';
import { ShareModal } from './ShareModal';

const POLLING_DELAY = 10000;

const TopContainer = styled.div`
  display: grid;
  column-gap: 0.25rem;
  grid-template-columns: max-content 1fr max-content;
  grid-template-areas: 'label badges actions';

  margin-bottom: 0.75rem;
  > *:first-child {
    white-space: nowrap;
    margin-top: 9px;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  column-gap: 0.75rem;
`;

const BaseContainer = styled.div`
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  padding: 4rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  height: 100%;
  text-align: center;
`;

const InfoText = styled.p`
  color: var(--text-muted);
  white-space: pre-line;
  font-size: 1rem;
  margin: 0;
`;

type propsT = {
  isPublic?: boolean,
  persistedAuditId?: string,
  setPersistedAuditId?: (string) => void,
};
export const AuditResultsView = ({
  isPublic = false,
  persistedAuditId,
  setPersistedAuditId = () => {},
}: propsT): Node => {
  const [delay, setDelay] = useState(POLLING_DELAY);
  const [isShareModalOpen, toggleShareModal] = useToggle(false);
  const [isEditing, toggleEditing] = useToggle(false);
  const query = useQueryParams();
  const auditId = query.get('id') || persistedAuditId;
  const { getIntlStrings } = useLocale();

  const useQuery = isPublic ? useGetPublicAuditQuery : useGetAuditQuery;
  const {
    data: audit,
    filteredResults = [],
    error,
    isError,
  } = useQuery(auditId, {
    pollingInterval: delay,
    skip: !auditId,
    selectFromResult: (res) => ({
      ...res,
      filteredResults: res.data?.results?.map(({ keywords = [], ...item }) => {
        const sortedKeywords = _.orderBy(keywords, (x) => x.googleSearchVolume ?? Number.MIN_SAFE_INTEGER, 'desc');

        return { ...item, keywords: sortedKeywords };
      }),
    }),
  });
  const { asins = [], sellerId, status, contactInformation } = audit ?? {};

  useEffect(() => {
    if (audit) {
      setPersistedAuditId(auditId);
      setDelay(status === AUDIT_STATUS.IN_PROGRESS ? POLLING_DELAY : 0);
    }
  }, [audit]);

  useEffect(() => {
    if (isError) {
      message.error(strings.getError(error.status, 'asinAudit.title'));
      setDelay(0);
    }
  }, [isError]);

  const { label, badgeIds } = useMemo(() => {
    if (sellerId) {
      return { label: getIntlStrings('asinAudit.sellerId.details'), badgeIds: [sellerId] };
    } else if (asins.length > 1) {
      return { label: getIntlStrings('asinAudit.asin.detailsMultiple'), badgeIds: asins };
    } else {
      return { label: getIntlStrings('asinAudit.asin.detailsSingle'), badgeIds: asins };
    }
  }, [asins, sellerId]);

  if (!auditId) {
    return (
      <BaseContainer>
        <InfoText>{getIntlStrings('asinAudit.missingId')}</InfoText>
      </BaseContainer>
    );
  }

  if (error || status === AUDIT_STATUS.FAILED) {
    return (
      <BaseContainer>
        <GenericBadges list={badgeIds} centerAlign />
        <InfoText>{getIntlStrings('asinAudit.failed')}</InfoText>
      </BaseContainer>
    );
  }

  if (delay) {
    return (
      <BaseContainer>
        <GenericBadges list={badgeIds} centerAlign />
        <InfoText>{getIntlStrings('asinAudit.loading')}</InfoText>
        <Spinner size={SpinnerSize.STANDARD} />
      </BaseContainer>
    );
  }

  if (isEditing) {
    return (
      <NewAuditView
        audit={audit}
        backText={getIntlStrings('asinAudit.auditResults.back')}
        onBack={() => {
          toggleEditing();
        }}
      />
    );
  }

  return (
    <div>
      <TopContainer>
        <GridCell $name="label">{label}</GridCell>
        <GridCell $name="badges">
          <GenericBadges list={badgeIds} />
        </GridCell>
        {!isPublic && (
          <GridCell $name="actions">
            <ActionsContainer>
              <BookCallButton
                contactInformation={contactInformation}
                auditId={auditId}
                asins={filteredResults.map((x) => x.product?.asin)}
                url={BOOK_CALL_URLS.PROSPER}
              />
              <Button size="large" icon={<EditOutlined />} onClick={toggleEditing} />
              <Button size="large" icon={<ShareAltOutlined />} onClick={toggleShareModal} />
            </ActionsContainer>
            <ShareModal visible={isShareModalOpen} onCancel={toggleShareModal} auditId={auditId} />
          </GridCell>
        )}
      </TopContainer>
      {asins.length === 1 ? (
        <AuditResultsSingleAsin auditItem={filteredResults[0]} />
      ) : (
        <AuditResultsTable data={filteredResults} sticky={{ offsetHeader: -CONTAINER_PADDING }} />
      )}
    </div>
  );
};
