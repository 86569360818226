import { apiCall } from './api';

export function getMetrics(
  productProviderID: string,
  productASIN: string,
  params: { accountId: string, from: string, to: string },
): Promise<GetProductMetricsResponse> {
  return apiCall({
    secure: true,
    method: 'get',
    url: `/product-providers/${productProviderID}/products/${productASIN}/metrics`,
    params,
  });
}
