import _ from 'lodash';

export function verifyParametersRequirements({ searchable, sortable, onSortEnd, values }) {
  if (searchable && sortable) {
    throw new Error("A dropDown can't be searchable and sortable, you have to choose");
  }

  const elementThatIsNotValid = _.find(values, (value) => value.position === undefined);

  if (sortable && elementThatIsNotValid) {
    throw new Error(
      `A sortable dropDown must provides values with field 'position' , this element doesn't match this requirement ${JSON.stringify(
        elementThatIsNotValid,
      )}`,
    );
  }

  if (sortable && !onSortEnd) {
    throw new Error('A sortable dropDown sortable must provides onSortEnd function', onSortEnd);
  }
}
