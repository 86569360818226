import React, { Component } from 'react';

import styled from 'styled-components';

import { Next } from './icons/Next';
import { MAX_REDIRECTS_COUNT } from '../../consts/consts';

const Container = styled.div.withConfig({
  displayName: 'ContainerRedirectsPaginator ',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin: 10px;
`;

const Indicator = styled.div.withConfig({
  displayName: 'Indicator ',
})`
  min-width: 75px;
  text-align: center;
  opacity: ${(props) => (props.loading === 'true' ? '0.2' : '1')};
`;

const PaginatorButtonContainer = styled.div.withConfig({
  displayName: 'PaginatorButtonContainer',
})`
  opacity: ${(props) => (props.disabled ? '0.2' : '1')};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  align-items: center;
  display: flex;
  width: 25px;
  height: 25px;
  padding: 6px;
  path {
    fill: rgb(110, 110, 110);
    stroke: rgb(110, 110, 110);
  }
`;

const PreviousButton = styled(Next).withConfig({
  displayName: 'PreviousButton',
})`
  display: flex;
`;

const NextButton = styled(Next).withConfig({ displayName: 'NextButton' })`
  transform: rotate(-180deg);
  display: flex;
`;

type internalPropsT = { config: columnsConfigT, selectAction: WorkerAc };
type internalActionsT = { onSelect: Function };
type internalStateT = { config: columnsConfigT };
const defaultState: internalStateT = {};

export class AbstractPaginator extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const { loadPrev, loadNext, start, limit, total, loading, shortlist } = this.props;
    const currentPage = total ? Math.floor(start / limit) + 1 : 0;
    const maxPage = Math.floor((total - 1) / limit) + 1;
    const prevIsPossible = currentPage > 1;
    const nextIsPossible = currentPage < maxPage && maxPage > 0;

    if (shortlist) {
      return <></>;
    }

    if (total < MAX_REDIRECTS_COUNT) {
      return (
        <Container>
          <PaginatorButtonContainer disabled={!prevIsPossible} onClick={prevIsPossible ? loadPrev : undefined}>
            <PreviousButton />
          </PaginatorButtonContainer>
          <Indicator loading={loading.toString()}>
            {currentPage}/{maxPage}
          </Indicator>
          <PaginatorButtonContainer disabled={!nextIsPossible} onClick={nextIsPossible ? loadNext : undefined}>
            <NextButton />
          </PaginatorButtonContainer>
        </Container>
      );
    }

    return (
      <Container>
        <PaginatorButtonContainer disabled={!prevIsPossible} onClick={prevIsPossible ? loadPrev : undefined}>
          <PreviousButton />
        </PaginatorButtonContainer>
        <Indicator loading={loading.toString()}>{currentPage}</Indicator>
        <PaginatorButtonContainer onClick={loadNext}>
          <NextButton />
        </PaginatorButtonContainer>
      </Container>
    );
  }
}
