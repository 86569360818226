import styled from 'styled-components';

export const BoxShadow = styled.div`
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px ${(props) => (props.warning ? '#ff4d4f' : 'rgba(0, 0, 0, 0.14)')};
  border-radius: 8px;
  display: flex;
  padding: 1em;
  margin: 1em;
  align-items: center;
  > div {
    flex: 1;
  }
`;
