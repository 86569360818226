type stateT = {
  +asError: boolean,
  +errorMessage: string,
  +loading: boolean,
  +stats: Array<{ date: number, value: number }>,
  +total: number,
  +period: string,
  +queryId: string,
};

// ACTIONS
type filterT = { period: string, linkId: string };
type errorT = { message: string };
export type actionT =
  | { type: 'LOAD_LINK_STATS', filter: filter, queryId: string }
  | { type: 'LOAD_LINK_STATS_SUCCESS', data: linkStatsT, queryId: string }
  | { type: 'LOAD_LINK_STATS_ERROR', error: error, queryId: string };

export function loadLinkStats(filter: filterT, queryId: string): actionT {
  return {
    type: 'LOAD_LINK_STATS',
    filter,
    queryId,
  };
}

export function loadLinkStatsSuccess(data: linkStatsT, queryId: string): action {
  return {
    type: 'LOAD_LINK_STATS_SUCCESS',
    data,
    queryId,
  };
}

export function loadLinkStatsError(error: errorT, queryId: string): actionT {
  return {
    type: 'LOAD_LINK_STATS_ERROR',
    error,
    queryId,
  };
}

// STORE
const initialLinksState: stateT = {
  asError: false,
  errorMessage: '',
  loading: true,
  stats: [],
  total: -1,
  linkId: '',
  period: '',
  queryId: '',
};

export function linkStats(state: stateT = initialLinksState, action: actionT): state {
  switch (action.type) {
    case 'LOAD_LINK_STATS':
      const { linkId, period } = action.filter;
      const { queryId } = action;

      return {
        ...state,
        linkId,
        period,
        queryId,
        loading: true,
        stats: [],
        total: -1,
      };
    case 'LOAD_LINK_STATS_SUCCESS':
      if (state.queryId === action.queryId) {
        const { stats } = action.data;

        let total = 0;

        stats.forEach((s) => (total += s.value));

        return {
          ...state,
          loading: false,
          stats,
          total,
        };
      }

      return state;
    case 'LOAD_LINK_STATS_ERROR':
      if (state.queryId === action.queryId) {
        return {
          ...state,
          loading: false,
          asError: true,
          errorMessage: action.error.message,
        };
      }

      return state;
    default:
      return state;
  }
}
