import React, { useState } from 'react';

import styled from 'styled-components';

import { Folder } from 'components/common/icons/Folder';
import { ALL_REDIRECTS } from 'consts/consts';
import { s, sArray } from 'lib/safe';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { handleChange } from './composerEditUtils';
import { ComposerTitle } from '../ComposerTitle';
import { ComposerCampaignSelect } from '../selects/ComposerCampaignSelect';
import { ComposerSubCampaignSelect } from '../selects/ComposerSubCampaignSelect';
import { ComposerTagsMultiSelect } from '../selects/ComposerTagsMultiSelect';

const ComposerEditCampaignContainer = styled.div.withConfig({
  displayName: 'ComposerEditCampaignContainer',
})`
  max-width: 600px;
`;

export function ComposerEditCampaign(props) {
  const { account, addCampaign, redirect, localUpdateOfAccount } = props;

  let { campaigns, onChange } = props;

  const [isDropDownOpen, setDropDownOpen] = useState(false);

  campaigns = campaigns.filter((c) => c.id !== ALL_REDIRECTS);

  const selectedCampaign = campaigns.find((c) => c.id === redirect.campaignId);
  const selectedSubCampaign = sArray(s(selectedCampaign).subCampaigns).find((c) => c.id === redirect.subCampaignId);

  return (
    <ComposerEditCampaignContainer>
      <div
        className="composer__line composer__dropdowns"
        // style={{ borderBottom: "0px", marginBottom: "0px" }}
      >
        <ComposerTitle
          renderIcon={() => <Folder />}
          title={getIntlStrings('composer.campaigns.title')}
          help={getIntlStrings('composer.campaigns.help')}
          setDropDownOpen={setDropDownOpen}
          showToggle={true}
        />
        {isDropDownOpen && (
          <>
            <br />
            <div className="composer__line_grid">
              <ComposerCampaignSelect
                account={account}
                redirect={redirect}
                onChange={onChange}
                selectedCampaign={selectedCampaign}
                addCampaign={addCampaign}
                campaigns={campaigns}
              />

              <ComposerSubCampaignSelect
                account={account}
                redirect={redirect}
                onChange={onChange}
                selectedCampaign={selectedCampaign}
                addCampaign={addCampaign}
                selectedSubCampaign={selectedSubCampaign}
              />

              <ComposerTagsMultiSelect
                account={account}
                onSelect={(tags) => handleChange([{ name: 'tags', value: tags }], props)}
                onChange={onChange}
                redirect={redirect}
                localUpdateOfAccount={localUpdateOfAccount}
              />
            </div>
          </>
        )}
      </div>
    </ComposerEditCampaignContainer>
  );
}
