import React, { useContext, useEffect, useRef, type Node } from 'react';

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsReact from 'highcharts-react-official';
import styled, { css } from 'styled-components';

import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { CAMPAIGN_ANALYTICS_UPDATE, useFeatureFlag } from 'lib/configcat';
import { getCurrency } from 'utils/currency';

import { MetricsGraphLegend } from './MetricsGraphLegend';
import { RankingGraphLegend } from './RankingGraphLegend';
import { useChartMouseEvents } from './useChartMouseEvents';
import { useChartOptions } from './useChartOptions';
import { useSelectionManager } from './useSelectionManager';
import { GRAPH_HEIGHT, getCacheVisible } from './utils';
import { MAX_METRICS_SELECTIONS } from '../constants';
import { GRAPH_TOOLTIP_WIDTH } from '../Style';

NoDataToDisplay(Highcharts);
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'var(--font-family)',
    },
  },
});

const TooltipDividerCSS = css`
  border-right: 1px solid #d9d9d9;
  height: 240px;
  width: ${GRAPH_TOOLTIP_WIDTH}px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  /* Style tooltip container for all graphs */
  .highcharts-container .highcharts-tooltip > span {
    ${TooltipDividerCSS}
    padding-right: 1rem;
    font-weight: 600;

    .tooltip-header {
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 0.25rem;
      margin-bottom: 1rem;
    }

    .tooltip-row {
      display: flex;
      margin-top: 0.5rem;

      .tooltip-bullet {
        margin-right: 0.25rem;
      }
      .tooltip-key {
        flex: 1;
        /* Text wrap max 2 lines, show ellipsis when more */
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
      }
      .tooltip-value {
        padding-left: 0.75rem;
      }
    }
  }
`;

const GraphTitle = styled.div`
  padding: 0.75rem 1rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  > div {
    h2 {
      font-size: 1rem;
      display: inline;
      margin-right: 0.75rem;
    }
    span {
      font-size: 0.75rem;
      color: #595959;
    }
  }
`;

const StyledSwitch = styled(Switch)`
  background-color: #d9d9d9;
  &.ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25);
    .ant-switch-inner svg {
      margin-left: 0;
      margin-right: 0.25rem;
    }
  }
  .ant-switch-inner {
    font-size: 1rem;
    color: #071629;
    svg {
      margin-left: 0.25rem;
      margin-right: 0;
    }
  }
`;

// Since the border-right divider is on the tooltip, use this prior to tooltip render
const TooltipPlaceholder = styled.div`
  ${TooltipDividerCSS}
  margin: 1rem;
  margin-top: 1.25rem;
  z-index: 1;
`;

const EmptyChartPlaceholder = styled.div`
  /* Position to the right of the Tooltip and cover the graph */
  position: absolute;
  right: 0;
  width: calc(100% - ${GRAPH_TOOLTIP_WIDTH}px - 25px);
  height: ${GRAPH_HEIGHT}px;
  background-color: #fff;
  z-index: 1;

  font-size: 1.25rem;
  color: #828282;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const GraphContainer = styled.div`
  position: relative;
  ${TooltipPlaceholder} {
    position: absolute;
  }

  display: none;
  ${(props) =>
    props.$show &&
    `
    display: block;
    border-top: 1px solid #d9d9d9;
  `}
`;

type propsT = {
  account: accountT,
  product: productT,
  campaigns: campaignT[],
  metrics: GetProductMetricsResponse,
  showMetricsGraph: boolean,
  setShowMetricsGraph: (boolean) => void,
  showRankingGraph: boolean,
  setShowRankingGraph: (boolean) => void,
};

export const ProductGraphs = function ({
  account,
  product,
  campaigns,
  metrics,
  showMetricsGraph,
  setShowMetricsGraph,
  showRankingGraph,
  setShowRankingGraph,
}: propsT): Node {
  Highcharts.Pointer.prototype.reset = function () {
    return;
  };

  Highcharts.Point.prototype.highlight = function (event) {
    event = this.series.chart.pointer.normalize(event);
    this.onMouseOver(); // Show the hover marker
    this.series.chart.xAxis[0].drawCrosshair(event, this);
  };

  const { ctx } = useContext(DashboardFilterContext);
  const [from, to] = ctx.adsReportsRange;

  const [, isConfigCatLoading] = useFeatureFlag(CAMPAIGN_ANALYTICS_UPDATE);
  const { metricsChartOptions, rankingChartOptions, rankingData, hydrateChartOptions } = useChartOptions();

  const metricsGraphRef = useRef(null);
  const rankingGraphRef = useRef(null);

  const rankingManager = useSelectionManager({ name: 'keyword' });
  const metricsManager = useSelectionManager({ name: 'name' });

  const isAmazonProduct = product.type === '';
  const { currencySymbol } = getCurrency(account);

  useEffect(() => {
    if (metricsChartOptions.series.length > 0 && metricsGraphRef?.current) {
      const metricsData = metricsGraphRef.current.chart.series.map((x) => ({ name: x.name }));

      metricsManager.initialize({
        data: metricsData,
        series: metricsGraphRef.current.chart.series,
        initialSelections: metricsData.filter((x) => getCacheVisible(x.name)).map((x) => x.name),
      });
    }
  }, [metricsChartOptions.series.length, metricsGraphRef]);
  useEffect(() => {
    if (rankingChartOptions.series.length > 0 && rankingGraphRef?.current) {
      const cacheSelections = rankingData.filter((r) => getCacheVisible(r.keyword));
      const initialSelections = cacheSelections.length
        ? cacheSelections
        : rankingData?.slice(0, MAX_METRICS_SELECTIONS);

      rankingManager.initialize({
        data: rankingData,
        series: rankingGraphRef.current.chart.series,
        initialSelections: initialSelections.map((r) => r.keyword),
      });
    }
  }, [rankingChartOptions.series.length, rankingGraphRef]);

  useEffect(() => {
    if (Object.keys(metrics).length && !isConfigCatLoading) {
      hydrateChartOptions({
        metrics,
        campaigns,
        filter: { startDate: from, endDate: to },
        isAmazonProduct,
        currencySymbol,
      });
    }
  }, [metrics, isConfigCatLoading]);

  useChartMouseEvents({ ref: metricsGraphRef, manager: metricsManager });
  useChartMouseEvents({ ref: rankingGraphRef, manager: rankingManager });

  return (
    <Container>
      <div>
        <GraphTitle>
          <div>
            <h2>Ad Metrics</h2>
            <span>Select up to 4 metrics to compare</span>
          </div>
          <StyledSwitch
            checkedChildren={<EyeOutlined />}
            unCheckedChildren={<EyeInvisibleOutlined />}
            checked={showMetricsGraph}
            onClick={setShowMetricsGraph}
          />
        </GraphTitle>
        <GraphContainer $show={showMetricsGraph}>
          <TooltipPlaceholder />
          {!metricsManager.selected.length && metricsManager.data.length > 0 && (
            <EmptyChartPlaceholder>No Metrics selected</EmptyChartPlaceholder>
          )}
          <HighchartsReact highcharts={Highcharts} options={metricsChartOptions} ref={metricsGraphRef} />
          <MetricsGraphLegend {...metricsManager} isAmazonProduct={isAmazonProduct} />
        </GraphContainer>
      </div>
      {isAmazonProduct && (
        <div>
          <GraphTitle>
            <div>
              <h2>Organic Keyword Rank Chart</h2>
              <span>Select up to 4 keywords to compare</span>
            </div>
            <StyledSwitch
              checkedChildren={<EyeOutlined />}
              unCheckedChildren={<EyeInvisibleOutlined />}
              checked={showRankingGraph}
              onClick={setShowRankingGraph}
            />
          </GraphTitle>
          <GraphContainer $show={showRankingGraph}>
            <TooltipPlaceholder />
            {!rankingManager.selected.length && rankingManager.data.length > 0 && (
              <EmptyChartPlaceholder>No Keywords selected</EmptyChartPlaceholder>
            )}
            <HighchartsReact highcharts={Highcharts} options={rankingChartOptions} ref={rankingGraphRef} />
            <RankingGraphLegend {...rankingManager} />
          </GraphContainer>
        </div>
      )}
    </Container>
  );
};
