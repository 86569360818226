import React, { useEffect, useState } from 'react';

import { Select } from 'antd';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokTools } from 'lib/tiktokPlateformApi';

/**
 * Tiktok OS selector
 * https://ads.tiktok.com/marketing_api/docs?id=1701890995018753
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function OSSelector(props: {
  account: accounT,
  adsProvider: adsProviderT,
  type: string,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [data, setData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const genericResponse = await getTiktokTools(
      localStorage,
      axios,
      props.adsProvider,
      { accountId: props.account.id, osType: props.type },
      'os_version',
    );
    const d = genericResponse.osVersions.map((l) => ({
      code: l.code,
      value: l.version,
      label: l.name,
    }));

    setData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <ExclusiveInlineLoadingContainer>
      <Select
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        size="large"
        style={{ width: 329 }}
        placeholder="Select OS"
        allowClear
        options={data}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
