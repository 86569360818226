import React from 'react';

import styled from 'styled-components';

import { Mediums } from './Mediums';
import { Platforms } from './Platforms';
import type { accountT } from '../../../../../flow-typed/pixelme/types';

const Style = styled.div`
  width: 100%;
  padding: 1em;
  margin-top: 1em;

  .settings-platforms > .box {
    margin-top: 1rem;
  }
  .settings-platforms > .box:first-child {
    margin-top: 0;
  }

  .settings-platforms__list__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .settings-platforms__list__title .button {
    height: 1.8rem;
    font-size: var(--small-size);
  }

  .settings-platforms__list__title span {
    font-size: var(--small-size);
    color: var(--text-muted);
    margin-left: 1rem;
  }

  .settings-platforms__list__headers {
    color: var(--text-muted);
  }

  .settings-platforms__list__line {
    display: grid;
    grid-template-columns: repeat(1, 1fr) 3rem;
    grid-gap: 0 1rem;
    height: 1.8rem;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
  }

  .settings-platforms__list__line .input {
    padding: 0.2rem;
  }

  .settings-platforms__list__line--odd {
    background: #f9f9f9;
  }
  .settings-platforms__list--del {
    justify-self: end;
    padding-top: 2px;
  }
`;

export function PlatformsSettings(props: { account: accountT }) {
  return (
    <Style>
      <Platforms account={props.account} />
      <Mediums account={props.account} />
    </Style>
  );
}
