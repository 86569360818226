import React from 'react';

import { SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';

import { DropDownElement } from './DropDownElement';
import { AbstractSortableDropDownBullet } from './SortableDropDownBullet';

export const SortableDropDownElement = styled(SortableElement((props) => <DropDownElement {...props} />)).withConfig({
  displayName: 'SortableDropDownLi',
})`
  flex-shrink: 0;
  z-index: 904;
  :hover {
    .${AbstractSortableDropDownBullet.styledComponentId} {
      opacity: 1;
    }
  }
`;
