import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';
import { addNotice } from 'stores/notices';

import { Actions } from '../actions';
import { getCampaignFromId, getSubCampaignFromId } from '../lib/campaigns';
import { s } from '../lib/safe';

export default (store: any) => (next: any) => async (action: any) => {
  next(action);
  let notice: noticeT;

  const { campaignId, subCampaignId } = action.payload || {};

  switch (action.type) {
    case Actions.api.audienceBuilder.campaigns.addRedirects.success().type:
      if (!campaignId) {
        throw new Error('Missing campaign in action');
      }

      if (subCampaignId) {
        notice = {
          duration: 10000,
          message: getIntlStrings('subCampaigns.addRedirects.success', {
            subCampaign: s(getSubCampaignFromId(subCampaignId)).name,
            campaign: s(getCampaignFromId(campaignId)).name,
            nbRedirects: action.payload.redirects.length,
          }),
          type: 'ok',
        };
      } else {
        notice = {
          duration: 10000,
          message: getIntlStrings('campaigns.addRedirects.success', {
            campaign: s(getCampaignFromId(campaignId)).name,
            nbRedirects: action.payload.redirects.length,
          }),
          type: 'ok',
        };
      }

      break;
    default:
      break;
  }

  if (notice) {
    store.dispatch(addNotice(notice));
  }
};
