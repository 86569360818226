import React, { Component } from 'react';

import { SortableContainer } from 'react-sortable-hoc';

import CampaignRedirectsDragDrop from './CampaignRedirectsDragDropTarget';
import { SubCampaignContainer } from './Common';
import { SubCampaignListElement } from './SubCampaignListElement';

type internalPropsT = {};
type internalActionsT = {};
type internalStateT = {};
const initialState: State = {};

class AbstractSubCampaignsList extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = initialState;
  render() {
    const { subCampaigns, campaign } = this.props;

    return (
      <span style={{ width: '100%' }}>
        {!!subCampaigns.length > 0 && (
          <SubCampaignContainer>
            {subCampaigns.map((subCampaign, index) => (
              <CampaignRedirectsDragDrop
                key={subCampaign.id}
                index={subCampaign.position}
                campaign={campaign}
                subCampaign={subCampaign}
                renderChildren={(isOver, canDrop) => (
                  <>
                    <SubCampaignListElement
                      index={index}
                      subCampaign={subCampaign}
                      campaign={campaign}
                      isOver={isOver}
                      canDrop={canDrop}
                    />
                  </>
                )}
              />
            ))}
          </SubCampaignContainer>
        )}
      </span>
    );
  }
}

export const SubCampaignsList = SortableContainer(AbstractSubCampaignsList);
