import React from 'react';

import { NavLink } from 'react-router-dom';

export function MenuItem({
  label,
  to,
  className = '',
  onClick,
}: {
  label: string,
  to: string,
  className?: string,
  onClick?: Function,
}) {
  return (
    <li className={className}>
      <NavLink onClick={onClick} to={to} activeClassName="current">
        {label}
      </NavLink>
    </li>
  );
}
