/* eslint-disable eqeqeq */
import {
  ALL_REDIRECTS,
  ALL_REDIRECTS_FOR_CAMPAIGN,
  UNCLASSIFIED_REDIRECTS,
  UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN,
} from 'consts/consts';

export function mergeRedirects(oldRedirects: Array<redirectT>, newRedirects: Array<redirectT>): Array<redirectT> {
  const mergedRedirects: Array<redirectT> = [];
  const oldRedirectsMap: { [key: string]: boolean } = {};

  oldRedirects.forEach((redirect: redirectT) => {
    oldRedirectsMap[redirect.id] = true;
  });

  let curOld: redirectT = oldRedirects.shift();
  let curNew: redirectT = newRedirects.shift();

  while (!(curNew === undefined && curOld === undefined)) {
    if (curNew === undefined) {
      mergedRedirects.push(curOld);
      curOld = oldRedirects.shift();
      continue;
    }

    if (curOld === undefined) {
      mergedRedirects.push(curNew);
      curNew = newRedirects.shift();
      continue;
    }

    // merge existing in both
    if (curNew.id === curOld.id) {
      mergedRedirects.push({
        ...curNew,
        clicks: curOld.clicks,
      });
      curOld = oldRedirects.shift();
      curNew = newRedirects.shift();
      continue;
    }

    // in original list, but not the first, add the new original one
    if (oldRedirectsMap[curNew.id]) {
      mergedRedirects.push(curOld);
      curOld = oldRedirects.shift();
    } else {
      // not in original, add the new one
      mergedRedirects.push(curNew);
      curNew = newRedirects.shift();
    }
  }

  return mergedRedirects;
}

export function mustBeDisplayAccordingToFilters(state: stateT, redirect: redirectT) {
  const { filter } = state;

  if (filter.campaignId === undefined) {
    filter.campaignId = ALL_REDIRECTS;
  }

  if (filter.subCampaignId === undefined) {
    filter.subCampaignId = ALL_REDIRECTS_FOR_CAMPAIGN;
  }

  if (filter.campaignId === UNCLASSIFIED_REDIRECTS) {
    filter.campaignId = '';
  }

  if (filter.subCampaignId === UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN || !filter.subCampaignId) {
    filter.subCampaignId = '';
  }

  // noinspection EqualityComparisonWithCoercionJS
  return (
    (filter.campaignId === ALL_REDIRECTS || redirect.campaignId == filter.campaignId) &&
    (filter.subCampaignId === ALL_REDIRECTS_FOR_CAMPAIGN || redirect.subCampaignId == filter.subCampaignId)
  );
}
