import './Graph.css';

import React from 'react';

import numeral from 'numeral';
// $FlowFixMe
import { Line } from 'react-chartjs-2';

import type { queryT } from 'stores/analytics';

const lineColor = '#071629';
const graphFillColor = '#f5f9fe';
const options = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      title(items) {
        if (items.length > 0 && items[0].datasetIndex === 0) {
          return 'Unique clicks';
        }

        return 'Total clicks';
      },
    },
  },
  elements: {
    point: {
      radius: 2,
      pointStyle: 'circle',
      borderWidth: 2,
      backgroundColor: lineColor,
      borderColor: lineColor,
    },
    line: {
      borderColor: lineColor,
      borderWidth: 2,
      backgroundColor: graphFillColor,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },

        type: 'time',
        distribution: 'series',
        time: {
          unit: 'day',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          borderDash: [2, 2],
        },
      },
    ],
  },
};

export default function Graph({
  total,
  stats,
  query,
}: {
  total: number,
  stats: Array<{ date: number, value: number, uniqueValue: number }>,
  query: queryT,
}) {
  const fullData = {
    datasets: [
      {
        data: stats.map((stat) => ({ x: stat.date, y: stat.uniqueValue })),
        backgroundColor: '#E3F0FD',
        pointBackgroundColor: '#007EE6',
        pointBorderColor: '#007EE6',
        borderColor: '#BDDCF9',
      },
      {
        data: stats.map((stat) => ({ x: stat.date, y: stat.value })),
      },
    ],
  };

  return (
    <div className="analytics-graph">
      <div className="analytics-graph__title">
        Total click
        {total > 1 ? 's:' : ':'} {numeral(total).format()}
      </div>
      <div className="analytics-graph__content">
        {/* <Line height={50} options={options} data={fullData} /> */}
        <Line height={200} options={options} data={fullData} />
      </div>
    </div>
  );
}
