import React, { useEffect, useState, Fragment } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Select, Modal, Alert } from 'antd';
import Button from 'antd/lib/button';
import _ from 'lodash';
import styled from 'styled-components';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';

import { tiktokCTAs } from '../../../../../assets/tiktokCTAs';
import { getTiktokAllIdentities } from '../../../../../lib/tiktokPlateformApi';

/**
 * Tiktok Identities selector
 * https://ads.tiktok.com/marketing_api/docs?id=1710496973954049
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function IdentitiesSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  type: string,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [data, setData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);
  const Style = styled.div`
    position: relative;
  `;

  async function load() {
    const genericResponse = await getTiktokAllIdentities(localStorage, axios, props.adsProvider, {
      accountId: props.account.id,
      osType: props.type,
    });

    const d = _.get(genericResponse, 'identityList', []).map((l) => ({
      code: l.identityId,
      value: l.identityId,
      type: l.identityType,
      label: l.displayName,
    }));

    setData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <Style>
      <ExclusiveInlineLoadingContainer>
        {data.length === 0 && (
          <Alert
            type="error"
            message={
              <div>
                Please link your TikTok account using{' '}
                <a target="_blank" href="https://ads.tiktok.com/ac/page/settings" rel="noreferrer">
                  Link to TikTokAccount
                </a>
              </div>
            }
          />
        )}
        {data.length > 0 && (
          <Fragment>
            <Button
              style={{ position: 'absolute', top: -30, right: 0, minWidth: 20 }}
              shape="circle"
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => {
                doAction(load);
              }}
            />
            <Select
              value={props.value}
              defaultValue={props.defaultValue}
              onChange={(v) => {
                props.onChange(v);
                props.onChangeFullObject(data.find((cta) => cta.value === v));
              }}
              size="large"
              placeholder="Select Audience"
              allowClear
              options={data}
            />
          </Fragment>
        )}
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}
