import * as React from 'react';

export function Csv(props) {
  return (
    <svg width={111} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={0.5}>
        <rect x={2} y={7} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={29.771} y={7} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={57.543} y={7} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={85.314} y={7} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={2} y={13.818} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={29.771} y={13.818} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={57.543} y={13.818} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={85.314} y={13.818} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={2} y={21} width={25} height={11} rx={1} fill="#1a21ff" />
        <rect x={29.771} y={20.636} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={57.543} y={20.636} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={85.314} y={20.636} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={2} y={27.455} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.6} />
        <rect x={29.771} y={27.455} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={57.543} y={27.455} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <rect x={85.314} y={27.455} width={24.686} height={4.545} rx={1} fill="#1a21ff" fillOpacity={0.3} />
        <path
          d="M6.586 29.594c0-.183.044-.311.133-.387a.473.473 0 0 1 .324-.117c.133 0 .245.039.336.117.091.076.137.204.137.387 0 .18-.046.31-.137.394a.489.489 0 0 1-.336.121.464.464 0 0 1-.324-.12c-.089-.084-.133-.216-.133-.395Zm4.738-4.801c-.315 0-.599.055-.851.164-.25.107-.464.263-.641.469-.174.203-.309.449-.402.738-.094.29-.14.615-.14.977 0 .479.073.896.222 1.25.15.351.375.623.672.816.3.193.673.29 1.12.29.256 0 .495-.022.72-.063.226-.045.446-.1.66-.165v.579a3.37 3.37 0 0 1-.657.172c-.229.039-.501.058-.816.058-.58 0-1.066-.12-1.457-.36a2.248 2.248 0 0 1-.875-1.023c-.193-.44-.29-.96-.29-1.558 0-.433.06-.827.18-1.184.123-.36.3-.67.532-.93.234-.26.52-.46.86-.601a3.003 3.003 0 0 1 1.171-.215c.29 0 .568.029.836.086.268.057.51.14.727.246l-.266.563a3.956 3.956 0 0 0-.602-.215 2.522 2.522 0 0 0-.703-.094Zm5.82 3.688c0 .338-.084.627-.253.867a1.565 1.565 0 0 1-.703.543 2.778 2.778 0 0 1-1.067.187c-.216 0-.42-.01-.613-.031-.193-.021-.37-.05-.531-.09a2.254 2.254 0 0 1-.426-.145v-.636a5.2 5.2 0 0 0 .715.226c.28.068.575.102.882.102.287 0 .53-.038.727-.113a.967.967 0 0 0 .45-.332.876.876 0 0 0 .155-.524.868.868 0 0 0-.128-.488c-.086-.133-.23-.253-.43-.36a5.662 5.662 0 0 0-.813-.347 4.353 4.353 0 0 1-.64-.281 1.999 1.999 0 0 1-.465-.352 1.324 1.324 0 0 1-.285-.453 1.718 1.718 0 0 1-.094-.59c0-.305.077-.565.23-.781.157-.219.372-.386.645-.5a2.41 2.41 0 0 1 .95-.176c.304 0 .585.029.843.086.26.057.499.134.715.23l-.207.57a4.496 4.496 0 0 0-.656-.214 2.92 2.92 0 0 0-.711-.086 1.57 1.57 0 0 0-.621.11.845.845 0 0 0-.383.3.806.806 0 0 0-.133.465c0 .2.041.367.125.5.086.133.221.251.406.355.188.102.435.209.742.32.336.123.621.253.856.391.234.136.413.302.535.5.122.196.184.444.184.746Zm5.157-4.192L20.25 30h-.668l-2.05-5.71h.695l1.335 3.76c.055.152.103.296.145.434a8.05 8.05 0 0 1 .207.77 8.454 8.454 0 0 1 .207-.774c.044-.14.094-.287.149-.44l1.328-3.75h.703Z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}
