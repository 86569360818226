import React from 'react';

import { message, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';

import { Copy } from 'components/common/icons';
import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';

import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';

export function CopyCampaignLink(props: { account: accountT, campaign: adsCampaignT }) {
  return (
    <CopyToClipboard
      text={props.campaign.redirect.redirect}
      onCopy={() => {
        message.success(`Your campaign link is copied to clipboard.`);
      }}
    >
      <IconInsideButton>
        <Tooltip title="Copy Campaign URL" placement="bottom">
          <Copy />
        </Tooltip>
      </IconInsideButton>
    </CopyToClipboard>
  );
}
