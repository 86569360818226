import './FacetBox.css';

import React, { Component } from 'react';
import type { Node } from 'react';

import BoxSwitcher from './BoxSwitcher';
import type { status } from './BoxSwitcher';
import Modal from '../../common/Modal';
import { Line as LinePlaceholder } from '../../common/Placeholders';

type propsT = {
  title: string,
  nextStatus: status,
  handleSwitchStatus: () => void,
  children: Node,
  tooltip?: string,
};

type stateT = {
  tooltipOpen: boolean,
};

export default class FacetBox extends Component<propsT, stateT> {
  constructor(props: propsT) {
    super(props);
    this.state = {
      tooltipOpen: false,
    };
  }
  render() {
    const { title, nextStatus, handleSwitchStatus, children, tooltip } = this.props;
    const { tooltipOpen } = this.state;

    return (
      <div className="box facet-box">
        {tooltipOpen && (
          <Modal
            handleCancel={() => {
              this.setState({ tooltipOpen: false });
            }}
            handleOk={(_) => {
              this.setState({ tooltipOpen: false });
            }}
            okMessage="Noticed :-)"
          >
            {tooltip}
          </Modal>
        )}
        <div className="facet-box__header">
          {tooltip && (
            <div
              onClick={(_) => this.setState({ tooltipOpen: true })}
              className="facet-box__title facet-box__title--clickable"
            >
              {title}
              {this.renderToolTipIcon()}
            </div>
          )}
          {!tooltip && <div className="facet-box__title">{title}</div>}

          <BoxSwitcher handleSwitchStatus={handleSwitchStatus} label="Switch to chart view" nextStatus={nextStatus} />
        </div>
        <div className="facet-box__content">{children}</div>
      </div>
    );
  }
  renderToolTipIcon() {
    return (
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          opacity="0.5"
          x="11.8896"
          y="0.889648"
          width="16.8129"
          height="16.8129"
          rx="8.40644"
          transform="rotate(45 11.8896 0.889648)"
          fill="#C4C4C4"
        />
        <rect x="11" y="11" width="2" height="7" fill="white" />
        <circle cx="12" cy="9" r="1" fill="white" />
      </svg>
    );
  }
}

export function FacetBoxPlaceholder() {
  return (
    <div className="box facet-box">
      <div className="facet-box__header">
        <LinePlaceholder />
      </div>
      <div className="facet-box__content">
        <div>
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
          <LinePlaceholder />
        </div>
      </div>
    </div>
  );
}
