export const tiktokActionDays = [
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 15,
    label: '15 days',
  },
];
