import React, { type Node } from 'react';

import styled from 'styled-components';

import { Skeleton } from 'components/common/antd/Skeleton';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { ProductGraphs } from './ProductGraphs';
import { CHART_CLOSED_HEIGHT, METRICS_CHART_CONTAINER_HEIGHT, RANKING_CHART_CONTAINER_HEIGHT } from './utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

type propsT = {
  account: accountT,
  product: productT,
  metrics: GetProductMetricsResponse,
  loading: boolean,
  campaigns: campaignT[],
};
export const ProductGraphsContainer = ({ account, product, metrics, loading, campaigns }: propsT): Node => {
  const [showMetricsGraph, setShowMetricsGraph] = useLocalStorage('graph-show-metrics-graph', true);
  const [showRankingGraph, setShowRankingGraph] = useLocalStorage('graph-show-ranking-graph', true);
  const isAmazonProduct = product.type === '';

  if (loading) {
    return (
      <Container>
        {/* Match these with the number of charts to render */}
        <Skeleton height={showMetricsGraph ? METRICS_CHART_CONTAINER_HEIGHT : CHART_CLOSED_HEIGHT} />
        {isAmazonProduct && (
          <Skeleton height={showRankingGraph ? RANKING_CHART_CONTAINER_HEIGHT : CHART_CLOSED_HEIGHT} />
        )}
      </Container>
    );
  }

  return (
    <ProductGraphs
      account={account}
      product={product}
      metrics={metrics}
      campaigns={campaigns}
      showMetricsGraph={showMetricsGraph}
      setShowMetricsGraph={setShowMetricsGraph}
      showRankingGraph={showRankingGraph}
      setShowRankingGraph={setShowRankingGraph}
    />
  );
};
