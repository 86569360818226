/**
 *
 * Return Start for next page
 * @param filter
 * @param nbRedirects
 * @returns {*}
 */
export function getNextStart(filter, nbRedirects) {
  const start = filter.start ? filter.start : 0;
  const limit = filter.limit ? filter.limit : 20;

  let newStart = start + limit;

  if (newStart >= nbRedirects) {
    newStart = start;
  }

  return newStart;
}

/**
 * Return start for previous page
 * @param filter
 * @returns {number}
 */
export function getPreviousStart(filter) {
  const start = filter.start ? filter.start : 0;
  const limit = filter.limit ? filter.limit : 20;

  let newStart = start - limit;

  if (newStart < 0) {
    newStart = 0;
  }

  return newStart;
}
