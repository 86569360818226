import React, { useEffect, useState } from 'react';

import { Select } from 'antd';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokAllAudiences } from 'lib/tiktokPlateformApi';

/**
 * Tiktok Audiences selector
 * https://ads.tiktok.com/marketing_api/docs?id=1708578418908161
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AudiencesSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  type: string,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [data, setData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const genericResponse = await getTiktokAllAudiences(localStorage, axios, props.adsProvider, {
      accountId: props.account.id,
      osType: props.type,
    });
    const d = genericResponse.list.map((l) => ({
      code: l.audienceId,
      value: l.audienceId,
      type: l.type,
      label: l.name,
    }));

    setData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <ExclusiveInlineLoadingContainer>
      <Select
        mode="multiple"
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        size="large"
        style={{ width: 329 }}
        placeholder="Select Audience"
        allowClear
        options={data}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
