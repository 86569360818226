import React, { Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TIKTOK_ACCESS, useFeatureFlag } from 'lib/configcat';

import { LimitedButton } from '../../../common/antd/LimitedButton';
import ShortInfo from '../../../common/ShortInfo';
import { AdsProviderLogo } from '../common/AdsProviderLogo';

const Style = styled.div`
  .ant-btn {
    display: flex;
    padding: 1em;
    height: fit-content;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
`;

export function ImportCampaignForm({ account, productId }) {
  const history = useHistory();

  const [tiktokEnabled] = useFeatureFlag(TIKTOK_ACCESS);

  return (
    <Fragment key="IMPORT_CAMPAIGN_FORM">
      <Style>
        <br />
        <LimitedButton
          account={account}
          feature="Google Ads Campaign"
          limitationTest={(limitations, usage) => usage.googleAds || limitations.limitGoogleAds > 0 || 0}
          style={{}}
          block
          onClick={() => {
            setTimeout(() => {
              history.push(`/${account.id}/adsprovider/import-google-campaign?productId=${productId}`);
            }, 500);
          }}
          icon={<AdsProviderLogo style={{ height: 30 }} providerType="GOOGLE" />}
        >
          <div style={{ width: 220, textAlign: 'left' }}>Google</div>
        </LimitedButton>
        <br />
        <LimitedButton
          account={account}
          disabled
          feature="Facebook Ads Campaign"
          limitationTest={(limitations, usage) => usage.facebookAds || limitations.limitFacebookAds > 0 || 0}
          block
          onClick={() => {
            setTimeout(() => {
              history.push(`/${account.id}/adsprovider/import-facebook-campaign?productId=${productId}`);
            }, 500);
          }}
          icon={<AdsProviderLogo style={{ height: 30 }} providerType="FACEBOOK" />}
        >
          <div style={{ width: 220, textAlign: 'left' }}>Facebook</div>
        </LimitedButton>
        <br />
        <LimitedButton
          account={account}
          feature="TikTok Ads Campaign"
          limitationTest={(limitations, usage) => {
            if (!tiktokEnabled) {
              return 'Tiktok is currently not available for campaign creation and is under maintenance. please reach out to albert@carbon6.io for further details';
            }

            return usage.limitTiktokAds || limitations.limitTiktokAds > 0 || 0;
          }}
          block
          onClick={() => {
            setTimeout(() => {
              history.push(`/${account.id}/adsprovider/import-tiktok-campaign?productId=${productId}`);
            }, 500);
          }}
          icon={<AdsProviderLogo style={{ height: 30 }} providerType="TIKTOK" />}
        >
          <div style={{ width: 220, textAlign: 'left' }}>TikTok</div>
        </LimitedButton>
        <br />
        <ShortInfo>
          <>
            <strong>New!</strong> Create a Custom Link by Clicking New Link on the top right
          </>
        </ShortInfo>
      </Style>
    </Fragment>
  );
}
