import './Usage.css';
import React from 'react';

import numeral from 'numeral';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { capitalizeFirstLetter } from 'lib/util';
import { selectCurrentAccount } from 'stores/accounts';

import { MonthlyUsageCTATooltip } from '../common/tooltip';

const numeralFormat = '0a';

const ProgressBar = ({ value, max }: { value: number, max: number }) => {
  const currentVal = value >= max ? 100 : Math.floor((value * 100) / max);
  const valStyle = {
    width: `${currentVal}%`,
  };
  const classes = currentVal > 80 ? 'progress-bar__value--full' : '';

  return (
    <div className="progress-bar">
      <div className={`progress-bar__value ${classes}`} style={valStyle} />
    </div>
  );
};

const UsageText = styled.span.withConfig({
  displayName: 'UsageText',
})`
  color: #b0b0b0;
  font-size: 10px;
`;

export default function Usage() {
  const { plan, account, limitations, monthlyUsage } = useSelector((state) => {
    let plan = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
      limitations: state.accounts.limitations,
      monthlyUsage: state.accounts.monthlyUsage,
    };
  });

  if (!monthlyUsage || !limitations || !plan) {
    return <div className="usage">loading...</div>;
  }

  if (monthlyUsage.total === -1) {
    return <div className="usage">loading...</div>;
  }

  const monthStr = capitalizeFirstLetter(monthlyUsage.month);
  const totalStr = numeral(monthlyUsage.total).format(numeralFormat);
  const limitStr = numeral(limitations.monthlyUsage).format(numeralFormat);

  if (limitations.monthlyUsage && limitations.monthlyUsage > 0) {
    if (monthlyUsage.total >= limitations.monthlyUsage) {
      return (
        <MonthlyUsageCTATooltip>
          <div className="usage">
            <ProgressBar value={monthlyUsage.total} max={limitations.monthlyUsage} />
            <div className="usage__subtitle">
              <span>
                {monthStr} usage: {totalStr} / {limitStr} clicks
              </span>
              <Link to={planPath(account.id)}>{capitalizeFirstLetter(plan.name)} plan</Link>
            </div>
          </div>
        </MonthlyUsageCTATooltip>
      );
    }

    return (
      <div className="usage">
        <ProgressBar value={monthlyUsage.total} max={limitations.monthlyUsage} />
        <div className="usage__subtitle">
          <span>
            {monthStr} usage: {totalStr} / {limitStr} clicks
          </span>
          <Link to={planPath(account.id)}>{capitalizeFirstLetter(plan.name)} plan</Link>
        </div>
      </div>
    );
  } else {
    return (
      <div className="usage usage--empty">
        <div className="usage__subtitle">
          <UsageText>
            {monthStr} usage: {totalStr} clicks
          </UsageText>
          <Link to={planPath(account.id)}>{capitalizeFirstLetter(plan.name)} plan</Link>
        </div>
      </div>
    );
  }
}

function planPath(accountId: string): string {
  return `/${accountId}/b/plans`;
}
