import React, { useState } from 'react';

import moment from 'moment';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Actions } from '../../../actions';
import Box from '../../common/Box';
import { DeleteButton } from '../../common/Button';
import Modal from '../../common/Modal';

const ResetButton = styled(DeleteButton).withConfig({
  displayName: 'ResetButton',
})`
  background-color: rgb(203, 51, 63);
  border: none;
  color: var(--white);
  font-weight: 600;

  :hover {
    color: var(--white);
    background-color: rgba(203, 51, 63, 0.8);
  }
`;

const MutedP = styled.p.withConfig({
  displayName: 'MutedP',
})`
  color: var(--text-muted);
  margin: 10px 0 20px 0;
`;

const Header = styled.div.withConfig({
  displayName: 'header',
})`
  display: flex;
  aligns-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
`;

const FitHeightBox = styled(Box).withConfig({
  displayName: 'FitHeightBox',
})`
  height: fit-content;
`;

export const Analytics = ({ account }) => {
  const [isResetting, setIsResetting] = useState(false);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const dispatch = useDispatch();

  const displayModal = () => {
    if (isModalDisplayed) {
      const message = isResetting ? 'Resetting...' : 'Yes';

      return (
        <Modal
          disabled={isResetting}
          okMessage={message}
          handleCancel={() => {
            setIsModalDisplayed(false);
            setIsResetting(false);
          }}
          handleOk={() => {
            dispatch(Actions.api.account.settings.analytics.reset.request()).then(() => {
              setIsModalDisplayed(false);
              setIsResetting(false);
            });
          }}
        >
          Are you sure you want to reset your analytics? If you proceed, there will be no turning back{' '}
          <span role="img" aria-label="warning">
            😅
          </span>
        </Modal>
      );
    }

    return null;
  };

  const resetDate = moment(account.settings.analyticsResettedAt);

  return (
    <>
      <FitHeightBox
        header={
          <Header>
            <p>Reset Analytics</p>
            {resetDate.year() !== 1 && (
              <p style={{ fontStyle: 'italic', fontSize: '12px' }}>
                Last reset: {resetDate.utc().format('dddd Do MMM YYYY [at] HH:mm [UTC]')}
              </p>
            )}
          </Header>
        }
      >
        <MutedP>
          Resetting your analytics reset to zero all your clicks tracked for this account. This won’t affect your
          monthly usage.
          <br />
          Be careful, if you proceed, we can’t revert it back!
        </MutedP>
        <ResetButton handleClick={(_) => setIsModalDisplayed(true)}>Reset analytics</ResetButton>
      </FitHeightBox>
      {displayModal()}
    </>
  );
};
