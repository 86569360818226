import React, { Fragment, useEffect, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Divider, Form, Image, Input, message, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { useEditProductMutation, useGetProductQuery } from 'stores/productsSlice';
import { getCurrencySymbol } from 'utils/currency';

import { getCurrencyCode } from '../../../../../lib/amazon';
import { strings } from '../../../../../locales/strings';
import { CurrencyInput } from '../../../../common/antd/inputs/CurrencyInput';
import { CompetitorsSelector } from '../../../../common/CompetitorsSelector';
import { If } from '../../../../common/conditional/If';
import { MutedText } from '../../../../common/MutedText';
import { keywordsNormalizer } from '../../../../common/normalizers/Keyword';
import { SmallCompactPlaceholder } from '../../../../common/Placeholders';
import { CurrencyValue } from '../../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { amazonAttributionIds } from '../../../oauth/AmazonAttributionIds';
import { ShowProduct } from '../../common/ShowProduct';
import { TYPE_PRODUCT } from '../../NewCampaign/NewProductModal';
import { ProductTagsSelector } from '../../NewCampaign/ProductTagsSelector';

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #595959;
  margin-right: 10px;
`;

const Paragraph = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 6px;
`;
const Value = styled.div`
  display: inline-block;
  max-width: 200px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #595959 !important;
  white-space: nowrap;
  wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;

  .ant-statistic-content {
    color: #595959 !important;
    font-size: 13px;
    line-height: 18px;
  }
`;

function ProductEditModal(props: {
  account: accountT,
  productId: string,
  limitations: accLimitationsT,
  onCancel: Function,
}) {
  const [form] = useForm();
  const [editProduct, { isLoading: isEditing }] = useEditProductMutation();
  const { messages } = useIntl();

  const currencyCode = getCurrencyCode(props.account.productProviders);

  const maxKeywords = props.limitations.limitProductKeyword;

  const { data, isFetching } = useGetProductQuery({ accountId: props.account.id, productId: props.productId });

  const product = data
    ? {
        ...data.product,
        price: data.product.priceMicros / 1000000,
        netMargin: data.product.netMarginMicros / 1000000,
        marketplaceName: (amazonAttributionIds.find((item) => item.id === data.product.marketplaceId) || {}).name,
      }
    : null;

  return (
    <Modal
      visible={true}
      title="Edit Product"
      width="750px"
      loading={isFetching}
      okButtonProps={{ loading: isEditing || isFetching }}
      onCancel={props.onCancel}
      onOk={form.submit}
    >
      <If _if={product} _else={<SmallCompactPlaceholder text="Loading product" />}>
        {(c) => (
          <Fragment>
            <Form
              form={form}
              layout="vertical"
              initialValues={product}
              onFinish={async (values) => {
                editProduct({
                  accountId: props.account.id,
                  product: {
                    ...values,
                    netMarginMicros: Math.round(values.netMargin * 1000000),
                  },
                  providerId: product.providerId,
                  productASIN: product.externalId,
                });
              }}
            >
              {product.externalId && (
                <Fragment>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 20,
                    }}
                  >
                    <div style={{ display: 'block' }}>
                      <Form.Item label="Product Name" name="name">
                        <Input size="large" />
                      </Form.Item>
                      {product.type === TYPE_PRODUCT && (
                        <>
                          <div style={{ display: 'flex' }}>
                            <div style={{ flexGrow: 1 }}>
                              <Form.Item label="Net margin by unit" name="netMargin">
                                <CurrencyInput size="large" currencyCode={currencyCode}></CurrencyInput>
                              </Form.Item>

                              <Paragraph>
                                <Title>Marketplace:</Title>
                                <Value>{product.marketplaceName}</Value>
                              </Paragraph>
                              <Paragraph>
                                <Title>ASIN:</Title>
                                <Value>{product.externalId}</Value>
                              </Paragraph>
                              <Paragraph>
                                <Title>Product URL:</Title>
                                <Value>
                                  <ShowProduct product={product} />
                                </Value>
                              </Paragraph>
                              <Paragraph>
                                <Title>Price:</Title>
                                <Value>
                                  <CurrencyValue
                                    amount={product.price}
                                    currency={getCurrencySymbol(product.currency)}
                                    suffix={<Fragment />}
                                  />
                                </Value>
                              </Paragraph>
                            </div>
                            <div style={{ flexGrow: 0, flexBasis: '140px', display: 'flex', alignItems: 'center' }}>
                              <Image style={{ maxWidth: '100%' }} src={product.productImageUrl} />
                            </div>
                          </div>
                          <Divider type="horizontal" />
                          <h3>Amazon search ranking</h3>
                          <MutedText>
                            Enter up to {maxKeywords} search terms or keywords to track and improve the ranking of your
                            product.
                          </MutedText>
                          <Form.Item
                            required
                            label=""
                            name="keywords"
                            normalize={(values) => keywordsNormalizer(values || [])}
                            rules={[
                              {
                                max: maxKeywords,
                                type: 'array',
                                message: `maximum ${maxKeywords} keywords per product`,
                              },
                              {
                                message: 'keywords should not be longer that 150 characters',
                                validator: (_, keywords) => {
                                  for (let i = 0; i < keywords.length; i++) {
                                    if (keywords[i].length > 150) {
                                      return Promise.reject('');
                                    }
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              tokenSeparators={[',', '&#13', '&#10']}
                              maxTagCount="responsive"
                              mode="tags"
                            ></Select>
                          </Form.Item>
                        </>
                      )}
                      <h3>
                        Competitor ASIN(s){' '}
                        <a
                          href="https://help.pixelme.me/en/articles/6873512-how-to-find-competing-asins"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ExclamationCircleOutlined style={{ fontSize: '13px', color: '#0E32F5' }} />
                        </a>
                      </h3>
                      <MutedText>Enter one ASIN at a time. Up to five competitor ASIN(s) to your product</MutedText>
                      <CompetitorsSelector marketplaceId={product.marketplaceId} />
                      <h3>{messages.productTags.title}</h3>
                      <MutedText>{messages.productTags.description}</MutedText>
                      <ProductTagsSelector account={props.account} size="large" maxWidth="100%" />
                    </div>
                  </div>
                </Fragment>
              )}
            </Form>
          </Fragment>
        )}
      </If>
    </Modal>
  );
}

const mapStateToProps = function (state, ownProps): any {
  return {
    limitations: state.accounts.limitations,
    ...ownProps,
  };
};

export default connect(mapStateToProps)(ProductEditModal);
