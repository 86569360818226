import React, { useState, type Node } from 'react';

import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { getCurrencyCode } from 'lib/amazon';
import axios from 'lib/axios.factory';
import { strings } from 'locales/strings';
import { useEditCampaignMutation } from 'stores/campaignsSlice';

import { CampaignAutomationsForm } from '../../../common/CampaignAutomationsForm';

const CampaignName = styled.div`
  font-size: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type propsT = {
  account: accountT,
  campaign: campaignT,
  visible: boolean,
  onFinish: () => any,
};
export const AutomationsModal = ({ account, campaign, visible, onFinish }: propsT): Node => {
  const [form] = useForm();
  const [formLoading, setFormLoading] = useState(false);
  const currencyCode = getCurrencyCode(account.productProviders);
  const [editCampaign] = useEditCampaignMutation();
  const { messages } = useIntl();

  return (
    <Modal
      visible={visible}
      title={
        <>
          {messages.campaignAutomations.title}
          <CampaignName>{campaign.name}</CampaignName>
        </>
      }
      width={650}
      loading={formLoading}
      okButtonProps={{ loading: formLoading }}
      okText="Save"
      onCancel={onFinish}
      onOk={form.submit}
    >
      <CampaignAutomationsForm
        form={form}
        currencyCode={currencyCode}
        initialValues={{ automationRules: campaign.automationRules }}
        onFinish={async (values) => {
          setFormLoading(true);

          try {
            await editCampaign({ accountId: account.id, campaignId: campaign.id, campaign: values });
            onFinish();
          } finally {
            setFormLoading(false);
          }
        }}
      />
    </Modal>
  );
};
