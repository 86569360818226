import React from 'react';

import styled from 'styled-components';

import { getCampaignFromId, getSubCampaignFromId } from 'lib/campaigns';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

const EmptyTable = styled.div.withConfig({ displayName: 'EmptyTable' })`
  color: var(--text-muted);
  font-size: 14px;
  border: 0.5px solid rgba(240, 240, 240);
  text-align: center;
`;

export const RedirectsListNoRedirect = ({ selectedCampaignId, selectedSubCampaignId }) => (
  <EmptyTable>
    <p
      dangerouslySetInnerHTML={{
        __html: getIntlStrings('redirects.empty', {
          campaignOrSubName: selectedSubCampaignId
            ? getSubCampaignFromId(selectedSubCampaignId).name
            : selectedCampaignId
              ? getCampaignFromId(selectedCampaignId).name
              : undefined,
        }),
      }}
    />
  </EmptyTable>
);
