import React, { useEffect } from 'react';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { API } from '../../../lib/api';
import { s } from '../../../lib/safe';
import { InputContainer, InputContainerHeader } from '../../ColorPickerWithLabel';

const Style = styled.div`
  .file-upload {
    display: none;
  }
  .avatar-uploader > .ant-upload {
    width: ${(props) => props.previewSize};
    max-height: ${(props) => props.previewSize};
  }

  .upload-button-container {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }

  .upload-icon {
    width: 100%;
    height: 100%;
    display: grid;
    align-content: center;
    align-self: center;
    color: white;
    position: absolute;
    top: 0;
  }

  :hover .upload-icon {
    color: var(--pixelme-color);
  }
`;

export function ImgUpload({
  account,
  onChange,
  value,
  previewSize,
  label,
  isBig,
}: {
  account: any,
  onChange: Function,
  value: string,
  previewSize: string,
  label: string,
  isBig?: boolean,
}) {
  const [uploadState, updateUploadState] = useImmer({
    loading: false,
    url: value,
  });

  useEffect(() => {
    updateUploadState((draft) => {
      draft.url = value;
    });
  }, [value]);
  async function handleChange(info) {
    try {
      if (info.file.status === 'uploading') {
        updateUploadState((draft) => {
          draft.loading = true;
        });

        return;
      }

      if (info.file.status === 'done') {
        updateUploadState((draft) => {
          draft.loading = false;
          draft.url = info.file.response.url;
        });
        onChange(info.file.response.url);
      }
    } catch {
      updateUploadState((draft) => {
        draft.loading = false;
      });
    }
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 10;

    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
    }

    return isJpgOrPng && isLt2M;
  }

  const uploadButton = (url) => (
    <div className="upload-button-container">
      {url ? (
        <div
          style={{
            width: '100%',
            backgroundImage: `url('${url}')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
        />
      ) : (
        <></>
      )}
      <div className="upload-icon">{uploadState.loading ? <LoadingOutlined /> : <PlusOutlined />}</div>
    </div>
  );

  const accessToken = localStorage.getItem('access-token');

  return (
    <Style previewSize={previewSize || '128px'}>
      <InputContainer>
        {label && (
          <InputContainerHeader isBig={isBig}>
            <label>{label}</label>
          </InputContainerHeader>
        )}
        {/* {uploadState.loading && <Spinner />} */}
        <Upload
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          action={`${s(s(window).env).REACT_APP_API_URL}/${API.IMAGE.POST.url}?account_id=${account.id}`}
          headers={{
            Authorization: `Bearer ${accessToken}`,
          }}
        >
          {uploadButton(uploadState.url)}
        </Upload>
      </InputContainer>
    </Style>
  );
}
