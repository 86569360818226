import React, { useContext, useEffect, useState, type Node } from 'react';

import { useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { SmallPlaceholder } from 'components/common/Placeholders';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { useFeatureFlag, CAMPAIGN_ANALYTICS_UPDATE } from 'lib/configcat';
import { getMetrics } from 'lib/metrics';
import { useGetAllCampaignsForProductQuery } from 'stores/campaignsSlice';
import { setProductMetrics } from 'stores/productMetricsSlice';

import { CampaignsDashboardHeader } from './CampaignsDashboardHeader';
import { CampaignsStats } from './CampaignsStats';
import { CampaignsTable } from './CampaignsTable';
import type { adsCampaignT, adsReportDataT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import { calculateStats } from '../../common/adsStats';
import { CHANNELS_FILTER, DashboardFilter } from '../../common/DashboardFilter';
import { KeyMetricsContainer } from '../KeyMetrics/KeyMetricsContainer';
import { ProductGraphsContainer } from '../ProductsTable/ProductGraphsContainer';

export type StateT = {
  allCampaigns: Array<adsCampaignT>,
  campaigns: Array<adsCampaignT>,
  stats: adsReportDataT,
};

const Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }
`;

const FilterContainer = styled.div`
  background-color: #fff;
  padding: 0.75rem 1rem;
`;

export function CampaignsDashBoard({
  account,
  productProviderId,
  productASIN,
}: {
  account: accountT,
  productProviderId: string,
  productASIN: string,
}): Node {
  const [showMetricsUpdate, isShowMetricsUpdateLoading] = useFeatureFlag(CAMPAIGN_ANALYTICS_UPDATE);
  const { ctx, updateCtx } = useContext(DashboardFilterContext);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);
  const dispatch = useDispatch();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [metricsLoading, setMetricsLoading] = useState(true);

  let [from, to] = ctx.adsReportsRange;

  from = from.toISOString();
  to = to.toISOString();

  const {
    isLoading: campaignsLoading,
    data,
    isSuccess,
  } = useGetAllCampaignsForProductQuery({
    accountId: account.id,
    from,
    to,
    with: 'productStats',
    providerId: productProviderId,
    productASIN,
  });

  const product = data?.product || {};
  const isAmazonProduct = product?.type === '';
  const productStats = data?.productStats || {};
  const allCampaigns =
    data?.campaigns?.map((campaign) => {
      const adsReport = (campaign.selectedAdsReports || {}).data;

      return { ...adsReport, ...campaign };
    }) || [];

  const filteredCampaigns = allCampaigns.filter((campaign) => {
    if (ctx.filterCampaignId?.length > 0) {
      if (!ctx.filterCampaignId.includes(campaign.id)) {
        return false;
      }
    }

    if (ctx.filterCampaignStatus) {
      if (campaign.status !== ctx.filterCampaignStatus) {
        return false;
      }
    }

    if (ctx.filterProviderType?.length > 0) {
      if (!ctx.filterProviderType.includes(campaign.providerType)) {
        return false;
      }
    }

    return true;
  });

  const stats = calculateStats(filteredCampaigns);

  const loadMetrics = async (filteredCampaignIds) => {
    const withArr = ['keywordMetrics', 'keyMetrics'];

    if (showMetricsUpdate) {
      withArr.push('tacos');
    }

    try {
      setMetricsLoading(true);
      const metrics = await getMetrics(productProviderId, productASIN, {
        accountId: account.id,
        from,
        to,
        campaigns: filteredCampaignIds ? filteredCampaignIds.join(',') : null,
        with: withArr.join(','),
      });

      setMetrics(metrics);
      dispatch(setProductMetrics(metrics));
    } finally {
      setMetricsLoading(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const urlParams = new URLSearchParams(window.location.search);
      const campaign = urlParams.get('campaign');

      if (campaign) {
        updateCtx((draft) => {
          draft.filterCampaignId = [campaign];
          draft.filterCampaignStatus = undefined;
        });
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isShowMetricsUpdateLoading) {
      loadMetrics(filteredCampaigns?.length === allCampaigns?.length ? [] : filteredCampaigns?.map((c) => c.id));
    }
  }, [ctx, data, isShowMetricsUpdateLoading]);

  return (
    <Style>
      <CampaignsDashboardHeader
        account={account}
        product={product}
        productStats={productStats}
        selectedRowKeys={selectedRowKeys}
      />
      <FilterContainer>
        <DashboardFilter
          attributedData={false}
          campaigns={allCampaigns}
          statusFilter
          channelsFilter={CHANNELS_FILTER.ONLY_AVAILABLE}
        />
      </FilterContainer>
      {isAmazonProduct && <KeyMetricsContainer keyMetrics={metrics.keyMetrics} loading={metricsLoading} />}
      <ProductGraphsContainer
        account={account}
        product={product}
        metrics={metrics}
        loading={metricsLoading}
        campaigns={filteredCampaigns}
      />
      {!metricsLoading && metrics ? (
        <CampaignsStats
          account={account}
          stats={stats}
          productASIN={productASIN}
          productProviderId={productProviderId}
        />
      ) : (
        <SmallPlaceholder text="Loading" />
      )}
      {!campaignsLoading && product ? (
        <CampaignsTable
          account={account}
          campaigns={filteredCampaigns}
          allCampaigns={allCampaigns}
          setSelectedRowKeys={setSelectedRowKeys}
          productASIN={productASIN}
          productId={product.id}
          productProviderId={productProviderId}
        />
      ) : (
        <SmallPlaceholder text="Loading" />
      )}
    </Style>
  );
}
