import './Gdpr.css';

import React, { Component } from 'react';

import { connect } from 'react-redux';

import { updateAccount } from 'lib/api';
import axios from 'lib/axios.factory';
import { validateCookieConsent } from 'lib/cookieConsent';
import { updateAccount as updateAccountAction } from 'stores/accounts';

import Box from '../../common/Box';
import Button, { LightButton, CancelButton } from '../../common/Button';
import DropDown from '../../common/dropDown/DropDown';
import Input from '../../common/Input';

const dropDownValues = [
  { id: 'true', value: 'Ask GDPR consent' },
  { id: 'false', value: "Don't ask GDPR consent" },
];

class Gdpr extends Component<{
  account: accountT,
  updateAccountAction: (account: accountT) => void,
}> {
  render() {
    const { account, updateAccountAction } = this.props;

    return (
      <div className="gdpr">
        <GdprForm account={account} updateAccountAction={updateAccountAction} />
      </div>
    );
  }
}

class GdprForm extends Component<
  {
    account: accountT,
    updateAccountAction: (account: accountT) => void,
  },
  {
    company: string,
    website: string,
    terms: string,
    active: boolean,
    calling: boolean,
    edit: boolean,
  },
> {
  constructor(props) {
    super(props);
    const {
      account: { cookieConsent },
    } = this.props;

    this.state = {
      ...cookieConsent,
      calling: false,
      edit: !validateCookieConsent(cookieConsent),
    };
  }
  render() {
    const { account } = this.props;
    const { company, website, terms, active, calling, edit } = this.state;

    return (
      <Box className="gdpr" header="GDPR settings">
        <div className="gdpr__form-group">
          <label htmlFor="company">Name of your company</label>
          {!edit && <div className="gdpr__field gdpr__company">{company}</div>}
          {edit && (
            <Input
              className="gdpr__field"
              value={company}
              id="company"
              placeholder="Company name"
              onChange={this.handleChange}
            />
          )}
        </div>
        <div className="gdpr__form-group">
          <label htmlFor="website">Redirect to your website</label>
          {!edit && (
            <div className="gdpr__field gdpr__website">
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a target="_blank" href={website}>
                {website}
              </a>
            </div>
          )}
          {edit && (
            <Input
              className="gdpr__field"
              value={website}
              id="website"
              placeholder="https://google.com"
              onChange={this.handleChange}
            />
          )}
        </div>
        <div className="gdpr__form-group">
          <label htmlFor="terms">Redirect to your terms</label>
          {!edit && (
            <div className="gdpr__field gdpr__terms">
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a target="_blank" href={terms}>
                {terms}
              </a>
            </div>
          )}
          {edit && (
            <Input
              className="gdpr__field"
              value={terms}
              id="terms"
              placeholder="https://google.com/tos"
              onChange={this.handleChange}
            />
          )}
        </div>
        <div className="gdpr__form-group">
          <label htmlFor="terms">Default behaviour on redirect creation</label>
          {!edit && (
            <div className="gdpr__field gdpr__active">
              {active && 'Ask GDPR consent'}
              {!active && "Don't ask GDPR consent"}
            </div>
          )}
          {edit && (
            <DropDown
              size="medium"
              id="active"
              values={dropDownValues}
              selected={[active ? 'true' : 'false']}
              onSelect={(selected) => this.setState({ active: selected[0] === 'true' })}
              multi={false}
            />
          )}
        </div>
        <div className="gdpr__buttons">
          {edit && (
            <div>
              <Button
                disabled={!this.validateFields() || !this.fieldsChanged()}
                loading={calling}
                loadingLabel="Updating..."
                handleClick={this.handleSubmit}
              >
                Save
              </Button>
              <CancelButton
                handleClick={() =>
                  this.setState({
                    edit: false,
                    ...account.cookieConsent,
                  })
                }
              >
                Cancel
              </CancelButton>
            </div>
          )}
          {!edit && <LightButton handleClick={() => this.setState({ edit: true })}>Edit</LightButton>}
        </div>
      </Box>
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { company, terms, website, active } = this.state;
    const { account, updateAccountAction } = this.props;

    this.setState({ calling: true });

    if (this.validateFields() && this.fieldsChanged()) {
      updateAccount(
        localStorage,
        axios,
        { accountId: account.id },
        {
          cookieConsent: { company, terms, website, active },
        },
      )
        .then(({ account: newAccount }) => {
          this.setState({
            calling: false,
            edit: false,
          });

          return updateAccountAction(newAccount);
        })
        .catch((_) => {
          this.setState({ calling: false });
        });
    }
  };

  validateFields = (): boolean => validateCookieConsent(this.state);

  fieldsChanged = (): boolean => {
    const { cookieConsent } = this.props.account;

    return (
      this.state.company !== cookieConsent.company ||
      this.state.website !== cookieConsent.website ||
      this.state.terms !== cookieConsent.terms ||
      this.state.active !== cookieConsent.active
    );
  };
}

export default connect(() => ({}), {
  updateAccountAction,
})(Gdpr);
