import React from 'react';

import { Tooltip } from 'react-tippy';

export class BarToolTip extends React.Component {
  render() {
    return (
      <Tooltip title={this.props.title} arrow="true" theme="light" size="large" position="right">
        {this.props.children}
      </Tooltip>
    );
  }
}
