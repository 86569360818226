export function getListItems(utmsElements: Array<{ value: string }>) {
  return (utmsElements || []).map((utmsElement) => {
    if (typeof utmsElement.value !== 'string') {
      throw new Error(`utms must be a string${JSON.stringify(utmsElement)}`);
    }

    return { id: utmsElement.value, value: utmsElement.value };
  });
}

export function getValue(utms: string) {
  if (utms && utms.replace) {
    return utms.replace(/[^A-Za-z0-9 _-]+/g, '');
  } else {
    return utms;
  }
}

export const getListItemsFromString = (utms: Array<tagT>) =>
  (utms || []).map((utm) => ({
    id: utm,
    value: utm,
    name: utm,
  }));

export const getListItemFromString = (utm) => ({
  id: utm,
  value: utm,
  name: utm,
});
