import React from 'react';

import './CreateFolderButton.css';
import styled from 'styled-components';

import { Plus } from 'components/common/icons/Plus';

const CreateFolButton = styled.button.withConfig({
  displayName: 'CreateFolButton',
})`
  background: var(--white);
  border: 1px solid var(--pixelme-color);
  color: var(--pixelme-color);
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  width: 147px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    background-color: rgb(192, 220, 246);
  }
`;

const CreateFolderButton = () => (
  <CreateFolButton>
    <Plus style={{ width: '1rem', marginRight: '0.5rem' }} /> Create a folder
  </CreateFolButton>
);

export default CreateFolderButton;
