// see https://stripe.com/docs/stripe-js/elements/iban
// test IBAN: DE89 3704 0044 0532 0130 00

import React, { useEffect, useState } from 'react';

import { Elements, IbanElement, injectStripe, StripeProvider } from 'react-stripe-elements';
import styled from 'styled-components';

import Box from 'components/common/Box';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { Line } from 'components/common/Placeholders';
import { stripePk } from 'lib/stripe';
import { validateEmail } from 'lib/util';

const fonts = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:300,300i,400,400i,500,500i,600,600i',
  },
];

const style = {
  base: {
    'fontFamily': 'var(--font-family)',
    'fontSize': '12px',
    'fontWeight': 400,
    '::placeholder': {
      color: '#828282',
      fontWeight: 'lighter',
    },
  },
};

const IBANForm = styled.div.withConfig({
  displayName: 'IBANForm',
})`
  & > label > div {
    margin: 10px 0px;
  }
  max-width: 600px;
  margin-top: 20px;
`;

const Info = styled.p.withConfig({
  displayName: 'Info',
})`
  background: var(--pixelme-light-color);
  margin: 20px 10px;
  padding: 10px;
`;

const ContactUs = styled.p.withConfig({
  displayName: 'ContactUs',
})`
  color: var(--pixelme-color);
`;

const BankAccountDiv = styled.div.withConfig({
  displayName: 'BankAccount',
})`
  background: var(--pixelme-light-color);
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
`;

const BankAccount = function ({ bankAccount }: { bankAccount: bankAccountT }) {
  return (
    <BankAccountDiv>
      <p>
        IBAN: {bankAccount.country} XXXX XXXX XXXX {bankAccount.last4Digits}
      </p>
      <p>Bank code: {bankAccount.bankCode}</p>
      <p>
        <a href={bankAccount.mandateUrl} target="_blank" rel="noopener noreferrer">
          View mandate
        </a>
      </p>
    </BankAccountDiv>
  );
};

function SepaForm({ stripe, accountId, addBankAccount, vat }) {
  const [ibanValid, setIbanValid] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const validateFields = function () {
    return validateEmail(email) && name.length > 4 && ibanValid;
  };

  let country = 'FR';

  if (vat && vat.country && vat.country.length > 0) {
    country = vat.country.toUpperCase();
  }

  return (
    <IBANForm>
      <label>
        Name
        <Input id="name" placeholder="Josephina Bloggs" onChange={(e) => setName(e.currentTarget.value)} value={name} />
      </label>
      <label>
        Email Address
        <Input
          id="email"
          placeholder="josephina.bloggs@example.com"
          onChange={(e) => setEmail(e.currentTarget.value)}
          value={email}
        />
      </label>
      <label>
        IBAN
        {!stripe && (
          <div style={style}>
            <input disabled placeholder="Waiting for stripe..." />
          </div>
        )}
        {stripe && (
          <IbanElement
            style={style}
            supportedCountries={['SEPA']}
            placeholderCountry={country}
            // hidePostalCode
            onChange={(data) => {
              setIbanValid(data.complete);
            }}
          />
        )}
      </label>
      <Info>
        By providing your IBAN and confirming this payment, you are authorizing PixelMe SAS and Stripe, our payment
        service provider, to send instructions to your bank to debit your account and your bank to debit your account in
        accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions
        of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your
        account was debited.
      </Info>
      <Button
        disabled={!validateFields()}
        handleClick={(_) => {
          // Within the context of `Elements`, this call to createToken knows which Element to
          // tokenize, since there's only one in this group.
          const sourceQuery = {
            type: 'sepa_debit',
            currency: 'eur',
            owner: {
              name,
              email,
            },
            mandate: {
              // Automatically send a mandate notification email to your customer
              // once the source is charged.
              notification_method: 'email',
            },
          };

          stripe
            .createSource(sourceQuery)
            .then(({ source }) => {
              addBankAccount({ accountId, source });
            })
            .catch((err) => console.error(err));
        }}
      >
        Add IBAN
      </Button>
    </IBANForm>
  );
}

const InjectedSepaForm = injectStripe(SepaForm, { withRef: false });

function SepaDetails({ vat, accountId, addBankAccount, bankAccount, bankAccountUpdating }) {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (window.Stripe) {
      setStripe(window.Stripe(stripePk));
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window.Stripe(stripePk));
      });
    }
  }, [window.Stripe]);

  if (bankAccountUpdating) {
    return (
      <Box header={<Line />}>
        <Line />
        <Line />
      </Box>
    );
  }

  if (bankAccount && bankAccount.last4Digits) {
    return (
      <Box header="SEPA details">
        <p>Currently using the IBAN:</p>
        <BankAccount bankAccount={bankAccount} />
        <ContactUs>If you need to change it, please contact us.</ContactUs>
      </Box>
    );
  }

  return (
    <Box header="Update SEPA details">
      <StripeProvider stripe={stripe}>
        <Elements fonts={fonts}>
          <InjectedSepaForm accountId={accountId} addBankAccount={addBankAccount} vat={vat} />
        </Elements>
      </StripeProvider>
    </Box>
  );
}

export default SepaDetails;
