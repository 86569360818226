import { toCamelCaseKeys } from './util';

/**
 * Return Message From Axios Error
 * @param error
 * @returns {{errorMessage: string, code: string}}
 */
export function getMessageFromAxiosError(error) {
  let errorMessage = { errorMessage: 'Unknown error', code: 'UNKNOWN' };

  if (error.response && error.response.data) {
    errorMessage = toCamelCaseKeys(error.response.data);
  }

  return errorMessage;
}
