import React, { Fragment } from 'react';

import { Keys, MenuItem } from '@blueprintjs/core';
import * as _ from 'lodash';
import styled from 'styled-components';

import { addTag } from 'lib/api';
import axios from 'lib/axios.factory';
import { sArray } from 'lib/safe';
import { updateAccount } from 'stores/accounts';

import { tagsFromAccount } from '../../../../../../lib/tags';
import { getListItems, getListItemsFromString } from '../../../../../../selectors/tags.selector';
import NewMultiDropDown from '../../../../../common/dropDown/NewMultiDropDown';

const Style = styled.div.attrs({
  displayName: 'ComposerPixelsMultiSelectStyle',
})`
  .pixel {
    margin-right: 0.4rem;
    flex-shrink: 0;
  }
`;

export function ComposerTagsMultiSelect({
  account,
  redirect,
  onChange,
  onSelect,
  localUpdateOfAccount,
}: {
  account: accountT,
  redirect: redirectT,
  onChange: () => void,
}) {
  const handleAddTag = (selectedTags, tag: string) => {
    if (account.tags.map((t) => t.name).indexOf(tag) >= 0) {
      if (selectedTags.indexOf(tag) >= 0) {
        return;
      }

      onSelect([...selectedTags, tag]);

      return;
    }

    addTag(localStorage, axios, { accountId: account.id }, { tag: { name: tag, description: '' } }).then(
      ({ account }) => {
        updateAccount(account);
        localUpdateOfAccount({ account });
        onSelect([...selectedTags, tag]);
      },
    );
  };

  const allTagsListItems = getListItems(tagsFromAccount(account));
  const selectedTagListItems = getListItemsFromString(sArray(redirect.tags));

  const availableTagsListItems = _.differenceBy(allTagsListItems, selectedTagListItems, 'name');

  redirect.tags = sArray(redirect.tags);

  return (
    <Style>
      <div className="dropdown_inline">
        <div className="dropdowns__header">
          <label htmlFor="tag-provider">Select your tag(s)</label>
        </div>
        <NewMultiDropDown
          id="tag-provider"
          placeholder="Select tags"
          values={availableTagsListItems.length ? availableTagsListItems : []}
          noResults={<MenuItem text="Type the tag name" />}
          onSelect={(tagObjectToSelect, event) => {
            if (event.type === 'keyup') {
              return;
            }

            const tagObjectToSelectAlreadySelected = _.find(redirect.tags, (tag) => tag === tagObjectToSelect.name);
            const newRedirect = { ...redirect };

            if (!tagObjectToSelectAlreadySelected) {
              newRedirect.tags.push(tagObjectToSelect.name);
              onChange({ redirect: newRedirect });
            }
          }}
          onRemove={(tagTagFragmentToRemove) => {
            const newRedirect = { ...redirect };

            newRedirect.tags = _.remove(redirect.tags, (tag) => tagTagFragmentToRemove.key !== tag);
            onChange({ redirect: newRedirect });
          }}
          selected={selectedTagListItems}
          renderTag={(tag) => (
            <Fragment key={tag.name}>
              <div style={{ display: 'flex' }}>{tag.name}</div>
            </Fragment>
          )}
          renderItem={(tag, { handleClick, query }) => {
            if (query && tag.value && tag.value.toLowerCase().indexOf(query.toLowerCase()) === -1) {
              return null;
            }

            return <MenuItem key={tag.name} text={tag.name} onClick={handleClick} />;
          }}
          onKeyUp={(event) => {
            event.preventDefault();

            if (event.keyCode === Keys.ENTER) {
              handleAddTag(sArray(redirect.tags), event.target.value);
            }
          }}
          createNewItemRenderer={(query: string, active: boolean, handleClick) => (
            <MenuItem
              icon="add"
              className="bp3-add-option"
              text={`Add "${query}"`}
              active={active}
              onClick={handleClick}
            />
          )}
        />
      </div>
    </Style>
  );
}
