import React from 'react';

import { message, Popconfirm, Tooltip } from 'antd';

import { useDeleteCampaignMutation } from 'stores/campaignsSlice';

import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';
import { trackedEvents } from '../../../../../config/trackedEvents.config';
import { useLoading } from '../../../../../hooks/useLoading';
import { track } from '../../../../../lib/analytics';
import { Delete } from '../../../../common/icons';
import { IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';

export function DeleteCampaign(props: { account: accountT, campaign: adsCampaignT }) {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);
  const [deleteCampaign] = useDeleteCampaignMutation();

  return (
    <ExclusiveInlineLoadingContainer>
      <Popconfirm
        title="Are you sure to want to delete"
        onConfirm={async () => {
          doAction(async () => {
            await deleteCampaign({ accountId: props.account.id, campaignId: props.campaign.id });
          });
        }}
      >
        <div>
          <IconInsideButton>
            <Tooltip title="Delete Campaign" placement="bottom">
              <Delete />
            </Tooltip>
          </IconInsideButton>
        </div>
      </Popconfirm>
    </ExclusiveInlineLoadingContainer>
  );
}
