import React, { useEffect, useState } from 'react';

import Button from 'antd/lib/button';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { Amazon } from '../../common/icons/Amazon';
import { SmallPlaceholder } from '../../common/Placeholders';
import SignLayout from '../../layout/Sign/SignLayout';
import { Signup } from '../easy/images/Signup';
import { useQueryParams } from 'hooks/useQueryParams';
import { WIDGET_API_CONNECTION } from 'consts/consts';

const Style = styled.div`

  a ,a:hover{
    text-decoration: none !important;
  }
<style>
`;

export function AmazonLoginButton({ btnStyle, title, primary, accountId }) {
  const [redirectUrl, setRedirectUrl] = useState(undefined);
  const query = useQueryParams();
  const widgetStartType = query.get(WIDGET_API_CONNECTION);

  const handleClick = () => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  btnStyle = btnStyle || {};
  primary = primary || false;
  async function load() {
    const { data } = await axios.get('product-providers/amazon/redirect-url');

    setRedirectUrl(
      `https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.9be562139317498d9aa657067331f22d&scope=advertising::campaign_management%20profile&response_type=code&state=aId:${accountId}&redirect_uri=${data.url}`,
    );
  }
  useEffect(() => {
    load().then();
  }, []);

  useEffect(() => {
    if (widgetStartType === 'amazon_ads' && redirectUrl) {
      localStorage.setItem('hasWidgetWorkFlowStarted', true);
      handleClick();
    }
  }, [widgetStartType, redirectUrl]);

  if (!redirectUrl) {
    return <SmallPlaceholder text="Please wait a few seconds" />;
  }

  if (primary) {
    return (
      <Button type="primary" href={redirectUrl}>
        {title || 'Login with Amazon'}
      </Button>
    );
  }

  return (
    <Style>
      <a style={{ textDecoration: 'none !important' }} onClick={handleClick}>
        <Button
          className="amazon-login-button LoginWithAmazon"
          block
          style={{
            alignItems: 'center',
            color: 'white',
            background: '#F8A51C',
            borderColor: '#F8A51C',
            display: 'flex',
            height: 'fit-content',
            ...btnStyle,
          }}
        >
          <div
            style={{
              margin: 'auto',
              gap: '20px',
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <Amazon style={{ width: '24px' }} />
            <span>{title || 'Login with Amazon'}</span>
          </div>
        </Button>
      </a>
    </Style>
  );
}

/**
 * Amazon Login Page
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AmazonLoginPage(props: { style: any, accountId: string, account: any }) {
  return (
    <SignLayout
      header={<></>}
      title={
        <>
          Amazon Attribution <span style={{ color: '#2E89E2', fontWeight: 700 }}>BETA</span>
        </>
      }
      description={
        <>
          Connect your Amazon attribution account to PixelMe to get Amazon page views,
          <br /> add to carts, purchases and more <b>directly on PixelMe for each of your short links</b>.
        </>
      }
      footer={
        <span style={{ float: 'left' }}>
          <p>
            <b>No Amazon attribution account yet?</b>{' '}
            <a href="https://sellercentral.amazon.com/attribution">Please request an access here.</a>
          </p>
          <br />
          <p>
            Note: Amazon Attribution is only available for Amazon 3P Sellers <br />
            and Amazon 1P vendors.{' '}
            <a href="https://www.amazon.com/forms/3635f20b-f743-4f6a-99a1-4150eb5b4d00?ref_=a20m_us_p_attr_gs_sgnup">
              Learn more
            </a>
            .{' '}
          </p>
        </span>
      }
      image={<Signup />}
    >
      <AmazonLoginButton accountId={props.accountId} />
    </SignLayout>
  );
}
