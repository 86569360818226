import React from 'react';

import styled from 'styled-components';

const Style = styled.div`
  background: white;
  padding: 1em;
  z-index: 0;
  grid-area: preview;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  iframe {
    overflow-x: hidden;
    overflow-y: hidden;
    border: 10px solid black;
    border-radius: 40px;
    width: 250px;
    height: 505px;
  }
  iframe::-webkit-scrollbar {
    display: none;
  }
`;

export function PageBlocksPreview({ previewUrl }) {
  // TODO comment this section
  let name = previewUrl;

  if (previewUrl && previewUrl.includes('localhost')) {
    const first_part_url = previewUrl;
    const fields = first_part_url.split('?');

    name = fields[0];
  }

  return (
    <Style>
      <iframe title="preview" src={name} width="212" height="420" style={{}} />
    </Style>
  );
}
