import React, { useState, type Node } from 'react';

import { Checkbox, Select, Tooltip } from 'antd';
import styled from 'styled-components';

import { MultiSelect } from 'components/common/antd/MultiSelect';

import { RankingToggle } from './RankingToggle';
import { TOOLTIPS } from '../constants';

const Container = styled.div`
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
  padding: 1rem;
  display: flex;
  column-gap: 1.5rem;
`;

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1.5rem;
`;

type propsT = {
  data: {
    id: string,
    active: boolean,
    selected: boolean,
    disabled: boolean,
    purchases: string,
    searchVolume: string,
    color: string,
  }[],
  selected: string[],
  onSelect: (string[]) => void,
  onToggle: (string) => void,
  onDelete: (string) => void,
};

export const RankingGraphLegend = ({ data, selected, onSelect, onToggle, onDelete }: propsT): Node => {
  const [searchValue, setSearchValue] = useState('');

  // Hide tags in antd/Select and show placeholder when not searching
  const hideTagsProps = {
    tagRender: () => (searchValue ? '' : 'Select keywords to view'),
    hideTags: true,
    searchValue,
    onSearch: setSearchValue,
    width: 225,
  };

  return (
    <Container>
      <MultiSelect
        showSearch
        filterOption
        placeholder="Select keywords to view"
        value={selected}
        onChange={onSelect}
        dropdownMatchSelectWidth={false}
        autoClearSearchValue={false}
        {...hideTagsProps}
      >
        {data.map((r) => (
          <Select.Option key={r.id} value={r.id} disabled={r.disabled}>
            <Tooltip placement="right" title={r.disabled ? TOOLTIPS.KEYWORDS_MAX : ''}>
              <div>
                {/* Ensures max width so tooltip is consistently on the right */}
                <Checkbox checked={r.selected} disabled={r.disabled} />
                &nbsp;
                {r.id}
              </div>
            </Tooltip>
          </Select.Option>
        ))}
      </MultiSelect>
      <FlexWrap>
        {data.map((r) =>
          r.selected ? (
            <RankingToggle
              key={r.id}
              active={r.active}
              label={r.id}
              sales={r.purchases}
              volume={r.searchVolume}
              color={r.color}
              onToggle={onToggle}
              onDelete={onDelete}
            />
          ) : null,
        )}
      </FlexWrap>
    </Container>
  );
};
