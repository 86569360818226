import React from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import { Buttonize } from 'components/common/Buttonize';
import { Search } from 'components/common/icons/Search';

const SearchButtonContainer = styled(Buttonize).withConfig({
  displayName: 'SearchButtonContainer',
})`
  flex: 0 0 25px;
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  margin: 20px;
  svg :nth-child(1) {
    stroke: ${(props) => (props.selected ? 'var(--pixelme-color)' : '')};
  }

  svg :nth-child(2) {
    fill: ${(props) => (props.selected ? 'var(--pixelme-color)' : '')};
  }
`;

const AbstractSearchButton = function ({ filterConfig, toggleFilter }) {
  return (
    <SearchButtonContainer selected={filterConfig.open}>
      <Search onClick={toggleFilter} />
    </SearchButtonContainer>
  );
};

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  filterConfig: state.front.filterConfig,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  toggleFilter: () => dispatch(Actions.front.audienceBuilder.redirect.filter.toggle.request()),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) => {};

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const SearchButton = reduxWrapper(AbstractSearchButton);
