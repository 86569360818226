import React, { useContext, useEffect, useState, type Node } from 'react';

import moment from 'moment';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import DismissableInfo from 'components/common/DismissableInfo';
import { SmallCompactPlaceholder } from 'components/common/Placeholders';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useGetVariationsByAdGroupIdQuery } from 'stores/variationsSlice';

import { VariationsDashboardHeader } from './VariationsDashboardHeader';
import { VariationsStats } from './VariationsStats';
import { VariationsTable } from './VariationsTable';
import { calculateStats } from '../../common/adsStats';
import { VARIATIONS } from '../constants';
import { useIntl } from 'react-intl';

export type StateT = {
  variations: Array<variationT>,
  product: productT,
  stats: adsReportDataT,
};

const Style = styled.div`
  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }
`;

type VariationsDashBoardProps = {
  account: accountT,
  adGroupId: string,
  productProviderId: string,
  productASIN: string,
};

export const VariationsDashBoard = ({
  account,
  adGroupId,
  productProviderId,
  productASIN,
}: VariationsDashBoardProps): Node => {
  const { ctx } = useContext(DashboardFilterContext);
  const [from, to] = ctx.adsReportsRange || [];

  const initialState: StateT = {
    variations: [],
    product: {},
    stats: {},
  };
  const [filtered, updateFiltered] = useImmer(initialState);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { messages } = useIntl();

  const {
    allVariations = [],
    campaign = {},
    product = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetVariationsByAdGroupIdQuery(
    {
      adGroupId,
      from: from && moment(from).toISOString(),
      to: to && moment(to).toISOString(),
      ...(ctx.showRank && { with: 'volumes' }),
    },
    {
      skip: !adGroupId,
      selectFromResult: ({ data = {}, ...res }) => {
        const variationsResults = data.variations?.map((variation) => {
          const [, keyword, matchType] = variation.name.split('|');

          return {
            ...variation,
            ...variation.selectedAdsReports?.data,
            ...([VARIATIONS.KEYWORD, VARIATIONS.CREATIVE].includes(variation.type) && { keyword, matchType }),
          };
        });

        return {
          ...res,
          allVariations: variationsResults,
          campaign: data.campaign,
          product: data.product,
        };
      },
    },
  );

  const onProductSave = (p) => {
    updateFiltered((draft) => {
      draft.product = p;
    });
  };

  const filterOnSearch = () => {
    let filtered = allVariations || [];

    if (ctx.searchVariation) {
      filtered = filtered.filter((v) => v.name.toLowerCase().includes(ctx.searchVariation));
    }

    if (ctx.filterStatus) {
      filtered = filtered.filter((v) => v.status === ctx.filterStatus);
    }

    updateFiltered((draft) => {
      draft.variations = filtered;
      draft.product = product;
      draft.stats = calculateStats(filtered);
    });
  };

  useEffect(() => {
    if (!isFetching) {
      filterOnSearch();
    }
  }, [ctx.searchVariation, ctx.filterStatus, isFetching]);

  return (
    <Style>
      <DismissableInfo
        style={{ margin: '20px 0' }}
        name="shopper-privacy"
        learnMore="https://www.carbon6.io/pixelme-help/why-am-i-seeing-less-purchases-and-add-to-carts-on-my-keywords-than-my-campaign-2"
      >
        <p>{messages.locales.variations.info} </p>
        <p>{messages.locales.variations.infoTwo}</p>
      </DismissableInfo>
      <VariationsDashboardHeader
        account={account}
        campaign={campaign}
        adGroupId={adGroupId}
        product={filtered.product}
        selectedRowKeys={selectedRowKeys}
        reload={refetch}
      />
      {isLoading ? (
        <SmallCompactPlaceholder text="" />
      ) : (
        <>
          <VariationsStats
            account={account}
            stats={filtered.stats}
            productASIN={productASIN}
            productProviderId={productProviderId}
          />
          <VariationsTable
            account={account}
            product={filtered.product}
            variationType={allVariations[0]?.type}
            variations={filtered.variations}
            setSelectedRowKeys={setSelectedRowKeys}
            onProductSave={onProductSave}
            productProviderId={productProviderId}
          />
        </>
      )}
    </Style>
  );
};
