import './C6Logo.css';
import React from 'react';

function C6Logo() {
  return (
    <div className="c6logo">
      <svg width="158" height="36" viewBox="0 0 158 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.0283 14.8725V8.9235L25.8551 5.949L22.7046 7.76043L20.6874 6.6001V2.9745L15.5142 0L10.341 2.9745V6.60101L8.32372 7.76043L5.17322 5.949L0 8.9235V14.8716L3.15416 16.6848V19.0092L0 20.8233V26.7714L5.17322 29.7459L8.33833 27.9263L10.341 29.0775V32.704L15.5142 35.6785L20.6874 32.704V29.0775L22.69 27.9263L25.8551 29.7459L31.0283 26.7714V20.8233L27.8742 19.0101V16.6858L31.0283 14.8725ZM20.6819 20.8233V24.4371L18.6683 25.5947L15.5142 23.7814L12.36 25.5947L10.3464 24.4371V20.8233L7.19228 19.0101V16.6858L10.3464 14.8725V11.2414L12.36 10.0838L15.5142 11.8971L18.6683 10.0838L20.6819 11.2414V14.8725L23.8361 16.6858V19.0101L20.6819 20.8233Z"
          fill="black"
        />
        <path
          d="M46.3388 27.1161C43.6595 27.1161 41.4368 26.2286 39.6706 24.4526C37.9045 22.6766 37.0215 20.4724 37.0215 17.838C37.0215 15.2045 37.9045 12.9993 39.6706 11.2233C41.4368 9.44734 43.6595 8.55981 46.3388 8.55981C47.9423 8.55981 49.4272 8.92901 50.7924 9.66832C52.1576 10.4076 53.2324 11.4143 54.0178 12.6892L50.4855 14.728C50.0929 14.0314 49.5294 13.4922 48.7961 13.1093C48.0619 12.7274 47.2428 12.5355 46.3388 12.5355C44.7516 12.5355 43.485 13.0202 42.5371 13.9887C41.5902 14.9571 41.1162 16.2402 41.1162 17.838C41.1162 19.4357 41.5902 20.7179 42.5371 21.6873C43.4841 22.6566 44.7516 23.1404 46.3388 23.1404C47.2428 23.1404 48.0665 22.9449 48.8089 22.5539C49.5514 22.1629 50.1102 21.6282 50.4855 20.948L54.0178 22.9876C53.2498 24.2626 52.1832 25.2692 50.8179 26.0085C49.4527 26.746 47.9597 27.1161 46.3388 27.1161Z"
          fill="black"
        />
        <path
          d="M67.3285 26.7588L66.4327 23.9552H59.7774L58.8815 26.7588H54.4023L60.4942 8.91541H65.7158L71.8077 26.7588H67.3285ZM61.0065 20.1314H65.2045L63.105 13.5804L61.0065 20.1314Z"
          fill="black"
        />
        <path
          d="M82.7633 26.7588L79.3845 20.8962H77.4394V26.7588H73.3438V8.91541H80.5114C82.2181 8.91541 83.6683 9.51012 84.8627 10.6996C86.0572 11.889 86.6544 13.334 86.6544 15.0326C86.6544 16.1202 86.3512 17.1269 85.7458 18.0535C85.1394 18.9801 84.3422 19.6976 83.3523 20.2078L87.1667 26.7588H82.7633ZM77.4394 12.7392V17.3278H80.5114C81.0748 17.3278 81.557 17.1069 81.9579 16.6649C82.3588 16.223 82.5597 15.6792 82.5597 15.0335C82.5597 14.3879 82.3588 13.8441 81.9579 13.4022C81.557 12.9602 81.0748 12.7392 80.5114 12.7392H77.4394Z"
          fill="black"
        />
        <path
          d="M100.197 17.5061C101.664 18.4409 102.399 19.7831 102.399 21.5336C102.399 23.0804 101.844 24.3372 100.735 25.3065C99.6252 26.275 98.2517 26.7597 96.6135 26.7597H89.0879V8.91541H96.1021C97.7057 8.91541 99.0499 9.38736 100.134 10.3304C101.218 11.2734 101.759 12.501 101.759 14.0132C101.758 15.4409 101.238 16.6058 100.197 17.5061ZM96.1012 12.7392H93.1826V15.9002H96.1012C96.5623 15.9002 96.9367 15.7519 97.2271 15.4537C97.5175 15.1563 97.6627 14.778 97.6627 14.3197C97.6627 13.8605 97.5175 13.4831 97.2271 13.1857C96.9377 12.8884 96.5623 12.7392 96.1012 12.7392ZM96.6135 22.9358C97.1258 22.9358 97.5349 22.7794 97.8417 22.4639C98.1486 22.1492 98.3029 21.7373 98.3029 21.2281C98.3029 20.7188 98.1495 20.3069 97.8417 19.9913C97.5349 19.6767 97.1249 19.5194 96.6135 19.5194H93.1835V22.9349H96.6135V22.9358Z"
          fill="black"
        />
        <path
          d="M119.599 24.4399C117.79 26.224 115.588 27.1161 112.995 27.1161C110.401 27.1161 108.199 26.224 106.391 24.4399C104.582 22.6557 103.678 20.4551 103.678 17.838C103.678 15.2208 104.582 13.0202 106.391 11.236C108.2 9.45189 110.401 8.55981 112.995 8.55981C115.588 8.55981 117.79 9.45189 119.599 11.236C121.408 13.0202 122.312 15.2217 122.312 17.838C122.312 20.4542 121.407 22.6548 119.599 24.4399ZM109.27 21.6609C110.268 22.6466 111.51 23.1395 112.995 23.1395C114.48 23.1395 115.721 22.6466 116.72 21.6609C117.718 20.6752 118.218 19.4011 118.218 17.8371C118.218 16.2739 117.718 14.999 116.72 14.0132C115.722 13.0275 114.48 12.5346 112.995 12.5346C111.51 12.5346 110.269 13.0275 109.27 14.0132C108.272 14.999 107.772 16.2739 107.772 17.8371C107.772 19.4011 108.272 20.6761 109.27 21.6609Z"
          fill="black"
        />
        <path
          d="M134.6 8.91541H138.696V26.7588H135.624L128.713 17.0723V26.7588H124.617V8.91541H127.689L134.6 18.6019V8.91541Z"
          fill="black"
        />
        <path
          d="M152.034 14.5489C153.808 14.6853 155.247 15.3309 156.347 16.4858C157.447 17.6416 157.998 19.0856 157.998 20.8189C157.998 22.6712 157.38 24.1835 156.143 25.3565C154.905 26.5287 153.305 27.1152 151.343 27.1152C149.363 27.1152 147.759 26.5287 146.53 25.3565C145.302 24.1844 144.688 22.6721 144.688 20.8189C144.688 19.4421 145.037 18.2363 145.737 17.1996L151.215 8.91541H155.925L152.034 14.5489ZM149.526 22.6294C150.021 23.0713 150.626 23.2923 151.343 23.2923C152.06 23.2923 152.665 23.0677 153.16 22.6167C153.655 22.1665 153.902 21.5673 153.902 20.8198C153.902 20.0723 153.655 19.473 153.16 19.0229C152.665 18.5728 152.06 18.3472 151.343 18.3472C150.626 18.3472 150.021 18.5682 149.526 19.0102C149.031 19.4521 148.783 20.055 148.783 20.8198C148.783 21.5845 149.031 22.1884 149.526 22.6294Z"
          fill="black"
        />
      </svg>
    </div>
  );
}

export default C6Logo;
