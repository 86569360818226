import React, { Fragment, useEffect, useState } from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import axios from 'lib/axios.factory';

import { getFacebookPages, getInstagramAccounts } from '../../../../lib/facebookPlateformApi';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

export function InstagramAccountsSelector(props: { adsProvider: adsProviderT, facebookPageId: string }) {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  async function load(searchValue) {
    setLoading(true);

    try {
      // Retrieve selected page's access token
      const facebookPages = await getFacebookPages(localStorage, axios, {
        id: props.adsProvider.externalAccountResourceName,
        accessToken: props.adsProvider.accessToken,
      });

      const page = facebookPages.filter((p) => p.id === props.facebookPageId)[0];

      const response = await getInstagramAccounts(localStorage, axios, page.accessToken, props.facebookPageId);

      setAccounts(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (props.facebookPageId) {
      load().then();
    }
  }, [props.facebookPageId]);

  return (
    <Fragment>
      {loading && <SmallCompactPlaceholder text="Loading current accounts" />}
      {!loading && (
        <Select
          size="large"
          {..._.omit(props, 'facebookPageId', 'adsProvider')}
          style={{ width: '100%' }}
          placeholder="Select your Instagram"
        >
          {accounts.map((c) => (
            <Select.Option disabled={c.id === 0} value={c.id}>
              {c.username || c.id}
            </Select.Option>
          ))}
        </Select>
      )}
    </Fragment>
  );
}
