import React from 'react';

import { Form, Switch, Tooltip } from 'antd';

export const PinHeadlinesToggle = (props: {
  account: accountT,
  disabled?: boolean,
  defaultChecked?: boolean,
  tooltip?: string,
}) => {
  const defaultChecked = props.defaultChecked || false;

  let sw = (
    <Switch checkedChildren="On" unCheckedChildren="Off" disabled={props.disabled} defaultChecked={defaultChecked} />
  );

  if (props.tooltip) {
    sw = <Tooltip title={props.tooltip}>{sw}</Tooltip>;
  }

  return (
    <Form.Item
      name={['googleCampaign', 'ad', 'pinHeadlines']}
      label="Pin headlines"
      valuePropName="checked"
      required
      disabled={props.disabled}
    >
      {sw}
    </Form.Item>
  );
};
