import React, { useEffect, useState } from 'react';

import { Form, Select, Tooltip } from 'antd';

import { s } from 'lib/safe';

import { useLoading } from '../../../../hooks/useLoading';
import { listAdsProviderExternalCampaigns } from '../../../../lib/adsPlateformApi';
import axios from '../../../../lib/axios.factory';

function CampaignSelectorContent(props: {
  campaigns: [],
  value: any,
  onChange: Function,
  loading: boolean,
  disabled: boolean,
  disabledTooltip: string,
}) {
  const [campaigns, setCampaigns] = useState(props.campaigns);
  const [filteredCampaigns, setFilteredCampaigns] = useState(props.campaigns);

  function getOption(campaign) {
    return (
      <Select.Option key={campaign.id} value={campaign.id} label={campaign.name} disabled={campaign.disabled}>
        <div
          style={{
            display: 'flex',
            gap: '1em',
            alignItems: 'center',
            height: '32px',
          }}
        >
          {campaign.disabled && props.disabledTooltip && (
            <Tooltip placement="topLeft" title={campaign.tooltip} arrowPointAtCenter>
              <span>{campaign.name}</span>
            </Tooltip>
          )}
          {(!campaign.disabled || !props.disabledTooltip) && <span>{campaign.name}</span>}
        </div>
      </Select.Option>
    );
  }

  console.log('filteredCampaigns', filteredCampaigns);

  return (
    <Select
      size="large"
      disabled={props.disabled}
      loading={props.loading}
      placeholder="Select the campaigns"
      defaultValue={s(props.value).id}
      mode="multiple"
      filterOption={false}
      onSearch={(value) => {
        setFilteredCampaigns(campaigns.filter((campaign) => campaign.name.toLowerCase().includes(value.toLowerCase())));
      }}
      onChange={(ids) => {
        const selected = filteredCampaigns.filter((p) => ids.indexOf(p.id) > -1);

        props.onChange(selected);
      }}
    >
      {filteredCampaigns &&
        filteredCampaigns
          .filter((c) => !c.disabled)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((campaign) => getOption(campaign))}
      {filteredCampaigns &&
        filteredCampaigns
          .filter((c) => c.disabled)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((campaign) => getOption(campaign))}
    </Select>
  );
}

export function ExternalCampaignSelector(props: {
  account: accountT,
  providerType: 'GENERIC' | 'GOOGLE' | 'FACEBOOK' | 'TIKTOK',
  adsProviderId: string,
  label?: string,
  disabled?: boolean,
  disabledItemFn?: (ExternalCampaignT) => boolean,
}) {
  const [campaigns, setCampaigns] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    setCampaigns([]);
    switch (props.providerType) {
      case 'TIKTOK':
      case 'GOOGLE':
        const data = await listAdsProviderExternalCampaigns(
          localStorage,
          axios,
          {
            accountId: props.account.id,
          },
          props.adsProviderId,
        );

        if (data.campaigns) {
          const c = data.campaigns.map((c) => {
            if (props.disabledItemFn) {
              c.disabled = !!props.disabledItemFn(c);
              c.tooltip = props.disabledItemFn(c);
            }

            return c;
          });

          setCampaigns(c);
        }

        break;
      default:
        throw Error('provider not implemented');
    }
  }

  useEffect(() => {
    props.adsProviderId && doAction(async () => await load());
  }, [props.adsProviderId]);

  return (
    <ExclusiveInlineLoadingContainer>
      <Form.Item
        name="campaigns"
        rules={[{ required: true, message: 'Please select active campaign(s)' }]}
        label={props.label || 'Select an active campaign(s) to import'}
        style={{ marginBottom: '5px' }}
        trigger="onChange"
      >
        <CampaignSelectorContent campaigns={campaigns} disabledTooltip />
      </Form.Item>
    </ExclusiveInlineLoadingContainer>
  );
}
