import React from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import { getCampaignFromId, getSubCampaignFromId } from 'lib/campaigns';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import * as string from 'locales/strings';
import { strings } from 'locales/strings';

import DropDown from '../../../../common/dropDown/DropDown';
import { LargeModalForRedux } from '../../../../common/ModalForRedux';

const ExportHelpContainer = styled.div.withConfig({
  displayName: 'ExportHelpContainer',
})`
  text-align: left;
  margin-bottom: 10px;
  font-size: var(--small-size);
`;

function AbstractExportModal({
  config,
  campaignName,
  subCampaignName,
  nbRedirects,
  handleCancel,
  handleSuccess,
  handleSelect,
}: {
  config: configT,
  campaignName: string,
  subCampaignName: string,
  nbRedirects: number,
  handleCancel: () => any,
  handleSuccess: () => any,
  handleSelect: () => any,
}) {
  return (
    <LargeModalForRedux
      okOnly={true}
      okMessage={getIntlStrings('redirect.export.button.title')}
      actionCancel={() => Actions.front.audienceBuilder.redirect.export.hide.request()}
      actionSuccess={() =>
        Actions.api.audienceBuilder.redirect.export.request({
          selectedColumns: config.selectedColumns,
          selectedHeaders: config.availableColumns
            .filter((column) => config.selectedColumns.indexOf(column.id) !== -1)
            .map((col) => col.value),
        })
      }
      config={config}
      title={getIntlStrings('redirect.export.title')}
    >
      <>
        {!!config && (
          <>
            <ExportHelpContainer>
              {getIntlStrings('redirect.export.help', {
                campaignName,
                subCampaignName,
                nbRedirects,
              })}
            </ExportHelpContainer>
            <br />
            <ExportHelpContainer>{getIntlStrings('redirect.export.list.help')}</ExportHelpContainer>
            <DropDown
              scrollable={true}
              sortValues={false}
              position="display"
              id="columns-configuration"
              placeholder=""
              values={config.availableColumns}
              selected={config.selectedColumns}
              multi={true}
              onSelect={handleSelect}
            />
          </>
        )}
      </>
    </LargeModalForRedux>
  );
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  config: state.front.exportRedirectsModalConfig,
  campaignName: getCampaignFromId(state.campaigns.selectedCampaignId).name,
  subCampaignName: getSubCampaignFromId(state.campaigns.selectedSubCampaignId).name,
  nbRedirects: state.abRedirects.nbRedirects,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  handleSelect: (selectedColumns) =>
    dispatch(
      Actions.front.audienceBuilder.redirect.export.updateSelected.request({
        selectedColumns,
      }),
    ),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) => {};

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const ExportRedirectsModal = reduxWrapper(AbstractExportModal);
