import React from 'react';

import moment from 'moment';
import { Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import styled from 'styled-components';

// For AntDesign widgets only:

import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { useImmer } from 'use-immer';
import Button from 'antd/lib/button';
import _ from 'lodash';
import Card from 'antd/lib/card';
// Choose your skin (ant/material/vanilla):
const InitialConfig = AntdConfig; // or MaterialConfig or BasicConfig
const ComposerEditDynamicRedirectContainer = styled.div.withConfig({
  displayName: 'ComposerEditDynamicRedirect',
})`
  max-width: 600px;
  .query-builder-container {
    padding: 0 !important;
  }
  .query-builder {
    margin: 0 !important;
  }
  .group {
    background-color: var(--pixelme-small-very-light-color);
    padding: 0.4em;
    border-radius: 0;
    border: none;
  }
  .rule--body {
    display: flex;
    align-items: center;
    justify-content: stretch;
  }
  .rule--field {
    flex: 0 0 105px;
    .ant-select {
      width: 100% !important;
    }
  }
  .rule--value {
    flex: 1 0 105px;
    .ant-select {
      width: 100% !important;
    }
  }
  .rule--header {
    margin-left: 10px;
  }
  .qb-lite .group--header:hover .group--header .group--drag-handler,
  .qb-lite .group--header:hover .group--header .group--actions,
  .qb-lite .group--header:not(:hover) .group--drag-handler,
  .qb-lite .group--header:not(:hover) .group--actions,
  .qb-lite .rule_group:not(:hover) .group--drag-handler,
  .qb-lite .rule_group:not(:hover) .group--actions {
    opacity: 1;
  }
  .ant-input {
    height: 25px;
  }
`;

const config = {
  ...InitialConfig,

  conjunctions: {
    AND: {
      ...InitialConfig.conjunctions.AND,
      isForDisplay: true,
    },
    OR: {
      ...InitialConfig.conjunctions.OR,
      isForDisplay: true,
    },
  },
  widgets: {
    ...InitialConfig.widgets,
    datetime: {
      ...InitialConfig.widgets.datetime,
      timeFormat: 'HH:mmZ',
      dateFormat: 'YYYY-MM-DD',
      valueFormat: 'YYYY-MM-DDTHH:mm',
    },
  },
  types: {
    ...InitialConfig.types,
    datetime: {
      ...InitialConfig.types.datetime,
      widgets: {
        datetime: {
          operators: ['lte', 'gte'],
        },
      },
    },
    text: {
      ...InitialConfig.types.text,
      widgets: {
        text: {
          operators: ['contains', 'endsWith', 'startsWith', 'equal'],
        },
      },
    },
  },
  operators: {
    ...InitialConfig.operators,
    lte: {
      ...InitialConfig.operators.less_or_equal,
      label: '<=',
      labelForFormat: '<=',
      valueSources: ['value'],
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef.labelForFormat} ${Date.parse(value.replaceAll('"', '') + moment().format('Z')) / 1000}`,
    },
    gte: {
      ...InitialConfig.operators.greater_or_equal,
      label: '>=',
      labelForFormat: '>=',
      valueSources: ['value'],
      formatOp: (field, _op, value, _valueSrc, _valueType, opDef) =>
        `${field} ${opDef.labelForFormat} ${Date.parse(value.replaceAll('"', '') + moment().format('Z')) / 1000}`,
    },
    contains: {
      ...InitialConfig.operators.equal,
      label: 'Contains',
      labelForFormat: 'co',
      mongoLogic: undefined,
      sqlLogic: undefined,
      valueSources: ['value'],
    },
    startsWith: {
      ...InitialConfig.operators.starts_with,
      label: 'Starts with',
      labelForFormat: 'sw',
      sqlLogic: undefined,
      valueSources: ['value'],
    },
    endsWith: {
      ...InitialConfig.operators.ends_with,
      label: 'Ends with',
      labelForFormat: 'ew',
      mongoLogic: undefined,
      sqlLogic: undefined,
      valueSources: ['value'],
    },
  },
  fields: {
    ...InitialConfig.fields,
    date: {
      label: 'Date',
      type: 'datetime',
      dateFormat: 'D/M/YYYY',
      format: 'D/M/YYYY',
      valueSources: ['value'],
      operators: ['gte', 'lte'],
    },
    referrer: {
      label: 'Referrer',
      type: 'text',
      widget: 'text',
      valueSources: ['value'],
      operators: ['contains', 'startsWith', 'endsWith', 'equal'],
    },
    userAgent: {
      label: 'UserAgent',
      type: 'text',
      widget: 'text',
      valueSources: ['value'],
      operators: ['contains', 'startsWith', 'endsWith', 'equal'],
    },
  },
};

const queryValue = { id: QbUtils.uuid(), type: 'group' };

export function ComposerEditDynamicRedirect(props) {
  const json = props.value ? props.value.json : queryValue;

  let tree;

  try {
    tree = QbUtils.checkTree(QbUtils.loadTree(json), config);
  } catch (e) {
    tree = QbUtils.checkTree(QbUtils.loadTree(queryValue), config);
  }

  const [state, updateState] = useImmer({
    tree,
    config,
    saved: true,
  });
  const isApiRule = (!props.value || !props.value.json) && props.value && props.value.query;

  return (
    <ComposerEditDynamicRedirectContainer>
      {isApiRule && <Card size="small">{props.value ? props.value.query : ''}</Card>}
      {!isApiRule && (
        <div className="composer__line_grid">
          <Query {...config} value={state.tree} onChange={onChange} renderBuilder={renderBuilder} />
        </div>
      )}
      {!state.saved && (
        <div className="composer__line_grid">
          <Button type="primary" disabled={state.saved} onClick={onSave}>
            Apply
          </Button>
        </div>
      )}
    </ComposerEditDynamicRedirectContainer>
  );

  function renderBuilder(props) {
    return (
      <div className="query-builder-container" style={{ padding: '10px' }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    );
  }

  /*
    function renderResult({tree: immutableTree, config}) {
        return (
            <div className="query-builder-result">
                <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
            </div>
        )
    }*/

  function onChange(immutableTree, config) {
    if (!_.isEqual(immutableTree, state.tree)) {
      console.log('onChange', immutableTree, state.tree);
      updateState((draft) => {
        draft.tree = immutableTree;
        draft.config = config;
        draft.saved = false;
      });
    }
  }

  function onSave() {
    updateState((draft) => {
      draft.saved = true;
    });
    const toSave = {
      query: QbUtils.queryString(state.tree, state.config, true),
      json: QbUtils.getTree(state.tree),
    };

    props.onChange(toSave);
  }
}
