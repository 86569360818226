// @flow
import React, { type Node } from 'react';

import styled from 'styled-components';
import { AlertsTable } from './AlertsTable';

const Style = styled.div`
  background-color: white;
  padding: 1em;
  height: 100%;
`;

type propsT = {
  account: accountT,
};
export const Alerts = ({ account }: propsT): Node => {
  return (
    <Style>
      <AlertsTable account={account} />
    </Style>
  );
};
