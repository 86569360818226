import React from 'react';

import { Select } from 'antd';

import { facebookCTAs } from './facebookCTAs';

export function FacebookCTASelector(props) {
  return (
    <Select size="large" {...props} placeholder="Select a CTA">
      {facebookCTAs.map((f) => (
        <Select.Option key={f.value} value={f.value}>
          {f.label}
        </Select.Option>
      ))}
    </Select>
  );
}
