import React, { useEffect } from 'react';

import _ from 'lodash';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { BoxShadow } from '../../../BoxShadow';
import { ColorPickerWithLabel } from '../../../ColorPickerWithLabel';
import { SortableStackBullet } from '../../../common/dropDown/SortableStackBullet';
import { Delete } from '../../../common/icons';
import Input from '../../../common/Input';
import { IconInsideButton } from '../../AudienceBuilder/Campaigns/campaignTree/Common';
import { ImgUpload } from '../imgUpload';

const Style = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: flex;
  padding-right: 1em;
  padding-top: 1em;
  padding-bottom: 1em;
`;

export function PageBlockEditor({ account, block, handleDelete, handleChange }) {
  const [state, updateState] = useImmer({ block });

  useEffect(() => {
    handleChange(state.block);
  }, [JSON.stringify(state.block)]);

  const prefixUrl = (value) => {
    if (!_.startsWith(value, `http://`) && !_.startsWith(value, `https://`)) {
      value = `https://${value}`;
    }

    return value;
  };

  if (!state.block.css) {
    updateState((draft) => {
      draft.block.css = {};
    });

    return <></>;
  }

  return (
    <Style>
      <SortableStackBullet />
      <div style={{ display: 'block', width: '90%' }}>
        <div style={{ width: '60%' }}>
          <Input
            name="title"
            title="Title"
            placeholder="Entrer your button title"
            onChange={(event) => {
              if (event.target) {
                const v = event.target.value;

                updateState((draft) => {
                  draft.block.title = v;
                });
              }
            }}
            value={state.block.title}
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Input
            name="url"
            displayLabel={false}
            isBig
            title="Url"
            placeholder="Entrer your destination URL"
            onBlur={(event) => {
              const v = prefixUrl(event.target.value);

              updateState((draft) => {
                draft.block.url = v.trim();
              });
            }}
            onChange={(event) => {
              if (event.target) {
                const v = event.target.value;

                updateState((draft) => {
                  draft.block.url = v.trim();
                });
              }
            }}
            value={state.block.url}
          />
        </div>

        <div style={{ display: 'flex', direction: 'row' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <ColorPickerWithLabel
              displayLabel={true}
              title="Background color"
              onChange={(bgcolor) => {
                updateState((draft) => {
                  draft.block.css = {
                    ...state.block.css,
                    bgcolor,
                    bgimg: undefined,
                  };
                });
              }}
              value={state.block.css.bgcolor || ''}
            />
            <ColorPickerWithLabel
              displayLabel={true}
              title="Text color"
              onChange={(valueHex) => {
                updateState((draft) => {
                  draft.block.css.color = valueHex;
                });
              }}
              value={state.block.css.color || ''}
            />
          </div>

          <BoxShadow style={{ flex: 1, marginRight: '0' }}>
            <ImgUpload
              account={account}
              label="Background"
              previewSize="50px"
              value={state.block.css.bgimg || ''}
              onChange={(url) => {
                updateState((draft) => {
                  draft.block.css = {
                    ...state.block.css,
                    bgcolor: undefined,
                    bgimg: url,
                  };
                });
              }}
            />
          </BoxShadow>
        </div>

        <div style={{ float: 'right', marginTop: '-5px', zIndex: 30 }}>
          <IconInsideButton>
            <Delete onClick={() => handleDelete(block)} />
          </IconInsideButton>
        </div>
      </div>
    </Style>
  );
}
