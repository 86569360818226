import React from 'react';

import { Select } from 'antd';

import { tiktokCTAs } from '../../../../../assets/tiktokCTAs';

/**
 * Tiktok CTA selector
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function CTAsSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  type: string,
  value: any,
  onChangeFullObject: any,
  onChange: any,
  defaultValue: any,
}) {
  return (
    <Select
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={(v) => {
        if (props.onChange) {
          props.onChange(v);
        }

        if (props.onChangeFullObject) {
          props.onChangeFullObject(tiktokCTAs.find((cta) => cta.value === v));
        }
      }}
      size="large"
      placeholder="Select CTA"
      allowClear
      options={tiktokCTAs}
    />
  );
}
