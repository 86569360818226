import React from 'react';

import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const UpButton = styled.button.withConfig({
  displayName: 'UpgradeButton',
})`
  background: var(--white);
  border: 1px solid var(--pixelme-color);
  color: var(--pixelme-color);
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  width: 120px;
  height: 32px;
  margin-right: 30px;
  &:hover {
    cursor: pointer;
    background-color: rgb(192, 220, 246);
  }
  > svg {
    position: relative;
    top: 3px;
  }
`;

function UpgradeButton() {
  const sub = useSelector((state) => state.subscription.sub);
  const accounts = useSelector((state) => state.accounts);

  if (!accounts || !sub) {
    return null;
  }

  const finishTrial = (sub) => {
    const emptyDate = '0001-01-01T00:00:00Z';

    if (sub.trialEnd && sub.trialEnd !== emptyDate) {
      return true;
    }

    return false;
  };

  if (sub && sub.status === 'free' && accounts.selected) {
    return (
      <div>
        <NavLink to={`/${accounts.selected}/b`} activeClassName="">
          {finishTrial(sub) ? (
            <UpButton>
              Upgrade <Rocket />
            </UpButton>
          ) : (
            <UpButton>Start a free trial</UpButton>
          )}
        </NavLink>
      </div>
    );
  }

  return null;
}

export default UpgradeButton;

function Rocket() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M1.89775 8.33C1.82416 8.47679 1.85282 8.65423 1.96876 8.77027L2.0619 8.8634C2.33098 8.54453 2.64459 8.19849 3.02496 7.81822C3.05183 7.79134 3.07951 7.77835 3.10648 7.75356L2.50732 7.1543C2.159 7.7843 1.95587 8.21475 1.89775 8.33Z"
          fill="#2E89E2"
        />
        <path
          d="M5.17936 9.97215C4.7981 10.3534 4.45295 10.6676 4.13586 10.9369L4.26202 11.0631C4.37906 11.1801 4.55818 11.2083 4.70547 11.1325C4.90274 11.0308 5.21298 10.8848 5.87155 10.5181L5.24413 9.89062C5.21933 9.9177 5.20634 9.94527 5.17936 9.97215Z"
          fill="#2E89E2"
        />
        <path
          d="M4.97632 8.02201C4.70109 7.74678 3.92707 7.99355 3.56347 8.35705C2.3471 9.57342 1.79773 10.4358 1.55107 10.945C1.48035 11.0909 1.50981 11.2656 1.62446 11.3802C1.73931 11.4952 1.91427 11.5243 2.06027 11.4536C2.55806 11.2119 3.40994 10.6651 4.64079 9.43437C5.04059 9.03436 5.23786 8.28365 4.97662 8.02231L4.97632 8.02201Z"
          fill="#2E89E2"
        />
        <path
          d="M1.18878 5.07042L0.11156 6.14764C-0.0102359 6.26943 -0.0352298 6.45798 0.0510586 6.60735C0.135264 6.75473 0.31161 6.82981 0.479426 6.78488C1.06163 6.62877 1.5739 6.56281 1.91886 6.65128C2.40961 5.77878 2.93696 4.9756 3.49546 4.24602C3.17242 4.03893 2.10492 4.15428 1.18878 5.07042Z"
          fill="#2E89E2"
        />
        <path
          d="M6.44653 12.9584C6.59322 13.0325 6.77086 13.0039 6.887 12.8876L7.96432 11.8104C8.79834 10.9762 8.94513 9.89988 8.75589 9.55859C7.9422 10.1718 7.1179 10.6897 6.34884 11.1232C6.4019 11.4228 6.36659 11.7135 6.24112 12.5625C6.21663 12.7269 6.30153 12.8859 6.44653 12.9584Z"
          fill="#2E89E2"
        />
        <path
          d="M7.56137 3.82047C7.11594 4.266 7.11594 4.99072 7.56137 5.43625C8.00689 5.88177 8.73171 5.88177 9.17724 5.43625C9.62277 4.99072 9.62277 4.266 9.17724 3.82047C8.73171 3.37495 8.00689 3.37495 7.56137 3.82047Z"
          fill="#2E89E2"
        />
        <path
          d="M12.9776 0.371503C12.9639 0.183156 12.814 0.034284 12.6262 0.0202002C11.6908 -0.0502191 10.7591 0.0648322 9.84583 0.344129C9.91456 0.971555 10.2391 1.65056 10.7931 2.20459C11.3538 2.76517 12.0512 3.08959 12.6899 3.15029C12.9358 2.2883 13.0525 1.36511 12.9776 0.371503Z"
          fill="#2E89E2"
        />
        <path
          d="M10.2546 2.74338C9.6208 2.1096 9.2338 1.34947 9.10962 0.609375C6.82258 1.54734 4.63323 3.59565 2.9032 6.47312L3.76271 7.33263C4.34997 7.0942 5.06289 7.03122 5.51506 7.48329C5.96783 7.93616 5.90366 8.64878 5.66532 9.23524L6.5531 10.123C8.76704 8.78873 11.3446 6.68656 12.4407 3.88972C11.6825 3.77517 10.8987 3.38747 10.2546 2.74338V2.74338ZM9.71582 5.97503C8.97344 6.71741 7.7653 6.71741 7.02283 5.97503C6.28035 5.23255 6.28035 4.02431 7.02283 3.28194C7.7653 2.53956 8.97344 2.53956 9.71582 3.28194C10.4583 4.02441 10.4583 5.23255 9.71582 5.97503Z"
          fill="#2E89E2"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
