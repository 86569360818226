export const tiktokCreatorsInteractionTypes = [
  {
    value: 'FOLLOWING',
    label: 'Followed',
  },
  {
    value: 'VIEW_HOMEPAGE',
    label: 'Viewed profile',
  },
];
