import React, { Fragment, useEffect } from 'react';

export function FakeValueChangeFormItem(props: { tref: any, onChange: any }) {
  useEffect(() => {
    props.tref.current = {
      onChange: (v) => props.onChange(v),
    };
  }, [props.tref]);

  return <Fragment />;
}
