import './Domains.css';

import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Actions } from 'actions';
import Box from 'components/common/Box';
import Button, { LightButton, CancelButton, DeleteButton, LockedButton } from 'components/common/Button';
import Input from 'components/common/Input';
import Modal from 'components/common/Modal';
import { DomainsCTATooltip } from 'components/common/tooltip';
import { addDomain, deleteDomain, updateDomain } from 'lib/api';
import axios from 'lib/axios.factory';
import { addDomain as addDomainLib, checkDomain, deleteDomain as deleteDomainLib } from 'lib/sslHistory';
import { validateUrl } from 'lib/util';
import { updateAccount, updateAccountsUsageThunk, selectCurrentAccount } from 'stores/accounts';

class Domains extends Component<{
  account: accountT,
  updateAccount: (account: accountT) => void,
  updateAccountsUsageThunk: () => void,
  notifySSL: ({ domain: string }) => void,
  limitations: { brandedDomains: number },
  usage: { brandedDomains: number },
}> {
  render() {
    return (
      <div className="domains">
        <List {...this.props} />
        <AddDomainForm {...this.props} />
      </div>
    );
  }
}

function List({
  account,
  updateAccount,
  updateAccountsUsageThunk,
  notifySSL,
}: {
  account: accountT,
  updateAccount: (account: accountT) => void,
  updateAccountsUsageThunk: () => void,
  notifySSL: ({ domain: string }) => void,
}) {
  const { domains } = account;

  return (
    <Box
      className="domains__list"
      header={
        <div className="domains__list__header">
          My custom domain
          {domains.length > 1 ? 's ' : ' '}
        </div>
      }
    >
      <div className="domains__list__title">
        {domains
          .filter((d) => d.custom)
          .map((domain, index) => (
            <EditDomainForm
              key={domain.name}
              updateAccount={updateAccount}
              updateAccountsUsageThunk={updateAccountsUsageThunk}
              index={index}
              account={account}
              domain={domain}
              notifySSL={notifySSL}
            />
          ))}
      </div>
    </Box>
  );
}

class EditDomainForm extends Component<
  {
    updateAccount: (account: accountT) => void,
    updateAccountsUsageThunk: () => void,
    notifySSL: ({ domain: string }) => void,
    account: accountT,
    domain: domainT,
    index: number,
  },
  {
    displayDeleteModal: boolean,
    edit: boolean,
    calling: boolean,
    notFoundRedirect: string,
    indexRedirect: string,
  },
> {
  constructor(props) {
    super(props);
    this.state = {
      displayDeleteModal: false,
      calling: false,
      edit: false,
      notFoundRedirect: this.props.domain.notFoundRedirect,
      indexRedirect: this.props.domain.indexRedirect,
      waitingValidation: false,
    };
  }

  componentDidMount = () => {
    const { domain } = this.props;

    this.setState({
      waitingValidation: checkDomain(localStorage, domain.name),
    });
  };

  onClickNewSSL = () => {
    const { notifySSL, domain } = this.props;

    addDomainLib(localStorage, domain.name);
    notifySSL({ domain: domain.name });
    this.setState({ waitingValidation: true });
  };

  render() {
    const { notFoundRedirect, indexRedirect, edit, displayDeleteModal, calling, waitingValidation } = this.state;
    const { index, domain } = this.props;

    return (
      <div className="domain">
        {displayDeleteModal && (
          <Modal
            handleCancel={() => {
              this.setState({ displayDeleteModal: false });
            }}
            handleOk={this.handleDelete}
            disabled={calling}
            okMessage="Delete custom domain?"
          >
            Delete your custom domain {domain.name}? :-(
          </Modal>
        )}
        <div className="domain__header">
          <div className="domain__header__name">
            Domain {index + 1}: <span>{domain.name}</span>
          </div>
        </div>
        <div className="domain__details">
          <div className="domain__details__label">404 page</div>
          <div className="domain__details__value">
            {!edit && (domain.notFoundRedirect.length > 0 ? domain.notFoundRedirect : 'https://getpixelme.com/404')}
            {edit && (
              <Input
                value={notFoundRedirect}
                id="notFoundRedirect"
                placeholder="https://getpixelme.com/404"
                onChange={this.handleChange}
              />
            )}
          </div>
          <div className="domain__details__label">Index page</div>
          <div className="domain__details__value">
            {!edit && (domain.indexRedirect.length > 0 ? domain.indexRedirect : 'https://getpixelme.com/')}
            {edit && (
              <Input
                value={indexRedirect}
                id="indexRedirect"
                placeholder="https://getpixelme.com/"
                onChange={this.handleChange}
              />
            )}
          </div>
          {edit && (
            <div className="domain__details__valid__buttons">
              <LightButton
                disabled={!this.validateFields()}
                loading={calling}
                loadingLabel="Saving..."
                handleClick={this.handleSubmit}
              >
                SAVE
              </LightButton>
            </div>
          )}
        </div>
        {!edit && (
          <div className="domain__header__buttons">
            <LightButton style={{ marginRight: '1rem' }} handleClick={() => this.setState({ edit: true })}>
              Edit
            </LightButton>
            <Button handleClick={this.onClickNewSSL} disabled={waitingValidation}>
              Activate SSL
            </Button>
            {this.state.waitingValidation && !domain.ssl ? (
              <span className="domain__waiting">Your SSL will be activated in the next 24 hours.</span>
            ) : (
              <></>
            )}
          </div>
        )}
        {edit && (
          <div className="domain__details__valid__buttons">
            <CancelButton
              handleClick={() =>
                this.setState({
                  edit: false,
                  indexRedirect: domain.indexRedirect,
                  notFoundRedirect: domain.notFoundRedirect,
                })
              }
            >
              Cancel
            </CancelButton>
            <DeleteButton handleClick={() => this.setState({ displayDeleteModal: true })}>Delete</DeleteButton>
          </div>
        )}
      </div>
    );
  }

  handleDelete = () => {
    const { account, domain, updateAccount, updateAccountsUsageThunk } = this.props;

    this.setState({ calling: true });
    deleteDomain(localStorage, axios, { accountId: account.id }, { domain: domain.name }).then(({ account }) => {
      this.setState({ calling: false, displayDeleteModal: false });
      deleteDomainLib(localStorage, domain.name);
      updateAccountsUsageThunk();

      return updateAccount(account);
    });
  };
  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = () => {
    if (this.validateFields()) {
      const { domain, account, updateAccount } = this.props;
      const { notFoundRedirect, indexRedirect } = this.state;

      this.setState({ calling: true });
      updateDomain(
        localStorage,
        axios,
        { accountId: account.id },
        { domain: { name: domain.name, notFoundRedirect, indexRedirect } },
      ).then(({ account }) => {
        this.setState({ calling: false, edit: false });

        return updateAccount(account);
      });
    }
  };

  validateFields = () => {
    const { notFoundRedirect, indexRedirect } = this.state;

    return (
      (validateUrl(notFoundRedirect) || notFoundRedirect.length === 0) &&
      (validateUrl(indexRedirect) || indexRedirect.length === 0)
    );
  };
}

class AddDomainForm extends Component<
  {
    account: accountT,
    updateAccount: (account: accountT) => void,
    updateAccountsUsageThunk: () => void,
    limitations: { brandedDomains: number },
    usage: { brandedDomains: number },
  },
  { domain: string, calling: boolean, errorMessage: string },
> {
  constructor(props) {
    super(props);
    this.state = {
      domain: '',
      calling: false,
      errorMessage: '',
    };
  }

  render() {
    const { domain, calling, errorMessage } = this.state;

    return (
      <Box className="domains__add" header="Add a custom domain">
        <form onSubmit={this.handleSubmit}>
          <Input value={domain} id="domain" placeholder="pix.mycomp.com" onChange={this.handleChange} />
          {this.renderButton()}
        </form>
        <div className="domains__add__text">
          <p>
            If you want to use your own custom domain with PixelMe, you should consider using a subdomain of your site
            (pix.mycompany.com) instead of the main one. You don’t want to redirect your main website to us if it has
            content on it, otherwise, your content may become inaccessible to others. To successfully use your custom
            domain, please Create a CNAME record pointing the desired domain to cname.pxlme.me.
          </p>
          <p className="domains__add__text--muted">To create your custom domain, please follow these steps:</p>
          <ol>
            <li>On your registrar, create a subdomain for PixelMe like social.mycompany.com</li>
            <li>Create a CNAME record in your DNS config pointing the desired domain to cname.pxlme.me</li>
            <li>Wait a few hours during the DNS propagation (up to 48 hours)</li>
            <li>When your subdomain social.mycompany.com redirects to pixelme.me that means it works!</li>
            <li>Add your subdomain on PixelMe</li>
          </ol>
        </div>
        {errorMessage.length > 0 && (
          <Modal
            handleCancel={() => {
              this.setState({ errorMessage: '' });
            }}
            handleOk={() => {
              this.setState({ errorMessage: '' });
            }}
            disabled={calling}
            okMessage="Ok"
            okOnly={true}
          >
            <div>
              <div>Unable to create your custom domain.</div>
              <div className="domains__modal--error">{errorMessage}</div>
            </div>
          </Modal>
        )}
      </Box>
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);

    let { value } = target;

    const { name } = target;

    if (name === 'domain') {
      value = value.toLowerCase();
    }

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { account, updateAccount, updateAccountsUsageThunk } = this.props;

    this.setState({ calling: true });

    if (this.validateFields()) {
      addDomain(localStorage, axios, { accountId: account.id }, { domain: this.state.domain })
        .then(({ account }) => {
          this.setState({ calling: false, domain: '' });
          updateAccountsUsageThunk();

          return updateAccount(account);
        })
        .catch(({ errorMessage }) => {
          this.setState({ calling: false, errorMessage });
        });
    }
  };

  validateFields = () => this.state.domain.length > 3;

  renderButton() {
    const { calling } = this.state;
    const { limitations, usage } = this.props;

    if (usage && usage.brandedDomains !== -1 && usage.brandedDomains >= limitations.brandedDomains) {
      return (
        <DomainsCTATooltip>
          <LockedButton>Add a custom domain</LockedButton>
        </DomainsCTATooltip>
      );
    }

    return (
      <Button disabled={!this.validateFields()} loading={calling} loadingLabel="Adding...">
        Add a custom domain
      </Button>
    );
  }
}

const mapStateToProps = function (state) {
  const { limitations, usage } = state.accounts;

  return {
    account: selectCurrentAccount(state),
    limitations,
    usage,
    subscription: state.subscription,
  };
};

const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  notifySSL: (args) => {
    dispatch(Actions.api.domain.notify.request(args));
  },

  updateAccount: (args) => dispatch(updateAccount(args)),
  updateAccountsUsageThunk: (args) => dispatch(updateAccountsUsageThunk(args)),
});

export default connect(mapStateToProps, dispatchToProps)(Domains);
