import './Checkbox.css';
import React from 'react';

import styled from 'styled-components';

function Checkbox({ checked, style, handleClick }: { style: ?Object, checked: boolean, handleClick?: () => void }) {
  return (
    <div
      style={{ cursor: 'pointer', ...style }}
      onClick={handleClick && handleClick}
      className={`checkbox-container ${checked ? 'checked' : ''}`}
    >
      <div />
    </div>
  );
}

export const CheckboxContainer = styled.div.withConfig({
  displayName: 'CheckboxContainer',
})`
  display: flex;
  align-items: center;

  > label {
    margin-left: 0.5rem;
  }
`;

export default Checkbox;
