import account from './account.actions';
import audienceBuilder from './audienceBuilder';
import domain from './domain.actions';

export default {
  error: {
    type: 'API_ERROR',
  },
  account,
  domain,
  audienceBuilder,
};
