import React, { Component } from 'react';

import { Dispatch } from '@reduxjs/toolkit';
import { Button, Empty } from 'antd';
import qs from 'query-string';
import { connect } from 'react-redux';
import type { Location, Match, RouterHistory } from 'react-router-dom';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { AccountsPage } from 'components/pages/Accounts';
import { AsinAuditPage } from 'components/pages/AsinAudit';
import { GoogleOAuthPage } from 'components/pages/oauth/GoogleOAuthPage';
import { TiktokOAuthPage } from 'components/pages/oauth/TiktokOAuthPage';
import { getAccounts, getAccountsUsage } from 'lib/api';
import axios from 'lib/axios.factory';
import { s, sn } from 'lib/safe';
import { strings } from 'locales/strings';
import { signIn } from 'stores';
import type { action } from 'stores/accounts';
import {
  selectCurrentAccount,
  loadAccounts,
  loadAccountsError,
  loadAccountsSuccess,
  switchAccount,
  updateAccountsUsage,
  updateUsageThunk,
} from 'stores/accounts';
import { displayModal, updatePlans, updateSub } from 'stores/subscription';

import { BigSubMenu } from './BigSubMenu';
import { AudienceBuilderContent, BillingContent, SettingsContent } from './Content';
import EndOfTrialModal from './EndOfTrialModal';
import { Actions } from '../../actions';
import { isLTDF } from '../../selectors/sub.selector';
import { Amazon } from '../common/icons/Amazon';
import { GlobalPlaceholder } from '../common/Placeholders';
import { AdsProviderContent } from '../pages/AdsProviders/AdsProviderContent';
import { AmazonAttributionContent } from '../pages/AmazonAttribution/AmazonAttributionContent';
import CampaignsTree from '../pages/AudienceBuilder/Campaigns/campaignTree/CampaignsTree';
import { AmazonAttributionMarketplacePage } from '../pages/oauth/AmazonAttributionMarketplacePage';
import { AmazonAttributionProfilePage } from '../pages/oauth/AmazonAttributionProfilePage';
import { PageBuilderContent } from '../pages/PageBuilder/PageBuilderContent';
import './MainLayout.css';
import { MenuItem } from './MenuItem';
import { TopContent } from './TopContent';
import { impersonateByID, unImpersonate } from '../../lib/auth';
import { getProductProvider, hasProductProvider } from '../../lib/amazon';
import { SignOut } from '../pages/signOut/SignOut';
import { WelcomeAfterSignup } from '../pages/welcomeAfterSignup/WelcomeAfterSignup';
import SignLayout from './Sign/SignLayout';
import { Signin } from '../pages/easy/images/Signin';

import { withAuth0 } from '@auth0/auth0-react';

import { LocaleContext } from '../../locales/LocalizationWrapper';

type actions = {
  signOut: () => void,
  loadAccounts: () => action,
  loadAccountsSuccess: ({ accounts: Array }) => action,
  loadAccountsError: ({ message: string }) => action,
  switchAccount: (string) => action,
  updateUsageThunk: ({ accountId: string }) => action,
  currentAccount: (any) => accountT,
  updateSub: ({ sub: subscriptionT }) => void,
  updatePlans: ({ plans: Array }) => void,
};

type props = {
  user: userT,
  accounts: Array<accountT>,
  account: accountT,
  sub: subscriptionT,
  plans: Array<planT>,
  displayModal: boolean,
};

export const withTrial = (props: any, renderChildren: Function) => {
  const { displayModal, sub, account } = props;

  if (!displayModal) {
    return renderChildren(props);
  } else {
    return <EndOfTrialModal sub={sub} account={account} />;
  }
};

export const withTopContent = (props: any, renderChildren: Function) => (
  <TopContent {...props}>{renderChildren(props)}</TopContent>
);

class MainLayout extends Component<{
  match: Match,
  history: RouterHistory,
  signOut: () => void,
  switchAccount: (string) => action,
  updateUsageThunk: ({ accountId: string }) => action,
  accounts: Array<accountT>,
  account: accountT,
  user: userT,
  sub: subscriptionT,
  limitations: limitationsT,
  plans: Array<planT>,
  onboarding: onboardingT,
  displayModal: boolean,
}> {
  static contextType = LocaleContext;

  render() {
    const { signOut, user, accounts, account, match, switchAccount, sub, limitations, displayModal } = this.props;
    const { getIntlStrings } = this.context;

    if (match.params.accountId === account.id) {
      const routeProps = {
        user,
        displayModal,
        title: '',
        account,
        accounts,
        signOut,
        sub,
        switchAccount,
        limitations,
      };
      const defaultPath = account.type === 'SELLER' ? `/adsprovider` : `/ab`;

      if (s(account).anonymous) {
        localStorage.removeItem('access-token');
        window.location.href = '/';

        return <GlobalPlaceholder />;
      }

      return (
        <>
          <Switch>
            <Route
              path={`${match.url}/s`}
              render={({ match }) =>
                withTopContent(
                  {
                    ...routeProps,
                    match,
                    title: getIntlStrings('head.settings'),
                    renderSubMenu: () => (
                      <BigSubMenu>
                        <MenuItem label={getIntlStrings('locales.headers.teamMembers')} to={`${match.url}/team`} />
                        <MenuItem label={getIntlStrings('locales.headers.profile')} to={`${match.url}/profile`} />
                      </BigSubMenu>
                    ),
                  },
                  ({ account, match }) => <SettingsContent match={match} account={account} />,
                )
              }
            />
            <Route
              path={`${match.url}/b`}
              render={({ match }) =>
                withTopContent(
                  {
                    ...routeProps,
                    match,
                    title: getIntlStrings('head.billing'),
                    renderSubMenu: () => {
                      if (!sub) {
                        return null;
                      }

                      if (isLTDF(sub)) {
                        return (
                          <BigSubMenu>
                            <MenuItem
                              label={getIntlStrings('locales.headers.lifetimeCoupon')}
                              to={`${match.url}/ltdf`}
                            />
                            <MenuItem label={getIntlStrings('locales.headers.usage')} to={`${match.url}/usage`} />
                          </BigSubMenu>
                        );
                      }

                      if (sub.status === 'lifetime_coupon' || sub.status === 'revoked') {
                        return (
                          <BigSubMenu>
                            <MenuItem label={getIntlStrings('locales.headers.lifetimeCoupon')} to={`${match.url}`} />
                            <MenuItem label={getIntlStrings('locales.headers.usage')} to={`${match.url}/usage`} />
                          </BigSubMenu>
                        );
                      }

                      return (
                        <BigSubMenu>
                          <MenuItem label={getIntlStrings('locales.headers.subscription')} to={`${match.url}/plans`} />
                          <MenuItem label={getIntlStrings('locales.headers.usage')} to={`${match.url}/usage`} />
                          <MenuItem label={getIntlStrings('locales.headers.invoices')} to={`${match.url}/invoices`} />
                          {!limitations.allowSepa && (
                            <MenuItem label={getIntlStrings('locales.headers.cc')} to={`${match.url}/card`} />
                          )}
                          {limitations.allowSepa && (
                            <MenuItem label={getIntlStrings('locales.headers.sepa')} to={`${match.url}/sepa`} />
                          )}
                        </BigSubMenu>
                      );
                    },
                  },
                  ({ match }) => <BillingContent match={match} account={account} />,
                )
              }
            />
            <Route
              path={`${match.url}/ab`}
              render={({ match }) =>
                withTopContent(
                  {
                    ...routeProps,
                    match,
                    title: () => {
                      const parsedPaths = window.location.pathname.split('/');
                      const lastPath = parsedPaths[parsedPaths.length - 1];

                      if (lastPath === 'campaigns') {
                        return '';
                      }

                      return `${getIntlStrings('locales.headers.linkManager')}`;
                    },
                    renderSubMenu: () => (
                      <BigSubMenu>
                        <MenuItem label={getIntlStrings('locales.headers.links')} to={`${match.url}/campaigns`} />
                        <MenuItem
                          label={getIntlStrings('locales.headers.linkAnalytics')}
                          to={`${match.url}/analytics`}
                        />
                        <MenuItem
                          label={getIntlStrings('locales.headers.bulkImport')}
                          to={`${match.url}/bulk-import`}
                        />
                        <MenuItem label={getIntlStrings('locales.headers.settings')} to={`${match.url}/settings`} />
                      </BigSubMenu>
                    ),
                    // TODO: Refactor this, the conditional is in <LeftSidePanel />, based on location.pathname
                    leftPanelContent: <CampaignsTree />,
                  },
                  (props) =>
                    withTrial(props, ({ account, match }) => (
                      <AudienceBuilderContent match={match} account={account} />
                    )),
                )
              }
            />
            <Route
              path={`${match.url}/pb`}
              render={({ match }) =>
                withTopContent(
                  {
                    ...routeProps,
                    match,
                    title: () => <span>{getIntlStrings('locales.headers.oneLink')}</span>,
                    renderSubMenu: () => (
                      <BigSubMenu>
                        <MenuItem label={getIntlStrings('locales.headers.lButton')} to={`${match.url}/page-blocks`} />
                        <MenuItem label={getIntlStrings('locales.headers.appearance')} to={`${match.url}/appearance`} />
                        <MenuItem label={getIntlStrings('locales.headers.analytics')} to={`${match.url}/analytics`} />
                        <MenuItem label={getIntlStrings('locales.headers.settings')} to={`${match.url}/settings`} />
                      </BigSubMenu>
                    ),
                  },
                  (props) =>
                    withTrial(props, ({ account, match }) => <PageBuilderContent match={match} account={account} />),
                )
              }
            />
            <Route
              path={`${match.url}/amazon`}
              render={({ match }) =>
                withTopContent(
                  {
                    ...routeProps,
                    match,
                    title: () => (
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <Amazon />
                        {getIntlStrings('locales.headers.aa')} <span className="beta">BETA</span>
                      </div>
                    ),
                    renderSubMenu: () => (
                      <BigSubMenu>
                        <MenuItem label={getIntlStrings('locales.headers.aa')} to={`${match.url}/attribution`} />
                        <MenuItem label={getIntlStrings('locales.headers.apiAccounts')} to={`${match.url}/account`} />
                        {hasProductProvider(account.productProviders) &&
                          getProductProvider(account.productProviders).amazon.accountinfo.type !== 'agency' && (
                            <MenuItem label={getIntlStrings('locales.headers.products')} to={`${match.url}/products`} />
                          )}
                      </BigSubMenu>
                    ),
                  },
                  (props) =>
                    withTrial(props, ({ account, match }) => (
                      <AmazonAttributionContent match={match} account={account} />
                    )),
                )
              }
            />
            <Route
              path={`${match.url}/oauth/amazon/callback`}
              render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
                if (location && location.search) {
                  const { code } = qs.parse(location.search);

                  return <AmazonAttributionMarketplacePage key={code} code={code} account={account} />;
                }
              }}
            />
            <Route
              path={`${match.url}/oauth/amazon/profile`}
              render={({ match }: { match: Match, history: RouterHistory }) => (
                <AmazonAttributionProfilePage accountId={account.id} />
              )}
            />{' '}
            <Route
              path={`${match.url}/adsprovider`}
              render={({ match }) => (
                <AdsProviderContent match={match} routeProps={routeProps} account={account} user={user} />
              )}
            />
            <Route
              path={`${match.url}/oauth/google/callback`}
              render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
                if (location && location.search) {
                  const { code } = qs.parse(location.search);

                  return (
                    <>
                      {code && <GoogleOAuthPage key={code} code={code} accountId={account.id} />}
                      {!code && <Empty />}
                    </>
                  );
                }
              }}
            />
            <Route
              path={`${match.url}/oauth/tiktok/callback`}
              render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
                if (location && location.search) {
                  const { code } = qs.parse(location.search);

                  return (
                    <>
                      {code && <TiktokOAuthPage key={code} code={code} accountId={account.id} />}
                      {!code && <Empty />}
                    </>
                  );
                }
              }}
            />
            <Route
              path={`${match.url}/accounts`}
              render={({ match }) =>
                withTopContent(
                  {
                    ...routeProps,
                    match,
                    title: getIntlStrings('head.accounts'),
                    renderSubMenu: () => (
                      <BigSubMenu>
                        <MenuItem label={getIntlStrings('locales.headers.overview')} to={`${match.url}/overview`} />
                        <MenuItem label={getIntlStrings('locales.headers.alerts')} to={`${match.url}/alerts`} />
                      </BigSubMenu>
                    ),
                  },
                  (props) => withTrial(props, ({ account, match }) => <AccountsPage account={account} match={match} />),
                )
              }
            />
            <Redirect to={`${match.url}${defaultPath}`} />
          </Switch>
        </>
      );
    } else {
      return 'switching account';
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { accounts, updateUsageThunk } = this.props;

    if (accounts && accounts.length > 0) {
      updateUsageThunk({ accountId: accounts[0].id });
    }

    this.checkAccountAndSwitch(this.props);
  }

  componentDidMount() {
    this.checkAccountAndSwitch(this.props);
  }

  checkAccountAndSwitch({ accounts, account: currentAccount, match, history, switchAccount }) {
    const urlAccountId = match.params.accountId;
    const currentAccountId = currentAccount.id;

    if (urlAccountId && currentAccountId !== urlAccountId) {
      // check if in allowed accounts
      if (accounts.filter((account) => account.id === urlAccountId).length > 0) {
        switchAccount(urlAccountId);

        return;
      } else {
        history.push('/');
      }
    }
  }
}

class RootContainerC extends Component<actions & props> {
  getAccountId(state, account) {
    let accountID = account.id ?? null;

    if (state && state.startsWith('aId:')) {
      accountID = state.split(':')[1];
    }

    return accountID;
  }

  render() {
    const {
      user,
      accounts,
      account,
      sub,
      displayModal,
      signOut,
      switchAccount,
      updateUsageThunk,
      plans,
      onboarding,
      limitations,
      impersonated,
    } = this.props;

    if (!account) {
      return <GlobalPlaceholder loadingText="Loading account" />;
    }

    return (
      <>
        <Switch>
          <Route path={['/sign-out']} render={this.connectSignOut} />
          <Route path="/usage">
            <Redirect to={`/${account.id}/b/usage`} />
          </Route>
          <Route path="/ltdf">
            <Redirect to={`/${account.id}/b/ltdf`} />
          </Route>
          <Route
            path="/invited/:code"
            render={({ match }: { match: Match }) => {
              if (match && match.params && match.params.code) {
                localStorage.setItem('PIX_INVITATION_CODE', match.params.code);

                return (
                  <SignLayout
                    header={<></>}
                    title="You've been invited to join a team on PixelMe"
                    description={
                      <>
                        <p>
                          Since you are already a member of pixelme, you will have to create a new account to accept the
                          invitation.
                        </p>
                      </>
                    }
                    image={<Signin />}
                    footer={<></>}
                  >
                    <Button
                      size="large"
                      block
                      htmlType="submit"
                      type="primary"
                      style={{ background: '#2E0077', borderColor: '#2E0077' }}
                      onClick={signOut}
                    >
                      Logout & Signup
                    </Button>
                    <Button
                      size="large"
                      block
                      htmlType="submit"
                      type="primary"
                      style={{ background: '#2E0077', borderColor: '#2E0077', marginTop: '10px' }}
                      onClick={() => {
                        localStorage.removeItem('PIX_INVITATION_CODE');
                        window.location.href = '/';
                      }}
                    >
                      Continue to your account
                    </Button>
                  </SignLayout>
                );
              }

              return <GlobalPlaceholder />;
            }}
          />
          <Route
            path="/impersonate-by-id/:userID"
            render={({ match }: { match: Match }) => (
              <Imp match={match} impersonated={impersonated} signIn={this.props.signIn} />
            )}
          />
          <Route
            path="/oauth/google/callback"
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
              if (location && location.search) {
                const { code, state } = qs.parse(location.search);

                const accountID = this.getAccountId(state, account);

                return <Redirect to={`/${accountID}/oauth/google/callback?code=${code}`} />;
              }
            }}
          />
          <Route
            path="/oauth/amazon/callback"
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
              if (location && location.search) {
                const { code, state } = qs.parse(location.search);

                const accountID = this.getAccountId(state, account);

                return <Redirect to={`/${accountID}/oauth/amazon/callback?code=${code}`} />;
              } else {
                return <>Missing something to connect</>;
              }
            }}
          />
          <Route
            path="/oauth/tiktok/callback"
            render={({ match, history, location }: { match: Match, history: RouterHistory, location: Location }) => {
              if (location && location.search) {
                const { code, state } = qs.parse(location.search);

                const accountID = this.getAccountId(state, account);

                return <Redirect to={`/${accountID}/oauth/tiktok/callback?code=${code}`} />;
              }
            }}
          />
          <Route path="/settings/:_">
            <Redirect to={`/${account.id}/ab/settings/api`} />
          </Route>
          <Route path="/audit" render={({ match }) => <AsinAuditPage match={match} />} />

          <Route path="/:accountId/welcome-after-signup">
            <WelcomeAfterSignup account={account} />
          </Route>
          <Route
            path="/:accountId"
            render={({ match, history }: { match: Match, history: RouterHistory }) => (
              <>
                <MainLayout
                  user={user}
                  accounts={accounts}
                  account={account}
                  sub={sub}
                  plans={plans}
                  onboarding={onboarding}
                  displayModal={displayModal}
                  match={match}
                  history={history}
                  signOut={signOut}
                  switchAccount={switchAccount}
                  updateUsageThunk={updateUsageThunk}
                  limitations={limitations}
                />
              </>
            )}
          />

          <Redirect to={`/${account.id}`} />
        </Switch>
      </>
    );
  }

  connectSignOut = () => <SignOut />;

  componentDidMount() {
    const {
      user,
      loadAccounts,
      loadAccountsSuccess,
      loadAccountsError,
      updateSub,
      updateColumns,
      updatePlans,
      updateAccountsUsage,
    } = this.props;

    loadAccounts();
    getAccountsUsage(localStorage, axios, { rnd: sn(user, 'id') }).then(
      (data) => {
        updateAccountsUsage(data);
        getAccounts(localStorage, axios).then(
          (data) => {
            // TODO: remove interfce
            if (data.accounts) {
              data.accounts.forEach((account) => {
                account.referrals = {
                  id: 'l8eg40',
                  candidates: [
                    {
                      id: 100001,
                      email: 'candidates01@ok.com',
                      isPending: true,
                    },
                  ],
                };
              });
            }

            loadAccountsSuccess(data);
            updateSub({ sub: data.sub });
            updatePlans({ plans: data.plans });
            updateColumns();
          },
          (err) => {
            loadAccountsError(err);
          },
        );
      },
      (err) => {
        loadAccountsError(err);
      },
    );
  }
}

class Imp extends Component<{ match: { params: { email: string } } }> {
  componentDidMount() {
    const { match, impersonated } = this.props;

    const { userID } = match.params;
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get('url') || '/';
    const accessOverride = !!urlParams.get('access_override');

    if (impersonated) {
      unImpersonate(localStorage, axios).then((response) => {
        this.props.signIn(response);
        impersonateByID(localStorage, axios, userID, accessOverride).then((response) => {
          this.props.signIn(response);
          window.location = url;
        });
      });
    } else {
      impersonateByID(localStorage, axios, userID, accessOverride).then((response) => {
        this.props.signIn(response);
        window.location = url;
      });
    }
  }

  render() {
    return <>Impersonating...</>;
  }
}

const mapStateToProps = function (state): props {
  const userState = state.user;
  const accountsState = state.accounts;
  const subState = state.subscription;
  const currAccount = selectCurrentAccount(state);

  return {
    user: userState.user,
    account: currAccount,
    accounts: accountsState.accounts,
    limitations: accountsState.limitations,
    usage: accountsState.usage,
    sub: subState.sub,
    plans: subState.plans,
    displayModal: displayModal(subState),
    onboarding: state.onboarding,
    impersonated: state.user.impersonated,
  };
};

const mapDispatchToProps = function (dispatch: Dispatch<*>): {} {
  return {
    signOut: () => {
      window.location.href = '/sign-out';
    },
    signIn: () => dispatch(signIn()),
    loadAccounts: () => dispatch(loadAccounts()),
    loadAccountsSuccess: (data) => dispatch(loadAccountsSuccess(data)),
    loadAccountsError: (error) => dispatch(loadAccountsError(error)),
    switchAccount: (accountId) => dispatch(switchAccount(accountId)),
    updateUsageThunk: (data: { accountId: string }) => dispatch(updateUsageThunk(data)),
    updateSub: (data) => dispatch(updateSub(data)),
    updateAccountsUsage: (data) => dispatch(updateAccountsUsage(data)),
    updateColumns: () => {
      dispatch(Actions.front.audienceBuilder.campaigns.columns.update.request());
    },
    updatePlans: (data) => dispatch(updatePlans(data)),
  };
};

export default withAuth0(withRouter(connect(mapStateToProps, mapDispatchToProps)(RootContainerC)));
