import React from 'react';

import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';

import { Actions } from 'actions';
import { UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN } from 'consts/consts';
import { store } from 'stores';

import { CampaignRedirectsCount } from './CampaignRedirectsCount';
import { DropIndicator } from './CampaignRedirectsDragDropTarget';
import { SubCampaignName } from './CampaignsName';
import { IconInsideButton, SubCampaignBullet, SubCampaignTreeElement } from './Common';
import SubCampaignMenu from './SubCampaignMenu';
import { Delete, Edit } from '../../../../common/icons';

const SubCampaignContainerHeader = styled.span.withConfig({
  displayName: 'CampaignContainerHeader',
})`
  display: flex;
  align-items: center;
  height: 28px;
  width: 100%;
  justify-content: space-between;
`;

const SortableSubCampaignBullet = SortableHandle(() => <SubCampaignBullet />);

export const SubCampaignListElement = SortableElement(
  ({ subCampaign, campaign, selectCampaign, selectedCampaignId, selectedSubCampaignId, isOver, canDrop }) => (
    <SubCampaignTreeElement>
      <SubCampaignContainerHeader>
        {!!isOver && canDrop && <DropIndicator />}
        {!(isOver && canDrop) && campaign.subCampaigns.length === 1 && <div style={{ width: '10px' }} />}
        {!(isOver && canDrop) && campaign.subCampaigns.length > 1 && <SortableSubCampaignBullet />}
        <SubCampaignName subCampaign={subCampaign} campaign={campaign} />
        <CampaignRedirectsCount subCampaignId={subCampaign.id} campaignId={campaign.id} />
        {subCampaign.id !== undefined && subCampaign.id !== UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN && (
          <SubCampaignMenu>
            {/* <IconInsideButton>*/}
            {/* <Analytics*/}
            {/* onClick={() =>*/}
            {/* store.dispatch(Actions.front.analytics.navigate.request())*/}
            {/* }*/}
            {/* />*/}
            {/* </IconInsideButton>*/}
            {!!subCampaign.id && (
              <>
                <IconInsideButton>
                  <Edit
                    onClick={() =>
                      store.dispatch(
                        Actions.front.audienceBuilder.campaigns.edit.show.request({
                          campaignOrSub: subCampaign,
                          parent: campaign,
                        }),
                      )
                    }
                  />
                </IconInsideButton>
                <IconInsideButton>
                  <Delete
                    onClick={() =>
                      store.dispatch(
                        Actions.front.audienceBuilder.campaigns.delete.show.request({
                          campaignOrSub: subCampaign,
                          parent: campaign,
                        }),
                      )
                    }
                  />
                </IconInsideButton>
              </>
            )}
          </SubCampaignMenu>
        )}
      </SubCampaignContainerHeader>
    </SubCampaignTreeElement>
  ),
);
