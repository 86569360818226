import { Actions } from 'actions';
import axios from 'lib/axios.factory';
import 'lib/array';

import { getNextStart, getPreviousStart } from 'lib/paginator';
import { s } from 'lib/safe';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';
import {
  addRedirect,
  deleteRedirects,
  exportRedirects,
  getRedirectsWithStats,
  updateRedirect,
} from 'lib/api/audienceBuilder';

import { debounce } from 'lodash';

import { selectCurrentAccountId, updateAccountsUsageThunk } from 'stores/accounts';

import { updatePreview, updateRedirects } from '../../../lib/api/audienceBuilder';

let loadRedirectsDebounced;

export default (store: any) => (next: any) => async (action: any) => {
  if (!loadRedirectsDebounced) {
    loadRedirectsDebounced = debounce(() => store.dispatch(Actions.api.audienceBuilder.redirect.load.request()), 125);
  }

  next(action);
  const accountFilter = { accountId: selectCurrentAccountId(store.getState()) };

  switch (action.type) {
    case Actions.api.audienceBuilder.redirect.sort.request().type:
      // We must do this action after the store save the sort config
      setTimeout(() => store.dispatch(Actions.api.audienceBuilder.redirect.load.request()), 0);
      break;

    case Actions.api.audienceBuilder.redirect.load.request().type:
      const state = store.getState();
      const filter = {
        ...accountFilter,
        ...state.abRedirects.filter,
      };

      filter.sort = s(state.front.campaignColumnsConfig).sort;
      filter.campaignId = state.campaigns.selectedCampaignId;
      filter.subCampaignId = state.campaigns.selectedSubCampaignId;
      getRedirectsWithStats(localStorage, axios, {
        ...filter,
      }).then((result) => {
        store.dispatch(action.workerAction.success(result));
      });
      break;

    case Actions.api.audienceBuilder.redirect.loadNext.request().type: {
      const state = store.getState();
      const { filter, nbRedirects } = state.abRedirects;

      filter.sort = s(state.front.campaignColumnsConfig).sort;
      const newStart = getNextStart(filter, nbRedirects);

      store.dispatch(
        Actions.api.audienceBuilder.redirect.loadNext.success({
          start: newStart,
        }),
        loadRedirectsDebounced(),
      );
      break;
    }

    case Actions.api.audienceBuilder.redirect.loadPrev.request().type: {
      const state = store.getState();
      const { filter } = state.abRedirects;

      filter.sort = s(state.front.campaignColumnsConfig).sort;
      const newStart = getPreviousStart(filter);

      store.dispatch(
        Actions.api.audienceBuilder.redirect.loadPrev.success({
          start: newStart,
        }),
        loadRedirectsDebounced(),
      );
      break;
    }

    case Actions.api.audienceBuilder.redirect.duplicate.request().type:
      if (!action.payload.redirect) {
        throw new Error('Unable to delete empty redirect');
      }

      store.dispatch(action.workerAction.success({}));
      break;

    case Actions.api.audienceBuilder.redirect.delete.request().type:
      {
        const { redirectsIds } = action.payload;

        if (!redirectsIds) {
          throw new Error('Unable to delete empty redirect');
        }

        deleteRedirects(localStorage, axios, accountFilter, {
          redirectsIds,
        }).then(() => store.dispatch(action.workerAction.success({ redirectsIds })));
      }
      break;

    case Actions.api.audienceBuilder.redirect.delete.success().type:
      store.dispatch(Actions.front.audienceBuilder.redirect.delete.hide.request());
      store.dispatch(updateAccountsUsageThunk());
      break;

    case Actions.api.audienceBuilder.redirect.edit.request().type:
      {
        const { redirect, preview } = action.payload;

        if (!redirect) {
          throw new Error('Unable to edit/create empty redirect');
        }

        redirect.redirectId = redirect.id;
        // redirect.monthly_ads_cost = redirect.monthly_ads_cost !== undefined ?redirect.monthly_ads_cost+"":"0"
        let updatedRedirect: redirectT;

        try {
          if (redirect.id) {
            const data = {
              title: redirect.preview.title,
              description: redirect.preview.description,
              file: redirect.preview.file,
              id: redirect.id,
            };

            updatedRedirect = (await updateRedirect(localStorage, axios, accountFilter, redirect)).redirect;
            updatedRedirect = (await updatePreview(localStorage, axios, accountFilter, data)).redirect;
          } else {
            updatedRedirect = (await addRedirect(localStorage, axios, accountFilter, redirect)).redirect;
          }

          if (!updatedRedirect) {
            throw new Error('Updated redirect is empty');
          }

          store.dispatch(
            action.workerAction.success({
              redirect: updatedRedirect,
              preview,
            }),
          );
        } catch (e) {
          const rawErrMsg = e.errorMessage || '';

          let errMessage = null;

          if (rawErrMsg.startsWith('Redirect')) {
            errMessage = getIntlStrings('composer.errors.redirect');
          } else if (rawErrMsg.startsWith('Invalid URL')) {
            errMessage = getIntlStrings('composer.errors.invalidURL');
          } else if (rawErrMsg.startsWith('Blocked domain')) {
            errMessage = getIntlStrings('composer.errors.blockedDomain');
          } else if (rawErrMsg.startsWith('Unreachable')) {
            errMessage = getIntlStrings('composer.errors.unreachable');
          }

          store.dispatch(
            action.workerAction.error({
              redirect: updatedRedirect,
              preview,
              message: errMessage,
            }),
          );
        }
      }
      break;

    case Actions.api.audienceBuilder.redirect.editSelected.request().type:
      {
        const { payload } = action;

        if (!payload.redirectsIds) {
          throw new Error('Unable to edit/create empty list of redirects');
        }

        payload.pixelsIds = payload.pixelsIds || [];
        payload.tags = payload.tags || [];
        payload.deletedTags = payload.deletedTags || [];
        payload.deletedPixelsIds = payload.deletedPixelsIds || [];

        try {
          const data = await updateRedirects(localStorage, axios, accountFilter, payload);

          store.dispatch(action.workerAction.success({ redirects: data.redirects }));
        } catch (e) {
          console.error(e);
          store.dispatch(action.workerAction.error(e));
        }
      }
      break;

    case Actions.api.audienceBuilder.redirect.editSelected.success().type: {
      store.dispatch(Actions.api.audienceBuilder.redirect.load.request());
      store.dispatch(Actions.api.audienceBuilder.campaigns.reloadAllStats.request());
      break;
    }

    case Actions.api.audienceBuilder.redirect.export.request().type:
      const { selectedColumns, selectedHeaders } = action.payload;
      // create export filter based on current ux
      const exportFilter = {
        ...Object.assign(accountFilter, store.getState().abRedirects.filter, {
          campaignId: store.getState().campaigns.selectedCampaignId,
          subCampaignId: store.getState().campaigns.selectedSubCampaignId,
        }),
        exportFields: selectedColumns,
        exportHeaders: selectedHeaders,
        limit: 50,
      };

      exportRedirects(localStorage, axios, exportFilter).then((result) => {
        store.dispatch(action.workerAction.success(result));
      });
      break;

    case Actions.api.audienceBuilder.redirect.search.request().type:
      return store
        .dispatch(Actions.front.audienceBuilder.redirect.filter.update.request(action.payload))
        .then(() => store.dispatch(Actions.api.audienceBuilder.redirect.load.request()));

    case Actions.api.audienceBuilder.redirect.edit.success().type:
    case Actions.api.audienceBuilder.redirect.duplicate.success().type:
      store.dispatch(updateAccountsUsageThunk());
      break;

    default:
      break;
  }
};
