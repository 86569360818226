import React, { useContext, useEffect, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { ExportButton } from 'components/common/buttons/ExportButton';
import { SmallPlaceholder } from 'components/common/Placeholders';
import ShortInfo from 'components/common/ShortInfo';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { HIDE_RESEARCH_ACCOUNT, useFeatureFlag } from 'lib/configcat';
import { useGetAllProductsForTableQuery, useDeleteProductMutation } from 'stores/productsSlice';

import { ProductsStats } from './ProductsStats';
import { ProductsTable } from './ProductsTable';
import { ACTIVE_CAMPAIGN_OPTIONS } from '../../../../../consts/consts';
import { calculateStats } from '../../common/adsStats';
import { DashboardFilter } from '../../common/DashboardFilter';
import NewProductModal from '../../NewCampaign/NewProductModal';
import { HeaderStyle } from '../Style';

export type StateT = {
  allProducts: Array<productForTableT>,
  products: Array<productForTableT>,
  stats: adsReportDataT,
  adsReportsRange: Date[],
};

const Style = styled.div`
  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }

  .ant-select-selection-item-content {
    font-size: 10px !important;
    line-height: 22px;
  }
`;

export function ProductsDashBoard({ account, productProviderId }: { account: accountT, productProviderId: string }) {
  const { ctx, updateCtx } = useContext(DashboardFilterContext);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  let from = _.get(ctx, ['adsReportsRange', 0]);

  if (from) {
    from = moment(from).toISOString();
  }

  let to = _.get(ctx, ['adsReportsRange', 1]);

  if (to) {
    to = moment(to).toISOString();
  }

  let filterProviderType = _.get(ctx, 'filterProviderType', []);

  filterProviderType =
    filterProviderType && filterProviderType.length > 0 ? filterProviderType.join(',') : filterProviderType;

  // TODO (Matej): implement backend pagination - why not now? because we will also have to create a new endpoint to fetch stats for all the product...
  const { isLoading, data } = useGetAllProductsForTableQuery({
    accountId: account.id,
    from,
    to,
    filterProviderType,
  });

  const filteredProducts = data?.products
    .filter((product) => {
      if (ctx.selectedTags.length > 0) {
        return ctx.selectedTags.some((tag) => product.tags?.includes(tag));
      }

      return true;
    })
    .filter((product) => {
      switch (ctx.filterProductByActiveCampaign) {
        case ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_GOOGLE:
          return product.activeGoogleCampaignsCount > 0;
        case ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_FACEBOOK:
          return product.activeFacebookCampaignsCount > 0;
        case ACTIVE_CAMPAIGN_OPTIONS.ACTIVE_TIKTOK:
          return product.activeTikTokCampaignsCount > 0;
        default:
          return true;
      }
    })
    .filter((product) => {
      if (ctx.searchProduct) {
        const searchTerm = ctx.searchProduct.toLowerCase();

        return (
          product.name.toLowerCase().includes(searchTerm) ||
          product.externalId.toLowerCase().includes(searchTerm) ||
          product.description.toLowerCase().includes(searchTerm)
        );
      }

      return true;
    })
    .map((product) => {
      const adsReport = (product.selectedAdsReport || {}).data;

      return { ...adsReport, ...product };
    });

  const stats = calculateStats(filteredProducts);

  // Empty search when navigate
  useEffect(() => {
    updateCtx((draft) => {
      draft.searchProduct = '';
    });
  }, []);

  if (isLoading) {
    return <SmallPlaceholder text="Loading" />;
  }

  return (
    <Style>
      <ProductsDashBoardHeader account={account} products={filteredProducts} selectedRowKeys={selectedRowKeys} />
      <ProductsStats account={account} stats={stats} productProviderId={productProviderId} />
      <ProductsTable
        account={account}
        setSelectedRowKeys={setSelectedRowKeys}
        products={filteredProducts}
        productProviderId={productProviderId}
      />
    </Style>
  );
}

function ProductsDashBoardHeader({ account, products, selectedRowKeys }) {
  const [hideResearchAccount] = useFeatureFlag(HIDE_RESEARCH_ACCOUNT);
  const [deleteProduct] = useDeleteProductMutation();
  const { messages } = useIntl();

  return (
    <div style={{ marginTop: '20px' }}>
      <HeaderStyle>
        {(selectedRowKeys || []).length > 0 && (
          <Popconfirm
            title="Are you sure to delete all selected products?"
            disabled={(selectedRowKeys || []).length === 0}
            onConfirm={async () => {
              selectedRowKeys.forEach((id) => {
                const { providerId, externalId } = products.find((p) => p.id === id);

                deleteProduct({ accountId: account.id, providerId, productASIN: externalId });
              });
            }}
          >
            <Button danger>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        )}
        <DashboardFilter tagsFilter searchProduct productByActiveCampaignFilter channelsFilter />
        <Form.Item>
          <div style={{ display: 'flex', gap: '1em' }}>
            {!hideResearchAccount && <ExportButton account={account} />}
            <ShortInfo>{messages.locales.dash.info}</ShortInfo>
            <AddNewProductButton account={account} />
          </div>
        </Form.Item>
      </HeaderStyle>
    </div>
  );
}

export function AddNewProductButton({ account }) {
  const [showAddNewProduct, setShowAddNewProduct] = useState(false);
  const { messages } = useIntl();

  return (
    <>
      <Button style={{ width: '100%' }} type="primary" onClick={() => setShowAddNewProduct(true)}>
        {messages.locales.dash.newProduct}
      </Button>
      {showAddNewProduct && (
        <NewProductModal
          account={account}
          onFinish={() => {
            setShowAddNewProduct(false);
          }}
        />
      )}
    </>
  );
}
