import React, { useEffect } from 'react';

import { Card, Form, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import { navigate } from 'hookrouter';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { LabelledCopyPasteRedirect } from './components/LabelledCopyPasteRedirect';
import { LinkTTL } from './components/LinkTTL';
import { SkipNextButton } from './components/SkipNextButton';
import { updateRedirect } from '../../../lib/api';

const Style = styled.div`
  .ant-select-selection-item {
    font-weight: 400;
  }
`;

export function IncreaseCtrForm({ account, state, updateState }) {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(state.redirect);
  }, [JSON.stringify(state.redirect)]);

  async function saveLink(url = state.url) {
    try {
      await updateRedirect(
        localStorage,
        axios,
        {
          accountId: account.id,
        },
        {
          redirectId: state.redirect.id,
          ...state.redirect,
        },
      );
      navigate(`/easy/link-preview/${state.redirect.id}`);
    } catch (e) {
      console.error(e, account, state);
      notification.warn({ message: e.errorMessage });
    }
  }

  async function onChange(changes) {
    const redirect = {
      ...state.redirect,
      ...changes,
    };

    updateState((draft) => {
      draft.redirect = redirect;
    });
  }

  return (
    <Style>
      <Form
        form={form}
        onFinish={saveLink}
        onValuesChange={onChange}
        initialValues={state.redirect}
        defaultValue={state.redirect}
        layout="vertical"
      >
        <Card className="card" title="Edit your short link" extra={<LinkTTL redirect={state.redirect} />}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <Form.Item
              label=""
              initialValue="https://"
              style={{
                marginRight: '-25px',
              }}
            >
              <Input
                value="https://"
                disabled
                style={{
                  flex: '0',
                  minWidth: '90px',
                  width: '90px',
                  color: 'rgba(0, 0, 0, 0.65)',
                  cursor: 'auto',
                }}
              />
            </Form.Item>
            <Form.Item name="domain" label="Edit domain">
              <Select style={{ width: '164px' }} size="large" value="pixelme.me" allowClear={true}>
                {account &&
                  account.domains &&
                  account.domains.map((domain) => (
                    <Select.Option key={domain.name} value={domain.name}>
                      {domain.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="slug" label="Customize slug" style={{ width: 'fit-content', marginRight: '1em' }}>
              <Input addonBefore="/" size="large"></Input>
            </Form.Item>
            <Form.Item label="Your short link">
              <LabelledCopyPasteRedirect redirect={state.redirect}></LabelledCopyPasteRedirect>
            </Form.Item>
          </div>
        </Card>
        <SkipNextButton account={account} step={2} />
      </Form>
    </Style>
  );
}
