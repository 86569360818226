import React, { useRef, useState } from 'react';

import { Form, Input, Select, Tag } from 'antd';

import axios from 'lib/axios.factory';

import { getProductsInfo } from '../../lib/adsPlateformApi';

function CompetitorsSelectorContent(props: { marketplaceId: string, value: productInfoT[], onChange: Function }) {
  const [fetching, setFetching] = useState(false);
  const [inputValue, seInputValue] = useState('');

  let safeValue = props.value || [];

  const inputRef = useRef();

  const focus = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputConfirm = (inputValue) => {
    if (fetching) {
      return;
    }

    // normalize input
    const asins = inputValue
      .split(/[\s,]+/)
      .map((v) => v.trim())
      .filter((v) => v.match(/^[A-Z0-9]{10,10}$/g))
      .filter((v) => !safeValue.some((e) => e.asin === v));

    if (asins.length <= 0) {
      return;
    }

    setFetching(true);

    getProductsInfo(localStorage, axios, { marketplaceId: props.marketplaceId, asins: asins.join(',') })
      .then(({ products }) => {
        safeValue = [...safeValue, ...products];
        props.onChange(safeValue);
        seInputValue('');
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase().trim();

    seInputValue(value);

    if (!/^[a-zA-Z0-9]+$/.test(value) || e.nativeEvent.inputType.startsWith('insertFromPaste')) {
      handleInputConfirm(value);
    }
  };

  const handleRemove = (p: productInfoT) => {
    safeValue = safeValue.filter((e) => e.asin !== p.asin);
    props.onChange(safeValue);
  };

  return (
    <div className="ant-input ant-input-lg" style={{ minHeight: '44px', height: 'auto' }} onClick={focus}>
      {safeValue.map((p: productInfoT) => (
        <Tag
          key={p.asin}
          // onMouseDown={onPreventMouseDown}
          closable={true}
          onClose={() => handleRemove(p)}
          style={{ marginRight: 3 }}
          icon={<img height="19px" style={{ marginRight: 3 }} src={p.imageUrl} />}
        >
          {p.asin}
        </Tag>
      ))}

      {safeValue.length < 5 && (
        <Input
          ref={inputRef}
          key="addCompetitor"
          size="small"
          style={{ width: '130px' }}
          bordered={false}
          disabled={fetching}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={() => handleInputConfirm(inputValue)}
          onPressEnter={() => handleInputConfirm(inputValue)}
        />
      )}
    </div>
  );
}

export function CompetitorsSelector(props: { marketplaceId: string, style?: any }) {
  return (
    <Form.Item
      style={props.style || {}}
      required
      label=""
      name="competitors"
      rules={[
        {
          max: 5,
          type: 'array',
          message: `Maximum five competitors per product`,
        },
      ]}
    >
      <CompetitorsSelectorContent marketplaceId={props.marketplaceId} />
    </Form.Item>
  );
}
