import React, { useEffect } from 'react';

import { omit } from 'lodash';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { BigColorPickerWithLabel } from 'components/BigColorPickerWithLabel';
import { BoxShadow } from 'components/BoxShadow';
import Box from 'components/common/Box';

import { ImgUpload } from '../imgUpload';

const Style = styled.div`
  overflow: auto;
  grid-area: builder;
  text-align: center;
`;

export function Background({ account, page, handleChange }) {
  const [state, updateState] = useImmer({ page });

  useEffect(() => {
    handleChange(omit(state.page, 'avatar'));
  }, [JSON.stringify(state.page)]);

  useEffect(() => {
    updateState((draft) => {
      draft.page = { css: page.css };
    });
  }, [page]);

  if (!state.page.css) {
    updateState((draft) => {
      draft.page.css = {};
    });

    return <></>;
  }

  return (
    <Style style={{ marginTop: '10px' }}>
      <Box className="" header="Background">
        <div style={{ display: 'flex' }}>
          <BoxShadow style={{ width: '150px' }}>
            <BigColorPickerWithLabel
              displayLabel={true}
              title="Background color"
              onChange={(valueHex) => {
                updateState((draft) => {
                  draft.page.css = {
                    ...state.page.css,
                    bgcolor: valueHex,
                    bgimg: '',
                  };
                });
              }}
              value={state.page.css.bgcolor || ''}
            />
          </BoxShadow>
          <BoxShadow style={{ flex: 1, width: '100%' }}>
            <ImgUpload
              account={account}
              label="Background"
              previewSize="50px"
              value={state.page.css.bgimg || ''}
              onChange={(url) => {
                const bgimg = url;

                updateState((draft) => {
                  draft.page.css = {
                    ...state.page.css,
                    bgcolor: '',
                    bgimg,
                  };
                });
              }}
            />
          </BoxShadow>
        </div>
      </Box>
    </Style>
  );
}
