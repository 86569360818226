import './Modal.css';
import type { Node } from 'react';
import React from 'react';

import Button, { CancelButton } from './Button';
import { Buttonize } from './Buttonize';
import { Close } from './icons/Close';
import warningIcon from '../../images/error/warning_icon.svg';

export default function Modal({
  children,
  footer,
  handleOk,
  handleCancel,
  okMessage = 'Yes',
  cancelMessage = 'Cancel',
  disabled = false,
  okOnly = false,
  cancelOnly = false,
  displayButtons = true,
  okOnEnterKey = true,
  className = '',
  title,
  okStyle = {},
  error,
}: {
  children: Node,
  footer: Node,
  handleOk: () => void,
  handleCancel?: () => void,
  okMessage?: string,
  cancelMessage?: string,
  disabled?: boolean,
  okOnly?: boolean,
  cancelOnly?: boolean,
  displayButtons?: boolean,
  okOnEnterKey?: boolean,
  className?: string,
  title?: string,
  okStyle?: Object,
  error?: string,
}) {
  function enterPressed(event) {
    if (okOnEnterKey) {
      const code = event.keyCode || event.which;

      if (code === 13) {
        // 13 is the enter keycode
        handleOk && handleOk();
      }
    }
  }

  const stopPropagation = (e: Event) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div className={error ? `error-modal-mask${className}` : `modal-mask ${className}`} onClick={handleCancel}>
      <div className="modal box" onClick={stopPropagation} onKeyPress={enterPressed.bind(this)}>
        {title && (
          <div className="modal__title__container">
            <div className="modal__title">{title}</div>
            <Buttonize className="modal__close-button">
              <Close onClick={handleCancel} />
            </Buttonize>
          </div>
        )}
        {error && (
          <div className="error_modal__title_container">
            <div style={{ display: 'flex' }}>
              <img alt="warning-icon" className="error_modal__image" src={warningIcon} />
              <h2 className="error_modal__title">{error}</h2>
            </div>
          </div>
        )}
        <div className="modal__content-under-title">
          <div className="modal__content">{children}</div>
          {displayButtons && (
            <div className="modal__buttons">
              {!cancelOnly && (
                <Button style={okStyle} disabled={disabled} handleClick={handleOk} className="modal__button">
                  {okMessage}
                </Button>
              )}
              {!okOnly && (
                <CancelButton disabled={disabled} handleClick={handleCancel} className="modal__button">
                  {cancelMessage}
                </CancelButton>
              )}
            </div>
          )}
        </div>
        <div className="modal__footer">{footer}</div>
      </div>
    </div>
  );
}
