export const getListItems = (domains: Array<domainT>) =>
  (domains || []).map((domain) => ({ id: domain.name, value: domain.name }));

export const getListItem = (domain: domainT) => {
  if (!domain) {
    return domain;
  }

  return { id: domain.name, value: domain.name };
};

export const getListItemFromString = (domainName: string) => {
  if (!domainName) {
    return domainName;
  }

  return { id: domainName, value: domainName };
};
