import React from 'react';

import { Modal, Form, message } from 'antd';
import type { accountT, adsProviderT } from 'flow-typed/pixelme/types';
import { useIntl } from 'react-intl';

import { updateAdsProvider } from 'lib/adsPlateformApi';
import { strings } from 'locales/strings';

import FacebookConversionSettings from './FacebookConversionSettings';

export function FacebookConversionModal(props: {
  account: accountT,
  provider: adsProviderT,
  onFinish: Function,
  visible: boolean,
}) {
  const [form] = Form.useForm();
  const { messages } = useIntl();

  const handleClose = () => {
    form.resetFields();
    props.onFinish();
  };
  const handleSubmit = async (values) => {
    if (values.facebookConversionApiAccessToken === '**************' || !values.facebookConversionApiAccessToken) {
      delete values.facebookConversionApiAccessToken;
    }

    if (values.facebookPixelId === props.provider.facebookPixelId) {
      delete values.facebookPixelId;
    }

    if (Object.keys(values).length > 0) {
      try {
        await updateAdsProvider(
          {
            accountId: props.account.id,
          },
          props.provider.id,
          values,
        );

        message.success('Facebook settings updated successfully...page will refresh shortly', 2, () => {
          window.location.reload();
        });
      } catch (error) {
        message.error(messages.error.apiError);
      } finally {
        form.resetFields();
        props.onFinish();
      }
    } else {
      message.info('No changes detected');
      form.resetFields();
      props.onFinish();
    }
  };

  return (
    <Modal
      visible={props.visible}
      title="Facebook Settings"
      style={{
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white !important',
      }}
      bodyStyle={{ maxHeight: 'calc(100vh - 110px)', overflow: 'auto' }}
      width="50vw"
      height="80vh"
      onCancel={handleClose}
      onOk={form.submit}
      okText="Save"
      destroyOnClose // Allows input to auto focus on subsequent opens
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
        initialValues={{
          facebookPixelId: props.provider?.facebookPixelId,
          facebookConversionApiAccessToken: props.provider?.hasFacebookConversionApi ? '**************' : '',
        }}
      >
        <FacebookConversionSettings account={props.account} provider={props.provider} />
      </Form>
    </Modal>
  );
}
