import React, { Fragment, useEffect, useState } from 'react';

import { Select } from 'antd';
import { flag } from 'country-emoji';
import _, { debounce } from 'lodash';
import styled from 'styled-components';

import { searchGoogleLocation } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { sd } from 'lib/safe';

const HideSearch = styled.div`
  .ant-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 250px;
  }
  .ant-select-item-option-active {
  }
`;

export function GoogleGeoTargetSelector({ account, value, onChange }) {
  const [options, setOptions] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [selected, setSelected] = useState([]);
  const [defaultValue, setDefaultValue] = useState(undefined);

  useEffect(() => {
    if (allLocations.length === 0) {
      setAllLocations([...allLocations, ...sd(value, []).filter((v) => !!v)]);
      setOptions([...options, ...sd(value, []).filter((v) => !!v)]);
      setDefaultValue(
        sd(value, [])
          .filter((v) => !!v)
          .map((v) => v.criteriaId),
      );
      setSelected(
        sd(value, [])
          .filter((v) => !!v)
          .map((v) => v.criteriaId),
      );
    }
  }, [value]);

  const search = async (v) => {
    if (v.length > 0 && v.length < 2) {
      return;
    }

    const locations = await searchGoogleLocation(localStorage, axios, {
      accountId: account.id,
      name: v,
    });
    const newOptions = [...locations, ...selected];

    setAllLocations(_.uniqBy([...allLocations, ...newOptions], 'criteriaId'));

    if (Array.isArray(locations)) {
      setOptions(_.uniqBy(newOptions, 'criteriaId'));
    }
  };

  useEffect(() => {
    search('').then();
  }, []);
  const debouncedSearch = debounce(search, 1000);

  return (
    <Fragment>
      {defaultValue && (
        <Select
          size="large"
          mode="tags"
          placeholder="Select at least one location"
          allowClear
          defaultValue={defaultValue}
          showSearch={false}
          onChange={(criteriaIds) => {
            const selected = criteriaIds.map((criteriaId) =>
              (allLocations || []).filter((o) => !!o).find((p) => `${p.criteriaId}` === `${criteriaId}`),
            );

            setSelected(selected);
            onChange(selected);
          }}
          dropdownRender={(c) => <HideSearch>{c}</HideSearch>}
          onClear={() => {
            setOptions([]);
            setAllLocations([]);
          }}
          filterOption={false}
          onSearch={debouncedSearch}
        >
          <Select.OptGroup label="Countries">
            {_.uniqBy(
              options.filter((l) => !!l && l.canonicalName && l.targetType === 'Country'),
              'criteriaId',
            ).map((l) => (
              <Select.Option
                key={l.criteriaId}
                id={l.criteriaId}
                className="visible"
                style={{
                  fontSize: l.targetType === 'Country' ? '14px' : '12px',
                  fontWeight: l.targetType === 'Country' ? 'bold' : 'normal',
                }}
                value={l.criteriaId}
              >
                {`${flag(l.countryCode) || ''} ${l.canonicalName}`}
              </Select.Option>
            ))}
          </Select.OptGroup>
          <Select.OptGroup label="Cities & Province">
            {_.uniqBy(
              options.filter((l) => !!l && l.canonicalName && l.targetType !== 'Country'),
              'criteriaId',
            ).map((l) => (
              <Select.Option
                key={l.criteriaId}
                id={l.criteriaId}
                className="visible"
                style={{
                  fontSize: l.targetType === 'Country' ? '14px' : '12px',
                  fontWeight: l.targetType === 'Country' ? 'bold' : 'normal',
                }}
                value={l.criteriaId}
              >
                {`${flag(l.countryCode) || ''} ${l.canonicalName}`}
              </Select.Option>
            ))}
          </Select.OptGroup>
        </Select>
      )}
    </Fragment>
  );
}
