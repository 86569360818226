import React, { Component } from 'react';

import styled from 'styled-components';

import Input from 'components/common/Input';

type internalPropsT = {
  name: string,
  value: string,
  title: string,
  pattern: string,
  placeholder: string,
  preInput: ?string,
  displayLabel: ?boolean,
  isBig: ?boolean,
  disabled: ?boolean,
};
type internalActionsT = {
  onChange: (string) => void,
};
type internalStateT = {};
const defaultState: internalStateT = {};

const FakeInput = styled.div.withConfig({
  displayName: 'InputContainerPreInput',
})`
  border-image-repeat: stretch;
  border-image-source: none;
  border: 1px solid rgb(229, 229, 229);
  box-sizing: border-box;
  border-right: 0;
  text-align: right;
  border-radius: 0.2rem 0 0 0.2rem;
  padding: 7px 10px;
  padding-right: 0 !important;
  color: var(--text-muted);
`;

const InputContainerInput = styled(Input).withConfig({
  displayName: 'InputContainerInput',
})`
  width: 160px !important;
  flex: 1;
  margin-top: 18px;
  ${(props) =>
    props.haspreinput
      ? ` input {
    padding-left: 0 !important;
     border-radius: 0 0.2rem 0.2rem 0;
     border: 0;
     }
  `
      : ''};
`;

export const InputContainer = styled.div.withConfig({
  displayName: 'InputContainer',
})`
  height: 100%;
  display: block;
  margin-top: 9px;
  align-items: center;
  flex-direction: column;
`;

export const InputContainerHeader = styled.div.withConfig({
  displayName: 'InputContainerHeader',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  width: 100%;
  font-size: ${(props) => (props.isBig ? 'var(--normal-size)' : 'var(--small-size)')};
`;

export class InputWithLabelUTMs extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const { displayLabel, onChange, title, placeholder, value, name, isBig, preInput, disabled, onBlur } = this.props;

    if (!title) {
      throw new Error('Missing title');
    }

    if (placeholder === undefined) {
      throw new Error(`Missing placeholder for ${title}`);
    }

    if (!onChange || typeof onChange !== 'function') {
      throw new Error(`Missing onChange for ${title}`);
    }

    return (
      <InputContainer>
        {/* {renderIcon && <InputContainerIcon>{renderIcon()}</InputContainerIcon>} */}
        {displayLabel && (
          <InputContainerHeader isBig={isBig}>
            <label htmlFor={name}>{title}</label>
          </InputContainerHeader>
        )}
        {!!preInput && <FakeInput> {preInput} </FakeInput>}
        <InputContainerInput
          id={name}
          value={value}
          haspreinput={preInput}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          onBlur={onBlur}
        />
      </InputContainer>
    );
  }
}
