import './DeleteItem.css';
import React from 'react';

export default function DeleteItem({ handleClick, disabled = false }: { handleClick: () => void, disabled?: boolean }) {
  return (
    <div
      className={`delete-item ${disabled ? 'delete-item--disabled' : ''}`}
      onClick={() => {
        !disabled && handleClick();
      }}
    >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
          <circle className="delete-item__icon" id="Ellipse 2" cx="9" cy="9" r="9" fill="#E5E5E5" />
          <path
            className="delete-item__cross"
            id="Vector 6"
            d="M0 0L8 7.5"
            transform="translate(5 5.5)"
            stroke="white"
          />
          <path
            className="delete-item__cross"
            id="Vector 6.1"
            d="M0 0L8 7.5"
            transform="translate(13 5.5) scale(-1 1)"
            stroke="white"
          />
        </g>
      </svg>
    </div>
  );
}
