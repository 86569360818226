import React, { Fragment, useEffect } from 'react';

import { Button, Divider, message, Steps } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';

import { BoxShadow } from '../../../BoxShadow';
import { SmallPlaceholder } from '../../../common/Placeholders';

const Style = styled.div`
  width: 100%;
  min-height: 100%;
  padding: 1em;
  background-color: white;
  .ant-divider-horizontal {
    margin-top: 0 !important;
  }
  .steps-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 2em;
  }

  .steps-left {
    width: ${(props) => (props.width ? props.width : 'fit-content')};
    flex: 0 0 ${(props) => (props.width ? props.width : '')};
    flex-grow: 1;
  }

  .steps-action {
    margin-left: -8px;
    margin-top: 1em;
    width: ${(props) => (props.width ? props.width : 'fit-content')};
    display: flex;
    justify-content: stretch;
    .ant-btn {
      flex: 0 1 50%;
    }
  }

  .steps-content {
    min-width: 200px;
  }

  .steps-middle {
    position: relative;
    flex: 0 1 fit-content;
    height: fit-content;
    width: fit-content;
    max-width: 480px;
  }
  .steps-right {
    width: 230px;
    position: relative;
    flex: 0 0 220;
    margin-right: 1em;
  }

  .ant-steps-item-icon {
    zoom: 0.5;
    margin-top: 15px;
    margin-left: 15px;
  }

  .ant-steps-item-description {
    margin-top: 1em;
  }

  .ant-steps-item-icon {
    zoom: 1;
    margin-top: 0px;
    margin-left: 0px;
  }

  .ant-steps-item-container {
    outline: none !important;
    margin-bottom: 15px;
  }

  .ant-steps-item-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px !important;
    color: #595959 !important;
  }

  .steps-right-title {
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #071629 !important;
  }
`;

export function Wizard({
  tRef,
  steps,
  stepsTitle,
  currentStep,
  onComplete,
  okText,
  previousText,
  nextText,
  loading,
  width,
}) {
  if (loading === undefined) {
    loading = false;
  }

  const [current, setUnsafeCurrent] = React.useState(currentStep);
  const [currentInError, setCurrentInError] = React.useState(false);

  function getDisabled(step) {
    if (step.disabled && typeof step.disabled === 'function') {
      return step.disabled();
    } else {
      return step.disabled;
    }
  }

  function getStatus(step, stepInc) {
    let status = 'wait';

    if (stepInc < current) {
      status = 'finish';

      return;
    }

    if (current === stepInc) {
      status = currentInError ? 'error' : 'process';
    }

    return status;
  }

  function setCurrent(nextPage) {
    setUnsafeCurrent(nextPage);
  }

  const onValidateAndChange = async () => {
    const { form } = steps[current];

    setCurrentInError(false);
    let values;

    if (form) {
      try {
        values = await form.validateFields();
        const newValues = await steps[current].onChange(values);

        if (newValues) {
          values = newValues;
        }
      } catch (e) {
        console.log(e);
        console.log(`${current}validation error`, e);
        setCurrentInError(true);

        return false;
      }
    }

    return values;
  };

  const next = async () => {
    if (await onValidateAndChange()) {
      const disabled = getDisabled(steps[current + 1]);

      if (!disabled) {
        setCurrent(current + 1);
      } else {
        console.debug('step is disabled');
      }
    }
  };

  const prev = async () => {
    const disabled = getDisabled(steps[current - 1]);

    if (!disabled) {
      setCurrent(current - 1);
    } else {
      console.debug('step is disabled');
    }
  };

  useEffect(() => {
    if (tRef) {
      tRef.current = {
        forceStep: (step) => {
          setUnsafeCurrent(step);
        },
      };
    }
  }, []);

  return (
    <Style width={width}>
      <div
        style={{
          width: 'calc( 100% - 2em)',
          flexDirection: 'column',
          paddingBottom: 0,
          alignItems: 'start',
        }}
      >
        {/* <div className={"steps-right-title"}>{stepsTitle}</div>*/}
        <div style={{ width: '100%' }}>
          <Steps
            current={current}
            onChange={async (nextPage) => {
              if (await onValidateAndChange()) {
                setCurrent(nextPage);
              }
            }}
          >
            {steps.map((step, i) => {
              const status = getStatus(step, i);
              const disabled = getDisabled(step);

              return (
                <Steps.Step key={i} title={step.title} disabled={i > current && disabled} status={status}></Steps.Step>
              );
            })}
          </Steps>
        </div>
        <Divider orientation="horizontal" />
      </div>
      <div className="steps-top">
        <div className="steps-left">
          {steps
            .filter((step) => steps[current].title === step.title)
            .map((step, i) => (
              <Fragment key={step.title || i}>
                <div
                  className="steps-content"
                  style={{
                    height: steps[current].title === step.title ? 'fit-content' : 0,
                  }}
                >
                  <h2>{step.title}</h2>
                  <SmallPlaceholder loading={loading} text="Loading">
                    {typeof step.content === 'function' ? step.content() : step.content}
                  </SmallPlaceholder>
                </div>
              </Fragment>
            ))}
          <div className="steps-action">
            <Fragment>
              <Button
                size="large"
                disabled={current === 0 || loading}
                type="primary"
                ghost
                style={{ margin: '0 8px' }}
                onClick={() => prev()}
              >
                {previousText}
              </Button>
              {current < steps.length - 1 && (
                <Button size="large" disabled={loading} type="primary" onClick={() => next()}>
                  {nextText}
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  size="large"
                  disabled={loading}
                  type="primary"
                  onClick={async (values) => {
                    try {
                      for (const step of steps) {
                        if (step.form) {
                          const errors = (step.form: FormInstance).getFieldsError().flatMap((e) => e.errors);

                          if (errors.length > 0) {
                            console.error(errors);

                            // noinspection ExceptionCaughtLocallyJS
                            throw new Error('Validation errors');
                          }

                          await step.form.validateFields();
                        }
                      }

                      values = await onValidateAndChange();

                      if (onComplete) {
                        setTimeout(() => onComplete(values), 0);
                      }
                    } catch (e) {
                      console.error(e);
                      message.error('Missing some required fields');
                    }
                  }}
                >
                  {okText}
                </Button>
              )}
            </Fragment>
          </div>
          {steps && steps[current] && steps[current].footerExtra && steps[current].footerExtra}
        </div>
        {steps && steps[current] && steps[current].extra && (
          <div className="steps-middle">
            <BoxShadow
              style={{
                width: 'calc( 100% - 1em)',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'start',
              }}
            >
              {steps[current].extra}
            </BoxShadow>
          </div>
        )}
      </div>
    </Style>
  );
}
