import { apiCall, apiCallFormData } from '../api';
import { toSnakeCase } from '../util';

// get redirects
export function getRedirectsWithStats(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {
    accountId: string,
    searchField?: string,
    tags?: Array<string>,
    sort?: string,
    from?: string,
    to?: string,
    start?: number,
    limit: number,
    search?: string,
    cookieConsent?: 'all' | 'true' | 'false',
    redirectsIds?: Array<string>,
    campaignId: string,
    subCampaignId: string,
    productProviderType?: 'AMAZON',
  },
): Promise<{ nbRedirects: number, redirects: Array<accountT> }> {
  const sort = filter.sort ? toSnakeCase(filter.sort) : undefined;

  if (!filter.start) {
    filter.start = 0;
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/redirects-with-stats',
    params: { ...filter, sort },
  });
}

export function addRedirect(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    url: string,
    pixelsIds: Array<string>,
    domain: string,
    tags: Array<string>,
    cookieConsent: boolean,
  },
): Promise<{ redirect: redirectT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ab/redirect',
    params,
    data,
  });
}

export function importRedirects(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    urls: Array<string>,
    pixelsIds: Array<string>,
    domain: string,
    tags: Array<string>,
    cookieConsent: boolean,
  },
): Promise<{ ok: boolean }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ab/redirects',
    params,
    data,
  });
}

export function updateRedirect(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectId: string,
    pixelsIds: Array<string>,
    tags: Array<string>,
    domain: string,
    title: string,
    url: string,
    slug: string,
    campaignId: string,
    subCampaignId: string,
  },
): Promise<{ redirect: redirectT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/redirect',
    params,
    data,
  });
}

export function updatePreview(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    title: string,
    description: string,
    file: any,
    id: string,
  },
): Promise<{ redirect: redirectT }> {
  // $FlowFixMe
  const formData = new FormData();

  formData.append('file', data.file);
  formData.append('title', data.title);
  formData.append('description', data.description);
  formData.append('id', data.id);

  return apiCallFormData({
    localStorage,
    secure: true,
    method: 'post',
    url: 'ab/redirect/upload',
    params,
    data: formData,
    axios,
  });
}

export function updateRedirectsCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectsIds?: Array<string>,
    campaignId?: string,
    subCampaignId?: string,
  },
): Promise<{ redirects: Array<redirectT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/redirects-campaigns',
    params,
    data,
  });
}

export function updateRedirects(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectsIds?: Array<string>,
    pixelsIds?: Array<string>,
    deletedPixelsIds?: Array<string>,
    tags?: Array<string>,
    deletedTags?: Array<string>,
    cookieConsent?: boolean,
    campaignId?: string,
    subCampaignId?: string,
  },
): Promise<{ redirects: Array<redirectT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/redirects',
    params,
    data,
  });
}

export function deleteRedirects(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    redirectsIds: Array<string>,
  },
): Promise<{ redirect: redirectT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'ab/redirects',
    params,
    data,
  });
}

// get redirects
export function exportRedirects(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {
    accountId: string,
    limit: number,
    search?: string,
    searchField?: string,
    tags?: Array<string>,
    sort?: string,
    from?: string,
    to?: string,
    start?: number,
    cookieConsent?: 'all' | 'true' | 'false',
    redirectsIds?: Array<string>,
    campaignId: string,
    subCampaignId: string,
    exportFields: Array<string>,
  },
): Promise<{ total: number, redirects: Array<accountT> }> {
  const sort = filter.sort ? toSnakeCase(filter.sort) : undefined;

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/export-redirects',
    params: { ...filter, limit: -1, sort },
  });
}

// get redirects by ids
export function getRedirectsByIds(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  filter: {
    accountId: string,
    redirectsIds?: Array<string>,
  },
): Promise<{ redirects: Array<accountT> }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ab/redirects-by-ids',
    params: { ...filter },
  });
}

// TODO: Add SA/AB diffenrentiation
export function editCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { campaign: campaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/campaigns',
    params,
    data,
  });
}

export function editSubCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { subCampaign: subCampaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'patch',
    url: 'ab/sub-campaigns',
    params,
    data,
  });
}

export function deleteCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { campaign: campaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'ab/campaigns',
    params,
    data,
  });
}

export function deleteSubCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { subCampaign: subCampaignT },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: 'ab/sub-campaigns',
    params,
    data,
  });
}
