import React, { Component } from 'react';

import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';

import { Actions } from 'actions';
import { s } from 'lib/safe';

import DropDown from '../../../../common/dropDown/DropDown';
import { Columns } from '../../../../common/icons/Columns';

type internalPropsT = { config: columnsConfigT, selectAction: WorkerAction };
type internalActionsT = { onSelect: Function };
type internalStateT = { config: columnsConfigT };
const defaultState: internalStateT = {
  config: {},
};

const ColumnsDropDownIcon = styled(Columns)`
  width: 14px;
  height: 14px;
`;

class AbstractCampaignDatatableConfiguration extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = defaultState;

  constructor(props: internalPropsT) {
    super(props);
    this.state = { config: props.config };
  }

  onSelect(selectedColumns) {
    const { updateConfig } = this.props;
    const { config } = this.state;
    const newConfig = { ...config, selectedColumns };

    this.setState({
      config: newConfig,
    });
    setTimeout(() => updateConfig(newConfig), 0);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { updateConfig } = this.props;
    const { config } = this.state;

    let availableColumns = Object.values(config.availableColumns);

    availableColumns = arrayMove(availableColumns, oldIndex, newIndex);
    availableColumns.forEach((column, index) => {
      column.position = index;
    });
    this.setState({
      config: { ...config, availableColumns },
    });
    setTimeout(() => updateConfig(config), 0);
  }

  static getDerivedStateFromProps(props, state) {
    if (s(state.config.availableColumns).length !== props.config.availableColumns.length) {
      return {
        config: props.config,
      };
    }

    return null;
  }

  render() {
    const { config } = this.state;

    const col = [...config.availableColumns];

    col.sort((a, b) => a.position - b.position);

    return (
      <>
        <DropDown
          sortValues={false}
          sortable={true}
          scrollable={false}
          onSortEnd={this.onSortEnd.bind(this)}
          renderTitle={() => <ColumnsDropDownIcon />}
          size="xs"
          position="right"
          id="columns-configuration"
          placeholder=""
          values={col}
          selected={config.selectedColumns}
          multi={true}
          onSelect={(selectedColumns) => this.onSelect(selectedColumns)}
        />
      </>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  config: state.front.audienceBuilder.campaigns.columnsConfig,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  updateConfig: (config) => {
    dispatch(
      Actions.front.audienceBuilder.campaigns.columns.updateConfig.request({
        config,
      }),
    );
  },
});

const reduxWrapper = connect(stateToProps, dispatchToProps);

export const CampaignDatatableConfiguration = reduxWrapper(AbstractCampaignDatatableConfiguration);
