import React from 'react';

import { Button, Statistic } from 'antd';
import { useIntl } from 'react-intl';

import { useDataProviderModal } from './useDataProviderModal';

export const DataProviderStats = ({ account }) => {
  const [openModal, isAllowed] = useDataProviderModal({ account });
  const { messages } = useIntl();

  if (!isAllowed) {
    return <></>;
  }

  return (
    <Statistic
      title={messages.locales.columns.dataProviders}
      valueRender={() => (
        <Button
          type="link"
          onClick={openModal}
          style={{
            paddingBottom: 0,
            marginBottom: -5,
          }}
        >
          {messages.locales.columns.viewLastUpdate}
        </Button>
      )}
    />
  );
};
