import React from 'react';

import styled from 'styled-components';

const Style = styled.div`
  color: var(--text-muted);
  margin-top: 1em;
  margin-bottom: 1em;
`;

export function MutedText({ children, style }) {
  style = style || {};
  style.fontSize = style.fontSize || '14px';

  return (
    <div style={style}>
      <Style>{children}</Style>
    </div>
  );
}
