import React from 'react';

import { Form, Switch, Tooltip } from 'antd';

export const DeeplinkToggle = (props: {
  account: accountT,
  disabled?: boolean,
  defaultChecked?: boolean,
  tooltip?: string,
}) => {
  const defaultChecked = props.defaultChecked || false;

  let sw = (
    <Switch checkedChildren="On" unCheckedChildren="Off" disabled={props.disabled} defaultChecked={defaultChecked} />
  );

  if (props.tooltip) {
    sw = <Tooltip title={props.tooltip}>{sw}</Tooltip>;
  }

  return (
    <Form.Item
      name="useDeepLinks"
      label="Deep Link to Amazon Mobile App"
      valuePropName="checked"
      disabled={props.disabled}
    >
      {sw}
    </Form.Item>
  );
};
