export function openCreditCardPage(accountID: string) {
  // Get C6_STRIPE_URL from env
  const c6Url = window.env.REACT_APP_C6_URL;

  if (!c6Url) {
    throw new Error('REACT_APP_C6_URL is not defined');
  }

  const callbackUrl = `${location.protocol}//${location.host}/${accountID}/b/card`;

  // Add path to the url
  const c6UrlWithPath = `${c6Url}/payment/card?callback_url=${callbackUrl}&tool_name_id=PIXEL_ME`;

  // Open the page in new tab
  window.location.replace(c6UrlWithPath);
}
