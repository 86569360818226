import React from 'react';

import { useSelector } from 'react-redux';
import { Tooltip as ITooltip } from 'react-tippy';
import styled from 'styled-components';

import { Info } from 'components/common/icons';
import { capitalizeFirstLetter } from 'lib/util';
import { selectCurrentAccount } from 'stores/accounts';

const Container = styled.div.withConfig({ displayName: 'TooltipContainer' })`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: ${(props) => (props.position === 'left' ? 'row-reverse' : 'row')};
`;

const StyledInfo = styled(Info)`
  margin: 5px;
  &:hover {
    cursor: pointer;
  }
`;

export function Tooltip({
  title,
  children,
  position = 'right',
  className,
}: {
  title: string,
  children: any,
  position: 'left' | 'right',
  className?: string,
}) {
  return (
    <Container position={position} className={className}>
      {children}
      <ITooltip title={title} arrow={false} theme="light" size="small" position="top" trigger="click">
        <StyledInfo />
      </ITooltip>
    </Container>
  );
}

export function PixelsCTATooltip({ children, position = 'bottom' }: { position?: string, children: React.Node }) {
  const { plan, account, limitations, sub } = useSelector((state) => {
    let plan = null;
    let sub = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
      sub = state.subscription.sub;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
      limitations: state.accounts.limitations,
      sub,
    };
  });

  const finishTrial = (sub) => {
    const emptyDate = '0001-01-01T00:00:00Z';

    if (sub) {
      if (sub.trialEnd && sub.trialEnd !== emptyDate) {
        return true;
      }
    }

    return false;
  };

  if (!plan) {
    return null;
  }

  let text = `${capitalizeFirstLetter(plan.type)} plan doesn't include retargeting pixels.`;

  if (limitations.pixels > 0) {
    text = `${capitalizeFirstLetter(plan.type)} plan includes only ${limitations.pixels} retargeting pixel${
      limitations.pixels > 1 ? 's' : ''
    }. Delete a pixel or upgrade your account.`;
  }

  return (
    <ITooltip
      html={
        <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
          {text}
          <br />
          <br />
          {finishTrial(sub) ? (
            <a href={planPath(account.id)}>UPGRADE</a>
          ) : (
            <a href={planPath(account.id)}>START A FREE TRIAL</a>
          )}
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function CustomScriptCTATooltip({
  children,
  position = 'bottom-start',
}: {
  position?: string,
  children: React.Node,
}) {
  const { plan, account, sub } = useSelector((state) => {
    let plan = null;
    let sub = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
      sub = state.subscription.sub;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
      sub,
    };
  });

  const finishTrial = (sub) => {
    const emptyDate = '0001-01-01T00:00:00Z';

    if (sub) {
      if (sub.trialEnd && sub.trialEnd !== emptyDate) {
        return true;
      }
    }

    return false;
  };

  if (!plan) {
    return null;
  }

  return (
    <ITooltip
      html={
        <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
          {`${capitalizeFirstLetter(plan.type)} plan doesn’t include custom script feature.`}
          <br />
          <br />
          {finishTrial(sub) ? (
            <a href={planPath(account.id)}>UPGRADE</a>
          ) : (
            <a href={planPath(account.id)}>START A FREE TRIAL</a>
          )}
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function ShortensCTATooltip({ children, position = 'bottom' }: { position?: string, children: React.Node }) {
  const { plan, account, limitations } = useSelector((state) => {
    let plan = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
      limitations: state.accounts.limitations,
    };
  });

  if (!plan) {
    return null;
  }

  return (
    <ITooltip
      html={
        <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
          {`${capitalizeFirstLetter(plan.type)} plan includes up to ${
            limitations.shortens
          } links in your account. Delete some links or upgrade your account.`}
          <br />
          <br />
          <a href={planPath(account.id)}>Upgrade</a>
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function DomainsCTATooltip({ children, position = 'bottom' }: { position?: string, children: React.Node }) {
  const { plan, account, limitations } = useSelector((state) => {
    let plan = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
      limitations: state.accounts.limitations,
    };
  });

  if (!plan) {
    return null;
  }

  return (
    <ITooltip
      html={
        <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
          {`${capitalizeFirstLetter(plan.type)} plan includes only ${limitations.brandedDomains} custom domain${
            limitations.brandedDomains > 1 ? 's' : ''
          }. Upgrade your account or delete a custom domain`}
          <br />
          <br />
          <a href={planPath(account.id)}>Upgrade</a>
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function WorkspacesCTATooltip({ children, position = 'bottom' }: { position?: string, children: React.Node }) {
  const { plan, account, limitations } = useSelector((state) => {
    let plan = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
      limitations: state.accounts.limitations,
    };
  });

  if (!plan) {
    return null;
  }

  let text = `${capitalizeFirstLetter(plan.type)} plan doesn’t include creating workspace.`;

  if (limitations.workspaces > 1) {
    text = `${capitalizeFirstLetter(plan.type)} plan includes up to ${
      limitations.workspaces
    } workspaces. Delete a workspace or upgrade your account.`;
  }

  return (
    <ITooltip
      html={
        <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
          {text}
          <br />
          <br />
          <a href={planPath(account.id)}>Upgrade</a>
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function EditRedirectCTATooltip({
  children,
  position = 'bottom-start',
}: {
  position?: string,
  children: React.Node,
}) {
  const { plan, account } = useSelector((state) => {
    let plan = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
    };
  });

  if (!plan) {
    return null;
  }

  return (
    <ITooltip
      html={
        <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
          {`${capitalizeFirstLetter(plan.type)} plan doesn’t include editing the destination URL.`}
          <br />
          <br />
          <a href={planPath(account.id)}>Upgrade</a>
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function TeamMembersCTATooltip({ children, position = 'bottom' }: { position?: string, children: React.Node }) {
  const { plan, account, limitations, sub } = useSelector((state) => {
    let plan = null;
    let sub = null;

    if (state.subscription.sub) {
      plan = state.subscription.sub.plan;
      sub = state.subscription.sub;
    }

    return {
      account: selectCurrentAccount(state),
      plan,
      limitations: state.accounts.limitations,
      sub,
    };
  });

  const finishTrial = (sub) => {
    const emptyDate = '0001-01-01T00:00:00Z';

    if (sub) {
      if (sub.trialEnd && sub.trialEnd !== emptyDate) {
        return true;
      }
    }

    return false;
  };

  if (!plan) {
    return null;
  }

  let text = `${capitalizeFirstLetter(plan.type)} plan doesn’t include team members access.`;

  if (limitations.teamMembers > 1) {
    text = `${capitalizeFirstLetter(plan.type)} plan includes only ${
      limitations.teamMembers
    } team members. Delete a team member or upgrade your account.`;
  }

  return (
    <ITooltip
      html={
        <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
          {text}
          <br />
          <br />
          {finishTrial(sub) ? (
            <a href={planPath(account.id)}>UPGRADE</a>
          ) : (
            <a href={planPath(account.id)}>START A FREE TRIAL</a>
          )}
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function MonthlyUsageCTATooltip({ children, position = 'bottom' }: { position?: string, children: React.Node }) {
  const account = useSelector(selectCurrentAccount);

  return (
    <ITooltip
      html={
        <div style={{ width: '180px', textAlign: 'left', fontSize: '12px' }}>
          Monthly limit reached - We're not tracking your analytics anymore -{' '}
          <a
            href="https://www.carbon6.io/pixelme-help/what-happens-if-i-reach-my-click-limit-for-the-month?"
            target="_blank"
            rel="noopener noreferrer"
          >
            read more
          </a>
          <br />
          <br />
          <a href={planPath(account.id)}>Upgrade</a>
        </div>
      }
      arrow="true"
      theme="light"
      size="large"
      position={position}
      interactive={true}
      animation="fade"
    >
      {children}
    </ITooltip>
  );
}

export function planPath(accountId: string): string {
  return `/${accountId}/b/plans`;
}
