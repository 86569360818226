import React, { Fragment } from 'react';

import { Statistic, Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { BrbNotOnboarded, BrbValue } from './BrbValue';
import { roasFormatter } from './constants';

export function AmazonAdCostStats(props: { cost: number, currency: string }) {
  const { messages } = useIntl();

  return <Statistic title="Amazon Ad Cost" value={props.cost} precision={2} prefix={props.currency} />;
}

export function TotalAdCostStats(props: { cost: number, currency: string }) {
  const { messages } = useIntl();

  return (
    <Statistic title={messages.locales.columns.totalAdCost} value={props.cost} precision={2} prefix={props.currency} />
  );
}

export function TotalSalesStats(props: { sales: number, currency: string }) {
  const { messages } = useIntl();

  return <Statistic title="Total Sales" value={props.sales} precision={2} prefix={props.currency} />;
}

export function OrganicSalesStats(props: { sales: number, currency: string }) {
  const { messages } = useIntl();

  return <Statistic title="Organic Sales" value={props.sales} precision={2} prefix={props.currency} />;
}

export function TacosStats(props: { tacos: number }) {
  const { messages } = useIntl();

  return (
    <Statistic
      title="TACOS"
      value={props.tacos}
      valueStyle={{ color: props.tacos <= 100 ? '#0F993E' : 'inherit' }}
      precision={0}
      suffix="%"
    />
  );
}

export function ImpressionsStats(props: { impressions: number }) {
  const { messages } = useIntl();

  return (
    <Tooltip placement="bottom" title="Data coming from ad platforms. Can take a few hours to be updated.">
      <Statistic title={`${messages.locales.columns.impressions} ℹ`} value={props.impressions} />
    </Tooltip>
  );
}

export function ClicksStats(props: { clicks: number }) {
  const { messages } = useIntl();

  return (
    <Tooltip placement="bottom" title="Data coming from ad platforms. Can take a few hours to be updated.">
      <Statistic title={`${messages.locales.columns.clicks} ℹ`} value={props.clicks} />
    </Tooltip>
  );
}

export function TotalCostStats(props: { cost: number, currency: string }) {
  const { messages } = useIntl();

  return (
    <Tooltip placement="bottom" title="Data coming from ad platforms. Can take a few hours to be updated.">
      <Statistic
        title={`${messages.locales.columns.totalAdCost} ℹ`}
        precision={2}
        prefix={props.currency}
        value={props.cost}
      />
    </Tooltip>
  );
}

export function PurchasesStats(props: { purchases: number }) {
  const { messages } = useIntl();

  return (
    <Tooltip placement="bottom" title="Data coming from Amazon Attribution. Can take up to 3 days to be updated.">
      <Statistic title={`${messages.locales.columns.purchases} ℹ`} precision={0} value={props.purchases} />
    </Tooltip>
  );
}

export function AddToCardStats(props: { adds: number }) {
  const { messages } = useIntl();

  return (
    <Tooltip placement="bottom" title="Data coming from Amazon Attribution. Can take up to 3 days to be updated.">
      <Statistic title={`${messages.locales.columns.addToCart} ℹ`} precision={0} value={props.adds} />
    </Tooltip>
  );
}

export function RevenueStats(props: { revenue: number, currency: string }) {
  const { messages } = useIntl();

  return (
    <Tooltip placement="bottom" title="Data coming from Amazon Attribution. Can take up to 3 days to be updated.">
      <Statistic
        title={`${messages.locales.columns.revenue} ℹ`}
        precision={2}
        prefix={props.currency}
        value={props.revenue}
      />
    </Tooltip>
  );
}

export function NetMarginStats(props: { netMargin: number, currency: string }) {
  const { messages } = useIntl();

  return (
    <Tooltip placement="bottom" title="Data calculated by PixelMe based on the product created.">
      <Statistic
        title={`${messages.locales.columns.netMargin} ℹ`}
        precision={2}
        prefix={props.currency}
        value={props.netMargin}
      />
    </Tooltip>
  );
}

export function ROASStats(props: { roas: number }) {
  const { roas } = props;
  const { messages } = useIntl();

  return (
    <Tooltip
      placement="bottom"
      title="Return on Ad Spend (ROAS) calculates how much revenue was generated from a specific ad or campaign."
    >
      <Statistic
        title={`${messages.locales.columns.roas} ℹ`}
        precision={0}
        valueStyle={{ color: roas >= 0 && roas < 100 ? 'inherit' : '#0F993E' }}
        value={roasFormatter(roas, false)}
        suffix="%"
      />
    </Tooltip>
  );
}

export function ACOSStats(props: { acos: number }) {
  const { messages } = useIntl();

  let { acos } = props;

  if (acos < 0) {
    acos = 0;
  }

  return (
    <Tooltip
      placement="bottom"
      title="Advertising Cost of Sales (ACOS), is how much you spend on advertising per dollar of revenue you make."
    >
      <Statistic
        title={`${messages.locales.columns.acos} ℹ`}
        precision={0}
        valueStyle={{ color: acos > 100 ? 'inherit' : '#0F993E' }}
        value={acos}
        suffix="%"
      />
    </Tooltip>
  );
}

export function ReferralBonusStats(props: {
  brbBonusAmount: number,
  brbBonusAmountCalculated: number,
  currencySymbol: string,
  currencyCode: string,
  brbEnrolled: boolean,
}) {
  const { messages } = useIntl();

  return (
    <Fragment>
      {/* props.brbEnrolled  === undefined => non US Marketplace so we display the calculated Amount that is inside brbBonusAmount*/}
      {/* TODO change brbBonusAmountCalculated to brbBonusAmount when we will have the explanation from Amazon*/}
      {props.brbEnrolled !== false && (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <Statistic
            style={{ width: '100%', minWidth: '' }}
            title={
              <span>
                Average Brand
                <br /> Referral Bonus ℹ
              </span>
            }
            precision={2}
            prefix={props.currency}
            value={BrbValue.formatCurrency(props.brbBonusAmountCalculated, props.currencyCode)}
          />
        </Tooltip>
      )}
      {props.brbEnrolled === false && (
        <BrbNotOnboarded
          brbBonusAmountCalculated={props.brbBonusAmountCalculated}
          style={{
            color: 'var(--bad-color)',
            width: '120px',
            padding: '0 !important',
            paddingLeft: 5,
            paddingRight: 5,
          }}
          currencyCode={props.currencyCode}
        />
      )}
    </Fragment>
  );
}
