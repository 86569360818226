import styled from 'styled-components';

export const CopiableCellSortIndicator = styled.div.withConfig({
  displayName: 'CopiableCellSortIndicator',
})`
  > div {
    margin-top: -3px;
    width: 10px;
    height: 10px;
  }

  cursor: ${(props) => (props.state ? 'auto' : 'pointer')};
  path {
    stroke: ${(props) => (props.state ? '#393939' : '#828282')};
  }
`;

export const CopiableCellSortIndicatorContainer = styled.div.withConfig({
  displayName: 'CopiableCellSortIndicatorContainer',
})`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 6px;
`;

export const CopiableCellContainer = styled.div.withConfig({
  displayName: 'CopiableCellContainer',
})`
  color: ${(props) => props.color};
  display: flex;
  height: 100%;
  width: 100%;
`;

export const CopiableCellChildrenContainer = styled.div.withConfig({
  displayName: 'CopiableCellChildrenContainer',
})`
  color: ${(props) => props.color};
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : null)};
`;

export const GroupsListDatatableContentHeader = styled.div.withConfig({
  displayName: 'GroupsListDatatableContentHeader',
})`
  font-size: 12px;
  div {
    color: var(--text-muted) !important;
  }
  background-color: white;
  z-index: 0;
  display: grid;
  grid-auto-rows: 50px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  grid-auto-columns: min-content;
  width: fit-content;
  min-width: 100%;
  border-bottom: var(--border-size) solid #e5e5e5;
  position: sticky;
  top: 0;
  .${CopiableCellChildrenContainer.styledComponentId} {
    width: auto !important;
  }
`;

export const FiltersListDatatableContentHeader = styled.div.withConfig({
  displayName: 'FiltersListDatatableContentHeader',
})`
  font-size: 12px;
  div {
    color: var(--text-muted) !important;
  }
  background-color: white;
  z-index: 0;
  display: grid;
  grid-auto-rows: 50px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  grid-auto-columns: min-content;
  //width: fit-content;
  //min-width: 100%;
  border-bottom: var(--border-size) solid #e5e5e5;
  position: sticky;
  top: 0;
  .${CopiableCellChildrenContainer.styledComponentId} {
    width: auto !important;
  }
  height: 42px;
`;

export const RedirectsListDatatableContentHeader = styled.div.withConfig({
  displayName: 'RedirectsListDatatableContentHeader',
})`
  font-size: 12px;
  div {
    color: var(--text-muted) !important;
  }
  background-color: white;
  z-index: 0;
  display: grid;
  grid-auto-rows: 50px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  grid-auto-columns: min-content;
  width: fit-content;
  min-width: 100%;
  border-bottom: var(--border-size) solid #e5e5e5;
  position: sticky;
  top: 0;
  .${CopiableCellChildrenContainer.styledComponentId} {
    width: auto !important;
  }
`;

export const LinksListDatatableContentHeader = styled.div.withConfig({
  displayName: 'RedirectsListDatatableContentHeader',
})`
  font-size: 12px;
  div {
    color: var(--text-muted) !important;
  }
  background-color: white;
  z-index: 0;
  display: grid;
  grid-auto-rows: 50px;
  -webkit-column-gap: 10px;
  column-gap: 10px;
  grid-auto-columns: min-content;
  //width: fit-content;
  min-width: 100%;
  border-bottom: var(--border-size) solid #e5e5e5;
  position: sticky;
  top: 0;
  .${CopiableCellChildrenContainer.styledComponentId} {
    width: auto !important;
  }
`;
