import React from 'react';

import moment from 'moment';
import styled from 'styled-components';

import { DateInputAsDate } from '../../../pages/AdsProviders/common/DateInputAsDate';

const Style = styled.div`
  .ant-form-item-control {
    min-height: auto !important;
  }
`;

function disableDateRanges(range = { startDate: false, endDate: false }) {
  const { startDate, endDate } = range;

  return function disabledDate(current) {
    let startCheck = true;
    let endCheck = true;

    if (startDate) {
      startCheck = current && current < moment(startDate, 'YYYY-MM-DD');
    }

    if (endDate) {
      endCheck = current && current > moment(endDate, 'YYYY-MM-DD');
    }

    return (startDate && startCheck) || (endDate && endCheck);
  };
}

export function InputDateWithDisabledRange({ startDate, endDate, ...otherProps }) {
  return (
    <Style>
      <DateInputAsDate {...otherProps} disabledDate={disableDateRanges({ startDate, endDate })} />
    </Style>
  );
}
