import React from 'react';

import { Keys, MenuItem } from '@blueprintjs/core';

import { addSourceToUtmsValues } from 'lib/utms';
import { useLocale } from 'locales/LocalizationWrapper';
import { getListItems, getListItemFromString, getValue } from 'selectors/utms.selector';

import { DropDownWithLabel } from '../DropDownWithLabel';

export function ComposerSourceSelect({ localUpdateOfAccount, onChange, redirect, account }) {
  const { getIntlStrings } = useLocale();

  function updateSource(source) {
    const newRedirect = {
      ...redirect,
      utms: { ...redirect.utms, source: getValue(source) },
    };

    redirect.utms = newRedirect.utms;
    onChange({ redirect: newRedirect });
    account.utmsValues = addSourceToUtmsValues(account.utmsValues, source);
    localUpdateOfAccount({ account });
  }

  return (
    <DropDownWithLabel
      account={account}
      values={getListItems(account.utmsValues.source)}
      selected={getListItemFromString(redirect.utms.source)}
      createNewItemRenderer={(query: string, active: boolean) => (
        <MenuItem
          icon="add"
          className="bp3-add-option"
          text={`Add "${query}"`}
          active={active}
          onClick={() => {
            const source = query;

            updateSource(source);
          }}
          shouldDismissPopover={false}
        />
      )}
      createNewItemFromQuery={(source) => source}
      title={getIntlStrings('utm.source.select.title')}
      placeholder={redirect.utms.source || getIntlStrings('utm.source.select.placeholder')}
      onKeyUp={(event) => {
        event.preventDefault();

        if (event.keyCode === Keys.ENTER) {
          const source = event.target.value.trim();

          updateSource(source);
        }
      }}
      onSelect={(selectedSource) => {
        const newRedirect = {
          ...redirect,
          utms: {
            ...redirect.utms,
            source: selectedSource.id,
          },
        };

        onChange({ redirect: newRedirect });
      }}
      displayLabel
    />
  );
}
