import React, { useMemo, type Node } from 'react';

import styled from 'styled-components';

import { MetricsToggle } from './MetricsToggle';
import { METRICS } from '../constants';

const Container = styled.div`
  background-color: #fff;
`;

const GroupRow = styled.div`
  padding: 0 1rem;
  border-top: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
`;

const GroupRowHeader = styled.h3`
  padding: 0.25rem 0;
  font-size: 0.875rem;
  display: inline;
  text-align: right;
  max-width: 200px;
  width: 100%;
  margin: 0;
  margin-right: 2.5rem;
  text-transform: capitalize;
`;

const TogglesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 3rem;
  align-items: center;
`;

const groupMetrics = (data) => {
  const result = {
    performance: [],
    ads: [],
    rank: [],
  };

  data.forEach((item) => {
    switch (item.name) {
      case METRICS.ACOS:
      case METRICS.ROAS:
      case METRICS.TACOS:
      case METRICS.TROAS:
      case METRICS.BLENDED_ACOS:
      case METRICS.BLENDED_ROAS:
      case METRICS.PRICE:
        result.performance.push(item);
        break;
      case METRICS.CLICKS:
      case METRICS.ADD_TO_CARTS:
      case METRICS.IMPRESSIONS:
      case METRICS.PURCHASES:
      case METRICS.AD_SPEND:
      case METRICS.AMAZON_AD_SPEND:
      case METRICS.REVENUE:
      case METRICS.ORGANIC_SALES:
        result.ads.push(item);
        break;
      default: {
        if (item.name.startsWith(METRICS.BSR)) {
          result.rank.push(item);
        }
      }
    }
  });

  return result;
};

type propsT = {
  data: {
    id: string,
    name: string,
    selected: boolean,
    disabled: boolean,
    color: string,
  }[],
  selected: string[],
  onSelect: (string[]) => void,
  isAmazonProduct?: boolean,
};

export const MetricsGraphLegend = ({ data, selected, onSelect, isAmazonProduct }: propsT): Node => {
  const metricsGroups = useMemo(() => {
    const groups = groupMetrics(data);

    if (!isAmazonProduct || !groups.rank.length) {
      delete groups.rank;
    }

    return groups;
  }, [data, isAmazonProduct]);
  const categories = useMemo(() => Object.keys(metricsGroups), [metricsGroups]);

  const handleSelect = (label) => {
    if (selected.includes(label)) {
      onSelect(selected.filter((x) => x !== label));
    } else {
      onSelect([...selected, label]);
    }
  };

  return (
    <Container>
      {categories.map((category) => (
        <GroupRow key={category}>
          <GroupRowHeader>{category}:</GroupRowHeader>
          <TogglesContainer>
            {metricsGroups[category].map((metric) => (
              <MetricsToggle
                key={metric.id}
                selected={metric.selected}
                disabled={metric.disabled}
                label={metric.name}
                color={metric.color}
                onSelect={handleSelect}
              />
            ))}
          </TogglesContainer>
        </GroupRow>
      ))}
    </Container>
  );
};
