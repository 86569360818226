import styled from 'styled-components';

const CampaignMenu = styled.div.withConfig({
  displayName: 'CampaignMenu',
})`
  opacity: 0.5;
  width: 0;
  position: absolute;
  right: 0px;
  transition: width 0.3s ease-in-out;
  height: 23px;
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  background-color: rgba(252, 252, 252, 0.9);
`;

export default CampaignMenu;
