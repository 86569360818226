import React from 'react';

export function Pinterest() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none" viewBox="0 0 16 16">
      <g>
        <circle cx="8" cy="8" r="8" fill="#BE000F"></circle>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M1.735 2.858c0-.33.083-.598.248-.802.166-.223.365-.335.598-.335.195 0 .34.063.438.19.107.136.16.296.16.48 0 .108-.024.249-.073.424-.078.31-.131.51-.16.598a6.38 6.38 0 00-.16.54.657.657 0 00.13.612.723.723 0 00.57.247c.408 0 .743-.228 1.006-.685.262-.467.393-1.026.393-1.677 0-.496-.16-.904-.48-1.225C4.073.914 3.621.758 3.047.758c-.661 0-1.186.21-1.575.627A2.025 2.025 0 00.86 2.873c0 .34.098.632.292.875.068.068.092.15.073.248-.03.078-.058.194-.088.35-.019.068-.048.107-.087.117a.155.155 0 01-.131 0 1.335 1.335 0 01-.686-.642A2.258 2.258 0 010 2.77c0-.253.044-.51.131-.773.097-.282.224-.53.38-.744.145-.204.354-.418.626-.641.244-.185.545-.336.905-.453A4.02 4.02 0 013.165 0c.418 0 .802.073 1.152.219.36.136.656.32.89.554.233.233.422.505.568.817.136.32.204.636.204.948 0 .884-.223 1.609-.67 2.172-.448.574-1.022.86-1.722.86-.252 0-.471-.053-.656-.16a1.028 1.028 0 01-.437-.393c-.156.68-.258 1.089-.307 1.225-.087.35-.296.758-.627 1.225h-.306c-.058-.603-.044-1.099.044-1.488l.569-2.406c-.088-.185-.132-.423-.132-.715z"
          clipRule="evenodd"
          transform="translate(5.2 4.2)"
        ></path>
      </g>
    </svg>
  );
}
