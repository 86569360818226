import React from 'react';

export function RoundInfo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none" viewBox="0 0 13 13">
      <path
        fill="#000"
        d="M7.008 5.23H5.992a.254.254 0 00-.254.254v4.57c0 .14.114.255.254.255h1.016c.14 0 .254-.114.254-.254v-4.57a.254.254 0 00-.254-.255z"
      ></path>
      <path
        fill="#000"
        d="M6.5 0A6.493 6.493 0 000 6.5C0 10.095 2.904 13 6.5 13c3.595 0 6.5-2.904 6.5-6.5C13 2.905 10.096 0 6.5 0zm0 12.492A5.999 5.999 0 01.508 6.5 5.999 5.999 0 016.5.508 5.999 5.999 0 0112.492 6.5 5.999 5.999 0 016.5 12.492z"
      ></path>
      <path fill="#000" d="M6.5 4.215a.762.762 0 100-1.524.762.762 0 000 1.524z"></path>
    </svg>
  );
}
