import React from 'react';

import styled from 'styled-components';

import { CopyPaste } from './CopyPasteRedirect';

const Style = styled.div`
  background: rgba(14, 83, 150, 0.1);
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: #0e5396;
  font-size: 14px;
  height: 45px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  flex: 0;
`;

export function LabelledCopyPasteRedirect({ redirect, ...props }) {
  return (
    <Style {...props}>
      <div
        style={{
          maxWidth: '300px',
          textOverflow: 'ellipse',
          overflow: 'hidden',
        }}
      >
        {`https://${redirect.domain}/${redirect.slug}`}
      </div>
      <CopyPaste redirect={redirect} />
    </Style>
  );
}
