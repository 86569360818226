import React from 'react';

import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'components/common/Button';

import { BaseBanner } from './common';

const CtaContainer = styled.div`
  margin-left: 2rem;
`;

const Style = styled(BaseBanner)`
  background: var(--ok-color);

  ${(props) =>
    props.$compact &&
    `
    background: none;
    color: var(--text-color);
    ${CtaContainer} {
      display: none;
    }
  `}
`;

const Bold = styled.span`
  font-weight: bolder;
`;

const TrialHeader = ({
  sub,
  displayTrialHeader,
  account,
  compact,
}: {
  sub: subscriptionT,
  displayTrialHeader: boolean,
  account: accountT,
  compact: boolean,
}) => {
  if (!displayTrialHeader) {
    return null;
  }

  let trialEnd = moment(sub.trialEnd);

  if (sub.businessTrialEnd) {
    const businessTrialEnd = moment(sub.businessTrialEnd);

    if (businessTrialEnd.diff(trialEnd) > 0) {
      trialEnd = businessTrialEnd;
    }
  }

  return (
    <Style $compact={compact} className={compact ? 'trial-header trial-header-compact' : 'trial-header'}>
      <div>
        You have <Bold>{trialEnd.diff(moment(), 'days')}</Bold> days left on your trial
      </div>
      <CtaContainer>
        <Link to={`/${account.id}/b/plans`}>
          <Button>View plans</Button>
        </Link>
      </CtaContainer>
    </Style>
  );
};

export default TrialHeader;
