import { parse } from 'json2csv';
import fileDownload from 'react-file-download';

import { HoverLink } from 'components/common/HoverLink';
import { Csv } from 'components/common/illustrations/Csv';
import { useLoading } from 'hooks/useLoading';
import { getVariationTimeSeries } from 'lib/adsPlateformApi';

type ExportVariationProps = {
  account: accountT,
  variation: variationT,
};
export const ExportVariation = ({ account, variation }: ExportVariationProps) => {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);

  return (
    <ExclusiveInlineLoadingContainer>
      <HoverLink
        style={{ width: '100%' }}
        content="Download data"
        onClick={() => {
          doAction(async () => {
            const data = await getVariationTimeSeries({ accountId: account.id }, variation.id);
            const result = parse(data?.timeseriesAdsReports, []);

            fileDownload(result, `${variation.name}.csv`);
          });
        }}
      >
        <Csv />
      </HoverLink>
    </ExclusiveInlineLoadingContainer>
  );
};
