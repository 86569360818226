import './Notices.css';

import React from 'react';

import { connect } from 'react-redux';

import { removeNotice } from 'stores/notices';

function Notices({ notices, removeNotice }: { notices: Array<noticeT>, removeNotice: (string) => void }) {
  if (notices.length > 0) {
    return (
      <div className="notices-container">
        <ul className="notices">
          {notices.map((n: noticeT) => (
            <li key={n.id} className={`notice--${n.type}`}>
              <div className="notice__icon">{renderIcon(n.type)}</div>
              <div className="notice__message">{n.message}</div>
              <div
                className="notice__cross"
                onClick={(_) => {
                  removeNotice(n.id);
                }}
              >
                {renderCross()}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return null;
}

function renderIcon(type: string) {
  if (type === 'error') {
    return (
      <svg
        className="icon-type error"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="9" cy="9" r="9" fill="#FB3B49" />
        <path d="M0 0L8 7.5" transform="translate(5 5.5)" stroke="white" />
        <path d="M0 0L8 7.5" transform="translate(13 5.5) scale(-1 1)" stroke="white" />
      </svg>
    );
  }

  return (
    <svg
      className="icon-type ok"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="#2EC4B6" />
      <path d="M0 5L3 7.5L8.5 0" transform="translate(5 5.5)" stroke="white" />
    </svg>
  );
}

function renderCross() {
  return (
    <svg className="cross" width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0L8 8M0 8L8 0" transform="translate(1 0.5)" stroke="#828282" strokeLinecap="round" />
    </svg>
  );
}

const mapStateToProps = function (state): { notices: Array<noticeT> } {
  const { notices } = state.notices;

  return {
    notices,
  };
};

export default connect(mapStateToProps, { removeNotice })(Notices);
