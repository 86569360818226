import React, { useEffect, useState } from 'react';

import { Form, Select } from 'antd';

import { getProductTags } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { strings } from 'locales/strings';

export function ProductTagsSelector({ account, size, onChange, maxWidth, placeholder, useOnlyExistingTags }) {
  return (
    <div style={{ minWidth: '211px', maxWidth: maxWidth || 'auto' }}>
      <Form.Item name="tags">
        <EmptyCompliantSelect
          account={account}
          size={size}
          onChange={onChange}
          placeholder={placeholder}
          useOnlyExistingTags={useOnlyExistingTags}
        />
      </Form.Item>
    </div>
  );
}

function EmptyCompliantSelect({ value = [], onChange, account, size, placeholder, useOnlyExistingTags }) {
  const [existingTags, setExistingTags] = useState([]);

  async function load() {
    const response = await getProductTags(localStorage, axios, {
      accountId: account.id,
    });

    setExistingTags(response.tags);
  }

  useEffect(() => {
    load().then();
  }, []);

  return (
    <Select
      value={value}
      onChange={(e) => {
        onChange(e);
      }}
      options={existingTags.map((e) => ({ label: e, value: e }))}
      maxTagCount={5}
      size={size}
      placeholder={placeholder}
      maxLength={5}
      mode={useOnlyExistingTags ? 'multiple' : 'tags'}
    ></Select>
  );
}
