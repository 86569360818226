import React from 'react';

import { Tooltip } from 'antd';
import styled from 'styled-components';

import { transformBigNumberToHumanReadable } from '../../../../lib/util';

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #595959;
  margin-right: 10px;
`;

const Paragraph = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2px;
  margin-bottom: 6px;
`;

const Value = styled.div`
  display: inline-block;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #595959 !important;
  overflow: hidden;

  .ant-statistic-content {
    color: #595959 !important;
    font-size: 13px;
    line-height: 18px;
  }
`;

/**
 * Facebook Graph Brows api result to tree data
 * @param paths
 * @returns {[]}
 */
function resultToTree(paths) {
  const tree = [];

  for (let i = 0; i < paths.length; i++) {
    const { path } = paths[i];
    const { value } = paths[i];

    let currentLevel = tree;

    for (let j = 0; j < path.length; j++) {
      const part = path[j];

      const existingPath = findWhere(currentLevel, 'value', part);

      if (existingPath) {
        currentLevel = existingPath.children;

        if (j === path.length - 1) {
          currentLevel.push(value);
        }
      } else {
        const newPart = {
          title: part,
          value: part,
          disableCheckbox: true,
          checked: false,
          selectable: false,
          key: `${path.join('/')}/${part}`,
          children: j === path.length - 1 ? [value] : [],
        };

        currentLevel.push(newPart);
        currentLevel = newPart.children;
      }
    }
  }

  return tree;

  function findWhere(array, key, value) {
    let t = 0; // t is used as a counter

    while (t < array.length && array[t][key] !== value) {
      t++;
    } // find the index where the id is the as the aValue

    if (t < array.length) {
      return array[t];
    } else {
      return false;
    }
  }
}

function TreeLine(props: { item: any }) {
  return (
    <div>
      <Tooltip
        placement="right"
        overlayStyle={{ maxWidth: '400px', marginLeft: '20px' }}
        color="white"
        title={
          <div>
            <Paragraph>
              <Title>Size</Title>
              <Value>
                {transformBigNumberToHumanReadable(props.item.audienceSizeLowerBound, 0)}-
                {transformBigNumberToHumanReadable(props.item.audienceSizeUpperBound, 0)}
              </Value>
            </Paragraph>
            {props.item.description && (
              <Paragraph>
                <Title>Description</Title>
                <Value>{props.item.description}</Value>
              </Paragraph>
            )}
          </div>
        }
      >
        {props.item.name}
      </Tooltip>
    </div>
  );
}

function getKey(item) {
  return `${item.type}|${item.id}|${item.name}`;
}

export function buildTreeDataFromFacebookBrowseAPIResult(browseItems) {
  let tree = [];

  const values = [];

  for (const item of browseItems) {
    // targetsearch return one more path that is the same as the name, we need to remove it
    if (item.path[item.path.length - 1] === item.name) {
      console.log(item.path[item.path.length - 1], item.name);
      item.path = item.path.slice(0, -1);
    }

    // create a unique key (names are not unique, ids are not unique)
    const key = getKey(item);

    // component that will be display in the tree (hack to be able to have a tooltip there)
    const titleComponent = <TreeLine item={item} />;

    // we have to push that to push that to expand all keys
    for (let i = 0; i < item.path.length; i++) {
      values.push({
        key: [...item.path, item.path[i]].join('/'),
        title: '',
      });
    }

    // we have to have an flat array of values for the transfert view
    values.push({
      key,
      title: titleComponent,
      description: item.description,
    });

    // tree will be use as data by the tree component
    tree.push({
      path: item.path,
      value: {
        path: item.path,
        key,
        title: titleComponent,
        value: getKey(item),
      },
    });
  }

  tree = resultToTree(tree);

  return { tree, values };
}

/**
 * Allow to deep filter on the treeData
 * @param object
 * @param key
 * @param value
 * @returns {null|*}
 */
export function deepFilterOnTree(object, key, value) {
  if (!object) {
    return;
  }

  if (Array.isArray(object)) {
    for (const obj of object) {
      const result = deepFilterOnTree(obj, key, value);

      if (result) {
        return result;
      }
    }
  } else {
    if (object.hasOwnProperty(key) && object[key] === value) {
      return object;
    }

    for (const k of Object.keys(object)) {
      if (typeof object[k] === 'object') {
        const o = deepFilterOnTree(object[k], key, value);

        if (o !== null && typeof o !== 'undefined') {
          return o;
        }
      }
    }

    return null;
  }
}
