import { PauseOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm } from 'antd';

import { GOOGLE_RMF, useFeatureFlag } from 'lib/configcat';
import { usePauseVariationMutation, useRunVariationMutation } from 'stores/variationsSlice';

import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { DashboardFilter } from '../../common/DashboardFilter';
import { ShowProduct } from '../../common/ShowProduct';
import { NewKeywordButton } from '../../NewCampaign/NewKeywordButton';
import { HeaderStyle } from '../Style';

export const VariationsDashboardHeader = ({ campaign, product, adGroupId, selectedRowKeys = [], reload }) => {
  const [canDeleteKeywordsForRMF] = useFeatureFlag(GOOGLE_RMF);
  const [pauseVariation] = usePauseVariationMutation();
  const [runVariation] = useRunVariationMutation();

  const bulkAction = (action) => async () => {
    // Cannot be concurrent since each variation affects the same ad group
    for (const id of selectedRowKeys) {
      await action({ id, invalidate: false });
    }
    reload();
  };

  return (
    <HeaderStyle>
      <Card bordered={false}>
        <Card.Meta
          avatar={<AdsProviderLogo style={{ height: 30 }} providerType={campaign.providerType} />}
          title={campaign.name || 'Google Campaign'}
        />
      </Card>
      <ShowProduct product={product} />
      {selectedRowKeys.length > 0 && (
        <>
          <Popconfirm
            title="Are you sure you want to pause all selected keywords?"
            disabled={!selectedRowKeys.length}
            onConfirm={bulkAction(pauseVariation)}
          >
            <Button
              style={{
                color: 'rgb(248, 165, 27)',
                borderColor: 'rgb(248, 165, 27)',
              }}
            >
              <PauseOutlined />
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure to active all selected keywords?"
            disabled={!selectedRowKeys.length}
            onConfirm={bulkAction(runVariation)}
          >
            <Button
              style={{
                color: 'rgb(82, 196, 26)',
                borderColor: 'rgb(82, 196, 26)',
              }}
            >
              <CaretRightOutlined />
            </Button>
          </Popconfirm>
          {canDeleteKeywordsForRMF && (
            <Popconfirm
              title="Are you sure you want to delete the selected keywords? This action is irreversible, and all keyword history and analytics will be lost"
              disabled={!selectedRowKeys.length}
              onConfirm={() => {
                reload();
              }}
            >
              <Button
                style={{
                  color: 'rgb(248, 165, 27)',
                  borderColor: 'rgb(248, 165, 27)',
                }}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )}
        </>
      )}
      <DashboardFilter searchVariation rankColumns activeFilter />
      {campaign && campaign.id && campaign.providerType === 'GOOGLE' && (
        <div>
          <NewKeywordButton campaignId={campaign.id} adGroupId={adGroupId} />
        </div>
      )}
    </HeaderStyle>
  );
};
