import React from 'react';

import IconContainer from './IconContainer';

export function Polygon(props) {
  return (
    <IconContainer
      {...props}
      style={{
        marginTop: '1px',
      }}
    >
      <svg width="100%" height="100%" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity={props.opacity ? props.opacity : '0.5'}
          d="M4.5 7L0.602886 0.25L8.39711 0.25L4.5 7Z"
          fill={props.color ? props.color : '#2e89e2db'}
        />
      </svg>
    </IconContainer>
  );
}
