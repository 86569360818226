import './CheckBoxes.css';

import React from 'react';

import { Link } from 'react-router-dom';

import { validateCookieConsent } from 'lib/cookieConsent';

import Checkbox from './Checkbox';

export function GdprCheckBox({
  cookieConsent,
  account,
  label = 'Add GDPR consent',
  hideCTA = false,
  handleClick,
  handleCTA,
}: {
  cookieConsent: boolean,
  account: accountT,
  label?: string,
  hideCTA?: boolean,
  handleClick: () => void,
  handleCTA?: () => void,
}) {
  const consentValid: boolean = validateCookieConsent(account.cookieConsent);

  return (
    <div className={`consent${consentValid ? '' : ' consent--disabled'}`}>
      <Checkbox
        checked={cookieConsent}
        handleClick={(_) => {
          consentValid && handleClick();
        }}
      />
      <label
        onClick={(_) => {
          consentValid && handleClick();
        }}
      >
        {label}
      </label>
      {!hideCTA && (
        <Link to={`/${account.id}/ab/settings/gdpr`} onClick={(_) => handleCTA && handleCTA()}>
          Settings
        </Link>
      )}
    </div>
  );
}
