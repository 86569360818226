import type { axiosParams } from './api';
import { apiCall, apiCallNoErrorHandler } from './api';

function verifyProvider(adsProvider: adsProviderT) {
  if (!adsProvider || adsProvider.providerType !== 'TIKTOK') {
    throw new Error('Unable to use provider as Tiktok provider', adsProvider);
  }
}

/**
 * Return tiktok available tools
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param params
 * @param endpoint
 * @returns {Promise<*>}
 */
export async function getTiktokTools(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  params,
  endpoint: string,
): Promise<{ device: any[] }> {
  verifyProvider(adsProvider);
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `ads-providers/tiktok/${adsProvider.id}/tools/${endpoint}`,
    params,
  });

  return res.data;
}

/**
 * getTiktokAllAudiences
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param params
 * @returns {Promise<*>}
 */
export async function getTiktokAllAudiences(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  params,
): Promise<{ audiences: any[] }> {
  verifyProvider(adsProvider);
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `ads-providers/tiktok/${adsProvider.id}/audiences`,
    params,
  });

  return res.data;
}

/**
 * getTiktokAllIdentities
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param params
 * @returns {Promise<*>}
 */
export async function getTiktokAllIdentities(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  params,
): Promise<{ audiences: any[] }> {
  verifyProvider(adsProvider);
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `ads-providers/tiktok/${adsProvider.id}/identities`,
    params,
  });

  return res.data;
}

/**
 * getTiktokAllVideos
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param params
 * @returns {Promise<*>}
 */
export async function getTiktokAllVideos(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  params,
): Promise<{ videos: any[] }> {
  verifyProvider(adsProvider);
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `ads-providers/tiktok/${adsProvider.id}/videos`,
    params,
  });

  return res.data;
}

/**
 * getTiktokAllImages
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param params
 * @returns {Promise<*>}
 */
export async function getTiktokAllImages(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  params,
): Promise<{ images: any[] }> {
  verifyProvider(adsProvider);
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `ads-providers/tiktok/${adsProvider.id}/images`,
    params,
  });

  return res.data;
}

export async function getTiktokAdPreview(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  params,
  creative,
): Promise<{ audiences: any[] }> {
  verifyProvider(adsProvider);
  // $FlowFixMe
  const res = await apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `ads-providers/tiktok/${adsProvider.id}/ad-preview`,
    params,
    data: { creative },
  });

  return res.data;
}

/**
 * Get Tiktok estimated read
 * @param localStorage
 * @param axios
 * @param adsProvider
 * @param adGroup
 * @returns {Promise<*>}
 */
export async function getTiktokEstimated(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  adsProvider: adsProviderT,
  adGroup: any,
): Promise<{ products: adsProducts }> {
  verifyProvider(adsProvider);
  const params = { accountId: adsProvider.accountId };
  const res = await apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `/ads-providers/tiktok/${adsProvider.id}/estimated-delivered?`,
    params,
    data: { adGroup },
  });

  return res.data;
}
