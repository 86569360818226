import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { GOOGLE_RMF, useFeatureFlag } from 'lib/configcat';

import { PlatformsSettings } from './PlatformsSettingsPage';
import Box from '../../../common/Box';
import { MenuItem } from '../../../layout/MenuItem';
import { SubMenu } from '../../../layout/SubMenu';
import Pixel from '../../settings/Pixels';
import { AdsProviderAccounts } from '../common/AdsProviderAccounts';
import { BiddingStrategyMetrics } from '../GoogleRMF/BiddingStrategyMetrics';
import { CalloutExtensions } from '../GoogleRMF/CalloutExtensionsPage';

const Style = styled.div`
  background: #f2f2f2;
  min-height: 100%;
`;

const StyledBox = styled(Box).withConfig({
  displayName: 'StyledBox',
})`
  margin: 1.5em 1em -1em 1em;
  padding: 1rem 2rem;
`;

export function AdsProviderSettingsPage({ match, account }) {
  const [showCalloutExtensions] = useFeatureFlag(GOOGLE_RMF);

  return (
    <>
      <Style>
        <StyledBox>
          <SubMenu>
            <MenuItem label="Accounts" to={`${match.url}/accounts`} />
            <MenuItem label="Platforms" to={`${match.url}/platforms`} />
            {showCalloutExtensions && (
              <>
                <MenuItem label="Google Ads Callout Extensions" to={`${match.url}/callouts`} />
                <MenuItem label="Bidding Strategy Metrics" to={`${match.url}/biddingmetrics`} />
              </>
            )}
          </SubMenu>
        </StyledBox>

        <Switch>
          <Route path={`${match.url}/accounts`} render={(props) => <AdsProviderAccounts account={account} />} />
          <Route path={`${match.url}/platforms`} render={(props) => <PlatformsSettings account={account} />} />
          {showCalloutExtensions && (
            <>
              <Route path={`${match.url}/callouts`} render={(props) => <CalloutExtensions />} />
              <Route path={`${match.url}/biddingmetrics`} render={(props) => <BiddingStrategyMetrics />} />
            </>
          )}

          <Redirect to={`${match.url}/accounts`} />
        </Switch>
      </Style>
    </>
  );
}
