import React, { useEffect, useState } from 'react';

import { Select } from 'antd';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokTools } from 'lib/tiktokPlateformApi';

/**
 * Tiktok Languages selector
 * https://ads.tiktok.com/marketing_api/docs?id=1701890994478081
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function LanguagesSelector({ account, adsProvider, ...restProps }) {
  const [data, setData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const genericResponse = await getTiktokTools(
      localStorage,
      axios,
      adsProvider,
      { accountId: account.id },
      'language',
    );
    const d = genericResponse.languages.map((l) => ({
      value: l.code,
      label: l.name,
    }));

    setData(d);
  }
  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(adsProvider)]);

  return (
    <ExclusiveInlineLoadingContainer>
      <Select
        {...restProps}
        mode="multiple"
        size="large"
        style={{ width: 329 }}
        placeholder="Select language"
        allowClear
        options={data}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
