import React, { type Node } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const BaseContainer = styled.div`
  display: flex;
  column-gap: 0.25rem;
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 1rem;
`;

const IconContainer = styled.span``;

const NeutralContainer = styled(BaseContainer)`
  background-color: #f2f4f7;
  color: #344054;
`;

const PositiveContainer = styled(BaseContainer)`
  background-color: #ecfdf3;
  color: #027a48;
  ${IconContainer} {
    color: #12b76a;
  }
`;

const NegativeContainer = styled(BaseContainer)`
  background-color: #fef3f2;
  color: #b42318;
  ${IconContainer} {
    color: #f04438;
  }
`;

const defaultComparisonFormatter = (comparison: number) => (Math.round(comparison * 10) / 10).toString();

type propsT = {
  value?: null | number,
  previousValue?: null | number,
  /** A value comparison increase is considered negative and a decrease is positive */
  inverse?: boolean,
  suffix?: string,
  comparisonFormatter?: (number) => string,
};
export const ComparisonBadge = ({
  value,
  previousValue,
  inverse = false,
  suffix = '%',
  comparisonFormatter = defaultComparisonFormatter,
}: propsT): Node => {
  if (previousValue == null || value == null || value - previousValue === 0) {
    return (
      <NeutralContainer>
        <span>-</span>
      </NeutralContainer>
    );
  }

  const comparison = value - previousValue;

  const isPositive = (comparison > 0 && !inverse) || (comparison < 0 && inverse);
  const Container = isPositive ? PositiveContainer : NegativeContainer;
  const Icon = comparison > 0 ? ArrowUpOutlined : ArrowDownOutlined;

  return (
    <Container>
      <IconContainer>
        <Icon />
      </IconContainer>
      {comparisonFormatter(Math.abs(comparison))}
      {suffix}
    </Container>
  );
};
