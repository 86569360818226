import React, { useContext, useState, type Node } from 'react';

import moment from 'moment';
import styled from 'styled-components';

import DismissableInfo from 'components/common/DismissableInfo';
import { SmallCompactPlaceholder } from 'components/common/Placeholders';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useGetAdGroupsByCampaignIdQuery } from 'stores/adGroupsSlice';

import { AdGroupsDashboardHeader } from './AdGroupsDashboardHeader';
import { AdGroupsStats } from './AdGroupsStats';
import { AdGroupsTable } from './AdGroupsTable';
import { calculateStats } from '../../common/adsStats';

const Style = styled.div`
  .ant-table-column-sorters {
    width: 100%;
    justify-content: space-between;
  }
`;

type AdGroupsDashBoardProps = {
  account: accountT,
  campaignId: string,
  productProviderId: string,
  productASIN: string,
};

export const AdGroupsDashBoard = ({
  account,
  campaignId,
  productProviderId,
  productASIN,
}: AdGroupsDashBoardProps): Node => {
  const { ctx } = useContext(DashboardFilterContext);
  const [from, to] = ctx.adsReportsRange || [];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const {
    adGroups = [],
    stats = {},
    campaign = {},
    product = {},
    isLoading,
    refetch,
  } = useGetAdGroupsByCampaignIdQuery(
    {
      campaignId,
      from: from && moment(from).toISOString(),
      to: to && moment(to).toISOString(),
    },
    {
      skip: !campaignId,
      selectFromResult: ({ data = {}, ...res }) => {
        const adGroupsResults = data.adgroups?.map((x) => ({ ...x, ...x.selectedAdsReports?.data }));

        return {
          ...res,
          adGroups: adGroupsResults,
          stats: calculateStats(adGroupsResults),
          campaign: data.campaign,
          product: data.product,
        };
      },
    },
  );

  return (
    <Style>
      <DismissableInfo
        style={{ margin: '20px 0' }}
        name="shopper-privacy"
        learnMore="https://www.carbon6.io/pixelme-help/why-am-i-seeing-less-purchases-and-add-to-carts-on-my-keywords-than-my-campaign-2"
      >
        <p>
          The data displayed here is limited by Amazon's shopper privacy policy, which only shows purchase data for
          keywords with more than 10 clicks.{' '}
        </p>
        <p>Keyword with less than 10 clicks will be display as zero until the click threshold is reached.</p>
      </DismissableInfo>
      <AdGroupsDashboardHeader
        account={account}
        campaign={campaign}
        product={product}
        selectedRowKeys={selectedRowKeys}
        reload={refetch}
      />
      {isLoading ? (
        <SmallCompactPlaceholder text="" />
      ) : (
        <>
          <AdGroupsStats account={account} stats={stats} productProviderId={productProviderId} />
          <AdGroupsTable
            account={account}
            product={product}
            productASIN={productASIN}
            adGroups={adGroups}
            setSelectedRowKeys={setSelectedRowKeys}
            productProviderId={productProviderId}
          />
        </>
      )}
    </Style>
  );
};
