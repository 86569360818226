import React from 'react';

import { useIntl } from 'react-intl';

import { BarToolTip } from '../BarToolTip';

const PageBuilder = () => {
  const { messages } = useIntl();

  return (
    <BarToolTip title={messages.locales.headers.oneLink}>
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="bar__icon"
      >
        <g>
          <rect width="33" height="33" rx="4" className="icon-background" />

          <path
            className="icon-main-fill"
            d="M21.1904 7H12.8097C9.60621 7 7 9.60621 7 12.8097V21.1903C7 24.3938 9.60621 27 12.8097 27H21.1903C24.3938 27 27 24.3938 27 21.1904V12.8097C27 9.60621 24.3938 7 21.1904 7ZM25.4375 21.1903C25.4375 23.5322 23.5322 25.4375 21.1904 25.4375H12.8097C10.4678 25.4375 8.5625 23.5322 8.5625 21.1904V12.8097C8.5625 10.4678 10.4678 8.5625 12.8097 8.5625H21.1903C23.5322 8.5625 25.4375 10.4678 25.4375 12.8097V21.1903Z"
            fill="white"
          />
          <path
            className="icon-main-fill"
            d="M17 11.6094C14.0276 11.6094 11.6094 14.0276 11.6094 17C11.6094 19.9724 14.0276 22.3906 17 22.3906C19.9724 22.3906 22.3906 19.9724 22.3906 17C22.3906 14.0276 19.9724 11.6094 17 11.6094ZM17 20.8281C14.8892 20.8281 13.1719 19.1108 13.1719 17C13.1719 14.8892 14.8892 13.1719 17 13.1719C19.1108 13.1719 20.8281 14.8892 20.8281 17C20.8281 19.1108 19.1108 20.8281 17 20.8281Z"
            fill="white"
          />
          <path
            className="icon-main-fill"
            d="M22.4688 12.3125C22.9002 12.3125 23.25 11.9627 23.25 11.5312C23.25 11.0998 22.9002 10.75 22.4688 10.75C22.0373 10.75 21.6875 11.0998 21.6875 11.5312C21.6875 11.9627 22.0373 12.3125 22.4688 12.3125Z"
            fill="white"
          />
        </g>
      </svg>
    </BarToolTip>
  );
};

export default PageBuilder;
