import { type Node } from 'react';

import { Button } from 'antd';
import qs from 'query-string';
import styled from 'styled-components';

import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { BOOK_CALL_URLS } from './constants';

const StyledButton = styled(Button)`
  font-size: 0.875rem;
  font-weight: 500;
`;

const sanitize = (arr, separator = ' ') =>
  arr
    .map((x) => x?.trim())
    .filter(Boolean)
    .join(separator);

type propsT = {
  children?: Node,
  contactInformation?: {
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: {
      countryCode?: string,
      areaCode?: string,
      number?: string,
    },
    notes?: string,
  },
  asins?: string[],
  auditId?: string,
  url?: string,
};
export const BookCallButton = ({
  children = getIntlStrings('asinAudit.bookCall'),
  contactInformation = {},
  asins = [],
  auditId,
  url = BOOK_CALL_URLS.DEFAULT,
  ...props
}: propsT): Node => {
  const { firstName, lastName, email, phone, notes } = contactInformation;
  const countryCode = phone?.countryCode ? `+${phone?.countryCode}` : '';
  const auditUrlNote = auditId ? `(Audit Results: ${window.env.REACT_APP_BASE_URL}/audit/results?id=${auditId})` : '';
  const asinsNote = asins.length ? `(ASINs to Review: ${asins.join(', ')})` : '';

  let query;

  if (url === BOOK_CALL_URLS.PROSPER) {
    query = {
      'name[first]': firstName?.trim(),
      'name[last]': lastName?.trim(),
      email,
      'phone': sanitize([phone?.areaCode, phone?.number]),
      'note': sanitize([notes, auditUrlNote, asinsNote], '\n'),
    };
  } else {
    query = {
      'name': sanitize([firstName, lastName]),
      email,
      'phone': sanitize([countryCode, phone?.areaCode, phone?.number]),
      'ASINs to Review': asins.join(','),
      'note': sanitize([notes, auditUrlNote], '\n'),
    };
  }

  const bookingUrl = qs.stringifyUrl({ url, query }, { skipEmptyString: true });

  return (
    <StyledButton type="primary" size="large" href={bookingUrl} target="_blank" rel="noreferrer" {...props}>
      {children}
    </StyledButton>
  );
};
