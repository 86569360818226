import { createSelector } from '@reduxjs/toolkit';
import produce from 'immer';
import * as _ from 'lodash';

import { Actions } from '../actions';

const INTERNAL_USER_DOMAIN = '@carbon6.io';

type stateT = {
  +logged: boolean,
  user?: userT,
  impersonated: boolean,
};

// ACTIONS
type actionT = { type: 'SIGN_IN', user: userT } | { type: 'SIGN_OUT' } | { type: 'UPDATE_USER', user: userT };

export function signIn(user: userT): actionT {
  return {
    type: 'SIGN_IN',
    user,
  };
}

export function signOut(): actionT {
  return {
    type: 'SIGN_OUT',
  };
}

export function updateUser(user: userT): actionT {
  return {
    type: 'UPDATE_USER',
    user,
  };
}
// STORE
const initalUserState: stateT = {
  logged: false,
  signup: {
    form: {
      fullName: '',
      emailAddress: '',
      company: '',
      password: '',
      code: '',
      captcha: '',
    },
  },
};

export function user(state: stateT = initalUserState, action: actionT): stateT {
  switch (action.type) {
    case 'SIGN_IN':
      const impersonated = action.user.impersonated === true;

      return { ...state, user: action.user, logged: true, impersonated };
    case 'SIGN_OUT':
      return initalUserState;
    case 'UPDATE_USER':
      return { ...state, user: action.user };
    case Actions.front.signup.form.update.request().type:
      const { name, value } = action.payload;

      return produce(state, (draft) => {
        draft.signup.form[name] = value;
      });
    default:
      return state;
  }
}

// Selectors
const selectRoot = (state) => state.user;

export const selectUser = createSelector(selectRoot, (root) => root.user);

export const selectIsInternalUser = createSelector(selectUser, (user) =>
  user?.email.toLowerCase().endsWith(INTERNAL_USER_DOMAIN),
);
