import React, { useEffect, useState } from 'react';

import { Form, Select } from 'antd';
import styled from 'styled-components';

import { LimitedButton } from 'components/common/antd/LimitedButton';
import { Amazon } from 'components/common/icons/Amazon';
import { Lock } from 'components/common/illustrations/Lock';
import { SmallPlaceholder } from 'components/common/Placeholders';
import { useLoading } from 'hooks/useLoading';
import { useUpgrade } from 'hooks/useUpgrade';
import axios from 'lib/axios.factory';
import { s } from 'lib/safe';
import { useGetAllProductsQuery, useGetProductQuery } from 'stores/productsSlice';

import NewProductModal, { TYPE_PRODUCT } from './NewProductModal';

const Style = styled.div``;

function ProductSelectorContent(props: {
  products: [],
  value: any,
  onChange: Function,
  loading: boolean,
  disabled: boolean,
}) {
  const { showUpgrade } = useUpgrade('product-limit', ' to get attribution data on this product (limit reached)');

  return (
    <Select
      size="large"
      disabled={props.disabled}
      loading={props.loading}
      placeholder="Select a product"
      defaultValue={s(props.value).id}
      onChange={(id) => {
        const selected = props.products.find((p) => p.id === id);

        props.onChange(selected);
      }}
    >
      {props.products &&
        props.products.map((product) => (
          <Select.Option
            key={product.id}
            value={product.id}
            disabled={product.outOfLimits}
            onMouseHover={product.outOfLimits ? showUpgrade : undefined}
            onMouseLeave={product.outOfLimits ? showUpgrade : undefined}
          >
            <div
              style={{
                display: 'flex',
                gap: '1em',
                alignItems: 'center',
                height: '38px',
              }}
            >
              <div
                style={{
                  flex: '0 0 20px',
                  position: 'relative',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {product.outOfLimits && <Lock size={20} />}
                {!product.outOfLimits && product.type === TYPE_PRODUCT && (
                  <img style={{ height: '70%' }} alt="product" src={product.productImageUrl} />
                )}
                {!product.outOfLimits && product.type !== TYPE_PRODUCT && <Amazon />}
              </div>
              <span
                style={{
                  flex: '1 1',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                  overflow: 'hidden',
                }}
              >
                {product.name}
              </span>
            </div>
          </Select.Option>
        ))}
    </Select>
  );
}

export const ProductSelector = (props: { account: accountT, productId?: string, label?: string }) => {
  const [showAddNewProduct, setShowAddNewProduct] = useState(false);
  const { isLoading: allProductsIsLoading, data: allProductsData } = useGetAllProductsQuery(
    {
      accountId: props.account.id,
      rangeId: 'ALL',
    },
    {
      skip: !!props.productId,
    },
  );
  const { isLoading: productIsLoading, data: productData } = useGetProductQuery(
    {
      accountId: props.account.id,
      productId: props.productId,
    },
    {
      skip: !props.productId,
    },
  );

  if (allProductsIsLoading || productIsLoading) {
    return (
      <Style>
        <SmallPlaceholder text="Loading" />
      </Style>
    );
  }

  const products = allProductsData?.products || [productData.product];
  const selectedProduct = products.find((p) => p.id === props.productId);

  return (
    <Style>
      <Form.Item
        required
        name="product"
        rules={[{ required: true, message: 'Please select a product' }]}
        label={props.label || 'Select an Amazon product or a Storefront:'}
        initialValue={selectedProduct}
        style={{ marginBottom: '5px' }}
        trigger="onChange"
      >
        <ProductSelectorContent products={products} disabled={!!selectedProduct} />
      </Form.Item>
      {!selectedProduct && (
        <LimitedButton
          style={{
            fontSize: '14px',
            marginBottom: '26px',
            paddingLeft: 0,
            textAlign: 'left',
          }}
          type="link"
          limitationTest={(limitations, usage) => true}
          onClick={() => setShowAddNewProduct(true)}
        >
          + Add a new product
        </LimitedButton>
      )}
      {showAddNewProduct && (
        <NewProductModal
          account={props.account}
          visible={showAddNewProduct}
          onFinish={() => {
            setShowAddNewProduct(false);
          }}
        />
      )}
    </Style>
  );
};
