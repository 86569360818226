import React from 'react';

import { useIntl } from 'react-intl';

import { Me } from './Me';
import Box from '../../../common/Box';

export function Owner({ owner, user }: { owner: memberT, user: memberT }) {
  const { messages } = useIntl();

  return (
    <Box className="team-members__owner" header={messages.locales.teamMembers.owner}>
      <div className="team-members__owner__row">
        <div>
          {owner.fullname}
          {owner.email === user.email && <Me />}
        </div>
        <div className="team-members__owner__email">({owner.email})</div>
      </div>
    </Box>
  );
}
