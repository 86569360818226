import { Statistic } from 'antd';
import styled from 'styled-components';

import { getImpressionColors } from './constants';

const Style = styled.div`
  width: 60px;
  height: 26px;
  border-radius: 20px;
  display: inline-block;

  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-statistic-content-suffix {
    font-size: 13px;
    margin-left: 0;
  }

  ${(props) => {
    const { backgroundColor, color } = getImpressionColors(props.$impression);

    return `
      background-color: ${backgroundColor};
      .ant-statistic-content {
        color: ${color};
      }
    `;
  }}
`;

type propsT = {
  score: number,
  impression?: string,
  maxScore?: number,
};
export const ScoreBadge = ({ score, impression, maxScore, ...props }: propsT) => (
  <Style $impression={impression}>
    <Statistic
      value={score}
      precision={1}
      {...(maxScore && score > maxScore && { value: maxScore, suffix: '+' })}
      {...props}
    />
  </Style>
);
