import React from 'react';

import styled from 'styled-components';

const Style = styled.span`
  color: var(--text-muted);
  font-size: 12px;
`;

export function InlineMutedText({ children }) {
  return <Style>{children}</Style>;
}
