import React, { useEffect } from 'react';

import './AmazonAttributionContent.css';

import { Redirect, Route, Switch } from 'react-router-dom';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';
import { useLazyGetProductProvidersQuery } from 'stores/productProvidersSlice';

import { AmazonFAQ } from './AmazonFAQ';
import { AmazonLoginPage } from './AmazonLoginPage';
import { AmazonOnBoardingPage } from './AmazonOnBoardingPage';
import { AmazonProductPage } from './AmazonProductPage';
import { AmazonReportTable } from './AmazonReportTable';
import { ProductProviderAccounts } from './ProductProviderAccounts.jsx';
import { getProductProviderSPAStatus } from '../../../lib/productsPlateformApi';
import { SmallPlaceholder } from '../../common/Placeholders';
import message from 'antd/es/message';

export function AmazonAttributionContent({ account, match }: { account: accountT, match: Match }) {
  const [state, updateState] = useImmer({
    providers: [],
    loading: true,
    loaded: false,
    spaStep: null,
  });
  const [getProductProviders] = useLazyGetProductProvidersQuery();

  const load = async () => {
    updateState((draft) => {
      draft.loaded = false;
      draft.loading = true;
    });

    if (window.location.href.includes('spa=finished')) {
      const spa = await getProductProviderSPAStatus(localStorage, axios, account.productProviders[0].id, {
        accountId: account.id,
      });

      if (spa.connectedAt !== null && spa.errorAt == null) {
        updateState((draft) => {
          draft.spaStep = 'ok';
        });
      } else {
        updateState((draft) => {
          draft.spaStep = 'error';
        });
      }
    }

    try {
      const { data } = await getProductProviders({ accountId: account.id }, true);

      updateState((draft) => {
        draft.providers = data.providers;
        draft.loaded = true;
      });
    } catch (e) {
      console.error(e);
      message.error('An error occurred while loading Amazon data');
    }
  };

  useEffect(() => {
    load().finally(
      updateState((draft) => {
        draft.loading = false;
      }),
    );
  }, []);

  if (!state.loaded || state.loading) {
    return <SmallPlaceholder text="We are loading Amazon Data" />;
  }

  if (state.providers && state.providers.length > 0) {
    return (
      <div className="content content--no-padding" style={{ display: 'block' }}>
        <Switch>
          <Route path={`${match.url}/attribution`} render={() => <AmazonOnBoardingPage account={account} />} />
          <Route path={`${match.url}/faq`} render={() => <AmazonFAQ />} />
          <Route
            path={`${match.url}/account`}
            render={() => (
              <ProductProviderAccounts providers={state.providers} account={account} spaFlowStep={state.spaStep} />
            )}
          />
          <Route
            path={`${match.url}/products`}
            render={() => <AmazonProductPage account={account} productProviders={state.providers} />}
          />
          <Route
            path={`${match.url}/reports/:productProviderId`}
            render={(props) => <AmazonReportTable match={props.match} accountId={account.id} />}
          />
          <Redirect to={`${match.url}/attribution`} />
        </Switch>
      </div>
    );
  } else {
    return (
      <div className="content" style={{ display: 'block', backgroundColor: 'white' }}>
        <AmazonLoginPage accountId={account.id} account={account} />
      </div>
    );
  }
}
