import React, { useEffect } from 'react';

import { Spinner } from '@blueprintjs/core';
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';

import { PageBlockEditor } from './PageBlockEditor';
import { trackedEvents } from '../../../../config/trackedEvents.config';
import { track } from '../../../../lib/analytics';
import { Plus } from '../../../common/icons';

const Style = styled.div`
  ul {
    padding: 0;
  }

  background: white;
  padding: 1em;
  grid-area: builder;
  overflow: auto;
`;

const Sortable = styled.li`
  display: block;
  align-items: center;
  list-style-type: none;
  padding: 0.5rem 0.8rem;
`;

const SortableItem = SortableElement(({ account, value, handleDelete, handleChange }) => (
  <Sortable>
    <PageBlockEditor
      account={account}
      key={value.id}
      block={value}
      handleDelete={handleDelete}
      handleChange={handleChange}
    ></PageBlockEditor>
  </Sortable>
));

const BlocksList = SortableContainer(({ account, blocks, handleDelete, handleChange }) => (
  <div style={{ margin: 'auto', maxWidth: 'fit-content' }}>
    <ul>
      {blocks.map((value, index) => (
        <SortableItem
          account={account}
          key={`item-${index}`}
          index={index}
          value={value}
          handleDelete={handleDelete}
          handleChange={handleChange}
        />
      ))}
    </ul>
  </div>
));

export function PageBlocksEditor({ account, updateState, savePage, state }) {
  function handleDelete(block) {
    track(trackedEvents.deleteButton, block);
    updateState((draft) => {
      _.remove(draft.page.blocks, {
        id: block.id,
      });
    });
  }

  function handleChange(block) {
    updateState((draft) => {
      draft.page.blocks = state.page.blocks.map((item) => (item.id === block.id ? block : item));
    });
  }

  useEffect(() => {
    savePage(state.page);
  }, [JSON.stringify(state.page)]);

  if (!state.page) {
    return <Spinner />;
  }

  return (
    <Style>
      <BlocksList
        account={account}
        distance={10}
        onSortEnd={({ oldIndex, newIndex }) => {
          updateState((draft) => {
            const tmp = state.page.blocks[oldIndex];

            draft.page.blocks[oldIndex] = state.page.blocks[newIndex];
            draft.page.blocks[newIndex] = tmp;
          });
        }}
        axis="y"
        lockAxis="y"
        useDragHandle={true}
        handleDelete={handleDelete}
        handleChange={handleChange}
        blocks={state.page.blocks}
      />
      <div style={{ width: '100%', textAlign: 'center' }}>
        <AddBlockButton
          onClick={() => {
            track(trackedEvents.addButton);
            const id = ObjectID.generate();

            updateState((draft) => {
              draft.page.blocks.push({
                id,
                title: '',
                url: '',
              });
            });
          }}
        />
      </div>
    </Style>
  );
}

const AddBlockButtonContainer = styled.button.withConfig({
  displayName: 'AddBlockButtonContainer',
})`
  background: var(--white);
  border: 1px solid var(--pixelme-color);
  color: var(--pixelme-color);
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  width: 147px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  &:hover {
    cursor: pointer;
    background-color: rgb(192, 220, 246);
  }
`;

function AddBlockButton({ onClick }) {
  return (
    <AddBlockButtonContainer onClick={onClick}>
      <Plus style={{ width: '1rem', marginRight: '0.5rem' }} /> Add a new link
    </AddBlockButtonContainer>
  );
}
