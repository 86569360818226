export const facebookPlacements = [
  {
    name: 'Desktop feed',
    id: 'desktopFeed',
    value: {
      devicePlatforms: ['desktop'],
      publisherPlatforms: ['facebook'],
      facebookPositions: ['feed'],
    },
  },
  {
    name: 'Mobile feed',
    id: 'mobileFeed',
    value: {
      devicePlatforms: ['mobile'],
      publisherPlatforms: ['facebook'],
      facebookPositions: ['feed'],
    },
  },
  {
    name: 'Audience Network (mobile)',
    id: 'audienceNetworkMobile',
    value: {
      devicePlatforms: ['mobile'],
      publisherPlatforms: ['audienceNetwork'],
      audienceNetworkPositions: ['classic'],
    },
  },
  {
    name: 'Instagram',
    id: 'instagram',
    value: {
      devicePlatforms: ['mobile'],
      publisherPlatforms: ['instagram'],
      instagram_positions: ['stream'],
    },
  },
  {
    name: 'Right column (desktop)',
    id: 'rightColumnDesktop',
    value: {
      devicePlatforms: ['desktop'],
      publisherPlatforms: ['facebook'],
      facebookPositions: ['rightHandColumn'],
    },
  },
  {
    name: 'Marketplace (mobile)',
    id: 'marketplaceMobile',
    value: {
      devicePlatforms: ['mobile'],
      publisherPlatforms: ['facebook'],
      facebookPositions: ['marketplace'],
    },
  },
  {
    name: 'Instant Article (mobile)',
    id: 'instantArticleMobile',
    value: {
      devicePlatforms: ['mobile'],
      publisherPlatforms: ['facebook'],
      facebookPositions: ['instantArticle'],
    },
  },
];
