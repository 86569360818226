import { useMemo, useState } from 'react';

import _ from 'lodash';

import { MAX_METRICS_SELECTIONS } from '../constants';

type propsT = {
  name: string,
  maxSelections?: number,
};

/**
 * Manage selections by their statuses (selected/active/disabled), max selections,
 * and event handlers.
 * Call `initialize` once all required data is available.
 */
export const useSelectionManager = ({ name, maxSelections = MAX_METRICS_SELECTIONS }: propsT) => {
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [actives, setActives] = useState([]);
  const [series, setSeries] = useState([]);

  const seriesMap = useMemo(() => _.keyBy(series, 'name'), [series.length]);

  const result = useMemo(
    () => ({
      initialize: ({ data, series, initialSelections }) => {
        setData(data);
        setSeries(series);

        if (initialSelections?.length) {
          setSelected(initialSelections);
          setActives(initialSelections);

          const initialSeriesMap = _.keyBy(series, 'name');

          initialSelections.forEach((name) => {
            initialSeriesMap[name]?.setVisible?.(true);
          });
        }
      },
      data: data.map((item) => ({
        ...item,
        id: item[name],
        selected: selected.includes(item[name]),
        disabled: selected.length >= maxSelections && !selected.includes(item[name]),
        active: actives.includes(item[name]),
        color: seriesMap[item[name]]?.color,
      })),
      selected,
      actives,
      onSelect: (newSelected: string[]) => {
        if (newSelected.length <= maxSelections) {
          // Determine if a select or deselect occurred
          if (newSelected.length > selected.length) {
            const latest = newSelected[newSelected.length - 1];

            setActives([...actives, latest]);

            seriesMap[latest]?.setVisible?.(true);
          } else {
            const deleted = actives.find((x) => !newSelected.includes(x));

            setActives(actives.filter((x) => x !== deleted));

            seriesMap[deleted]?.setVisible?.(false);
          }

          setSelected(newSelected);
        }
      },
      onToggle: (id: string) => {
        const filtered = actives.filter((x) => x !== id);

        setActives(filtered.length === actives.length ? [...actives, id] : filtered);

        if (seriesMap[id]) {
          seriesMap[id].setVisible();
        }
      },
      onDelete: (id: string) => {
        setSelected(selected.filter((x) => x !== id));
        setActives(actives.filter((x) => x !== id));

        if (seriesMap[id]) {
          seriesMap[id].setVisible(false);
        }
      },
    }),
    [data.length, selected.length, actives.length, series.length],
  );

  return result;
};
