import React from 'react';

import { DatePicker } from 'antd';
import moment from 'moment';

export function DateInputAsDate(props) {
  function onChange(e) {
    if (e) {
      props.onChange(e.toISOString());
    } else {
      props.onChange(undefined);
    }
  }

  let defaultValue = undefined;

  if (props.defaultValue) {
    defaultValue = moment(props.defaultValue);
  }

  let value = undefined;

  if (props.value) {
    value = moment(props.value);
  }

  return <DatePicker size="large" {...props} onChange={onChange} defaultValue={defaultValue} value={value} />;
}
