import React from 'react';

import { Button } from 'antd';

export function AddANewButton(props) {
  return (
    <Button
      {...props}
      style={{
        fontSize: '14px',
        marginBottom: '26px',
        paddingLeft: 0,
        textAlign: 'left',
        ...(props.style || {}),
      }}
      type="link"
    >
      {props.children}
    </Button>
  );
}
