import React, { Fragment } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Collapse, Modal } from 'antd';
import Button from 'antd/lib/button';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

import { API } from 'lib/api';
import { getMessageFromAxiosError } from 'lib/error';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';
import { addNotice } from 'stores/notices';

import { Actions } from '../actions';

const { confirm } = Modal;

const Style = styled.div`
  .ant-collapse-content-box {
    h1 {
      font-size: 14px;
      font-weight: bold;
    }

    p {
      font-size: 10px;
    }
  }
`;

export default (store: any) => (next: any) => async (action: any) => {
  if (action.payload && action.payload) {
    const { error, url, method } = action.payload;

    let notice: Object;

    if (error && error.response) {
      if (error.response.status === 403) {
        window.location.href = '/';
      }

      if (error.response.status === 401 && !url.includes('/authent') && !url.includes('user/validate')) {
        window.location.reload();
      }

      if (error.response.status === 503) {
        notice = {
          duration: 10000,
          message: getIntlStrings('error.unavailable'),
          type: 'error',
        };
      }

      const e = getMessageFromAxiosError(error);
      const message = e.errorMessage;
      const { detailMessage } = e;
      const { requestId } = e;
      const { context } = e;

      console.log('New Error, contact the support with the followings information:');
      console.log(message);
      console.log(detailMessage);
      console.log(context);

      if (!notice) {
        switch (action.type) {
          case Actions.api.error.type:
            switch (url + method) {
              case API.STATS.campaign.url + API.STATS.campaign.method:
              case API.STATS.usage.url + API.STATS.usage.method:
                notice = {
                  duration: 10000,
                  message: getIntlStrings('error.unableToGetStats'),
                  type: 'error',
                };
                break;
              case API.PAGE.GET.url + API.PAGE.GET.method:
                // ignore this error
                break;
              default:
                if (!detailMessage) {
                  notice = {
                    duration: 10000,
                    message,
                    type: 'error',
                  };
                } else {
                  confirm({
                    title: 'Oops, an error occurred',
                    icon: <ExclamationCircleOutlined />,
                    width: '100%',
                    content: (
                      <Style>
                        <br />
                        <Alert
                          message={
                            <Fragment>
                              <br />
                              There were an error.
                              <br />
                              Try again and if the error persists, provide <b>the following report</b> to our support
                              team.
                              <br />
                              <br />
                            </Fragment>
                          }
                          type="error"
                        ></Alert>
                        <Fragment>
                          <br />
                          {detailMessage && (
                            <Collapse ghost>
                              <Collapse.Panel
                                key={0}
                                header={
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'baseline',
                                      gap: '1em',
                                    }}
                                  >
                                    <ReactMarkdown style={{ marginBottom: 0 }}>{message}</ReactMarkdown>
                                    <CopyToClipboard
                                      text={`${requestId}: ${message} ${detailMessage} ${JSON.stringify(context)}`}
                                    >
                                      <Button size="small" style={{ marginLeft: '10px' }}>
                                        Copy
                                      </Button>
                                    </CopyToClipboard>
                                  </div>
                                }
                              >
                                <h5>{requestId}</h5>
                                <ReactMarkdown>{detailMessage}</ReactMarkdown>
                              </Collapse.Panel>
                            </Collapse>
                          )}
                          {!detailMessage && <ReactMarkdown>{message}</ReactMarkdown>}
                        </Fragment>
                      </Style>
                    ),
                  });
                }

                break;
            }
            break;
          default:
            break;
        }
      }
    }

    if (notice) {
      store.dispatch(addNotice(notice));
    }
  }

  next(action);
};
