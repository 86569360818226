import React from 'react';

import { Form, Select } from 'antd';
import styled from 'styled-components';

import { sd } from 'lib/safe';

import { getAllowedDomains } from '../../../../lib/adsProvider/common';
import { extraDomains } from '../common/extraDomains';

const RedSmallText = styled.small`
  color: red;
`;

export const DomainSelector = (props: {
  account: accountT,
  help?: string,
  disabledForPerformanceBooster?: boolean,
}) => {
  const domains = sd(getAllowedDomains([...extraDomains, ...props.account.domains]), []);

  let defaultDomain = '';

  for (let i = 0; i < domains.length; i++) {
    if (domains[i].ssl) {
      defaultDomain = domains[i].name;
      break;
    }
  }

  return (
    <Form.Item
      name="domain"
      initialValue={defaultDomain}
      dependencies={['pixelsIds', 'useDeepLinks']}
      label="Custom domain"
      help={props.help || 'Only used if there is a retargeting pixel or deep link is enabled'}
      style={{ marginBottom: 0 }}
      rules={[
        {
          required: true,
          message: 'Please select a domain',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            const pixels = getFieldValue('pixelsIds');
            const hasPixels = pixels !== undefined && pixels.length > 0;
            const usesDeepLinks = Boolean(getFieldValue('useDeepLinks'));

            if (!value && (hasPixels || usesDeepLinks)) {
              return Promise.reject(new Error('Domain is required with pixels or deep links'));
            }

            return Promise.resolve();
          },
        }),
      ]}
    >
      <Select size="large" placeholder="Select a custom domain" disabled={props.disabledForPerformanceBooster}>
        {domains.map(({ name, ssl }) => {
          const disabled = !ssl;

          return (
            <Select.Option key={name} value={name} disabled={disabled}>
              {name}{' '}
              {disabled && (
                <a href={`/${props.account.id}/ab/settings/domains`} target="_blank" rel="noreferrer">
                  <RedSmallText>Please Activate SSL for this domain</RedSmallText>
                </a>
              )}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
