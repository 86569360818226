import React from 'react';

import _ from 'lodash';
import styled from 'styled-components';

const Style = styled.div`
  min-width: 300px;
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 12px;
  min-width: 60px;
  color: #595959;
  margin-right: 15px;
`;

const Paragraph = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 6px;
`;
const Value = styled.div`
  display: inline-block;
  font-weight: normal;
  font-size: 11px;
  color: #595959 !important;
  white-space: pre-wrap;
`;

export function FacebookAudiencePreview(props: { audience: any }) {
  return (
    <Style>
      <Paragraph>
        <Title>Location:</Title>
        <Value>{(_.get(props.audience, 'geoLocations') || []).map((g) => g.name).join(', ') || 'All'}</Value>
      </Paragraph>
      <Paragraph>
        <Title>Age:</Title>
        <Value>
          {_.get(props.audience, 'ageMin')} - {_.get(props.audience, 'ageMax')}
        </Value>
      </Paragraph>
      <Paragraph>
        <Title>Language:</Title>
        <Value>{(_.get(props.audience, 'languages') || []).map((l) => l.name).join(', ') || 'All'}</Value>
      </Paragraph>
      <Paragraph>
        <Title>Included:</Title>
        <Value>
          {(_.get(props.audience, 'includedDetailedTargets') || []).map((l) => l.split('|')[2]).join(', ') || 'All'}
        </Value>
      </Paragraph>
      <Paragraph>
        <Title>Excluded:</Title>
        <Value>
          {(_.get(props.audience, 'excludedDetailedTargets') || []).map((l) => l.split('|')[2]).join(', ') || 'None'}
        </Value>
      </Paragraph>

      {/* <Paragraph>
        <Title>
            Exclude:
        </Title>
        <Value>
            {(_.get(props.audience, "exclusions.educationStatuses", [])).map(l => JSON.stringify(l)).join(", ")}
        </Value>
    </Paragraph>
    <Paragraph>
        <Title>
            People who match:
        </Title>
        <Value>
            {(_.get(props.audience, "flexibleSpec", [])).map(l => JSON.stringify(l)).join(", ")}
        </Value>
    </Paragraph>*/}
    </Style>
  );
}
