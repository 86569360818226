import type { axiosParams } from './api';
import { apiCall, apiCallNoErrorHandler } from './api';

/**
 * Return true if the profile is already processed
 * @returns {boolean}
 * @param productProvider
 */
export function alreadyProcessed(productProvider: productProviderT) {
  return productProvider.lastProcessedAt !== '0001-01-01T00:00:00Z';
}

/**
 * Return true if it's a new profile
 * @returns {boolean}
 * @param productProvider
 */
export function neverProcessed(productProvider: productProviderT) {
  return productProvider.lastProcessedAt === '0001-01-01T00:00:00Z';
}

export function getCurrencyCode(productProviders: productProviderT[]) {
  if (productProviders && productProviders.length > 0 && productProviders[0]) {
    return productProviders[0].currencyCode;
  } else {
    return 'USD';
  }
}

/**
 * Return active profiles
 * @returns {unknown[]}
 * @param productProviders
 */
export function getActivesAmazonProfiles(productProviders: productProvider[]): productProvider[] {
  return productProviders;
}

export function hasProductProvider(productProviders: productProvider[]): boolean {
  return getProductProvider(productProviders) !== null;
}

export function getProductProvider(productProviders: productProvider[]): ?productProvider {
  if (productProviders.length <= 0) {
    return null;
  }

  return productProviders[0];
}

/**
 * Return true if active profiles
 * @param productProviders
 * @returns {unknown[]}
 */
export function hasActiveAmazonProfile(productProviders: productProvider[]) {
  return getActivesAmazonProfiles(productProviders).length > 0;
}

export function getAmazonProfiles(account: accountT): productProvider[] {
  if (account && account.productProviders && account.productProviders) {
    return account.productProviders;
  } else {
    return [];
  }
}
