import React from 'react';

import { useHistory } from 'react-router-dom';

import { AddANewButton } from './AddANewButton';

export function AddPixelButton({ accountId }) {
  const history = useHistory();

  return (
    <AddANewButton onClick={() => history.push(`/${accountId}/ab/settings/pixels`)}>+ Add a new pixel</AddANewButton>
  );
}
