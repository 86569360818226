import Account from './api/account.middleware';
import abCampaign from './api/audienceBuilder/campaign.middleware';
import abRedirect from './api/audienceBuilder/redirect.middleware';
import Domain from './api/domain.middleware';
import Error from './error.middleware';
import Front from './front.middleware';
import Notice from './notice.middleware';
import Track from './track.middleware';

export const middlewares = [Error, Notice, Front, Track, Account, Domain, abRedirect, abCampaign];
