import React, { type Node } from 'react';

import { Tooltip } from 'antd';
import styled from 'styled-components';

import { LegendItemSymbol } from './LegendItemSymbol';
import { TOOLTIPS } from '../constants';

const ButtonContainer = styled.button`
  padding: 0.25rem;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

const IconContainer = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.75rem;
  margin-left: 0.25rem;
  ${(props) => {
    if (props.$disabled) {
      return 'color: #d9d9d9;';
    }

    if (props.$inactive) {
      return 'color: #828282;';
    }
  }}
`;

export const MetricsToggle = ({
  className,
  selected = false,
  disabled = false,
  label,
  color,
  onSelect,
}: {
  className?: string,
  selected?: boolean,
  disabled?: boolean,
  label: string,
  color?: string,
  onSelect: (string) => void,
}): Node => (
  <Tooltip placement="right" title={disabled ? TOOLTIPS.METRICS_MAX : ''}>
    <div>
      {/* Need this div for tooltip to apply to since tooltip doesn't show on disabled buttons */}
      <ButtonContainer className={className} onClick={() => onSelect(label)} disabled={disabled}>
        <IconContainer>
          <LegendItemSymbol color={color} inactive={!selected} disabled={disabled} />
        </IconContainer>
        <Label $inactive={!selected} $disabled={disabled}>
          {label}
        </Label>
      </ButtonContainer>
    </div>
  </Tooltip>
);
