import React from 'react';

import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';

import { SecondBullet } from '../icons/SecondBullet';

export const AbstractSortableDropDownBullet = styled(SecondBullet).withConfig({
  displayName: 'DropDownValuesBullet',
})`
  display: flex;
  align-items: center;
  align-self: center;
  cursor: move;
  height: 10px;
  width: 10px;
  opacity: 0.2;
  margin-right: 5px;
  flex-shrink: 1;
`;

export const SortableDropDownBullet = SortableHandle(({ className }) => (
  <AbstractSortableDropDownBullet className={className} />
));
