import React from 'react';

import { Card, Form } from 'antd';
import Button from 'antd/lib/button';
// @ts-ignore
import { navigate } from 'hookrouter';
import styled from 'styled-components';

import { LabelledCopyPasteRedirect } from './components/LabelledCopyPasteRedirect';
import { LinkTTL } from './components/LinkTTL';
// @ts-ignore

const Style = styled.div`
  .ant-form {
    display: flex;
    align-items: flex-start;
    margin: auto;
  }

  .ant-form-horizontal {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    .ant-form-item {
      width: 100%;
    }
    .ant-form-item-control {
      //flex: 0 !important;
    }
  }
`;

export function LinkExpiredForm({ state }) {
  return (
    <Style>
      <Card className="card" title="Your short link was disable" extra={<LinkTTL redirect={state.redirect} />}>
        <Form layout="vertical" style={{ justifyContent: 'center' }}>
          <Form.Item name="url" label="" style={{ width: '450px', marginBottom: '0px' }}>
            <LabelledCopyPasteRedirect redirect={state.redirect} />
          </Form.Item>

          <Form.Item>
            <Button
              size="large"
              type="primary"
              title="Shorten"
              htmlType="submit"
              onClick={() => navigate('/easy/signup')}
            >
              KEEP MY LINK
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Style>
  );
}
