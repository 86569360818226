import './QueryBuilder.css';

import React, { useState } from 'react';

import { Button, Icon, Popover } from '@blueprintjs/core';
import { DateRangePicker, IDateFormatProps } from '@blueprintjs/datetime';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import type { queryT } from 'stores/analytics';

import { Buttonize } from '../../common/Buttonize';
import { Export } from '../../common/icons/Export';
import { Polygon } from '../../common/icons/Polygon';
import Modal from '../../common/Modal';

const jsDateFormatter: IDateFormatProps = {
  // note that the native implementation of Date functions differs between browsers
  formatDate: (date) => date.toLocaleDateString(),
  parseDate: (str) => new Date(str),
  placeholder: 'MM/DD/YYYY',
};

const ExportButtonContainer = styled(Buttonize).withConfig({
  displayName: 'ExportButtonContainer',
})`
  width: 30px;
  height: 30px;
  border-color: rgb(238, 238, 238);
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 6px;
  margin-right: 3px;
  float: left;
  flex-shrink: 0;
  flex-grow: 0;
`;

export default function QueryBuilder({
  account,
  query,
  redirect,
  handleChangeQuery,
  handleExportStats,
}: {
  account: accountT,
  query: queryT,
  redirect?: redirectT,
  handleChangeQuery: (queryT) => void,
  handleExportStats: (queryT) => void,
}) {
  const date = new Date();
  const endDateStr = date.toISOString();

  date.setDate(date.getDate() - 7);
  const startDateStr = date.toISOString();

  const [isDateRangeShowed, showDateRange] = useState(false);
  const [startDate, setStartDate] = useState(startDateStr);
  const [endDate, setEndDate] = useState(endDateStr);

  return (
    <div className="query-builder">
      <div className="query-builder__header">
        {redirect ? (
          <div className="query-builder__breadcrumb">
            <Link key="2" to={`/${account.id}/ab/analytics`}>
              Global stats >{' '}
            </Link>
            {redirect.title}
          </div>
        ) : (
          <div className="query-builder__breadcrumb">Global stats</div>
        )}
        <div className="query-builder__actions">
          <ExportButtonContainer>
            <ConfirmedExport onOk={(_) => handleExportStats(query)} />
          </ExportButtonContainer>
          <Popover
            isOpen={isDateRangeShowed}
            onClose={() => showDateRange(false)}
            usePortal={true}
            minimal={true}
            hasBackdrop={true}
            canOutsideClickClose={true}
          >
            <Button onClick={() => showDateRange(true)} className="date-picker__button">
              <Icon icon="calendar" iconSize={12} color="#2e89e2" />
              <span>
                {`${startDate ? moment(startDate).format('D MMM') : ' '}-${
                  endDate ? moment(endDate).format('D MMM') : ' '
                }`}
              </span>
              <span style={{ height: '11px', width: '11px' }}>
                <Polygon />
              </span>
            </Button>
            <DateRangePicker
              {...jsDateFormatter}
              onChange={(dates) => {
                const [startDate, endDate] = [...dates];

                handleChangeQuery({
                  ...query,
                  startDate: startDate ? startDate.toISOString() : '',
                  endDate: endDate ? endDate.toISOString() : '',
                });
                setStartDate(startDate);
                setEndDate(endDate);
                showDateRange(!(startDate && endDate));
              }}
            />
          </Popover>
        </div>
      </div>
      {redirect && (
        <div className="query-builder__details">
          <div>
            <label>Shortened link</label>
            <span className="query-builder__details--muted">{redirect.shorten}</span>
          </div>
          <div>
            <label>Destination URL</label>
            <a href={redirect.url} target="_blank" rel="noopener noreferrer">
              {redirect.url}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

function ConfirmedExport({ onOk }) {
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      {showConfirmation && (
        <Modal
          handleCancel={() => {
            setShowConfirmation(false);
          }}
          handleOk={(_) => {
            setShowConfirmation(false);
            onOk();
          }}
          okMessage="Send it!"
        >
          Your stats will be send by email{' '}
          <span role="img" aria-label="email icon">
            💌
          </span>
        </Modal>
      )}
      <Export
        onClick={function () {
          setShowConfirmation(!showConfirmation);
        }}
      />
    </>
  );
}
