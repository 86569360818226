import React, { useState } from 'react';

import styled from 'styled-components';

import { Toggle } from '../../../../common/icons';

const Title = styled.span.withConfig({ displayName: 'Title' })`
  margin-right: 10px;
  border-radius: 5px;
  font-size: var(--normal-size);
`;
const Help = styled.span.withConfig({ displayName: 'Help' })`
  font-size: var(--small-size);
  color: var(--text-muted);
`;

type internalPropsT = {
  title: string,
  help: string,
  locked: boolean,
};

function AbstractComposerTitle(props: internalPropsT) {
  const { title, help, locked, className, setDropDownOpen, showToggle } = props;
  const [isDropDownOpen, setInternalDropDownOpen] = useState(false);

  function onClick() {
    if (locked) {
      return;
    }

    return () => {
      if (setDropDownOpen) {
        setDropDownOpen(!isDropDownOpen);
      }

      setInternalDropDownOpen(!isDropDownOpen);
    };
  }

  return (
    <div className={className} onClick={onClick()}>
      {/* <Icon>{!!renderIcon && renderIcon()}</Icon> */}
      <Title>{title}</Title>
      <Help>{help}</Help>
      {locked && <Lock />}
      {!locked && showToggle && (
        <Toggle
          className={
            isDropDownOpen ? 'toggle-modal-create-link toggle-modal-create-link-active' : 'toggle-modal-create-link'
          }
          onClick={() => setInternalDropDownOpen(!isDropDownOpen)}
        />
      )}
    </div>
  );
}

export const ComposerTitle = styled(AbstractComposerTitle).withConfig({
  displayName: 'ComposerTitle',
})`
  border-bottom: ${(props) => (props.noBorder ? 'none !important' : '')};
  padding-bottom: ${(props) => (props.noBorder ? '0 !important' : '')};
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  color: #6d859e;

  @media screen and (min-height: 800px) {
    //padding-top: 10px;
  }
`;

function Lock() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: '10px' }}
    >
      <path
        d="M10.25 4.5H9.49999V3.49999C9.49999 1.57008 7.92994 0 6 0C4.07006 0 2.50001 1.57008 2.50001 3.49999V4.5H1.75001C1.61182 4.5 1.5 4.61182 1.5 4.75001V11C1.5 11.5515 1.94848 12 2.50001 12H9.50002C10.0515 12 10.5 11.5515 10.5 11V4.75001C10.5 4.61182 10.3882 4.5 10.25 4.5ZM6.74855 9.72241C6.75635 9.79296 6.73366 9.86377 6.6863 9.91676C6.63893 9.96973 6.57105 10 6.50002 10H5.50001C5.42897 10 5.36109 9.96973 5.31373 9.91676C5.26636 9.86379 5.24365 9.79298 5.25148 9.72241L5.40919 8.30421C5.15309 8.11793 5.00002 7.82325 5.00002 7.5C5.00002 6.94849 5.44849 6.49999 6.00002 6.49999C6.55155 6.49999 7.00003 6.94847 7.00003 7.5C7.00003 7.82325 6.84696 8.11793 6.59086 8.30421L6.74855 9.72241ZM7.99999 4.5H4.00001V3.49999C4.00001 2.39721 4.89722 1.5 6 1.5C7.10278 1.5 7.99999 2.39721 7.99999 3.49999V4.5Z"
        fill="#B0B0B0"
      />
    </svg>
  );
}
