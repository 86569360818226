import api from './api';
import front from './front';
import track from './track';

export const Actions = { api, front, track };

export function addApiError(error: Error, url: string, method: string) {
  return {
    type: Actions.api.error.type,
    payload: { error, url, method },
  };
}

export function trackFromBackend(response: any) {
  return {
    type: Actions.track.trackingFromBackend,
    payload: response,
  };
}

export function trackOnboarding(payload: any) {
  return {
    type: Actions.track.trackingOnboarding,
    payload,
  };
}
