import React from 'react';

function NewLogo() {
  return (
    <svg width="163" height="37" viewBox="0 0 163 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_908_14557)">
        <path d="M25 17.5V25H17.5L2.5 40H40V2.5L25 17.5Z" fill="black" />
        <path d="M15 22.5V15H22.5L37.5 0H0V37.5L15 22.5Z" fill="black" />
      </g>
      <path
        d="M69.84 9.5H62.1V30.5H65.55V23.3H69.84C73.8 23.3 76.8 20.27 76.8 16.4C76.8 12.5 73.8 9.5 69.84 9.5ZM69.84 20.06H65.55V12.74H69.84C71.88 12.74 73.35 14.27 73.35 16.4C73.35 18.5 71.88 20.06 69.84 20.06ZM80.6053 13.25C81.7453 13.25 82.6753 12.29 82.6753 11.18C82.6753 10.04 81.7453 9.11 80.6053 9.11C79.4653 9.11 78.5353 10.04 78.5353 11.18C78.5353 12.29 79.4653 13.25 80.6053 13.25ZM78.9853 30.5H82.2253V15.5H78.9853V30.5ZM98.7973 30.5L93.1873 22.82L98.5273 15.5H94.7473L91.2973 20.24L87.8473 15.5H84.0973L89.4373 22.79L83.7973 30.5H87.5773L91.3273 25.37L95.0473 30.5H98.7973ZM101.839 24.38H113.779C113.839 23.96 113.899 23.48 113.899 23.03C113.899 18.71 110.839 15.11 106.369 15.11C101.659 15.11 98.4492 18.56 98.4492 23C98.4492 27.5 101.659 30.89 106.609 30.89C109.549 30.89 111.799 29.66 113.179 27.62L110.509 26.06C109.789 27.11 108.439 27.92 106.639 27.92C104.239 27.92 102.349 26.72 101.839 24.38ZM101.809 21.74C102.259 19.49 103.909 18.05 106.339 18.05C108.289 18.05 110.179 19.16 110.629 21.74H101.809ZM116.15 30.5H119.39V8.6H116.15V30.5ZM142.862 9.5H139.172L132.812 20L126.482 9.5H122.762V30.5H126.212V15.5L132.602 26.09H133.022L139.412 15.53V30.5H142.862V9.5ZM148.789 24.38H160.729C160.789 23.96 160.849 23.48 160.849 23.03C160.849 18.71 157.789 15.11 153.319 15.11C148.609 15.11 145.399 18.56 145.399 23C145.399 27.5 148.609 30.89 153.559 30.89C156.499 30.89 158.749 29.66 160.129 27.62L157.459 26.06C156.739 27.11 155.389 27.92 153.589 27.92C151.189 27.92 149.299 26.72 148.789 24.38ZM148.759 21.74C149.209 19.49 150.859 18.05 153.289 18.05C155.239 18.05 157.129 19.16 157.579 21.74H148.759Z"
        fill="black"
      />
      <defs>
        <clipPath id="clip0_908_14557">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default NewLogo;
