import React from 'react';

import IconContainer from './IconContainer';

export function Triangle(props) {
  return (
    <IconContainer {...props}>
      <svg width="9" height="7" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5 7L.603.25h7.794L4.5 7z" fill={`${props.color ? props.color : '#2E89E2'}`} />
      </svg>
    </IconContainer>
  );
}
