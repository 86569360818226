import styled from 'styled-components';

import { meanAverage } from 'lib/util';

export const MOVING_AVG_LEN = 3;

const Style = styled.div`
  text-align: center;
`;

const CurrentRank = styled.div`
  font-weight: bold;
`;

const RankComparison = styled.div`
  ${(props) => {
    if (props.$changeValue < 0) {
      return 'color: #0F993E;';
    } else if (props.$changeValue > 0) {
      return 'color: #D93F21;';
    }

    return 'color: var(--text-muted);';
  }}
`;

type KeywordRankComparisonProps = {
  keyword: string,
  metrics?: {
    ranking: Array<{
      keyword: string,
      metrics: Array<{ date: string, value: number | null }>,
    }>,
  },
};

export const KeywordRankComparison = ({ keyword, metrics }: KeywordRankComparisonProps) => {
  const ranks =
    metrics?.ranking
      ?.find((r) => r.keyword === keyword)
      ?.metrics.filter((m) => m.value != null)
      .map((m) => m.value) ?? [];
  const startRanks = ranks.slice(0, MOVING_AVG_LEN);
  const endRanks = ranks.slice(-MOVING_AVG_LEN);

  if (startRanks.length === 0 || endRanks.length === 0) {
    return <Style>N/A</Style>;
  }

  const movingAvgStart = Math.round(meanAverage(startRanks));
  const movingAvgEnd = Math.round(meanAverage(endRanks));
  const changeValue = movingAvgEnd - movingAvgStart;
  const changeSymbol = changeValue === 0 ? '' : changeValue > 0 ? '\u2212' : '+';

  return (
    <Style>
      <CurrentRank>{movingAvgEnd}</CurrentRank>
      <RankComparison $changeValue={changeValue}>
        {movingAvgStart} ( {changeSymbol + Math.abs(changeValue)} )
      </RankComparison>
    </Style>
  );
};
