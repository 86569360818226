import { amazonAttributionIds } from '../../oauth/AmazonAttributionIds';

export function productURL(marketplaceID, asin) {
  // Find the attribution ID for the marketplace
  const entry = amazonAttributionIds.find((item) => item.id === marketplaceID);

  if (!entry) {
    return '';
  }

  // Build the URL
  return `https://www.amazon${entry.domainExtension}/dp/${asin}`;
}
