import { WorkerAction } from '../../framework/workerAction';

export default {
  columns: {
    update: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.columns.update',
    }),
    updateConfig: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.columns.updateConfig',
    }),
  },
  edit: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.edit.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.edit.hide',
    }),
  },
  delete: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.delete.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.delete.hide',
    }),
  },
  add: {
    show: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.add.show',
    }),
    hide: new WorkerAction({
      name: 'front.audienceBuilder.campaigns.add.hide',
    }),
  },
  select: new WorkerAction({ name: 'front.audienceBuilder.campaigns.select' }),
  toggleTree: new WorkerAction({
    name: 'front.audienceBuilder.campaigns.toggleTree',
  }),
  toggleRedirect: new WorkerAction({
    name: 'front.audienceBuilder.campaigns.toggleRedirect',
  }),
};
