import { apiSlice, TAGS } from './apiSlice';

const { VARIATIONS } = TAGS;

// $FlowFixMe
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVariationsByAdGroupId: builder.query({
      query: ({ adGroupId, from, to, with: withParams }) => ({
        url: `/product-providers/-/products/-/campaigns/-/adgroups/${adGroupId}/variations`,
        method: 'get',
        params: { from, to, with: withParams },
      }),
      providesTags: (result) =>
        result?.variations
          ? [...result.variations.map(({ id }) => ({ type: VARIATIONS, id })), { type: VARIATIONS, id: 'LIST' }]
          : [{ type: VARIATIONS, id: 'LIST' }],
    }),
    createVariation: builder.mutation({
      query: ({ campaignId, adGroupId, ...data }) => ({
        url: `/product-providers/-/products/-/campaigns/${campaignId}/adgroups/${adGroupId}/variations`,
        method: 'post',
        data,
      }),
      invalidatesTags: [{ type: VARIATIONS, id: 'LIST' }],
    }),
    pauseVariation: builder.mutation({
      query: ({ id }) => ({
        url: `/product-providers/-/products/-/variations/${id}/pause`,
        method: 'put',
      }),
      invalidatesTags: (result, error, { id, invalidate = true }) => (invalidate ? [{ type: VARIATIONS, id }] : []),
    }),
    runVariation: builder.mutation({
      query: ({ id }) => ({
        url: `/product-providers/-/products/-/variations/${id}/run`,
        method: 'put',
      }),
      invalidatesTags: (result, error, { id, invalidate = true }) => (invalidate ? [{ type: VARIATIONS, id }] : []),
    }),
  }),
});

export const {
  useLazyGetVariationsByAdGroupIdQuery,
  useGetVariationsByAdGroupIdQuery,
  useCreateVariationMutation,
  usePauseVariationMutation,
  useRunVariationMutation,
} = extendedApiSlice;
