// PLEASE UPDATE https://www.notion.so/pixelme/Tracking-details-66fe6ca0fd1d49f0810ec6a874569de1
export const trackedEvents = {
  sortRedirects: 'sort-redirects',
  exportRedirects: 'export-redirects',
  exportFeedback: 'export-feedback',
  updateRedirectColumnConfiguration: 'update-redirect-column-configuration',
  addCampaign: 'add-campaign',
  deleteCampaign: 'delete-campaign',
  editCampaign: 'edit-campaign',
  addRedirect: 'add-redirect',
  deleteRedirect: 'delete-redirect',
  editRedirect: 'edit-redirect',
  reorderCampaign: 'reorder-campaign',
  addRedirectToCampaign: 'add-redirect-to-campaign',
  addSegment: 'add-segment',
  enterSegment: 'enter-segment',
  createTrackedLink: 'create-tracked-link',
  changeAttributionModel: 'change-attribution-model',
  changeDateRange: 'change-date-range',
  changeGroupBy: 'change-group-by',
  editEvent: 'edit-event',
  connectSegment: 'connect-segment',
  disconnectSegment: 'disconnect-segment',
  addIban: 'add-iban',
  addCard: 'add-card',
  changePlan: 'change-plan',
  subscribe: 'subscribe',
  logout: 'logout',
  login: 'login',
  clickInstallTracking: 'click-install-tracking',
  viewModalTrackConversions: 'view-modal-track-conversions',
  seeEndOfTrialFullmodal: 'see-end-of-trial-fullmodal',
  seeRevokedFullmodal: 'see-revoked-fullmodal',
  clickIconConversionPage: 'click-icon-conversion-page',
  connectGeneric: 'connect-generic',
  connectFacebook: 'connect-facebook',
  connectTiktok: 'connect-tiktok',
  disconnectFacebook: 'disconnect-facebook',
  disconnectGoogle: 'disconnect-google',

  editSegment: 'edit-segment',
  changeWindow: 'change-window',
  changeConversionEvent: 'change-conversion-event',
  changeTopFiveSources: 'change-top-five-sources',
  changeModel: 'change-model',
  emailValidated: 'email-validated',
  clickOnboarding: 'click-onboarding',

  addUsername: 'add-username',
  addButton: 'add-button',
  deleteButton: 'delete-button',
  changeTheme: 'change-theme',
  newLinkCreatedFromWebsite: 'new-link-created-from-website',
  step1Completed: 'step1-completed',
  step2Completed: 'step2-completed',
  step3Completed: 'step3-completed',
  step4Completed: 'step4-completed',
  newSignupStep2: 'new-signup-step-2',
  newSignupStep3: 'new-signup-step-3',
  newSignupStep4: 'new-signup-step-4',

  connectAmazon: 'connect-amazon',
  amazonNotFound: 'no-amazon-attribution-account',
  connectGoogle: 'connect-google',
  disconnectAdsProvider: 'disconnect-ads-provider',
  googleNotFound: 'no-google-attribution-account',
  disconnectAmazon: 'disconnect-amazon',

  addAdsCampaign: 'add-ads-campaign',
  deleteAdsCampaign: 'delete-ads-campaign',
  addProduct: 'add-product',
  deleteProduct: 'delete-product',
};
// PLEASE UPDATE https://www.notion.so/pixelme/Tracking-details-66fe6ca0fd1d49f0810ec6a874569de1
