import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { LegendItemSymbol } from './LegendItemSymbol';

const Container = styled.button`
  text-align: left;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 0 0.75rem;
  padding-right: 0.25rem;
  cursor: pointer;
  width: fit-content;
`;

const IconContainer = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  margin-left: 0.75rem;
  margin-right: 0.25rem;
  ${(props) =>
    !props.$active &&
    `
    * {
      color: #828282;
    }
  `}
`;

const Header = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.75rem;
`;

const DataRow = styled.div`
  display: flex;
  > * {
    font-size: 0.625rem;
    :not(:first-child) {
      margin-left: 1rem;
    }
  }
`;

const DeleteIcon = styled(DeleteOutlined)`
  // Enlarge click area
  padding: 0.5rem;
`;

export const RankingToggle = ({
  className,
  active = false,
  label,
  sales,
  volume,
  color,
  onToggle,
  onDelete,
}: {
  className?: string,
  active?: boolean,
  label: string,
  sales: string,
  volume: string,
  color?: string,
  onToggle: (string) => void,
  onDelete: (string) => void,
}) => {
  const handleDelete = (e) => {
    // Prevent calling onToggle when Delete is clicked
    e.stopPropagation();
    onDelete(label);
  };

  return (
    <Container className={className} onClick={() => onToggle(label)}>
      <IconContainer>
        <LegendItemSymbol color={color} inactive={!active} />
      </IconContainer>
      <Content $active={active}>
        <Header>{label}</Header>
        <DataRow>
          <span>sales: {sales}</span>
          <span>volume: {volume}</span>
        </DataRow>
      </Content>
      <DeleteIcon onClick={handleDelete} />
    </Container>
  );
};
