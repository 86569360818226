import React from 'react';

import styled from 'styled-components';

function ButtonizeNoStyle({ children, className }) {
  return <span className={className}>{children}</span>;
}

export const Buttonize = styled(ButtonizeNoStyle).withConfig({
  displayName: 'Buttonize',
})`
  background: transparent;
  cursor: pointer;
  :hover {
    border-color: var(--pixelme-color) !important;
  }
`;
