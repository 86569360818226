import React, { useState, type Node } from 'react';

import { Tooltip } from 'antd';

import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';
import { Info } from 'icons/Info';

import { InfoCampaignModal } from './InfoCampaignModal';

type propsT = {
  campaign: campaignT,
};
export function InfoCampaignButton({ campaign }: propsT): Node {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <InfoCampaignModal campaign={campaign} visible={visible} onFinish={() => setVisible(false)} />
      <IconInsideButton onClick={() => setVisible(true)}>
        <Tooltip title="Campaign Info" placement="bottom">
          <Info />
        </Tooltip>
      </IconInsideButton>
    </>
  );
}
