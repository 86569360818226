import React from 'react';

import IconContainer from './IconContainer';

export function Close(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L9 9M1 9L9 1" stroke="#828282" strokeLinecap="round" />
      </svg>
    </IconContainer>
  );
}
