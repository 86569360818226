import { Input } from 'antd';
import styled from 'styled-components';

export const FormInputGroup = styled(Input.Group)`
  display: flex;
  justify-content: space-between;

  .ant-select {
    width: 100% !important;
  }
  .ant-input-group .ant-form-item {
    flex: 1;
  }
  ::before,
  ::after {
    display: none;
  }
`;
