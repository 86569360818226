import _ from 'lodash';
import { connect } from 'react-redux';

import { s, sd } from 'lib/safe';

import { Actions } from '../../../../../actions';
import { AbstractPaginator } from '../../../../common/Paginator';

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => {
  state.abRedirects = sd(state.abRedirects, []);

  return {
    start: sd(s(state.abRedirects.filter).start, 0),
    limit: sd(s(state.abRedirects.filter).limit, 20),
    total: sd(state.abRedirects.nbRedirects, 0),
    loading: sd(s(state.front.campaignColumnsConfig).loading, false),
  };
};

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  loadPrev: (event) => dispatch(Actions.api.audienceBuilder.redirect.loadPrev.request({})),
  loadNext: (event) => dispatch(Actions.api.audienceBuilder.redirect.loadNext.request({})),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) =>
  _.isEqual(s(prev.abRedirects.filter).start, s(next.abRedirects.filter).start) &&
  _.isEqual(s(prev.abRedirects).nbRedirects, s(next.abRedirects.filter).nbRedirects);

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const RedirectsPaginator = reduxWrapper(AbstractPaginator);
