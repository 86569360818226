import React, { Fragment, useEffect, useRef, useState } from 'react';

import { CheckCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Divider, Form, Input, Modal, message, notification } from 'antd';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import qs from 'query-string';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { googleLanguages } from 'assets/googleLanguage';
import { trackedEvents } from 'config/trackedEvents.config';
import { overwriteMerge, useAdsCampaign } from 'hooks/useAdsCampaign';
import { getRawCampaign, submitCampaign } from 'lib/adsPlateformApi';
import { generateDefaultCampaignName } from 'lib/adsProvider/common';
import { track } from 'lib/analytics';
import axios from 'lib/axios.factory';
import {
  useFeatureFlag,
  PERFORMANCE_BOOSTER,
  CLICK_TRACKER_TRANSPARENCY,
  GENERATE_DKI_SUGGESTIONS,
  GOOGLE_RMF,
  CAMPAIGN_AUTOMATIONS,
} from 'lib/configcat';
import { s } from 'lib/safe';
import { getAdSuggestions } from 'lib/suggestions';
import { strings } from 'locales/strings';
import { useSaveCampaignOnProductAndProviderMutation } from 'stores/campaignsSlice';
import { useEditProductMutation } from 'stores/productsSlice';
import { getCurrencySymbol } from 'utils/currency';

import { AdSuggestionsSection, AdSuggestionsSubSection } from './AdSuggestions';
import { BulkAddKeywords } from './BulkAddKeywords';
import { MATCH_TYPE_DEFAULT, MatchTypeLabel } from './constants';
import { GoogleBlockedAccount } from './GoogleBlockedAccount';
import { GoogleGeoTargetSelector } from './GoogleGeoTargetSelector';
import { GoogleRawCampaignView } from './GoogleRawCampaignView';
import { KeywordSuggestionsModal } from './KeywordSuggestionsModal';
import { MatchTypeSelect } from './MatchTypeSelect';
import PerformanceBoosterDescription from './PerformanceBoosterDescription';
import { PinHeadlinesToggle } from './PinHeadlinesToggle';
import { CurrencyInput } from '../../../common/antd/inputs/CurrencyInput';
import { InputDateWithDisabledRange } from '../../../common/antd/inputs/InputDateWithDisabledRange';
import { MutedText } from '../../../common/MutedText';
import { SmallPlaceholder } from '../../../common/Placeholders';
import { PrettyPrintJson } from '../../../common/PrettyPrintJson';
import { TitleWithInstructions } from '../../../common/TitleWithInstructions';
import { CurrencyValue } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { AddANewButton } from '../common/AddANewButton';
import { AddDomainButton } from '../common/AddDomainButton';
import { AddPixelButton } from '../common/AddPixelButton';
import { AdsPreview } from '../common/AdsPreview';
import { CampaignAutomationsForm, getAutomationsPayload, OPTIONS } from '../common/CampaignAutomationsForm';
import { ProductPreviewForNewCampaign } from '../common/ProductPreviewForNewCampaign';
import { Wizard } from '../common/Wizard';
import { authorizedTextInAds, authorizedTextInKeywords } from '../constants';
import { BiddingOptionsSelector } from '../GoogleRMF/BiddingOptionsSelector.jsx';
import { NegativeKeywordsInput } from '../GoogleRMF/NegativeKeywordsInput.jsx';
import { AdsProviderSelector } from '../NewCampaign/AdsProviderSelector';
import { DeeplinkToggle } from '../NewCampaign/DeeplinkToggle';
import { DomainSelector } from '../NewCampaign/DomainSelector';
import { LanguageSelector } from '../NewCampaign/LanguageSelector';
import { TYPE_PRODUCT } from '../NewCampaign/NewProductModal';
import { PerformanceBoosterToggle } from '../NewCampaign/PerformanceBoosterToggle';
import { GoogleCampaignPixelSelector } from '../NewCampaign/PixelSelector';
import { ProductSelector } from '../NewCampaign/ProductSelector';

const Style = styled.div`
  min-width: 100%;
  min-height: 100%;
  padding: 1em;
  background: #f2f2f2;
  overflow-x: hidden;

  .ant-tag {
    height: 28px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #595959;
    text-decoration: underline;
  }

  /* Keywords height fix*/

  input,
  .ant-form-item-control-input-content {
    height: 100%;
  }

  .suggestions {
    margin: 1em 0;
  }

  .suggestions__text {
    background-color: #fafafa;
    padding: 0.7em;
    margin-bottom: 1em;
  }
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #595959;
  margin-right: 10px;
`;

const Paragraph = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 6px;
  ${(props) => props.$leftIndent && `padding-left: 1.5rem;`}
`;
const Value = styled.div`
  display: inline-block;
  max-width: 200px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #595959 !important;
  white-space: nowrap;
  wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;

  .ant-statistic-content {
    color: #595959 !important;
    font-size: 13px;
    line-height: 18px;
  }
`;
const LeftSide = styled.div`
  @media screen and (min-width: 0px) and (max-width: 1800px) {
    min-width: 350px;
  }

  @media screen and (min-width: 1801px) {
    min-width: 500px;
  }
`;

const KeywordsButtonContainer = styled.div`
  display: grid;
  column-gap: 1em;
  grid-template-columns: 1fr 1fr;
`;

const { confirm } = Modal;

const initialValues = {};

const dkiMatch = new RegExp(/^(.*){KeyWord:(.*)}(.*)$/);

const getToggleLabel = (enabled) => Object.values(OPTIONS).find((option) => enabled === option.value)?.label ?? '';

export function GoogleNewAdsCampaignPage(props: { account: accountT, campaignId: string, productId: string }) {
  const [performanceBoosterEnabled] = useFeatureFlag(PERFORMANCE_BOOSTER);
  const [clickTrackerTransparencyEnabled, loadingFeatureFlags] = useFeatureFlag(CLICK_TRACKER_TRANSPARENCY);
  const [generateDKISuggestions] = useFeatureFlag(GENERATE_DKI_SUGGESTIONS);
  const [showBiddingOptions] = useFeatureFlag(GOOGLE_RMF);
  const [isCampaignAutomationsEnabled] = useFeatureFlag(CAMPAIGN_AUTOMATIONS);
  const [saveCampaignOnProductAndProvider] = useSaveCampaignOnProductAndProviderMutation();

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const wizardRef = useRef();
  const [currencyCode, setCurrencyCode] = useState('USD');
  const [currencySymb, setCurrencySymb] = useState('$');

  const { campaign, setCampaign } = useAdsCampaign(props.campaignId, props.account.id, initialValues, 'GOOGLE');

  const productId = _.get(campaign, ['productId']);

  const [fetchingAdSuggestions, setFetchingAdSuggestions] = useState(true);
  const [adSuggestions, setAdSuggestions] = useState({
    productHeadlines: [],
    offerHeadlines: [],
    descriptions: [],
  });
  const [adSuggestionsError, setAdSuggestionsError] = useState(false);
  const [negativeKeywordsList, setNegativeKeywordsList] = useState([<NegativeKeywordsInput />]);

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();
  const [form5] = Form.useForm();

  const timeOutId = useRef();
  const { messages } = useIntl();

  const loadAdSuggestions = (productProviderId, adsProviderId, asin, keywords, totalTimeInSeconds = 0) => {
    // Timeout after 3 minutes

    if (totalTimeInSeconds > 180) {
      setAdSuggestionsError(true);

      return;
    }

    getAdSuggestions(localStorage, axios, productProviderId, asin, {
      accountId: props.account.id,
      adsProviderId,
      keywords,
    })
      .then((response) => {
        if (response.fetchStartedAt !== null) {
          // pull the data every 2 seconds, until it gets generated
          timeOutId.current = setTimeout(
            () => loadAdSuggestions(productProviderId, adsProviderId, asin, keywords, totalTimeInSeconds + 2),
            2000,
          );

          return;
        }

        // Add DKI suggestions generated from first keyword
        if (generateDKISuggestions && keywords.length > 0) {
          response.productHeadlines = [
            `Best {KeyWord:${keywords[0].replace(/\b\w/g, (x) => x.toUpperCase())}}`,
            ...response.productHeadlines,
          ];
        }

        setAdSuggestions(response);
        setFetchingAdSuggestions(false);
      })
      .catch((e) => {
        console.log('failed to fetch suggestions', e);
        setAdSuggestionsError(true);
        setFetchingAdSuggestions(false);
      });
  };

  useEffect(
    () => () => {
      clearTimeout(timeOutId.current);
    },
    [],
  );

  const stepChanged = (form) => async (values) => {
    clearTimeout(timeOutId.current);

    if (values.adsProvider) {
      values.providerId = values.adsProvider.id;
      values.providerType = values.adsProvider.providerType;
      const cC = _.get(values.adsProvider, ['externalAccountCurrencyCode'], currencyCode) || 'USD';
      const cSymb = getCurrencySymbol(cC);

      setCurrencyCode(cC);
      setCurrencySymb(cSymb);

      delete values.adsProvider;
    }

    if (values.product) {
      values.suggestedKeywords = values.product.suggestedKeywords;
      values.productId = values.product.id;
      values.productExternalId = values.product.externalId;
      values.productUrl = values.product.productUrl;

      // "auto"-refresh name only if product was changed
      if (values.product.id !== _.get(campaign, ['productId'])) {
        const name = generateDefaultCampaignName(values.product);

        _.set(values, ['name'], name);
        _.set(values, ['googleCampaign', 'name'], name);
        _.set(values, ['googleCampaign', 'adGroup', 'name'], name);
      }

      if (!_.get(values, ['googleCampaign', 'ad', 'headlines'])) {
        _.set(
          values,
          ['googleCampaign', 'ad', 'headlines'],
          _.get(campaign, ['googleCampaign', 'ad', 'headlines']) || [],
        );
      }

      if (!_.get(values, ['googleCampaign', 'ad', 'headlines', 0])) {
        _.set(values, ['googleCampaign', 'ad', 'headlines', 0], '');
      }

      if (!_.get(values, ['googleCampaign', 'ad', 'headlines', 1])) {
        _.set(values, ['googleCampaign', 'ad', 'headlines', 1], '');
      }

      if (!_.get(values, ['googleCampaign', 'ad', 'headlines', 2])) {
        _.set(values, ['googleCampaign', 'ad', 'headlines', 2], '');
      }

      if (!_.get(values, ['googleCampaign', 'ad', 'descriptions'])) {
        _.set(
          values,
          ['googleCampaign', 'ad', 'descriptions'],
          _.get(campaign, ['googleCampaign', 'ad', 'descriptions']) || [],
        );
      }

      if (!_.get(values, ['googleCampaign', 'ad', 'descriptions', 0])) {
        _.set(values, ['googleCampaign', 'ad', 'descriptions', 0], '');
      }

      if (!_.get(values, ['googleCampaign', 'ad', 'descriptions', 1])) {
        _.set(values, ['googleCampaign', 'ad', 'descriptions', 1], '');
      }
    }

    const newCampaign = deepmerge(campaign, values, {
      arrayMerge: overwriteMerge,
      clone: true,
    });

    setCampaign(newCampaign);

    if (_.get(values, ['googleCampaign', 'adGroup', 'keywords', 0])) {
      setAdSuggestions({
        productHeadlines: [],
        offerHeadlines: [],
        descriptions: [],
      });
      setAdSuggestionsError(false);
      setFetchingAdSuggestions(true);

      loadAdSuggestions(
        newCampaign.product.providerId,
        newCampaign.providerId,
        newCampaign.product.externalProductId || newCampaign.product.externalId,
        newCampaign.googleCampaign.adGroup.keywords.map((k) => k.text),
      );
    }

    // Refresh data in forms
    form1.setFieldsValue(newCampaign);
    form2.setFieldsValue(newCampaign);
    form3.setFieldsValue(newCampaign);
    form4.setFieldsValue(newCampaign);

    return true;
  };

  async function saveCampaign(values) {
    setLoading(true);

    if (!values.product) {
      message.error('Missing Product');

      return;
    }

    // deep clone
    const newValues = JSON.parse(JSON.stringify(values));

    if (values.googleCampaign.geoCriteria !== undefined) {
      newValues.googleCampaign.geoCriteria = values.googleCampaign.geoCriteria || [];
    }

    newValues.googleCampaign.geoCriteria = newValues.googleCampaign.geoCriteria || [];
    newValues.googleCampaign.geoCriteria = newValues.googleCampaign.geoCriteria
      .filter((g) => g && g.criteriaId && g.canonicalName)
      .map((g) => ({
        ...g,
        id: parseInt(g.criteriaId),
      }));

    if (values.googleCampaign.languagesCriteria) {
      newValues.googleCampaign.languagesCriteria = values.googleCampaign.languagesCriteria.map((g) => ({ id: g }));
    }

    newValues.googleCampaign.languagesCriteria = newValues.googleCampaign.languagesCriteria || [];
    newValues.googleCampaign.languagesCriteria = newValues.googleCampaign.languagesCriteria.filter((g) => g && g.id);

    if (newValues.googleCampaign.adGroup.keywords !== undefined) {
      newValues.googleCampaign.adGroup.keywords = newValues.googleCampaign.adGroup.keywords.filter(
        (k) => k.matchType !== '',
      );
    }

    if (isCampaignAutomationsEnabled) {
      newValues.automationRules = getAutomationsPayload(newValues).automationRules;
    }

    try {
      const newCampaignData = (
        await saveCampaignOnProductAndProvider({
          accountId: props.account.id,
          providerId: values.product.providerId,
          productId: values.product.externalId,
          campaign: newValues,
        })
      ).data;
      const newRawCampaign = await getRawCampaign(
        localStorage,
        axios,
        { accountId: props.account.id },
        newCampaignData.campaign.id,
      );
      const redirectUrl = qs.stringifyUrl({
        url: `/${props.account.id}/adsprovider/new-google-campaign-success/${newCampaignData.campaign.id}`,
        query: {
          productProviderId: values.product.providerId,
          productExternalId: values.product.externalId,
        },
      });

      setLoading(false);
      confirm({
        onOk: async () => {
          setLoading(true);

          try {
            await submitCampaign(localStorage, axios, { accountId: props.account.id }, newCampaignData.campaign.id);
            track(trackedEvents.addCampaign, { campaign });
            history.push(redirectUrl);
            setLoading(false);
          } catch (e) {
            setLoading(false);

            throw e;
          }
        },
        onCancel: async () => {
          history.push(redirectUrl);
        },
        okText: 'Publish',
        cancelText: 'Save as draft',
        title: 'Campaign Created - Review it',
        width: 600,
        icon: <CheckCircleOutlined />,
        content: <GoogleRawCampaignView rawQueryResult={newRawCampaign} account={props.account} />,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.response && e.response.status === 409) {
        confirm({
          okText: 'Ok',
          cancelButtonProps: { disabled: true },
          title: 'Campaign already exists',
          icon: <ExclamationCircleOutlined />,
          content: (
            <MutedText>
              Campaign {newValues.name} already exists! <br />
              Please change campaign name
            </MutedText>
          ),
          afterClose: () => setTimeout(() => wizardRef.current.forceStep(1)),
        });

        return;
      }

      const errorMessage = _.get(e, 'errorMessage');

      if (errorMessage && errorMessage.includes('Unable to launch your ad: Your account is locked by Google.')) {
        confirm({
          okText: 'Ok',
          width: 600,
          cancelButtonProps: { disabled: true },
          title: 'Unable to launch your ad!',
          icon: <ExclamationCircleOutlined />,
          content: (
            <Fragment>
              <br />
              <Alert type="error" message="Your account is locked by Google."></Alert>
              <br />
              <MutedText>
                You can check your account{' '}
                <a target="_blank" href="https://ads.google.com/aw/overview" rel="noreferrer">
                  here
                </a>
                ,<br />
                or change ads provider on the first page.
              </MutedText>
              <br />
              <div>
                <GoogleBlockedAccount />
              </div>
            </Fragment>
          ),
          afterClose: () => setTimeout(() => wizardRef.current.forceStep(0)),
        });
      } else if (
        errorMessage &&
        errorMessage.includes(
          'Cannot change run status for the Creative in With Issues status. You cannot change the run status of Creative in With Issues status',
        )
      ) {
        notification.error({
          duration: 30,
          message: errorMessage.replace('Creative rejected by facebook please, '),
        });
      } else if (errorMessage) {
        notification.error({
          style: { width: 600 },
          duration: 30,
          message: errorMessage
            .replace('Error during querying (status):', '')
            .replaceAll(/Error(((?!message).)|\n)*message:/g, ''),
        });
      }

      throw e;
    }
  }

  const campaignRequirementsOk = campaign && campaign.providerId && campaign.productId;

  function RequirementsOk({ children }) {
    return <Fragment>{campaignRequirementsOk && children()}</Fragment>;
  }

  let wizardSteps = [];

  if (campaign) {
    wizardSteps = [
      {
        title: 'Product',
        form: form1,
        onChange: stepChanged(form1),
        content: (
          <Form
            initialValues={campaign}
            requiredMark="optional"
            form={form1}
            style={{ height: 'calc( 100% - 50px)' }}
            layout="vertical"
          >
            <div style={{ width: 500 }}>
              <AdsProviderSelector account={props.account} providerType="GOOGLE" form={form1} />
              <ProductSelector account={props.account} productId={props.productId} />
            </div>
          </Form>
        ),
      },
      {
        title: 'Campaign Settings',
        form: form2,
        extra: <ProductPreviewForNewCampaign productId={productId} account={props.account} />,
        onChange: stepChanged(form2),
        content: <RequirementsOk>{CAMPAIGN_SETTINGS_FORM}</RequirementsOk>,
      },
      {
        title: 'Set Up Ad group',
        form: form3,
        extra: <ProductPreviewForNewCampaign productId={productId} account={props.account} />,
        footerExtra: (
          <div
            style={{
              backgroundColor: '#FAFAFA',
              color: '#000000',
              float: 'left',
              fontSize: '12px',
              padding: '0.5em 1em',
              marginTop: '0.5em',
            }}
          >
            Need help selecting keywords?{' '}
            <a
              href="https://go.oncehub.com/PixelMeFastTrack"
              target="_blank"
              style={{ color: '#000000', textDecoration: 'underline' }}
              rel="noreferrer"
            >
              Schedule a call
            </a>{' '}
            with us!
          </div>
        ),
        onChange: stepChanged(form3),
        content: <RequirementsOk>{AD_GROUP_FORM}</RequirementsOk>,
      },
      {
        title: 'Set Up Ad',
        form: form4,
        onChange: stepChanged(form4),
        extra: <ProductPreviewForNewCampaign productId={productId} account={props.account} />,
        content: <RequirementsOk>{AD_FORM}</RequirementsOk>,
      },
      ...(isCampaignAutomationsEnabled
        ? [
            {
              title: messages.campaignAutomations.shortTitle,
              form: form5,
              extra: <ProductPreviewForNewCampaign productId={productId} account={props.account} />,
              onChange: stepChanged(form5),
              content: <RequirementsOk>{AUTOMATIONS_FORM}</RequirementsOk>,
            },
          ]
        : []),
      {
        title: 'Review & Publish',
        onChange: stepChanged(),
        extra: (
          <div style={{ flex: '1 0 300px' }}>
            <Form layout="vertical">
              <Form.Item name="" label="Ad preview">
                <AdsPreview
                  keywords={_.get(campaign, 'googleCampaign.adGroup.keywords')}
                  url={_.get(campaign, 'product.productUrl')}
                  headlines={_.get(campaign, 'googleCampaign.ad.headlines', '')}
                  descriptions={_.get(campaign, 'googleCampaign.ad.descriptions', '')}
                />
              </Form.Item>
            </Form>
          </div>
        ),
        content: <RequirementsOk>{REVIEW_FORM}</RequirementsOk>,
      },
    ];
  }

  function CAMPAIGN_SETTINGS_FORM() {
    const SPAConnected =
      props.account.productProviders[0].spa.connectedAt !== null &&
      props.account.productProviders[0].spa.errorAt === null;

    return (
      <Form
        initialValues={campaign}
        onValuesChange={(v) => {
          if (v.name) {
            const c = { ...campaign };

            _.set(c, ['googleCampaign', 'name'], v.name);
            _.set(c, ['googleCampaign', 'adGroup', 'name'], v.name);
            form3.setFieldsValue(c);
          }
        }}
        requiredMark="optional"
        form={form2}
        style={{ height: 'calc( 100% - 50px)' }}
        layout="vertical"
      >
        <div style={{ width: 500 }}>
          <Form.Item required name="name" label="Campaign Name" rules={[{ required: true, type: 'string' }]}>
            <Input size="large" placeholder="[Batterie acquisition] - Summer 2021"></Input>
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'block' }}>
              <Form.Item name={['googleCampaign', 'start']} label="Start date">
                <InputDateWithDisabledRange
                  startDate={new Date()}
                  placeholder="Default Today"
                  endDate={new Date(8640000000000000)}
                />
              </Form.Item>
            </div>
            <div style={{ display: 'block' }}>
              <Form.Item
                tooltip={
                  <MutedText>
                    Your ads will continue to run
                    <br /> unless you specify an end date.
                  </MutedText>
                }
                name={['googleCampaign', 'end']}
                label="End date"
              >
                <InputDateWithDisabledRange startDate={new Date()} endDate={new Date(8640000000000000)} />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name={['googleCampaign', 'geoCriteria']}
            label="Location"
            rules={[{ required: false, type: 'array', min: 0, max: 30 }]}
          >
            <GoogleGeoTargetSelector account={props.account} />
          </Form.Item>
          <Form.Item
            name={['googleCampaign', 'languagesCriteria']}
            rules={[{ required: false, type: 'array', min: 0, max: 30 }]}
            label="Languages"
          >
            <LanguageSelector googleLanguages={googleLanguages} />
          </Form.Item>
          <Form.Item
            required
            name={['googleCampaign', 'budgetMicros']}
            label={`Daily budget in ${currencyCode}`}
            rules={[{ required: true, type: 'number', min: 1 }]}
          >
            <CurrencyInput currencyCode={currencyCode} unit={1000000}></CurrencyInput>
          </Form.Item>
          <div
            style={{
              marginTop: '-62px',
              marginBottom: '20px',
              marginLeft: '120px',
              textAlign: 'center',
            }}
          >
            <MutedText>
              We recommend setting an <br />
              initial daily budget of at least $15
            </MutedText>
          </div>
          <Form.Item
            name={['googleCampaign', 'adGroup', 'cpcBidMicros']}
            label={`Maximum cost per click bid limit in ${currencyCode}`}
          >
            <CurrencyInput currencyCode={currencyCode} unit={1000000}></CurrencyInput>
          </Form.Item>
          <GoogleCampaignPixelSelector account={props.account} />
          <AddPixelButton accountId={props.account.id} />
          {showBiddingOptions && <BiddingOptionsSelector />}
          {!loadingFeatureFlags && !clickTrackerTransparencyEnabled && (
            <>
              <DomainSelector account={props.account} help="Only used if there is a retargeting pixel" />
              <AddDomainButton accountId={props.account.id} />
            </>
          )}
        </div>
        {performanceBoosterEnabled && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: 500 }}>
              <PerformanceBoosterToggle account={props.account} spaConnected={SPAConnected} />
            </div>
            {!SPAConnected && <PerformanceBoosterDescription />}
          </div>
        )}
        <div style={{ width: 500 }}>
          <DeeplinkToggle
            account={props.account}
            disabled={true}
            defaultChecked={true}
            tooltip="Deep links are enabled by default for Google campaigns"
          />
        </div>
      </Form>
    );
  }

  function AD_GROUP_FORM() {
    const [isSuggestionsModalVisible, showSuggestionsModal] = useState(false);
    const [product, setProduct] = useState(form1.getFieldValue('product'));
    const adsProvider = form1.getFieldValue('adsProvider');
    const dailyBudget = form2.getFieldValue(['googleCampaign', 'budgetMicros']) || 25000000;
    const languages = form2.getFieldValue(['googleCampaign', 'languagesCriteria']) || [];
    const locations = (form2.getFieldValue(['googleCampaign', 'geoCriteria']) || []).map((c) => c.id);
    const selectedKeywords = () =>
      (form3.getFieldValue(['googleCampaign', 'adGroup', 'keywords']) || []).map((f) => f.text);

    const removeByValue = (remove: (kw: any) => void, val: string) => {
      (form3.getFieldValue(['googleCampaign', 'adGroup', 'keywords']) || [])
        .map((f, index) => (f.text === val ? index : null))
        .filter((v) => v !== null)
        .reverse()
        .forEach((index) => remove(['googleCampaign', 'adGroup', 'keywords', index]));
    };
    const [editProduct, { isLoading }] = useEditProductMutation();

    return (
      <div
        style={{
          display: 'flex',
          gap: '2em',
          justifyContent: 'space-between',
          width: 500,
        }}
      >
        <Form
          initialValues={campaign}
          requiredMark="optional"
          form={form3}
          style={{ height: 'calc( 100% - 50px)' }}
          layout="vertical"
        >
          <div style={{ width: 500 }}>
            <Form.Item required name={['googleCampaign', 'adGroup']} label="">
              <Form.Item
                required
                name={['googleCampaign', 'adGroup', 'name']}
                rules={[{ required: true, type: 'string', max: 120 }]}
                label="Ad group name"
                style={{ marginBottom: '5px' }}
              >
                <Input size="large"></Input>
              </Form.Item>
              <Form.Item
                name={['googleCampaign', 'adGroup', 'keywords']}
                rules={[{ required: true, type: 'array', min: 1, max: 100 }]}
              >
                <Form.List
                  name={['googleCampaign', 'adGroup', 'keywords']}
                  label=""
                  rules={[{ required: true, type: 'array', min: 1, max: 100 }]}
                >
                  {(fields, { add, remove }) => (
                    <div>
                      <div className="suggestions">
                        <div className="suggestions__text">
                          <p>
                            Start your campaign with only <b>3-4 keywords</b> and expand from there. You don’t want your
                            spend to spread out too thin.
                          </p>
                          <p>
                            You want each keyword to have at least 110% of the product price in ad spend so you can
                            properly test if that keyword converts.
                          </p>
                        </div>
                        <KeywordsButtonContainer>
                          <KeywordSuggestionsModal
                            account={props.account}
                            product={product}
                            adsProvider={adsProvider}
                            selectedKeywords={selectedKeywords()}
                            dailyBudget={dailyBudget}
                            languages={languages}
                            locations={locations}
                            visible={isSuggestionsModalVisible}
                            onKeywordAdd={(kw: string) => {
                              // Remove empty lines
                              removeByValue(remove, '');
                              add({ text: kw, matchType: MATCH_TYPE_DEFAULT });
                            }}
                            onKeywordRemove={(kw: string) => removeByValue(remove, kw)}
                            onCompetitorsSelected={async (competitors: productInfoT[]) => {
                              await editProduct({
                                accountId: props.account.id,
                                product: {
                                  ...product,
                                  competitors,
                                },
                                providerId: product.providerId,
                                productASIN: product.externalId,
                              });
                              form1.setFieldsValue({
                                ...form1.getFieldsValue(),
                                product,
                              });
                              setProduct({
                                ...product,
                                competitors,
                              });
                            }}
                            savingCompetitors={isLoading}
                            onFinish={() => showSuggestionsModal(false)}
                          />
                          <Button type="primary" ghost onClick={() => showSuggestionsModal(true)}>
                            View Keyword Suggestions
                          </Button>
                          <BulkAddKeywords
                            onSubmit={(kws, matchType) => {
                              removeByValue(remove, '');
                              kws.forEach((kw) => add({ text: kw, matchType }));
                            }}
                          />
                        </KeywordsButtonContainer>
                      </div>

                      {fields.map(({ key, name, ...restField }) => (
                        <div
                          key={`${name}_${key}`}
                          style={{
                            display: 'flex',
                            width: '100%',
                            gap: '1em',
                          }}
                        >
                          <Form.Item
                            required
                            style={{ flex: '1', marginBottom: 12 }}
                            name={[name, 'text']}
                            rules={[
                              {
                                required: true,
                                pattern: authorizedTextInKeywords,
                                min: 1,
                                max: 100,
                                message: 'Invalid Text',
                              },
                            ]}
                            label="Keyword"
                            {...restField}
                          >
                            <Input size="large"></Input>
                          </Form.Item>
                          <Form.Item
                            required
                            style={{ flex: '0 0 165px', marginBottom: 12 }}
                            label={<MatchTypeLabel />}
                            name={[name, 'matchType']}
                            rules={[{ required: true }]}
                            {...restField}
                          >
                            {MatchTypeSelect}
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: '40px' }} />
                        </div>
                      ))}
                      <AddANewButton onClick={() => add({ text: '', matchType: MATCH_TYPE_DEFAULT })}>
                        + {messages.campaigns.keywords.add}
                      </AddANewButton>
                    </div>
                  )}
                </Form.List>
              </Form.Item>
              <Form.Item name={['googleCampaign', 'adGroup', 'negativeKeywords']}>
                {showBiddingOptions && (
                  <div>
                    <div className="suggestions">
                      <div className="suggestions__text">
                        <p>Negative Keywords: Add your negative keywords to the campaign</p>
                      </div>
                    </div>
                    {negativeKeywordsList}
                    <AddANewButton
                      onClick={() => setNegativeKeywordsList(negativeKeywordsList.concat(<NegativeKeywordsInput />))}
                    >
                      + {messages.campaigns.keywords.add}
                    </AddANewButton>
                  </div>
                )}
              </Form.Item>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }

  function AD_FORM() {
    const selectedHeadlines = () => form4.getFieldValue(['googleCampaign', 'ad', 'headlines']) || [];
    const selectedDescriptions = () => form4.getFieldValue(['googleCampaign', 'ad', 'descriptions']) || [];
    const selectedKeywords = () =>
      (form3.getFieldValue(['googleCampaign', 'adGroup', 'keywords']) || []).map((f) => _.startCase(f.text));

    const removeByValue = (remove: (val: string) => void, field: string[], val: string) => {
      (form4.getFieldValue(field) || [])
        .map((f, index) => (f === val ? index : null))
        .filter((v) => v !== null)
        .reverse()
        .map((index) => remove([...field, index]));
    };

    return (
      <Form
        initialValues={campaign}
        requiredMark="optional"
        form={form4}
        style={{ height: 'calc( 100% - 50px)', minWidth: '400px' }}
        layout="vertical"
      >
        <div className="form-container">
          <Form.Item required name={['googleCampaign', 'ad']} label="">
            <Form.List
              name={['googleCampaign', 'ad', 'headlines']}
              label="Headlines"
              rules={[
                {
                  type: 'array',
                  message: 'Minimum 3 and maximum 14 headlines are required',
                  min: 3,
                  max: 14,
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div className="form-container__row" style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                  <LeftSide>
                    <TitleWithInstructions title="Headlines" instructions="Start your campaign with 8-10 headlines." />
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div
                        key={`${name}_${key}`}
                        style={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'stretch',
                          gap: '1em',
                          minWidth: '300px',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Form.Item
                            required
                            name={[name]}
                            label=""
                            rules={[
                              {
                                required: true,
                                min: 1,
                                message: 'Invalid Text',
                              },
                              {
                                message: 'Invalid Text Characters',
                                validator: (_, value) => {
                                  const found = value.match(dkiMatch);

                                  if (found !== null) {
                                    value = found[1] + found[2] + found[3];
                                  }

                                  if (authorizedTextInAds.test(value)) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(new Error('Invalid Text Characters'));
                                },
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const otherValues = (
                                    getFieldValue(['googleCampaign', 'ad', 'headlines']) || []
                                  ).filter((s) => s === value);

                                  if (otherValues.length === 1) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(new Error('Should be unique'));
                                },
                              }),
                              {
                                message: 'Maximum 30 characters',
                                validator: (_, value) => {
                                  const found = value.match(dkiMatch);

                                  if (found !== null) {
                                    value = found[1] + found[2] + found[3];
                                  }

                                  if (value.length <= 30) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(new Error('Maximum 30 characters'));
                                },
                              },
                            ]}
                          >
                            <Input size="large" placeholder="Headline" />
                          </Form.Item>
                          <MutedText
                            style={{
                              marginTop: '-20px',
                              marginLeft: '200px',
                              textAlign: 'right',
                            }}
                          >
                            Max 30 characters
                          </MutedText>
                        </div>
                        <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                      </div>
                    ))}
                    <AddANewButton onClick={() => add('')}>+ Add a new headline</AddANewButton>
                    <Form.ErrorList errors={errors} />
                  </LeftSide>
                  <div>
                    <AdSuggestionsSection title="Suggested Headlines">
                      <AdSuggestionsSubSection
                        title="Product Headlines"
                        description="Choose 3-4 headlines from this section"
                        loading={fetchingAdSuggestions}
                        options={adSuggestions.productHeadlines || []}
                        selected={selectedHeadlines()}
                        onSelect={(h) => {
                          console.log('on select', h);
                          removeByValue(remove, ['googleCampaign', 'ad', 'headlines'], '');
                          add(h);
                        }}
                        onDeselect={(h) => {
                          console.log('on deselect', h);
                          removeByValue(remove, ['googleCampaign', 'ad', 'headlines'], h);
                        }}
                      />
                      <AdSuggestionsSubSection
                        title="Offer Headlines"
                        description="Choose 5-6 headlines from this section"
                        loading={fetchingAdSuggestions}
                        options={adSuggestions.offerHeadlines || []}
                        selected={selectedHeadlines()}
                        onSelect={(h) => {
                          removeByValue(remove, ['googleCampaign', 'ad', 'headlines'], '');
                          add(h);
                        }}
                        onDeselect={(h) => {
                          removeByValue(remove, ['googleCampaign', 'ad', 'headlines'], h);
                        }}
                      />
                      <AdSuggestionsSubSection
                        title="Keywords being advertised"
                        description="Choose 1-2 headlines from this section"
                        loading={fetchingAdSuggestions}
                        options={selectedKeywords() || []}
                        selected={selectedHeadlines()}
                        onSelect={(h) => {
                          removeByValue(remove, ['googleCampaign', 'ad', 'headlines'], '');
                          add(h);
                        }}
                        onDeselect={(h) => {
                          removeByValue(remove, ['googleCampaign', 'ad', 'headlines'], h);
                        }}
                      />
                    </AdSuggestionsSection>
                  </div>
                </div>
              )}
            </Form.List>
          </Form.Item>
          <PinHeadlinesToggle account defaultChecked={true} />
          <Form.Item required name={['googleCampaign', 'ad']} label="">
            <Form.List
              name={['googleCampaign', 'ad', 'descriptions']}
              label="Descriptions"
              rules={[
                {
                  message: 'You must set between 2 or 4 descriptions',
                  type: 'array',
                  min: 2,
                  max: 4,
                },
              ]}
            >
              {(fields, { add, remove, move }, { errors }) => (
                <div className="form-container__row" style={{ display: 'flex', flexDirection: 'row', gap: '2em' }}>
                  <LeftSide>
                    <TitleWithInstructions
                      title="Descriptions"
                      instructions="Start your campaign with 2-4 descriptions."
                    />
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <div
                        key={`${name}_${key}`}
                        style={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'stretch',
                          gap: '1em',
                          minWidth: '300px',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Form.Item
                            required
                            name={[name]}
                            label=""
                            rules={[
                              {
                                required: true,
                                pattern: authorizedTextInAds,
                                min: 1,
                                max: 90,
                                message: 'Invalid Text',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const otherValues = (
                                    getFieldValue(['googleCampaign', 'ad', 'descriptions']) || []
                                  ).filter((s) => s === value);

                                  if (otherValues.length === 1) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject(new Error('Should be unique'));
                                },
                              }),
                            ]}
                          >
                            <Input.TextArea size="large" row={10} maxLength={90} placeholder="Description">
                              {' '}
                            </Input.TextArea>
                          </Form.Item>
                          <MutedText
                            style={{
                              marginTop: '-20px',
                              marginLeft: '200px',
                            }}
                          >
                            Max 90 characters
                          </MutedText>
                        </div>
                        <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(name)} />
                      </div>
                    ))}
                    <AddANewButton onClick={() => add('')}>+ Add a new description</AddANewButton>
                    <Form.ErrorList errors={errors} />
                  </LeftSide>
                  <div>
                    <AdSuggestionsSection title="Suggested Descriptions">
                      <AdSuggestionsSubSection
                        title="Descriptions"
                        description="Choose 2-4 from this section"
                        loading={fetchingAdSuggestions}
                        options={
                          adSuggestions.descriptions.map((description) =>
                            description.replace(/\w+/g, _.capitalize).replace(/\.$/, ''),
                          ) || []
                        }
                        selected={selectedDescriptions()}
                        onSelect={(h) => {
                          removeByValue(remove, ['googleCampaign', 'ad', 'descriptions'], '');
                          add(h);
                        }}
                        onDeselect={(h) => {
                          removeByValue(remove, ['googleCampaign', 'ad', 'descriptions'], h);
                        }}
                      />
                    </AdSuggestionsSection>
                  </div>
                </div>
              )}
            </Form.List>
          </Form.Item>
        </div>
      </Form>
    );
  }

  function AUTOMATIONS_FORM() {
    return (
      <div style={{ width: 750 }}>
        <CampaignAutomationsForm form={form5} currencyCode={currencyCode} />
      </div>
    );
  }

  function REVIEW_FORM() {
    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            width: '100%',
            gap: '3em',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ flex: '0 1 440px' }}>
            {s(campaign).product.type === TYPE_PRODUCT && (
              <>
                <h3>Amazon Product</h3>
                <Paragraph>
                  <Title>Product Title:</Title>
                  <Value>{s(s(campaign).product).name}</Value>
                </Paragraph>
                <Paragraph>
                  <Title>ASIN:</Title>
                  <Value>{s(s(campaign).product).externalId}</Value>
                </Paragraph>
              </>
            )}

            <Divider orientation="horizontal" />
            <h3>Campaign Settings</h3>
            <Paragraph>
              <Title>Campaign Name:</Title>
              <Value>{campaign.name}</Value>
            </Paragraph>
            <Paragraph>
              <Title>Location:</Title>
              <Value
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {' '}
                {(_.get(campaign, 'googleCampaign.geoCriteria', []) || [])
                  .filter((l) => !!l)
                  .map((l) => l.canonicalName)
                  .join(',') || 'All'}
              </Value>
            </Paragraph>
            <Paragraph>
              <Title>Budget:</Title>
              <Value>
                <CurrencyValue
                  amount={s(campaign.googleCampaign).budgetMicros}
                  currency={currencySymb}
                  unit={1000000}
                  suffix="/day"
                />
              </Value>
            </Paragraph>

            <Divider orientation="horizontal" />
            <h3>Set Up Ad Group</h3>
            <Paragraph>
              <Title>Keywords:</Title>
              <Value>
                {_.get(campaign, 'googleCampaign.adGroup.keywords', [])
                  .map((l) => l && l.text)
                  .join(', ')}
              </Value>
            </Paragraph>

            {isCampaignAutomationsEnabled && (
              <>
                <Divider orientation="horizontal" />
                <h3>{messages.campaignAutomations.shortTitle}</h3>
                {campaign?.automationRules?.keywordPauser && (
                  <>
                    <Paragraph>
                      <Title>{messages.campaignAutomations.keywordPauser.title}:</Title>
                      <Value>{getToggleLabel(campaign.automationRules.keywordPauser.enabled)}</Value>
                    </Paragraph>
                    {campaign.automationRules.keywordPauser.enabled === OPTIONS.ENABLED.value && (
                      <>
                        <Paragraph $leftIndent>
                          <Title>{messages.campaignAutomations.keywordPauser.maxAllowedAcosPerKw.title}:</Title>
                          <Value>{campaign.automationRules.keywordPauser.maxAllowedAcosPerKw}</Value>
                        </Paragraph>
                        <Paragraph $leftIndent>
                          <Title>{messages.campaignAutomations.keywordPauser.reviewPausedKeywords.title}:</Title>
                          <Value>{OPTIONS.ENABLED.label}</Value>
                        </Paragraph>
                        <Paragraph $leftIndent>
                          <Title>{messages.campaignAutomations.keywordPauser.maxAdSpendPerKw.title}:</Title>
                          <Value>
                            <CurrencyValue
                              amount={campaign.automationRules.keywordPauser.maxAdSpendPerKw}
                              unit={100}
                              currency={currencySymb}
                            />
                          </Value>
                        </Paragraph>
                        <Paragraph $leftIndent>
                          <Title>{messages.campaignAutomations.keywordPauser.guaranteedActiveKwCount.title}:</Title>
                          <Value>{campaign.automationRules.keywordPauser.guaranteedActiveKwCount}</Value>
                        </Paragraph>
                      </>
                    )}
                  </>
                )}
                {campaign?.automationRules?.automatedBidding && (
                  <Paragraph>
                    <Title>Automated Bidding:</Title>
                    <Value>{getToggleLabel(campaign.automationRules.automatedBidding.enabled)}</Value>
                  </Paragraph>
                )}
              </>
            )}
            <br />
            <br />
          </div>
        </div>
      </Fragment>
    );
  }

  if (loading) {
    return <SmallPlaceholder text="We are creating your campaign, please wait a few seconds" />;
  }

  return (
    <Style>
      <Wizard
        steps={wizardSteps}
        currentStep={0}
        tRef={wizardRef}
        stepsTitle="Campaign resume"
        okText="Launch"
        previousText="Back"
        nextText="Next"
        loading={loading}
        onComplete={async () => {
          await saveCampaign(campaign);
        }}
      />
      <div style={{ maxWidth: 400 }}>
        <PrettyPrintJson hide={true} data={campaign} />
      </div>
    </Style>
  );
}
