import { Alert as AntdAlert } from 'antd';
import styled from 'styled-components';

const StyledAlert = styled(AntdAlert)`
  .ant-alert-message,
  b {
    font-weight: 600;
  }
`;

export const Alert = (props) => <StyledAlert {...props} />;
