import React, { useState } from 'react';

import { Form, Select } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { sd } from 'lib/safe';

import { pixelsFromAccount } from '../../../../lib/pixels';

const Warning = styled.div`
  color: red;
`;

const BasePixelSelector = (props: { account: accountT, isGoogleCampaign: boolean }) => {
  const allPixels = pixelsFromAccount(props.account);
  const [selectedPixelIds, setSelectedPixelIds] = useState([]);
  const accessTokenRequired = allPixels
    .filter((pixel) => selectedPixelIds.includes(pixel.id))
    .some((pixel) => pixel.hasAccessToken === false);

  return (
    <>
      <Form.Item name="pixelsIds" label="Retargeting pixels" style={{ marginBottom: 0 }}>
        <Select
          size="large"
          mode="multiple"
          disabled={allPixels.length <= 0}
          showArrow={true}
          placeholder="Select a pixel"
          onChange={setSelectedPixelIds}
        >
          {sd(allPixels, []).map(({ id, value, providerKey }) => {
            if (props.isGoogleCampaign && providerKey !== 'fb' && providerKey !== 'tkt') {
              return (
                <Select.Option key={id} value={id} disabled>
                  {value}: Not Supported
                </Select.Option>
              );
            } else {
              return (
                <Select.Option key={id} value={id}>
                  {value}{' '}
                </Select.Option>
              );
            }
          })}
        </Select>
      </Form.Item>
      {accessTokenRequired && (
        <Warning>
          Access Token Required.
          <Link to={`/${props.account.id}/ab/settings/pixels`}> Go to Link Manager Settings </Link>
          to add access token
        </Warning>
      )}
    </>
  );
};

export const PixelSelector = (props: { account: accountT }) => (
  <BasePixelSelector account={props.account} isGoogleCampaign={false} />
);

export const GoogleCampaignPixelSelector = (props: { account: accountT }) => (
  <BasePixelSelector account={props.account} isGoogleCampaign={true} />
);
