import { connect } from 'react-redux';

import { AbstractStatsHeader } from './AbstractStatsHeader';

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  nbRedirects: state.abRedirects.nbRedirects,
  nbClicks: state.abRedirects.nbClicks,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({});

const reduxWrapper = connect(stateToProps, dispatchToProps);

export const StatsHeader = reduxWrapper(AbstractStatsHeader);
