import { useFeatureFlag as useConfigCat, User } from 'configcat-react';

import { getFeatureFlagUser } from './auth';

export const CAMPAIGN_ANALYTICS_UPDATE = 'CAMPAIGN_ANALYTICS_UPDATE';
export const SHOW_TACOS = 'SHOW_TACOS';
export const TRANSFER_PRODUCT = 'TRANSFER_PRODUCT';
export const PERFORMANCE_BOOSTER = 'PERFORMANCE_BOOSTER';
export const CLICK_TRACKER_TRANSPARENCY = 'CLICK_TRACKER_TRANSPARENCY';
export const GENERATE_DKI_SUGGESTIONS = 'GENERATE_DKI_SUGGESTIONS';
export const CAMPAIGN_AUTOMATIONS = 'CAMPAIGN_AUTOMATIONS';
export const GOOGLE_RMF = 'GOOGLE_RMF';
export const MANUAL_SYNC = 'MANUAL_SYNC';
export const HIDE_PRODUCT_METRICS = 'HIDE_PRODUCT_METRICS';
export const AGENCY_TOOLS = 'AGENCY_TOOLS';
export const HIDE_RESEARCH_ACCOUNT = 'HIDE_RESEARCH_ACCOUNT';
export const FORCED_APP_DOMAIN = 'FORCED_APP_DOMAIN';
export const ASIN_AUDIT = 'ASIN_AUDIT';
export const ASIN_AUDIT_MANAGE = 'ASIN_AUDIT_MANAGE';
export const ENABLE_AGGREGATED_PRODUCT_EXPORT = 'ENABLE_AGGREGATED_PRODUCT_EXPORT';
export const MIGRATE_GOOGLE_TOKEN = 'MIGRATE_GOOGLE_TOKEN';
export const FACEBOOK_ADS_UPGRADE = 'FACEBOOK_ADS_UPGRADE';
export const TIKTOK_ACCESS = 'TIKTOK_ACCESS';
export const ASIN_AUDITS_UPDATE = 'ASIN_AUDITS_UPDATE';
export const YOUTUBE_CAMPAIGNS = 'YOUTUBE_CAMPAIGNS';
export const AUTOMATIONS_KEYWORD_HARVESTING = 'AUTOMATIONS_KEYWORD_HARVESTING';
export const ENABLE_ASIN_SOLD_EXPORT = 'ENABLE_ASIN_SOLD_EXPORT';

/** The default value passed to configcat will be `false` unless specified here */
const defaultValueOverride = {};

/**
 * This is a wrapper func of `useFeatureFlag` from `configcat-react` that simplifies the
 * parameters by setting defaults
 * @returns [value, loading]
 * @link Feature flags are set here https://app.configcat.com/
 */
export const useFeatureFlag = (key: string, defaultValue?: any, user?: User): [any, boolean] => {
  const { value, loading } = useConfigCat(
    key,
    defaultValue ?? defaultValueOverride[key] ?? false,
    user ?? getFeatureFlagUser(),
  );

  return [value, loading];
};
