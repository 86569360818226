import './Input.css';
import React from 'react';
import type { Node } from 'react';

import { omit } from 'lodash';

export default function Input(props: {
  id: string,
  value: string,
  label?: Node,
  className?: string,
  handleRef?: (o: *) => void,
}) {
  const { id, handleRef, ...newProps } = props;

  return (
    <WithLabel {...newProps} id={id}>
      <input
        ref={handleRef}
        {...omit(newProps, 'isBig', 'displayLabel')}
        id={id}
        name={id}
        style={{ width: '100%', height: '100%' }}
        className="input"
        autoComplete="off"
      />
    </WithLabel>
  );
}

export function Textarea(props: { id: string, label?: Node, className?: string }) {
  const { id } = props;

  return (
    <WithLabel {...props}>
      <textarea {...props} name={id} className="input" />
    </WithLabel>
  );
}

function WithLabel(props: { id: string, children: Node, label?: Node, className?: string }) {
  const { children, label, className = '', id } = props;

  return (
    <div className={`${className} input-container`}>
      {label && (
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
      )}
      {children}
    </div>
  );
}
