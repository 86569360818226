import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { GlobalPlaceholder, SmallCompactPlaceholder, SmallPlaceholder } from '../components/common/Placeholders';

/**
 * Hook that allow to manage loading
 * @param defaultLoad
 * @returns {{ExclusiveLoadingContainer: (function(*): *), ExclusiveInlineLoadingContainer: (function(*): *), doAction: (function(*): *), loading: boolean}}
 */
export function useLoading(defaultLoad?: boolean) {
  const [loading, setLoading] = useState(defaultLoad);

  const doAction = useCallback((action) => {
    setLoading(true);

    if (!action) {
      console.error('Missing action, you might forgot async');
    }

    return action()
      .catch((e) => {
        console.error(e);

        throw e;
      })
      .finally(() => setLoading(false));
  }, []);

  const ExclusiveLoadingContainer = useMemo(
    () => (props: any) => {
      if (loading) {
        return <SmallPlaceholder text="Loading" />;
      }

      return <Fragment>{props.children}</Fragment>;
    },
    [loading],
  );

  const ExclusiveAccountLoadingContainer = useMemo(
    () => (props: any) => {
      if (loading) {
        return <GlobalPlaceholder loadingText="Loading account" />;
      }

      return <Fragment>{props.children}</Fragment>;
    },
    [loading],
  );

  const ExclusiveInlineLoadingContainer = useMemo(
    () => (props: any) => {
      if (loading) {
        return <SmallCompactPlaceholder text="" />;
      }

      return <Fragment>{props.children}</Fragment>;
    },
    [loading],
  );

  return {
    doAction,
    ExclusiveLoadingContainer,
    ExclusiveInlineLoadingContainer,
    ExclusiveAccountLoadingContainer,
    loading,
  };
}
