export const amazonSellerCentralUrls = {
  ATVPDKIKX0DER: 'https://sellercentral.amazon.com',
  A1AM78C64UM0Y8: 'https://sellercentral.amazon.com.mx',
  A2EUQ1WTGCTBG2: 'https://sellercentral.amazon.ca',
  A1PA6795UKMFR9: 'https://sellercentral-europe.amazon.com',
  A1RKKUPIHCS9HS: 'https://sellercentral-europe.amazon.com',
  A13V1IB3VIYZZH: 'https://sellercentral-europe.amazon.com',
  APJ6JRA9NG5V4: 'https://sellercentral-europe.amazon.com',
  A1805IZSGTT6HS: 'https://sellercentral.amazon.nl',
  A1C3SOZRARQ6R3: 'https://sellercentral.amazon.pl',
  A1F83G8C2ARO7P: 'https://sellercentral-europe.amazon.com',
  ARBP9OOSHTCHU: 'https://sellercentral.amazon.eg',
};
