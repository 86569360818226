import { useState, type Node } from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useQueryParams } from 'hooks/useQueryParams';
import { ReactComponent as C6Logo } from 'icons/c6.svg';
import { ReactComponent as PixelMeLogo } from 'icons/pixelme.svg';
import { ASIN_AUDIT, useFeatureFlag } from 'lib/configcat';
import { strings } from 'locales/strings';

import { AuditResultsView } from './AuditResultsView';
import { BookCallButton } from './BookCallButton';
import { BOOK_CALL_URLS, CONTAINER_PADDING } from './constants';
import { PageNotAvailable } from './PageNotAvailable';

const Style = styled.div`
  height: calc(100% - 70px);
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #f2f2f2;
  background-color: #fff;
  min-height: 70px;
  padding: 0.75rem ${CONTAINER_PADDING}px;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
`;

const PixelMeContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
`;

const BetaBadge = styled.span`
  background-color: #f8a51b;
  color: #fff;
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  height: fit-content;
  letter-spacing: 0.36px;
`;

const ContentContainer = styled.div`
  padding: ${CONTAINER_PADDING}px;
  overflow: auto;
  height: 100%;
  background-color: #fafafa;
`;

/** This page renders the Audit Results table */
export const AuditSharePage = (): Node => {
  const [isAsinAuditEnabled, isFlagLoading] = useFeatureFlag(ASIN_AUDIT);
  const [audit, setAudit] = useState();
  const query = useQueryParams();
  const auditId = query.get('id');
  const { messages } = useIntl();

  if (isFlagLoading) {
    return null;
  } else if (!isAsinAuditEnabled || !auditId) {
    return <PageNotAvailable />;
  }

  return (
    <Style>
      <Header>
        <LeftContainer>
          <C6Logo fill="black" />
          <PixelMeContainer>
            <PixelMeLogo fill="black" />
            <Title>{messages.asinAudit.share.pageTitle}</Title>
            <BetaBadge>{messages.global.beta}</BetaBadge>
          </PixelMeContainer>
        </LeftContainer>
        <BookCallButton
          contactInformation={audit?.contactInformation}
          auditId={auditId}
          asins={audit?.results?.map((x) => x.product?.asin)}
          url={BOOK_CALL_URLS.PROSPER}
        />
      </Header>
      <ContentContainer>
        <AuditResultsView isPublic prevAudit={audit} setPrevAudit={setAudit} />
      </ContentContainer>
    </Style>
  );
};
