import React, { Component } from 'react';

import Tooltip from 'antd/lib/tooltip';
import _ from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';

import { Actions } from 'actions';
import {
  CopiableCellChildrenContainer,
  CopiableCellContainer,
  CopiableCellSortIndicator,
  CopiableCellSortIndicatorContainer,
} from 'components/common/CopiableCell';
import { AffixedInfoCircleFilled } from 'components/common/icons/AffixedInfoCircleFilled';
import { Down } from 'components/common/icons/Down';
import { Up } from 'components/common/icons/Up';
import { isSortedByColumnAndAsc, isSortedByColumnAndDesc, isVisible } from 'lib/columns';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';
import { store } from 'stores';
import { addNotice } from 'stores/notices';

const defaultState: internalStateT = {};

/**
 * Cell content
 *
 * Properties:
 * sortable: if true, it display the sort icons
 * sortKey: optional key that will be send to the backend on the click on sort icon example: pixels
 * textToCopy: optional text to copy on click on the cell
 * config: all columns configuration (allow to know if we must to display or not the cell, the position of the cell ...) type: columnsConfigT example: state.front.campaignColumnsConfig
 * children: Node content of the cell
 *
 * Actions:
 * toggleSort: method to call on sort toggleSort(columnName, lastSort)
 **/

class AbstractCopiableCell extends Component<internalPropsT, internalStateT> {
  state = defaultState;

  render() {
    const { sortable, sortKey, textToCopy, children, toggleSort, className, name, tooltip, color } = this.props;

    if (sortable && !sortKey) {
      throw new Error('Sortable cells must provide sortKey', this.props);
    }

    if (isVisible(this.props)) {
      return (
        <CopiableCellContainer key={name} className={className} color={color}>
          {textToCopy && (
            <CopyToClipboard
              text={textToCopy}
              onCopy={() => {
                if (textToCopy) {
                  store.dispatch(
                    addNotice({
                      message: `${textToCopy} ${getIntlStrings('action.copied')}`,
                    }),
                  );
                }
              }}
            >
              <CopiableCellChildrenContainer color={color}>{children}</CopiableCellChildrenContainer>
            </CopyToClipboard>
          )}
          {!textToCopy && (
            <CopiableCellChildrenContainer onClick={sortable ? toggleSort : undefined}>
              {children}
            </CopiableCellChildrenContainer>
          )}
          {!!tooltip && (
            <Tooltip title={tooltip}>
              <div>
                <AffixedInfoCircleFilled />
              </div>
            </Tooltip>
          )}
          {!!sortable && this.getSort()}
        </CopiableCellContainer>
      );
    } else {
      return <></>;
    }
  }

  getSort() {
    const { sortKey, config, sortAsc, sortDesc } = this.props;
    const isAsc = isSortedByColumnAndAsc(sortKey, config.sort);
    const isDesc = isSortedByColumnAndDesc(sortKey, config.sort);

    return (
      <CopiableCellSortIndicatorContainer>
        <CopiableCellSortIndicator state={isAsc}>
          <Up onClick={sortAsc} />
        </CopiableCellSortIndicator>
        <CopiableCellSortIndicator state={isDesc}>
          <Down onClick={sortDesc} />
        </CopiableCellSortIndicator>
      </CopiableCellSortIndicatorContainer>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => {
  const config = state.front.audienceBuilder.campaigns.columnsConfig;

  return { config };
};

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  sortAsc: () =>
    dispatch(
      Actions.api.audienceBuilder.redirect.sort.request({
        columnName: ownProps.sortKey,
        direction: 'asc',
      }),
    ),
  sortDesc: () =>
    dispatch(
      Actions.api.audienceBuilder.redirect.sort.request({
        columnName: ownProps.sortKey,
        direction: 'desc',
      }),
    ),
  toggleSort: () =>
    dispatch(
      Actions.api.audienceBuilder.redirect.sort.request({
        columnName: ownProps.sortKey,
      }),
    ),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) =>
  _.isEqual(prev.front.campaignColumnsConfig, next.front.campaignColumnsConfig);

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const RedirectsListLineCell = reduxWrapper(AbstractCopiableCell);
