import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import { isARealCampaignId } from 'lib/campaigns';

import { ALL_REDIRECTS } from '../../../../../consts/consts';

type internalPropsT = { selectCampaign: Function };
type internalStateT = {};
const defaultState: internalStateT = {};

class CompaignNameNoStyle extends Component<internalPropsT, internalStateT> {
  state = defaultState;

  render() {
    const { campaign, subCampaign, selectCampaign, className, selectedCampaignId, selectedSubCampaignId, renderIcon } =
      this.props;
    const isSelected = subCampaign
      ? _.isEqual(selectedSubCampaignId, subCampaign.id) && _.isEqual(selectedCampaignId, campaign.id)
      : _.isEqual(selectedCampaignId, campaign.id) && !isARealCampaignId(selectedSubCampaignId);

    return (
      <span
        className={className}
        style={{
          color: isSelected ? 'var(--pixelme-color)' : '',
        }}
        onClick={() => selectCampaign(campaign.id, subCampaign ? subCampaign.id : ALL_REDIRECTS)}
        title={subCampaign ? subCampaign.name : campaign.name}
      >
        <span>{subCampaign ? subCampaign.name : campaign.name}</span>

        {!!renderIcon && (
          <span
            style={{
              marginLeft: '5px',
              verticalAlign: 'middle',
            }}
          >
            {renderIcon()}
          </span>
        )}
      </span>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  selectedCampaignId: state.campaigns.selectedCampaignId,
  selectedSubCampaignId: state.campaigns.selectedSubCampaignId,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  selectCampaign: (campaignId, subCampaignId) =>
    dispatch(
      Actions.front.audienceBuilder.campaigns.select.request({
        campaignId,
        subCampaignId,
      }),
    ),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) =>
  _.isEqual(prev.campaigns.selectedCampaignId, next.campaigns.selectedCampaignId) &&
  _.isEqual(prev.campaigns.selectedSubCampaignId, next.campaigns.selectedSubCampaignId);

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

const ConnectedCompaignNameNoStyle = reduxWrapper(CompaignNameNoStyle);

export const CampaignName = styled(ConnectedCompaignNameNoStyle).withConfig({
  displayName: 'CampaignName',
})`
  color: #071629;
  cursor: pointer;
  user-select: none;
  max-width: 189px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  font-size: ${(props) => (props.big ? '16px' : 'auto')};
`;

export const SubCampaignName = styled(ConnectedCompaignNameNoStyle).withConfig({
  displayName: 'SubCampaignName',
})`
  font-size: 12px;
  color: #828282;
  cursor: pointer;
  user-select: none;
  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
`;
