import { message, notification } from 'antd';
import produce from 'immer';

import { trackedEvents } from 'config/trackedEvents.config';
import { track } from 'lib/analytics';

import { apiSlice, TAGS } from './apiSlice';

const { CAMPAIGNS, CAMPAIGN_METRICS } = TAGS;

// $FlowFixMe
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCampaignsForProduct: builder.query({
      query: ({ accountId, from, to, with: withParams, providerId, productASIN }) => ({
        url: `product-providers/${providerId}/products/${productASIN}/campaigns`,
        method: 'get',
        params: { accountId, from, to, with: withParams },
      }),
      providesTags: (result, error, { productASIN }) =>
        result?.campaigns
          ? [...result.campaigns.map(({ id }) => ({ type: CAMPAIGNS, id })), { type: CAMPAIGNS, id: productASIN }]
          : [{ type: CAMPAIGNS, id: productASIN }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          message.error('Campaigns could not be loaded');
        });
      },
    }),
    getCampaign: builder.query({
      query: ({ accountId, campaignId }) => ({
        url: `product-providers/-/products/-/campaigns/${campaignId}`,
        method: 'get',
        params: { accountId },
      }),
      providesTags: (result, error, { campaignId }) => [{ type: CAMPAIGNS, id: campaignId }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          message.error('Campaign could not be loaded');
        });
      },
    }),
    getCampaignMetrics: builder.query({
      query: ({ accountId, campaignId }) => ({
        url: `product-providers/-/products/-/campaigns/${campaignId}/refresh-metrics`,
        method: 'get',
        params: { accountId },
      }),
      providesTags: (result, error, { campaignId }) => [{ type: CAMPAIGN_METRICS, id: campaignId }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          message.error('Campaign metrics could not be loaded');
        });
      },
    }),
    saveCampaignOnProductAndProvider: builder.mutation({
      query: ({ accountId, providerId, productId, campaign }) => {
        const payload = campaign?.automationRules?.keywordPauser
          ? produce(campaign, (draft) => {
              campaign.automationRules.keywordPauser.reviewPausedKeywords = true;
            })
          : campaign;

        return {
          url: `product-providers/${providerId}/products/${productId}/campaigns`,
          method: 'post',
          params: { accountId },
          data: { campaign },
        };
      },
      invalidatesTags: (result, error, { productId }) => [{ type: CAMPAIGNS, id: productId }],
      onQueryStarted: ({ campaign }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Campaign saved');
            track(trackedEvents.addCampaign, { campaign });
          })
          .catch((e) => {
            notification.error({
              duration: 30,
              message: e.error?.data?.errorMessage || 'Campaign could not be saved',
            });
          });
      },
    }),
    editCampaign: builder.mutation({
      query: ({ accountId, campaign, campaignId }) => {
        const payload = campaign?.automationRules?.keywordPauser
          ? produce(campaign, (draft) => {
              campaign.automationRules.keywordPauser.reviewPausedKeywords = true;
            })
          : campaign;

        return {
          url: `product-providers/-/products/-/campaigns/${campaignId}`,
          method: 'patch',
          params: { accountId },
          data: payload,
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: CAMPAIGNS, id }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Campaign updated');
          })
          .catch(() => {
            message.error('Campaign could not be updated');
          });
      },
    }),
    deleteCampaign: builder.mutation({
      query: ({ accountId, campaignId }) => ({
        url: `product-providers/-/products/-/campaigns/${campaignId}`,
        method: 'delete',
        params: { accountId },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CAMPAIGNS, id }],
      onQueryStarted: ({ campaignId }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Campaign deleted');
            track(trackedEvents.deleteCampaign, { campaignId });
          })
          .catch(() => {
            message.error('Campaign could not be deleted');
          });
      },
    }),
    runCampaign: builder.mutation({
      query: ({ accountId, campaignId }) => ({
        url: `product-providers/-/products/-/campaigns/${campaignId}/run`,
        method: 'put',
        params: { accountId },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CAMPAIGNS, id }],
      onQueryStarted: ({ campaignId }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Campaign launched');
          })
          .catch(() => {
            message.error('Campaign could not be launched');
          });
      },
    }),
    pauseCampaign: builder.mutation({
      query: ({ accountId, campaignId }) => ({
        url: `product-providers/-/products/-/campaigns/${campaignId}/pause`,
        method: 'put',
        params: { accountId },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: CAMPAIGNS, id }],
      onQueryStarted: ({ campaignId }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Campaign paused');
          })
          .catch(() => {
            message.error('Campaign could not be paused');
          });
      },
    }),
  }),
});

export const {
  useGetAllCampaignsForProductQuery,
  useGetCampaignQuery,
  useGetCampaignMetricsQuery,
  useSaveCampaignOnProductAndProviderMutation,
  useEditCampaignMutation,
  useDeleteCampaignMutation,
  useRunCampaignMutation,
  usePauseCampaignMutation,
} = extendedApiSlice;
