// eslint-disable-next-line no-extend-native
Array.prototype.move = function (element, offset) {
  const index = this.indexOf(element);

  const newIndex = index + offset;

  if (newIndex > -1 && newIndex < this.length) {
    const removedElement = this.splice(index, 1)[0];

    this.splice(newIndex, 0, removedElement);
  }
};
