import './Logo.css';
import React from 'react';

function NewLogoIcon(props) {
  return (
    <div className="logo" {...props}>
      <svg width="52" height="52" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2996_16187)">
          <path d="M176 103V176H103L23 256H256V23L176 103Z" fill="black" />
          <path d="M80 153V80H153L233 0H0V233L80 153Z" fill="black" />
        </g>
        <defs>
          <clipPath id="clip0_2996_16187">
            <rect width="256" height="256" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export default NewLogoIcon;
