import React, { Component } from 'react';

import _ from 'lodash';
import styled from 'styled-components';

import { getStringsKey } from 'lib/modalConfig';
import { LocaleContext } from 'locales/LocalizationWrapper';

import { LargeModalForRedux } from '../../../../common/ModalForRedux';

const InputLabel = styled.div.withConfig({
  displayName: 'InputLabel',
})`
  text-align: left;
  width: 100%;
  margin-bottom: 7px;
  font-size: 12px;
`;

const CategoryNameInput = styled.input
  .withConfig({
    displayName: 'CategoryNameInput',
  })
  .attrs({
    type: 'text',
  })`
    border: 1px solid #E5E5E5;
    padding: 5px;
    width: 100%;
`;

type internalStateT = {
  config: { open: boolean, parent: campaignT, campaign: object },
};
const defaultState: internalStateT = {};

type internalPropsT = {
  config: { open: boolean, campaign: campaignT },
  actionSuccess: Object,
  actionCancel: Object,
  mode: 'edit' | 'add' | 'delete',
};

export class CampaignModal extends Component<internalPropsT, internalStateT> {
  state = defaultState;
  static contextType = LocaleContext;

  handleChangeName(e: Event) {
    const { value } = e.target;
    const config = {
      ...this.props.config,
      campaignOrSub: { ...this.state.config.campaignOrSub, name: value },
    };

    this.setState({ config });
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(nextProps, this.props)) {
      this.setState({ config: nextProps.config });
    }
  }

  actionCancel() {
    return this.props.actionCancel(this.state.config);
  }
  actionSuccess() {
    return this.props.actionSuccess(this.state.config);
  }

  render() {
    const { config, mode } = this.props;
    const { getIntlStrings } = this.context;

    return (
      <LargeModalForRedux
        okOnly={true}
        okMessage={getIntlStrings(`${getStringsKey(config)}.${mode}.button`)}
        actionCancel={this.actionCancel.bind(this)}
        actionSuccess={this.actionSuccess.bind(this)}
        config={config}
        title={getIntlStrings(`${getStringsKey(config)}.${mode}.title`)}
      >
        <>
          {!!this.state.config && this.state.config.campaignOrSub && (
            <>
              <span style={{ textAlign: 'left' }}>
                <InputLabel>{getIntlStrings(`${getStringsKey(config)}.name`)}</InputLabel>
                <CategoryNameInput
                  disabled={mode === 'delete'}
                  value={this.state.config.campaignOrSub.name}
                  onChange={this.handleChangeName.bind(this)}
                />
              </span>
            </>
          )}
        </>
      </LargeModalForRedux>
    );
  }
}
