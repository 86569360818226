import React, { Component } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

import { s } from 'lib/safe';
import { addNotice } from 'stores/notices';

import { Actions } from '../../../actions';
import { Title, Url } from '../../pages/AudienceBuilder/Campaigns/campaigns/RedirectsListLineElements';
import { Favicon } from '../FavIcon';
import Modal from '../Modal';

type internalPropsT = {
  open: boolean,
  account: accountT,
  redirect: redirectT,
  campaigns: Array<campaignT>,
};

type internalActionsT = {
  handleClose: (void) => void,
  addNotice: (any) => void,
  deleteRedirects: (Array<string>) => void,
};

const initialState = {
  callingDelete: false,
  errorMessage: '',
};

const DeleteRedirectsListContainer = styled.div.withConfig({
  displayName: 'DeleteRedirectsListContainer',
})`
  overflow-y: auto;
  max-height: 400px;
`;

const DeleteRedirectsLineContainer = styled.div.withConfig({
  displayName: 'DeleteRedirectsLineContainer',
})`
  display: flex;
  grid-auto-columns: min-content;
  cursor: pointer;
  width: fit-content;
  min-width: 100%;
  border-bottom: var(--border-size) solid #e5e5e5;
  div {
    height: 40px;
    margin: 5px;
  }
`;

class DeleteModal extends Component<
  internalActionsT & internalActionsT,
  {
    callingDelete: boolean,
    errorMessage?: string,
  },
> {
  constructor(props: internalPropsT & internalActionsT) {
    super(props);
    this.state = initialState;
  }

  handleDelete = () => {
    const { redirect, deleteRedirect } = this.props;

    this.setState({ callingDelete: true });
    deleteRedirect([redirect.id]);
    this.setState(initialState);
  };
  handleDeleteSelected = () => {
    const { deleteRedirect, selectedRedirects } = this.props;

    this.setState({ callingDelete: true });
    deleteRedirect(selectedRedirects.filter((redirect) => !!redirect).map((redirect) => redirect.id));
    this.setState(initialState);
  };

  render() {
    const { selectedRedirects, open, handleClose } = this.props;
    const { callingDelete } = this.state;

    if (!open || !selectedRedirects) {
      return null;
    }

    if (selectedRedirects) {
      return (
        <Modal
          handleCancel={() => {
            handleClose();
          }}
          handleOk={this.handleDeleteSelected}
          disabled={callingDelete}
          okMessage={`Delete ${selectedRedirects.length} redirects`}
        >
          Delete your redirects?
          <DeleteRedirectsListContainer>
            {selectedRedirects
              .filter((redirect) => !!redirect)
              .map((redirect) => (
                <DeleteRedirectsLineContainer key={redirect.id}>
                  <Title>
                    <Favicon
                      referrerPolicy="no-referrer"
                      src={`${s(s(window).env).REACT_APP_ICON_URL}/?url=${encodeURIComponent(redirect.url)}&size=32`}
                    />
                    <span title={redirect.title}> {redirect.title}</span>
                  </Title>
                  <Url>
                    {' '}
                    <span title={redirect.trackUrl}>{redirect.trackUrl}</span>
                  </Url>
                </DeleteRedirectsLineContainer>
              ))}
          </DeleteRedirectsListContainer>
        </Modal>
      );
    }
  }
}

const mapStateToProps = function (state): {} {
  return {
    open: state.front.deleteRedirectModalConfig.open,
    redirect: state.front.deleteRedirectModalConfig.redirect,
    selectedRedirects: state.campaigns.selectedRedirects,
  };
};
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  handleClose: () => dispatch(Actions.front.audienceBuilder.redirect.delete.hide.request()),
  addNotice: (payload) => dispatch(addNotice(payload)),
  deleteRedirect: (redirectsIds) => dispatch(Actions.api.audienceBuilder.redirect.delete.request({ redirectsIds })),
});

export default connect(mapStateToProps, dispatchToProps)(DeleteModal);
