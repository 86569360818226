import React from 'react';

import { useSelector } from 'react-redux';

import { s, sn } from '../../../../lib/safe';
import AudienceBuilderRedirectCreator from '../../AudienceBuilder/Campaigns/RedirectCreator';

export function PageLinkEditor() {
  const { needsCard } = useSelector((states) => {
    const planID = sn(states, 'subscription.sub.plan.id', '');

    return {
      needsCard: !sn(states, 'subscription.card.name', '') && !planID.startsWith('lifetimedeal_'),
    };
  });

  return <AudienceBuilderRedirectCreator cardRegistered={!needsCard} />;
}
