import './Switch.css';
import React from 'react';

function Switch({
  position,
  handleClick,
  withShadow,
}: {
  position: 'right' | 'left',
  handleClick?: () => void,
  withShadow?: boolean,
}) {
  return (
    <div
      onClick={handleClick && handleClick}
      className={`switch-container ${withShadow ? 'switch-container--withShadow' : ''}`}
    >
      {position === 'right' && <Right />}
      {position === 'left' && <Left />}
    </div>
  );
}

function Right() {
  return (
    <svg className="switch" width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24.3201" height="12" transform="translate(0 2)" fill="white" />
      <ellipse cx="7.71757" cy="8" rx="7.71757" ry="8" transform="translate(15.5649)" fill="#2E89E2" />
    </svg>
  );
}

function Left() {
  return (
    <svg className="switch" width="31" height="16" viewBox="0 0 31 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="12" transform="translate(6 2)" fill="white" />
      <ellipse cx="7.93333" cy="8" rx="7.93333" ry="8" fill="#2E89E2" />
    </svg>
  );
}

export default Switch;
