import React from 'react';

export function Google() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none" viewBox="0 0 16 16">
      <circle cx="8" cy="8" r="8" fill="#041521"></circle>
      <path
        fill="#fff"
        d="M4.058 6.368V5.162h3.115v2.852c-.303.293-.742.551-1.319.776a4.801 4.801 0 01-1.743.332c-.748 0-1.401-.156-1.958-.469A3.012 3.012 0 01.898 7.306a4.37 4.37 0 01-.42-1.91c0-.745.157-1.407.47-1.987.312-.58.77-1.024 1.371-1.333.46-.237 1.03-.356 1.714-.356.889 0 1.582.187 2.08.561.502.371.824.886.967 1.543l-1.435.269a1.5 1.5 0 00-.572-.83c-.276-.205-.623-.308-1.04-.308-.631 0-1.134.2-1.509.6-.37.401-.556.995-.556 1.783 0 .85.189 1.488.566 1.914.378.423.873.635 1.485.635a2.5 2.5 0 00.908-.176c.306-.12.568-.265.786-.435v-.908H4.058z"
        transform="translate(4 2.5)"
      ></path>
    </svg>
  );
}
