import React from 'react';

import IconContainer from './IconContainer';

export function Down(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L3 3" stroke="#828282" strokeLinecap="round" />
        <path d="M3 3L5 1" stroke="#828282" strokeLinecap="round" />
      </svg>
    </IconContainer>
  );
}
