import React from 'react';

import { MutedText } from '../MutedText';

export function AutomaticPlacementsPlaceholder() {
  return (
    <MutedText>
      Use automatic placements to maximize your budget and help show your ads to more people. Facebook's delivery system
      will allocate your ad set's budget across multiple placements based on where they're likely to perform best.
    </MutedText>
  );
}
