import { MIGRATE_GOOGLE_TOKEN, useFeatureFlag } from 'lib/configcat';

export const authorizedTextInAds = new RegExp(/^[0-9\p{L} $&€%=+\\'.;:"/?><,-]+$/iu);
export const authorizedTextInKeywords = new RegExp(/^[0-9\p{L} ]+$/iu);
export const authorizedTextInKeywordsWithNewline = new RegExp(/^[0-9\p{L} \n]+$/iu);

export const useGetShouldReconnect = (): ((provider: adsProviderT) => boolean) => {
  const [migrateGoogleTokenEnabled] = useFeatureFlag(MIGRATE_GOOGLE_TOKEN);

  return (p) => p.loginNeeded || (migrateGoogleTokenEnabled && p.migrateGoogleToken);
};

export const ADS_PROVIDERS = {
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  TIKTOK: 'TIKTOK',
  GENERIC: 'GENERIC',
};
