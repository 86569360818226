// @flow
import React, { type Node } from 'react';

import styled from 'styled-components';

import AccountsFilterProvider from './AccountsFilterProvider';
import { AccountsTable } from './AccountsTable';
import { AccountSwitcher } from './AccountSwitcher.jsx';
import { Filters } from './Filters';

const Style = styled.div`
  background-color: white;
  padding: 1em;
  height: 100%;
`;

type propsT = {
  account: accountT,
};
export const AccountsOverview = ({ account }: propsT): Node => {
  return (
    <Style>
      <AccountsFilterProvider>
        <Filters />
        <AccountsTable account={account} />
        <AccountSwitcher />
      </AccountsFilterProvider>
    </Style>
  );
};
