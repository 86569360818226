import React, { Fragment } from 'react';

import { CurrencyValue } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';

/**
 * Display Brand referral bonus value
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function BrbValue(props: {
  currencyCode: string,
  currencySymbol: string,
  brbBonusAmountCalculated: number,
  brbBonusAmount: number,
}) {
  return (
    <Fragment>
      {/* props.brbEnrolled  === undefined => non US Marketplace so we display the calculated Amount that is inside brbBonusAmount*/}
      {/* TODO change brbBonusAmountCalculated to brbBonusAmount when we will have the explanation from Amazon*/}
      {props.brbEnrolled !== false && (
        <CurrencyValue amount={props.brbBonusAmountCalculated} currency={props.currencySymbol} suffix="" unit="" />
      )}
      {props.brbEnrolled === false && (
        <BrbNotOnboarded
          currencyCode={props.currencyCode}
          brbBonusAmountCalculated={props.brbBonusAmountCalculated}
        ></BrbNotOnboarded>
      )}
    </Fragment>
  );
}

/**
 * Display Warning message if the seller is not enrolled in brand referral bonus program.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function BrbNotOnboarded(props: { brbBonusAmountCalculated: number, currencyCode: string, style: any }) {
  const formatedBrbValue = BrbValue.formatCurrency(props.brbBonusAmountCalculated, props.currencyCode);

  return (
    <Fragment>
      <a
        href="https://www.carbon6.io/pixelme-help/enroll-in-brand-referral-bonus-program-3"
        style={{ display: 'flex', alignItems: 'center' }}
        target="_blank"
        rel="noreferrer"
      >
        <div style={{ textAlign: 'center', fontSize: '12px', color: '#000000', ...(props.style || {}) }}>
          Losing {formatedBrbValue}, Enroll Brand Referral Program
        </div>
      </a>
    </Fragment>
  );
}

BrbValue.formatCurrency = function (value: number, currencyCode: string) {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: currencyCode,
  });
};
