import styled from 'styled-components';

export const StatsTitle = styled.span.withConfig({
  displayName: 'StatsTitle',
})`
  color: black;
  font-size: 14px;
  margin-bottom: 6px;
  text-transform: capitalize;
`;
