import React, { useEffect, useState } from 'react';

import { Table, Tabs } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { getProductProviderReports } from '../../../lib/adsPlateformApi';
import { MutedText } from '../../common/MutedText';
import { SmallPlaceholder } from '../../common/Placeholders';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;
  width: 100%;
  padding: 1em;
  .ant-table {
    width: 100%;
    overflow: auto;
  }
`;

export function AmazonReportTable(props: { accountId: string, match: Match }) {
  const { productProviderId } = props.match.params;
  const [loading, setLoading] = useState(false);
  const [reportsLinkedColumns, setReportsLinkedColumns] = useState([]);
  const [reportsNotLinkedColumns, setReportsNotLinkedColumns] = useState([]);
  const [reportsLinked, setReportsLinked] = useState([]);
  const [reportsNotLinked, setReportsNotLinked] = useState([]);

  async function load() {
    setLoading(true);

    try {
      const data = await getProductProviderReports(
        localStorage,
        axios,
        { accountId: props.accountId, rangeId: 'LAST_30D' },
        productProviderId,
      );

      setReportsLinked(_.get(data, 'reportsLinkedToRedirects.reports'));
      setReportsNotLinked(_.get(data, 'reportsNotLinkedToRedirects.reports'));
      const columnsReportsLinked = Object.keys(_.get(data, 'reportsLinkedToRedirects.reports', [])[0] || []).map(
        (k) => ({
          title: k,
          dataIndex: k,
          key: k,
        }),
      );

      setReportsLinkedColumns(columnsReportsLinked);
      const columnsReportsNotLinked = Object.keys(_.get(data, 'reportsNotLinkedToRedirects.reports', [])[0] || []).map(
        (k) => ({
          title: k,
          dataIndex: k,
          key: k,
        }),
      );

      setReportsNotLinkedColumns(columnsReportsNotLinked);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      throw e;
    }
  }

  useEffect(() => {
    if (productProviderId) {
      load().then();
    }
  }, [productProviderId]);

  if (loading) {
    return <SmallPlaceholder />;
  }

  return (
    <Style>
      <Tabs>
        <Tabs.TabPane tab="Amazon Data Linked to PixelMe" key="1">
          <MutedText>Raw attribution data linked to PixelMe</MutedText>
          <Table dataSource={reportsLinked} columns={reportsLinkedColumns} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Amazon Data Not Linked to PixelMe" key="2">
          <MutedText>Raw attribution data that we were not able to link to your links or products</MutedText>
          <Table dataSource={reportsNotLinked} columns={reportsNotLinkedColumns} />
        </Tabs.TabPane>
      </Tabs>
    </Style>
  );
}
