import React, { useEffect } from 'react';

import { useImmer } from 'use-immer';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { getDefaultRange } from 'selectors/ranges.selector';

import { DEFAULT_COLUMNS } from './constants';

export const AccountsFilterContext = React.createContext();

const AccountsFilterProvider = ({ children }) => {
  const [columnsCache, setColumnsCache] = useLocalStorage('accounts-columns', DEFAULT_COLUMNS);
  const [ctx, updateCtx] = useImmer({
    dateRange: getDefaultRange(),
    columns: columnsCache,
    campaignStatus: null,
  });
  const value = { ctx, updateCtx };

  useEffect(() => {
    setColumnsCache(ctx.columns);
  }, [ctx.columns]);

  return <AccountsFilterContext.Provider value={value}>{children}</AccountsFilterContext.Provider>;
};

export default AccountsFilterProvider;
