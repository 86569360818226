import { useState, type Node } from 'react';

import { LinkOutlined } from '@ant-design/icons';
import { Button, Modal, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TagInput } from 'components/common/antd/TagInput';
import { validateEmail } from 'lib/util';
import { useLocale } from 'locales/LocalizationWrapper';
import { useSendAuditMutation } from 'stores/auditsSlice';

const InputContainer = styled.div`
  display: flex;
  column-gap: 0.75rem;

  button {
    font-size: 0.875rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  button {
    padding: 0 0.5rem;
    color: var(--text-muted);
    font-weight: 500;
  }
`;

type propsT = {
  visible: boolean,
  onCancel: () => void,
  auditId: string,
};
export const ShareModal = ({ visible, onCancel, auditId, ...props }: propsT): Node => {
  const [emails, setEmails] = useState([]);
  const [sendAudit] = useSendAuditMutation();
  const { messages } = useIntl();
  const { getIntlStrings } = useLocale();

  const shareUrl = `${window.env.REACT_APP_BASE_URL}/audit/share?id=${auditId}`;

  const handleShare = async () => {
    const validEmails = emails.filter(validateEmail);

    if (validEmails.length > 0) {
      try {
        await sendAudit({ id: auditId, email: validEmails }).unwrap();
        const successMessage =
          validEmails.length === 1
            ? `${messages.asinAudit.share.sentTo} ${validEmails[0]}`
            : getIntlStrings('asinAudit.share.sentSuccess', { numOfEmails: validEmails.length });

        message.success(successMessage);
      } catch (err) {
        message.error(messages.error.apiError);
      }
    }
  };

  return (
    <Modal
      title={messages.asinAudit.share.title}
      visible={visible}
      onCancel={onCancel}
      footer={
        <ButtonContainer>
          <CopyToClipboard text={shareUrl} onCopy={() => message.success(messages.asinAudit.share.clipboard)}>
            <Button type="text" icon={<LinkOutlined />}>
              {messages.asinAudit.share.copyLink}
            </Button>
          </CopyToClipboard>
        </ButtonContainer>
      }
      destroyOnClose // Allows input to auto focus on subsequent opens
      {...props}
    >
      <p>{messages.asinAudit.share.inputLabel}</p>
      <InputContainer>
        <TagInput
          value={emails}
          onChange={setEmails}
          placeholder={messages.asinAudit.share.inputPlaceholder}
          icon={null}
          autoFocus
          validate={validateEmail}
        />
        <Button type="primary" size="large" onClick={handleShare}>
          {messages.asinAudit.share.submit}
        </Button>
      </InputContainer>
    </Modal>
  );
};
