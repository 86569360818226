import { trackedEvents } from 'config/trackedEvents.config';
import { track } from 'lib/analytics';
import {
  addCoupon,
  getBankAccount,
  getCard,
  getVAT,
  postBankAccount,
  postCard,
  postDefaultCard,
  subscribe,
  trial,
} from 'lib/api';
import { gtag_report_conversion_subscribe } from 'lib/ga_conv';

import { updateAccountLimitations, updateUsageThunk } from './accounts';
import { addNotice } from './notices';
import { getOrgCoupon } from '../lib/api';

type stateT = {
  sub?: subscriptionT,
  plans?: Array<planT>,
  card?: cardT,
  bankAccount?: bankAccountT,
  orgCoupon?: orgCouponT,
  vat?: vatT,
  updating: boolean,
  cardUpdating: boolean,
  bankAccountUpdating: boolean,
  vatUpdating: boolean,
  orgCouponLoading: false,
  cardError: string | null,
};

// ACTIONS
export type actionT =
  | {
      type: 'SUB_UPDATE_SUB',
      sub: subscriptionT,
    }
  | {
      type: 'SUB_UPDATE_PLANS',
      plans: Array<planT>,
    }
  | {
      type: 'SUB_UPDATE_CARD',
      card: cardT,
    }
  | {
      type: 'SET_ORG_COUPON',
      orgCoupon: orgCouponT,
    }
  | {
      type: 'SUB_UPDATE_BANK_ACCOUNT',
      bankAccount: bankAccountT,
    }
  | {
      type: 'SUB_UPDATE_VAT',
      vat: vatT,
    }
  | {
      type: 'SUB_UPDATING',
      updating: boolean,
    }
  | {
      type: 'SUB_CARD_UPDATING',
      updating: boolean,
    }
  | {
      type: 'SUB_ORG_COUPON_LOADING',
      loading: boolean,
    }
  | {
      type: 'SUB_BANK_ACCOUNT_UPDATING',
      updating: boolean,
    }
  | {
      type: 'SUB_VAT_UPDATING',
      updating: boolean,
    };

export function updateSub({ sub }: { sub: subscriptionT }): actionT {
  return {
    type: 'SUB_UPDATE_SUB',
    sub,
  };
}

export function updatePlans({ plans }: { plans: Array<planT> }): actionT {
  return {
    type: 'SUB_UPDATE_PLANS',
    plans,
  };
}

export function updateCard({ card }: { card: cardT }): actionT {
  return {
    type: 'SUB_UPDATE_CARD',
    card,
  };
}

export function setOrgCoupon(orgCoupon: orgCouponT | null): actionT {
  return {
    type: 'SET_ORG_COUPON',
    orgCoupon,
  };
}

export function updateBankAccount({ bankAccount }: { bankAccount: bankAccountT }): actionT {
  return {
    type: 'SUB_UPDATE_BANK_ACCOUNT',
    bankAccount,
  };
}

export function updateVAT({ vat }: { vat: vatT }): actionT {
  return {
    type: 'SUB_UPDATE_VAT',
    vat,
  };
}

export function updating(updating: boolean): actionT {
  return {
    type: 'SUB_UPDATING',
    updating,
  };
}

export function cardUpdating(updating: boolean): actionT {
  return {
    type: 'SUB_CARD_UPDATING',
    updating,
  };
}

export function cardUpdatingError(error: string): actionT {
  return {
    type: 'SUB_CARD_UPDATING_ERROR',
    cardError: error,
  };
}

export function orgCouponLoading(loading: boolean): actionT {
  return {
    type: 'SUB_ORG_COUPON_LOADING',
    loading,
  };
}

export function bankAccountUpdating(updating: boolean): actionT {
  return {
    type: 'SUB_BANK_ACCOUNT_UPDATING',
    updating,
  };
}

export function vatUpdating(updating: boolean): action {
  return {
    type: 'SUB_VAT_UPDATING',
    updating,
  };
}

// STORE
const initalSubState: stateT = {
  updating: false,
  cardUpdating: false,
  cardError: null,
  vatUpdating: false,
  bankAccountUpdating: false,
  orgCouponLoading: false,
};

export function subscription(state: stateT = initalSubState, action: actionT): state {
  switch (action.type) {
    case 'SUB_UPDATE_SUB':
      return {
        ...state,
        updating: false,
        sub: action.sub,
      };
    case 'SUB_UPDATE_PLANS':
      return {
        ...state,
        updating: false,
        plans: action.plans,
      };
    case 'SUB_UPDATE_CARD':
      return {
        ...state,
        cardUpdating: false,
        cardError: null,
        card: action.card,
      };
    case 'SUB_CARD_UPDATING_ERROR':
      return {
        ...state,
        cardUpdating: false,
        cardError: action.cardError,
      };
    case 'SET_ORG_COUPON':
      return {
        ...state,
        orgCouponLoading: false,
        orgCoupon: action.orgCoupon,
      };
    case 'SUB_UPDATE_BANK_ACCOUNT':
      return {
        ...state,
        bankAccountUpdating: false,
        bankAccount: action.bankAccount,
      };
    case 'SUB_UPDATE_VAT':
      return {
        ...state,
        vatUpdating: false,
        vat: action.vat,
      };
    case 'SUB_UPDATING':
      return {
        ...state,
        updating: action.updating,
      };
    case 'SUB_CARD_UPDATING':
      return {
        ...state,
        cardUpdating: action.updating,
      };
    case 'SUB_ORG_COUPON_LOADING':
      return {
        ...state,
        orgCouponLoading: action.loading,
      };
    case 'SUB_BANK_ACCOUNT_UPDATING':
      return {
        ...state,
        bankAccountUpdating: action.updating,
      };

    default:
      return state;
  }
}

// Thunks
export function loadCard({ organizationId }: { organizationId: string }) {
  return function (dispatch, getState, axios) {
    dispatch(cardUpdating(true));
    getCard(localStorage, axios, { organizationId }).then(({ card }) => {
      dispatch(updateCard({ card }));
    });
  };
}

export function loadOrgCoupon({ accountId }: { accountId: string }) {
  return function (dispatch, getState, axios) {
    dispatch(orgCouponLoading(true));
    getOrgCoupon(localStorage, axios, { accountId }).then((res) => {
      dispatch(setOrgCoupon(res.coupon));
    });
  };
}

export function loadBankAccount({ accountId }: { accountId: string }) {
  return function (dispatch, getState, axios) {
    dispatch(bankAccountUpdating(true));
    getBankAccount(localStorage, axios, { accountId }).then(({ bankAccount }) => {
      dispatch(updateBankAccount({ bankAccount }));
    });
  };
}

export function addBankAccount({ accountId, source }: { accountId: string, source: { id: string } }) {
  return function (dispatch, getState, axios) {
    dispatch(bankAccountUpdating(true));
    postBankAccount(localStorage, axios, {
      accountId,
      sourceId: source.id,
    }).then(({ bankAccount }) => {
      dispatch(updateBankAccount({ bankAccount }));
      track(trackedEvents.addIban, {
        last_4_digits: bankAccount.last4Digits,
      });
    });
  };
}

export function addCard({ accountId, token, next }: { accountId: string, token: string, next?: () => void }) {
  return function (dispatch, getState, axios) {
    dispatch(cardUpdating(true));
    postCard(localStorage, axios, {
      accountId,
      token,
    }).then(({ card }) => {
      dispatch(updateCard({ card }));
      track(trackedEvents.addCard, {
        last_4_digits: card.last4Digits,
        name: card.name,
        brand: card.brand,
      });

      if (next) {
        next();
      }
    });
  };
}

export function callAddCoupon({ accountId, orgId, code }: { accountId: string, orgId: string, code: string }) {
  return function (dispatch, getState, axios) {
    dispatch(updating(true));
    addCoupon(localStorage, axios, { accountId }, { orgId, code })
      .then(({ sub }) => {
        dispatch(updateSub({ sub }));
      })
      .catch((error) => {
        dispatch(updating(false));
      });
  };
}

export function callTrial({ accountId, plan }: { accountId: string, plan: planT }) {
  return function (dispatch, getState, axios) {
    dispatch(updating(true));
    trial(
      localStorage,
      axios,
      {
        accountId,
      },
      {
        planId: plan.id,
      },
    ).then(({ sub, limitations }: { sub: subT, limitations: {} }) => {
      dispatch(updateSub({ sub, plans: getState().plans }));
      dispatch(updateAccountLimitations(limitations));
      dispatch(updateUsageThunk({ accountId, force: true }));
      dispatch(addNotice({ message: 'Trial started 👍' }));
    });
  };
}

export function callSubscribe({
  accountId,
  coupon,
  plan,
  vat,
  token,
}: {
  accountId: string,
  coupon: string,
  plan: planT,
  vat: vatT,
  token?: string,
}) {
  return function (dispatch, getState, axios) {
    const subscribeL = () => {
      subscribe(
        localStorage,
        axios,
        {
          accountId,
        },
        {
          coupon,
          planId: plan.id,
          vat,
        },
      ).then(({ sub, limitations }: { sub: subT, limitations: {} }) => {
        const event =
          getState().subscription.sub.status === 'paying' || plan.type === 'free'
            ? trackedEvents.changePlan
            : trackedEvents.subscribe;

        track(event, {
          plan: plan.id,
          period: plan.period,
          type: plan.type,
        });

        if (event === trackedEvents.subscribe) {
          gtag_report_conversion_subscribe();
        }

        dispatch(updateSub({ sub, plans: getState().plans }));
        dispatch(updateAccountLimitations(limitations));
        dispatch(updateUsageThunk({ accountId, force: true }));
        dispatch(addNotice({ message: 'Subscription successfully updated' }));
      });
    };

    dispatch(updating(true));

    if (token) {
      dispatch(addCard({ accountId, token, next: subscribeL }));
    } else {
      subscribeL();
    }
  };
}

export function setDefaultCard({ organizationId, setupIntent }: { organizationId: string, setupIntent: string }) {
  return function (dispatch, getState, axios) {
    dispatch(cardUpdating(true));
    postDefaultCard(localStorage, axios, {
      organizationId,
      setupIntent,
    })
      .then(({ card }) => {
        dispatch(updateCard({ card }));
      })
      .catch((err) => {
        dispatch(cardUpdatingError(err.response.data.error_message));
      });
  };
}

export function loadVAT({ accountId }: { accountId: string }) {
  return function (dispatch, getState, axios) {
    dispatch(vatUpdating(true));
    getVAT(localStorage, axios, { accountId }).then(({ vat }) => {
      dispatch(updateVAT({ vat }));
    });
  };
}

// Selectors
export function getSub(state: stateT): subT {
  return state.subscription.sub;
}

export function displayTrialHeader(state: stateT): boolean {
  return state.sub !== undefined && state.sub.status.indexOf('trialing') >= 0;
}

export function displayModal(state: stateT): boolean {
  return (
    state.sub !== undefined &&
    (state.sub.status === 'churned' ||
      state.sub.status === 'not_paying' ||
      state.sub.status === 'paying_business_trial_ended' ||
      state.sub.status === 'revoked')
  );
}
