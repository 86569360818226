import React, { Fragment, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';

import { ImportCampaignForm } from './ImportCampaignForm';
import { NewCampaignForm } from './NewCampaignForm';
import { RadioButtonTabs } from '../../../common/antd/RadioButtonTabs';

export const TYPE_NEW = '';
export const TYPE_EXISTING = 'EXISTING';

export function INewCampaignButton({ account, limitations, productId, style, type, ghost, size }) {
  const [visible, setVisible] = useState(false);
  const [campaignType, setCampaignType] = useState(TYPE_NEW);
  const { messages } = useIntl();

  return (
    <Fragment>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title={messages.locales.campaigns.newCampaign}
        icon={<ExclamationCircleOutlined />}
      >
        <RadioButtonTabs
          value={campaignType}
          onChange={(type) => {
            setCampaignType(type);
          }}
          childStyle={{ width: '100%', padding: '10px 0' }}
          options={[
            {
              value: TYPE_NEW,
              label: messages.locales.campaigns.newCampaign,
              children: <NewCampaignForm productId={productId} account={account} />,
            },
            {
              value: TYPE_EXISTING,
              label: messages.locales.campaigns.importCampaigns,
              children: <ImportCampaignForm productId={productId} account={account} />,
            },
          ]}
        />
      </Modal>
      {limitations &&
        (limitations.limitGoogleProducts || limitations.limitFacebookAds || limitations.limitTiktokAds) && (
          <Button
            style={style}
            type={type || 'primary'}
            ghost={ghost}
            size={size}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setVisible(true);

              return false;
            }}
          >
            {messages.locales.campaigns.newCampaign}
          </Button>
        )}
      {(!limitations ||
        (!limitations.limitGoogleProducts && !limitations.limitFacebookAds && !limitations.limitTiktokAds)) && (
        <Tooltip title={messages.locales.campaigns.campaignError}>
          <Button style={style} type={type || 'primary'} ghost={ghost} size={size} disabled={true}>
            {messages.locales.campaigns.newCampaign}
          </Button>
        </Tooltip>
      )}
    </Fragment>
  );
}

const stateToProps = function (state): internalPropsT {
  return {
    limitations: state.accounts.limitations,
  };
};

export const NewCampaignButton = connect(stateToProps)(INewCampaignButton);
