import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { TiktokOAuthError } from './TiktokOAuthError';
import { trackedEvents } from '../../../config/trackedEvents.config';
import { tiktokOauthCallback } from '../../../lib/adsPlateformApi';
import { track } from '../../../lib/analytics';
import { SmallPlaceholder } from '../../common/Placeholders';

/**
 * Page use to select the right Tiktok Customer ID after OAuth
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TiktokOAuthPage(props: { style: ?any, accountId: string, code: string }) {
  const history = useHistory();
  const [state, updateState] = useImmer({ error: undefined, loading: true });

  async function load() {
    try {
      updateState((d) => {
        d.loading = true;
        d.error = undefined;
      });
      const data = await tiktokOauthCallback(
        localStorage,
        axios,
        { accountId: props.accountId },
        {
          code: props.code,
        },
      );
      const { adsProvider } = data;

      adsProvider.externalAccountId = `${adsProvider.externalId}`;
      adsProvider.externalAccountDescriptiveName = `${adsProvider.externalId}`;
      adsProvider.externalAccountResourceName = `${adsProvider.externalId}`;
      localStorage.setItem('adsProvider', JSON.stringify(adsProvider));

      track(trackedEvents.connectGoogle);
      setTimeout(() => {
        history.push(`/${props.accountId}/adsprovider/tiktok`);
      }, 500);

      /*
      await createAdsProvider(
          localStorage,
          axios,
          { accountId: props.accountId },
          {
            adsProvider
          }
      )
      track(trackedEvents.connectTiktok);
      setTimeout(() => {
        //  don't use history here, we need to reload the page to load the account
        window.location.href = `/${props.accountId}/adsprovider/tiktok/success`;
      }, 500);*/
    } finally {
      await updateState((d) => {
        d.loading = false;
      });
    }
  }

  useEffect(() => {
    load().finally(
      updateState((draft) => {
        draft.loading = false;
      }),
    );
  }, []);

  if (state.error) {
    return <TiktokOAuthError />;
  }

  return <SmallPlaceholder text="We are importing your Tiktok accounts, please wait a few seconds" />;
}
