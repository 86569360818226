import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Actions } from '../../../../actions';
import Box from '../../../common/Box';
import DeleteItem from '../../../common/DeleteItem';
import Modal from '../../../common/Modal';

const Style = styled.div``;

export const Platforms = ({ account }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [selected, setSelected] = useState();
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const dispatch = useDispatch();

  const renderHeader = () => (
    <div className="settings-platforms__list__title">
      <div>Platforms</div>
    </div>
  );

  const displayModal = () => {
    if (isModalDisplayed) {
      const message = isDeleting ? 'Deleting...' : "Yes I'm sure";

      return (
        <Modal
          disabled={isDeleting}
          okMessage={message}
          handleCancel={() => {
            setIsModalDisplayed(false);
            setIsDeleting(false);
          }}
          handleOk={() =>
            dispatch(
              Actions.api.account.platforms.remove.request({
                platform: selected,
              }),
            ).then(() => {
              setIsModalDisplayed(false);
              setIsDeleting(false);
              setSelected(undefined);
            })
          }
        >
          Are you sure you want to delete platform "{selected}"?
        </Modal>
      );
    }

    return null;
  };

  return (
    <Style>
      <Box className="settings-platforms__list" header={renderHeader()}>
        <>
          <div className="settings-platforms__list__headers settings-platforms__list__line">
            <div className="settings-platforms__list--name">Name</div>
            <div className="settings-platforms__list--del">Delete</div>
          </div>
          {account.platformValues.platforms.map((platform, index) => {
            let classes = 'settings-platforms__list__line';

            if (index % 2 === 1) {
              classes = `${classes} settings-platforms__list__line--odd`;
            }

            return (
              <div key={platform} className={classes}>
                <div className="settings-platforms__list--name">{platform}</div>
                <div className="settings-platforms__list--del">
                  <DeleteItem
                    disabled={isDeleting || index < 6}
                    handleClick={() => {
                      setIsModalDisplayed(true);
                      setSelected(platform);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </>
      </Box>
      {displayModal()}
    </Style>
  );
};
