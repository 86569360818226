import React, { useEffect } from 'react';

import { Empty, Form, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import styled from 'styled-components';

import { s } from 'lib/safe';

import { AdsProviderLogo } from '../common/AdsProviderLogo';
import { NewAdsProviderButton } from '../common/NewAdsProviderButton';

const Style = styled.div`
  select {
    height: 20px;
  }
`;

export type PROVIDER_TYPE = 'GENERIC' | 'GOOGLE' | 'FACEBOOK' | 'TIKTOK';

function AdsProviderSelectorContent(props: {
  onChange: any,
  adsProviders: adsProviderT,
  value: any,
  initialValue: any,
}) {
  useEffect(() => {
    if (props.adsProviders.length === 1) {
      props.onChange(props.adsProviders[0]);
    }
  }, [props.adsProviders.length]);

  return (
    <Select
      size="large"
      style={{ width: '100%' }}
      value={s(props.value).id}
      defaultValue={props.initialValue}
      disabled={props.adsProviders.length < 2}
      placeholder="Select an ad account"
      onChange={(id) => {
        const selected = props.adsProviders.find((p) => p.id === id);

        props.onChange(selected);
      }}
    >
      {props.adsProviders.map((provider) => (
        <Select.Option value={provider.id} key={provider.id}>
          <div
            style={{
              display: 'flex',
              gap: '1em',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <AdsProviderLogo
              style={{ flex: '0 0 16px', height: 16 }}
              providerType={provider.providerType}
            ></AdsProviderLogo>
            {`${provider.externalAccountDescriptiveName} (${provider.externalAccountId})`}
          </div>
        </Select.Option>
      ))}
      {props.adsProviders.length === 0 && <Empty description="No provider" />}
    </Select>
  );
}

export interface AddButtonPropsI {
  label?: string;
  style?: Object;
}

export function AdsProviderSelector(props: {
  account: accountT,
  form: FormInstance,
  providerType: PROVIDER_TYPE | PROVIDER_TYPE[],
  label?: string,
  addButton?: AddButtonPropsI,
}) {
  const providerTypes = Array.isArray(props.providerType) ? props.providerType : [props.providerType];
  const adsProviders = props.account.adsProviders.filter((p) => providerTypes.includes(p.providerType));

  const addButton = props.addButton || {};

  return (
    <Style>
      <Form.Item
        required
        name="adsProvider"
        rules={[{ required: true }]}
        label={props.label || 'Select an ad platform to launch a campaign:'}
        style={{ marginBottom: '5px' }}
      >
        <AdsProviderSelectorContent adsProviders={adsProviders} />
      </Form.Item>
      <NewAdsProviderButton account={props.account} title={addButton.label} style={addButton.style} />
    </Style>
  );
}
