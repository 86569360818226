import React from 'react';

import { AdsProviderDashBoardPage } from './Dashboard/AdsProviderDashBoardPage';
import { AdsProviderOnBoardingPage } from './Dashboard/AdsProviderOnBoardingPage';
import { getAdsProviders, hasActiveGoogleProvider } from '../../../lib/adsProvider/google';
import { getAmazonProfiles, hasActiveAmazonProfile } from '../../../lib/amazon';

export function AdsProviderPageSwitcher(props: { account: accountT, match: any }) {
  const amazonConnected = hasActiveAmazonProfile(getAmazonProfiles(props.account));
  const googleConnected = hasActiveGoogleProvider(getAdsProviders(props.account));

  if (amazonConnected && googleConnected) {
    return <AdsProviderDashBoardPage account={props.account} match={props.match} />;
  } else {
    return <AdsProviderOnBoardingPage account={props.account} />;
  }
}
