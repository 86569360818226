import React, { Fragment, useEffect } from 'react';

// import { useDetectAdBlock } from 'adblock-detect-react';
// import { Alert } from 'antd';
import styled from 'styled-components';

import { LimitedButton } from '../../../common/antd/LimitedButton';
import { AdsProviderLogo } from '../common/AdsProviderLogo';

const ProviderButton = styled(LimitedButton)`
  height: 51px;
  padding: 1em;
  display: flex;
  gap: 1em;
`;

const Style = styled.div`
  a {
    text-decoration: none !important;
  }
  .btn-text {
    width: 180px;
  }
`;

/**
 * Tiktok Login Button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TiktokLoginButton(props: { account: accountT, widgetStartType: string }) {
  // const adBlockDetected = useDetectAdBlock();

  const handleClick = () =>
    (window.location.href = `https://ads.tiktok.com/marketing_api/auth?app_id=7058163394024570881&state=aId:${
      props.account.id
    }&redirect_uri=${encodeURIComponent(window.location.origin)}%2Foauth%2Ftiktok%2Fcallback&rid=0svfme05yo9b`);

  async function load() {}

  useEffect(() => {
    load().then();
  }, []);

  useEffect(() => {
    if (props.widgetStartType === 'tiktok') {
      handleClick();
    }
  }, [props.widgetStartType]);

  return (
    <Style>
      <a>
        <ProviderButton
          icon={<AdsProviderLogo providerType="TIKTOK" style={{ height: 22 }} />}
          feature="TikTok Ads"
          limitationTest={(limitations, usage) => usage.tiktokAdsProvider < limitations.limitTiktokAdsProvider}
          onClick={handleClick}
        >
          <p className="btn-text">Connect Tiktok Business</p>
        </ProviderButton>
      </a>
    </Style>
  );
}
