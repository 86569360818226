import React from 'react';

import IconContainer from './IconContainer';

export function WorldWideWeb(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path
            d="M17.2574 5.98575C15.9024 3.00065 12.9213 1.0409 9.64075 0.986536C6.23778 0.930157 3.14539 2.89014 1.74164 5.98586C0.76 6.11669 0 6.95897 0 7.97582V11.0254C0 12.0424 0.760155 12.8847 1.74195 13.0154C3.12209 16.0602 6.14394 18.016 9.5 18.016C12.8563 18.016 15.8781 16.0603 17.2581 13.0154C18.2398 12.8846 19 12.0423 19 11.0254V7.97582C19 6.95866 18.2395 6.11619 17.2574 5.98575ZM9.62873 1.71117C12.5122 1.75894 15.1433 3.41563 16.4449 5.96764H2.55418C3.90078 3.31958 6.63325 1.66154 9.62873 1.71117ZM9.5 17.2913C6.54771 17.2913 3.87809 15.6371 2.55453 13.0336H16.4455C15.122 15.6371 12.4525 17.2913 9.5 17.2913ZM18.2753 11.0254C18.2753 11.7331 17.6995 12.3089 16.9918 12.3089H2.00818C1.30045 12.3089 0.724714 11.7331 0.724714 11.0254V7.97582C0.724714 7.26809 1.30045 6.69235 2.00818 6.69235H16.9918C17.6995 6.69235 18.2753 7.26809 18.2753 7.97582V11.0254Z"
            fill="#2E89E2"
          />
          <path
            d="M5.27141 10.0064L4.65888 8.11035H3.81314L3.20061 10.0064L2.58807 8.11035H1.68359L2.7512 11.2489H3.65529L4.23886 9.53958L4.81634 11.2489H5.72648L6.79409 8.11035H5.88395L5.27141 10.0064Z"
            fill="#2E89E2"
          />
          <path
            d="M10.5331 10.0064L9.9206 8.11035H9.07486L8.46229 10.0064L7.84979 8.11035H6.94531L8.01292 11.2489H8.91701L9.50058 9.53958L10.0781 11.2489H10.9882L12.0558 8.11035H11.1457L10.5331 10.0064Z"
            fill="#2E89E2"
          />
          <path
            d="M15.7949 10.0064L15.1823 8.11035H14.3366L13.724 10.0064L13.1115 8.11035H12.207L13.2746 11.2489H14.1787L14.7623 9.53958L15.3398 11.2489H16.2499L17.3175 8.11035H16.4074L15.7949 10.0064Z"
            fill="#2E89E2"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="19" height="19" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconContainer>
  );
}
