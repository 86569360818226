import React from 'react';

import { Spinner } from '@blueprintjs/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { s } from 'lib/safe';

import { Actions } from '../../../actions';
import { strings } from '../../../locales/strings';
import { addNotice } from '../../../stores/notices';
import { Copy, Edit } from '../../common/icons';
import { QrCode } from '../../common/icons/QrCode';
import { IconInsideButton } from '../AudienceBuilder/Campaigns/campaigns/RedirectsListLineElements';
import { LockedInputWithLabel } from '../AudienceBuilder/Campaigns/composer/InputWithLabel';

const Style = styled.div`
  background: white;
  padding: 1em;
  grid-area: link;
`;

export function PageLink({ account, redirect, updateState, state }) {
  const { messages } = useIntl();

  const dispatch = useDispatch();

  function handleEditRedirect(redirect) {
    dispatch(Actions.front.audienceBuilder.redirect.edit.show.request({ redirect }));
  }

  if (!redirect || !state.page) {
    return <Spinner />;
  }

  return (
    <Style>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ flex: 1 }}>
          <LockedInputWithLabel
            name="url"
            title="Your shortened link:"
            placeholder="url"
            displayLabel={true}
            disabled
            isBig
            value={redirect.shorten}
          />
        </div>
        <div style={{ minWidth: 110, flex: 0, textAlign: 'right' }}>
          <IconInsideButton>
            <Edit onClick={() => handleEditRedirect(redirect)} />
          </IconInsideButton>
          <IconInsideButton>
            <CopyToClipboard
              text={redirect.shorten}
              onCopy={() => {
                if (redirect.shorten) {
                  dispatch(
                    addNotice({
                      message: `${redirect.shorten} ${messages.action.copied}`,
                    }),
                  );
                }
              }}
            >
              <Copy />
            </CopyToClipboard>
          </IconInsideButton>
          <IconInsideButton>
            <a
              href={`${s(s(window).env).REACT_APP_QRCODE_URL}/?data=${redirect.shorten}&size=300`}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <QrCode />
            </a>
          </IconInsideButton>
        </div>
      </div>
    </Style>
  );
}
