import React from 'react';

import IconContainer from './IconContainer';

export function Next(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.59961 1L0.999609 6.33333" stroke="#2E89E2" strokeWidth="2" strokeLinecap="round" fill="#2E89E2" />
        <path
          d="M0.999609 6.33301L6.59961 11.6663"
          stroke="#2E89E2"
          strokeWidth="2"
          strokeLinecap="round"
          fill="#2E89E2"
        />
      </svg>
    </IconContainer>
  );
}
