import React, { useState } from 'react';

import { BugOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';

import { CampaignDebugModal } from './CampaignDebugModal';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';

export function DebugCampaign(props: { account: accountT, campaign: campaignT }) {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <CampaignDebugModal
        account={props.account}
        campaign={props.campaign}
        visible={visible}
        onFinish={() => setVisible(false)}
      />

      <IconInsideButton onClick={() => setVisible(true)}>
        <Tooltip title="Debug Campaign" placement="bottom">
          <BugOutlined />
        </Tooltip>
      </IconInsideButton>
    </div>
  );
}
