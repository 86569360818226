import React from 'react';

import styled from 'styled-components';

import { sanityCheck } from 'lib/slug';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { handleChange } from './composerEditUtils';
import { InputWithLabel } from '../InputWithLabel';

export const ComposerEditSlug = styled((props) => {
  const { redirect, className, account } = props;
  const usePixelMeDomain =
    account.domains.length === 0 || account.domains.map((d) => d.name).indexOf(redirect.domain) === -1;

  return (
    <div className={className}>
      <InputWithLabel
        name="slug"
        isBig
        disabled={usePixelMeDomain}
        displayLabel
        onChange={(e) => {
          e.target.value = sanityCheck(e.target.value);
          handleChange([e.target], props);
        }}
        preInput={redirect.shorten.replace(/(.*\/)[^]*/g, '$1')}
        title={getIntlStrings('redirect.slug.input.title')}
        placeholder={getIntlStrings('redirect.slug.input.placeholder')}
        value={redirect.slug}
      />
    </div>
  );
}).withConfig({ displayName: 'ComposerEditSlug' })``;
