import React from 'react';

import Steps from 'antd/lib/steps';
import styled from 'styled-components';

const { Step } = Steps;

const Style = styled.div`
  margin-bottom: 55px;

  .ant-steps-item-title {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff !important;
  }
  .ant-steps-item-description {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff !important;
  }
  .ant-steps-item-container .ant-steps-item-wait,
  .ant-steps-item-container .ant-steps-item-icon,
  .ant-steps-item-container span.ant-steps-icon {
    color: white;
    background-color: transparent;
    border-color: white;
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon span.ant-steps-icon {
    background-color: white;
    color: #0e5396 !important;
    border-color: #0e5396 !important;
  }
`;

export function EasySteps({ current, onChange }) {
  return (
    <Style>
      <Steps current={current}>
        <Step title="Shorten" description="your first link" />
        <Step title="Edit" description="your short link" />
        <Step title="Create" description="the perfect preview" />
        <Step title="Retarget" description="your customers" />
      </Steps>
    </Style>
  );
}
