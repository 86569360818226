import React, { Fragment, useEffect, useState } from 'react';

import { Button, Card, message } from 'antd';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { MutedText } from 'components/common/MutedText';
import { getRawCampaign } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { useLocale } from 'locales/LocalizationWrapper';

import { ADS_PROVIDERS } from '../constants';
import { GenericCampaignLink } from '../GenericAdsProvider/GenericCampaignLink';

const Style = styled.div`
  text-align: center;
  align-items: center;
  width: 610px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  h1 {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
  }
`;

export function NewCampaignSuccess(props: {
  account: accountT,
  adsProviderType: string,
  campaignId: string,
  productProviderId?: string,
  productExternalId?: string,
}) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState();
  const { getIntlStrings } = useLocale();

  async function loadRawCampaign() {
    setLoading(true);
    setCampaign(undefined);

    try {
      const campaignResponse = await getRawCampaign(
        localStorage,
        axios,
        { accountId: props.account.id },
        props.campaignId,
      );

      setCampaign(campaignResponse);
    } catch (e) {
      message.error('Unable to retrieve current campaign');

      throw e;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadRawCampaign().then();
  }, [props.campaignId]);

  return (
    <Style>
      <svg width="319" height="243" viewBox="0 0 319 243" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_475_12576)">
          <path
            d="M127.647 50.3926H126.484V18.4738C126.484 16.0478 126.007 13.6455 125.079 11.4042C124.152 9.16283 122.793 7.1263 121.08 5.41085C119.366 3.6954 117.332 2.33463 115.093 1.40624C112.855 0.477843 110.456 2.05551e-06 108.033 0H40.4904C35.5968 -9.34021e-06 30.9036 1.94632 27.4433 5.41081C23.983 8.8753 22.0391 13.5742 22.0391 18.4737V193.583C22.0391 196.009 22.5163 198.411 23.4436 200.653C24.3708 202.894 25.7299 204.93 27.4433 206.646C29.1567 208.361 31.1907 209.722 33.4293 210.651C35.6679 211.579 38.0673 212.057 40.4903 212.057H108.032C112.926 212.057 117.619 210.11 121.079 206.646C124.54 203.181 126.484 198.483 126.484 193.583V73.113H127.647V50.3926Z"
            fill="#3F3D56"
          />
          <path
            d="M108.777 4.80621H99.961C100.366 5.80145 100.521 6.88125 100.412 7.95048C100.302 9.01971 99.9316 10.0456 99.3328 10.9377C98.734 11.8298 97.9253 12.5607 96.9779 13.0662C96.0304 13.5716 94.9734 13.836 93.8999 13.8361H55.2058C54.1323 13.836 53.0753 13.5716 52.1278 13.0662C51.1804 12.5607 50.3717 11.8298 49.7729 10.9377C49.1741 10.0456 48.8036 9.01971 48.694 7.95048C48.5845 6.88125 48.7392 5.80145 49.1447 4.80621H40.9101C39.1006 4.80621 37.3088 5.16306 35.637 5.85637C33.9652 6.54968 32.4462 7.56589 31.1667 8.84696C29.8872 10.128 28.8722 11.6489 28.1797 13.3227C27.4873 14.9965 27.1309 16.7905 27.1309 18.6022V193.455C27.1309 195.266 27.4873 197.06 28.1797 198.734C28.8722 200.408 29.8872 201.929 31.1667 203.21C32.4462 204.491 33.9652 205.507 35.637 206.2C37.3088 206.894 39.1005 207.251 40.9101 207.251H108.777C110.587 207.251 112.379 206.894 114.051 206.2C115.722 205.507 117.241 204.491 118.521 203.21C119.8 201.929 120.815 200.408 121.508 198.734C122.2 197.06 122.557 195.266 122.557 193.455V18.6022C122.557 16.7905 122.2 14.9965 121.508 13.3227C120.815 11.6489 119.8 10.128 118.521 8.84696C117.241 7.56589 115.722 6.54968 114.051 5.85637C112.379 5.16306 110.587 4.80621 108.777 4.80621Z"
            fill="white"
          />
          <path
            d="M149.296 212.087H0.390828C0.287174 212.087 0.187765 212.046 0.114471 211.972C0.0411761 211.899 0 211.799 0 211.696C0 211.592 0.0411761 211.492 0.114471 211.419C0.187765 211.346 0.287174 211.304 0.390828 211.304H149.296C149.4 211.304 149.499 211.346 149.573 211.419C149.646 211.492 149.687 211.592 149.687 211.696C149.687 211.799 149.646 211.899 149.573 211.972C149.499 212.046 149.4 212.087 149.296 212.087Z"
            fill="#CBCBCB"
          />
          <path
            d="M74.8438 135.266C93.0714 135.266 107.848 120.472 107.848 102.222C107.848 83.9721 93.0714 69.1777 74.8438 69.1777C56.6162 69.1777 41.8398 83.9721 41.8398 102.222C41.8398 120.472 56.6162 135.266 74.8438 135.266Z"
            fill="#2E89E2"
          />
          <path
            d="M71.5733 114.827C70.8308 114.828 70.1081 114.588 69.514 114.142L69.4772 114.114L61.7208 108.174C61.3616 107.898 61.0601 107.554 60.8336 107.162C60.6071 106.769 60.4601 106.336 60.4008 105.887C60.3415 105.438 60.3712 104.981 60.4882 104.543C60.6051 104.105 60.8071 103.695 61.0825 103.335C61.3579 102.976 61.7013 102.674 62.0932 102.447C62.4852 102.22 62.9178 102.073 63.3666 102.014C63.8153 101.954 64.2714 101.984 64.7087 102.101C65.1459 102.218 65.5559 102.42 65.9151 102.696L70.9391 106.553L82.8109 91.0464C83.0862 90.6868 83.4296 90.3851 83.8213 90.1585C84.213 89.9318 84.6455 89.7846 85.0941 89.7253C85.5427 89.6659 85.9985 89.6957 86.4356 89.8127C86.8727 89.9298 87.2825 90.1319 87.6416 90.4075L87.6426 90.4083L87.5689 90.5107L87.6446 90.4083C88.369 90.9657 88.843 91.7881 88.9625 92.6951C89.0819 93.602 88.8372 94.5193 88.2819 95.2458L74.318 113.477C73.9949 113.897 73.5796 114.237 73.1041 114.471C72.6286 114.704 72.1059 114.825 71.5764 114.823L71.5733 114.827Z"
            fill="white"
          />
          <path
            d="M271.331 215.335C271.732 214.685 272.205 214.082 272.74 213.537C272.961 213.308 273.194 213.092 273.434 212.882C274.893 211.581 276.672 210.693 278.587 210.309C280.503 209.926 282.486 210.059 284.333 210.697C286.18 211.335 287.823 212.454 289.095 213.939C290.367 215.424 291.221 217.221 291.569 219.145C293.181 213.177 288.985 207.298 284.766 202.783C280.545 198.269 275.721 193.317 275.745 187.131C275.757 183.689 277.439 180.619 279.978 178.342C280.056 178.273 280.134 178.205 280.214 178.138C283.283 175.504 287.231 174.127 291.27 174.281C298.773 174.683 305.325 179.732 309.875 185.721C317.199 195.358 320.408 208.885 315.281 219.855C310.771 229.503 301.153 235.233 291.337 239.839C289.966 240.482 288.598 241.106 287.233 241.71C287.224 241.713 287.214 241.719 287.205 241.722C287.139 241.75 287.073 241.779 287.012 241.809C286.73 241.934 286.448 242.058 286.169 242.181L286.298 242.266L286.702 242.527C286.562 242.443 286.424 242.36 286.284 242.275C286.242 242.25 286.198 242.226 286.156 242.2C281.335 239.303 276.447 236.239 273.116 231.718C269.662 227.023 268.273 220.298 271.331 215.335Z"
            fill="#F1F1F1"
          />
          <path
            d="M303.497 204.432C304.565 206.863 305.273 209.438 305.598 212.074C305.854 214.424 305.758 216.8 305.312 219.122C304.355 223.894 302.267 228.366 299.224 232.161C296.929 235.043 294.278 237.622 291.337 239.839C289.966 240.482 288.598 241.106 287.233 241.71C287.224 241.713 287.214 241.719 287.205 241.722C287.139 241.751 287.073 241.779 287.012 241.809C286.73 241.934 286.448 242.058 286.169 242.181L286.298 242.266L286.702 242.527C286.562 242.443 286.424 242.36 286.284 242.275C286.242 242.25 286.198 242.226 286.157 242.2C287.217 238.484 287.373 234.568 286.611 230.779C285.849 226.991 284.191 223.44 281.776 220.425C279.348 217.448 276.252 215.088 272.74 213.537C272.961 213.308 273.194 213.092 273.433 212.882C274.786 213.504 276.081 214.244 277.304 215.094C281.676 218.12 284.96 222.475 286.668 227.514C288.193 232.086 288.383 236.999 287.217 241.675C287.459 241.517 287.702 241.354 287.938 241.192C292.429 238.16 296.569 234.518 299.687 230.052C302.376 226.277 304.097 221.898 304.698 217.301C305.295 212.279 304.04 207.341 301.801 202.86C299.328 198.066 296.212 193.633 292.54 189.685C288.851 185.627 284.68 182.037 280.121 178.993C280.018 178.924 279.946 178.818 279.919 178.697C279.892 178.575 279.913 178.449 279.978 178.342C280.026 178.246 280.111 178.172 280.214 178.138C280.266 178.125 280.321 178.123 280.373 178.134C280.426 178.145 280.476 178.167 280.519 178.2C281.082 178.576 281.64 178.955 282.191 179.346C286.783 182.601 290.953 186.415 294.606 190.7C298.135 194.83 301.348 199.419 303.497 204.432Z"
            fill="white"
          />
          <path
            d="M205.202 15.3433C209.073 15.3433 212.211 12.2014 212.211 8.3256C212.211 4.44984 209.073 1.30792 205.202 1.30792C201.33 1.30792 198.192 4.44984 198.192 8.3256C198.192 12.2014 201.33 15.3433 205.202 15.3433Z"
            fill="#F1F1F1"
          />
          <path
            d="M292.356 109.256C294.716 109.256 296.63 107.341 296.63 104.978C296.63 102.615 294.716 100.699 292.356 100.699C289.996 100.699 288.083 102.615 288.083 104.978C288.083 107.341 289.996 109.256 292.356 109.256Z"
            fill="#F1F1F1"
          />
          <path
            d="M167.291 120.995C169.651 120.995 171.564 119.08 171.564 116.717C171.564 114.354 169.651 112.438 167.291 112.438C164.931 112.438 163.018 114.354 163.018 116.717C163.018 119.08 164.931 120.995 167.291 120.995Z"
            fill="#F1F1F1"
          />
          <path
            d="M169.703 243H318.609C318.713 243 318.812 242.959 318.885 242.885C318.959 242.812 319 242.712 319 242.609C319 242.505 318.959 242.405 318.885 242.332C318.812 242.259 318.713 242.217 318.609 242.217H169.703C169.6 242.217 169.5 242.259 169.427 242.332C169.354 242.405 169.312 242.505 169.312 242.609C169.312 242.712 169.354 242.812 169.427 242.885C169.5 242.959 169.6 243 169.703 243Z"
            fill="#CBCBCB"
          />
          <path
            d="M302.177 37.8137C302.372 36.4238 302.052 35.0104 301.276 33.8413C300.5 32.6722 299.323 31.8287 297.967 31.4705C296.612 31.1123 295.172 31.2644 293.921 31.8979C292.67 32.5314 291.695 33.6024 291.18 34.9078L279.097 48.7773L282.642 58.6917L294.794 42.4857C295.587 42.7401 296.426 42.8172 297.252 42.7118C298.078 42.6063 298.871 42.3208 299.575 41.8754C300.279 41.4299 300.876 40.8354 301.326 40.1336C301.775 39.4319 302.066 38.64 302.177 37.8137Z"
            fill="#FFB7B7"
          />
          <path d="M252.089 91.78L292.199 48.9208L286.789 40.309L236.48 82.908L252.089 91.78Z" fill="#3F3D56" />
          <path
            d="M243.137 65.7391C250.908 65.7391 257.207 59.4322 257.207 51.6522C257.207 43.8721 250.908 37.5652 243.137 37.5652C235.367 37.5652 229.067 43.8721 229.067 51.6522C229.067 59.4322 235.367 65.7391 243.137 65.7391Z"
            fill="#2F2E41"
          />
          <path
            d="M244.269 43.6943C247.296 40.6204 250.96 38.2483 255.002 36.7451C257.597 35.7827 260.421 35.1881 263.133 35.7346C265.846 36.2811 268.411 38.1599 269.168 40.8246C269.786 43.0035 269.147 45.3496 268.187 47.4007C267.227 49.4518 265.945 51.3538 265.168 53.4815C264.376 55.6525 264.137 57.9872 264.475 60.2738C264.812 62.5603 265.715 64.7263 267.1 66.5751C268.486 68.4239 270.311 69.8969 272.409 70.8605C274.508 71.8241 276.813 72.2477 279.116 72.0928C276.451 72.4506 273.994 73.7046 271.389 74.3721C268.784 75.0396 265.71 74.9943 263.774 73.1257C261.725 71.1487 261.684 67.9342 261.783 65.0874C261.931 60.8554 262.078 56.6235 262.225 52.3915C262.3 50.2333 262.364 48.0059 261.587 45.9915C260.809 43.9771 258.962 42.2132 256.805 42.2316C255.171 42.2454 253.724 43.2369 252.413 44.2147C251.102 45.1925 249.714 46.2357 248.086 46.3811C246.458 46.5265 244.599 45.3201 244.721 43.688L244.269 43.6943Z"
            fill="#2F2E41"
          />
          <path
            d="M189.7 146.239C188.349 146.615 186.907 146.482 185.648 145.864C184.389 145.247 183.4 144.189 182.868 142.89C182.337 141.591 182.299 140.143 182.763 138.818C183.227 137.493 184.16 136.385 185.385 135.704L197.54 121.897L207.821 124.121L193.361 138.301C193.717 139.055 193.903 139.877 193.906 140.711C193.91 141.545 193.731 142.369 193.382 143.126C193.033 143.883 192.522 144.554 191.886 145.092C191.25 145.63 190.503 146.021 189.7 146.239H189.7Z"
            fill="#FFB7B7"
          />
          <path d="M229.689 96.8107L199.394 134.885L190.159 130.64L225.768 75.1351L229.689 96.8107Z" fill="#3F3D56" />
          <path d="M205.516 211.778L198.296 211.665L199.164 236.304H205.515L205.516 211.778Z" fill="#FFB7B7" />
          <path
            d="M207.135 234.228L194.628 234.227H194.628C192.514 234.227 190.487 235.068 188.992 236.565C187.497 238.061 186.657 240.091 186.657 242.207V242.467L207.135 242.467L207.135 234.228Z"
            fill="#2F2E41"
          />
          <path d="M253.321 205.243L245.434 210.313L261.211 229.317L266.555 225.882L253.321 205.243Z" fill="#FFB7B7" />
          <path
            d="M266.797 223.258L256.273 230.023L256.272 230.024C254.494 231.167 253.242 232.971 252.791 235.039C252.341 237.107 252.73 239.269 253.872 241.05L254.012 241.269L271.243 230.192L266.797 223.258Z"
            fill="#2F2E41"
          />
          <path
            d="M256.13 130.007C256.13 130.007 238.606 71.7311 232.974 70.9436C227.342 70.1561 218.268 85.8965 218.268 85.8965C206.503 100.926 194.371 149.609 194.371 149.609C194.371 149.609 214.595 150.876 229.379 158.751C244.163 166.626 261.61 158.353 261.61 158.353L256.13 130.007Z"
            fill="#3F3D56"
          />
          <path
            opacity="0.1"
            d="M256.13 130.007C256.13 130.007 238.606 71.7311 232.974 70.9435C227.342 70.156 220.573 86.0562 220.573 86.0562C214.71 110.317 194.371 149.609 194.371 149.609C194.371 149.609 214.595 150.876 229.379 158.751C244.163 166.626 261.61 158.353 261.61 158.353L256.13 130.007Z"
            fill="black"
          />
          <path
            d="M241.754 112.571V141.854L245.196 184.273L262.392 216.36L254.185 222.23L230.735 191.708L221.46 161.402L206.894 228.491L195.951 227.708L203.551 134.691L217.973 108.509L241.754 112.571Z"
            fill="#2F2E41"
          />
          <path
            d="M224.085 84.2477C218.613 89.726 223.694 95.5955 223.694 95.5955L217.44 109.682L242.063 116.335L250.667 77.8388L244.805 71.578C233.08 71.1866 233.074 70.9434 233.074 70.9434C231.019 72.9562 224.085 84.2477 224.085 84.2477Z"
            fill="#2E89E2"
          />
          <path
            d="M251.659 73.8507C250.54 73.0242 249.269 72.4281 247.919 72.0969C246.569 71.7657 245.167 71.706 243.794 71.9212C242.421 72.1364 241.104 72.6222 239.919 73.3506C238.735 74.079 237.707 75.0354 236.894 76.1645C230.704 84.7937 224.981 98.7276 232.867 116.106C245.529 144.009 256.548 158.294 261.028 158.292C261.224 158.294 261.418 158.263 261.604 158.202C264.361 157.265 268.348 152.594 269.07 148.477C269.328 147.003 269.266 144.951 267.506 143.542C262.451 139.493 253.375 126.887 250.544 110.295C248.979 101.127 252.013 92.7754 254.834 87.3841C256.026 85.1534 256.374 82.5673 255.816 80.1001C255.258 77.6329 253.83 75.4498 251.795 73.951L251.659 73.8507Z"
            fill="#3F3D56"
          />
          <path
            d="M243.861 64.3552C248.88 61.6082 250.725 55.3077 247.981 50.2825C245.238 45.2573 238.945 43.4105 233.926 46.1575C228.907 48.9045 227.062 55.2051 229.806 60.2303C232.549 65.2554 238.842 67.1022 243.861 64.3552Z"
            fill="#FFB7B7"
          />
          <path
            d="M224.327 54.4723H239.282L239.436 52.3226L240.203 54.4723H242.505L242.809 50.2116L244.329 54.4723H248.785V54.2612C248.782 51.295 247.603 48.4511 245.508 46.3537C243.413 44.2562 240.573 43.0763 237.61 43.073H235.502C232.539 43.0763 229.699 44.2562 227.604 46.3536C225.509 48.4511 224.33 51.295 224.327 54.2612V54.4723Z"
            fill="#2F2E41"
          />
          <path
            d="M246.526 62.1995L252.908 60.1261V44.6087H240.854L241.152 44.907C245.304 49.0591 249.923 63.7902 246.526 62.1995Z"
            fill="#2F2E41"
          />
        </g>
        <defs>
          <clipPath id="clip0_475_12576">
            <rect width="319" height="243" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <br />
      <Card title="Campaign launched">
        {props.adsProviderType === ADS_PROVIDERS.GENERIC ? (
          <Fragment>
            <GenericCampaignLink campaign={_.get(campaign, 'genericRawCampaign')} />
          </Fragment>
        ) : (
          <Fragment>
            <MutedText>
              {getIntlStrings('campaigns.adsProviderSuccess', { adsProviderType: props.adsProviderType })}
            </MutedText>
            {props.adsProviderType === ADS_PROVIDERS.GOOGLE && (
              <MutedText>
                In order to properly test these ads, we recommend running them for at least two weeks. If you pause the
                ad after a few days, you won’t have enough data to determine if this was a successful campaign. Bear in
                mind that amazon attribution may take up to 2 weeks to attribute a sale to this campaign.
              </MutedText>
            )}
          </Fragment>
        )}
        <br />

        <Button.Group>
          <Button
            type="ghost"
            size="large"
            onClick={() => {
              const redirectUrl =
                props.productProviderId && props.productExternalId
                  ? `/${props.account.id}/adsprovider/${props.productProviderId}/${props.productExternalId}/campaigns`
                  : `/${props.account.id}/adsprovider/dashboard`;

              history.push(redirectUrl);
            }}
          >
            See my campaigns
          </Button>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              history.push(
                `/${props.account.id}/adsprovider/new-${props.adsProviderType.toLowerCase()}-campaign/${
                  props.campaignId
                }`,
              );
            }}
          >
            Duplicate this campaign
          </Button>
        </Button.Group>
      </Card>
    </Style>
  );
}
