import React, { useEffect } from 'react';
import type { Node } from 'react';

import { notification } from 'antd';
import styled from 'styled-components';

import { ImageOnRightSide } from '../../common/ImageOnRightSide';
import { MutedText } from '../../common/MutedText';
import NewLogo from '../NewLogo';

const Style = styled.div`
  height: 100%;
  display: flex;
  .ant-input,
  .ant-input-affix-wrapper {
    border: none !important;
    background-color: #f3f5f7 !important;
  }
  .highlight {
    color: #2e0077;
  }
  .ant-input-password-large {
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const Left = styled.div`
  width: ${(props) => props.width || '432px'};
  margin: auto;
`;

const Header = styled.div`
  text-align: center;
`;

const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  color: #000000;
  margin-bottom: 1em;
`;
const HeaderDescription = styled(MutedText)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #7b7b7b;
`;

const Footer = styled.div`
  margin-top: 1em;
  font-size: 12px !important;
`;

function SignLayout({
  errorMessage,
  children,
  title,
  description,
  footer,
  image,
  header,
  left = {},
  theme,
}: {
  errorMessage?: string,
  children: Node,
  title: Node,
  description?: Node,
  footer: Node,
  image?: Node,
  header?: Node,
  left?: { width: number },
  theme?: string,
}) {
  useEffect(() => {
    if (errorMessage) {
      notification.error({
        message: 'Oops, there was an error!',
        description: errorMessage,
        duration: 20000,
      });
    }
  }, [errorMessage]);

  return (
    <Style>
      <ImageOnRightSide header={header || <NewLogo />} image={image} theme={theme}>
        <Left {...left}>
          <Header>
            <HeaderTitle>{title}</HeaderTitle>
            <HeaderDescription>{description}</HeaderDescription>
          </Header>
          <br />
          {children}
          <br />
          <Footer>{footer}</Footer>
        </Left>
      </ImageOnRightSide>
    </Style>
  );
}

export default SignLayout;
