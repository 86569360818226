export function gtag_report_conversion_subscribe() {
  window.dataLayer.push({
    event: 'call-to-action',
    eventCategory: 'subscribe',
    eventAction: 'form_submitted',
    eventLabel: 'Payed',
  });

  return false;
}
