import './BulkImport.css';

import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';

import Box from 'components/common/Box';
import Button from 'components/common/Button';
import { GdprCheckBox } from 'components/common/CheckBoxes';
import { TagsDropDown, PixelsDropDown, DomainDropDown } from 'components/common/dropDown/DropDowns';
import { Textarea } from 'components/common/Input';
import Modal from 'components/common/Modal';
import { importRedirects } from 'lib/api';
import axios from 'lib/axios.factory';

type state = {
  redirect: boolean,
  calling: boolean,
  displayModal: boolean,
  tags: Array<string>,
  pixelsIds: Array<string>,
  urlsField: string,
  domain: string,
  cookieConsent: boolean,
};

export default class Import extends Component<{ account: accountT }, state> {
  constructor(props: { account: accountT }) {
    super(props);
    this.state = {
      redirect: false,
      calling: false,
      displayModal: false,
      tags: [],
      pixelsIds: [],
      urlsField: '',
      domain: '',
      cookieConsent: false,
    };
  }
  render() {
    const { account } = this.props;

    const { redirect, calling, tags, pixelsIds, domain, urlsField, cookieConsent, displayModal } = this.state;

    if (redirect) {
      return <Redirect to={`/${account.id}/ab/campaigns`} />;
    }

    return (
      <Box header="Import redirects">
        <div className="import__container">
          {displayModal && (
            <Modal
              okOnly
              handleOk={(_) => {
                this.setState({ redirect: true });
              }}
              okMessage="Noticed :-)"
            >
              <>
                <p className="import__modal__header">Your import is running...</p>
                <p>You will receive an email when it will be finished</p>
              </>
            </Modal>
          )}
          <div className="import__headline">Select the following parameters to create your redirects</div>
          <div className="import__dropdowns">
            <PixelsDropDown
              displayLabel
              account={account}
              pixelsIds={pixelsIds}
              onSelect={(pixelsIds) => {
                this.setState({ pixelsIds });
              }}
            />
            <DomainDropDown
              displayLabel
              account={account}
              domain={domain}
              onSelect={(domain) => this.setState({ domain })}
            />
            <TagsDropDown
              displayLabel
              account={account}
              selectedTags={tags}
              onSelect={(tags) => this.setState({ tags })}
            />
          </div>
          <GdprCheckBox
            cookieConsent={cookieConsent}
            handleClick={(_) => this.setState({ cookieConsent: !cookieConsent })}
            account={account}
          />
          <Textarea
            id="urls"
            label="URLs (one per line)"
            placeholder={`https://pixelme.me/blog/2018/07/08/how-to-create-an-ultra-targeted-audience-for-free-using-quora/\nhttps://pixelme.me/blog/2018/04/25/3-reasons-to-use-pixelme-when-you-launch-an-ad-on-facebook/`}
            onChange={this.handleChange}
            value={urlsField}
          />
          <Button
            loading={calling}
            loadingLabel="Importing..."
            disabled={!this.validateFields()}
            handleClick={this.handleImport}
          >
            Import redirects
          </Button>
        </div>
      </Box>
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const urlsField = target.value;

    // const name = target.name;
    this.setState({
      urlsField,
    });
  };

  handleImport = () => {
    const { account } = this.props;
    const { urlsField, domain, pixelsIds, tags, cookieConsent } = this.state;
    const urls = urlsField.split(/(\s+)/).filter((url: string) => url.trim().length > 0);

    this.setState({ calling: true });
    importRedirects(
      localStorage,
      axios,
      { accountId: account.id },
      {
        domain,
        pixelsIds,
        urls,
        tags,
        cookieConsent,
      },
    ).then((_) => this.setState({ displayModal: true, urlsField: '' }));
  };

  validateFields() {
    const { urlsField } = this.state;

    return urlsField.trim().length > 0;
  }
}
