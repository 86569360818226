import React from 'react';

import Radio from 'antd/lib/radio';
import extractDomain from 'extract-domain';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import { s } from 'lib/safe';

import { Facebook } from '../../../../icons/providers/Facebook';
import { Google } from '../../../../icons/providers/Google';
import { Linkedin } from '../../../../icons/providers/Linkedin';
import { Pinterest } from '../../../../icons/providers/Pinterest';
import { Slack } from '../../../../icons/providers/Slack';
import { Twitter } from '../../../../icons/providers/Twitter';
import { Favicon } from '../../../common/FavIcon';

const Style = styled.div`
  height: 100%;
  width: 100%;
  text-align: left;

  .ant-radio-button-wrapper {
    border: none !important;
    outline: white !important;
    color: white !important;
    box-shadow: none !important;
    padding: 0;
    margin: 10px;
    opacity: 0.4;
  }
  .ant-radio-button-wrapper-checked {
    opacity: 1;
  }
  .ant-radio-button-wrapper {
    outline: none !important;
    border: none !important;
    border-image-width: 0 !important;
  }
  .index-metadata__content {
    width: 100%;
    margin: auto;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: '';
    display: none;
    outline: none !important;
    background-color: transparent;
  }

  @media all {
    *,
    *:before,
    *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    h4 {
      margin: 0;
      font-weight: 500;
    }
    h4 {
      font-size: 14px;
      letter-spacing: 0.015em;
      color: #a3b3ca;
    }
    ::selection {
      color: #fff;
      background: #18283e;
    }
    ::-moz-selection {
      color: #fff;
      background: #18283e;
    }
    ::-webkit-input-placeholder {
      color: #99abc8;
    }
    ::-moz-placeholder {
      color: #99abc8;
    }
    :-ms-input-placeholder {
      color: #99abc8;
    }
    :-moz-placeholder {
      color: #99abc8;
    }
    ::-webkit-input-placeholder {
      color: #a0a8ae;
    }
    .index-metadata__content h4 {
      display: flex;
      margin-bottom: 0.5em;
    }
    .metadata-group__display {
      display: none;
      position: relative;
    }
    .metadata-group__display.is-active {
      display: block;
    }
    h4.metadata-group__title {
      position: relative;
      margin: 1.75em 0 1em;
    }
    h4.metadata-group__title:before {
      content: '';
      position: absolute;
      top: 10px;
      right: 0;
      width: 100%;
      height: 1px;
      background: #e0e7f1;
    }
    h4.metadata-group__title span {
      position: relative;
      background: #fff;
      padding-right: 12px;
    }
    .card-seo-google {
      position: relative;
      display: block;
      box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
      margin: 0 -10px;
      padding: 12px 16px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 20px;
      font-family: Arial, sans-serif;
      -webkit-font-smoothing: subpixel-antialiased;
    }
    @media only screen and (min-width: 770px) {
      .card-seo-google {
        width: 500px;
        box-shadow: none;
        margin: 0;
        padding: 0;
      }
    }
    .card-seo-google__title {
      display: block;
      letter-spacing: normal;
      color: #1a0dab;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @media only screen and (min-width: 770px) {
      .card-seo-google__title {
        font-size: 18px;
        line-height: 1.2;
      }
    }
    .card-seo-google__title:hover {
      text-decoration: underline;
    }
    .card-seo-google__url {
      display: block;
      height: 17px;
      white-space: nowrap;
      border-bottom: 1px solid #ddd;
      padding-bottom: 22px;
      margin-bottom: 6px;
    }
    @media only screen and (min-width: 770px) {
      .card-seo-google__url {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    .card-seo-google__url-title {
      position: relative;
      top: -2px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: normal;
      color: #006621;
    }
    .card-seo-google__url-arrow {
      display: none;
      vertical-align: middle;
      margin-top: -4px;
      margin-left: 3px;
      border-width: 5px 4px 0 4px;
      border-style: solid;
      border-color: #006621 transparent;
    }
    @media only screen and (min-width: 770px) {
      .card-seo-google__url-arrow {
        display: inline-block;
      }
    }
    .card-seo-google__description {
      color: #545454;
      font-size: 13px;
      line-height: 1.4;
      word-wrap: break-word;
    }
  }

  @media only screen and (min-width: 770px) {
    .card-seo-google {
      width: 500px;
      box-shadow: none;
      margin: 0;
      padding: 0;
    }
  }

  .card-seo-google__title {
    display: block;
    letter-spacing: normal;
    color: #1a0dab;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-google__title {
      font-size: 18px;
      line-height: 1.2;
    }
  }

  .card-seo-google__title:hover {
    text-decoration: underline;
  }

  .card-seo-google__url {
    display: block;
    height: 17px;
    white-space: nowrap;
    border-bottom: 1px solid #ddd;
    padding-bottom: 22px;
    margin-bottom: 6px;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-google__url {
      border: none;
      padding: 0;
      margin: 0;
    }
  }

  .card-seo-google__url-title {
    position: relative;
    top: -2px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: normal;
    color: #006621;
  }

  .card-seo-google__url-arrow {
    display: none;
    vertical-align: middle;
    margin-top: -4px;
    margin-left: 3px;
    border-width: 5px 4px 0 4px;
    border-style: solid;
    border-color: #006621 transparent;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-google__url-arrow {
      display: inline-block;
    }
  }

  .card-seo-google__description {
    color: #545454;
    font-size: 13px;
    line-height: 1.4;
    word-wrap: break-word;
  }

  .card-seo-google__date {
    color: #808080;
  }

  .card-seo-facebook {
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 14px;
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      '.SFNSText-Regular',
      sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    transition:
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-facebook {
      width: 500px;
    }
  }

  .card-seo-facebook__image {
    height: 180px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top: 1px solid #dadde1;
    border-bottom: 1px solid #dadde1;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-facebook__image {
      border: 1px solid #dadde1;
      width: 500px;
      height: 261px;
    }
  }

  .card-seo-facebook__content {
    border-collapse: separate;
    color: #4b4f56;
    direction: ltr;
    display: block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    height: 46px;
    line-height: 14.4px;
    max-height: 46px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    user-select: none;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-font-smoothing: antialiased;
  }

  .card-seo-facebook__text {
    padding: 10px 12px;
    color: #1d2129;
    border-bottom: 1px solid #dadde1;
    background: #f2f3f5;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-facebook__text {
      border: 1px solid #dadde1;
      border-top: none;
    }
  }

  .card-seo-facebook__title {
    margin-top: 5px;
    border-collapse: separate;
    color: #1d2129;
    cursor: pointer;
    direction: ltr;
    display: inline;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 600;
    height: auto;
    letter-spacing: normal;
    line-height: 18px;
    text-align: left;
    text-decoration-color: #1d2129;
    text-decoration-line: none;
    text-decoration-style: solid;
    transition-delay: 0s;
    transition-duration: 0.1s;
    transition-property: color;
    transition-timing-function: ease-in-out;
    user-select: none;
    white-space: normal;
    width: auto;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-font-smoothing: antialiased;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-facebook__title {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .card-seo-facebook__description {
    border-collapse: separate;
    color: #606770;
    direction: ltr;
    display: -webkit-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 18px;
    line-height: 20px;
    margin-top: 3px;
    max-height: 80px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    text-overflow: ellipsis;
    user-select: none;
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-box-orient: vertical;
    -webkit-font-smoothing: antialiased;
    -webkit-line-clamp: 1;
  }

  .card-seo-facebook__link {
    border-collapse: separate;
    color: #606770;
    direction: ltr;
    display: block;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    height: 11px;
    line-height: 11px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;
    user-select: none;
    white-space: nowrap;
    width: 501px;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-font-smoothing: antialiased;
  }

  .card-seo-twitter {
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    border-radius: 14px;
    border-style: solid;
    border-color: #e1e8ed;
    border-width: 1px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    transition:
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  .card-seo-twitter:before {
    content: '';
    position: absolute;
    top: 0;
    left: -48px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #ebf1fa;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-twitter {
      width: 506px;
      border-radius: 0.42857em;
      overflow: hidden;
    }

    .card-seo-twitter:before {
      display: none;
    }
  }

  @media only screen and (min-width: 770px) {
    .card-seo-twitter:hover {
      background-color: #f5f8fa;
      border-color: rgba(136, 153, 166, 0.5);
    }
  }

  .card-seo-twitter__image {
    height: 150px;
    background-size: cover;
    background-color: #e1e8ed;
    background-repeat: no-repeat;
    background-position: center;
    border-style: solid;
    border-color: inherit;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    overflow: hidden;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-twitter__image {
      width: 504px;
      height: 252px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      overflow: hidden;
    }
  }

  .card-seo-twitter__text {
    padding: 9px;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-twitter__text {
      padding: 0.75em 1em;
    }
  }

  .card-seo-twitter__title {
    display: block;
    margin: 0 0 0.15em;
    font-size: 14px;
    line-height: 18.375px;
    letter-spacing: normal;
    overflow: hidden;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-twitter__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1em;
      font-weight: bold;
      line-height: 1.3em;
      max-height: 1.3em;
    }
  }

  .card-seo-twitter__description {
    display: none;
    height: 2.6em;
    max-height: 2.6em;
    margin-top: 0.32333em;
    line-height: 1.3em;
    letter-spacing: normal;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-twitter__description {
      display: block;
      display: -webkit-box;
    }
  }

  .card-seo-twitter__link {
    display: block;
    color: #657786;
    text-transform: lowercase;
    line-height: 1.3125;
    letter-spacing: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-twitter__link {
      margin-top: 0.32333em;
      color: #8899a6;
    }
  }

  .card-seo-linkedin {
    cursor: pointer;
    position: relative;
    display: block;
    background-color: #ffffff;
    overflow: hidden;
    font-size: 14px;
    font-family:
      system-ui,
      -apple-system,
      BlinkMacSystemFont,
      '.SFNSText-Regular',
      sans-serif;
    -webkit-font-smoothing: subpixel-antialiased;
    transition:
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-linkedin {
      width: 552px;
    }
  }

  .card-seo-linkedin__image {
    height: 288px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #dadde1;
  }

  @media only screen and (min-width: 770px) {
    .card-seo-linkedin__image {
      width: 552px;
    }
  }

  .card-seo-linkedin__text {
    padding: 8px 16px;
    color: #1d2129;
    border: 1px solid #e6e9ec;
    border-top: none;
    background: #f3f6f8;
    display: flex;
    flex-grow: 1;
  }

  .card-seo-linkedin__title {
    display: block;
    direction: ltr;
    font-family:
      -apple-system,
      system-ui,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Helvetica Neue,
      Fira Sans,
      Ubuntu,
      Oxygen,
      Oxygen Sans,
      Cantarell,
      Droid Sans,
      Apple Color Emoji,
      Segoe UI Emoji,
      Segoe UI Emoji,
      Segoe UI Symbol,
      Lucida Grande,
      Helvetica,
      Arial,
      sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.9);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 40px;
  }

  .card-seo-linkedin__description {
    border-collapse: separate;
    color: #606770;
    direction: ltr;
    display: -webkit-box;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 18px;
    line-height: 20px;
    margin-top: 3px;
    max-height: 80px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: left;
    text-overflow: ellipsis;
    user-select: none;
    white-space: normal;
    word-break: break-word;
    word-wrap: break-word;
    -webkit-border-horizontal-spacing: 0px;
    -webkit-border-vertical-spacing: 0px;
    -webkit-box-orient: vertical;
    -webkit-font-smoothing: antialiased;
    -webkit-line-clamp: 1;
  }

  .card-seo-linkedin__link {
    font-family:
      -apple-system,
      system-ui,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Helvetica Neue,
      Fira Sans,
      Ubuntu,
      Oxygen,
      Oxygen Sans,
      Cantarell,
      Droid Sans,
      Apple Color Emoji,
      Segoe UI Emoji,
      Segoe UI Emoji,
      Segoe UI Symbol,
      Lucida Grande,
      Helvetica,
      Arial,
      sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 16px;
  }

  .card-seo-pinterest {
    color: #211922;
    width: 236px;
    column-rule-color: #211922;
    perspective-origin: 130px 166px;
    transform-origin: 130px 166px;
    caret-color: #211922;
    cursor: zoom-in;
    border: 0px none #211922;
    font:
      normal normal 400 normal 12px / normal 'Helvetica Neue',
      Helvetica,
      'ヒラギノ角ゴ Pro W3',
      'Hiragino Kaku Gothic Pro',
      メイリオ,
      Meiryo,
      'ＭＳ Ｐゴシック',
      arial,
      sans-serif;
    outline: #211922 none 0px;
    transition:
      transform 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }

  .card-seo-pinterest__image {
    position: relative;
    width: 236px;
    border-radius: 8px;
    overflow: hidden;
  }

  .card-seo-pinterest__image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
  }

  .pinterest-image {
    max-width: 100%;
  }

  .card-seo-pinterest__content {
    display: flex;
    padding: 4px 8px;
  }

  .card-seo-pinterest__title {
    color: #333333;
    letter-spacing: -0.4px;
    overflow-wrap: break-word;
    text-align: left;
    text-decoration: none solid #333333;
    text-size-adjust: 100%;
    word-wrap: break-word;
    column-rule-color: #333333;
    hyphens: auto;
    perspective-origin: 0px 0px;
    transform-origin: 0px 0px;
    caret-color: #333333;
    border: 0px none #333333;
    font:
      normal normal 700 normal 14px / 16.8px -apple-system,
      system-ui,
      'Segoe UI',
      'Roboto Oxygen-Sans',
      Ubuntu,
      Cantarell,
      '“Fira Sans”',
      '“Droid Sans”',
      'Helvetica Neue',
      Helvetica,
      '\\\\30D2ラギノ角ゴPro W3',
      'Hiragino Kaku Gothic Pro',
      '\\\\30E1イリオ',
      Meiryo,
      'ＭＳ Ｐゴシック',
      Arial,
      sans-serif;
    outline: #333333 none 0px;
  }

  .card-seo-pinterest__dots {
    display: flex;
    margin-top: 6px;
  }

  .card-seo-pinterest__dot {
    width: 3px;
    height: 3px;
    border-radius: 1.5px;
    background: #8e8e8e;
    margin-left: 2px;
  }

  .card-seo-slack {
    display: flex;
    font-family: 'Noto Sans', 'NotoSansJP', 'Slack-Lato', 'Lato', 'appleLogo', sans-serif;
    font-size: 15px;
    line-height: 1.46666667;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  .card-seo-slack__bar {
    flex-shrink: 0;
    width: 4px;
    background-color: #e8e8e8;
    border-radius: 8px;
  }

  .card-seo-slack__content {
    padding: 0 12px;
    max-width: 520px;
    width: 100%;
    text-align: left;
  }

  .card-seo-slack__favicon {
    border-radius: 2px;
    box-sizing: content-box;
    height: 16px;
    margin-right: 6px;
    overflow: hidden;
    vertical-align: middle;
    width: 16px;
  }

  .card-seo-slack__link {
    color: #717274;
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
  }

  .card-seo-slack__title {
    color: #0576b9;
    font-weight: 700;
    font-variant-ligatures: common-ligatures;
  }

  .card-seo-slack__description {
    color: #2c2d30;
  }

  .card-seo-slack__image {
    border-radius: 4px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    max-width: 360px;
    height: 189px;
    background-size: cover;
    background-position: 50%;
  }
  /*! CSS Used from: https://metatags.io/assets/application-da5c3d0437685cc895e9cc55d990b135eb6c802e2dd5ad29cd4019d95db276bc.css ; media=all */
  @media all {
    img {
      border: 0;
    }
    *,
    *:before,
    *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    h4 {
      margin: 0;
      font-weight: 500;
    }
    h4 {
      font-size: 14px;
      letter-spacing: 0.015em;
      color: #a3b3ca;
    }
    ::selection {
      color: #fff;
      background: #18283e;
    }
    ::-moz-selection {
      color: #fff;
      background: #18283e;
    }
    img {
      max-width: 100%;
      vertical-align: middle;
    }
    ::-webkit-input-placeholder {
      color: #99abc8;
    }
    ::-moz-placeholder {
      color: #99abc8;
    }
    :-ms-input-placeholder {
      color: #99abc8;
    }
    :-moz-placeholder {
      color: #99abc8;
    }
    ::-webkit-input-placeholder {
      color: #a0a8ae;
    }
    .index-metadata__content h4 {
      display: flex;
      margin-bottom: 0.5em;
    }
    .metadata-group__display {
      display: none;
      position: relative;
    }
    .metadata-group__display.is-active {
      display: block;
    }
    h4.metadata-group__title {
      position: relative;
      margin: 1.75em 0 1em;
    }
    h4.metadata-group__title:before {
      content: '';
      position: absolute;
      top: 10px;
      right: 0;
      width: 100%;
      height: 1px;
      background: #e0e7f1;
    }
    h4.metadata-group__title span {
      position: relative;
      background: #fff;
      padding-right: 12px;
    }
    .card-seo-pinterest {
      color: #211922;
      width: 236px;
      column-rule-color: #211922;
      perspective-origin: 130px 166px;
      transform-origin: 130px 166px;
      caret-color: #211922;
      cursor: zoom-in;
      border: 0px none #211922;
      font:
        normal normal 400 normal 12px / normal 'Helvetica Neue',
        Helvetica,
        'ヒラギノ角ゴ Pro W3',
        'Hiragino Kaku Gothic Pro',
        メイリオ,
        Meiryo,
        'ＭＳ Ｐゴシック',
        arial,
        sans-serif;
      outline: #211922 none 0px;
      transition:
        transform 0.2s ease 0s,
        -webkit-transform 0.2s ease 0s;
    }
    .card-seo-pinterest__image {
      position: relative;
      width: 236px;
      border-radius: 8px;
      overflow: hidden;
    }
    .card-seo-pinterest__image:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.05);
    }
    .card-seo-pinterest__content {
      display: flex;
      padding: 4px 8px;
    }
    .card-seo-pinterest__title {
      color: #333333;
      letter-spacing: -0.4px;
      overflow-wrap: break-word;
      text-align: left;
      text-decoration: none solid #333333;
      text-size-adjust: 100%;
      word-wrap: break-word;
      column-rule-color: #333333;
      hyphens: auto;
      perspective-origin: 0px 0px;
      transform-origin: 0px 0px;
      caret-color: #333333;
      border: 0px none #333333;
      font:
        normal normal 700 normal 14px / 16.8px -apple-system,
        system-ui,
        'Segoe UI',
        'Roboto Oxygen-Sans',
        Ubuntu,
        Cantarell,
        '“Fira Sans”',
        '“Droid Sans”',
        'Helvetica Neue',
        Helvetica,
        '\\\\30D2ラギノ角ゴPro W3',
        'Hiragino Kaku Gothic Pro',
        '\\\\30E1イリオ',
        Meiryo,
        'ＭＳ Ｐゴシック',
        Arial,
        sans-serif;
      outline: #333333 none 0px;
    }
    .card-seo-pinterest__dots {
      display: flex;
      margin-top: 6px;
    }
    .card-seo-pinterest__dot {
      width: 3px;
      height: 3px;
      border-radius: 1.5px;
      background: #8e8e8e;
      margin-left: 2px;
    }
  }
`;

export function LinkPreviewPreviewer({ redirect, ...props }) {
  const [state, updateState] = useImmer({ provider: 'facebook' });

  return (
    <Style {...props}>
      <Radio.Group
        defaultValue={state.provider}
        value={state.provider}
        size="large"
        onChange={(e) => {
          const { value } = e.target;

          updateState((draft) => {
            draft.provider = value;
          });
        }}
      >
        <Radio.Button value="facebook" shape="circle">
          <Facebook />
        </Radio.Button>
        <Radio.Button value="twitter" shape="circle">
          <Twitter />
        </Radio.Button>
        <Radio.Button value="linkedin" shape="circle">
          <Linkedin />
        </Radio.Button>
        <Radio.Button value="pinterest" shape="circle">
          <Pinterest />
        </Radio.Button>
        <Radio.Button value="google" shape="circle">
          <Google />
        </Radio.Button>
        <Radio.Button value="slack" shape="circle">
          <Slack />
        </Radio.Button>
      </Radio.Group>
      <br />
      <div className="index-metadata__content">
        {state.provider === 'google' && (
          <div id="google" className="metadata-group__display is-active">
            <h4 className="metadata-group__title">
              <span>Google</span>
            </h4>
            <div className="card-seo-google">
              <span className="card-seo-google__title js-preview-title">{redirect.preview.title}</span>
              <div className="card-seo-google__url">
                <span className="card-seo-google__url-title js-preview-domain">{extractDomain(redirect.url)}</span>
                <span className="card-seo-google__url-arrow" />
              </div>
              <span className="card-seo-google__description js-preview-description">
                {redirect.preview.description}
              </span>
            </div>
          </div>
        )}
        {state.provider === 'facebook' && (
          <div id="facebook" className="metadata-group__display is-active">
            <div className="card-seo-facebook">
              <div
                className="card-seo-facebook__image js-preview-image"
                style={{ backgroundImage: `url(${redirect.preview.picture})` }}
              />
              <div className="card-seo-facebook__text">
                <span className="card-seo-facebook__link js-preview-domain">{extractDomain(redirect.url)}</span>
                <div className="card-seo-facebook__content">
                  <div style={{ marginTop: '5px' }}>
                    <div className="card-seo-facebook__title js-preview-title">{redirect.preview.title}</div>
                  </div>
                  <span className="card-seo-facebook__description js-preview-description">
                    {redirect.preview.description}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        {state.provider === 'twitter' && (
          <div id="twitter" className="metadata-group__display is-active">
            <div className="card-seo-twitter">
              <div
                className="card-seo-twitter__image js-preview-image"
                style={{ backgroundImage: `url(${redirect.preview.picture})` }}
              />
              <div className="card-seo-twitter__text">
                <span className="card-seo-twitter__title js-preview-title">{redirect.preview.title}</span>
                <span className="card-seo-twitter__description js-preview-description">
                  {redirect.preview.description}
                </span>
                <span className="card-seo-twitter__link js-preview-domain">{extractDomain(redirect.url)}</span>
              </div>
            </div>
          </div>
        )}
        {state.provider === 'linkedin' && (
          <div id="linkedin" className="metadata-group__display is-active">
            <div className="card-seo-linkedin">
              <div
                className="card-seo-linkedin__image js-preview-image"
                style={{ backgroundImage: `url(${redirect.preview.picture})` }}
              />
              <div className="card-seo-linkedin__text">
                <div className="card-seo-linkedin__content">
                  <div className="card-seo-linkedin__title js-preview-title">{redirect.preview.title}</div>
                  <span className="card-seo-linkedin__link js-preview-domain">{extractDomain(redirect.url)}</span>
                </div>
              </div>
            </div>
          </div>
        )}
        {state.provider === 'pinterest' && (
          <div id="pinterest" className="metadata-group__display is-active">
            <div className="card-seo-pinterest">
              <div className="card-seo-pinterest__image">
                <img alt={' '} id="pinterest-image" className="js-preview-img" src={redirect.preview.picture} />
              </div>
              <div className="card-seo-pinterest__content">
                <div className="card-seo-pinterest__title js-preview-title">{redirect.preview.title}</div>
                <div className="card-seo-pinterest__dots">
                  <div className="card-seo-pinterest__dot" />
                  <div className="card-seo-pinterest__dot" />
                  <div className="card-seo-pinterest__dot" />
                </div>
              </div>
            </div>
          </div>
        )}
        {state.provider === 'slack' && (
          <div id="slack" className="metadata-group__display is-active">
            <div className="card-seo-slack">
              <div className="card-seo-slack__bar" />
              <div className="card-seo-slack__content">
                <div className="flex" style={{ alignItems: 'center' }}>
                  <Favicon
                    className="card-seo-slack__favicon js-preview-favicon"
                    referrerPolicy="no-referrer"
                    src={`${s(s(window).env).REACT_APP_ICON_URL}/?url=${encodeURIComponent(redirect.url)}&size=32`}
                  />
                  <span className="card-seo-slack__link js-preview-site-name">{extractDomain(redirect.url)}</span>
                </div>
                <div className="card-seo-slack__title js-preview-title">{redirect.preview.title}</div>
                <span className="card-seo-slack__description js-preview-description">
                  {redirect.preview.description}
                </span>
                <div
                  className="card-seo-slack__image js-preview-image js-slack-image"
                  style={{
                    backgroundImage: `url(${redirect.preview.picture})`,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Style>
  );
}
