import React, { useEffect, useState, Fragment } from 'react';

import { Empty, message, Table, Tabs } from 'antd';
import Column from 'antd/es/table/Column';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { parse } from 'json2csv';
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'react-file-download';
import ReactJson from 'react-json-view';

import axios from 'lib/axios.factory';

import { DATE_FORMAT } from '../../../../../consts/consts';
import { useLoading } from '../../../../../hooks/useLoading';
import {
  getRawAdsProviderReport,
  getRawCampaign,
  getRawProductProviderReport,
} from '../../../../../lib/adsPlateformApi';
import { HoverLink } from '../../../../common/HoverLink';
import { Csv } from '../../../../common/illustrations/Csv';
import { MutedText } from '../../../../common/MutedText';

export function CampaignDebugModal(props: {
  account: accountT,
  campaign: campaignT,
  onFinish: Function,
  visible: boolean,
}) {
  const [form] = useForm();
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState(undefined);
  const { doAction: doCampaignAction, ExclusiveInlineLoadingContainer: CampaignLoader } = useLoading(true);
  const [adsProviderReport, setAdsProviderReport] = useState(undefined);
  const { doAction: doAdsProviderReportAction, ExclusiveInlineLoadingContainer: AdsProviderReportLoader } =
    useLoading(true);
  const [productProviderCurls, setProductProviderCurls] = useState(undefined);
  const [productProviderReport, setProductProviderReport] = useState(undefined);
  const { doAction: doProductProviderReportAction, ExclusiveInlineLoadingContainer: ProductProviderReportLoader } =
    useLoading(true);

  async function loadRawCampaign() {
    setLoading(true);
    setCampaign(undefined);

    try {
      try {
        const campaignResponse = await doCampaignAction(
          async () => await getRawCampaign(localStorage, axios, { accountId: props.account.id }, props.campaign.id),
        );

        setCampaign(campaignResponse);
      } catch (e) {
        message.error('Error while loading campaign from Google');
      }

      doAdsProviderReportAction(
        async () =>
          await getRawAdsProviderReport(localStorage, axios, { accountId: props.account.id }, props.campaign.id),
      ).then((adsProviderResponse) => {
        setAdsProviderReport(adsProviderResponse.metrics);
      });

      doProductProviderReportAction(
        async () =>
          await getRawProductProviderReport(
            localStorage,
            axios,
            { accountId: props.account.id },
            props.campaign.id,
          ).then((productProviderResponse) => {
            setProductProviderCurls(productProviderResponse.curls);
            setProductProviderReport(productProviderResponse.metrics);
          }),
      );
    } catch (e) {
      message.error('Unable to retrieve debug for campaign');

      throw e;
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.visible) {
      loadRawCampaign().then();
    }
  }, [props.campaign.id, props.visible]);

  const providerType = _.get(campaign, ['campaign', 'providerType']);

  return (
    <Modal
      visible={props.visible}
      title="Debug Campaign"
      style={{
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'white !important',
      }}
      bodyStyle={{ maxHeight: 'calc( 100vh - 110px)', overflow: 'auto' }}
      width="100vw"
      height="100vh"
      loading={loading}
      okButtonProps={{ loading }}
      onCancel={props.onFinish}
      onOk={form.submit}
    >
      <Tabs>
        <Tabs.TabPane tab={`PixelMe and ${providerType || ''} campaign`} key="1">
          <CampaignLoader>
            <h3>PixelMe campaign {props.campaign.id}</h3>
            <ReactJson collapsed={true} src={_.get(campaign, 'campaign')} />
            <br />
            <h3>Campaign in {providerType}</h3>
            <ReactJson
              collapsed={true}
              src={
                _.get(campaign, 'googleRawCampaign') ||
                _.get(campaign, 'facebookRawCampaign') ||
                _.get(campaign, 'tiktokRawCampaign') ||
                _.get(campaign, 'genericRawCampaign')
              }
            />
            <br />
          </CampaignLoader>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${providerType || ''} data`} key="2">
          <AdsProviderReportLoader>
            {_.get(adsProviderReport, 'length') > 0 && (
              <div>
                <div style={{ width: 120, height: 50 }}>
                  <HoverLink
                    style={{ width: '100%' }}
                    content="Download data"
                    onClick={(e) => {
                      const result = parse(adsProviderReport);

                      fileDownload(result, `${_.get(campaign, ['campaign', 'name'])}_${providerType}.csv`);
                    }}
                    children={<Csv />}
                  />
                </div>
                <MutedText>
                  This is pure raw data from {_.get(campaign, ['campaign', 'providerType'])}, it's not filter, it's all
                  the data we got
                </MutedText>
                <Table dataSource={adsProviderReport} size="small" rowKey="id" scroll={{ x: 1000 }}>
                  <Column
                    title="Date"
                    dataIndex="date"
                    key="date"
                    sorter={(a, b) => moment(a.date).unix() - moment(b.date).unix()}
                    render={(v) => v && moment(v).format(DATE_FORMAT)}
                    width={100}
                  />
                  <Column
                    title="Campaign Id"
                    dataIndex="campaignId"
                    key="campaignId"
                    width={100}
                    sorter={(a, b) => `${a.campaignId}`.localeCompare(b.campaignId)}
                  />
                  <Column
                    title="External Campaign Id"
                    dataIndex="externalCampaignId"
                    key="externalCampaignId"
                    width={100}
                    sorter={(a, b) => `${a.externalCampaignId}`.localeCompare(b.externalCampaignId)}
                  />
                  <Column
                    title="External Variation Id"
                    dataIndex="externalVariationId"
                    key="externalVariationId"
                    width={150}
                    sorter={(a, b) => `${a.externalVariationId}`.localeCompare(b.externalVariationId)}
                  />
                  <Column
                    title="Impressions"
                    dataIndex="impressions"
                    key="impressions"
                    sorter={(a, b) => a.impressions - b.impressions}
                    width={50}
                  />
                  <Column
                    title="Clicks"
                    dataIndex="clicks"
                    key="clicks"
                    sorter={(a, b) => a.clicks - b.clicks}
                    width={50}
                  />
                  <Column
                    title="AverageCpc"
                    dataIndex="averageCpc"
                    key="averageCpc"
                    sorter={(a, b) => a.averageCpc - b.averageCpc}
                    width={50}
                  />
                  <Column
                    title="AdSpent"
                    dataIndex="adSpent"
                    key="adSpent"
                    sorter={(a, b) => a.adSpent - b.adSpent}
                    width={50}
                  />
                </Table>
              </div>
            )}
            {_.get(adsProviderReport, 'length') == 0 && (
              <div>
                <Empty description={`No data from ${providerType} for this campaign`} />
              </div>
            )}
          </AdsProviderReportLoader>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Amazon data" key="3">
          <ProductProviderReportLoader>
            <ReactJson collapsed={true} src={productProviderCurls} />
            {_.get(productProviderReport, 'length') > 0 && (
              <div>
                <div style={{ width: 120, height: 50 }}>
                  <HoverLink
                    content="Download data"
                    onClick={(e) => {
                      const result = parse(productProviderReport);

                      fileDownload(result, `${_.get(campaign, ['campaign', 'name'])}_amazon.csv`);
                    }}
                    children={<Csv />}
                  />
                </div>
                <MutedText>
                  This is pure raw data from Amazon, it's not filter, it's all the data we got. If campaignId is filled
                  that means that the row is linked to this campaign, if not, it can be link to another campaign inside
                  or outside PixelMe
                </MutedText>
                <Table dataSource={productProviderReport} size="small" rowKey="id" scroll={{ x: 1000 }}>
                  <Column
                    title="Date"
                    dataIndex="date"
                    sorter={(a, b) => moment(a.date).unix() - moment(b.date).unix()}
                    key="date"
                    render={(v) => v && moment(v).format(DATE_FORMAT)}
                    width={100}
                  />
                  <Column
                    title="Campaign Id"
                    dataIndex="campaignId"
                    key="campaignId"
                    width={100}
                    sorter={(a, b) => `${a.campaignId}`.localeCompare(b.campaignId)}
                  />
                  <Column
                    title="External Campaign Id"
                    dataIndex="externalCampaignId"
                    key="externalCampaignId"
                    width={100}
                    sorter={(a, b) => `${a.externalCampaignId}`.localeCompare(b.externalCampaignId)}
                  />
                  <Column
                    title="External Variation Id"
                    dataIndex="externalVariationId"
                    key="externalVariationId"
                    onFilter={(value: string, record) => record.externalVariationId.indexOf(value) === 0}
                    width={150}
                    sorter={(a, b) => `${a.externalVariationId}`.localeCompare(b.externalVariationId)}
                  />
                  <Column
                    title="Clicks"
                    dataIndex="clicks"
                    key="clicks"
                    width={50}
                    sorter={(a, b) => a.clicks - b.clicks}
                  />
                  <Column
                    title="AttributedDetailPageViewsClicks14d"
                    dataIndex="attributedDetailPageViewsClicks14d"
                    key="attributedDetailPageViewsClicks14d"
                    sorter={(a, b) => a.attributedDetailPageViewsClicks14d - b.attributedDetailPageViewsClicks14d}
                    width={50}
                  />
                  <Column
                    title="AttributedAddToCartClicks14d"
                    dataIndex="attributedAddToCartClicks14d"
                    key="attributedAddToCartClicks14d"
                    sorter={(a, b) => a.attributedAddToCartClicks14d - b.attributedAddToCartClicks14d}
                    width={50}
                  />
                  <Column
                    title="AttributedTotalAddToCartClicks14d"
                    dataIndex="attributedTotalAddToCartClicks14d"
                    key="attributedTotalAddToCartClicks14d"
                    sorter={(a, b) => a.attributedAddToCartClicks14d - b.attributedAddToCartClicks14d}
                    width={50}
                  />
                  <Column
                    title="AttributedPurchases14d"
                    dataIndex="attributedPurchases14d"
                    key="attributedPurchases14d"
                    sorter={(a, b) => a.attributedPurchases14d - b.attributedPurchases14d}
                    width={50}
                  />
                  <Column
                    title="AttributedTotalPurchases14d"
                    dataIndex="attributedTotalPurchases14d"
                    key="attributedTotalPurchases14d"
                    sorter={(a, b) => a.attributedTotalPurchases14d - b.attributedTotalPurchases14d}
                    width={50}
                  />
                  <Column
                    title="AttributedSales14d"
                    dataIndex="attributedSales14d"
                    key="attributedSales14d"
                    sorter={(a, b) => a.attributedSales14d - b.attributedSales14d}
                    width={50}
                  />
                  <Column
                    title="TotalAttributedSales14d"
                    dataIndex="totalAttributedSales14d"
                    key="totalAttributedSales14d"
                    sorter={(a, b) => a.totalAttributedSales14d - b.totalAttributedSales14d}
                    width={50}
                  />
                  <Column
                    title="UnitsSold14d"
                    dataIndex="unitsSold14d"
                    key="unitsSold14d"
                    sorter={(a, b) => a.unitsSold14d - b.unitsSold14d}
                    width={50}
                  />
                  <Column
                    title="TotalUnitsSold14d"
                    dataIndex="totalUnitsSold14d"
                    key="totalUnitsSold14d"
                    sorter={(a, b) => a.totalUnitsSold14d - b.totalUnitsSold14d}
                    width={50}
                  />
                  <Column
                    title="AttributedNewToBrandPurchases14d"
                    dataIndex="attributedNewToBrandPurchases14d"
                    key="attributedNewToBrandPurchases14d"
                    width={50}
                  />
                  <Column
                    title="AttributedNewToBrandUnitsSold14d"
                    dataIndex="attributedNewToBrandUnitsSold14d"
                    key="attributedNewToBrandUnitsSold14d"
                    width={50}
                  />
                  <Column
                    title="AttributedNewToBrandSales14d"
                    dataIndex="attributedNewToBrandSales14d"
                    key="attributedNewToBrandSales14d"
                    width={50}
                  />
                  <Column title="Brb Amount" dataIndex="brbBonusAmount" key="brbBonusAmount" width={50} />
                  <Column
                    title="Brb Amount Estimated"
                    dataIndex="brbBonusAmountCalculated"
                    key="brbBonusAmountCalculated"
                    width={50}
                  />
                  <Column
                    title="AttributionTagCampaignID"
                    dataIndex="attributionTagCampaignID"
                    key="attributionTagCampaignID"
                    width={50}
                  />
                  <Column
                    title="AttributionTagAdGroupID"
                    dataIndex="attributionTagAdGroupID"
                    key="attributionTagAdGroupID"
                    width={50}
                  />
                  <Column
                    title="AttributionTagAdvertiserName"
                    dataIndex="attributionTagAdvertiserName"
                    key="attributionTagAdvertiserName"
                    width={50}
                  />
                  <Column
                    title="AttributionTagPublisher"
                    dataIndex="attributionTagPublisher"
                    key="attributionTagPublisher"
                    width={50}
                  />
                  <Column
                    title="AttributionTagCreativeID"
                    dataIndex="attributionTagCreativeID"
                    key="attributionTagCreativeID"
                    width={50}
                  />
                  <Column title="AlgorithmUsed" dataIndex="algorithmUsed" key="algorithmUsed" width={50} />
                </Table>
                {_.get(productProviderReport, 'length') == 0 && (
                  <div>
                    <Empty description="No data from Amazon for this campaign" />
                    <MutedText>
                      This is pure raw data from Amazon, it's not filter, it's all the data we got. If campaignId is
                      filled that means that the row is linked to this campaign, if not, it can be link to another
                      campaign inside or outside PixelMe
                    </MutedText>
                  </div>
                )}
              </div>
            )}
          </ProductProviderReportLoader>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}
