import React, { useState } from 'react';

import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { Tooltip } from 'antd';

import { SearchTermsModal } from './SearchTermsModal.jsx';
import { IconInsideButton } from '../../AudienceBuilder/Campaigns/campaignTree/Common';

export default function SearchTermsCampaignView(): React$Element<'div'> {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <SearchTermsModal visible={visible} onFinish={() => setVisible(false)} />
      <IconInsideButton onClick={() => setVisible(true)}>
        <Tooltip title="View Search Terms">
          <SearchOutlined style={{ color: '#2E89E2' }} />
        </Tooltip>
      </IconInsideButton>
    </div>
  );
}
