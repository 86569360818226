import React from 'react';

import { SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';

import { StackIcon } from '../icons/StackIcon';

export const AbstractSortableStackBullet = styled(StackIcon).withConfig({
  displayName: 'DropDownValuesBullet',
})`
  display: flex;
  align-items: center;
  align-self: center;
  cursor: move;
  height: 50px;
  width: 50px;
  opacity: 1;
  padding: 1em;
  margin-right: 5px;
  flex-shrink: 1;
`;

export const SortableStackBullet = SortableHandle(({ className }) => (
  <AbstractSortableStackBullet className={className} />
));
