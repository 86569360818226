import React, { useEffect, useState } from 'react';

import { Form, Select } from 'antd';
import styled from 'styled-components';

import { getAdsProviders } from '../../../../lib/adsProvider/google';
import axios from '../../../../lib/axios.factory';
import { getFacebookAdPreview } from '../../../../lib/facebookPlateformApi';

const Style = styled.div`
  .ant-select {
    width: 400px;
  }
  iframe {
    width: 600px !important;
    height: 600px !important;
  }
`;

/**
 * Facebook Ad Preview
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookAdPreview(props: { account: accountT, creativeId: any, adsProvider: adsProviderT }) {
  const [adFormat, setAdFormat] = useState('MOBILE_FEED_STANDARD');
  const [previewUrl, setPreviewUrl] = useState(undefined);
  const adsProvider = getAdsProviders(props.account).find((p) => p.id === props.adsProvider.id);

  async function load() {
    try {
      const previewData = await getFacebookAdPreview(localStorage, axios, adsProvider, adFormat, props.creativeId);

      setPreviewUrl(previewData[0].body);
    } catch (e) {
      throw e;
    }
  }

  useEffect(() => {
    if (props.creativeId) {
      load().then();
    }
  }, [adFormat, props.creativeId]);

  return (
    <Style>
      <Form.Item label="Ad format">
        <Select
          defaultValue={adFormat}
          onSelect={(value) => {
            setAdFormat(value);
          }}
        >
          <Select.Option value="RIGHT_COLUMN_STANDARD">Right Column standard</Select.Option>
          <Select.Option value="DESKTOP_FEED_STANDARD">Desktop feed standard</Select.Option>
          <Select.Option value="MOBILE_FEED_STANDARD">Mobile feed standard</Select.Option>
          <Select.Option value="MOBILE_BANNER">Mobile banner</Select.Option>
          <Select.Option value="MOBILE_INTERSTITIAL">Mobile interstitial</Select.Option>
          <Select.Option value="MOBILE_FEED_BASIC">Mobile feed basic</Select.Option>
          <Select.Option value="MOBILE_NATIVE">Mobile native</Select.Option>
          <Select.Option value="INSTAGRAM_STANDARD">Instagram feed</Select.Option>
        </Select>
      </Form.Item>
      <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: previewUrl }}></div>
    </Style>
  );
}
