import './Lifetime.css';
import React, { Component } from 'react';

import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import Box from '../../common/Box';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Separator from '../../common/Separator';

type propsT = {
  updating: boolean,
  sub: subscriptionT,
  addCoupon: (string) => void,
  limitations: { allowCouponsStack: boolean },
};

type stateT = {
  coupon: string,
};

export default class extends Component<propsT, stateT> {
  constructor(props: propsT) {
    super(props);
    this.state = {
      coupon: '',
    };
  }
  render() {
    const { sub, addCoupon, updating, limitations } = this.props;
    const { coupon } = this.state;

    return (
      <Box header={`${sub.codes.length} coupon${sub.codes.length > 1 ? 's' : ''} used`}>
        <div className="coupons">
          {sub.codes.map((c) => (
            <div key={c} className="coupon">
              {c}
            </div>
          ))}
        </div>
        {sub.status === 'revoked' && (
          <>
            <Separator />
            <p className="revoked">{getIntlStrings('endOfTrial.revoked')} Please contact us.</p>
          </>
        )}
        {limitations.allowCouponsStack && sub.status !== 'revoked' && (
          <>
            <Separator />
            <form className="coupons__add">
              <Input id="code" value={coupon} placeholder="appsumo_xxxx" onChange={this.handleCouponChange} />
              {updating && <Button disabled>Adding coupon...</Button>}
              {!updating && (
                <Button
                  handleClick={(event: SyntheticEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    addCoupon(coupon);
                  }}
                >
                  Add coupon
                </Button>
              )}
            </form>
          </>
        )}
      </Box>
    );
  }

  componentDidUpdate(prevProps: propsT, prevState: stateT) {
    if (prevProps.updating !== this.props.updating) {
      this.setState({ coupon: '' });
    }
  }

  handleCouponChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;

    this.setState({
      coupon: value,
    });
  };
}
