import styled from 'styled-components';

export const DropDownElement = styled.li.withConfig({
  displayName: 'DropDownElement',
})`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0.5rem 0.8rem;
  width: 100%;
`;
