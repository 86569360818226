import styled from 'styled-components';

export const BaseBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  padding: 0 1rem;
  font-weight: 100;
  font-size: var(--small-size);
  color: var(--white);
  text-align: center;
`;
