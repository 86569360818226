import React, { Fragment, useEffect } from 'react';

export function FixedValueFormItem({ fixedValue, value, onChange, defaultValue }) {
  useEffect(() => {
    if (fixedValue !== value) {
      onChange(fixedValue);
    }
  }, [fixedValue]);

  return <Fragment />;
}
