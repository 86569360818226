import React, { useEffect, useState, Fragment } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Alert, Form, Select } from 'antd';
import Button from 'antd/lib/button';
import _ from 'lodash';
import styled from 'styled-components';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';

import { getTiktokAllImages } from '../../../../../lib/tiktokPlateformApi';

const Style = styled.div`
  position: relative;
  height: 170px;

  .ant-select-selector {
    height: 100px !important;
    min-height: fit-content !important;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`;

/**
 * Tiktok Images selector
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ImagesSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  type: string,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [data, setData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const genericResponse = await getTiktokAllImages(localStorage, axios, props.adsProvider, {
      accountId: props.account.id,
      osType: props.type,
    });

    const d = _.get(genericResponse, 'list', []).map((l) => ({
      id: l.imageId,
      src: l.imageUrl,
      thumbnail: l.imageUrl,
      thumbnailWidth: l.width / 10,
      thumbnailHeight: l.height / 10,
    }));

    setData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <Style>
      <ExclusiveInlineLoadingContainer>
        <Form.Item noStyle>
          <Alert
            type="info"
            message={
              <div>
                Create a new image with the{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://ads.tiktok.com/i18n/material/image?aadvid=${props.adsProvider.externalId}`}
                >
                  {' '}
                  TikTok’s Image editor
                </a>
              </div>
            }
          />
        </Form.Item>
        <br />
        <Button
          style={{ position: 'absolute', top: -30, right: 0, minWidth: 20 }}
          shape="circle"
          size="small"
          icon={<ReloadOutlined />}
          onClick={() => {
            doAction(load);
          }}
        />
        {data.length > 0 && (
          <Fragment>
            <Select
              value={props.value}
              defaultValue={props.defaultValue}
              onChange={(e) => {
                props.onChange(e);
              }}
              size="large"
              placeholder="Select Image"
              allowClear
            >
              {data.map((l) => (
                <Select.Option key={l.id} value={l.id}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1em',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      maxHeight: '100%',
                      overflow: 'hidden',
                    }}
                  >
                    <img style={{ padding: '1em' }} height={200} src={l.src} alt=""></img>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Fragment>
        )}
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}
