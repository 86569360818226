import './Placeholders.css';

import React, { Fragment } from 'react';

import { Spinner } from '@blueprintjs/core';
import styled from 'styled-components';

import NewLogoIcon from 'components/layout/NewLogoIcon';

import { MutedText } from './MutedText';

export function Line({ motion = true }: { motion?: boolean }) {
  return (
    <div className={`placeholder${motion ? '' : ' motion-less'}`}>
      <div className="placeholder__line" />
    </div>
  );
}

export function Graph({ motion = true }: { motion?: boolean }) {
  return (
    <svg
      className={`placeholder__svg${motion ? '' : ' motion-less'}`}
      width="86"
      height="60"
      viewBox="0 0 86 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // id="Vector 5"
        d="M0 44L22.3306 19.6017C25.1403 16.5319 29.5574 15.523 33.4213 17.0685L47.7955 22.8182C51.798 24.4192 56.3759 23.2751 59.1546 19.9794L76 0"
        transform="translate(5 8)"
        // stroke="#F2F2F2"
        strokeWidth="10"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function LineGraphPlaceholder() {
  return (
    <div className="placeholder placeholder__line-graph">
      <div className="placeholder__line" />
      <div className="placeholder__graph">
        <Graph />
      </div>
    </div>
  );
}

export function StatsPlaceholder({ motion = true }: { motion?: boolean }) {
  return (
    <div className={`placeholder${motion ? '' : ' motion-less'}`}>
      <div className="placeholder__group">
        <div className="placeholder__line" />
        <div className="placeholder__sbox" />
      </div>
      <Graph motion={motion} />
    </div>
  );
}

export function SmallPlaceholder({ title, text, loading, children }) {
  if (loading === undefined) {
    loading = true;
  }

  return (
    <Fragment>
      {loading && (
        <div
          style={{
            height: '100%',
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            paddingTop: '25%',
          }}
        >
          <h2>{title || 'Work in progress...'}</h2>
          <MutedText>{text}</MutedText>
          <br />
          <Spinner />
        </div>
      )}
      {!loading && children}
    </Fragment>
  );
}

const Style = styled.div`
  .bp3-spinner-animation svg {
    height: 25px !important;
    width: 25px !important;
  }
`;

/**
 * SmallInlinePlaceholder
 * @param title
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
export function SmallCompactPlaceholder({ text }) {
  return (
    <Style
      style={{
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        padding: '1em',
      }}
    >
      <MutedText>{text}</MutedText>
      <Spinner />
    </Style>
  );
}

/**
 * GlobalPlaceholder
 * @param loadingText
 * @returns {JSX.Element}
 * @constructor
 */
export function GlobalPlaceholder({ loadingText = 'Loading' }: { loadingText?: string }) {
  return (
    <div className="container" id="globalPlaceholder">
      <div className="loading">
        <NewLogoIcon alt="logo" style={{ width: 'auto' }} />
        <div className="loading__text">{loadingText}</div>
      </div>
    </div>
  );
}

export const PlaceholderText = styled.span.withConfig({
  displayName: 'PlaceholderText',
})`
  background-color: #f2f2f2;
  width: ${(props) => props.width};
  height: 1em;
  border-radius: 20px;
  display: inline-block;
`;
