import { apiSlice } from './apiSlice';

// $FlowFixMe
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductProviders: builder.query({
      query: ({ accountId }) => ({ url: `/product-providers?account_id=${accountId}`, method: 'get' }),
    }),
  }),
});

export const { useGetProductProvidersQuery, useLazyGetProductProvidersQuery } = extendedApiSlice;
