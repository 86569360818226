import './DropDown.css';
import type { Node } from 'react';
import React, { Component, Fragment } from 'react';

import { arrayMove } from 'react-sortable-hoc';
import styled from 'styled-components';

import { isSelected } from './drop-down';
import { verifyParametersRequirements } from './dropDown.verify';
import { DropDownContent } from './DropDownContent';
import { SortableDropDownContent } from './SortableDropDownContent';
import { Polygon } from '../icons/Polygon';

const DropDownContentContainer = styled.div.withConfig({
  displayName: 'DropDownContentContainer',
})`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 902;
  margin-top: 0.2rem;
  padding: 0;
  background: var(--white);
  border: solid var(--border-size) #e5e5e5;
  border-radius: 0.2rem;
  box-shadow: 0px 4px 2px 2px #eeeeee;
  min-width: 180px;
  max-height: ${(props) => (props.scrollable ? '212px' : 'auto')};
  overflow: ${(props) => (props.scrollable ? 'auto' : 'inherit')};

  > * {
    z-index: 101;
  }

  ul {
    padding: 0;
    margin: 0;
  }
  li:hover {
    cursor: pointer;
    background: var(--pixelme-light-color);
  }
`;

const DropDownContainer = styled.div.withConfig({
  displayName: 'DropDownContainer',
})`
  button {
    width: 100%;
  }

  button :hover {
    border: none !important;
  }
`;

type propsT = {
  id: string,
  values: Array<any>,
  onSelect: (Array<string>) => void,
  onSortEnd?: (Array<any>) => void,
  selected: Array<string>, // HOTFIX @STEPHEN it's an array
  disabled?: boolean,
  open?: boolean,
  multi?: boolean,
  adding?: boolean,
  sortValues?: boolean,
  searchable?: boolean,
  scrollable?: boolean,
  sortable?: boolean, // if true can drag and drop to sort
  className?: string,
  placeholder?: string,
  renderArrow?: () => Node,
  renderTitle?: () => Node,
  renderItem?: ({}) => Node,
  renderAddItem?: () => Node,
  onClose?: () => void,
  onAddItem?: (string) => void,
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xlarge' | 'auto',
  position?: 'left' | 'right',
};

export default class DropDown extends Component<
  props,
  { open: boolean, search: string, addedValue: string, values: any },
> {
  constructor(props: propsT) {
    super(props);
    this.state = { open: this.props.open };
    verifyParametersRequirements(this.props);
  }

  render() {
    let {
      size = 'auto',
      position = 'left',
      className = '',
      id,
      renderTitle,
      renderArrow,
      selected,
      title,
      disabled = false,
      scrollable = true,
      sortable = false,
      multi,
      noBorder = false,
    } = this.props;

    const { open } = this.state;

    const values = this.state.values || this.props.values;

    if (!renderArrow) {
      renderArrow = this.renderArrow;
    }

    if (multi && selected && !selected.indexOf) {
      throw new Error(`Missing selected for dropdown ${title}`, selected);
    }

    return (
      <DropDownContainer id={id} className={`${className} drop-down__container drop-down__container--${size}`}>
        <button
          className={`
          drop-down${open ? ' drop-down--open' : ''}
          ${disabled ? ' drop-down--disabled' : ''}
          ${noBorder ? 'drop-down--no-border' : ''}
          `}
          onClick={() => !disabled && this.setState({ open: !open })}
        >
          <div className="drop-down__title">{renderTitle ? renderTitle() : this.renderTitle()}</div>
          {renderArrow()}
        </button>
        {open && (
          <Fragment>
            <DropDownContentContainer
              className={`drop-down__content--${position} drop-down__content--scrollable--${scrollable} `}
            >
              {sortable && (
                <SortableDropDownContent
                  {...this.props}
                  items={values}
                  distance={10}
                  onSortEnd={this.onSortEnd.bind(this)}
                  axis="y"
                  lockAxis="y"
                  useDragHandle={true}
                  handleClose={this.handleClose}
                />
              )}
              {!sortable && <DropDownContent {...this.props} items={values} handleClose={this.handleClose} />}
            </DropDownContentContainer>
            <div className="drop-down__mask" onClick={this.handleClose} />
          </Fragment>
        )}
      </DropDownContainer>
    );
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { onSortEnd } = this.props;
    const values = this.state.values || this.props.values;

    if (onSortEnd) {
      onSortEnd({ oldIndex, newIndex });
    } else {
      this.setState({
        values: arrayMove(values, oldIndex, newIndex),
      });
    }
  };

  renderTitle = (): Node => {
    const { values, selected = [], placeholder = '' } = this.props;
    const selectedValuesItems = values.filter((val) => isSelected(val.id, selected) !== -1);
    const selectedValues = selectedValuesItems.map((val) => val.value);

    if (selectedValues.length === 1 && this.props.renderItem) {
      return this.props.renderItem(selectedValuesItems[0]);
    }

    if (selectedValues.length > 0) {
      return selectedValues.join(', ');
    }

    return placeholder;
  };

  handleClose = () => {
    this.setState({ open: false, search: '' });

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderArrow = (): Node => {
    const { open } = this.state;
    const graph = open ? <Polygon /> : <Polygon />;

    return <div style={{ height: '11px', width: '11px' }}>{graph}</div>;
  };
}
