import './Bar.css';

import React from 'react';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { AGENCY_TOOLS, useFeatureFlag } from 'lib/configcat';
import { User } from 'lib/user';

import { Accounts } from './Items/Accounts';
import AccountSettings from './Items/AccountSettings';
import { AdsProvider } from './Items/AdsProvider';
import { Amazon } from './Items/Amazon';
import AudienceBuilder from './Items/AudienceBuilder';
import Billing from './Items/Billing';
import Help from './Items/Help';
import PageBuilder from './Items/PageBuilder';
import { useGetAlertsCountQuery } from 'stores/alertsSlice';
import { ALERTS_STATUS } from '../../pages/Accounts/constants';
import { Spin } from 'antd';

const TopBar = styled.div.withConfig({
  displayName: 'TopBar',
})``;
const BottomBar = styled.div.withConfig({
  displayName: 'BottomBar',
})`
  position: absolute;
  bottom: 50px;
`;

// TODO just comment it not to loose them, should make this components, or use something like isNew (like isBeta)?
// const NewFeature = styled.div.withConfig({
//   displayName: "NewFeature"
// })`
//   text-align: center;
//   color: #fff;
//   span :hover {
//     text-decoration: none !important;
//   }
// `;

// const NewPulse = styled.div.withConfig({
//   displayName: "NewPulse"
// })`
//   display: inline-block;
//   height: 7px;
//   width: 7px;
//   border-radius: 10px;
//   background-color: #0087e9;
//   box-shadow: 0 0 0 rgba(0,135,233, 0.4);
//   animation: pulse 2s infinite;
//   margin-right: 2px;
//   bottom: 1px;
//   position: relative;

//   @keyframes pulse {
//   0% {

//     box-shadow: 0 0 0 0 rgba(0,135,233, 0.4);
//   }
//   70% {
//       box-shadow: 0 0 0 10px rgba(0,135,233, 0);
//   }
//   100% {
//       box-shadow: 0 0 0 0 rgba(0,135,233, 0);
//   }
// }
// }
// `;

const Bar = ({
  account,
  user,
  sub,
  limitations,
}: {
  account: accountT,
  user: userT,
  sub: subscriptionT,
  limitations: any,
}): React$Element<'nav'> => {
  const [isAgencyToolsEnabled] = useFeatureFlag(AGENCY_TOOLS);
  const {
    data: alertsCount,
    error,
    isLoading: alertsCountLoading,
  } = useGetAlertsCountQuery({ status: ALERTS_STATUS.UNREAD });

  return (
    <nav className="bar">
      <ul>
        <TopBar>
          <NavLink to={`/${account.id}/ab`} activeClassName="current">
            <li className="bar__menu-item">
              <AudienceBuilder />
            </li>
          </NavLink>
          <NavLink to={`/${account.id}/adsprovider`} activeClassName="current">
            <li className="bar__menu-item">
              <AdsProvider />
            </li>
          </NavLink>
          {isAgencyToolsEnabled && (
            <NavLink to={`/${account.id}/accounts`} activeClassName="current">
              <li className="bar__menu-item">
                <Accounts />
                <span className={alertsCount?.count ? 'alerts' : 'hidden'}>{alertsCount?.count}</span>
              </li>
            </NavLink>
          )}
          <NavLink to={`/${account.id}/pb/page-blocks`} activeClassName="current">
            <li className="bar__menu-item">
              <PageBuilder />
            </li>
          </NavLink>
        </TopBar>

        <BottomBar>
          <NavLink to={`/${account.id}/amazon`} activeClassName="current">
            <li className="bar__menu-item">
              <Amazon />
            </li>
          </NavLink>
          {/* <NavLink to={`/${account.id}/analytics`} activeClassName="current">
            <li className="bar__menu-item">
              <Analytics />
            </li>
          </NavLink> */}
          <NavLink to={`/${account.id}/s`} activeClassName="current">
            <li className="bar__menu-item">
              <AccountSettings />
            </li>
          </NavLink>
          {!!User.isOrgOwnerOfAccount(user, account) && (
            <NavLink to={`/${account.id}/b`} activeClassName="current">
              <li className="bar__menu-item">
                <Billing />
              </li>
            </NavLink>
          )}

          <a href="https://www.carbon6.io/pixelme-help" target="_blank" rel="noopener noreferrer">
            <li className="bar__menu-item">
              <Help />
            </li>
          </a>
        </BottomBar>
      </ul>
    </nav>
  );
};
export default Bar;
