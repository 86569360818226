import { getCurrencyCode } from 'lib/amazon';

const locale = 'en-us';
const defaultCurrency = 'USD';

export const getCurrencySymbol = (currencyCode: string): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode || defaultCurrency,
  })
    .formatToParts(0)
    .find((part) => part.type === 'currency')?.value || '$';

export function getCurrency(account: accountT) {
  const currencyCode = getCurrencyCode(account.productProviders);
  const currencySymbol = getCurrencySymbol(currencyCode);

  return { currencyCode, currencySymbol };
}
