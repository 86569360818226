import React from 'react';

import Modal from '../../../common/Modal';

import './ErrorPaiementModal.css';
import { useSelector } from 'react-redux';

export const ErrorPaiementModal = () => {
  const { errorPaiement } = useSelector((state) => {
    if (state.subscription.sub) {
      return {
        errorPaiement: state.subscription.sub.status === 'error',
      };
    }

    return {
      errorPaiement: false,
    };
  });

  if (errorPaiement) {
    return (
      <Modal displayButtons={false} error="Error during your payment process">
        <p className="error_modal__text">We encountered an error processing your payment.</p>
        <p className="error_modal__text" style={{ marginTop: '1rem' }}>
          Please <strong>contact us</strong> to unlock your account.
        </p>
      </Modal>
    );
  }

  return null;
};

export default ErrorPaiementModal;
