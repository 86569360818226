import { Select } from 'antd';

import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

const OBJECTIVES = {
  APP_PROMOTION: 'OUTCOME_APP_PROMOTION',
  AWARENESS: 'OUTCOME_AWARENESS',
  ENGAGEMENT: 'OUTCOME_ENGAGEMENT',
  LEADS: 'OUTCOME_LEADS',
  SALES: 'OUTCOME_SALES',
  TRAFFIC: 'OUTCOME_TRAFFIC',
};

const options = [
  { label: getIntlStrings('facebook.campaignObjective.appPromotion'), value: OBJECTIVES.APP_PROMOTION },
  { label: getIntlStrings('facebook.campaignObjective.awareness'), value: OBJECTIVES.AWARENESS },
  { label: getIntlStrings('facebook.campaignObjective.engagement'), value: OBJECTIVES.ENGAGEMENT },
  { label: getIntlStrings('facebook.campaignObjective.leads'), value: OBJECTIVES.LEADS },
  { label: getIntlStrings('facebook.campaignObjective.sales'), value: OBJECTIVES.SALES },
  { label: getIntlStrings('facebook.campaignObjective.traffic'), value: OBJECTIVES.TRAFFIC },
];

export const FacebookObjectiveSelector = (props) => (
  <Select
    size="large"
    placeholder={getIntlStrings('facebook.campaignObjective.placeholder')}
    options={options}
    allowClear
    {...props}
  />
);
