import React from 'react';

import { Select } from 'antd';
import styled from 'styled-components';

const SELECT_WIDTH = 211;

const StyledSelect = styled(Select)`
  height: fit-content;

  .ant-select-selection-placeholder {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
  }

  ${(props) => `
    min-width: ${props.$width}px;
    max-width: ${props.$width}px;
  `}
  ${(props) =>
    props.$hasDarkPlaceholder &&
    `
    .ant-select-selection-placeholder {
      color: #071629 !important;
      font-size: 0.875rem !important;
      font-weight: 400 !important;
    }
  `}
  ${(props) =>
    props.$hideTags &&
    `
    /* Hide all selected tags but one */
    .ant-select-selection-overflow-item {
      &:not(:first-child):not(.ant-select-selection-overflow-item-suffix) {
        display: none;
      }
      > span {
        /* Match what the default placeholder looks like */
        margin-left: 7px;
        color: ${props.$hasDarkPlaceholder ? 'inherit' : '#ccc'};
      }
    }
  `}
`;

export const MultiSelect = ({
  options = [],
  name = '',
  placeholder = '',
  hasDarkPlaceholder,
  hideTags,
  width = SELECT_WIDTH,
  children,
  ...props
}) => (
  <StyledSelect
    mode="multiple"
    showArrow
    showSearch={false}
    filterOption={false}
    placeholder={placeholder}
    $hasDarkPlaceholder={hasDarkPlaceholder}
    $hideTags={hideTags}
    $width={width}
    {...props}
  >
    {children}
  </StyledSelect>
);
