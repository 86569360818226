import React from 'react';

import { Steps } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import type { adsCampaignT, productT, variationT } from '../../../../../flow-typed/pixelme/adsPlateform';
import { DATE_FORMAT } from '../../../../consts/consts';

const { Step } = Steps;

const AttributionStepsStyle = styled.div`
  height: 100%;
  width: 620px;
  display: flex;
  z-index: 1;
  position: inherit;

  .steps-container {
    width: inherit;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .ant-steps-item {
    overflow: visible;
  }
  .ant-steps-small .ant-steps-item-icon {
    margin-top: 13px;
  }
  .ant-steps-item-content {
    margin-left: -0px;
  }
  .ant-steps-item-icon {
    zoom: 0.5;
    margin-left: 15px;
  }

  .ant-steps-item-description {
    margin-top: 1em;
  }
`;
const AttributionStepsTitle = styled.div`
  position: absolute;
  top: -13px;
  left: -65px;
  width: 150px;
  text-align: center;
  font-size: 12px;
  color: #595959;
`;
const AttributionStepsDate = styled.div`
  font-size: 8px;
  position: absolute;
  top: 22px;
  left: -65px;
  width: 150px;
  text-align: center;
  color: #979797;
`;

export function AttributionSteps(props: { report: adsCampaignT | productT | variationT }) {
  const { messages } = useIntl();

  if (!props.report) {
    return <></>;
  }

  return (
    <AttributionStepsStyle>
      <div className="steps-container">
        <Steps
          style={{ width: '480px', marginLeft: '65px' }}
          size="small"
          current={props.report.lastClicks ? 2 : props.report.lastSpent ? 1 : props.report.firstAdSpent ? 0 : -1}
        >
          <Step
            title=""
            description={
              <div>
                <AttributionStepsTitle>{messages.locales.dash.campaignRunning}</AttributionStepsTitle>
                {props.report.firstSpent && (
                  <>
                    <AttributionStepsDate>{`Started date: ${d(props.report.firstSpent)}`}</AttributionStepsDate>
                  </>
                )}
              </div>
            }
          />
          <Step
            title=""
            description={
              <div>
                <AttributionStepsTitle>{messages.locales.dash.adDataReceived}</AttributionStepsTitle>
                {props.report.lastSpent && (
                  <>
                    <AttributionStepsDate>{`Last received: ${d(props.report.lastSpent)}`}</AttributionStepsDate>
                  </>
                )}
              </div>
            }
          />
          <Step
            title=""
            description={
              <div>
                <AttributionStepsTitle>{messages.locales.dash.amazonDataReceived}</AttributionStepsTitle>
                {props.report.lastClicks && (
                  <>
                    <AttributionStepsDate>{`Last received: ${d(props.report.lastClicks)}`}</AttributionStepsDate>
                  </>
                )}
              </div>
            }
          />
        </Steps>
      </div>
    </AttributionStepsStyle>
  );
}

function d(v) {
  return moment(v).format(DATE_FORMAT);
}
