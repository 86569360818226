import React, { useEffect, useState, Fragment } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Alert, Form, Select } from 'antd';
import Button from 'antd/lib/button';
import _ from 'lodash';
import styled from 'styled-components';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokAllVideos } from 'lib/tiktokPlateformApi';

import { MutedText } from '../../../../common/MutedText';

const Style = styled.div`
  position: relative;
  .ant-select-selector {
    height: 100px !important;
    min-height: fit-content !important;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`;

/**
 * Tiktok Videos selector
 * https://ads.tiktok.com/marketing_api/docs?id=1709477013957634
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function VideosSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  type: string,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [data, setData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const genericResponse = await getTiktokAllVideos(localStorage, axios, props.adsProvider, {
      accountId: props.account.id,
      osType: props.type,
    });
    const d = _.get(genericResponse, 'list', []).map((l) => ({
      videoId: l.videoId,
      fileName: l.fileName,
      posterUrl: l.videoCoverUrl,
    }));

    setData(d);
  }

  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <Style>
      <ExclusiveInlineLoadingContainer>
        <Form.Item noStyle>
          <Alert
            type="info"
            message={
              <div>
                Create a new video with the{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://ads.tiktok.com/i18n/material/video?aadvid=${props.adsProvider.externalId}`}
                >
                  TikTok’s video editor
                </a>
              </div>
            }
          />
        </Form.Item>
        <br />
        <Button
          style={{ position: 'absolute', top: -30, right: 0, minWidth: 20 }}
          shape="circle"
          size="small"
          icon={<ReloadOutlined />}
          onClick={() => {
            doAction(load);
          }}
        />
        {data.length > 0 && (
          <Fragment>
            <Select
              value={props.value}
              defaultValue={props.defaultValue}
              onChange={(e) => {
                props.onChange(e);
              }}
              size="large"
              placeholder="Select Video"
              allowClear
            >
              {data.map((l) => (
                <Select.Option value={l.videoId}>
                  <div
                    style={{
                      display: 'flex',
                      gap: '1em',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <img style={{ padding: '1em' }} height={100} src={l.posterUrl}></img>
                    <div>{l.fileName}</div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Fragment>
        )}
        <Form.Item noStyle>
          <MutedText>
            <div style={{ fontSize: 10 }}>
              <b>File format:</b>
              <br />
              MP4, MOV, MPEG, AVI or GIF; File size
              <br />
              <b>Aspect ratio requirements for all placements:</b>
              <br />
              Horizontal(16:9) / Square(1:1) / Vertical(9:16)
              <br />
              <b>For TikTok:</b>
              <br />
              Resolution Horizontal(960*540) / Square(640*640) / Vertical(540*960) / Bitrate ≥ 516.00 Kbpskbps
              <br />
              / Duration 5-60s / Safe zone From left: 44px, From right: 140px, From top: 130px, From bottom: 483-484px
              <br />
              <b>For Pangle:</b> Duration 5-60s
              <br />
            </div>
          </MutedText>
        </Form.Item>
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}
