import { Actions } from 'actions';
import { removeCampaign, removeMedium, removeSource, removeTag, resetAnalytics, removePlatform } from 'lib/api';
import axios from 'lib/axios.factory';
import { selectCurrentAccountId } from 'stores/accounts';

export default (store: any) => (next: any) => async (action: any) => {
  next(action);

  const accountFilter = { accountId: selectCurrentAccountId(store.getState()) };

  switch (action.type) {
    case Actions.api.account.settings.analytics.reset.request().type:
      resetAnalytics(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.tags.remove.request().type:
      removeTag(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.utms.sources.remove.request().type:
      removeSource(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.utms.mediums.remove.request().type:
      removeMedium(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.utms.campaigns.remove.request().type:
      removeCampaign(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.account.platforms.remove.request().type:
      removePlatform(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success({ account: data.account }));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    default:
      break;
  }
};
