import styled from 'styled-components';

const LightChip = styled.div.withConfig({
  displayName: 'LightChip',
})`
  background: #2ec1b3;
  border-radius: 7px;
  color: white;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  max-height: 16px;
  width: 100px;
  text-align: center;
`;

const WhiteChip = styled.div.withConfig({
  displayName: 'LightChip',
})`
  border-radius: 7px;
  color: white;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  max-height: 16px;
  width: 100px;
  text-align: center;
`;

const DarkChip = styled.div.withConfig({
  displayName: 'DarkChip',
})`
  background: rgb(130, 184, 238);
  border-radius: 7px;
  color: var(--white);
  font-weight: 500;
  font-size: 9px;
  line-height: 16px;
  max-height: 16px;
  width: 69px;
  text-align: center;
`;

export { LightChip, DarkChip, WhiteChip };
