import React, { useState, useEffect, type Node } from 'react';

import { message } from 'antd';
import { IntlProvider } from 'react-intl';

import { useLocalStorage } from 'hooks/useLocalStorage';

import { INIT_LOCALE, fetchStrings, getLocale, localeHandler } from './i18n/localeHelpers';

type propsT = {
  children: Node,
};

export const LocaleContext = React.createContext({});

export const localeFromBrowser: string = navigator.language.split('-')[0];

export const LANGUAGES = {
  en: 'English',
  zh: 'Chinese',
};

export const LocalizationWrapper = ({ children }: propsT): Node => {
  const [localeFromCache, setLocaleFromCache] = useLocalStorage('locale');
  const [localeMessagingFromCache, setLocaleMessagingFromCache] = useLocalStorage('localeMessaging', false);
  const [locale, setLocale] = useState(localeFromCache || getLocale(localeFromBrowser) || INIT_LOCALE);
  const [messages, setMessages] = useState({});

  useEffect(() => {
    setLocaleFromCache(locale);
    !localeMessagingFromCache &&
      message.success(
        `Language changed to ${LANGUAGES[locale]}. Go to Account > Profile to change it to another language.`,
        3,
        setLocaleMessagingFromCache(true),
      );
  }, [locale]);

  useEffect(() => {
    const loadMessages = async () => {
      const loadedMessages = await fetchStrings(locale);

      setMessages(loadedMessages);
    };

    loadMessages();
  }, [locale]);

  const updateLocale = (newLocale: string) => {
    setLocale(newLocale);
  };

  const getIntlStrings = (key: string, variables: Object = {}): string => {
    try {
      const messages = localeHandler(localeFromCache);

      let result = key.split('.').reduce((o, i) => o[i], messages.i18n(variables));

      if (result === undefined) {
        result = key;
      }

      return result;
    } catch (error) {
      if (error.message.indexOf('Cannot read property') !== -1) {
        return key;
      } else {
        throw error;
      }
    }
  };

  return (
    <LocaleContext.Provider value={{ locale, updateLocale, getIntlStrings }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export const useLocale = () => React.useContext(LocaleContext);
