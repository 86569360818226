import { zeroIfNegative } from '../../../../lib/util';

export function calculateStats(campaigns = []) {
  const stats = {};

  for (const result of campaigns) {
    stats.impressions = zeroIfNegative(stats.impressions) + zeroIfNegative(result.impressions);
    stats.clicks = zeroIfNegative(stats.clicks) + zeroIfNegative(result.clicks);
    stats.adSpent = zeroIfNegative(stats.adSpent) + zeroIfNegative(result.adSpent);
    stats.amazonTotalPurchases =
      zeroIfNegative(stats.amazonTotalPurchases) + zeroIfNegative(result.amazonTotalPurchases);
    stats.amazonTotalSales = zeroIfNegative(stats.amazonTotalSales) + zeroIfNegative(result.amazonTotalSales);
    stats.amazonTotalAddToCart =
      zeroIfNegative(stats.amazonTotalAddToCart) + zeroIfNegative(result.amazonTotalAddToCart);
    stats.brbBonusAmount = zeroIfNegative(stats.brbBonusAmount) + zeroIfNegative(result.brbBonusAmount);
    stats.brbBonusAmountCalculated =
      zeroIfNegative(stats.brbBonusAmountCalculated) + zeroIfNegative(result.brbBonusAmountCalculated);
    stats.netMargin = zeroIfNegative(stats.netMargin) + zeroIfNegative(result.netMargin);
  }

  if (stats.adSpent) {
    stats.roas = (stats.amazonTotalSales / (stats.adSpent - stats.brbBonusAmount)) * 100;
  }

  if (stats.amazonTotalSales) {
    stats.acos = ((stats.adSpent - stats.brbBonusAmount) / stats.amazonTotalSales) * 100;
  }

  return stats;
}
