import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm } from 'antd';

import { usePauseAdGroupMutation, useRunAdGroupMutation } from 'stores/adGroupsSlice';

import { NewAdGroupButton } from './NewAdGroupButton';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { DashboardFilter } from '../../common/DashboardFilter';
import { ShowProduct } from '../../common/ShowProduct';
import { HeaderStyle } from '../Style';

export const AdGroupsDashboardHeader = ({ account, campaign, product, selectedRowKeys = [], reload }) => {
  const [pauseAdGroup] = usePauseAdGroupMutation();
  const [runAdGroup] = useRunAdGroupMutation();

  const bulkAction = (action) => async () => {
    // Cannot be concurrent since each ad group affects the same campaign
    for (const id of selectedRowKeys) {
      await action({ id, invalidate: false });
    }
    reload();
  };

  if (!campaign) {
    return null;
  }

  return (
    <HeaderStyle>
      <Card bordered={false}>
        <Card.Meta
          avatar={<AdsProviderLogo style={{ height: 30 }} providerType={campaign.providerType} />}
          title={`Ad Groups: ${campaign.name}`}
        />
      </Card>
      <ShowProduct product={product} />
      {selectedRowKeys.length > 0 && (
        <>
          <Popconfirm
            title="Are you sure to pause all selected adGroups?"
            disabled={selectedRowKeys.length === 0}
            onConfirm={bulkAction(pauseAdGroup)}
          >
            <Button
              style={{
                color: 'rgb(248, 165, 27)',
                borderColor: 'rgb(248, 165, 27)',
              }}
            >
              <PauseOutlined />
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Are you sure to active all selected adGroups?"
            disabled={selectedRowKeys.length === 0}
            onConfirm={bulkAction(runAdGroup)}
          >
            <Button
              style={{
                color: 'rgb(82, 196, 26)',
                borderColor: 'rgb(82, 196, 26)',
              }}
            >
              <CaretRightOutlined />
            </Button>
          </Popconfirm>
        </>
      )}
      <DashboardFilter />
      <NewAdGroupButton account={account} campaign={campaign} />
    </HeaderStyle>
  );
};
