import React from 'react';

import styled from 'styled-components';

import { UtmsCampaigns } from './UtmsCampaigns';
import { UtmsMediums } from './UtmsMediums';
import { UtmsSources } from './UtmsSources';

const Style = styled.div`
  .settings-utms > .box {
    margin-top: 1rem;
  }
  .settings-utms > .box:first-child {
    margin-top: 0;
  }

  .settings-utms__list__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .settings-utms__list__title .button {
    height: 1.8rem;
    font-size: var(--small-size);
  }

  .settings-utms__list__title span {
    font-size: var(--small-size);
    color: var(--text-muted);
    margin-left: 1rem;
  }

  .settings-utms__list__headers {
    color: var(--text-muted);
  }

  .settings-utms__list__line {
    display: grid;
    grid-template-columns: repeat(1, 1fr) 3rem;
    grid-gap: 0 1rem;
    height: 1.8rem;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
  }

  .settings-utms__list__line .input {
    padding: 0.2rem;
  }

  .settings-utms__list__line--odd {
    background: #f9f9f9;
  }
  .settings-utms__list--del {
    justify-self: end;
    padding-top: 2px;
  }
  .no-utms {
    padding: 2em;
    text-align: center;
    font-size: 14px;
  }
`;

export const Utms = ({ account }) => (
  <Style>
    <UtmsSources account={account} />
    <UtmsCampaigns account={account} />
    <UtmsMediums account={account} />
  </Style>
);
