import React, { useState, type Node, useEffect } from 'react';

import { Table, Input } from 'antd';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectAccounts } from 'stores/accounts';

const Style = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  .ant-picker,
  .ant-input {
    min-height: 32px;
  }
  background-color: #fff;
`;

const Heading = styled.h3`
  margin: 0;
  padding-left: 1rem;
`;

const StyleLink = styled.a`
  text-align: left;
  text-decoration: none;
  margin: 0;
  user-select: none;
  color: #828282;
  :hover {
    text-decoration: none;
    color: #828282;
    cursor: pointer;
  }
`;

const TableContainer = styled.div`
  max-height: 40vh;
  overflow: auto;
`;

const HeaderStyle = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const AccountSwitcher = (): Node => {
  const accounts = useSelector(selectAccounts);
  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const [searchAccounts, setSearchAccounts] = useState('');
  const { messages } = useIntl();

  const columns = [
    {
      key: 'accountName',
      title: messages.locales.columns.accountName,
      dataIndex: 'name',
      width: 600,
      render: (v, record) => (
        <StyleLink href={`/${record.id}/adsprovider/`} target="_blank" rel="noopener noreferrer">
          {v}
        </StyleLink>
      ),
    },
  ];

  useEffect(() => {
    if (searchAccounts) {
      const filteredBySearch = accounts.filter((account) =>
        account.name.toLowerCase().includes(searchAccounts.toLowerCase()),
      );

      setFilteredAccounts(filteredBySearch);
    } else {
      setFilteredAccounts(accounts);
    }
  }, [searchAccounts]);

  return (
    <Style>
      <HeaderStyle>
        <Heading>{messages.locales.accounts.accountSwitcher}</Heading>
        <Input.Search
          value={searchAccounts}
          placeholder={messages.locales.accounts.searchForAccountsByName}
          onChange={(e) => setSearchAccounts(e.target.value)}
          style={{ width: 300 }}
        />
      </HeaderStyle>
      <TableContainer>
        <Table
          rowKey="id"
          tableLayout="fixed"
          dataSource={filteredAccounts}
          columns={columns}
          pagination={false}
          sticky
        />
      </TableContainer>
    </Style>
  );
};
