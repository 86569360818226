import React, { useEffect } from 'react';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { EasySteps } from './components/EasySteps';
import { EasyContainer } from './EsayContainer';
// @ts-ignore
import { LinkExpiredForm } from './LinkExpiredForm';
import { getRedirectsByIds } from '../../../lib/api';
import { sd } from '../../../lib/safe';

const Style = styled.div`
  .ant-select-selection-item {
    font-weight: 400;
  }
`;

export function LinkExpired({ redirectId }: { redirectId: string }) {
  const [state, updateState] = useImmer({ redirect: { id: redirectId } });

  const account = useSelector((states) => {
    const acc = sd(states.accounts.accounts, [])[0];

    return acc;
  });

  useEffect(() => {
    async function loadRedirect(redirectId) {
      const { redirects } = await getRedirectsByIds(localStorage, axios, {
        accountId: account.id,
        redirectsIds: [redirectId],
      });

      updateState((draft) => {
        draft.redirect = _.get(redirects, '0');
        draft.pixel = _.get(redirects, ['0', 'pixels', '0']);
        draft.providers = account.providers.map(({ id, name }) => ({
          id,
          value: name,
        }));
      });
    }

    if (redirectId) {
      loadRedirect(redirectId).then();
    }
  }, [redirectId, JSON.stringify(account)]);

  return (
    <EasyContainer
      title="Link expired"
      description={
        <>
          PixelMe is a <b>URL shortener</b> that embed retargeting pixels from Facebook, Google, Linkedin and more into
          shortened links. <br />
          Brand, track & share your own <b>branded short URLs</b> and retarget anyone who clicks on your links!
        </>
      }
    >
      <Style>
        <EasySteps current={0} />
        <LinkExpiredForm state={state} updateState={updateState} account={account} redirectId={redirectId} />
      </Style>
    </EasyContainer>
  );
}
