import './NewMultiDropDown.css';
import type { Node } from 'react';
import React, { Component } from 'react';

import { MultiSelect } from '@blueprintjs/select';
import _ from 'lodash';

import { verifyParametersRequirements } from './dropDown.verify';
import { Polygon } from '../icons/Polygon';

export default class NewMultiDropDown extends Component<
  props,
  { open: boolean, search: string, addedValue: string, values: any },
> {
  constructor(props: propsT) {
    super(props);
    verifyParametersRequirements(this.props);
  }

  render() {
    let { id, renderArrow, renderItem, renderTag, selected, title, onSelect, onRemove, onActiveItemChange, values } =
      this.props;

    if (!renderArrow) {
      renderArrow = this.renderArrow;
    }

    if (!selected) {
      throw new Error("selected can't be null for ", values);
    }

    if (!Array.isArray(selected)) {
      throw new Error(`selected is not an array for dropdown ${title}`, selected);
    }

    if (!onSelect) {
      throw new Error("onSelect can't be null for ", values);
    }

    if (!onRemove) {
      throw new Error("onRemove can't be null for ", values);
    }

    return (
      <MultiSelect
        id={id}
        items={values}
        selectedItems={selected}
        onItemSelect={onSelect}
        onActiveItemChange={onActiveItemChange}
        popoverProps={{ minimal: true }}
        tagRenderer={renderTag}
        itemRenderer={renderItem}
        resetOnQuery={true}
        resetOnSelect={true}
        resetOnClose={true}
        {..._.omit(this.props, 'open')}
        tagInputProps={{
          onRemove,
          placeholder: 'Search...',
          inputProps: { leftIcon: 'search' },
          children: <span className="icon-for-input bp3-icon bp3-icon-search" />,
        }}
      />
    );
  }

  renderTitle = (): Node => {
    const { values, selected = [], placeholder = '' } = this.props;
    const selectedValues = values.filter((val) => selected.indexOf(val.id) !== -1).map((val) => val.value);

    if (selectedValues.length > 0) {
      return selectedValues.join(', ');
    }

    if (values.indexOf(placeholder) !== -1) {
      return placeholder;
    }

    return <span style={{ color: '#DBDBDB' }}>{placeholder}</span>;
  };

  handleClose = () => {
    this.setState({ open: false, search: '' });

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderArrow = (): Node => {
    const { open } = this.state;
    const graph = open ? <Polygon /> : <Polygon />;

    return <div style={{ height: '11px', width: '11px' }}>{graph}</div>;
  };
}
