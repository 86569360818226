import { message, notification } from 'antd';

import { trackedEvents } from 'config/trackedEvents.config';
import { track } from 'lib/analytics';

import { apiSlice, TAGS } from './apiSlice';

const { PRODUCTS, PRODUCTS_FOR_TABLE } = TAGS;

// $FlowFixMe
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: ({ accountId, rangeId }) => ({
        url: `/product-providers/-/products`,
        method: 'get',
        params: { accountId, rangeId },
      }),
      providesTags: (result, error, { accountId }) =>
        result?.products
          ? [...result.products.map(({ id }) => ({ type: PRODUCTS, id })), { type: PRODUCTS, id: accountId }]
          : [{ type: PRODUCTS, id: accountId }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          message.error('Products could not be loaded');
        });
      },
    }),
    getAllProductsForTable: builder.query({
      query: ({ accountId, from, to, filterProviderType }) => ({
        url: `/product-providers/-/products-table`,
        method: 'get',
        params: { accountId, from, to, filterProviderType },
      }),
      providesTags: (result, error, { accountId }) =>
        result?.products
          ? [
              ...result.products.map(({ id }) => ({ type: PRODUCTS_FOR_TABLE, id })),
              { type: PRODUCTS_FOR_TABLE, id: accountId },
            ]
          : [{ type: PRODUCTS_FOR_TABLE, id: accountId }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          message.error('Products table could not be loaded');
        });
      },
    }),
    getProduct: builder.query({
      query: ({ accountId, productId }) => ({
        url: `/product-providers/-/products/${productId}`,
        method: 'get',
        params: { accountId },
      }),
      providesTags: (result, error, { accountId }) => [{ type: PRODUCTS, id: result.product.id }],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          message.error('Product could not be loaded');
        });
      },
    }),
    saveProductOnProvider: builder.mutation({
      query: ({ accountId, providerId, product }) => ({
        url: `/product-providers/${providerId}/products`,
        method: 'post',
        params: { accountId },
        data: { product },
      }),
      invalidatesTags: (result, error, { accountId }) => [
        { type: PRODUCTS, id: accountId },
        { type: PRODUCTS_FOR_TABLE, id: accountId },
      ],
      onQueryStarted: ({ product }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Product saved');
            track(trackedEvents.addProduct, { product });
          })
          .catch((e) => {
            if (e.error?.data?.response?.status === 409) {
              notification.error({
                duration: 30,
                message: 'Product already exists, please be sure that ASIN or URL are unique.',
              });
            } else {
              notification.error({
                duration: 30,
                message: e.error?.data?.errorMessage || 'Product could not be saved',
              });
            }
          });
      },
      extraOptions: { useDefaultErrorHandling: false },
    }),
    editProduct: builder.mutation({
      query: ({ accountId, product, providerId, productASIN }) => ({
        url: `/product-providers/${providerId}/products/${productASIN}`,
        method: 'patch',
        params: { accountId },
        data: product,
      }),
      invalidatesTags: (result, error) =>
        result?.product
          ? [
              { type: PRODUCTS, id: result.product.id },
              { type: PRODUCTS_FOR_TABLE, id: result.product.id },
            ]
          : [],
      onQueryStarted: (arg, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Product updated');
          })
          .catch(() => {
            message.error('Product could not be updated');
          });
      },
    }),
    deleteProduct: builder.mutation({
      query: ({ accountId, providerId, productASIN }) => ({
        url: `/product-providers/${providerId}/products/${productASIN}`,
        method: 'delete',
        params: { accountId },
      }),
      invalidatesTags: (result, error) =>
        result?.product
          ? [
              { type: PRODUCTS, id: result.product.id },
              { type: PRODUCTS_FOR_TABLE, id: result.product.id },
            ]
          : [],
      onQueryStarted: ({ productId }, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            message.success('Product deleted');
            track(trackedEvents.deleteProduct, { productId });
          })
          .catch(() => {
            message.error('Product could not be deleted');
          });
      },
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetAllProductsForTableQuery,
  useGetProductQuery,
  useSaveProductOnProviderMutation,
  useEditProductMutation,
  useDeleteProductMutation,
} = extendedApiSlice;
