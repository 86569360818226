import React, { useEffect } from 'react';

import { Popover } from '@blueprintjs/core';
import { BlockPicker } from 'react-color';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import Button from './common/Button';

export const InputContainer = styled.div.withConfig({
  displayName: 'InputContainer',
})`
  height: 100%;
  display: flex;
  margin-top: 9px;
  flex-direction: row;
  color: #6d859e;
  align-items: center;
  .bp3-popover-target {
    width: 30px;
    margin-left: 1em;
  }
`;

export const InputContainerHeader = styled.div.withConfig({
  displayName: 'InputContainerHeader',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
  min-width: 110px;
  margin-bottom: 10px;
  font-size: ${(props) => (props.isBig ? 'var(--normal-size)' : 'var(--small-size)')};
`;

export function ColorPickerWithLabel({ onChange, displayLabel, isBig, name, title, value }) {
  const [state, updateState] = useImmer({ open: false, color: value });

  useEffect(() => {
    updateState((draft) => {
      draft.color = value;
    });
  }, [JSON.stringify(value)]);

  return (
    <InputContainer>
      {displayLabel && (
        <InputContainerHeader isBig={isBig}>
          <label htmlFor={name}>{title}</label>
        </InputContainerHeader>
      )}

      <Popover
        isOpen={state.open}
        onClose={() =>
          updateState((draft) => {
            draft.open = false;
          })
        }
        usePortal={true}
        minimal={true}
        hasBackdrop={true}
        canOutsideClickClose={true}
      >
        <Button
          style={{
            borderRadius: '10px',
            backgroundColor: state.color,
            width: '20px',
            minWidth: '20px',
            height: '20px',
            padding: 0,
            border: '2px solid white',
          }}
          handleClick={() => {
            updateState((draft) => {
              draft.open = !state.open;
            });
          }}
        ></Button>
        <div style={{}}>
          <BlockPicker
            value={value}
            onChange={(v) => {
              updateState((draft) => {
                draft.color = v.hex;
                draft.open = false;
              });
              onChange(v.hex);
            }}
          />
        </div>
      </Popover>

      {state.color && <span style={{ width: '60px', lineHeight: '20px' }}>{state.color}</span>}
      {!state.color && <span style={{ width: '60px', lineHeight: '20px' }}>#------</span>}
    </InputContainer>
  );
}
