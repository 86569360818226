import produce from 'immer';
import _ from 'lodash';

import { Actions } from 'actions';
import { toggleSortForStringBasedSort } from 'lib/columns';
import { validateUrl } from 'lib/util';
import { parseUtms, updateUrlFromUtms } from 'lib/utms';
import { getPixelsIds } from 'selectors/pixels.selector';

import { isARealCampaignId } from '../lib/campaigns';
import { s } from '../lib/safe';

type stateT = {
  filterConfig: {
    open: boolean,
  },
  dateFilterConfig: {
    open: boolean,
  },
  statsConfig: {
    open: boolean,
    stats: campaignStatsT,
  },
  composerModalConfig: {
    open: boolean,
    accountPixels: Array<pixelT>,
    selectedCampaignId?: campaignT,
    selectedSubCampaignId?: subCampaignT,
    isFormValid: false,
    link: linkT,
    redirect: redirectT,
  },
  abComposerModalConfig: {
    open: boolean,
    accountPixels: Array<pixelT>,
    selectedCampaignId?: campaignT,
    selectedSubCampaignId?: subCampaignT,
    isFormValid: false,
    redirect: redirectT,
    errorMessage: null,
    editPreview: false,
  },
  saComposerModalConfig: {
    open: boolean,
    accountPixels: Array<pixelT>,
    selectedCampaignId?: campaignT,
    selectedSubCampaignId?: subCampaignT,
    isFormValid: false,
    link: linkT,
  },
  editCampaignModalConfig: {
    open: boolean,
    campaignOrSub: campaignT | subCampaignT,
    parent: campaignT,
  },
  addCampaignModalConfig: {
    open: boolean,
    campaignOrSub: campaignT | subCampaignT,
    parent: campaignT,
  },
  deleteCampaignModalConfig: {
    open: boolean,
    campaignOrSub: campaignT | subCampaignT,
    parent: campaignT,
  },
  deleteRedirectModalConfig: {
    open: boolean,
    redirect: redirectT,
  },
  bulkEditRedirectModalConfig: {
    open: boolean,
    listOfRedirectsToEdit: Array<redirectT>,
  },
  duplicateRedirectModalConfig: {
    open: boolean,
    redirect: redirectT,
  },
  exportRedirectsModalConfig: {
    open: boolean,
    redirects: redirectT,
    availableColumns: string[],
    selectedColumns: string[],
  },
  exportRedirectsSuccessModalConfig: {
    open: boolean,
    feedback: string,
  },
  campaignsTreeConfig: campaignsTreeConfigT,
  saConversionColumnsConfig: columnsConfigT,
  lastVisitedURL: string,
  version: number,
};

function savedRedirectWasInEditMode(redirect) {
  return redirect.id !== undefined;
}

function getDefaultRedirect(redirect) {
  return {
    url: '',
    utms: {
      term: '',
      content: '',
    },
    pixelsIds: getPixelsIds(s(redirect).pixels),
    campaignId: s(redirect).campaignId,
    subCampaignId: s(redirect).subCampaignId,
    preview: {
      title: '',
      description: '',
      picture: '',
    },
  };
}

function getEmptyRedirectWithSavedParamsFromLastRedirect(redirect) {
  const result = getDefaultRedirect(redirect);

  return {
    ...result,
    pixelsIds: getPixelsIds(s(redirect).pixels),
    domain: redirect.domain,
    campaignId: redirect.campaignId,
    subCampaignId: redirect.subCampaignId,
    preview: {
      title: redirect.preview ? redirect.preview.title : '',
      description: redirect.preview ? redirect.preview.description : '',
      picture: redirect.preview ? redirect.preview.picture : '',
    },
  };
}

function duplicateRedirect(redirect) {
  return {
    ...redirect,
    id: undefined,
    pixelsIds: getPixelsIds(redirect.pixels),
  };
}

function initialRedirectsState() {
  // STORE
  return {
    composerOpen: false,
    filterConfig: {
      open: false,
    },
    dateFilterConfig: {
      open: false,
    },
    statsConfig: {
      open: true,
      nbRedirects: 0,
      nbClicks: 0,
    },
    composerModalConfig: {
      open: false,
    },
    saComposerModalConfig: {
      open: false,
    },
    abComposerModalConfig: {
      open: false,
      editPreview: false,
    },
    editCampaignModalConfig: {
      open: false,
      campaignOrSub: undefined,
      parent: undefined,
    },
    addCampaignModalConfig: {
      open: false,
      campaignOrSub: undefined,
      parent: undefined,
    },
    deleteCampaignModalConfig: {
      open: false,
      campaignOrSub: undefined,
      parent: undefined,
    },
    deleteRedirectModalConfig: {
      open: false,
      redirect: undefined,
    },
    bulkEditRedirectModalConfig: {
      open: false,
      redirect: [],
    },
    editRedirectModalConfig: {
      open: false,
      redirect: undefined,
    },
    duplicateRedirectModalConfig: {
      open: false,
      redirect: undefined,
    },
    saConversionColumnsConfig: {
      loading: false,
    },
    campaignsTreeConfig: {
      configs: {},
    },
    exportRedirectsModalConfig: {
      open: false,
    },
    bulkEditRedirectsModalConfig: {
      open: false,
    },
    exportRedirectsSuccessModalConfig: {
      open: false,
    },
    audienceBuilder: {
      campaigns: {
        columnsConfig: {
          availableColumns: [],
          selectedColumns: [].map((column) => column.id),
          sort: '-createdAt',
          loading: false,
        },
      },
    },
  };
}

const validateRotatorUrls = (redirect) => {
  if ('rotatorUrls' in redirect) {
    for (let i = 0; i < redirect.rotatorUrls.length; i++) {
      if (!validateUrl(redirect.rotatorUrls[i].url)) {
        return false;
      }
    }
  }

  return true;
};

export function front(state: stateT, action: actionT): stateT {
  if (!state) {
    state = initialRedirectsState();
  }

  switch (action.type) {
    case Actions.front.audienceBuilder.campaigns.columns.update.success().type: {
      return produce(state, (draft) => {
        draft.audienceBuilder.campaigns.columnsConfig = {
          availableColumns: action.payload,
          selectedColumns:
            state.audienceBuilder.campaigns.columnsConfig.selectedColumns.length > 0
              ? state.audienceBuilder.campaigns.columnsConfig.selectedColumns
              : action.payload.filter((column) => column.visible).map((column) => column.id),
          sort: '-createdAt',
          loading: false,
        };
      });
    }

    case Actions.front.audienceBuilder.campaigns.columns.updateConfig.request().type: {
      return {
        ...state,
        audienceBuilder: {
          ...state.audienceBuilder,
          campaigns: {
            ...state.audienceBuilder.campaigns,
            columnsConfig: action.payload.config,
          },
        },
        campaigns: {
          columnsConfig: action.payload.config,
        },
        // FIXME: Seems very strange that I need to duplicate state here but 2 hours on this, skipping for now!
      };
    }

    case Actions.api.audienceBuilder.campaigns.edit.request().type:
    case Actions.front.audienceBuilder.campaigns.edit.hide.request().type: {
      return {
        ...state,
        editCampaignModalConfig: {
          open: false,
        },
      };
    }
    case Actions.api.audienceBuilder.campaigns.add.request().type:
    case Actions.front.audienceBuilder.campaigns.add.hide.request().type: {
      return {
        ...state,
        addCampaignModalConfig: {
          open: false,
        },
      };
    }
    case Actions.api.audienceBuilder.campaigns.delete.request().type:
    case Actions.front.audienceBuilder.campaigns.delete.hide.request().type: {
      return {
        ...state,
        deleteCampaignModalConfig: {
          open: false,
        },
      };
    }

    case Actions.front.audienceBuilder.campaigns.edit.show.request().type: {
      const { campaignOrSub, parent } = action.payload;

      return {
        ...state,
        editCampaignModalConfig: {
          open: true,
          parent,
          campaignOrSub,
        },
      };
    }
    case Actions.front.audienceBuilder.campaigns.add.show.request().type: {
      const { parent } = action.payload;

      return {
        ...state,
        addCampaignModalConfig: {
          open: true,
          parent,
          campaignOrSub: { name: '' },
        },
      };
    }
    case Actions.front.audienceBuilder.campaigns.delete.show.request().type: {
      const { campaignOrSub, parent } = action.payload;

      return {
        ...state,
        deleteCampaignModalConfig: {
          open: true,
          parent,
          campaignOrSub,
        },
      };
    }

    /**
     * EDIT REDIRECT
     */
    case 'UPDATE_REDIRECT': {
      const { redirect } = action;

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          redirect,
        },
      };
    }

    /**
     * DUPLICATE REDIRECT
     */
    case Actions.front.audienceBuilder.redirect.duplicate.show.request().type: {
      const { redirect } = action.payload;

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          open: true,
          redirect: duplicateRedirect(redirect),
        },
      };
    }

    /**
     * EDIT REDIRECT
     */
    case Actions.front.audienceBuilder.redirect.edit.show.request().type: {
      const { redirect } = action.payload;

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          open: true,
          redirect: { ...redirect, pixelsIds: getPixelsIds(redirect.pixels) },
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.edit.request().type:
      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          loading: true,
          errorMessage: null,
        },
      };

    case Actions.api.audienceBuilder.redirect.edit.error().type:
      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          loading: false,
          editPreview: false,
          errorMessage: action.payload.message,
        },
      };

    case Actions.api.audienceBuilder.redirect.edit.success().type:
      const { redirect, preview } = action.payload;

      if (preview) {
        return {
          ...state,
          abComposerModalConfig: {
            ...state.abComposerModalConfig,
            loading: false,
            open: true,
            redirect: {
              ...redirect,
              pixelsIds: getPixelsIds(redirect.pixels),
            },
          },
        };
      }

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          open: false,
          loading: false,
          redirect: getEmptyRedirectWithSavedParamsFromLastRedirect(redirect),
        },
      };

    case Actions.front.audienceBuilder.redirect.edit.hide.request().type: {
      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          open: false,
        },
      };
    }

    /**
     * DELETE REDIRECT
     */
    case Actions.front.audienceBuilder.redirect.delete.show.request().type: {
      const { redirect } = action.payload;

      return {
        ...state,
        deleteRedirectModalConfig: {
          open: true,
          redirect,
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.delete.request().type:
    case Actions.front.audienceBuilder.redirect.delete.hide.request().type: {
      return {
        ...state,
        deleteRedirectModalConfig: {
          open: false,
        },
      };
    }

    /**
     * BULK EDIT REDIRECT
     */
    case Actions.front.audienceBuilder.redirect.bulkEdit.show.request().type: {
      const { redirect } = action.payload;

      return {
        ...state,
        bulkEditRedirectModalConfig: {
          open: true,
          redirect,
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.editSelected.success().type:
    case Actions.front.audienceBuilder.redirect.bulkEdit.hide.request().type: {
      return {
        ...state,
        bulkEditRedirectModalConfig: {
          open: false,
        },
      };
    }
    /**
     * SORT REDIRECT ON CLICK ON COLUMN HEADER
     */
    case Actions.api.audienceBuilder.redirect.sort.request().type: {
      const { columnName, direction } = action.payload;

      let sort = '';

      if (!direction) {
        sort = toggleSortForStringBasedSort(columnName, state.campaignColumnsConfig.sort, direction);
      } else if (direction === 'asc') {
        sort = `${columnName}`;
      } else {
        sort = `-${columnName}`;
      }

      return {
        ...state,
        campaignColumnsConfig: {
          ...state.campaignColumnsConfig,
          sort,
          loading: true,
        },
        audienceBuilder: {
          ...state.audienceBuilder,
          campaigns: {
            ...state.audienceBuilder.campaigns,
            columnsConfig: {
              ...state.audienceBuilder.campaigns.columnsConfig,
              sort,
            },
          },
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.sort.success().type: {
      return {
        ...state,
        campaignColumnsConfig: {
          ...state.campaignColumnsConfig,
          loading: false,
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.load.request().type: {
      return {
        ...state,
        campaignColumnsConfig: {
          ...state.campaignColumnsConfig,
          loading: true,
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.load.success().type: {
      return {
        ...state,
        campaignColumnsConfig: {
          ...state.campaignColumnsConfig,
          loading: false,
        },
        dateFilterConfig: {
          ...state.dateFilterConfig,
          focusedInput: undefined,
        },
      };
    }

    case Actions.front.initLoading.request().type: {
      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          loading: false,
        },
        campaignColumnsConfig: {
          ...state.campaignColumnsConfig,
          loading: false,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.url.update.request().type: {
      const { redirect } = action.payload;
      const updatedRedirect = { ...redirect };

      if (!redirect) {
        throw new Error('Unable to update current redirect on front state because redirect is undefined');
      }

      const isFormValid = validateUrl(redirect.url) && validateRotatorUrls(redirect);

      if (!_.isEqual(updatedRedirect.url, state.abComposerModalConfig.redirect.url)) {
        updatedRedirect.utms = parseUtms(redirect.url);
      }

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          redirect: { ...updatedRedirect },
          isFormValid,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.update.request().type: {
      const { redirect } = action.payload;
      const updatedRedirect = { ...redirect };

      if (!redirect) {
        throw new Error('Unable to update current redirect on front state because redirect is undefined');
      }

      const isFormValid = validateUrl(redirect.url) && validateRotatorUrls(redirect);

      updatedRedirect.url = updateUrlFromUtms(redirect);

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          redirect: { ...updatedRedirect },
          isFormValid,
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.duplicate.request().type:
    case Actions.front.audienceBuilder.redirect.duplicate.hide.request().type: {
      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          open: false,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.editPreview.show.request().type:
      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          editPreview: true,
        },
      };

    case Actions.front.audienceBuilder.redirect.editPreview.hide.request().type:
      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          editPreview: false,
        },
      };

    case Actions.front.audienceBuilder.redirect.create.show.request().type: {
      const { selectedCampaignId, selectedSubCampaignId } = action.payload;

      let redirect = { ...state.abComposerModalConfig.redirect };
      // should keep saved redirect if it's a new redirect in create mode and user click outside of the composer and reopen it after

      if (!state.abComposerModalConfig.redirect) {
        redirect = getDefaultRedirect({});
      }

      if (isARealCampaignId(selectedCampaignId)) {
        redirect.campaignId = selectedCampaignId;
      }

      if (isARealCampaignId(selectedSubCampaignId)) {
        redirect.subCampaignId = selectedSubCampaignId;
      }

      if (savedRedirectWasInEditMode(redirect)) {
        redirect = getDefaultRedirect(redirect);
      }

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          open: true,
          editPreview: false,
          redirect,
        },
      };
    }
    case Actions.front.audienceBuilder.redirect.create.hide.request().type: {
      return {
        ...state,
        abComposerModalConfig: { ...state.abComposerModalConfig, open: false, editPreview: false },
      };
    }

    case Actions.api.audienceBuilder.campaigns.add.success().type: {
      const { campaign, subCampaign } = action.payload;

      let { redirect } = state.abComposerModalConfig;

      if (!redirect) {
        redirect = getDefaultRedirect({});
      }

      return {
        ...state,
        abComposerModalConfig: {
          ...state.abComposerModalConfig,
          selectedCampaignId: campaign,
          redirect: {
            ...redirect,
            campaignId: campaign ? campaign.id : s(state.abComposerModalConfig.redirect).campaignId,
            subCampaignId: subCampaign ? subCampaign.id : s(state.abComposerModalConfig.redirect).subCampaignId,
          },
          selectedSubCampaignId: subCampaign,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.filter.toggle.request().type: {
      return {
        ...state,
        filterConfig: { ...state.filterConfig, open: !state.filterConfig.open },
        statsConfig: { ...state.statsConfig, open: state.filterConfig.open },
      };
    }

    case Actions.front.audienceBuilder.redirect.filter.date.toggle.request().type: {
      const { focusedInput } = action.payload;

      return {
        ...state,
        dateFilterConfig: {
          ...state.dateFilterConfig,
          focusedInput,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.export.show.request().type: {
      return {
        ...state,
        exportRedirectsModalConfig: {
          open: true,
          availableColumns: state.audienceBuilder.campaigns.columnsConfig.availableColumns.filter(
            (column) => column.exportable,
          ),
          selectedColumns: state.audienceBuilder.campaigns.columnsConfig.availableColumns.map((column) => column.id),
        },
      };
    }

    case Actions.api.audienceBuilder.redirect.export.success().type:
      return {
        ...state,
        exportRedirectsModalConfig: {
          open: false,
        },
        exportRedirectsSuccessModalConfig: {
          open: true,
        },
      };

    case Actions.front.audienceBuilder.redirect.export.hide.request().type: {
      return {
        ...state,
        exportRedirectsModalConfig: {
          open: false,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.export.feedback.update.request().type: {
      const { feedback } = action.payload;

      if (feedback === undefined) {
        throw new Error('Missing feedback');
      }

      return {
        ...state,
        exportRedirectsSuccessModalConfig: {
          ...state.exportRedirectsSuccessModalConfig,
          feedback,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.export.feedback.hide.request().type:
      return {
        ...state,
        exportRedirectsSuccessModalConfig: {
          ...state.exportRedirectsSuccessModalConfig,
          open: false,
        },
      };

    case Actions.track.redirect.export.feedback.success().type: {
      return {
        ...state,
        exportRedirectsSuccessModalConfig: {
          open: false,
        },
      };
    }

    case Actions.front.audienceBuilder.redirect.export.updateSelected.request().type: {
      const { selectedColumns } = action.payload;

      if (!selectedColumns) {
        throw new Error('Missing selectedColumns');
      }

      return {
        ...state,
        exportRedirectsModalConfig: {
          ...state.exportRedirectsModalConfig,
          selectedColumns,
        },
      };
    }

    case Actions.api.audienceBuilder.campaigns.load.success().type: {
      const { campaigns } = action.payload;
      const { selectedCampaignId } = action.payload;

      return produce(state, (draft) => {
        campaigns.forEach((campaign) => {
          draft.campaignsTreeConfig.configs[campaign.id] = {
            hideSubCampaigns: selectedCampaignId !== campaign.id,
          };
        });
      });
    }

    case Actions.front.audienceBuilder.campaigns.toggleTree.request().type: {
      const { campaignId, hide } = action.payload;

      if (!campaignId) {
        throw new Error('Missing campaignId');
      }

      const newState = { ...state };

      if (!newState.campaignsTreeConfig.configs[campaignId]) {
        newState.campaignsTreeConfig.configs[campaignId] = {
          hideSubCampaigns: true,
        };
      }

      if (hide !== undefined) {
        newState.campaignsTreeConfig.configs[campaignId].hideSubCampaigns = hide;
      } else {
        newState.campaignsTreeConfig.configs[campaignId].hideSubCampaigns = !s(
          state.campaignsTreeConfig.configs[campaignId],
        ).hideSubCampaigns;
      }

      return { ...state };
    }

    case Actions.front.lastVisitedURL.request().type: {
      const lastVisitedURL = action.payload;

      if (lastVisitedURL.indexOf('impersonate') === -1) {
        return {
          ...state,
          lastVisitedURL,
        };
      }

      return state;
    }
    /* SMART ATTRIBUTION START */

    // case Actions.front.smartAttribution.campaigns.filters.update.request()
    //   .type: {
    //   const { values, filterName } = action.payload;
    //   const nextState = produce(state, draftState => {
    //     draftState.smartAttribution.campaigns.filters[filterName] = values;
    //   });
    //   return nextState;
    // }

    /* SMART ATTRIBUTION ENDS */

    default:
      return state;
  }
}
