import './DropDownMenu.css';
import React, { Component } from 'react';
import type { Node } from 'react';

import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';

import { Triangle } from 'components/common/icons/Triangle';
import { getActivesAmazonProfiles } from 'lib/amazon';
import { addAccount } from 'lib/api';
import { getSignedUser } from 'lib/auth';
import axios from 'lib/axios.factory';
import { User } from 'lib/user';
import type { action } from 'stores/accounts';

import { LightButton as Button, CancelButton, LockedButton } from '../common/Button';
import Input from '../common/Input';
import { WorkspacesCTATooltip } from '../common/tooltip';

const StyledTriangle = styled(Triangle).withConfig({
  displayName: 'StyledTriangle',
})`
  width: 9px;
  height: 9px;
  position: relative;
  top: 3px;
  left: -8px;
`;

type propsT = {
  user: userT,
  account: accountT,
  accounts: Array<accountT>,
  limitations: { workspaces: number },
  usage: { workspaces: number },
  signOut: () => void,
  switchAccount: (string) => action,
  updateAccount: (accountT) => *,
  updateUser: (userT) => *,
};

class DropDownMenu extends Component<props, { open: boolean, displayAccountInput: boolean, newAccountName: string }> {
  constructor(props: propsT) {
    super(props);
    this.state = {
      open: false,
      displayAccountInput: false,
      newAccountName: '',
    };
  }
  input: HTMLInputElement;

  render() {
    const { account, accounts, signOut, switchAccount, user } = this.props;
    const { open, displayAccountInput, newAccountName } = this.state;

    return (
      <div className="drop-down-menu">
        <div
          className="drop-down-menu__title"
          onClick={() => this.setState({ open: !open })}
          style={{ cursor: 'pointer' }}
        >
          {account.name}
        </div>
        {open &&
          this.renderDropDown(account, accounts, switchAccount, signOut, displayAccountInput, newAccountName, user)}
        <StyledTriangle onClick={() => this.setState({ open: !open })} style={{ cursor: 'pointer' }} />
        {/* <img
          onClick={() => this.setState({ open: !open })}
          className="drop-down-menu__icon"
          src={icon}
          alt="account icon"
        /> */}
      </div>
    );
  }

  close() {
    this.setState({
      open: false,
      displayAccountInput: false,
      newAccountName: '',
    });
  }

  addAccount({ history }) {
    const { account, user, updateAccount, updateUser } = this.props;
    const orgId = Object.keys(user.orgsRights)[0];
    const { newAccountName } = this.state;

    addAccount(
      localStorage,
      axios,
      {
        accountId: account.id,
      },
      { name: newAccountName, orgId },
    ).then(({ account }) => {
      updateAccount(account);
      getSignedUser(localStorage, axios).then((user) => {
        updateUser(user);
        history.push(`/${account.id}`);
      });
    });
  }

  handleChangeNewAccountName = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;

    this.setState({
      newAccountName: value,
    });
  };

  renderWorkspaceButton(): Node {
    const { user, limitations, usage } = this.props;

    if (User.isOrgOwner(user)) {
      if (usage && usage.workspaces >= limitations.workspaces) {
        return (
          <WorkspacesCTATooltip>
            <LockedButton>Add workspace</LockedButton>
          </WorkspacesCTATooltip>
        );
      }

      return (
        <Button
          handleClick={(_) => this.setState({ displayAccountInput: true }, (_) => this.input && this.input.focus())}
        >
          Add workspace
        </Button>
      );
    }

    return null;
  }

  renderDropDown(
    account: accountT,
    accounts: Array<accountT>,
    switchAccount: (string) => action,
    signOut: () => void,
    displayAccountInput: boolean,
    newAccountName: string,
    user: userT,
  ): Node {
    return (
      <Route
        render={({ match, history }) => {
          const othersAccount = accounts.filter((ac) => ac.id !== account.id);

          return (
            <>
              <div className="drop-down-menu__content">
                <div className="drop-down-menu__account">
                  <div
                    className="drop-down-menu__account__name"
                    style={{
                      color: getActivesAmazonProfiles(account.productProviders).length > 0 ? '#F8A51C' : 'unset',
                    }}
                  >
                    {account.name}
                  </div>
                  <Link to={`/${account.id}/s/profile`}>Settings</Link>
                </div>
                {othersAccount.length > 0 && (
                  <div className="drop-down-menu__accounts">
                    {othersAccount.map((ac) => {
                      const to = `/${ac.id}`;

                      return (
                        <div key={ac.id} className="drop-down-menu__account">
                          <Link to={to} key={ac.id}>
                            <span
                              style={{
                                color: getActivesAmazonProfiles(ac.productProviders).length > 0 ? '#F8A51C' : 'unset',
                              }}
                            >
                              {ac.name}
                            </span>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                )}
                {displayAccountInput && (
                  <Input
                    handleRef={(input) => (this.input = input)}
                    id="account"
                    value={newAccountName}
                    placeholder="New account name"
                    onChange={this.handleChangeNewAccountName}
                  />
                )}
                <div className="drop-down-menu__actions">
                  {displayAccountInput && (
                    <>
                      <Button handleClick={(_) => this.addAccount({ history })}>Create workspace</Button>
                      <CancelButton
                        handleClick={(_) =>
                          this.setState({
                            displayAccountInput: false,
                          })
                        }
                      >
                        Cancel
                      </CancelButton>
                    </>
                  )}
                  {!displayAccountInput && <>{this.renderWorkspaceButton()}</>}
                </div>
              </div>
              <div className="drop-down-menu__mask" onClick={(_) => this.close()} />
            </>
          );
        }}
      />
    );
  }
}

export default DropDownMenu;
