import { Actions } from '../actions';

import 'lib/array';
import { trackedEvents } from 'config/trackedEvents.config';
import { track, traits } from 'lib/analytics';

export default (store: any) => (next: any) => async (action: any) => {
  next(action);
  switch (action.type) {
    case Actions.track.trackingFromBackend:
      if (action.payload) {
        if (action.payload.event_to_fire) {
          const { name, metas } = action.payload.event_to_fire;

          track(name, metas);
        }

        if (action.payload.user_traits_to_fire) {
          const { metas } = action.payload.user_traits_to_fire;

          traits(metas);
        }
      }

      break;

    case Actions.track.trackingOnboarding:
      track(trackedEvents.clickOnboarding, action.payload);
      break;
    case Actions.track.redirect.export.feedback.request().type:
      track(trackedEvents.exportFeedback, action.payload);
      store.dispatch(action.workerAction.success());
      break;
    case Actions.api.audienceBuilder.redirect.export.request().type:
      track(trackedEvents.exportRedirects, action.payload);
      break;
    case Actions.front.audienceBuilder.campaigns.columns.updateConfig.request().type:
      track(trackedEvents.updateRedirectColumnConfiguration, action.payload);
      break;
    case Actions.api.audienceBuilder.redirect.sort.request().type: {
      track(trackedEvents.sortRedirects, action.payload);
      break;
    }
    case Actions.api.audienceBuilder.campaigns.add.request().type: {
      track(trackedEvents.addCampaign, action.payload);
      break;
    }
    case Actions.api.audienceBuilder.campaigns.delete.request().type: {
      track(trackedEvents.deleteCampaign, action.payload);
      break;
    }
    case Actions.api.audienceBuilder.campaigns.edit.request().type: {
      track(trackedEvents.editCampaign, action.payload);
      break;
    }
    case Actions.api.audienceBuilder.campaigns.reOrder.request().type: {
      track(trackedEvents.reorderCampaign, action.payload);
      break;
    }
    case Actions.api.audienceBuilder.campaigns.addRedirects.request().type: {
      track(trackedEvents.addRedirectToCampaign, action.payload);
      break;
    }
    case Actions.api.audienceBuilder.redirect.delete.request().type: {
      track(trackedEvents.deleteRedirect, action.payload);
      break;
    }
    case Actions.api.audienceBuilder.redirect.edit.request().type: {
      const { redirect } = action.payload;

      if (redirect) {
        redirect.redirectId = redirect.id;

        if (redirect.id) {
          track(trackedEvents.editRedirect, { redirect });
        } else {
          track(trackedEvents.addRedirect, { redirect });
        }
      }

      break;
    }

    default:
      break;
  }
};
