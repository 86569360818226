// Thanks to https://stackoverflow.com/questions/12931828/convert-returned-json-object-properties-to-lower-first-camelcase
// and lodash

import numeral from 'numeral';

export function isObject(value: any) {
  const type = typeof value;

  return value !== null && (type === 'object' || type === 'function');
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function transformBigNumberToHumanReadable(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value);

  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}

export function toCamelCase(str: string): string {
  // Lower cases the string
  return (
    str
      .toLowerCase()
      // Replaces any - or _ characters with a space
      .replace(/[-_]+/g, ' ')
      // Removes any non alphanumeric characters
      .replace(/[^\w\s]/g, '')
      // Uppercases the first character in each group immediately following a space
      // (delimited by spaces)
      .replace(/ (.)/g, ($1) => $1.toUpperCase())
      // Removes spaces
      .replace(/ /g, '')
  );
}

export function toSnakeCase(str: string): string {
  return str
    .replace(/([A-Z])/g, '_$1')
    .toLowerCase()
    .replace(/^_+|_+$/g, '');
}

function switchCaseObjectKeys(obj: any, caseFunction: (string) => string) {
  if (!isObject(obj)) {
    return obj;
  } else if (Array.isArray(obj)) {
    return obj.map((v) => switchCaseObjectKeys(v, caseFunction));
  }

  const newObject = {};

  for (const key in obj) {
    newObject[caseFunction(key)] = switchCaseObjectKeys(obj[key], caseFunction);
  }

  return newObject;
}

export function toCamelCaseKeys(obj: any) {
  return switchCaseObjectKeys(obj, toCamelCase);
}

export function toSnakeCaseKeys(obj: any) {
  return switchCaseObjectKeys(obj, toSnakeCase);
}

export function firstElement(data: Array<*>): * {
  if (data.length > 0) {
    return data[0];
  }
}

export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export function validateEmail(email: string) {
  return emailRegex.test(email);
}

const urlRegex =
  // eslint-disable-next-line no-useless-escape
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export function validateUrl(url: string) {
  return urlRegex.test(url);
}

export function arraysEquals(a1: Array<*>, a2: Array<*>): boolean {
  if (a1.length !== a2.length) {
    return false;
  }

  for (const i in a1) {
    if (a2.indexOf(a1[i]) < 0) {
      return false;
    }
  }

  return true;
}

export function arraysIntersection(a1: Array<string>, a2: Array<string>): Array<string> {
  const intersection = [];

  for (const v in a1) {
    if (a2.indexOf(a1[v]) >= 0) {
      intersection.push(a1[v]);
    }
  }

  return intersection;
}

export function arraysSubstraction(a1: Array<string>, a2: Array<string>): Array<string> {
  const substraction = [];

  for (const v in a1) {
    if (a2.indexOf(a1[v]) < 0) {
      substraction.push(a1[v]);
    }
  }

  return substraction;
}

export function generateDateString(dateStr: string): string {
  // Remove all /
  dateStr = dateStr.replace(/\D+/g, '');

  if (dateStr.length > 6) {
    dateStr = dateStr.slice(0, 6);
  }

  if (dateStr.length > 3) {
    return `${dateStr.slice(0, 2)}/${dateStr.slice(2, 4)}/${dateStr.slice(4)}`;
  }

  if (dateStr.length > 1) {
    return `${dateStr.slice(0, 2)}/${dateStr.slice(2)}`;
  }

  return dateStr;
}

// https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
export function simpleCopyToClipboard(str: string): void {
  const el = document.createElement('textarea'); // Create a <textarea> element

  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before

  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element

  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
}

export function simpleHash(str: string) {
  let hash = 0;

  if (!str || str.length === 0) {
    return hash;
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);

    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
}

export function extractEventFromJsonPah(jsonPath: string): string {
  let parts = jsonPath.split('->');

  if (parts.length === 2) {
    parts = parts[1].split("'");

    if (parts.length === 3) {
      return parts[1];
    }

    return parts[0];
  }

  return jsonPath;
}

export function formatCurrency(amount: string, currency: string): string {
  let res = '';

  const formattedAmount = numeral(amount).format('0,0');

  if (currency === 'EUR') {
    res = `€${formattedAmount}`;
  } else if (currency === 'USD') {
    res = `$${formattedAmount}`;
  } else {
    res = `?${formattedAmount}`;
  }

  return res;
}

export function zeroIfNegative(value) {
  return value > 0 ? value : 0;
}

export const meanAverage = (arr: number[] = []): number => arr.reduce((acc, val) => acc + val, 0) / arr.length;
