import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Actions } from 'actions';
import { store } from 'stores';

import { CampaignTreeContainer, CampaignTreeElement } from './Common';
import CreateFolderButton from '../../../../common/buttons/CreateFolderButton';

type internalPropsT = { campaigns: campaignT[], loadCampaigns: Function };
type internalStateT = {};

class CampaignsTreeComponent extends Component<internalPropsT, internalStateT> {
  render() {
    const { campaigns, selectedCampaignId, selectedSubCampaignId, reOrderCampaigns } = this.props;

    const displayButtonCreateAFolder = (campaign) => {
      if (campaigns.length > 1) {
        return null;
      }

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2rem',
          }}
          onClick={() =>
            store.dispatch(
              Actions.front.audienceBuilder.campaigns.add.show.request({
                parent: campaign,
              }),
            )
          }
        >
          <CreateFolderButton></CreateFolderButton>
        </div>
      );
    };

    return (
      <CampaignTreeContainer>
        {!!campaigns &&
          campaigns.map((campaign, index) => (
            <div key={index}>
              <CampaignTreeElement
                key={campaign.id}
                campaign={campaign}
                reOrderCampaigns={reOrderCampaigns}
                selectedCampaignId={selectedCampaignId}
                selectedSubCampaignId={selectedSubCampaignId}
                displayIcon={campaigns.length > 1}
              />
              {displayButtonCreateAFolder(campaign)}
            </div>
          ))}
      </CampaignTreeContainer>
    );
  }

  componentDidMount() {
    this.props.loadCampaigns();
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  campaigns: state.campaigns.campaigns,
  selectedCampaignId: state.campaigns.selectedCampaignId,
  selectedSubCampaignId: state.campaigns.selectedSubCampaignId,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  loadCampaigns: (_) => dispatch(Actions.api.audienceBuilder.campaigns.load.request()),
  reOrderCampaigns: (campaign: campaignT) =>
    dispatch(
      Actions.api.audienceBuilder.campaigns.reOrder.request({
        parent: campaign,
      }),
    ),
});

// noinspection JSUnusedGlobalSymbols
export default connect(stateToProps, dispatchToProps, null)(CampaignsTreeComponent);
