import _ from 'lodash';

export function parseUtms(url: string): utms {
  try {
    const u = new URL(url);

    return {
      source: u.searchParams.get('utm_source') || '',
      medium: u.searchParams.get('utm_medium') || '',
      campaign: u.searchParams.get('utm_campaign') || '',
      term: u.searchParams.get('utm_term') || '',
      content: u.searchParams.get('utm_content') || '',
    };
  } catch (e) {
    return {
      source: '',
      medium: '',
      campaign: '',
      term: '',
      content: '',
    };
  }
}

function update(u, key, value) {
  if (value && value !== u.searchParams.get(key)) {
    u.searchParams.delete(key);
    u.searchParams.set(key, value);
  }

  if (!value) {
    u.searchParams.delete(key);
  }
}

export function updateUrlFromUtms({ url, utms }: { url: string, utms: utms }): string {
  try {
    const u = new URL(url);

    update(u, 'utm_source', utms.source);
    update(u, 'utm_medium', utms.medium);
    update(u, 'utm_campaign', utms.campaign);
    update(u, 'utm_term', utms.term);
    update(u, 'utm_content', utms.content);

    if (url.slice(-1) === '&' && u.href.slice(-1) !== '&') {
      return `${u.href}&`;
    }

    if (u.href.slice(-1) === '=' && url.slice(-1) !== '=') {
      return u.href.slice(0, -1);
    }

    if (u.href.slice(-1) === '/' && url.slice(-1) !== '/') {
      return u.href.slice(0, -1);
    }

    return u.href;
  } catch (e) {
    return url;
  }
}

export function addCampaignToUtmsValues(utmsValues: utmsValuesT, value: string, description: ?string): utmsValuesT {
  if (!utmsValues.campaign) {
    utmsValues.campaign = [];
  }

  if (!_.find(utmsValues.campaign, (utm) => utm.value === value)) {
    utmsValues.campaign.push({ value, description });
  }

  return utmsValues;
}

export function addSourceToUtmsValues(utmsValues: utmsValuesT, value: string, description: ?string): utmsValuesT {
  if (!utmsValues.source) {
    utmsValues.source = [];
  }

  if (!_.find(utmsValues.source, (utm) => utm.value === value)) {
    utmsValues.source.push({ value, description });
  }

  return utmsValues;
}

export function addMediumToUtmsValues(utmsValues: utmsValuesT, value: string, description: ?string): utmsValuesT {
  if (!utmsValues.medium) {
    utmsValues.medium = [];
  }

  if (!_.find(utmsValues.medium, (utm) => utm.value === value)) {
    utmsValues.medium.push({ value, description });
  }

  return utmsValues;
}
