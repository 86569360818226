import React, { useEffect } from 'react';

import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { GoogleOAuthError } from './GoogleOAuthError';
import { trackedEvents } from '../../../config/trackedEvents.config';
import { googleOauthCallback } from '../../../lib/adsPlateformApi';
import { track } from '../../../lib/analytics';
import { SmallPlaceholder } from '../../common/Placeholders';

/**
 * Page use to select the right Google Customer ID after OAuth
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function GoogleOAuthPage(props: { style: ?any, accountId: string, code: string }) {
  const history = useHistory();
  const [state, updateState] = useImmer({ error: undefined, loading: true });

  async function load() {
    try {
      updateState((d) => {
        d.loading = true;
        d.error = undefined;
      });
      await googleOauthCallback(
        localStorage,
        axios,
        { accountId: props.accountId },
        {
          code: props.code,
        },
      )
        .then((data) => {
          localStorage.setItem('adsProvider', JSON.stringify(data.adsProvider));

          track(trackedEvents.connectGoogle);
          setTimeout(() => {
            history.push(`/${props.accountId}/adsprovider/google`);
          }, 500);
        })
        .catch((e) => {
          if (e.errorMessage.includes('There is no Google Customer available')) {
            notification.error({
              message: 'No Google Customer available on your account',
            });
            track(trackedEvents.googleNotFound, { accountId: props.accountId });
            updateState((d) => {
              d.error = e;
            });
          } else {
            setTimeout(() => {
              history.push(`/${props.accountId}`);
            }, 500);
          }
        });
    } finally {
      await updateState((d) => {
        d.loading = false;
      });
    }
  }

  useEffect(() => {
    load().finally(
      updateState((draft) => {
        draft.loading = false;
      }),
    );
  }, []);

  if (state.error) {
    return <GoogleOAuthError />;
  }

  return <SmallPlaceholder text="We are importing your Google accounts, please wait a few seconds" />;
}
