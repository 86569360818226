import React, { useEffect, useState } from 'react';

import { Form, notification, Progress } from 'antd';
import _, { debounce } from 'lodash';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { getFacebookEstimated } from '../../../../lib/facebookPlateformApi';
import { transformBigNumberToHumanReadable } from '../../../../lib/util';
import { BoxShadow } from '../../../BoxShadow';
import { MutedText } from '../../../common/MutedText';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #595959;
  margin-right: 10px;
`;

const Paragraph = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 6px;
  width: 100%;
`;

const Value = styled.div`
  display: inline-block;
  max-width: 300px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin-left: 1em;
  color: #595959 !important;
  white-space: nowrap;
  wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;
`;

/**
 * Faebook Estimated reach
 * @param adsProvider
 * @param targeting
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookEstimated({ adsProvider, targeting }) {
  const Style = styled.div`
    width: 356px;
    min-width: 365px;
  `;
  const [loading, setLoading] = useState(false);
  const [estimated, setEstimated] = useState({});

  async function load() {
    setLoading(true);

    try {
      const data = await getFacebookEstimated(localStorage, axios, adsProvider, targeting);

      setEstimated(data[0]);
      console.log('data', data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      notification.error({
        duration: 20,
        message: e.detailMessage,
      });
    }
  }

  const debouncedLoad = debounce(load, 500);

  useEffect(() => {
    if (targeting && !_.isEmpty(targeting) && !_.isEmpty(_.get(targeting, 'geoLocations'))) {
      debouncedLoad();
    }
  }, [JSON.stringify(targeting)]);

  return (
    <Style>
      {loading && <SmallCompactPlaceholder />}
      {estimated && (
        <Form.Item label="Audience Definition" required={true}>
          <BoxShadow>
            <div style={{ display: 'flex', gap: '1em', width: '100%', flex: 1 }}>
              <span>Specific</span>
              <div style={{ flex: '1 0 100px' }}>
                <Progress showInfo={false} percent={(100 * estimated.estimateMauLowerBound) / 1000000} />
              </div>
              <span>Broad</span>
            </div>
          </BoxShadow>
          <BoxShadow>
            <Paragraph>
              <Title>Estimated audience size: </Title>
              <Value>
                {transformBigNumberToHumanReadable(estimated.estimateMauLowerBound)} -{' '}
                {transformBigNumberToHumanReadable(estimated.estimateMauUpperBound)}
              </Value>
            </Paragraph>
          </BoxShadow>
          <BoxShadow>
            <Paragraph>
              <Title>Link clicks: </Title>
              <Value>{transformBigNumberToHumanReadable(estimated.estimateDau)}</Value>
            </Paragraph>
          </BoxShadow>
          {_.get(targeting, 'geoLocations') && (
            <BoxShadow>
              <Paragraph>
                <Title>Countries: </Title>
                <Value>{_.uniq(_.get(targeting, 'geoLocations', []).map((c) => c.countryCode || c.key)).length}</Value>
              </Paragraph>
            </BoxShadow>
          )}
        </Form.Item>
      )}
      <MutedText>
        The accuracy of estimates is based on factors like past campaign data, the budget you entered, market data,
        targeting criteria and ad placements. Numbers are provided to give you an idea of performance for your budget,
        but are only estimates and don't guarantee results.
      </MutedText>
    </Style>
  );
}
