import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { BaseBanner } from './common';

const Style = styled(BaseBanner)`
  background: #007bb6;
`;

const PixelDisabledHeader = ({ account, displayPixelHeader }) => {
  if (!displayPixelHeader) {
    return null;
  }

  return (
    <Style>
      <span>
        Your pixels are disabled.{' '}
        <Link
          to={`/${account.id}/b/plans`}
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
            color: 'white',
          }}
        >
          Upgrade
        </Link>{' '}
        if you want to reactivate your pixels
      </span>
    </Style>
  );
};

export default PixelDisabledHeader;
