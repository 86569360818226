import styled from 'styled-components';

export const DropDownElementText = styled.li.withConfig({
  displayName: 'DropDownElementText',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  display: flex;
`;
