import './VAT.css';

import React from 'react';

export default function PoweredByStripe({ vat }: { vat: vatT }) {
  if (vat && vat.percent > 0) {
    return <div className="sub-checkout__vat">VAT: {vat.percent}%</div>;
  }

  return null;
}
