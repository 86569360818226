import React from 'react';

import { useIntl } from 'react-intl';

import { BarToolTip } from '../BarToolTip';

const Help = () => {
  const { messages } = useIntl();

  return (
    <BarToolTip title={messages.head.faq}>
      <svg className="bar__icon" width="33" height="33" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
        <g>
          <rect width="33" height="33" rx="4" className="icon-background" />
          <rect
            x="9"
            y="1"
            width="20"
            height="20"
            transform="rotate(45 11 10)"
            strokeWidth="2"
            className="icon-main-stroke"
          />
          <path
            d="M12.9346 15.9648V15.5273C12.9346 15.026 13.0257 14.609 13.208 14.2764C13.3903 13.9437 13.7093 13.5996 14.165 13.2441C14.7074 12.8158 15.056 12.4831 15.2109 12.2461C15.3704 12.0091 15.4502 11.7266 15.4502 11.3984C15.4502 11.0156 15.3226 10.7217 15.0674 10.5166C14.8122 10.3115 14.4453 10.209 13.9668 10.209C13.5339 10.209 13.1328 10.2705 12.7637 10.3936C12.3945 10.5166 12.0345 10.6647 11.6836 10.8379L11.1094 9.63477C12.0345 9.11979 13.0257 8.8623 14.083 8.8623C14.9762 8.8623 15.6849 9.08105 16.209 9.51855C16.7331 9.95605 16.9951 10.5599 16.9951 11.3301C16.9951 11.6719 16.945 11.9772 16.8447 12.2461C16.7445 12.5104 16.5918 12.7633 16.3867 13.0049C16.1862 13.2464 15.8376 13.5609 15.3408 13.9482C14.917 14.2809 14.6322 14.5566 14.4863 14.7754C14.3451 14.9941 14.2744 15.2881 14.2744 15.6572V15.9648H12.9346ZM12.6543 18.1455C12.6543 17.4574 12.9893 17.1133 13.6592 17.1133C13.9873 17.1133 14.238 17.2044 14.4111 17.3867C14.5843 17.5645 14.6709 17.8174 14.6709 18.1455C14.6709 18.4691 14.582 18.7266 14.4043 18.918C14.2311 19.1048 13.9827 19.1982 13.6592 19.1982C13.3356 19.1982 13.0872 19.1071 12.9141 18.9248C12.7409 18.738 12.6543 18.4782 12.6543 18.1455Z"
            className="icon-main-fill"
            transform="translate(2 2)"
          />
        </g>
      </svg>
    </BarToolTip>
  );
};

export default Help;
