import React from 'react';

import Button from 'antd/lib/button';
import { navigate } from 'hookrouter';
import styled from 'styled-components';

import { trackedEvents } from '../../../../config/trackedEvents.config';
import { track } from '../../../../lib/analytics';

// @ts-ignore

const Style = styled.div`
  text-align: right;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  .skip {
    border: 1px solid white !important;
    color: white !important;
    background-color: transparent !important;
  }
`;

export function SkipNextButton({ finish, account, step }) {
  return (
    <Style>
      {!finish && (
        <Button
          className="skip"
          style={{
            border: 'white !important',
            color: 'white !important',
            backgroundColor: 'transparent !important',
          }}
          size="large"
          title="SKIP"
          onClick={() => {
            switch (step) {
              case 2: {
                track(trackedEvents.newSignupStep2, { account });
                break;
              }
              case 3: {
                track(trackedEvents.newSignupStep3, { account });
                break;
              }
              case 4: {
                track(trackedEvents.newSignupStep4, { account });
                break;
              }
              default: {
              }
            }
            navigate('/easy/signup');
          }}
        >
          SKIP
        </Button>
      )}
      <Button
        size="large"
        title="NEXT"
        htmlType="submit"
        onClick={() => {
          switch (step) {
            case 2: {
              track(trackedEvents.step2Completed, { account });
              break;
            }
            case 3: {
              track(trackedEvents.step3Completed, { account });
              break;
            }
            case 4: {
              track(trackedEvents.step4Completed, { account });
              break;
            }
            default: {
            }
          }
        }}
      >
        {finish ? 'FINISH' : 'NEXT'}
      </Button>
    </Style>
  );
}
