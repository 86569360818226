import './Donut.css';

import React from 'react';

// $FlowFixMe
import { Doughnut } from 'react-chartjs-2';

const backgroundColor = ['#2E89E2', '#2EC4B6', '#F2994A', '#FB3B49', '#6E6E6E'];

type props = {
  values: Array<{
    id: string,
    label: string,
    value: number,
    uniqueValue: number,
  }>,
  nbOfItems: number,
  total: number,
};
export default function Donut({ values, nbOfItems, total }: props) {
  // split on
  const { values: cutValues, labels } = cutOnMax({
    values,
    nbOfItems,
    total,
  });
  const data = {
    labels,
    datasets: [
      {
        data: cutValues,
        backgroundColor,
        // hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
      },
    ],
  };

  return (
    <div className="donut">
      <Doughnut data={data} height={180} options={{ cutoutPercentage: 70 }} />
    </div>
  );
}

function cutOnMax({ values, total, nbOfItems }: props): { values: Array<number>, labels: Array<string> } {
  const curValues = [];
  const labels = [];

  let index = 0;
  let curTotal = 0;

  while (index < nbOfItems - 1 && index < values.length) {
    const value = values[index];

    curTotal += value.uniqueValue;
    curValues.push(value.uniqueValue);
    labels.push(cleanLabel(value.label, 30));
    index++;
  }

  // no more items, return
  if (index === values.length) {
    return { values: curValues, labels };
  }

  if (nbOfItems >= values.length) {
    // less items or equals than limit
    const value = values[index];

    curValues.push(value.uniqueValue);
    labels.push(cleanLabel(value.label, 30));
  } else {
    // generate others item
    curValues.push(total - curTotal);
    labels.push('Others');
  }

  return { values: curValues, labels };
}

function cleanLabel(label: string, maxSize: number = 6): string {
  if (label.length < maxSize + 4) {
    return label;
  }

  return `${label.slice(0, maxSize)}...`;
}

export { cutOnMax as curOnMaxTest, cleanLabel as cleanLabelTest };
