import React, { useState, useRef } from 'react';

import { Button, Divider, Form, Input } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

import { sendValidationEmail } from 'lib/api';
import axios from 'lib/axios.factory';

import { useLoading } from '../../../hooks/useLoading';
import { RoundInfo } from '../../common/icons/RoundInfo';
import SignLayout from '../../layout/Sign/SignLayout';
import { PromoApplied2 } from '../easy/images/PromoApplied2';
import { SignupSuccess } from '../easy/images/SignupSuccess';
import { SignupSuccessV2 } from '../easy/images/SignupSuccessV2';
import { C6Header, gtmButtonStyles, Title } from '../signUp/Theme';

export function NotValidated({ user, onSignOut }: { user: userT, onSignOut: () => void }) {
  user = user || { email: 'your email' };
  const captchaRef = useRef(null);
  const [newUser, setUser] = useState(user);
  const [email, setEmail] = useState(user.email);
  const [error, setError] = useState();
  const { doAction, loading: sending } = useLoading(false);
  const theme = localStorage.getItem('signup-theme');

  function handleSubmit(values) {
    if (!values.captcha) {
      return;
    }

    doAction(async () => {
      try {
        sendValidationEmail(localStorage, axios, {
          newEmail: values.email,
          captcha: values.captcha,
        });
        setError('');
        setUser({ ...user, email: values.email });
      } catch (error) {
        if (captchaRef && captchaRef.current) {
          captchaRef.current.reset();
        }

        const { errorMessage = 'Unable to send validation email' } = error;

        setError(errorMessage);
      }
    });
  }

  return (
    <SignLayout
      errorMessage={error}
      theme={theme}
      header={theme === 'gtm' ? <C6Header /> : theme === 'sign-up-c6' ? <></> : null}
      title={
        theme === 'gtm' || theme === 'sign-up-c6' ? (
          <Title>Check your inbox & confirm your email address!</Title>
        ) : (
          'Check your inbox & confirm your email address!'
        )
      }
      description={
        <div>
          An email has been sent to <span className="highlight">{newUser.email}</span> with a validation link. Check
          your inbox.
        </div>
      }
      footer={
        <Link className="highlight" to="/sign-out">
          Back to Sign in page
        </Link>
      }
      image={theme === 'gtm' ? <PromoApplied2 /> : theme === 'sign-up-c6' ? <SignupSuccessV2 /> : <SignupSuccess />}
    >
      <Divider orientation="horizontal" />
      <Form onFinish={handleSubmit} layout="vertical">
        <Form.Item>
          <RoundInfo /> <span style={{ fontSize: 14 }}>Didn't receive an email?</span>
        </Form.Item>
        <Form.Item name="email" label="Email" initialValue={email}>
          <Input disabled={true} size="large" placeholder="john@apple.com" />
        </Form.Item>
        <Form.Item name="captcha">
          <ReCAPTCHA ref={captchaRef} sitekey={window.env.REACT_APP_CAPTCHA_SITE_KEY} />
        </Form.Item>
        <Button
          size="large"
          block
          htmlType="submit"
          type="primary"
          style={gtmButtonStyles[theme] || {}}
          loading={sending}
        >
          Resend Email
        </Button>
      </Form>
      <br />
    </SignLayout>
  );
}
