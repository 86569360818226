import React, { Fragment } from 'react';

import { Divider } from 'antd';
import _ from 'lodash';
import styled from 'styled-components';

import { s, sd } from 'lib/safe';
import { getCurrency } from 'utils/currency';

import { CurrencyValue } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { AdsPreview } from '../common/AdsPreview';

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #595959;
  margin-right: 10px;
`;

const Paragraph = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 6px;
`;

const Value = styled.div`
  display: inline-block;
  max-width: 300px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #595959 !important;
  white-space: nowrap;
  wrap: none;
  text-overflow: ellipsis;
  overflow: hidden;

  .ant-statistic-content {
    color: #595959 !important;
    font-size: 13px;
  }
`;

export function GoogleRawCampaignView({ account, rawQueryResult }) {
  const { currencySymbol } = getCurrency(account);

  return (
    <Fragment>
      <Divider orientation="horizontal" />
      <AdsPreview
        keywords={sd(rawQueryResult.googleRawCampaign.campaignCriteriaKeywords, []).map(
          (c) => c.adGroupCriterion.displayName,
        )}
        url={rawQueryResult.googleRawCampaign.adGroupAd.ad.finalUrls.join(',')}
        headlines={_.get(
          rawQueryResult,
          ['googleRawCampaign', 'adGroupAd', 'ad', 'responsiveSearchAd', 'headlines'],
          '',
        ).map((t) => t.text)}
        descriptions={_.get(
          rawQueryResult,
          ['googleRawCampaign', 'adGroupAd', 'ad', 'responsiveSearchAd', 'descriptions'],
          '',
        ).map((t) => t.text)}
      ></AdsPreview>
      <Divider orientation="horizontal" />
      <h3>Campaign Settings</h3>
      <Paragraph>
        <Title>Campaign name:</Title>
        <Value>{rawQueryResult.googleRawCampaign.campaign.name}</Value>
      </Paragraph>
      <Paragraph>
        <Title>Location:</Title>
        <Value
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {' '}
          {sd(rawQueryResult.googleRawCampaign.campaignCriteriaLocation, [])
            .map((c) => c.geoTarget.canonicalName)
            .filter((l) => !!l)
            .join(',')}
        </Value>
      </Paragraph>
      <Paragraph>
        <Title>Languages:</Title>
        <Value
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {sd(rawQueryResult.googleRawCampaign.campaignCriteriaLanguage, [])
            .map((c) => c.languageConstant.name)
            .filter((l) => !!l)
            .join(',')}
        </Value>
      </Paragraph>
      <Paragraph>
        <Title>Budget:</Title>
        <Value>
          <CurrencyValue
            amount={s(rawQueryResult.googleRawCampaign.campaignBudget).amountMicros / 1000000}
            currency={currencySymbol}
            suffix="/day"
          />
        </Value>
      </Paragraph>
      <Paragraph>
        <Title>Start date:</Title>
        <Value>{s(rawQueryResult.googleRawCampaign.campaign).startDate || 'Start today'}</Value>
      </Paragraph>
      <Paragraph>
        <Title>End date:</Title>
        {s(rawQueryResult.googleRawCampaign.campaign).endDate || 'Never end'}
        <Value></Value>
      </Paragraph>
      <Divider orientation="horizontal" />
      <div>
        <h3>Setup Ad group</h3>
      </div>
      <Paragraph>
        <Title>Keywords:</Title>
        <Value>
          {sd(rawQueryResult.googleRawCampaign.campaignCriteriaKeywords, [])
            .map((c) => c.adGroupCriterion.displayName)
            .filter((l) => !!l)
            .join(',')}
        </Value>
      </Paragraph>
    </Fragment>
  );
}
