import React from 'react';

import styled from 'styled-components';

import Box from '../../common/Box';

const Style = styled.div`
  color: #6d859e;
  height: 100%;

  p {
    font-size: 13px !important;
  }

  h1 {
    color: #000;
    font-weight: 700;
    font-size: 13px !important;
  }
`;

/**
 * Amazon FAQ Page
 * @returns {JSX.Element}
 * @constructor
 */
export function AmazonFAQ() {
  return (
    <Style>
      <Box header="Get started with PixelMe & Amazon attribution">
        <div style={{ padding: '1em', width: '1095px' }}>
          <br />
          <br />
          <h1>1/ Create a tracked short link</h1>
          <br />
          <p>
            From the creation modal, create a new link and select the 'Activate auto tagging'. We'll automatically add
            all parameters on your link to track the attribution on Amazon. Please don't edit those parameters.
          </p>
          <br />
          <br />
          <h1>2/ Launch your ads on Facebook or other ad platforms</h1>
          <br />
          <p>
            Simply use your short link as usual on your ad platforms. Please note, use the short link and not the long
            URL of your Amazon product.
          </p>
          <br />
          <br />
          <h1>3/ Get results on PixelMe</h1>
          <br />
          <p>
            We'll automatically collect data from Amazon directly on PixelMe. For each of your links, you'll be able to
            collect the number of 'add to cart' or 'purchases' for example.
          </p>
          <br />
          <br />
          <h1>4/ Launch a retargeting ad</h1>
          <br />
          <p>
            If you included retargeting pixels (step 1) into your short link, launch a retargeting ad based on people
            who clicked on your PixelMe links! And track the ROI of all your campaign.
          </p>
        </div>
      </Box>
    </Style>
  );
}
