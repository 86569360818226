import React from 'react';
import type { Element } from 'react';

import { MenuItem } from './MenuItem';

export function SubMenu({
  children,
  className = '',
}: {
  children: Array<Element<typeof MenuItem>>,
  className?: string,
}) {
  return (
    <nav className={`${className} content__submenu`}>
      <ul>{children}</ul>
    </nav>
  );
}
