export const tiktokConnections = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Wifi',
    value: 'WIFI',
  },
  {
    label: '2G',
    value: '2G',
  },
  {
    label: '3G',
    value: '3G',
  },
  {
    label: '4G',
    value: '4G',
  },
];
