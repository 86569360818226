import React from 'react';

export function StackIcon(props) {
  return (
    <span {...props}>
      <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="10" width="18" height="2" rx="1" fill="#DBDBDB" />
        <rect width="18" height="2" rx="1" fill="#DBDBDB" />
        <rect y="5" width="18" height="2" rx="1" fill="#DBDBDB" />
        <rect y="15" width="18" height="2" rx="1" fill="#DBDBDB" />
      </svg>
    </span>
  );
}
