import React, { Fragment, useContext, useEffect, useState } from 'react';

import { SendOutlined } from '@ant-design/icons';
import { Divider, Popconfirm, Skeleton, Table, Tooltip, message, notification } from 'antd';
import { parse } from 'json2csv';
import _ from 'lodash';
import fileDownload from 'react-file-download';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ExportButton } from 'components/common/buttons/ExportButton';
import { HoverLink } from 'components/common/HoverLink';
import { Delete, Edit } from 'components/common/icons';
import { Amazon } from 'components/common/icons/Amazon';
import { Csv } from 'components/common/illustrations/Csv';
import { Lock } from 'components/common/illustrations/Lock';
import { MutedText } from 'components/common/MutedText';
import { SmallPlaceholder } from 'components/common/Placeholders';
import { trackedEvents } from 'config/trackedEvents.config';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { useLoading } from 'hooks/useLoading';
import { useUpgrade } from 'hooks/useUpgrade';
import { getProductTimeSeries } from 'lib/adsPlateformApi';
import { track } from 'lib/analytics';
import axios from 'lib/axios.factory';
import { useFeatureFlag, TRANSFER_PRODUCT, HIDE_PRODUCT_METRICS } from 'lib/configcat';
import { useDeleteProductMutation } from 'stores/productsSlice';
import { getCurrency } from 'utils/currency';

import ProductEditModal from './ProductEditModal';
import { AddNewProductButton } from './ProductsDashBoard';
import { TransferProductModal } from './TransferProductModal';
import type { productForTableT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import {
  ACOSValue,
  CurrencyValue,
  NumberVal,
  ROASValue,
} from '../../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';
import { AttributionSteps } from '../../common/AttributionSteps';
import { FloatingRowAction } from '../../common/FloatingRowAction';
import { HideIfNoAmazonData } from '../../common/HideIfNoAmazonData';
import { NAValue } from '../../common/NAValue';
import { RowCellWithSpanOverflow } from '../../common/RowCellWithSpanOverflow';
import { ShowProduct } from '../../common/ShowProduct';
import { NewCampaignButton } from '../../NewCampaign/NewCampaignButton';
import { TYPE_PRODUCT } from '../../NewCampaign/NewProductModal';
import { BrbValue } from '../BrbValue';

const Style = styled.div`
  overflow: auto;
  font-size: 13px;
  max-width: 100%;

  .ant-table-pagination.ant-pagination {
    justify-content: left;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title {
    font-size: 13px;
  }
`;

export function ProductsTable(props: {
  products: productForTableT[],
  account: accountT,
  setSelectedRowKeys: any,
  productProviderId: string,
}) {
  const [productTransferEnabled] = useFeatureFlag(TRANSFER_PRODUCT);
  const [hideProductMetrics] = useFeatureFlag(HIDE_PRODUCT_METRICS);

  const history = useHistory();
  const { currencyCode, currencySymbol } = getCurrency(props.account);
  const { ctx } = useContext(DashboardFilterContext);
  const [deleteProduct, { isError }] = useDeleteProductMutation();
  const { messages } = useIntl();

  const { showUpgrade, hideUpgrade, immediateHideUpgrade } = useUpgrade(
    'product-limit',
    ' to get attribution data on this product (limit reached)',
  );

  useEffect(() => {
    if (isError) {
      notification.error({
        duration: 500,
        message: 'Unable to delete product',
        description: <div>Delete campaigns first before deleting the product.</div>,
      });
    }
  }, [isError]);

  const columns = [
    {
      title: '',
      dataIndex: 'productImageUrl',
      key: 'productImageUrl',
      width: 60,
      onCell: (record) => ({
        onMouseEnter: (e) => {
          if (record.outOfLimits) {
            showUpgrade(e);
          }
        },
        onMouseLeave: (e) => {
          if (record.outOfLimits) {
            hideUpgrade(e);
          } else {
            immediateHideUpgrade(e);
          }
        },
      }),
      render: (v, product: productForTableT) => {
        if (product.outOfLimits) {
          return <Lock size={20} />;
        } else if (v) {
          return (
            <div>
              <img height={20} src={v} alt="Product" />
            </div>
          );
        } else {
          return (
            <div>
              <Amazon size={20} />
            </div>
          );
        }
      },
    },
    {
      title: messages.locales.columns.productName,
      dataIndex: 'name',
      key: 'name',
      width: 400,
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (v) => (
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '350px',
          }}
        >
          {v}
        </div>
      ),
    },
    {
      title: messages.locales.columns.liveImpressions,
      dataIndex: 'liveImpressions',
      key: 'liveImpressions',
      hidden: ctx.showAttributedData,
      width: 120,
      maxWidth: 120,
      minWidth: 120,
      render: displayCell((v, product) => (
        <Fragment>
          <NumberVal clicks={v}></NumberVal>
        </Fragment>
      )),
      sorter: (a, b) => a.liveImpressions - b.liveImpressions,
    },
    {
      title: messages.locales.columns.liveClicks,
      dataIndex: 'liveClicks',
      key: 'liveClicks',
      hidden: ctx.showAttributedData,
      width: 120,
      render: displayCell((v, product) => (
        <>
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        </>
      )),
      sorter: (a, b) => a.liveClicks - b.liveClicks,
    },
    {
      title: messages.locales.columns.liveAdCost,
      dataIndex: 'liveAdSpent',
      key: 'liveAdSpent',
      hidden: ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.liveAdSpent - b.liveAdSpent,
      render: displayCell((v, product) => (
        <>
          <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
        </>
      )),
    },
    {
      title: messages.locales.columns.liveCPC,
      dataIndex: 'liveAverageCpc',
      key: 'liveAverageCpc',
      hidden: ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.liveAverageCpc - b.liveAverageCpc,
      render: displayCell((v, product) => (
        <>
          <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
        </>
      )),
    },
    {
      title: messages.locales.columns.attributedImpressions,
      dataIndex: 'impressions',
      key: 'impressions',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: displayCell((v, product) => {
        if (hideProductMetrics) {
          return (
            <RowCellWithSpanOverflow>
              <MutedText style={{ fontSize: 12, marginLeft: '24px' }}>
                Product metrics are not available for current account.
              </MutedText>
            </RowCellWithSpanOverflow>
          );
        }

        /* Albert asked to remove that by emails and keep only steps for campaigns*/
        if (!product.totalCampaignsCount) {
          return (
            <RowCellWithSpanOverflow>
              <MutedText style={{ fontSize: 12, marginLeft: '24px' }}>
                {messages.locales.dash.noCampaignsError}
              </MutedText>
              <NewCampaignButton type="dashed" ghost size="small" account={props.account} productId={product.id} />
            </RowCellWithSpanOverflow>
          );
        }

        if (!product.lastClicks) {
          return (
            <RowCellWithSpanOverflow>
              <AttributionSteps report={product} />
            </RowCellWithSpanOverflow>
          );
        }

        return (
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        );
      }),
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: messages.locales.columns.attributedClicks,
      dataIndex: 'clicks',
      key: 'clicks',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        </HideIfNoAmazonData>
      )),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Amazon Clickthroughs',
      dataIndex: 'amazonClickThroughs',
      key: 'amazonClickThroughs',
      hidden: true,
      className: 'amazon',
      width: 120,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        </HideIfNoAmazonData>
      )),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: messages.locales.columns.attributedAdCost,
      dataIndex: 'adSpent',
      key: 'adSpent',
      hidden: !ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
          </Fragment>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: messages.locales.columns.attributedCpc,
      dataIndex: 'averageCpc',
      key: 'averageCpc',
      hidden: !ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.averageCpc - b.averageCpc,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
          </Fragment>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: messages.locales.columns.rawData,
      hidden: ctx.showAttributedData,
      width: 140,
      style: { padding: 0 },
      className: 'no-padding',
      render: displayCell((v, product) => (
        <div>{product.totalCampaignsCount >= 0 && <ExportProduct product={product} account={props.account} />}</div>
      )),
    },
    {
      title: messages.locales.columns.addToCart,
      dataIndex: 'amazonTotalAddToCart',
      key: 'amazonTotalAddToCart',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.amazonTotalAddToCart - b.amazonTotalAddToCart,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: messages.locales.columns.purchases,
      dataIndex: 'amazonTotalPurchases',
      key: 'amazonTotalPurchases',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.amazonTotalPurchases - b.amazonTotalPurchases,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <NumberVal clicks={v}></NumberVal>
          </Fragment>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: messages.locales.columns.revenue,
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
          </Fragment>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: messages.locales.columns.netMargin,
      dataIndex: 'netMargin',
      key: 'netMargin',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.netMargin - b.netMargin,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: messages.locales.columns.roas,
      dataIndex: 'roas',
      key: 'roas',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.roas - b.roas,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <ROASValue roas={v} />
          </Fragment>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: messages.locales.columns.acos,
      dataIndex: 'acos',
      key: 'acos',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.acos - b.acos,
      render: displayCell((v, product) => (
        <HideIfNoAmazonData report={product}>
          <Fragment>
            <ACOSValue acos={v} />
          </Fragment>
        </HideIfNoAmazonData>
      )),
    },
    {
      title: (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <span>{messages.locales.columns.avgBrandReferralBonus}</span>
        </Tooltip>
      ),
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      hidden: !ctx.showAttributedData,
      width: 160,
      render: displayCell((v, product) => (
        <Fragment>
          {product.amazonTotalSales > 0 && (
            <BrbValue
              brbBonusAmountCalculated={product.brbBonusAmountCalculated}
              brbBonusAmount={product.brbBonusAmount}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              brbEnrolled={_.get(
                props.account.productProviders.find((p) => p.id === props.productProviderId),
                'brbEnrolled',
              )}
            />
          )}
        </Fragment>
      )),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: messages.locales.columns.productASIN,
      dataIndex: 'externalId',
      key: 'externalId',
      width: 160,
      render: (v, product) => <>{v && product.type === TYPE_PRODUCT ? v : <NAValue />}</>,
      sorter: (a, b) => a.externalId - b.externalId,
    },
    {
      title: '',
      width: 1,
      fixed: 'right',
      render: (v, product: productForTableT) => (
        <FloatingRowAction
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <ExportButton account={props.account} product={product} theme="icon-with-divider" />
          <EditProduct account={props.account} product={product} />
          <Divider type="vertical"></Divider>
          {productTransferEnabled && (
            <>
              <TransferProduct account={props.account} product={product} />
              <Divider type="vertical"></Divider>
            </>
          )}
          <ShowProduct product={product} />
          <Divider type="vertical"></Divider>
          <Popconfirm
            title={messages.locales.dash.deleteConfirm}
            onConfirm={async () => {
              deleteProduct({
                accountId: props.account.id,
                providerId: product.providerId,
                productASIN: product.externalId,
              });
            }}
          >
            <div>
              <IconInsideButton>
                <Delete />
              </IconInsideButton>
            </div>
          </Popconfirm>
          <Divider type="vertical"></Divider>
        </FloatingRowAction>
      ),
    },
  ].filter((e) => !e.hidden);

  const rowSelection = {
    selectionType: 'checkbox',
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      props.setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <Style>
      <Table
        rowSelection={rowSelection}
        style={{ cursor: 'pointer' }}
        rowKey="id"
        scroll={{ x: ctx.showAttributedData ? 1500 : 1200 }}
        locale={{
          emptyText: (
            <div style={{ maxWidth: 200, margin: 'auto' }}>
              <MutedText>{messages.locales.dash.noProducts}</MutedText>
              <AddNewProductButton account={props.account} />
            </div>
          ),
        }}
        pagination={{
          defaultPageSize: 25,
        }}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            if (record.outOfLimits) {
              history.push(`/${props.account.id}/b/plans`);
            } else {
              history.push(`/${props.account.id}/adsprovider/${record.providerId}/${record.externalId}/campaigns`);
            }
          },
        })}
        rowClassName={(record, index) =>
          `${record.totalCampaignsCount === 0 || !record.lastClicks ? 'row-empty' : ''} ${
            record.outOfLimits ? 'row-out-of-limits' : ''
          }`
        }
        dataSource={props.products}
        columns={columns}
      />
      <div style={{ textAlign: 'center' }}>
        <MutedText>{messages.locales.dash.infoAttribution}</MutedText>
      </div>
    </Style>
  );
}

function ExportProduct(props: { account: accountT, product: productForTableT }) {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);

  return (
    <ExclusiveInlineLoadingContainer>
      <HoverLink
        style={{ width: '100%' }}
        content="Download data"
        onClick={(e) => {
          doAction(async () => {
            const data = await getProductTimeSeries(
              localStorage,
              axios,
              { accountId: props.account.id },
              _.get(props, 'product.providerId'),
              _.get(props, 'product.externalId'),
            );

            const result = parse(_.get(data, 'timeseriesAdsReports'), []);

            fileDownload(result, `${_.get(props, 'product.name')}.csv`);
          });
        }}
        children={<Csv />}
      />
    </ExclusiveInlineLoadingContainer>
  );
}

function TransferProduct(props: { account: accountT, product: productForTableT }) {
  const [visible, setVisible] = useState(false);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <TransferProductModal
        account={props.account}
        product={props.product}
        visible={visible}
        onFinish={() => {
          setVisible(false);
          window.location.reload();
        }}
        onCancel={() => {
          setVisible(false);
        }}
      />
      <IconInsideButton>
        <SendOutlined onClick={() => setVisible(true)} />
      </IconInsideButton>
    </div>
  );
}

function EditProduct(props: { account: accountT, product: productForTableT }) {
  const [visible, setVisible] = useState(false);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {visible && (
        <ProductEditModal
          account={props.account}
          productId={props.product.id}
          onCancel={() => {
            setVisible(false);
          }}
        />
      )}
      <IconInsideButton>
        <Edit onClick={() => setVisible(true)}></Edit>
      </IconInsideButton>
    </div>
  );
}

function displayCell(children) {
  return (v, product) => {
    if (product.outOfLimits) {
      return <Skeleton.Button size="small" />;
    } else {
      return children(v, product);
    }
  };
}
