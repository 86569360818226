import React from 'react';

import { notification, Popconfirm } from 'antd';
import Button from 'antd/lib/button';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { trackedEvents } from '../../../config/trackedEvents.config';
import { deleteProductProvider } from '../../../lib/adsPlateformApi';
import { track } from '../../../lib/analytics';

export function AmazonLogoutButton(props: { provider: productProviderT, accountId: string }) {
  const [state, updateState] = useImmer({ loading: false });

  const onDeleteProductProvider = async (productProviderId) => {
    try {
      updateState((draft) => {
        draft.loading = true;
      });
      await deleteProductProvider(localStorage, axios, productProviderId, {
        accountId: props.accountId,
      });
      track(trackedEvents.disconnectAmazon);
      window.location.reload();
    } catch (e) {
      notification.error({
        duration: 500,
        message: 'Unable to delete product provider',
        description: (
          <div>
            You can't delete a product provider that still have products. <br />
            Please delete the products first.
          </div>
        ),
      });
      console.error(e);
    } finally {
      updateState((draft) => {
        draft.loading = false;
      });
    }
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete all your data?"
      onConfirm={() => onDeleteProductProvider(props.provider.id)}
    >
      <Button danger type="text" loading={state.loading} style={{ padding: 0 }}>
        Disconnect and remove all data
      </Button>
    </Popconfirm>
  );
}
