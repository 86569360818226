export const tiktokCTAs = [
  {
    value: 'LEARN_MORE',
    label: 'View details',
  },
  {
    value: 'SHOP_NOW',
    label: 'Shop now',
  },
  {
    value: 'READ_MORE',
    label: 'Read More',
  },
  {
    value: 'ORDER_NOW',
    label: 'Order Now',
  },
  {
    value: 'INTERESTED',
    label: 'Interested',
  },
  {
    value: 'PREORDER_NOW',
    label: 'Pre-order Now',
  },
  {
    value: 'VISIT_STORE',
    label: 'Visit Store',
  },
];
