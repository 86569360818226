import styled from 'styled-components';

const Badge = styled.div`
  padding: 0 0.75rem;
  background-color: #e6ebff;
  border-radius: 20px;
  height: 28px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const BadgeGroup = styled.div`
  display: flex;
  gap: 0.25rem;
  flex-wrap: wrap;
  margin-top: 6px;
  min-height: 30px;
  ${(props) => props.$centerAlign && 'justify-content: center;'}
`;

export const GenericBadges = ({ list, centerAlign = false }: { list: string[] }) => (
  <BadgeGroup $centerAlign={centerAlign}>
    {list.map((id) => (
      <Badge key={id}>{id}</Badge>
    ))}
  </BadgeGroup>
);
