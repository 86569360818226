import React, { type Node } from 'react';

import { SwapOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import { ComparisonBadge } from 'components/common/ComparisonBadge';

import { InfoIcon } from '../Style';

const Container = styled.div`
  flex: 1;
  background-color: #fff;
  min-width: 250px;
`;

const Header = styled.div`
  font-size: 1rem;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  > span {
    position: relative;
    margin-right: 1.25rem;
    line-height: 1.5rem;
  }
`;

const ToggleButton = styled(Button)`
  color: #828282;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
  > span {
    font-size: 0.75rem;
  }
`;

const Content = styled.div`
  padding: 1rem;
  border-top: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  flex-wrap: wrap;
`;

const Value = styled.span`
  font-size: 1.75rem;
`;

const Caption = styled.span`
  font-size: 0.625rem;
  min-width: 50px;
  flex: 1;
`;

const defaultValueFormatter = (value: number) => value.toFixed(0);

type propsT = {
  title: string,
  tooltip?: string,
  value?: null | number,
  previousValue?: null | number,
  previousValueCaption: string,
  toggleText?: Node,
  onToggle?: (string) => void,
  inverse?: boolean,
  suffix?: string,
  valueFormatter?: (number) => string,
  comparisonFormatter?: (number) => string,
};
export const KeyMetric = ({
  title,
  tooltip,
  value,
  previousValue,
  previousValueCaption,
  toggleText,
  onToggle,
  inverse,
  suffix = '%',
  valueFormatter = defaultValueFormatter,
  comparisonFormatter,
}: propsT): Node => (
  <Container>
    <Header>
      <span>
        {title}
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoIcon $superscript />
          </Tooltip>
        )}
      </span>
      {onToggle && toggleText && (
        <ToggleButton size="small" onClick={() => onToggle(title)}>
          <SwapOutlined /> {toggleText}
        </ToggleButton>
      )}
    </Header>
    <Content>
      {value != null && (
        <Value>
          {valueFormatter(value)}
          {suffix}
        </Value>
      )}
      <ComparisonBadge
        value={value}
        previousValue={previousValue}
        inverse={inverse}
        suffix={suffix}
        valueFormatter={valueFormatter}
        comparisonFormatter={comparisonFormatter}
      />
      <Caption>
        {value == null || previousValue == null
          ? 'Not enough data to compare the selected period'
          : previousValueCaption}
      </Caption>
    </Content>
  </Container>
);
