import produce from 'immer';

import type { axiosParams } from './api';
import { apiCall, apiCallNoErrorHandler } from './api';
import type { adsCampaignT, productT } from '../../flow-typed/pixelme/adsPlateform';

/**
 * Delete the specified productprovider
 * @param localStorage
 * @param axios
 * @param productProviderId
 * @param params
 * @returns {Promise<*>}
 */
export function deleteProductProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  productProviderId: string,
  params: { accountId: string },
): Promise {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: `product-providers/${productProviderId}`,
    params,
  });
}

/**
 * Get All product provider profiles
 * @param localStorage
 * @param axios
 * @param data
 * @param params
 * @returns {Promise<*>}
 */
export function getProductProviderProfiles(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { productProvider: any },
): Promise<{ productProviderProfiles: productProviderProfileT }> {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: false,
    method: 'post',
    url: 'product-providers/-/profiles',
    data,
    params,
  });
}

/**
 * Get All existing tags from all the products.
 * @param localStorage
 * @param axios
 * @param params
 * @returns {Promise<*>}
 */
export function getProductTags(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<{ tags: string[] }> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'product-providers/-/tags',
    params,
  });
}

/**
 * After Amazon OAuth, allow to select the active profile
 * @param localStorage
 * @param axios
 * @param params
 * @param data
 * @returns {Promise<*>}
 */
export function amazonCreateProductProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { productProvider: any },
): Promise<{
  success: boolean,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'product-providers',
    params,
    data,
  });
}

export function getProductProviderReports(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  productProviderId,
): Promise<{
  ReportsLinkedToRedirects: any[],
  ReportsNotLinkedToRedirects: any[],
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/${productProviderId}/reports`,
    params,
  });
}

/**
 *
 * @param localStorage
 * @param axios
 * @param params
 * @param data
 * @returns {Promise<*>}
 */
export function googleOauthCallback(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { code: string },
): Promise<{
  success: boolean,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ads-providers/google/oauth/callback',
    params,
    data,
  });
}

/**
 *
 * @param localStorage
 * @param axios
 * @param params
 * @param data
 * @returns {Promise<*>}
 */
export function tiktokOauthCallback(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { code: string },
): Promise<{
  success: boolean,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ads-providers/tiktok/oauth/callback',
    params,
    data,
  });
}

/**
 * Search product on the specified provider
 * @param localStorage
 * @param axios
 * @param params
 * @param asin
 * @param productProviderId
 * @returns {Promise<*>}
 */
export async function searchProductOnProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  asin: number,
  productProviderId: string,
): Promise<{
  customers: productT,
}> {
  if (!productProviderId) {
    throw new Error('Missing productProviderId to searchProductOnProvider');
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'put',
    url: `product-providers/${productProviderId}/product/search`,
    params,
    data: { asin },
  });
}

/**
 * GetPerformanceReportsOnProvider
 * @param localStorage
 * @param axios
 * @param params
 * @param productProviderId
 * @returns {Promise<*>}
 */
export async function getPerformanceReportsOnProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, from: string, to: string },
  productProviderId: string,
): Promise<{
  customers: productT,
}> {
  if (!productProviderId) {
    throw new Error('Missing productProviderId to searchProductOnProvider');
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/${productProviderId}/performance-reports`,
    params,
  });
}

/**
 * SearchProductByURL
 * @param localStorage
 * @param axios
 * @param params
 * @param url
 * @returns {Promise<*>}
 */
export async function searchProductByURL(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  url: string,
): Promise<{
  customers: productT,
}> {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'put',
    url: `product-providers/amazon/search`,
    params,
    data: { url },
  });
}

export async function getProductsInfo(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { marketplaceId: string, asins: string },
): Promise<{
  products: productInfoT[],
}> {
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/amazon/products-info`,
    params,
  });
}

/**
 * Submit Campaign
 * @param localStorage
 * @param axios
 * @param params
 * @param campaignId
 * @returns {Promise<*>}
 */
export async function submitCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  campaignId: string,
): Promise<{}> {
  if (!campaignId) {
    throw new Error('Missing campaignId to runCampaign');
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'put',
    url: `product-providers/-/products/-/campaigns/${campaignId}/submit`,
    params,
  });
}

/**
 * Return logs for campaign
 * @param localStorage
 * @param axios
 * @param params
 * @param campaignId
 * @returns {Promise<*>}
 */
export async function getCampaignLogs(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  campaignId: string,
): Promise<{}> {
  if (!campaignId) {
    throw new Error('Missing campaignId to getCampaignLogs');
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/${campaignId}/logs`,
    params,
  });
}

/**
 * Return logs for variation
 * @param localStorage
 * @param axios
 * @param params
 * @param variationId
 * @returns {Promise<*>}
 */
export async function getVariationLogs(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  variationId: string,
): Promise<{}> {
  if (!variationId) {
    throw new Error('Missing variationId');
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/variations/${variationId}/logs`,
    params,
  });
}

/**
 * Get Variation TimeSeries
 * @param params
 * @param variationID
 * @returns {Promise<*>}
 */
export function getVariationTimeSeries(
  params: { accountId: string, from: string, to: string },
  variationID: string,
): Promise<{
  variation: variationT,
  campaign: adsCampaignT,
  timeseriesAdsReports: any,
}> {
  // $FlowFixMe
  return apiCall({
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/-/variations/${variationID}/timeseries`,
    params,
  });
}

/**
 * Get AdGroup TimeSeries
 * @param localStorage
 * @param axios
 * @param params
 * @param adGroupID
 * @returns {Promise<*>}
 */
export function getAdGroupTimeSeries(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, from: string, to: string },
  adGroupID: string,
): Promise<{
  adGroup: adGroupT,
  campaign: adsCampaignT,
  timeseriesAdsReports: any,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/-/adgroup/${adGroupID}/timeseries`,
    params,
  });
}

/**
 * Get Empty Campaign
 * @param localStorage
 * @param axios
 * @param params
 * @param adsProviderType
 * @returns {Promise<*>}
 */
export async function getEmptyCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  adsProviderType: string,
): Promise<{ campaign: adsCampaignT }> {
  if (!adsProviderType) {
    throw new Error('Missing adsProviderType to getEmptyCampaign');
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `ads-providers/${adsProviderType}/campaigns/-/new`,
    params,
  });
}

/**
 * Get Campaign Clone
 * @param localStorage
 * @param axios
 * @param params
 * @param campaignId
 * @returns {Promise<*>}
 */
export async function getCampaignClone(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  campaignId: string,
): Promise<{ campaign: adsCampaignT }> {
  if (!campaignId) {
    throw new Error('Missing campaignId to getCampaignClone');
  }

  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/${campaignId}/clone`,
    params,
  });
}

export async function getRawCampaign(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  campaignId: string,
): Promise<{ googleRawCampaign: rawCampaignT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/${campaignId}/raw`,
    params,
  });
}

export async function getRawAdsProviderReport(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  campaignId: string,
): Promise<{ googleRawCampaign: rawCampaignT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/${campaignId}/raw-ads-report`,
    params,
  });
}

export async function getRawProductProviderReport(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  campaignId: string,
): Promise<{ googleRawCampaign: rawCampaignT }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/${campaignId}/raw-product-report`,
    params,
  });
}

export async function saveCampaignOnProductAndProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { campaign: adsCampaignT },
  productProviderId: string,
  productId: string,
): Promise<{
  newCampaign: adsCampaignT,
}> {
  // Enforce this to always be true
  const payload = data?.campaign?.automationRules?.keywordPauser
    ? produce(data, (draft) => {
        draft.campaign.automationRules.keywordPauser.reviewPausedKeywords = true;
      })
    : data;

  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `product-providers/${productProviderId}/products/${productId}/campaigns`,
    params,
    data: payload,
  });
}

export async function bulkImportCampaignsOnProductAndProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    domain: string,
    pixelsIds?: string[],
    useDeepLinks?: boolean,
    adsProviderID: string,
    performanceBooster?: boolean,
    campaigns: {
      id: string,
      name: string,
    }[],
  },
  productProviderId: string,
  productId: string,
): Promise<BulkImportCampaignResponse> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `product-providers/${productProviderId}/products/${productId}/campaigns/bulk/import`,
    params,
    data,
  });
}

export async function createCampaignLink(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    // Required params
    name: string,
    // Optional params
    platform?: null | string,
    medium?: null | string,
    pixelsIds?: null | Array<string>,
    domain?: null | string,
    performanceBooster?: boolean,
    useDeepLinks?: boolean,
    performanceBoosterAdsProviderId?: string,
  },
  productProviderId: string,
  productId: string,
): Promise<CreateCampaignLinkResponse> {
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `product-providers/${productProviderId}/products/${productId}/campaign-link`,
    params,
    data,
  });
}

export async function checkCampaignName(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: {
    name: string,
  },
  productProviderId: string,
): Promise {
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `product-providers/${productProviderId}/products/-/campaigns/check-name`,
    params,
    data,
  });
}

/**
 * Return Customers for the ad provider, for example  google customer ids
 * @param localStorage
 * @param axios
 * @param params
 * @param data
 * @returns {Promise<*>}
 */
export async function getAdsProviderProfiles(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { adsProvider: adsProviderT },
): Promise<{
  customers: googleCustomerT[],
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ads-providers/-/profiles',
    params,
    data,
  });
}

/**
 * Deletethe specified ad provider
 * @param localStorage
 * @param axios
 * @param params
 * @param adsProviderId
 * @returns {Promise<*>}
 */
export function deleteAdsProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  adsProviderId: string,
): Promise {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'delete',
    url: `/ads-providers/${adsProviderId}`,
    params,
  });
}

export function updateAdsProvider(
  params: { accountId: string },
  adsProviderId: string,
  data: { adsProvider: adsProviderT },
): Promise<adsProviderT> {
  // $FlowFixMe
  return apiCall({
    secure: true,
    method: 'patch',
    url: `/ads-providers/${adsProviderId}`,
    params,
    data,
  });
}
/**
 * GetAllAdsProviders
 * @param localStorage
 * @param axios
 * @param params
 * @returns {Promise<*>}
 */
export function getAllAdsProviders(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
): Promise<adsProviderT> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: '/ads-providers',
    params,
  });
}

/**
 * Get Product TimeSeries
 * @param localStorage
 * @param axios
 * @param params
 * @returns {Promise<*>}
 */
export function getProductTimeSeries(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, from: string, to: string },
  providerId: string,
  productASIN: string,
): Promise<{ product: adsProduct, timeseriesAdsReports: any }> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/${providerId}/products/${productASIN}/timeseries`,
    params,
  });
}

/**
 * Get Campaign TimeSeries
 * @param localStorage
 * @param axios
 * @param params
 * @param campaignId
 * @returns {Promise<*>}
 */
export function getCampaignTimeSeries(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, from: string, to: string },
  campaignId: string,
): Promise<{
  product: productT,
  campaign: adsCampaignT,
  timeseriesAdsReports: any,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/-/products/-/campaigns/${campaignId}/timeseries`,
    params,
  });
}

/**
 * get datahawk keywords ranking
 * @param localStorage
 * @param axios
 * @param params
 * @param providerId
 * @param productASIN
 * @returns {Promise<*>}
 */
export function getKeywordsRanking(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, rangeId: string },
  providerId: string,
  productASIN: string,
): Promise<{ ranking: rankReportT }> {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/${providerId}/products/${productASIN}/ranking`,
    params,
  });
}
export function searchGoogleLocation(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string, name: string },
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: 'ads-providers/google/locations?',
    params,
  });
}

/**
 * After Google OAuth, allow to select the customer
 * @param localStorage
 * @param axios
 * @param params
 * @param data
 * @returns {Promise<*>}
 */
export function createAdsProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  data: { adsProvider: adsProviderT },
): Promise<{
  success: boolean,
}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'ads-providers',
    params,
    data,
  });
}

/**
 * syncProductProvider sync product provider data
 * @param localStorage
 * @param axios
 * @param params
 * @param productProviderId
 * @returns {Promise<*>}
 */
export function syncProductProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  productProviderId: string,
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'put',
    url: `product-providers/${productProviderId}/sync-data`,
    params,
  });
}

/**
 * syncAdsProvider sync ad provider data
 * @param localStorage
 * @param axios
 * @param params
 * @param adsProviderId
 * @returns {Promise<*>}
 */
export function syncAdsProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  adsProviderId: string,
): Promise<{}> {
  // $FlowFixMe
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'put',
    url: `ads-providers/${adsProviderId}/sync-data`,
    params,
  });
}

export async function listAdsProviderExternalCampaigns(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  adsProviderId: string,
): Promise<{
  campaigns: ExternalCampaignT[],
}> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `/ads-providers/${adsProviderId}/campaigns`,
    params,
  });
}
