/**
 * Return all message selected on state
 * @param state
 * @returns {redirectT[]}
 */
export const getRedirectsSelectedForCampaigns = (state: stateT) => {
  const selected = state.campaigns.selectedRedirects;

  return selected;
};
