import React, { Component } from 'react';

import { MenuItem } from '@blueprintjs/core';
import styled from 'styled-components';

import { s } from 'lib/safe';

import NewDropDown from '../../../../common/dropDown/NewDropDown';

type internalPropsT = {
  values: elementWithIdNameT[],
  selected: elementWithIdNameT,
  title: string,
  displayLabel: boolean,
  placeholder?: string,
  createNewItemRenderer: () => Node,
  createNewItemFromQuery: (string) => undefined,
};
type internalActionsT = {
  onSelect: (string) => void,
};
type internalStateT = {};
const defaultState: internalStateT = {};

const DropDownContainer = styled.div.withConfig({
  displayName: 'DropDownContainer',
})`
  height: 100%;
  .bp3-popover-target {
    margin-top: 10px;
  }
`;
const DropDownContainerHeader = styled.div.withConfig({
  displayName: 'DropDownContainerHeader',
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;
`;

export class DropDownWithLabel extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    let { values, displayLabel, selected, title, onSelect, filterable } = this.props;

    if (!title) {
      throw new Error('Missing title for dropdown');
    }

    if (!values) {
      console.debug(`Missing values for dropdown ${title}`);
      values = [];

      if (selected) {
        console.debug(`Can't select a value for empty dropdown, removing selected`);
        selected = undefined;
      }
    }

    if (!onSelect || typeof onSelect !== 'function') {
      throw new Error(`Missing onSelect for dropdown ${title}`);
    }

    const { placeholder = title } = this.props;

    return (
      <DropDownContainer>
        {displayLabel && (
          <DropDownContainerHeader>
            <label htmlFor={title}>{title}</label>
          </DropDownContainerHeader>
        )}
        <NewDropDown
          id={title}
          filterable={filterable}
          renderItem={(elt, { modifiers, handleClick, query }) => {
            if (
              query &&
              query.toLowerCase &&
              s(elt.value).toLowerCase &&
              s(elt.value).toLowerCase().indexOf(query.toLowerCase()) === -1
            ) {
              return null;
            }

            return <MenuItem key={elt.id} text={elt.value} onClick={handleClick}></MenuItem>;
          }}
          renderTitle={() => s(selected).value || <span style={{ color: 'rgb(219, 219, 219)' }}>{placeholder}</span>}
          {...this.props}
        />
      </DropDownContainer>
    );
  }
}
