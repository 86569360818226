import React, { useEffect, useState } from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, message, notification } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import _ from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';
import styled from 'styled-components';

import { getRedirectsByIds, updateRedirect } from 'lib/api';
import axios from 'lib/axios.factory';
import { YOUTUBE_CAMPAIGNS, useFeatureFlag } from 'lib/configcat';

import type { rawCampaignT } from '../../../../../flow-typed/pixelme/adsPlateform';
import { useLoading } from '../../../../hooks/useLoading';
import { MutedText } from '../../../common/MutedText';

const Style = styled.div`
  .ant-input-affix-wrapper > input.ant-input {
    margin-top: 2px;
  }
`;

/**
 * Display campaign finalUrl
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function GenericCampaignLink(props: { campaign: rawCampaignT }) {
  const link = _.get(props, ['campaign', 'link'], '');
  const campaign = _.get(props, ['campaign']);
  const [redirect, setRedirect] = useState({});
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);
  const [form] = useForm();
  const [isYoutubeAdsEnabled] = useFeatureFlag(YOUTUBE_CAMPAIGNS);

  let slug = '';
  let host = link;
  let amazonLink = '';

  if (redirect) {
    amazonLink = redirect.url;
  }

  if (campaign && campaign.useRedirect) {
    slug = redirect.slug;
    host = redirect.domain;
  }

  async function loadRedirects() {
    if (campaign) {
      const { redirects } = await getRedirectsByIds(localStorage, axios, {
        accountId: campaign.accountId,
        redirectsIds: [campaign.redirectId],
      });

      if (redirects.length > 0) {
        setRedirect(_.get(redirects, '0'));
      }
    }
  }

  async function updateRedirectKey(values) {
    if (redirect) {
      try {
        await updateRedirect(
          localStorage,
          axios,
          {
            accountId: campaign.accountId,
          },
          {
            ...redirect,
            redirectId: redirect.id,
            slug: values.slug,
          },
        );
        window.location.reload();
      } catch (e) {
        notification.error({
          placement: 'bottomRight',
          duration: 30,
          message: 'Unable to update link',
        });
      }
    }
  }

  useEffect(() => {
    if (campaign) {
      doAction(loadRedirects);
    }
  }, [campaign]);

  return (
    <Style>
      <ExclusiveInlineLoadingContainer>
        {!isYoutubeAdsEnabled && (
          <>
            <MutedText>Use this Amazon Attribution link in your campaign.</MutedText>
            {host && (
              <Alert type="warning" message={<div>Please be sure to not add any extra parameter to it.</div>}></Alert>
            )}
          </>
        )}
        <br />

        <div style={{ display: 'flex', justifyContent: 'right' }}>
          {!slug && host && (
            <div
              style={{
                maxWidth: '400px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                lineHeight: '30px',
              }}
            >
              {host}
            </div>
          )}
          {slug && !isYoutubeAdsEnabled && (
            <>
              <Form form={form} onFinish={updateRedirectKey}>
                <>
                  <Form.Item initialValue={slug} label="Short link" name="slug">
                    <Input
                      size="small"
                      addonBefore={host}
                      suffix={<Button type="primary" icon={<SaveOutlined />} onClick={form.submit} />}
                    />
                  </Form.Item>
                </>
              </Form>
            </>
          )}
          {link && !isYoutubeAdsEnabled && (
            <CopyToClipboard
              text={isYoutubeAdsEnabled ? `https://${host}/${slug}` : link}
              onCopy={() => message.success(`Your campaign link is copied to clipboard.`)}
            >
              <div>
                <Button style={{ marginLeft: '10px' }}>Copy</Button>
              </div>
            </CopyToClipboard>
          )}
        </div>

        {isYoutubeAdsEnabled && (
          <>
            <MutedText>
              Copy and Paste the Tracking URL into the <b>Tracking Template</b> field in Google Ads. Do not make any
              modifications to this URL as it will interfere with attribution.
            </MutedText>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Input
                style={{
                  maxWidth: '400px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  lineHeight: '30px',
                }}
                disabled
                value={campaign.trackingUrl}
              />
              <CopyToClipboard
                text={campaign.trackingUrl}
                onCopy={() => message.success(`Your Tracking Link has been copied to the clipboard.`)}
              >
                <div>
                  <Button style={{ marginLeft: '10px' }}>Copy</Button>
                </div>
              </CopyToClipboard>
            </div>
            <MutedText>
              Copy and Paste the Final URL into the <b>Final URL</b> field in Google Ads. Do not make any modifications
              to this URL as it will interfere with attribution.
            </MutedText>
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Input
                style={{
                  maxWidth: '400px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  lineHeight: '30px',
                }}
                disabled
                value={campaign.link}
              />
              <CopyToClipboard
                text={campaign.link}
                onCopy={() => message.success(`Your Tracking Link has been copied to the clipboard.`)}
              >
                <div>
                  <Button style={{ marginLeft: '10px' }}>Copy</Button>
                </div>
              </CopyToClipboard>
            </div>
          </>
        )}
      </ExclusiveInlineLoadingContainer>
    </Style>
  );
}
