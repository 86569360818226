import React, { Fragment } from 'react';

import { Radio } from 'antd';
import styled from 'styled-components';

const Style = styled.div`
  width: 100%;

  .ant-radio-group {
    width: 100%;
  }

  .ant-radio-button-wrapper {
    text-align: center;
    width: 50%;
    max-width: 50%;
    max-height: 100%;
    overflow: hidden;
    border-left-width: 1.2px;
  }
`;

/**
 *
 * @param onChange
 * @param value
 * @param defaultValue
 * @param options
 * @param label
 * @param children: can b
 * @returns {JSX.Element}
 * @constructor
 */
export function RadioButtonTabs({ onChange, value, defaultValue, options, label, topStyle, childStyle }) {
  return (
    <Style>
      {label && (
        <div className="ant-col ant-form-item-label">
          <label className="ant-form-item-required ant-form-item-required-mark-optional" title="Placements">
            {label}
          </label>
        </div>
      )}
      <Radio.Group
        size="large"
        style={{ ...topStyle, display: 'flex' }}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        value={value}
        defaultValue={defaultValue}
      >
        {(options || options).map((o) => (
          <Fragment key={o.value || o.label}>
            <Radio.Button disabled={o.disabled && o.disabled()} style={o.style} value={o.value}>
              {o.label}
            </Radio.Button>
          </Fragment>
        ))}
      </Radio.Group>
      {options.length > 0 && (
        <div
          style={{
            minWidth: '100%',
            width: 'fit-content',
            backgroundColor: 'rgb(252,252,252)',
            padding: '10px',
            ...childStyle,
          }}
        >
          {(options || options)
            .filter((o) => value === o.value)
            .map((o) => (
              <Fragment key={Math.random()}>
                {typeof o.children === 'function' && o.children(value)}
                {typeof o.children !== 'function' && o.children}
              </Fragment>
            ))}
        </div>
      )}
    </Style>
  );
}
