import React, { type Node } from 'react';

import { Skeleton as AntdSkeleton, SkeletonProps } from 'antd';
import styled from 'styled-components';

const BaseSkeleton = styled(AntdSkeleton).attrs({ active: true, paragraph: false })`
  .ant-skeleton-title {
    ${(props) => (props.$height ? `height: ${props.$height}px` : '')};
    margin: 0;
  }
`;

type propsT = {
  height?: number,
};
export const Skeleton = ({ classNames, height, children, ...props }: propsT & SkeletonProps): Node => (
  <BaseSkeleton classNames={classNames} $height={height} {...props}>
    {children}
  </BaseSkeleton>
);
