import { apiSlice, TAGS } from './apiSlice';

const { AD_GROUPS } = TAGS;

// $FlowFixMe
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdGroupsByCampaignId: builder.query({
      query: ({ campaignId, from, to }) => ({
        url: `/product-providers/-/products/-/campaigns/${campaignId}/adgroups`,
        method: 'get',
        params: { from, to },
      }),
      providesTags: (result, error, { campaignId }) =>
        result?.adgroups
          ? [...result.adgroups.map(({ id }) => ({ type: AD_GROUPS, id })), { type: AD_GROUPS, id: campaignId }]
          : [{ type: AD_GROUPS, id: campaignId }],
    }),
    deleteAdGroup: builder.mutation({
      query: (id) => ({
        url: `/product-providers/-/products/-/adgroups/${id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, id) => [{ type: AD_GROUPS, id }],
    }),
    updateAdGroup: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/product-providers/-/products/-/adgroups/${id}`,
        method: 'patch',
        data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: AD_GROUPS, id }],
    }),
    pauseAdGroup: builder.mutation({
      query: ({ id }) => ({
        url: `/product-providers/-/products/-/adgroups/${id}/pause`,
        method: 'put',
      }),
      invalidatesTags: (result, error, { id, invalidate = true }) => (invalidate ? [{ type: AD_GROUPS, id }] : []),
    }),
    runAdGroup: builder.mutation({
      query: ({ id }) => ({
        url: `/product-providers/-/products/-/adgroups/${id}/run`,
        method: 'put',
      }),
      invalidatesTags: (result, error, { id, invalidate = true }) => (invalidate ? [{ type: AD_GROUPS, id }] : []),
    }),
  }),
});

export const {
  useLazyGetAdGroupsByCampaignIdQuery,
  useGetAdGroupsByCampaignIdQuery,
  useDeleteAdGroupMutation,
  useUpdateAdGroupMutation,
  usePauseAdGroupMutation,
  useRunAdGroupMutation,
} = extendedApiSlice;
