import React from 'react';

import { s } from 'lib/safe';

import { Copy } from '../../../common/icons';
import { QrCode } from '../../../common/icons/QrCode';
import { RedirectsListLineCell } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLineCell';
import { IconInsideButton } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLineElements';

export function CopyPaste({ redirect }) {
  return (
    <>
      <IconInsideButton>
        <RedirectsListLineCell forceDisplay textToCopy={redirect.shorten}>
          <Copy />
        </RedirectsListLineCell>
      </IconInsideButton>
      <IconInsideButton>
        <a
          href={`${s(s(window).env).REACT_APP_QRCODE_URL}/?data=${redirect.shorten}&size=300`}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          <QrCode />
        </a>
      </IconInsideButton>
    </>
  );
}
