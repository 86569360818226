import React from 'react';

import styled from 'styled-components';

import { FirstBullet } from 'components/common/icons/FirstBullet';
import { SecondBullet } from 'components/common/icons/SecondBullet';
import { SmallToggle } from 'components/common/icons/SmallToggle';

import CampaignMenu from './CampaignMenu';
import CampaignTreeElementNoStyle from './CampaignTreeElement';

export const CampaignTreeTitle = styled.div.withConfig({
  displayName: 'CampaignTreeTitle',
})`
  font-size: 16px;
  color: #071629;
  margin-bottom: 17px;
`;

export const CampaignTreeContainer = styled.ul.withConfig({
  displayName: 'CampaignTreeContainer',
})`
  white-space: nowrap;
  list-style: none;
  padding-inline-start: 0px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  padding: 0;
  padding-top: 10px;
`;

export const CampaignTreeElement = styled(CampaignTreeElementNoStyle).withConfig({
  displayName: 'CampaignTreeElement',
})`
  display: contents;
`;

export const CampaignContainer = styled.li.withConfig({
  displayName: 'CampaignContainer',
})`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0 0 10px;
  margin-bottom: 5px;
  align-items: center;
  :hover {
    background: rgba(252, 252, 252, 0.9);
  }
`;

export const CampaignToggle = styled(SmallToggle).withConfig({
  displayName: 'CampaignToggle',
})`
  display: inline-block;
  margin-right: 10px;
  svg{
    transform: rotate${(props) => (props.hideSubCampaigns ? '(-90deg)' : '(0deg)')}
`;

export const SubCampaignBullet = styled(SecondBullet).withConfig({
  displayName: 'SubCampaignBullet',
})`
  display: flex;
  align-items: center;
  align-self: center;
  cursor: move;
  height: 10px;
  width: 10px;
  opacity: 0;
`;

export const SubCampaignContainer = styled.ul.withConfig({
  displayName: 'SubCampaignContainer',
})`
  margin-left: 7px;
  padding: 0 0 5px 5px;
  width: calc(100% - 12px);

  :hover {
    .${SubCampaignBullet.styledComponentId} {
      opacity: 1;
    }
  }
`;

export const SubCampaignTreeElement = styled.li.withConfig({
  displayName: 'SubCampaignTreeElement',
})`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  :hover .${CampaignMenu.styledComponentId} {
    width: 60px;
    opacity: 1;
  }
`;

export const CampaignBullet = styled(FirstBullet).withConfig({
  displayName: 'CampaignBullet',
})`
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
`;

function ButtonizeNoStyle({ children, className, ...props }) {
  return (
    <span className={className} {...props}>
      {children}
    </span>
  );
}

export const Buttonize = styled(ButtonizeNoStyle).withConfig({
  displayName: 'Buttonize',
})`
  background: transparent;
  cursor: pointer;
  :hover {
    border-color: var(--pixelme-color) !important;
  }
`;

export const IconInsideButton = styled(ButtonizeNoStyle).withConfig({
  displayName: 'IconInsideButton',
})`
  background: transparent;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  display: inline-block;
  width: 23px;
  height: 23px;
  padding: 4px;
  cursor: pointer;
  :hover {
    background: rgba(46, 137, 226, 0.12);
  }
`;
export const DisabledIconInsideButton = styled(IconInsideButton).withConfig({
  displayName: 'DisabledIconInsideButton',
})`
  cursor: not-allowed;
  :hover {
    background: none;
  }
`;
