import { DatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { getRanges } from 'selectors/ranges.selector';

const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)`
  .ant-picker-separator {
    display: flex;
  }
`;

const dateRanges = getRanges();

export const DateRangePicker = ({ style, ...props }) => (
  <StyledRangePicker
    style={{ maxWidth: 240, ...style }}
    ranges={dateRanges}
    disabledDate={(current) => current.isAfter(moment())}
    {...props}
  />
);
