export const facebookCTAs = [
  {
    label: 'Shop now',
    value: 'SHOP_NOW',
  },
  {
    label: 'Get offer',
    value: 'GET_OFFER',
  },
  {
    label: 'Order now',
    value: 'ORDER_NOW',
  },
  {
    label: 'Learn More',
    value: 'LEARN_MORE',
  },
];
