import type { axiosParams } from './api';
import { apiCallNoErrorHandler } from './api';

export function getAccount(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  accountId: string,
  params: { with?: ('ad_providers' | 'owner')[] },
): Promise<{
  data: {
    id: string,
    name: string,
    adProviders?: {
      id: string,
      name: string,
      type: 'GOOGLE' | 'TIKTOK' | 'FACEBOOK' | 'GENERIC',
    }[],
    owner?: {
      id: string,
      name: string,
      email: string,
    },
  },
}> {
  const par = {};

  if (params.with) {
    par.with = params.with.join(',');
  }

  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `admin-tools/accounts/${accountId}`,
    params: par,
  });
}

export function transferProduct(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  data: {
    sourceProductId: string,
    targetAccountId: string,
    adsProviderId: string,
  },
): Promise<> {
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: 'admin-tools/products/transfer',
    data,
  });
}
