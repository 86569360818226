import * as _ from 'lodash';

import { s } from 'lib/safe';

export const getPixelsIds = (pixels: Array<pixelT>) => (pixels || []).map((pixel) => pixel.id);

export const getListItems = (pixels: Array<pixelT>) =>
  (pixels || []).map((pixel) => ({ id: pixel.id, value: pixel.value, providerKey: pixel.providerKey }));

export const getListItemsFromString = (allPixelsAsObject, pixelsId: Array<pixelT>) =>
  (pixelsId || []).map((pixelId) => ({
    id: pixelId,
    value: s(_.find(allPixelsAsObject, (pixelAsObject) => pixelAsObject.id === pixelId)).value,
    providerKey: s(_.find(allPixelsAsObject, (pixelAsObject) => pixelAsObject.id === pixelId)).providerKey,
  }));

export const getListItem = (pixel: pixelT) => {
  if (!pixel) {
    return pixel;
  }

  return { id: pixel.id, value: pixel.value };
};
