import { validateUrl } from './util';

export function validateCookieConsent({
  company,
  website,
  terms,
}: {
  company: string,
  website: string,
  terms: string,
}): boolean {
  return company.length > 1 && validateUrl(website) && validateUrl(terms);
}
