import React, { useState, useEffect } from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { GlobalPlaceholder } from 'components/common/Placeholders';
import SignLayout from 'components/layout/Sign/SignLayout';
import { trackedEvents } from 'config/trackedEvents.config';
import { track } from 'lib/analytics';
import { validateEmail } from 'lib/api';
import axios from 'lib/axios.factory';

import { sd } from '../../../lib/safe';

export default function Validating({
  localStorage,
  email,
  code,
  onSignOut,
}: {
  localStorage: any,
  email: string,
  code: string,
  onSignOut: () => void,
}) {
  const [error, setError] = useState('');
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    validateEmail(localStorage, axios, { email, code })
      .then((response) => {
        track(trackedEvents.emailValidated);
        setValidated(true);
        // Set timeout
        setTimeout(() => {
          window.location.replace(`/${localStorage.getItem('selected-account')}/welcome-after-signup`);
        }, 5000);
      })
      .catch((error) => {
        setError('Bad validation code');
      });
  }, []);

  if (validated) {
    return (
      <SignLayout
        footer={
          <Link className="highlight" to="/sign-out">
            Back to Sign in page
          </Link>
        }
        image={
          <div style={{ textAlign: 'center', width: '100%' }}>
            <CheckCircleOutlined style={{ fontSize: '250px', color: 'green' }} />
          </div>
        }
        step={6}
      >
        <p></p>
        <h2>Email validation successful.</h2>
        <p>Redirecting to PixelMe app...</p>
      </SignLayout>
    );
  }

  if (error.length > 0) {
    return (
      <SignLayout
        footer={
          <Link className="highlight" to="/sign-out">
            Back to Sign in page
          </Link>
        }
        image={
          <div style={{ textAlign: 'center', width: '100%' }}>
            <CloseCircleOutlined style={{ fontSize: '250px', color: '#ff4d4f' }} />
          </div>
        }
        step={6}
      >
        <p></p>
        <p>The submitted validation code is not valid.</p>
      </SignLayout>
    );
  }

  return <GlobalPlaceholder loadingText="Validating your email..." />;
}
