import { WorkerAction } from '../framework/workerAction';

export default {
  update: new WorkerAction({ name: 'api.account.update' }, {}),
  tags: {
    remove: new WorkerAction({ name: 'api.account.tags.remove' }, {}),
  },
  utms: {
    sources: {
      remove: new WorkerAction({ name: 'api.account.utms.sources.remove' }, {}),
    },
    mediums: {
      remove: new WorkerAction({ name: 'api.account.utms.mediums.remove' }, {}),
    },
    campaigns: {
      remove: new WorkerAction({ name: 'api.account.utms.campaigns.remove' }, {}),
    },
  },
  platforms: {
    remove: new WorkerAction({ name: 'api.account.platforms.remove' }, {}),
  },
  settings: {
    analytics: {
      reset: new WorkerAction({ name: 'api.account.settings.analytics.reset' }, {}),
    },
  },
};
