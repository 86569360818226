import { Actions } from 'actions';
import {
  addCampaign,
  addSubCampaign,
  deleteCampaign,
  deleteSubCampaign,
  editCampaign,
  editSubCampaign,
  getCampaigns,
  getCampaignsStats,
  getCampaignStats,
  updateRedirectsCampaign,
} from 'lib/api';
import axios from 'lib/axios.factory';
import 'lib/array';
import { ALL_REDIRECTS, ALL_REDIRECTS_FOR_CAMPAIGN, UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN } from 'consts/consts';
import { fillSubCampaignsWithAllAndNone } from 'lib/campaigns';
import { selectCurrentAccountId } from 'stores/accounts';

export default (store: any) => (next: any) => async (action: any) => {
  next(action);
  const accountFilter = { accountId: selectCurrentAccountId(store.getState()) };

  switch (action.type) {
    case Actions.api.audienceBuilder.campaigns.load.request().type:
      getCampaigns(localStorage, axios, accountFilter).then((campaignsApiResult) => {
        const campaignsToLoadStats = fillSubCampaignsWithAllAndNone(campaignsApiResult.campaigns);

        campaignsApiResult.selectedCampaignId = store.getState().campaigns.selectedCampaignId;
        store.dispatch(action.workerAction.success(campaignsApiResult));
        getCampaignsStats(
          localStorage,
          axios,
          {
            ...accountFilter,
            fields: [],
          },
          {
            campaigns: campaignsToLoadStats,
          },
        ).then((campaignsStatsResult) => {
          campaignsStatsResult.stats &&
            campaignsStatsResult.stats.forEach((campaignStatsResult) => {
              store.dispatch(
                Actions.api.audienceBuilder.campaigns.loadStats.success({
                  stats: [{ ...campaignStatsResult }],
                }),
              );
            });
        });
      });
      break;

    case Actions.api.audienceBuilder.campaigns.loadStats.request().type:
      const { campaignId, subCampaignId, fields } = action.payload;

      getCampaignStats(localStorage, axios, {
        ...accountFilter,
        campaignId,
        subCampaignId,
        fields,
      }).then((statsResult) => {
        store.dispatch(
          action.workerAction.success({
            stats: {
              ...statsResult.stats,
              campaignId,
              subCampaignId,
            },
          }),
        );
      });
      break;

    case Actions.api.audienceBuilder.campaigns.loadStats.success().type: {
      const { stats } = action.payload;

      if (!Array.isArray(stats)) {
        break;
      }

      // for (const stat of stats) {
      //    if (!stat.nbRedirects && stat.subCampaignId === "ALL") {
      //     store.dispatch(
      //       Actions.front.audienceBuilder.campaigns.toggleTree.request({
      //         campaignId: stat.campaignId,
      //         hide: true
      //       })
      //     );
      //    }
      // }
      break;
    }
    case Actions.api.audienceBuilder.campaigns.delete.request().type: {
      const { campaignOrSub, parent } = action.payload;

      if (parent && parent.id) {
        await deleteSubCampaign(localStorage, axios, accountFilter, {
          subCampaignId: campaignOrSub.id,
          parentId: parent.id,
        });
        store.dispatch(
          Actions.api.audienceBuilder.campaigns.delete.success({
            campaignId: parent.id,
            subCampaignId: campaignOrSub.id,
          }),
        );
      } else {
        await deleteCampaign(localStorage, axios, accountFilter, {
          campaignId: campaignOrSub.id,
        });
        store.dispatch(
          Actions.api.audienceBuilder.campaigns.delete.success({
            campaignId: campaignOrSub.id,
          }),
        );
      }

      break;
    }
    case Actions.api.audienceBuilder.campaigns.edit.request().type: {
      const { campaignOrSub, parent } = action.payload;

      if (parent && parent.id) {
        await editSubCampaign(localStorage, axios, accountFilter, {
          subCampaign: campaignOrSub,
          parentId: parent.id,
        });
      } else {
        await editCampaign(localStorage, axios, accountFilter, {
          campaign: campaignOrSub,
        });
      }

      store.dispatch(
        Actions.api.audienceBuilder.campaigns.edit.success({
          campaign: campaignOrSub,
        }),
      );
      break;
    }
    case Actions.api.audienceBuilder.campaigns.add.request().type:
      {
        const { campaignOrSub, parent } = action.payload;

        let campaign;
        let subCampaign;

        if (parent && parent.id && parent.id !== ALL_REDIRECTS) {
          const res = await addSubCampaign(localStorage, axios, accountFilter, {
            subCampaign: campaignOrSub,
            parentId: parent.id,
          });

          campaign = res.campaign;
          subCampaign = res.subCampaign;
        } else {
          const res = await addCampaign(localStorage, axios, accountFilter, {
            campaign: campaignOrSub,
          });

          campaign = res.campaign;
        }

        store.dispatch(
          Actions.api.audienceBuilder.campaigns.add.success({
            campaign,
            subCampaign,
          }),
        );
      }
      break;

    case Actions.api.audienceBuilder.campaigns.add.success().type:
    case Actions.api.audienceBuilder.campaigns.edit.success().type:
      store.dispatch(Actions.api.audienceBuilder.campaigns.load.request());
      break;

    case Actions.api.audienceBuilder.campaigns.delete.success().type: {
      const { campaignId } = action.payload;

      store.dispatch(Actions.api.audienceBuilder.campaigns.load.request());
      // Reload stats for removed campaign
      store.dispatch(
        Actions.api.audienceBuilder.campaigns.loadStats.request({
          campaignId: ALL_REDIRECTS,
          subCampaignId: ALL_REDIRECTS_FOR_CAMPAIGN,
        }),
      );
      store.dispatch(
        Actions.api.audienceBuilder.campaigns.loadStats.request({
          campaignId,
          subCampaignId: UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN,
        }),
      );

      if (store.getState().campaigns.selectedSubCampaignId === UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN) {
        store.dispatch(Actions.api.audienceBuilder.redirect.load.request());
      }

      break;
    }

    case Actions.api.audienceBuilder.campaigns.reOrder.request().type:
      {
        const { parent } = action.payload;

        if (!parent) {
          throw new Error('Parent campaign is missing, unable to reOrder children');
        }

        for (const i in parent.subCampaigns) {
          const subCampaign = parent.subCampaigns[i];

          if (
            subCampaign &&
            subCampaign.name &&
            subCampaign.id &&
            subCampaign.id !== UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN &&
            subCampaign.id !== ALL_REDIRECTS_FOR_CAMPAIGN
          ) {
            subCampaign.position = parseInt(i);
            await editSubCampaign(localStorage, axios, accountFilter, {
              subCampaign,
              parentId: parent.id,
            });
          }
        }
        store.dispatch(action.workerAction.success());
      }
      break;

    case Actions.api.audienceBuilder.campaigns.addRedirects.request().type:
      {
        const { redirects, campaignId, subCampaignId } = action.payload;

        updateRedirectsCampaign(localStorage, axios, accountFilter, {
          redirectsIds: redirects.map((redirect) => redirect.id),
          campaignId,
          subCampaignId,
        }).then(() =>
          store.dispatch(
            Actions.api.audienceBuilder.campaigns.addRedirects.success({
              campaignId,
              subCampaignId,
              redirects,
            }),
          ),
        );
      }
      break;

    case Actions.api.audienceBuilder.campaigns.addRedirects.success().type: {
      store.dispatch(Actions.api.audienceBuilder.redirect.load.request());

      store.dispatch(Actions.api.audienceBuilder.campaigns.reloadAllStats.request());
      break;
    }

    case Actions.api.audienceBuilder.redirect.edit.success().type:
      const { redirect } = action.payload;

      if (!redirect) {
        throw new Error('Unable to update current redirect on front state because redirect is undefined');
      }

      store.dispatch(Actions.api.audienceBuilder.redirect.load.request());

      store.dispatch(Actions.api.audienceBuilder.campaigns.reloadAllStats.request());
      break;

    case Actions.api.audienceBuilder.redirect.delete.success().type: {
      const { redirectsIds } = action.payload;

      if (!redirectsIds) {
        throw new Error('Unable to update current redirect on front state because redirect is undefined');
      }

      store.dispatch(Actions.api.audienceBuilder.redirect.load.request());

      store.dispatch(Actions.api.audienceBuilder.campaigns.reloadAllStats.request());
      break;
    }

    case Actions.api.audienceBuilder.campaigns.reloadAllStats.request().type: {
      const { campaigns } = store.getState().campaigns;

      const campaignsToLoadStats = fillSubCampaignsWithAllAndNone(campaigns);

      getCampaignsStats(
        localStorage,
        axios,
        {
          ...accountFilter,
          fields: [],
        },
        {
          campaigns: campaignsToLoadStats,
        },
      ).then((statsResult) => {
        const campaignsToToggleOff = [];

        for (const stat of statsResult.stats) {
          if (
            !stat.nbRedirects &&
            campaignsToToggleOff.indexOf(stat.campaignId) === -1 &&
            stat.subCampaignId === 'ALL'
          ) {
            campaignsToToggleOff.push(stat.campaignId);
            store.dispatch(
              Actions.front.audienceBuilder.campaigns.toggleTree.request({
                campaignId: stat.campaignId,
                hide: true,
              }),
            );
          }
        }
        store.dispatch(
          action.workerAction.success({
            stats: {
              ...statsResult.stats,
            },
          }),
        );
      });
      break;
    }

    default:
      break;
  }
};
