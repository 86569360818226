import React from 'react';

import { useHistory } from 'react-router-dom';

import { ADS_PROVIDERS } from '../constants';
import { FacebookLoginButton } from '../Facebook/FacebookLoginButton';
import { GoogleLoginButton } from '../Google/GoogleLoginButton';

type AdsProviderReloginButtonProps = {
  account: accountT,
  provider: adsProviderT,
  block: boolean,
};
export function AdsProviderReloginButton({ account, provider, block }: AdsProviderReloginButtonProps) {
  const history = useHistory();

  switch (provider.providerType) {
    case ADS_PROVIDERS.FACEBOOK:
      return (
        <FacebookLoginButton
          account={account}
          history={history}
          onConnected={() => {}}
          adsProvider={provider}
          block={block}
        />
      );
    case ADS_PROVIDERS.GOOGLE:
      return <GoogleLoginButton account={account} adsProvider={provider} />;
    default:
      return null;
  }
}
