import React from 'react';

import IconContainer from './IconContainer';

export function SmallToggle(props) {
  return (
    <IconContainer {...props}>
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5" stroke="#393939" strokeLinecap="round" />
        <path d="M5 5L9 1" stroke="#393939" strokeLinecap="round" />
      </svg>
    </IconContainer>
  );
}
