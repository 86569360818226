import React, { useEffect, useState } from 'react';

import { Alert, Button, Modal } from 'antd';
import { useIntl } from 'react-intl';

import { getAdsProviders } from 'lib/adsProvider/google';
import { MIGRATE_GOOGLE_TOKEN, useFeatureFlag } from 'lib/configcat';
import { strings } from 'locales/strings';

import { AdsProviderAccounts } from './AdsProviderAccounts';
import type { accountT } from '../../../../../flow-typed/pixelme/types';

const getMigrationRequired = (p) => p.migrateGoogleToken && !p.loginNeeded;
const getLoginRequired = (p) => p.loginNeeded;

let migrationSuppressed = false;
let loginSuppressed = false;

type AdsProviderReLoginModalProps = {
  account: accountT,
};
/**
 * Open modal to prompt Google reconnect when `adsProvider.migrateGoogleToken: true`.
 * Once closed or if never open, open modal to prompt reconnect when `adsProvider.loginNeeded: true`.
 */
export const AdsProviderReLoginModal = ({ account }: AdsProviderReLoginModalProps) => {
  const adsProviders = getAdsProviders(account);
  const [migrationOpen, setMigrationOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [migrateGoogleTokenEnabled, isFlagLoading] = useFeatureFlag(MIGRATE_GOOGLE_TOKEN);
  const { messages } = useIntl();

  const handleMigrationClose = () => {
    migrationSuppressed = true;
    setMigrationOpen(false);
  };
  const handleLoginClose = () => {
    loginSuppressed = true;
    setLoginOpen(false);
  };

  useEffect(() => {
    if (!isFlagLoading) {
      if (migrateGoogleTokenEnabled && !migrationSuppressed && adsProviders.some(getMigrationRequired)) {
        setMigrationOpen(true);
      }

      if (!loginSuppressed && adsProviders.some(getLoginRequired)) {
        setLoginOpen(true);
      }
    }
  }, [isFlagLoading]);

  if (migrationOpen) {
    return (
      <Modal
        visible
        width="680px"
        title={messages.adsProviderReLoginModal.migration.title}
        onCancel={handleMigrationClose}
        footer={<Button onClick={handleMigrationClose}>{messages.global.ok}</Button>}
      >
        <Alert type="info" message={messages.adsProviderReLoginModal.migration.description} />
        <br />
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <AdsProviderAccounts
            account={account}
            adsProviders={adsProviders.filter(getMigrationRequired)}
            hideAddNew
            hideDisconnect
          />
        </div>
      </Modal>
    );
  }

  if (loginOpen) {
    return (
      <Modal
        visible
        width="680px"
        title={messages.adsProviderReLoginModal.login.title}
        onCancel={handleLoginClose}
        footer={<Button onClick={handleLoginClose}>{messages.global.ok}</Button>}
      >
        <Alert
          type="error"
          message={messages.adsProviderReLoginModal.login.errorTitle}
          description={messages.adsProviderReLoginModal.login.errorDescription}
        />
        <br />
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <AdsProviderAccounts account={account} adsProviders={adsProviders.filter(getLoginRequired)} />
        </div>
      </Modal>
    );
  }

  return null;
};
