import React, { useEffect } from 'react';

import { Form, Select } from 'antd';
import styled from 'styled-components';

import { s } from 'lib/safe';

const Style = styled.div`
  select {
    height: 20px;
  }
`;

export function ProductProviderSelectorContent({ onChange, productProviders, value, ...otherProps }) {
  useEffect(() => {
    if (productProviders.length === 1) {
      onChange(productProviders[0]);
    }
  }, [productProviders]);

  return (
    <Select
      style={{ width: '100%' }}
      value={s(value).id}
      disabled={productProviders.length < 2}
      onChange={(id) => {
        const selected = productProviders.find((p) => p.id === id);

        onChange(selected);
      }}
      {...otherProps}
    >
      {productProviders.map((provider) => (
        <Select.Option value={provider.id} key={provider.id}>
          <div
            style={{
              display: 'flex',
              gap: '1em',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {`${provider.externalAccountDescriptiveName} (${provider.externalAccountId})`}
          </div>
        </Select.Option>
      ))}
    </Select>
  );
}

export function ProductProviderSelector({ account }: { account: accountT }) {
  return (
    <Style>
      <Form.Item
        required
        name="productProvider"
        rules={[{ required: true }]}
        label="Product Platform"
        style={{ marginBottom: '5px' }}
      >
        <ProductProviderSelectorContent productProviders={account.productProviders} />
      </Form.Item>
    </Style>
  );
}
