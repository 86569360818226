import React from 'react';

import styled from 'styled-components';

import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { handleChange } from './composerEditUtils';
import { WorldWideWeb } from '../../../../../common/icons/WorldWideWeb';
import { ComposerTitle } from '../ComposerTitle';
import { InputWithLabel } from '../InputWithLabel';

export const ComposerEditTitle = styled((props) => {
  const { redirect, className } = props;

  return (
    <div className={className}>
      <InputWithLabel
        name="title"
        displayLabel
        isBig
        onChange={(e) => handleChange([e.target], props)}
        title={getIntlStrings('redirect.title.input.title')}
        placeholder={getIntlStrings('redirect.title.input.placeholder')}
        value={redirect.title}
      >
        <ComposerTitle renderIcon={() => <WorldWideWeb />} style={{ gridArea: 'title-title' }} />
      </InputWithLabel>
    </div>
  );
}).withConfig({ displayName: 'ComposerEditTitle' })``;
