import React from 'react';

import { Keys, MenuItem } from '@blueprintjs/core';
import _ from 'lodash';

import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';
import { getListItem, getListItems } from 'selectors/campaigns.selector';
import { store } from 'stores';

import { Actions } from '../../../../../../actions';
import { DropDownWithLabel } from '../DropDownWithLabel';
import { handleChange } from '../inputs/composerEditUtils';

export function ComposerSubCampaignSelect({ onChange, redirect, selectedSubCampaign, selectedCampaign, addCampaign }) {
  const selectedSubCampaignAsListItem = getListItem(selectedSubCampaign);

  return (
    <DropDownWithLabel
      disabled={!selectedCampaign}
      values={selectedCampaign ? getListItems(selectedCampaign.subCampaigns) : []}
      selected={selectedSubCampaignAsListItem}
      noResults={
        <MenuItem
          icon="add"
          className="bp3-add-option"
          text={`Add New Sub Folder"`}
          onClick={() => {
            store.dispatch(
              Actions.front.audienceBuilder.campaigns.add.show.request({
                parent: selectedCampaign,
              }),
            );
          }}
        />
      }
      createNewItemRenderer={(query: string, active: boolean, handleClick) => (
        <MenuItem
          icon="add"
          className="bp3-add-option"
          text={`Add "${query}"`}
          active={active}
          onClick={() => {
            const newCampaign = { name: query };

            addCampaign({
              campaignOrSub: newCampaign,
              parent: selectedCampaign,
            });
          }}
        />
      )}
      createNewItemFromQuery={(c) => ({ value: _.capitalize(c) })}
      title={getIntlStrings('campaigns.subCampaigns.select.title')}
      placeholder={getIntlStrings('campaigns.subCampaigns.select.placeholder')}
      onKeyUp={(event) => {
        const newCampaign = { name: event.target.value };

        if (event.keyCode === Keys.ENTER) {
          addCampaign({
            campaignOrSub: newCampaign,
            parent: selectedCampaign,
          });
        }
      }}
      onSelect={(selectedSubCampaign) => {
        handleChange([{ name: 'subCampaignId', value: selectedSubCampaign.id }], { onChange, redirect });
      }}
      displayLabel
    />
  );
}
