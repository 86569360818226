const ssl = 'ssl';

export function addDomain(localStorage, domain) {
  let sslList = [];

  if (localStorage.getItem(ssl)) {
    sslList = JSON.parse(localStorage.getItem(ssl));
    sslList.push(domain);
    localStorage.setItem('ssl', JSON.stringify(sslList));
  } else {
    sslList.push(domain);
    localStorage.setItem('ssl', JSON.stringify(sslList));
  }
}

export function checkDomain(localStorage, domain) {
  if (localStorage.getItem(ssl)) {
    return JSON.parse(localStorage.getItem(ssl)).includes(domain);
  }

  return false;
}

export function deleteDomain(localStorage, domain) {
  let sslList = [];

  sslList = JSON.parse(localStorage.getItem(ssl));
  sslList = sslList.filter((e) => e !== domain);
  localStorage.setItem('ssl', JSON.stringify(sslList));
}
