import styled from 'styled-components';

import { SubMenu } from './SubMenu';

export const BigSubMenu = styled(SubMenu).withConfig({
  displayName: 'BigSubMenu',
})`
  background: transparent !important;
  height: 100% !important;
  display: inline-block;
  ul,
  li {
    padding-top: 7px;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
  }
`;
