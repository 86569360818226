import React, { useState } from 'react';

import { Button } from 'antd';

import { AdsProviderLogo } from './AdsProviderLogo';
import { FacebookConversionModal } from './FacebookConversionModal';
import { ADS_PROVIDERS } from '../constants';

export function FacebookSettings(props: { account: accountT, provider: adsProviderT }) {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <FacebookConversionModal
        account={props.account}
        visible={visible}
        onFinish={() => setVisible(false)}
        provider={props.provider}
      />
      <Button
        onClick={() => setVisible(true)}
        type="primary"
        ghost
        style={{ padding: '1.5rem 2.6rem', display: 'flex', gap: '10px', alignItems: 'center' }}
        icon={<AdsProviderLogo providerType={ADS_PROVIDERS.FACEBOOK} style={{ height: 22 }} />}
      >
        Facebook Settings
      </Button>
    </div>
  );
}
