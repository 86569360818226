import React from 'react';

import IconContainer from './IconContainer';

export function Folder(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.2052 8.55H17.1V6.56513C17.1 6.08823 16.7118 5.7 16.2349 5.7H15.2V4.93557L15.0433 4.77882C15.0429 4.77882 15.0429 4.7785 15.0429 4.77818L12.7325 2.46778L10.2644 0H2.53333V3.48333H0.865133C0.388233 3.48333 0 3.87157 0 4.34847V18.1827H0.0019C0.000316667 18.361 0.0557333 18.5354 0.1672 18.6814C0.322367 18.8841 0.557333 19 0.81225 19H14.9859C15.352 19 15.6744 18.7533 15.7618 18.4259L19 9.54718V9.49113C19 8.94583 18.6656 8.55 18.2052 8.55ZM16.2349 6.33333C16.3625 6.33333 16.4667 6.4372 16.4667 6.56513V8.55H15.2V6.33333H16.2349ZM10.45 1.08142L14.1189 4.75H10.45V1.08142ZM3.16667 0.633333H9.81667V5.38333H14.5667V5.7V8.55H4.03148C3.9862 8.55 3.94155 8.5538 3.89785 8.56108C3.58942 8.61238 3.33228 8.83722 3.25565 9.12412L3.16667 9.36858V3.48333V0.633333ZM0.633333 4.34847C0.633333 4.22053 0.737517 4.11667 0.865133 4.11667H2.53333V11.0944L0.633333 16.2764V4.34847ZM15.1585 18.2346C15.1376 18.3122 15.0667 18.3667 14.9859 18.3667H0.81225C0.7353 18.3667 0.690333 18.3226 0.670383 18.2964C0.650433 18.2704 0.619717 18.2153 0.631433 18.1672L2.53333 12.951V12.9526L3.85067 9.34135L3.8589 9.31538C3.8798 9.2378 3.95105 9.18333 4.03148 9.18333H14.5667H15.2H17.1H18.2052C18.3255 9.18333 18.3562 9.35623 18.3641 9.44268L15.1585 18.2346Z"
          fill="#2E89E2"
        />
        <path
          d="M5.06667 5.38333H8.23333C8.40813 5.38333 8.55 5.24178 8.55 5.06666C8.55 4.89155 8.40813 4.75 8.23333 4.75H5.06667C4.89187 4.75 4.75 4.89155 4.75 5.06666C4.75 5.24178 4.89187 5.38333 5.06667 5.38333Z"
          fill="#2E89E2"
        />
        <path
          d="M5.06667 3.16654H8.23333C8.40813 3.16654 8.55 3.02499 8.55 2.84987C8.55 2.67475 8.40813 2.5332 8.23333 2.5332H5.06667C4.89187 2.5332 4.75 2.67475 4.75 2.84987C4.75 3.02499 4.89187 3.16654 5.06667 3.16654Z"
          fill="#2E89E2"
        />
        <path
          d="M5.06667 7.60013H12.6667C12.8415 7.60013 12.9833 7.45858 12.9833 7.28346C12.9833 7.10835 12.8415 6.9668 12.6667 6.9668H5.06667C4.89187 6.9668 4.75 7.10835 4.75 7.28346C4.75 7.45858 4.89187 7.60013 5.06667 7.60013Z"
          fill="#2E89E2"
        />
      </svg>
    </IconContainer>
  );
}
