import React from 'react';

export function Twitter() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none" viewBox="0 0 16 16">
      <g>
        <path
          fill="#55ACEE"
          fillRule="evenodd"
          d="M0 8a8 8 0 1116 0A8 8 0 110 8zm12-2.672c-.296.14-.611.239-.944.279.342-.22.601-.569.722-.977a3.21 3.21 0 01-1.037.428A1.624 1.624 0 009.537 4.5c-.907 0-1.639.788-1.639 1.765 0 .14.01.28.037.409-1.36-.08-2.565-.748-3.38-1.815-.138.26-.222.528-.222.858 0 .608.287 1.146.732 1.465a1.58 1.58 0 01-.74-.219v.02c0 .857.564 1.575 1.314 1.735a1.585 1.585 0 01-.74.03c.203.698.814 1.216 1.527 1.226-.556.479-1.269.838-2.037.838-.13 0-.26-.01-.389-.03.722.499 1.593.718 2.519.718 3.018 0 4.666-2.692 4.666-5.026v-.229c.315-.25.593-.558.815-.917z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
