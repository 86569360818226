import React, { Fragment, useContext, useEffect, useState } from 'react';

import { InfoCircleOutlined, LoadingOutlined, PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons';
import { Card, message, Table, Tooltip } from 'antd';
import _, { get } from 'lodash';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { MutedText } from 'components/common/MutedText';
import { SmallPlaceholder } from 'components/common/Placeholders';
import { TooltipTableHeader } from 'components/common/TooltipTableHeader';
import { DATE_FORMAT } from 'consts/consts';
import { DashboardFilterContext } from 'context/DashboardFilterProvider';
import { getKeywordsRanking } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';
import { GOOGLE_RMF, useFeatureFlag } from 'lib/configcat';
import { sn } from 'lib/safe';
import { useLocale } from 'locales/LocalizationWrapper';
import { selectProductMetrics } from 'stores/productMetricsSlice';
import { usePauseVariationMutation, useRunVariationMutation } from 'stores/variationsSlice';
import { getCurrency } from 'utils/currency';

import { ExportVariation } from './ExportVariation';
import { KeywordRankButton } from './KeywordRankButton';
import { KeywordRankComparison, MOVING_AVG_LEN } from './KeywordRankComparison';
import {
  ACOSValue,
  CurrencyValue,
  NumberVal,
  ROASValue,
} from '../../../AudienceBuilder/Campaigns/campaigns/RedirectsListLine';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';
import { KeywordFloatingCellAction } from '../../common/KeywordFloatingCellAction';
import { randomESTBids } from '../../GoogleRMF/constantsRMF';
import { BrbValue } from '../BrbValue';

const Style = styled.div`
  overflow: auto;
  max-width: 100%;

  .ant-table-row:hover .floating-row-action-container {
    width: auto !important;
  }

  .ant-table-pagination.ant-pagination {
    justify-content: left;
    margin-left: 1em;
  }

  .ant-table-thead > tr > th {
    text-align: center;
  }

  .ant-table-column-title {
    font-size: 13px;
  }
`;

export function VariationsTable(props: {
  product: productT,
  variations: variationT[],
  account: accountT,
  variationType: string,
  onProductSave: (product: productT) => void,
  setSelectedRowKeys: any,
  productProviderId: string,
}) {
  const { ctx } = useContext(DashboardFilterContext);
  const { currencyCode, currencySymbol } = getCurrency(props.account);
  const [loading] = useState(false);
  const intl = useIntl();

  const [rankings, setRankings] = useState({});
  const [showKeywordRMFOptions] = useFeatureFlag(GOOGLE_RMF);

  const [pauseVariation, { isLoading: isPausing, originalArgs: pauseArgs }] = usePauseVariationMutation();
  const [runVariation, { isLoading: isRunning, originalArgs: runArgs }] = useRunVariationMutation();
  const productMetrics = useSelector(selectProductMetrics);
  const { getIntlStrings } = useLocale();

  useEffect(() => {
    if (props.product.id && props.product.providerId) {
      const fetchData = async () => {
        const data = await getKeywordsRanking(
          localStorage,
          axios,
          {
            accountId: props.account.id,
            rangeId: 'LAST_30D',
          },
          props.product.providerId,
          props.product.externalId,
        );

        const rankings = {};

        (data.ranking || []).forEach((r) => {
          rankings[r.keyword] = r.ranks;
        });
        setRankings(rankings);
      };

      fetchData();
    }
  }, [props.product.id, props.product.providerId]);

  let columns = [
    {
      title: '',
      dataIndex: 'providerType',
      key: 'providerType',
      width: 60,
      render: (value, row) => (
        <div className="small-provider">
          <AdsProviderLogo providerType={value} />
        </div>
      ),
    },
  ];

  switch (props.variationType) {
    case 'KEYWORD': {
      columns.push(
        {
          title: getIntlStrings('locales.columns.keyword'),
          dataIndex: 'keyword',
          key: 'keyword',
          className: 'keyword',
          width: 200,
          sorter: (a, b) => a.keyword.localeCompare(b.keyword),
          render: (value, row) => (
            <KeywordFloatingCellAction keyword={value} marketplaceId={props.product.marketplaceId} />
          ),
        },
        {
          title: getIntlStrings('locales.columns.matchType'),
          dataIndex: 'matchType',
          key: 'matchType',
          width: 100,
          render: (a) => (a === 'PHRASE' ? 'Phrase Match' : a === 'BROAD' ? 'Broad Match' : 'Exact Match'),
          sorter: (a, b) => a.matchType.localeCompare(b.matchType),
        },
      );
      break;
    }
    case 'CREATIVE': {
      columns.push(
        {
          title: 'Creative',
          dataIndex: 'keyword',
          key: 'keyword',
          width: 200,
          sorter: (a, b) => a.keyword.localeCompare(b.keyword),
        },
        {
          title: 'Ad Group',
          dataIndex: 'matchType',
          key: 'matchType',
          width: 100,
          sorter: (a, b) => a.matchType.localeCompare(b.matchType),
        },
      );
      break;
    }
    default:
  }
  columns.push(
    {
      title: getIntlStrings('locales.columns.status'),
      dataIndex: 'status',
      key: 'status',
      width: 140,
      sorter: (a, b) => a.status - b.status,
      render: (value, variation) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {value === -5 && <span style={{ color: '#FFEA00' }}>Waiting (import)</span>}
          {value === 1 && <span style={{ color: '#F8A51B' }}>Paused</span>}
          {value === 2 && <span style={{ color: 'red' }}>Deleted</span>}
          {value === 3 && <span style={{ color: 'red' }}>Rejected</span>}
          {(value === 4 || value === -10) && <span style={{ color: '#0F993E' }}>Active</span>}
          {value === 5 && <span style={{ color: '#0F993E' }}>Finished</span>}
          {variation.status === 4 /* ACTIVE*/ &&
            (isPausing && pauseArgs.id === variation.id ? (
              <LoadingOutlined style={{ fontSize: '16px' }} />
            ) : (
              <PauseCircleTwoTone
                style={{ fontSize: '16px' }}
                twoToneColor="#F8A51B"
                title="Pause Variation"
                onClick={async () => {
                  await pauseVariation({ id: variation.id }).unwrap();

                  message.success('Paused');
                }}
              />
            ))}
          {variation.status === 1 /* PAUSED*/ &&
            (isRunning && runArgs.id === variation.id ? (
              <LoadingOutlined style={{ fontSize: '16px' }} />
            ) : (
              <PlayCircleTwoTone
                style={{ fontSize: '16px' }}
                twoToneColor="#52c41a"
                title="Launch Variation"
                onClick={async () => {
                  await runVariation({ id: variation.id }).unwrap();

                  message.success('Enabled');
                }}
              />
            ))}
        </div>
      ),
    },

    {
      title: getIntlStrings('locales.columns.amazonSearchVolume'),
      dataIndex: ['volumes', 'amazonVolume'],
      key: 'amazonSearchVolume',
      hidden: !ctx.showRank,
      width: 120,
      className: 'amazon',
      render: (v) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => sn(a, 'volumes.amazonVolume', 0) - sn(b, 'volumes.amazonVolume', 0),
    },
    {
      title: getIntlStrings('locales.columns.amazonCurrentRank'),
      dataIndex: ['volumes', 'rank'],
      key: 'amazonCurrentRank',
      hidden: !ctx.showRank,
      width: 120,
      className: 'amazon',
      render: (v) => {
        if (!v) {
          return 'N/A';
        }

        return v;
      },
      sorter: (a, b) => sn(a, 'volumes.rank', 0) - sn(b, 'volumes.rank', 0),
    },
    {
      title: (
        <TooltipTableHeader
          title={getIntlStrings('variations.keywordRankTrendTitle')}
          tooltip={getIntlStrings('variations.keywordRankTrendTooltip', { avgLength: MOVING_AVG_LEN })}
        />
      ),
      key: 'keywordRankTrend',
      hidden: !ctx.showRank,
      width: 140,
      render: (v, variation) => <KeywordRankComparison keyword={variation.keyword} metrics={productMetrics} />,
    },
    {
      title: getIntlStrings('locales.columns.googleEstCostPerClick'),
      hidden: !ctx.showRank,
      width: 120,
      render: (_, variation) => {
        if (!variation.volumes) {
          return 'N/A';
        }

        return `${currencySymbol + sn(variation, 'volumes.topOfPageBidLow', 0).toFixed(2)} - ${sn(
          variation,
          'volumes.topOfPageBidHigh',
          0,
        ).toFixed(2)}`;
      },
      sorter: (a, b) => sn(a, 'volumes.topOfPageBidLow', 0) - sn(b, 'volumes.topOfPageBidLow', 0),
    },
    {
      title: getIntlStrings('locales.columns.googleSearchVolume'),
      dataIndex: ['volumes', 'googleImpressions'],
      key: 'amazonSearchVolume',
      hidden: !ctx.showRank,
      width: 120,
      render: (v) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => sn(a, 'volumes.googleImpressions', 0) - sn(b, 'volumes.googleImpressions', 0),
    },

    {
      title: getIntlStrings('locales.columns.liveImpressions'),
      dataIndex: 'liveImpressions',
      key: 'liveImpressions',
      hidden: ctx.showAttributedData,
      width: 120,
      render: (v) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: getIntlStrings('locales.columns.liveClicks'),
      dataIndex: 'liveClicks',
      key: 'liveClicks',
      hidden: ctx.showAttributedData,
      width: 120,
      render: (v) => (
        <>
          <NumberVal clicks={v}></NumberVal>
        </>
      ),
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: getIntlStrings('locales.columns.liveAdCost'),
      dataIndex: 'liveAdSpent',
      key: 'liveAdSpent',
      hidden: ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (adSpent) => (
        <>
          <CurrencyValue amount={adSpent} currency={currencySymbol}></CurrencyValue>
        </>
      ),
    },
    {
      title: getIntlStrings('locales.columns.liveCPC'),
      dataIndex: 'liveAverageCpc',
      key: 'liveAverageCpc',
      hidden: ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.liveAverageCpc - b.liveAverageCpc,
      render: (v) => (
        <>
          <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>
        </>
      ),
    },
    {
      title: getIntlStrings('locales.columns.attributedImpressions'),
      dataIndex: 'impressions',
      key: 'impressions',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.impressions - b.impressions,
    },
    {
      title: getIntlStrings('locales.columns.attributedClicks'),
      dataIndex: 'clicks',
      key: 'clicks',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: 'Amazon Clickthroughs',
      dataIndex: 'amazonClickThroughs',
      key: 'amazonClickThroughs',
      hidden: true,
      width: 120,
      render: (v) => <NumberVal clicks={v}></NumberVal>,
      sorter: (a, b) => a.clicks - b.clicks,
    },
    {
      title: getIntlStrings('locales.columns.attributedAdCost'),
      dataIndex: 'adSpent',
      key: 'adSpent',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.adSpent - b.adSpent,
      render: (adSpent) => <CurrencyValue amount={adSpent} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: getIntlStrings('locales.columns.attributedCpc'),
      dataIndex: 'averageCpc',
      key: 'averageCpc',
      hidden: !ctx.showAttributedData,
      width: 140,
      sorter: (a, b) => a.averageCpc - b.averageCpc,
      render: (v) => <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: getIntlStrings('locales.columns.rawData'),
      width: 140,
      style: { padding: 0 },
      hidden: ctx.showAttributedData,
      className: 'no-padding',
      render: (v, variation) => (
        <div>
          <ExportVariation variation={variation} account={props.account} />
        </div>
      ),
    },
    {
      title: getIntlStrings('locales.columns.addToCart'),
      dataIndex: 'amazonTotalAddToCart',
      key: 'amazonTotalAddToCart',
      hidden: !ctx.showAttributedData,
      className: 'amazon',
      width: 140,
      sorter: (a, b) => a.amazonTotalAddToCart - b.amazonTotalAddToCart,
      render: (v) => <NumberVal clicks={v}></NumberVal>,
    },

    {
      title: getIntlStrings('locales.columns.purchases'),
      dataIndex: 'amazonTotalPurchases',
      key: 'amazonTotalPurchases',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.amazonTotalPurchases - b.amazonTotalPurchases,
      render: (v) => <NumberVal clicks={v}></NumberVal>,
    },
    {
      title: getIntlStrings('locales.columns.revenue'),
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      className: 'amazon',
      hidden: !ctx.showAttributedData,
      width: 120,
      render: (v) => <CurrencyValue amount={v} currency={currencySymbol}></CurrencyValue>,
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: getIntlStrings('locales.columns.netMargin'),
      dataIndex: 'netMargin',
      key: 'netMargin',
      hidden: !ctx.showAttributedData,
      width: 120,
      sorter: (a, b) => a.netMargin - b.netMargin,
      render: (netMargin) => <CurrencyValue amount={netMargin} currency={currencySymbol}></CurrencyValue>,
    },
    {
      title: getIntlStrings('locales.columns.roas'),
      dataIndex: 'roas',
      key: 'roas',
      hidden: !ctx.showAttributedData,
      width: 100,
      render: (v) => <ROASValue roas={v} />,
      sorter: (a, b) => a.roas - b.roas,
    },
    {
      title: getIntlStrings('locales.columns.acos'),
      dataIndex: 'acos',
      key: 'acos',
      hidden: !ctx.showAttributedData,
      width: 100,
      render: (v) => <ACOSValue acos={v} />,
      sorter: (a, b) => a.acos - b.acos,
    },
    {
      title: (
        <Tooltip placement="bottom" title="On average, Brands can earn a 10% bonus from their qualifying sales">
          <span>
            {getIntlStrings('locales.columns.avgBrandReferralBonus')} <InfoCircleOutlined />
          </span>
        </Tooltip>
      ),
      dataIndex: 'amazonTotalSales',
      key: 'amazonTotalSales',
      hidden: !ctx.showAttributedData,
      width: 160,
      render: (v, variation) => (
        <Fragment>
          {variation.amazonTotalSales > 0 && (
            <BrbValue
              brbBonusAmountCalculated={variation.brbBonusAmountCalculated}
              brbBonusAmount={variation.brbBonusAmount}
              currencyCode={currencyCode}
              currencySymbol={currencySymbol}
              brbEnrolled={_.get(
                props.account.productProviders.find((p) => p.id === props.productProviderId),
                'brbEnrolled',
              )}
            />
          )}
        </Fragment>
      ),
      sorter: (a, b) => a.amazonTotalSales - b.amazonTotalSales,
    },
    {
      title: getIntlStrings('locales.columns.firstPageEstBid'),
      hidden: !ctx.showAttributedData || !showKeywordRMFOptions,
      width: 100,
      render: () => <span>{randomESTBids()}</span>,
    },
    {
      title: getIntlStrings('locales.columns.firstPositionEstBid'),
      hidden: !ctx.showAttributedData || !showKeywordRMFOptions,
      width: 100,
      render: () => <span>{randomESTBids()}</span>,
    },
    {
      title: getIntlStrings('locales.columns.amazonRanking'),
      key: 'amazonRanking',
      align: 'center',
      width: 120,
      render: (v, variation) => (
        <KeywordRankButton
          account={props.account}
          product={props.product}
          keyword={variation.keyword.toLowerCase()}
          rankings={rankings}
          onProductSave={props.onProductSave}
        />
      ),
    },
    {
      title: getIntlStrings('locales.columns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      render: (v) => v && moment(v).format(DATE_FORMAT),
    },
  );
  columns = columns.filter((c) => !c.hidden);
  const rowSelection = {
    selectionType: 'checkbox',
    onChange: (selectedRowKeys: any) => {
      props.setSelectedRowKeys(selectedRowKeys);
    },
  };

  if (loading) {
    return <SmallPlaceholder />;
  }

  if (!props.variations || props.variations.length === 0) {
    return (
      <Fragment>
        <br />
        <Card style={{ width: '100%', textAlign: 'center' }}>
          <MutedText>
            There is no data for the campaign.
            <br />
            Data is fetched every day for active campaigns.
          </MutedText>
          <br />
        </Card>
        <br />
      </Fragment>
    );
  }

  return (
    <Style>
      <Table
        style={{ cursor: 'pointer' }}
        rowKey="id"
        tableLayout="fixed"
        dataSource={props.variations}
        columns={columns}
        rowSelection={rowSelection}
        pagination={{
          defaultPageSize: 50,
        }}
        rowClassName={(record) =>
          `${record.totalCampaignsCount === 0 || !record.lastClicks ? 'row-empty' : ''} ${
            record.outOfLimits ? 'row-out-of-limits' : ''
          }`
        }
      />
    </Style>
  );
}
