import './EndOfTrialModal.css';
import React from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { trackedEvents } from 'config/trackedEvents.config';
import { track } from 'lib/analytics';
import { useLocale } from 'locales/LocalizationWrapper';

import Button from '../common/Button';
import { Line } from '../common/Placeholders';

export default function EndOfTrialModal({ account, sub }: { account: accountT, sub: subscriptionT }) {
  const { messages } = useIntl();
  const { getIntlStrings } = useLocale();

  if (sub.status === 'revoked') {
    track(trackedEvents.seeRevokedFullmodal);
  } else {
    track(trackedEvents.seeEndOfTrialFullmodal);
  }

  /* const downgradeButtonText = `Downgrade to ${
    sub.status === "paying_business_trial_ended" ? sub.plan.type : "free"
  }`;*/
  return (
    <div className="endtrial">
      <div className="facet-box__header">
        <Line motion={false} />
      </div>
      <div className="facet-box__content">
        <div>
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
          <Line motion={false} />
        </div>
      </div>
      <div className="endtrial-mask">
        <div className="endtrial__modal box">
          <div className="endtrial__modal__header">
            <Left />
            {sub.status === 'revoked'
              ? messages.endOfTrial.revoked
              : getIntlStrings('endOfTrial.trial', {
                  business: sub.status === 'paying_business_trial_ended',
                })}
            <Right />
          </div>
          <div className="endtrial__modal__content">{messages.endOfTrial.content}</div>
          <div className="endtrial__modal__footer" style={{ justifyContent: 'center', display: 'flex' }}>
            {/* <Link to={`/${account.id}/b/plans`}>
              <LightButton>{downgradeButtonText}</LightButton>
            </Link>*/}
            <Link to={`/${account.id}/b/plans`}>
              <Button>{messages.endOfTrial.button}</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function Left() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="10" height="10" transform="translate(6 23) rotate(30)" fill="#2EC4B6" />
      <rect width="10" height="10" transform="translate(46 0) rotate(78)" fill="#2E89E2" />
      <circle cx="5" cy="5" r="5" transform="translate(42 42)" fill="#F2994A" />
    </svg>
  );
}

function Right() {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="5" transform="translate(6 39)" fill="#2E89E2" />
      <circle cx="5" cy="5" r="5" transform="translate(42 28)" fill="#F2994A" />
      <rect width="10" height="10" transform="translate(28 0) rotate(38)" fill="#2EC4B6" />
    </svg>
  );
}
