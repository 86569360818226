import React, { useState, type Node, useEffect } from 'react';

import { DownOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Drawer, Table } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TagInput } from 'components/common/antd/TagInput';
import { strings } from 'locales/strings';

import { AddCustomKeywordsModal } from './AddCustomKeywordsModal';
import { SEARCH_AUDIT_KEYWORDS } from './constants';
import { KeywordFloatingCellAction } from '../AdsProviders/common/KeywordFloatingCellAction';

type propsT = {
  isModalVisible: boolean,
  onFinish: () => Promise<void>,
  setAddKeywords: any,
  onKeywordChange: (kw: any) => void,
  addKeywords: string[],
  removeKeywords: string[],
  onClose: () => void,
  tableData: {
    title: string,
    keywords: auditKeywordT[],
  }[],
};

const TitleContainer = styled.span`
  font-weight: 600;
`;

const TopContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const ModalContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const DrawerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const KeywordsAuditModal = ({
  tableData,
  isModalVisible,
  onFinish,
  onKeywordChange,
  addKeywords,
  removeKeywords,
  onClose,
  setAddKeywords,
}: propsT): Node => {
  const [searchKeywords, setSearchKeywords] = useState([]);
  const [isAddKeywordsModalVisible, showAddKeywordsModal] = useState(false);
  const [filteredData, setFilteredData] = useState(tableData);
  const { messages } = useIntl();

  useEffect(() => {
    if (!searchKeywords.length) {
      setFilteredData(tableData);
    } else {
      const filtered = tableData.map((row) => ({
        ...row,
        keywords: row.keywords.filter((kw) => kw.keyword === searchKeywords[0]),
      }));

      setFilteredData(filtered);
    }
  }, [searchKeywords.length]);

  const onCustomAddKeywords = (keywords) => {
    // add keywords to only the Top Keywords
    const newTableData = tableData.map((row) => {
      if (row.title === 'Top Keywords') {
        return {
          ...row,
          keywords: [...row.keywords, ...keywords.map((kw) => ({ keyword: kw }))],
        };
      }

      return row;
    });

    setAddKeywords([...addKeywords, ...keywords]);
    setFilteredData(newTableData);
  };

  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      className: 'keyword',
      width: 250,
      render: (v, record) => {
        if (record.title) {
          return (
            <TitleContainer>
              {record.title} ({record.keywords?.length})
            </TitleContainer>
          );
        }

        return <KeywordFloatingCellAction keyword={v} marketplaceId="ATVPDKIKX0DER" />;
      },
    },
    {
      title: <span className="amazon">Amazon Search Volume</span>,
      dataIndex: 'amazonSearchVolume',
      sorter: (a, b) => a.amazonSearchVolume - b.amazonSearchVolume,
      render: (v) => {
        if (v) {
          return v.toLocaleString('en-US', { maximumFractionDigits: 0 });
        } else {
          return '';
        }
      },
    },
    {
      title: <span className="amazon">Amazon Current Rank</span>,
      dataIndex: 'amazonCurrentRank',
      key: 'amazonCurrentRank',
      sorter: (a, b) => a.amazonCurrentRank - b.amazonCurrentRank,
      render: (v) => (v ? v : ''),
    },
    {
      title: <span className="amazon">Amazon Competitor Rank</span>,
      dataIndex: 'amazonCompetitorRank',
      key: 'amazonCompetitorRank',
      sorter: (a, b) => a.amazonCompetitorRank - b.amazonCompetitorRank,
      render: (v) => {
        if (v) {
          return v;
        } else {
          return '';
        }
      },
    },
    {
      title: 'Google Est. Cost per Click',
      render: (_, record) => {
        if (!record.googleEstCostPerClickLow || !record.googleEstCostPerClickHigh) {
          return '';
        }

        return `$ ${record.googleEstCostPerClickLow.toFixed(2)} - ${record.googleEstCostPerClickHigh.toFixed(2)}`;
      },
    },
    {
      title: 'Google Search Volume',
      dataIndex: 'googleSearchVolume',
      sorter: (a, b) => a.googleSearchVolume - b.googleSearchVolume,
      render: (_, record) => {
        if (record.googleSearchVolume) {
          return record.googleSearchVolume.toLocaleString('en-US', {
            maximumFractionDigits: 0,
          });
        } else {
          return '';
        }
      },
    },
    {
      title: 'Action',
      key: 'actions',
      fixed: 'right',
      render: (_, record) => {
        if (record.title) {
          return '';
        }

        if (record.rejected) {
          return (
            <span style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => onKeywordChange(record)}>
              {addKeywords.includes(record.keyword) ? 'Added' : 'Add'}
            </span>
          );
        } else {
          return (
            <span style={{ color: '#E1473D', cursor: 'pointer' }} onClick={() => onKeywordChange(record)}>
              {removeKeywords.includes(record.keyword) ? 'Removed' : 'Remove'}
            </span>
          );
        }
      },
    },
  ];

  return (
    <Drawer
      visible={isModalVisible}
      title={messages.asinAudit.auditImprovement.editKeywords}
      width="calc(100vw - 5rem)"
      footer={
        <DrawerContainer>
          <Button key="back" onClick={onClose}>
            Cancel
          </Button>
          <Button key="submit" type="primary" onClick={onFinish}>
            Save Changes
          </Button>
        </DrawerContainer>
      }
      onClose={onClose}
    >
      <ModalContainer>
        <TopContainer>
          <TagInput
            maxTags={SEARCH_AUDIT_KEYWORDS}
            placeholder={messages.asinAudit.auditImprovement.searchKeywords}
            onChange={setSearchKeywords}
            value={searchKeywords}
            separators={['Enter']}
          />
          <Button
            icon={<PlusOutlined />}
            type="primary"
            ghost
            style={{ height: '2.45rem' }}
            onClick={() => showAddKeywordsModal(true)}
          >
            {messages.asinAudit.auditImprovement.addKeywords}
          </Button>
          {isAddKeywordsModalVisible && (
            <AddCustomKeywordsModal
              isModalVisible={isAddKeywordsModalVisible}
              onClose={() => showAddKeywordsModal(false)}
              data={tableData.filter((row) => row.title === 'Top Keywords')}
              onFinish={onCustomAddKeywords}
            />
          )}
        </TopContainer>
        <Table
          rowKey={(record) => record.keyword ?? record.title}
          tableLayout="fixed"
          dataSource={filteredData}
          scroll={{ x: 1000 }}
          childrenColumnName="keywords"
          pagination={{
            hideOnSinglePage: true,
          }}
          columns={columns}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              if (!record.keywords) {
                return <span></span>;
              }

              return expanded ? (
                <DownOutlined onClick={(e) => onExpand(record, e)} style={{ paddingRight: '16px' }} />
              ) : (
                <RightOutlined onClick={(e) => onExpand(record, e)} style={{ paddingRight: '16px' }} />
              );
            },
            indentSize: -15,
            defaultExpandAllRows: true,
          }}
        />
      </ModalContainer>
    </Drawer>
  );
};
