let initialized: boolean = false;

export function initGrowsumo() {
  if (!initialized) {
    const gs = document.createElement('script');

    gs.src = 'https://ps.carbon6.io/pr/js';
    gs.type = 'text/javascript';
    gs.async = 'true';
    gs.onload = gs.onreadystatechange = function () {
      const rs = this.readyState;

      if (rs && rs !== 'complete' && rs !== 'loaded') {
        return;
      }

      try {
        window.growsumo._initialize('pk_Ax5TNjYhRkxPb88cHGKZtnlb2Vzgo4Zt');
      } catch (e) {}
    };
    const s = document.getElementsByTagName('script')[0];

    s.parentNode.insertBefore(gs, s);
    initialized = true;
  }
}
