import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

const productMetricsSlice = createSlice({
  name: 'productMetrics',
  initialState,
  reducers: {
    setProductMetrics: (state, action) => {
      state.data = action.payload;
    },
  },
  selectors: {
    selectProductMetrics: (state) => state.data,
  },
});

export const { setProductMetrics } = productMetricsSlice.actions;
export const { selectProductMetrics } = productMetricsSlice.selectors;
export default productMetricsSlice;
