import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

export const TooltipTableHeader = ({ title, tooltip }: { title: string, tooltip: string }) => (
  <HeaderContainer>
    <div>{title}</div>
    <Tooltip title={tooltip} overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}>
      <InfoCircleOutlined />
    </Tooltip>
  </HeaderContainer>
);
