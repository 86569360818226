export const tiktokAges = [
  {
    label: '13-17',
    value: 'AGE_13_17',
  },
  {
    label: '18-24',
    value: 'AGE_18_24',
  },
  {
    label: '25-34',
    value: 'AGE_25_34',
  },
  {
    label: '35-44',
    value: 'AGE_35_44',
  },
  {
    label: '45-54',
    value: 'AGE_45_54',
  },
  {
    label: '+55',
    value: 'AGE_55_100',
  },
];
