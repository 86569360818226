import React from 'react';

import styled from 'styled-components';

const Style = styled.div`
  display: flex;
  width: max-content;
  align-items: center;
  gap: 1em;
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
`;

export function RowCellWithSpanOverflow({ children }) {
  return <Style>{children}</Style>;
}
