import React, { Component } from 'react';

import numeral from 'numeral';

import { Stats } from './Stats';
import { StatsContainer } from './StatsContainer';
import { StatsTitle } from './StatsTitle';
import { StatsValue } from './StatsValue';
import { MAX_REDIRECTS_COUNT } from '../../../../../../consts/consts';

const defaultState: internalStateT = {};

export class AbstractStatsHeader extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const {
      nbRedirects,
      nbClicks,
    }: {
      nbRedirects: number,
      nbClicks: number,
    } = this.props;

    return (
      <StatsContainer>
        {nbRedirects < MAX_REDIRECTS_COUNT && (
          <Stats key="nbRedirects">
            <StatsTitle>Links</StatsTitle>
            <StatsValue type="nbRedirect">{numeral(nbRedirects).format('0,000')}</StatsValue>
          </Stats>
        )}

        <Stats key="nbClicks">
          <StatsTitle>Clicks</StatsTitle>
          <StatsValue type="nbClicks">{numeral(nbClicks).format('0,000')}</StatsValue>
        </Stats>
      </StatsContainer>
    );
  }
}
