import React from 'react';

import { useHistory } from 'react-router-dom';

import { AddANewButton } from './AddANewButton';

export function AddDomainButton({ accountId, disabledForPerformanceBooster }) {
  const history = useHistory();

  return (
    <AddANewButton
      onClick={() => history.push(`/${accountId}/ab/settings/domains`)}
      disabled={disabledForPerformanceBooster}
    >
      + Add a new domain
    </AddANewButton>
  );
}
