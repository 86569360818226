import React, { useState } from 'react';

import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Actions } from '../../../actions';
import Box from '../../common/Box';
import DeleteItem from '../../common/DeleteItem';
import Modal from '../../common/Modal';

const Style = styled.div`
  .settings-tags > .box {
    margin-top: 1rem;
  }
  .settings-tags > .box:first-child {
    margin-top: 0;
  }

  .settings-tags__list__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .settings-tags__list__title .button {
    height: 1.8rem;
    line-height: 1.8rem;
    padding: 0;
    font-size: var(--small-size);
  }

  .settings-tags__list__title span {
    font-size: var(--small-size);
    color: var(--text-muted);
    margin-left: 1rem;
  }

  .settings-tags__list__headers {
    color: var(--text-muted);
  }

  .settings-tags__list__line {
    display: grid;
    grid-template-columns: repeat(1, 1fr) 3rem;
    grid-gap: 0 1rem;
    height: 1.8rem;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
  }

  .settings-tags__list__line .input {
    padding: 0.2rem;
  }

  .settings-tags__list__line--odd {
    background: #f9f9f9;
  }
  .settings-tags__list--del {
    justify-self: end;
    padding-top: 2px;
  }
  .no-tags {
    padding: 2em;
    text-align: center;
    font-size: 14px;
  }
`;

export const Tags = ({ account }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedTag, setSelectedTag] = useState();
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const tags = _.sortBy(account.tags, 'name');
  const dispatch = useDispatch();

  const renderHeader = () => (
    <div className="settings-tags__list__title">
      <div>Tags</div>
    </div>
  );

  const renderNoTags = () => (
    <div className="no-tags">
      <p>There is no tag in your account.</p>
      <p>To add some, please create at least one redirect using the new tag.</p>
    </div>
  );
  const renderTags = () =>
    tags.map((tag, index) => {
      let classes = 'settings-tags__list__line';

      if (index % 2 === 1) {
        classes = `${classes} settings-tags__list__line--odd`;
      }

      return (
        <div key={tag.name} className={classes}>
          <div className="settings-tags__list--name">{tag.name}</div>
          <div className="settings-tags__list--del">
            <DeleteItem
              disabled={isDeleting}
              handleClick={() => {
                setIsModalDisplayed(true);
                setSelectedTag(tag);
              }}
            />
          </div>
        </div>
      );
    });

  const displayModal = () => {
    if (isModalDisplayed) {
      const message = isDeleting ? 'Deleting...' : "Yes I'm sure";

      return (
        <Modal
          disabled={isDeleting}
          okMessage={message}
          handleCancel={() => {
            setIsModalDisplayed(false);
            setIsDeleting(false);
          }}
          handleOk={() =>
            dispatch(Actions.api.account.tags.remove.request({ tag: selectedTag })).then(() => {
              setIsModalDisplayed(false);
              setIsDeleting(false);
              setSelectedTag(undefined);
            })
          }
        >
          Are you sure you want to delete tag "{selectedTag.name}
          "?
        </Modal>
      );
    }

    return null;
  };

  return (
    <Style>
      <Box className="settings-tags__list" header={renderHeader()}>
        {!tags.length && renderNoTags()}
        {!!tags.length && (
          <>
            <div className="settings-tags__list__headers settings-tags__list__line">
              <div className="settings-tags__list--name">Name</div>
              <div className="settings-tags__list--del">Delete</div>
            </div>
            {renderTags()}
          </>
        )}
      </Box>
      {displayModal()}
    </Style>
  );
};
