import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Delete } from 'components/common/icons/Delete';

import { Actions } from '../../../../../actions';
import { Buttonize } from '../campaignTree/Common';

const DeleteButtonContainer = styled(Buttonize).withConfig({
  displayName: 'DeleteButtonContainer',
})`
  width: 32px;
  height: 35px;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 6px;
  margin-right: 3px;
  flex-shrink: 0;
  flex-grow: 0;
`;

type internalPropsT = { config: columnsConfigT, selectAction: WorkerAc };
type internalActionsT = { onSelect: Function };
type internalStateT = { config: columnsConfigT };
const defaultState: internalStateT = {
  config: {},
};

class AbstractDeleteRedirects extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const { showDelete } = this.props;

    return (
      <>
        <DeleteButtonContainer>
          <Delete onClick={showDelete} />
        </DeleteButtonContainer>
      </>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  config: state.front.deleteRedirectsModalConfig,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  showDelete: (_) => {
    dispatch(Actions.front.audienceBuilder.redirect.delete.show.request({}));
  },
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) =>
  _.isEqual(prev.front.deleteRedirectsModalConfig, next.front.deleteRedirectsModalConfig);

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const DeleteRedirects = reduxWrapper(AbstractDeleteRedirects);
