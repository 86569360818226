import { Actions } from 'actions';
import { CAMPAIGNS_TABLE_DEFAULT_COLUMNS } from '../consts/consts';

type stateT = {
  selectedColumns: string[],
};

// STORE
export const initialState: stateT = {
  selectedColumns: CAMPAIGNS_TABLE_DEFAULT_COLUMNS,
};

export function campaignsTable(state: stateT, action: actionT): stateT {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case Actions.front.campaignsTable.columns.update.request().type:
      return {
        ...state,
        selectedColumns: action.payload,
      };

    case Actions.front.campaignsTable.columns.reset.request().type:
      return {
        ...state,
        selectedColumns: CAMPAIGNS_TABLE_DEFAULT_COLUMNS,
      };

    default:
      return state;
  }
}
