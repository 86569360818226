import './SubscriptionCheckout.css';

import React, { Component, Fragment } from 'react';

import numeral from 'numeral';

import { getCoupon } from 'lib/api';
import axios from 'lib/axios.factory';

import Card from './Card';
import { openCreditCardPage } from './openCreditCardPage';
import PoweredByStripe from './PoweredByStripe';
import VAT from './VAT';
import Button, { CancelButton, LightButton } from '../../common/Button';
import Input from '../../common/Input';
import { Line } from '../../common/Placeholders';

export default class SubscriptionCheckout extends Component<
  {
    account: accountT,
    plans: Array<planT>,
    card?: cardT,
    vat?: vatT,
    coupon?: orgCouponT,
    updating: boolean,
    period: 'yearly' | 'monthly',
    callSubscribe: ({ accountId: string, coupon: string, plan: planT, vat: vatT, token?: string }) => void,
    handleCancel: () => void,
  },
  {
    coupon?: couponT,
    couponStr: string,
  },
> {
  constructor(props: {
    account: accountT,
    plans: Array<planT>,
    card?: cardT,
    vat?: vatT,
    coupon?: orgCouponT,
    updating: boolean,
    period: 'yearly' | 'monthly',
    callSubscribe: ({ accountId: string, coupon: string, plan: planT, vat: vatT, token?: string }) => void,
    handleCancel: () => void,
  }) {
    super(props);

    if (props.coupon) {
      this.state = {
        coupon: props.coupon,
        couponStr: props.coupon.id,
      };
    } else {
      this.state = {
        couponStr: '',
      };
    }
  }

  render() {
    const { plans, period, card, vat, updating } = this.props;
    const { coupon, couponStr } = this.state;
    const currentPlan = plans.filter((p) => p.period === period)[0];

    if (updating) {
      return (
        <div className="sub-checkout">
          <div className="sub-checkout__content box">
            <Line />
            <Line />
          </div>
        </div>
      );
    }

    return (
      <div className="sub-checkout">
        <div className="sub-checkout__content box">
          <div className="sub-checkout__plan-title">
            Subscribe to <span>{currentPlan.name}</span> plan
          </div>
          {card && (
            <Fragment>
              <Card card={card} />
              <div className="sub-checkout__coupon">
                <Input
                  id="coupon"
                  value={couponStr}
                  placeholder="Coupon"
                  disabled={coupon}
                  onChange={this.handleChangeCoupon}
                />
                {!coupon && (
                  <LightButton
                    disabled={couponStr.length < 5}
                    handleClick={(_) => this.handleCheckCoupon({ coupon: couponStr })}
                  >
                    Apply coupon
                  </LightButton>
                )}
              </div>
              <VAT vat={vat} />
              <div className="sub-checkout__buttons">
                <CancelButton handleClick={this.props.handleCancel}>Cancel</CancelButton>
                <Button
                  handleClick={(_) =>
                    this.handleSubscribe({
                      plan: currentPlan,
                      coupon: coupon === undefined ? '' : coupon.id,
                      vat,
                    })
                  }
                >
                  {generateButtonFromPlanAndCoupon({
                    plan: currentPlan,
                    coupon,
                    vat,
                  })}
                </Button>
              </div>
              <PoweredByStripe />
            </Fragment>
          )}
          {!card && (
            <div className="sub-checkout__message">
              <p>Please add credit card details to subscribe to the plan.</p>
              <Button handleClick={(_) => openCreditCardPage(this.props.account.id)}>Add a card</Button>
            </div>
          )}
        </div>
      </div>
    );
  }

  handleCheckCoupon = ({ coupon }: { coupon: string }) => {
    const { account, plans, period } = this.props;
    const currentPlan = plans.filter((p) => p.period === period)[0];

    getCoupon(localStorage, axios, {
      accountId: account.id,
      coupon,
      planId: currentPlan.id,
    }).then(({ coupon }) => this.setState({ coupon }));
  };

  handleSubscribe = ({ plan, coupon, vat, token }: { plan: planT, coupon: string, vat: vatT, token?: string }) => {
    const { account, callSubscribe } = this.props;

    callSubscribe({
      accountId: account.id,
      coupon,
      plan,
      vat,
      token,
    });
    this.props.handleCancel();
  };

  handleChangeCoupon = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;

    this.setState({
      couponStr: value,
    });
  };
}

function generateButtonFromPlanAndCoupon({ plan, coupon, vat }: { plan?: planT, coupon?: couponT, vat: vatT }): string {
  if (!plan) {
    return '';
  }

  let amount = plan.amountInCents;

  if (coupon) {
    if (coupon.amountInCents > 0) {
      amount = plan.amountInCents - coupon.amountInCents;
    }

    if (coupon.percent > 0) {
      amount = ((100 - coupon.percent) / 100) * plan.amountInCents;
    }
  }

  if (vat && vat.percent > 0) {
    amount = (vat.percent / 100 + 1) * amount;
  }

  return `Pay ${numeral(amount / 100).format('$0,0.00')}`;
}
