import { DragSource } from 'react-dnd';

import RedirectsListLine from './RedirectsListLine';

const redirectsListLineSource = {
  beginDrag({ redirect, selectedRedirects }) {
    if (selectedRedirects && selectedRedirects.length > 0) {
      return { redirects: selectedRedirects };
    } else {
      return { redirects: [redirect] };
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const dragAndDropWrapper = DragSource(RedirectsListLine.TYPE, redirectsListLineSource, collect);

export const CampaignRedirectsDragDropSource = dragAndDropWrapper(RedirectsListLine);
