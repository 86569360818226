import { useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import { useImmer } from 'use-immer';

import { useFeatureFlag, CAMPAIGN_ANALYTICS_UPDATE } from 'lib/configcat';

import {
  defaultGraphSettings,
  getCacheVisible,
  getEmptyLine,
  getFirstDateIndex,
  isLifetimeSelected,
  MetricsPointFormatter,
  RankingPointFormatter,
  roundTo100,
} from './utils';
import { METRICS } from '../constants';

export const useChartOptions = () => {
  const [metricsChartOptions, setMetricsChartOptions] = useImmer(_.cloneDeep(defaultGraphSettings));
  const [rankingChartOptions, setRankingChartOptions] = useImmer(_.cloneDeep(defaultGraphSettings));
  const [rankingData, setRankingData] = useState([]);
  const [showMetricsUpdate] = useFeatureFlag(CAMPAIGN_ANALYTICS_UPDATE);

  const hydrateChartOptions = ({
    metrics,
    campaigns,
    filter: { startDate, endDate },
    isAmazonProduct,
    currencySymbol,
  }) => {
    const zones = {};
    const liveDate = moment().utc().startOf('day').subtract(2, 'd');

    if (endDate > liveDate) {
      zones.zoneAxis = 'x';
      zones.zones = [{ value: liveDate }, { dashStyle: 'dot' }];
    }

    const firstDateIndex = isLifetimeSelected(startDate, endDate) ? getFirstDateIndex(metrics) : 0;

    const converter = (entries) => entries?.slice(firstDateIndex).map(({ date, value }) => [Date.parse(date), value]);

    const emptyLine = getEmptyLine(firstDateIndex ? startDate.clone().add(firstDateIndex, 'days') : startDate, endDate);

    const metricsChartSeries = [
      emptyLine,
      {
        name: METRICS.CLICKS,
        data: converter(metrics.clicks),
        visible: getCacheVisible(METRICS.CLICKS),
        yAxis: 0,
      },
      {
        name: METRICS.ADD_TO_CARTS,
        data: converter(metrics.totalAddToCarts),
        visible: getCacheVisible(METRICS.ADD_TO_CARTS),
        yAxis: 0,
      },
      {
        name: METRICS.IMPRESSIONS,
        data: converter(metrics.impressions),
        visible: getCacheVisible(METRICS.IMPRESSIONS),
        yAxis: 0,
      },
      {
        name: METRICS.PURCHASES,
        data: converter(metrics.totalPurchases),
        visible: getCacheVisible(METRICS.PURCHASES),
        yAxis: 0,
      },
      {
        name: METRICS.AD_SPEND,
        data: converter(metrics.adCost),
        visible: getCacheVisible(METRICS.AD_SPEND),
        yAxis: 1,
      },
      {
        name: METRICS.REVENUE,
        data: converter(metrics.totalRevenue),
        visible: getCacheVisible(METRICS.REVENUE),
        yAxis: 1,
      },
      {
        name: METRICS.ACOS,
        data: converter(metrics.acos),
        visible: getCacheVisible(METRICS.ACOS),
        yAxis: 2,
      },
      {
        name: METRICS.PRICE,
        data: converter(metrics.price),
        visible: getCacheVisible(METRICS.PRICE),
        yAxis: 1,
      },
      {
        name: METRICS.ROAS,
        data: converter(metrics.roas),
        visible: getCacheVisible(METRICS.ROAS),
        yAxis: 2,
      },
      ...metrics.bsr
        .filter((x) => x.categoryName)
        .map((m, i) => {
          const name = `${METRICS.BSR} (${m.categoryName})`;

          return {
            name,
            data: converter(m.metrics),
            visible: getCacheVisible(name),
            yAxis: 3 + i,
          };
        }),
    ];

    if (showMetricsUpdate && isAmazonProduct) {
      if (metrics.organicSales) {
        metricsChartSeries.push({
          name: METRICS.ORGANIC_SALES,
          data: converter(metrics.organicSales),
          visible: getCacheVisible(METRICS.ORGANIC_SALES),
          yAxis: 1,
        });
      }

      if (metrics.tacos) {
        metricsChartSeries.push({
          name: METRICS.TACOS,
          data: converter(metrics.tacos),
          visible: getCacheVisible(METRICS.TACOS),
          yAxis: 2,
        });
      }

      if (metrics.troas) {
        metricsChartSeries.push({
          name: METRICS.TROAS,
          data: converter(metrics.troas),
          visible: getCacheVisible(METRICS.TROAS),
          yAxis: 2,
        });
      }

      if (metrics.blendedAcos) {
        metricsChartSeries.push({
          name: METRICS.BLENDED_ACOS,
          data: converter(metrics.blendedAcos),
          visible: getCacheVisible(METRICS.BLENDED_ACOS),
          yAxis: 2,
        });
      }

      if (metrics.blendedRoas) {
        metricsChartSeries.push({
          name: METRICS.BLENDED_ROAS,
          data: converter(metrics.blendedRoas),
          visible: getCacheVisible(METRICS.BLENDED_ROAS),
          yAxis: 2,
        });
      }

      if (metrics.amazonAdSpend) {
        metricsChartSeries.push({
          name: METRICS.AMAZON_AD_SPEND,
          data: converter(metrics.amazonAdSpend),
          visible: getCacheVisible(METRICS.AMAZON_AD_SPEND),
          yAxis: 1,
        });
      }
    }

    const campaignStartPlotLines = campaigns.map((c) => ({
      value: Date.parse(c.createdAt),
      width: 1,
      label: {
        text: `campaign ${c.externalId} created`,
        style: {
          fontSize: '8px',
          color: '#828282',
        },
      },
    }));

    setMetricsChartOptions((draft) => {
      draft.xAxis = {
        ...draft.xAxis,
        plotLines: campaignStartPlotLines,
      };
      draft.yAxis = [
        ...draft.yAxis,
        { visible: false },
        { visible: false },
        ...[...Array(metrics.bsr.length).keys()].map(() => ({ visible: false, reversed: true })),
      ];
      draft.series = metricsChartSeries.map((x) => ({ ...x, ...zones }));
      draft.tooltip.pointFormatter = function () {
        return MetricsPointFormatter.call(this, currencySymbol);
      };
    });

    // Get keywords data from keywords metric
    let kwMetrics;
    let rankingChartSeries = metrics.ranking;

    if (metrics.keywordMetrics) {
      kwMetrics = _.keyBy(metrics.keywordMetrics, 'keyword');
      rankingChartSeries = rankingChartSeries
        .map((r) => ({
          ...r,
          purchases: kwMetrics[r.keyword].totalPurchases || 0,
          searchVolume: kwMetrics[r.keyword].amazonVolume || 0,
        }))
        .sort((a, b) => (b.purchases === a.purchases ? b.searchVolume - a.searchVolume : b.purchases - a.purchases));
    }

    setRankingData(
      rankingChartSeries.map((r) => ({
        ...r,
        searchVolume: r.searchVolume >= 0 ? roundTo100(r.searchVolume).toLocaleString() : '0',
        purchases: r.purchases >= 0 ? Math.ceil(r.purchases).toLocaleString() : '0',
      })),
    );

    setRankingChartOptions((draft) => {
      draft.xAxis = {
        ...draft.xAxis,
        plotLines: campaignStartPlotLines,
      };
      draft.yAxis[0].reversed = true;
      draft.yAxis[0].labels.enabled = true;
      draft.series = [
        emptyLine,
        ...rankingChartSeries.map((r) => ({
          name: r.keyword,
          data: converter(r.metrics),
          visible: getCacheVisible(r.keyword),
          yAxis: 0,
        })),
      ];
      draft.tooltip.pointFormatter = RankingPointFormatter;
    });
  };

  return {
    metricsChartOptions,
    rankingChartOptions,
    rankingData,
    hydrateChartOptions,
  };
};
