import React from 'react';

export function Facebook() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none" viewBox="0 0 16 16">
      <g>
        <circle cx="8" cy="8" r="8" fill="#3B4998"></circle>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M2.703 8V4.344h1.219l.187-1.422H2.703v-.906c0-.23.05-.401.149-.516.099-.115.283-.172.554-.172h.75V.062C3.99.021 3.625 0 3.063 0 2.5 0 2.054.164 1.726.492c-.329.328-.493.79-.493 1.383v1.047H0v1.422h1.234V8h1.47z"
          clipRule="evenodd"
          transform="translate(5.5 4)"
        ></path>
      </g>
    </svg>
  );
}
