import './CardDetails.css';

import React, { Component, Fragment } from 'react';

import { withRouter } from 'react-router-dom';

import Card from './Card';
import { openCreditCardPage } from './openCreditCardPage';
import Box from '../../common/Box';
import Button from '../../common/Button';
import { Line } from '../../common/Placeholders';

class CardDetails extends Component<
  {
    user: userT,
    account: accountT,
    card?: cardT,
    updating: boolean,
    setDefaultCard: ({ organizationId: string, setupIntent: string }) => void,
    cardError: string | null,
  },
  { editing: boolean },
> {
  constructor(props: {
    user: userT,
    account: accountT,
    card?: cardT,
    updating: boolean,
    setDefaultCard: ({ organizationId: string, setupIntent: string }) => void,
    cardError: string | null,
  }) {
    super(props);
    this.state = { editing: false };
  }

  hasError = false;

  render() {
    const { updating, card, location, history, account, cardError } = this.props;

    // if (account.ownerId !== user.id || account.userRight !== "owner") {
    //   return null;
    // }
    if (updating) {
      return (
        <Box className="card-details" header={<Line />}>
          <Line />
          <Line />
        </Box>
      );
    }

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('redirect_status')) {
      const status = queryParams.get('redirect_status');
      const setupIntent = queryParams.get('setup_intent');
      const errorMessage = queryParams.get('message');
      const errorCode = queryParams.get('code');

      queryParams.forEach((value, key) => {
        queryParams.delete(key);
      });

      history.replace({
        search: queryParams.toString(),
      });

      if (status === 'error') {
        console.error(`UBS Checkout Error ${errorCode}: ${errorMessage}`);
        this.hasError = true;
      } else if (status === 'succeeded' && setupIntent) {
        this.props.setDefaultCard({ organizationId: account.organizationId, setupIntent });
      }
    }

    if (this.hasError) {
      return (
        <Box className="card-details" header="Error">
          <div className="card-details__current-card">
            <p>Something went wrong. Please try again later.</p>
          </div>
        </Box>
      );
    }

    return (
      <Fragment>
        {card && (
          <Box className="card-details" header="Current card">
            <div className="card-details__current-card">
              <Card card={card} />
              <Button handleClick={(_) => openCreditCardPage(this.props.account.id)}>Update</Button>
              {cardError && <div className="error-message">{cardError}</div>}
            </div>
          </Box>
        )}
        {!card && (
          <Box className="card-details" header="No default card">
            <Button handleClick={(_) => openCreditCardPage(this.props.account.id)}>Add a card</Button>
            {cardError && <div className="error-message">{cardError}</div>}
          </Box>
        )}
      </Fragment>
    );
  }
}

export default withRouter(CardDetails);
