import React from 'react';

import { Form, Select } from 'antd';

import { sd } from 'lib/safe';

import { BIDDING_DROPDOWN_OPTIONS } from './mockdataRMF';

export const BiddingOptionsSelector = (props: { defaultValue: string }) => (
  <Form.Item name="biddingOptions" label="Bidding Options" style={{ marginBottom: 0 }}>
    <Select
      size="large"
      disabled={BIDDING_DROPDOWN_OPTIONS.length <= 0}
      showArrow={true}
      placeholder="Select a Bidding strategy"
      defaultValue={props.defaultValue}
    >
      {BIDDING_DROPDOWN_OPTIONS.map((value) => (
        <Select.Option key={value} value={value}>
          {value}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);
