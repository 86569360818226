import React, { Fragment, useEffect, useState } from 'react';

import { Select } from 'antd';
import { flag } from 'country-emoji';
import _, { debounce } from 'lodash';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { sd } from 'lib/safe';

import { searchFacebookLocation } from '../../../../lib/facebookPlateformApi';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

const HideSearch = styled.div`
  .ant-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 250px;
  }

  .ant-select-item-option-active {
  }
`;

/**
 * Facebook Geo target selector
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookGeoTargetSelector(props: {
  account: any,
  value: any,
  onChange: any,
  defaultValue: any,
  initialValue: any,
  adsProvider: adsProviderT,
}) {
  const [options, setOptions] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState(props.defaultValue || props.initialValue);

  async function load(v) {
    setLoading(true);

    try {
      const locations = await searchFacebookLocation(localStorage, axios, props.adsProvider, v);
      const newOptions = [...locations, ...selected];

      setAllLocations(_.uniqBy([...allLocations, ...newOptions], 'key'));

      if (Array.isArray(locations)) {
        setOptions(_.uniqBy(newOptions, 'key'));
      }
    } catch (e) {
      setLoading(false);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (allLocations.length === 0) {
      load().then();
      setAllLocations([...allLocations, ...sd(props.value, []).filter((v) => !!v)]);
      setOptions([...options, ...sd(props.value, []).filter((v) => !!v)]);
      setDefaultValue(sd(props.value, []).filter((v) => !!v));
      setSelected(sd(props.value, []).filter((v) => !!v));
    }
  }, [props.value]);

  return (
    <Fragment>
      {defaultValue && (
        <Select
          size="large"
          mode="multiple"
          placeholder="Select at least one location"
          allowClear
          onInputKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();

              return false;
            }
          }}
          loading={loading}
          defaultValue={defaultValue}
          onChange={(keys) => {
            const selected = keys.map((key) =>
              (allLocations || []).filter((o) => !!o).find((p) => `${p.key}` === `${key}`),
            );

            setSelected(selected);
            props.onChange(selected);
          }}
          dropdownRender={(c) => <HideSearch>{c}</HideSearch>}
          onClear={() => {
            setOptions([]);
            setAllLocations([]);
          }}
          filterOption={false}
          onSearch={debounce(load, 200)}
        >
          {loading && <SmallCompactPlaceholder />}
          {!loading && (
            <Fragment>
              <Select.OptGroup label="Countries">
                {_.uniqBy(
                  options.filter((l) => !!l && l.name && l.type === 'country'),
                  'key',
                ).map((l) => (
                  <Select.Option
                    key={l.key}
                    id={l.key}
                    className="visible"
                    style={{
                      fontSize: l.targetType === 'Country' ? '14px' : '12px',
                      fontWeight: l.targetType === 'Country' ? 'bold' : 'normal',
                    }}
                    value={l.key}
                  >
                    {`${flag(l.countryCode) || ''} ${l.name}`}
                  </Select.Option>
                ))}
              </Select.OptGroup>
              <Select.OptGroup label="Cities & Province">
                {_.uniqBy(
                  options.filter((l) => !!l && l.name && l.type !== 'country'),
                  'key',
                ).map((l) => (
                  <Select.Option
                    key={l.key}
                    id={l.key}
                    className="visible"
                    style={{
                      fontSize: l.targetType === 'Country' ? '14px' : '12px',
                      fontWeight: l.targetType === 'Country' ? 'bold' : 'normal',
                    }}
                    value={l.key}
                  >
                    {`${flag(l.countryCode) || ''} ${l.name}, ${l.region || '-'}, ${l.countryName || '-'}`}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            </Fragment>
          )}
        </Select>
      )}
    </Fragment>
  );
}
