import React from 'react';

import { Input } from 'antd';
import _ from 'lodash';

const prefixUrl = (value) => {
  if (!_.startsWith(value, `http://`) && !_.startsWith(value, `https://`)) {
    value = `https://${value}`;
  }

  return value;
};

export function InputUrl(props: { value: string, onChange: Function }) {
  return (
    <Input
      value={props.value}
      onBlur={_.flow((e) => e.target.value, prefixUrl, props.onChange)}
      onChange={_.flow((e) => e.target.value, props.onChange)}
    />
  );
}
