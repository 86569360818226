import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const GRAPH_TOOLTIP_WIDTH = 250;

export const HeaderStyle = styled.div`
  .ant-table-column-sorters {
    justify-content: space-between;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }
  margin-top: -25px;
  gap: 1em;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f2;

  .ant-card {
    min-width: 300px;
    max-width: 400px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1 1 300px;
    .ant-card-meta {
      flex-wrap: nowrap;
      align-items: center;
      display: flex;
    }
  }
`;

export const InfoIcon = styled(InfoCircleOutlined)`
  color: #595959;
  font-size: 0.75rem;
  margin-left: 0.25rem;
  ${(props) => props.$superscript && `position: absolute;`}
`;
