import React from 'react';

import styled from 'styled-components';

import { s } from 'lib/safe';

const Style = styled.div``;

export function AdsProviderLogo(props: { providerType: 'GENERIC' | 'GOOGLE' | 'FACEBOOK' | 'TIKTOK', style?: Object }) {
  const style = props.style || {};

  switch ((props.providerType || '').toUpperCase()) {
    case 'GOOGLE':
      return (
        <Style style={style}>
          <svg height="100%" width="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#a)">
              <path
                d="M19.245 8.261h-8.158a.652.652 0 0 0-.652.652v2.606c0 .36.292.652.652.652h4.594a6.133 6.133 0 0 1-2.64 3.094L15 18.655c3.142-1.817 5-5.005 5-8.575 0-.508-.037-.871-.112-1.28a.655.655 0 0 0-.643-.539Z"
                fill="#167EE6"
              />
              <path
                d="M10 16.087a6.086 6.086 0 0 1-5.265-3.046l-3.39 1.954A9.987 9.987 0 0 0 15 18.662v-.005l-1.959-3.391a6.041 6.041 0 0 1-3.04.822Z"
                fill="#12B347"
              />
              <path
                d="M15 18.66v-.004l-1.959-3.391a6.041 6.041 0 0 1-3.041.822V20a9.983 9.983 0 0 0 5-1.34Z"
                fill="#0F993E"
              />
              <path
                d="M3.913 10c0-1.108.302-2.145.822-3.04l-3.39-1.955A9.896 9.896 0 0 0 0 10c0 1.819.488 3.526 1.344 4.996l3.391-1.955a6.041 6.041 0 0 1-.822-3.04Z"
                fill="#FFD500"
              />
              <path
                d="M10 3.913c1.466 0 2.813.521 3.865 1.388a.65.65 0 0 0 .874-.04l1.846-1.846a.657.657 0 0 0-.037-.961A9.97 9.97 0 0 0 10 0a9.987 9.987 0 0 0-8.656 5.005l3.391 1.954A6.086 6.086 0 0 1 10 3.913Z"
                fill="#FF4B26"
              />
              <path
                d="M13.864 5.3a.65.65 0 0 0 .875-.039l1.846-1.846a.657.657 0 0 0-.037-.961A9.97 9.97 0 0 0 10 0v3.913c1.466 0 2.813.521 3.864 1.388Z"
                fill="#D93F21"
              />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
              </clipPath>
            </defs>
          </svg>
        </Style>
      );
    case 'FACEBOOK':
      return (
        <Style style={style}>
          <svg height="100%" width="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 10c0 4.991-3.657 9.128-8.438 9.878v-6.987h2.33L14.337 10h-2.774V8.124c0-.79.388-1.562 1.63-1.562h1.261v-2.46s-1.144-.196-2.239-.196c-2.284 0-3.777 1.385-3.777 3.89V10H5.899v2.89h2.54v6.988C3.657 19.128 0 14.991 0 10 0 4.477 4.477 0 10 0s10 4.477 10 10Z"
              fill="#1877F2"
            />
            <path
              d="m13.893 12.89.443-2.89h-2.774V8.124c0-.79.388-1.562 1.63-1.562h1.261v-2.46s-1.144-.196-2.238-.196c-2.285 0-3.777 1.385-3.777 3.89V10h-2.54v2.89h2.54v6.988a10.073 10.073 0 0 0 3.124 0v-6.987h2.33Z"
              fill="#fff"
            />
          </svg>
        </Style>
      );
    case 'TIKTOK':
      return (
        <Style style={style}>
          <svg height="100%" width="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.763 5.015A5.031 5.031 0 0 1 15.723 4a5.043 5.043 0 0 1-2.01-3.984h-3.261v8.91l-.004 4.88a2.951 2.951 0 0 1-3.123 2.945 2.94 2.94 0 0 1-1.35-.414 2.951 2.951 0 0 1-1.442-2.487 2.954 2.954 0 0 1 3.888-2.845v-3.31a6.416 6.416 0 0 0-.94-.07c-1.805 0-3.492.75-4.698 2.101a6.116 6.116 0 0 0-1.543 3.69 6.109 6.109 0 0 0 1.82 4.76 6.253 6.253 0 0 0 4.421 1.81c.317 0 .631-.024.94-.07a6.231 6.231 0 0 0 3.481-1.74 6.104 6.104 0 0 0 1.831-4.345l-.017-7.288a8.243 8.243 0 0 0 5.055 1.718V5.014c.002.001-.008.001-.008.001Z"
              fill="#000"
            />
          </svg>
        </Style>
      );
    case 'GENERIC':
      return (
        <Style style={style}>
          <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#a)" fill="#2E89E2">
              <path d="M19.387 0h-1.784a.612.612 0 1 0 0 1.225h1.172v1.172a.612.612 0 1 0 1.225 0V.612A.612.612 0 0 0 19.387 0ZM19.387 10.785a.612.612 0 0 0-.612.612v1.172h-1.172a.612.612 0 1 0 0 1.225h1.784A.612.612 0 0 0 20 13.18v-1.784a.612.612 0 0 0-.613-.612ZM6.819 3.01a.612.612 0 0 0 .612-.613V1.225h1.172a.612.612 0 1 0 0-1.225H6.819a.612.612 0 0 0-.613.612v1.785c0 .338.274.612.613.612ZM9.572 8.982.3 18.256a1.02 1.02 0 1 0 1.443 1.443l9.273-9.274-.391-1.051-1.052-.392ZM18.11 8.186l-1.356-2.1a.614.614 0 0 1-.077-.49l.643-2.393a.428.428 0 0 0-.525-.525l-2.393.643a.614.614 0 0 1-.491-.077l-2.1-1.356a.428.428 0 0 0-.66.341l-.109 2.47a.613.613 0 0 1-.228.45l-1.952 1.57a.428.428 0 0 0 .12.736l1.543.575c1.217.454.99.226 1.443 1.443l.575 1.543a.428.428 0 0 0 .735.12l1.57-1.952a.613.613 0 0 1 .451-.228l2.47-.11a.428.428 0 0 0 .34-.66Z" />
            </g>
            <defs>
              <clipPath id="a">
                <path fill="#fff" d="M0 0h20v20H0z" />
              </clipPath>
            </defs>
          </svg>
        </Style>
      );
    default:
      return <Style></Style>;
  }
}
