import React, { Fragment } from 'react';

export const PrettyPrintJson = (props: { data: any, hide: boolean }) => {
  if (props.hide) {
    return <Fragment />;
  }

  return (
    <div style={{ maxHeight: 300, overflow: 'auto' }}>
      <pre>{JSON.stringify(props.data, null, 2)}</pre>
    </div>
  );
};
