import './DropDowns.css';

import React, { Component, Fragment } from 'react';

import * as _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { addTag } from 'lib/api';
import axios from 'lib/axios.factory';
import { pixelsFromAccount } from 'lib/pixels';
import { updateAccount } from 'stores/accounts';

import DropDown from './DropDown';
import { DropDownElementText } from './DropDownElementText';
import { Plus } from '../icons';
import Pixel from '../Pixel';

export const DropDownContentAddElt = styled.div`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0.5rem 0.8rem;
  width: 100%;

  .label {
    margin-left: 5px;
  }
`;

export function DomainDropDown({
  domain,
  account,
  onSelect,
  displayLabel = false,
  handleCTA,
}: {
  domain: string,
  account: accountT,
  onSelect: (string) => void,
  displayLabel?: boolean,
  handleCTA?: () => void,
}) {
  const domains = [
    ...account.domains.map((domain) => ({
      id: domain.name,
      value: domain.name,
    })),
  ];

  setTimeout(() => {
    if (domains[0] && (domain === undefined || domain.length === 0)) {
      onSelect(domains[0].id);
    }
  }, 0);

  return (
    <div className="dropdowns__domains">
      <div className="dropdown_inline">
        {displayLabel && (
          <div className="dropdowns__header">
            <label htmlFor="pixel-provider">Select a domain</label>
          </div>
        )}
        <DropDown
          size="medium"
          id="domain"
          placeholder="Select a domain"
          values={domains}
          selected={[domain]}
          onSelect={(selected) => onSelect(selected[0])}
          renderAddItem={() => (
            <Link
              className="dropdowns__add"
              to={`/${account.id}/ab/settings/domains`}
              onClick={() => handleCTA && handleCTA()}
            >
              <DropDownContentAddElt>
                <Plus style={{ width: '12px', height: '12px' }} />
                <span className="label">Add a new domain</span>
              </DropDownContentAddElt>
            </Link>
          )}
        />
      </div>
    </div>
  );
}

export function PixelsDropDown({
  open,
  account,
  pixelsIds,
  displayLabel = false,
  displayCTA = false,
  onSelect,
  handleCTA,
  onClose,
}: {
  account: accountT,
  pixelsIds: Array<string>,
  onSelect: (Array<string>) => void,
  open?: boolean,
  handleCTA?: () => void,
  displayLabel?: boolean,
  displayCTA?: boolean,
  onClose?: () => void,
}) {
  const allPixels = pixelsFromAccount(account);

  return (
    <div className="dropdowns__pixels">
      <div className="dropdown_inline">
        {displayLabel && (
          <div className="dropdowns__header">
            <label htmlFor="pixel-provider">Select your pixel(s)</label>
          </div>
        )}
        <DropDown
          open={open}
          onClose={onClose}
          size="medium"
          id="pixel-provider"
          placeholder="Select pixels"
          values={allPixels}
          selected={pixelsIds}
          multi={true}
          onSelect={onSelect}
          renderTitle={() => {
            if (pixelsIds.length > 1) {
              return `${pixelsIds.length} pixels selected`;
            }

            if (pixelsIds.length > 0) {
              return `${pixelsIds.length} pixel selected`;
            }

            return <span style={{ color: '#DBDBDB' }}>No pixel selected</span>;
          }}
          renderItem={(pixel) => (
            <Fragment>
              <Pixel provider={pixel.providerKey} />
              <DropDownElementText>{pixel.value}</DropDownElementText>
            </Fragment>
          )}
          renderAddItem={() => (
            <Link
              className="dropdowns__add"
              to={`/${account.id}/ab/settings/pixels`}
              onClick={() => handleCTA && handleCTA()}
            >
              <DropDownContentAddElt>
                <Plus style={{ width: '12px', height: '12px' }} />
                <span className="label">Add a new pixel</span>
              </DropDownContentAddElt>
            </Link>
          )}
        />
      </div>
    </div>
  );
}

class TagsDropDown extends Component<
  {
    account: accountT,
    selectedTags: Array<string>,
    onSelect: (Array<string>) => void,
    updateAccount: (account: accountT) => void,
    displayLabel?: boolean,
  },
  {
    adding: boolean,
  },
> {
  constructor(props: {
    open: boolean,
    account: accountT,
    selectedTags: Array<string>,
    onSelect: (Array<string>) => void,
    adding: boolean,
    updateAccount: (account: accountT) => void,
    displayLabel?: boolean,
    onClose?: () => void,
  }) {
    super(props);
    this.state = { newTag: '', adding: false };
  }

  renderTitle(selectedTags) {
    const workingSelectedTags = _.reduce(
      selectedTags,
      (total, tag) => {
        if (tag.length > 0 && !tag.includes('.')) {
          total.push(tag);
        }

        return total;
      },
      [],
    );

    if (workingSelectedTags.length > 1) {
      return `${workingSelectedTags.length} tags selected`;
    }

    if (workingSelectedTags.length > 0) {
      return `${workingSelectedTags.length} tag selected`;
    }

    return <span style={{ color: '#DBDBDB' }}>No tag selected</span>;
  }

  render() {
    const { open, account, selectedTags, displayLabel = false, onSelect, onClose } = this.props;
    const { adding } = this.state;
    const tagsValues = account.tags.map((tag) => ({
      id: tag.name,
      value: tag.name,
    }));

    return (
      <div className="dropdowns__tags">
        <div className="dropdown_inline">
          {displayLabel && (
            <div className="dropdowns__header">
              <label htmlFor="tags">Tags</label>
            </div>
          )}
          <DropDown
            open={open}
            onClose={onClose}
            searchable
            size="medium"
            id="tags"
            placeholder="Select tags"
            values={tagsValues}
            selected={selectedTags}
            multi={true}
            onSelect={onSelect}
            renderTitle={() => this.renderTitle(selectedTags)}
            onAddItem={this.handleAddTag}
            adding={adding}
          />
        </div>
      </div>
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;

    this.setState({
      newTag: value,
    });
  };

  handleAddTag = (tag: string) => {
    const { account, updateAccount, onSelect, selectedTags } = this.props;

    if (account.tags.map((t) => t.name).indexOf(tag) >= 0) {
      if (selectedTags.indexOf(tag) >= 0) {
        return;
      }

      onSelect([...selectedTags, tag]);

      return;
    }

    this.setState({ adding: true });
    addTag(localStorage, axios, { accountId: account.id }, { tag: { name: tag, description: '' } })
      .then(({ account }) => {
        updateAccount(account);
        onSelect([...selectedTags, tag]);
        this.setState({ adding: false, newTag: '' });
      })
      .catch(() => this.setState({ adding: false, newTag: '' }));
  };
}

const TagsDropDownC = connect(() => ({}), { updateAccount })(TagsDropDown);

export { TagsDropDownC as TagsDropDown };
