import React from 'react';

import { DropDownElement } from './DropDownElement';

export function DropDownSearch(props: {
  value: any,
  onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
  adding: boolean,
  addedValue: any,
}) {
  return (
    <>
      <DropDownElement>
        <input autoFocus value={props.value} onChange={props.onChange} className="" placeholder="Search..." />
      </DropDownElement>
      {props.value.length > 0 && !props.adding && (
        <DropDownElement className="drop-down__search-info">
          Press enter to add
          {'\u00A0'}
          <span>{props.value}</span>
        </DropDownElement>
      )}
      {props.adding && (
        <DropDownElement className="drop-down__search-info">
          Adding
          {'\u00A0'}
          <span>{props.addedValue}</span>
          ...
        </DropDownElement>
      )}
    </>
  );
}
