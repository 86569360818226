/**
 * @flow
 */
import React, { Component } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

import Modal from './Modal';

type internalPropsT = {
  actionCancel: any,
  actionSuccess: any,
  config: { open: boolean },
};
type internalStateT = {};
const defaultState: internalStateT = {};

class AbstractModalForRedux extends Component<internalPropsT, internalStateT> {
  state = defaultState;

  render() {
    const { config, cancel, ok, children } = this.props;

    return (
      <>
        {!!config && config.open && (
          <Modal handleCancel={() => cancel()} handleOk={() => ok()} {...this.props}>
            {children}
          </Modal>
        )}
      </>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  form: state.front.addCampaignModalConfig,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  cancel: () => (ownProps.actionCancel ? dispatch(ownProps.actionCancel()) : console.debug('No cancel action')),
  ok: () => (ownProps.actionSuccess ? dispatch(ownProps.actionSuccess()) : console.debug('No success action')),
});

const reduxWrapper = connect(stateToProps, dispatchToProps, null);

export const ModalForRedux = reduxWrapper(AbstractModalForRedux);

export const LargeModalForRedux = styled(ModalForRedux)`
  .modal.box {
    width: 531px !important;
  }
`;
