export const googleLanguages = [
  {
    name: 'Arabic',
    code: 'ar',
    id: 1019,
  },
  {
    name: 'Bengali',
    code: 'bn',
    id: 1056,
  },
  {
    name: 'Bulgarian',
    code: 'bg',
    id: 1020,
  },
  {
    name: 'Catalan',
    code: 'ca',
    id: 1038,
  },
  {
    name: 'Chinese (simplified)',
    code: 'zh_CN',
    id: 1017,
  },
  {
    name: 'Chinese (traditional)',
    code: 'zh_TW',
    id: 1018,
  },
  {
    name: 'Croatian',
    code: 'hr',
    id: 1039,
  },
  {
    name: 'Czech',
    code: 'cs',
    id: 1021,
  },
  {
    name: 'Danish',
    code: 'da',
    id: 1009,
  },
  {
    name: 'Dutch',
    code: 'nl',
    id: 1010,
  },
  {
    name: 'English',
    code: 'en',
    id: 1000,
  },
  {
    name: 'Estonian',
    code: 'et',
    id: 1043,
  },
  {
    name: 'Filipino',
    code: 'tl',
    id: 1042,
  },
  {
    name: 'Finnish',
    code: 'fi',
    id: 1011,
  },
  {
    name: 'French',
    code: 'fr',
    id: 1002,
  },
  {
    name: 'German',
    code: 'de',
    id: 1001,
  },
  {
    name: 'Greek',
    code: 'el',
    id: 1022,
  },
  {
    name: 'Gujarati',
    code: 'gu',
    id: 1072,
  },
  {
    name: 'Hebrew',
    code: 'iw',
    id: 1027,
  },
  {
    name: 'Hindi',
    code: 'hi',
    id: 1023,
  },
  {
    name: 'Hungarian',
    code: 'hu',
    id: 1024,
  },
  {
    name: 'Icelandic',
    code: 'is',
    id: 1026,
  },
  {
    name: 'Indonesian',
    code: 'id',
    id: 1025,
  },
  {
    name: 'Italian',
    code: 'it',
    id: 1004,
  },
  {
    name: 'Japanese',
    code: 'ja',
    id: 1005,
  },
  {
    name: 'Kannada',
    code: 'kn',
    id: 1086,
  },
  {
    name: 'Korean',
    code: 'ko',
    id: 1012,
  },
  {
    name: 'Latvian',
    code: 'lv',
    id: 1028,
  },
  {
    name: 'Lithuanian',
    code: 'lt',
    id: 1029,
  },
  {
    name: 'Malay',
    code: 'ms',
    id: 1102,
  },
  {
    name: 'Malayalam',
    code: 'ml',
    id: 1098,
  },
  {
    name: 'Marathi',
    code: 'mr',
    id: 1101,
  },
  {
    name: 'Norwegian',
    code: 'no',
    id: 1013,
  },
  {
    name: 'Persian',
    code: 'fa',
    id: 1064,
  },
  {
    name: 'Polish',
    code: 'pl',
    id: 1030,
  },
  {
    name: 'Portuguese',
    code: 'pt',
    id: 1014,
  },
  {
    name: 'Romanian',
    code: 'ro',
    id: 1032,
  },
  {
    name: 'Russian',
    code: 'ru',
    id: 1031,
  },
  {
    name: 'Serbian',
    code: 'sr',
    id: 1035,
  },
  {
    name: 'Slovak',
    code: 'sk',
    id: 1033,
  },
  {
    name: 'Slovenian',
    code: 'sl',
    id: 1034,
  },
  {
    name: 'Spanish',
    code: 'es',
    id: 1003,
  },
  {
    name: 'Swedish',
    code: 'sv',
    id: 1015,
  },
  {
    name: 'Tamil',
    code: 'ta',
    id: 1130,
  },
  {
    name: 'Telugu',
    code: 'te',
    id: 1131,
  },
  {
    name: 'Thai',
    code: 'th',
    id: 1044,
  },
  {
    name: 'Turkish',
    code: 'tr',
    id: 1037,
  },
  {
    name: 'Ukrainian',
    code: 'uk',
    id: 1036,
  },
  {
    name: 'Urdu',
    code: 'ur',
    id: 1041,
  },
  {
    name: 'Vietnamese',
    code: 'vi',
    id: 1040,
  },
];
