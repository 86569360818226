import { Statistic, Table, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { TooltipTableHeader } from 'components/common/TooltipTableHeader';
import { AmazonWhite } from 'components/layout/Bar/Items/AmazonWhite';
import { strings } from 'locales/strings';

import {
  STRATEGIES,
  STRATEGIES_LABELS,
  calculateAmazonSearchVolume,
  calculateGoogleSearchVolume,
  stringSorter,
} from './constants';
import { OpportunityBadge } from './OpportunityBadge';

const TableContainer = styled.div`
  height: calc(100vh - 105px - 4rem);
  overflow: auto;
  .ant-table-sticky-scroll {
    display: none;
  }
  thead {
    user-select: none;
  }
`;

const ProductLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  > img,
  > svg {
    min-width: 32px;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
  > svg {
    padding: 0.25rem;
  }
`;

const OpportunityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AuditPreviewTable = ({ data, error, isLoading }) => {
  const { messages } = useIntl();

  const columns = [
    {
      title: messages.asinAudit.product.title,
      dataIndex: ['product', 'title'],
      fixed: 'left',
      width: 300,
      render: (val, record) => (
        <ProductLink href={record.product.url} target="_blank" rel="noreferrer">
          {record.product.imageUrl ? <img src={record.product.imageUrl} alt={val} /> : <AmazonWhite />}
          <Tooltip title={val}>
            <span className="ellipsis">{val}</span>
          </Tooltip>
        </ProductLink>
      ),
      sorter: stringSorter(['product', 'title']),
    },
    {
      title: (
        <TooltipTableHeader
          title={messages.asinAudit.product.strategy}
          tooltip={messages.asinAudit.preview.strategyTooltip}
        />
      ),
      dataIndex: 'strategy',
      width: 150,
      align: 'center',
      render: (val) => STRATEGIES_LABELS[val] || 'N/A',
      sorter: stringSorter('strategy'),
    },
    {
      title: (
        <TooltipTableHeader
          title={messages.asinAudit.keywords.amazonSearchVolume}
          tooltip={messages.asinAudit.preview.asvTooltip}
        />
      ),
      key: 'amazonSearchVolume',
      width: 150,
      align: 'center',
      render: (v) => <Statistic value={calculateAmazonSearchVolume(v.keywords)} precision={0} />,
      sorter: (a, b) => calculateAmazonSearchVolume(a.keywords) - calculateAmazonSearchVolume(b.keywords),
    },
    {
      title: (
        <TooltipTableHeader
          title={messages.asinAudit.keywords.googleSearchVolume}
          tooltip={messages.asinAudit.preview.gsvTooltip}
        />
      ),
      key: 'googleSearchVolume',
      width: 150,
      align: 'center',
      render: (v) => <Statistic value={calculateGoogleSearchVolume(v.keywords)} precision={0} />,
      sorter: (a, b) => calculateGoogleSearchVolume(a.keywords) - calculateGoogleSearchVolume(b.keywords),
    },
    {
      title: (
        <TooltipTableHeader
          title={messages.asinAudit.opportunityKeywords}
          tooltip={messages.asinAudit.preview.oppKeywordsTooltip}
        />
      ),
      key: 'opportunityKeywords',
      width: 150,
      align: 'center',
      render: (v) => <Statistic value={v.keywords?.length} precision={0} />,
      sorter: (a, b) => a.keywords.length - b.keywords.length,
    },
    {
      title: (
        <TooltipTableHeader
          title={messages.asinAudit.impactScore}
          tooltip={messages.asinAudit.preview.impactScoreTooltip}
        />
      ),
      key: 'opportunity',
      width: 150,
      align: 'center',
      render: (v, record) => (
        <OpportunityContainer>
          <OpportunityBadge score={record.strategy === STRATEGIES.PROFIT ? v?.score?.profit : v?.score?.ranking} />
        </OpportunityContainer>
      ),
    },
  ];

  return (
    <TableContainer>
      <Table
        dataSource={data}
        locale={{
          emptyText: error || (isLoading && messages.asinAudit.loading) || messages.asinAudit.preview.noData,
        }}
        columns={columns}
        bordered
        pagination={false}
        scroll={{ x: 'min-content' }}
        showSorterTooltip={false}
        sticky
        tableLayout="fixed"
        rowKey={(record) => record.product.asin}
      />
    </TableContainer>
  );
};
