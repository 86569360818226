import { Actions } from 'actions';
import { selectProductProvider, selectCurrentAccount, updateAccountsUsageThunk } from 'stores/accounts';
import 'lib/array';
import { redirectColumnsConfig } from 'config/AudienceBuilder/redirectColumns.config';

export default (store: any) => (next: any) => async (action: any) => {
  next(action);
  switch (action.type) {
    case Actions.front.audienceBuilder.campaigns.columns.update.request().type: {
      const defaultColumns = redirectColumnsConfig(
        selectCurrentAccount(store.getState()),
        selectProductProvider(store.getState()),
        store.getState().accounts.limitations,
      );

      if (
        defaultColumns.length !== store.getState().front.audienceBuilder.campaigns.columnsConfig.availableColumns.length
      ) {
        store.dispatch(action.workerAction.success(defaultColumns));
      }

      break;
    }

    case Actions.front.audienceBuilder.campaigns.select.request().type:
      // init the paginator
      store
        .dispatch(Actions.api.audienceBuilder.redirect.loadPrev.success({ start: 0 }))
        .then(() => store.dispatch(Actions.api.audienceBuilder.redirect.load.request()));
      break;
    case 'SWITCH_ACCOUNT':
      store.dispatch(Actions.front.audienceBuilder.campaigns.columns.update.request());
      store.dispatch(updateAccountsUsageThunk());
      break;

    default:
      break;
  }
};
