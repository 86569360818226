import './PageBuilderContent.css';
import React, { useCallback, useEffect } from 'react';

import { Spinner } from '@blueprintjs/core';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

import { Appearance } from './Appearance/Appearance';
import { PageBlocksEditor } from './PageBlocks/PageBlocksEditor';
import { PageBlocksPreview } from './PageBlocksPreview';
import { PageLink } from './PageLink';
import { PageLinkEditor } from './PageLink/PageLinkEditor';
import { PageSetting } from './PageSetting';
import { usePage } from './usePage.hook';
import { UsernameEditor } from './UsernameEditor';
import { Actions } from '../../../actions';
import { apiCall } from '../../../lib/api';
import { replacePathUsername } from '../../../lib/url';

const Style = styled.div`
  display: grid;
  grid-template:
    'builder  link'
    'builder  preview';
  grid-template-columns: 1fr 408px;
  grid-template-rows: auto calc(100% - 110px);
  column-gap: 10px;
  row-gap: 10px;
  overflow-y: hidden;
  height: 100%;
`;

export function PageBuilderContent({ account, match }: { account: accountT, match: Match }) {
  const [previewState, updatePreviewState] = useImmer({
    previewUrl: undefined,
  });

  const editRedirect = useSelector((state) => state.front.abComposerModalConfig.redirect);

  const [redirectState, updateRedirectState] = useImmer({
    redirect: undefined,
  });

  function handleRefresh() {
    if (redirectState.redirect) {
      updatePreviewState((draft) => {
        draft.previewUrl = `${`${redirectState.redirect.url}?hash=${new Date().getTime()}`}`;
      });
    }
  }

  const debouncedHandleRefresh = useCallback(debounce(handleRefresh, 500), [
    JSON.stringify(updatePreviewState),
    JSON.stringify(redirectState.redirect),
  ]);

  const { savePage, loadPage, state, updateState } = usePage(account.id, debouncedHandleRefresh);

  const dispatch = useDispatch();

  function handleEditRedirect(redirect) {
    dispatch(
      Actions.api.audienceBuilder.redirect.edit.request({ redirect }),
      Actions.front.audienceBuilder.redirect.edit.show.request({ redirect }),
    );
  }

  const debouncedSavePage = useCallback(debounce(savePage, 500), []);

  const debouncedSaveSettingPage = useCallback(
    debounce(async (page) => {
      if (page.username && page.username.length !== 0) {
        const newUrl = replacePathUsername(redirectState.redirect.url, page.username);

        const redirect = {
          ...redirectState.redirect,
          title: page.title ? page.title : page.username,
          // local test
          // url: `${"http://localhost:5000/#" + page.username}`
          // trackUrl: result,
          url: newUrl,
        };

        // fonction de test
        await savePage(page);
        handleEditRedirect(redirect);
        updateRedirectState((draft) => {
          draft.redirect = redirect;
        });
      }
    }, 500),
    [redirectState],
  );

  const url = redirectState.redirect ? redirectState.redirect.url : '';

  useEffect(() => {
    if (!state.page) {
      loadPage().then();
    }

    debouncedHandleRefresh();
  }, [JSON.stringify(state.page), url]);

  // reload after edit
  useEffect(() => {
    if (state.page && !redirectState.redirect && !redirectState.loading) {
      loadRedirect().then();
    }
  }, [JSON.stringify(editRedirect), JSON.stringify(state.page)]);

  if (state.userNameError) {
    return <UsernameEditor savePage={savePage} loadPage={loadPage}></UsernameEditor>;
  }

  if (!state.page || !redirectState.redirect) {
    return <Spinner />;
  }

  if (!previewState.previewUrl) {
    debouncedHandleRefresh();
  }

  async function loadRedirect() {
    updateRedirectState((draft) => {
      draft.loading = true;
    });

    const filter = {
      accountId: account.id,
      redirectsIds: [state.page.redirectId],
    };

    try {
      const response = await apiCall({
        localStorage,
        axios,
        secure: true,
        method: 'get',
        url: 'ab/redirects-by-ids',
        params: { ...filter },
      });

      updateRedirectState((draft) => {
        draft.redirect = response.redirects[0];
      });
    } finally {
      updateRedirectState((draft) => {
        draft.loading = false;
      });
    }
  }

  // fonction de test

  // async function updateRedirect(data) {
  //   updateRedirectState((draft) => {
  //     draft.loading = true;
  //   });

  //   const filter = {
  //     accountId: account.id,
  //     redirectsIds: [state.page.redirectId]
  //   };

  //   try {
  //     const fbLoginResponse = await apiCall({
  //       localStorage,
  //       axios,
  //       secure: true,
  //       method: "patch",
  //       url: "ab/redirect",
  //       data: {
  //         redirectId: data.id,
  //         pixelsIds: data.pixels,
  //         tags: data.tags,
  //         domain: data.domain,
  //         title: data.title,
  //         url: data.url,
  //         slug: data.slug,
  //         campaignId: data.campaignId,
  //         subCampaignId: data.subCampaignId,
  //       },
  //       params: { ...filter }
  //     });
  //     updateRedirectState((draft) => {
  //       draft.redirect = fbLoginResponse.redirect;
  //     });
  //   } catch (e) {
  //     console.log("error de ouf", e.errorMessage)
  //   } finally {
  //     updateRedirectState((draft) => {
  //       draft.loading = false;
  //     });
  //   }
  // }

  return (
    <div className="content" style={{ display: 'block' }}>
      <Switch>
        <Route
          path={`${match.url}/analytics`}
          render={(props) => (
            <Style>
              <Redirect to={`/${account.id}/ab/analytics/l/${state.page.redirectId}`}></Redirect>
            </Style>
          )}
        />

        <Route
          path={`${match.url}/page-blocks`}
          render={(props) => (
            <Style>
              <PageBlocksEditor
                account={account}
                state={state}
                savePage={debouncedSavePage}
                updateState={updateState}
              />
              <PageLink redirect={redirectState.redirect} state={state} updateState={updateState} />
              <PageBlocksPreview previewUrl={previewState.previewUrl} />
              <PageLinkEditor />
            </Style>
          )}
        />
        <Route
          path={`${match.url}/appearance`}
          render={(props) => (
            <Style>
              <Appearance account={account} savePage={debouncedSavePage} updateState={updateState} state={state} />
              <PageLink account={account} redirect={redirectState.redirect} state={state} updateState={updateState} />
              <PageBlocksPreview previewUrl={previewState.previewUrl} />
              <PageLinkEditor />
            </Style>
          )}
        />
        <Route
          path={`${match.url}/settings`}
          render={(props) => (
            <Style>
              <PageSetting state={state} savePage={debouncedSaveSettingPage} updateState={updateState} />
              <PageBlocksPreview previewUrl={previewState.previewUrl} />
              <PageLink redirect={redirectState.redirect} state={state} updateState={updateState} />
            </Style>
          )}
        />
      </Switch>
    </div>
  );
}
