import { type Node } from 'react';

import { message, Popconfirm } from 'antd';

import { Delete } from 'components/common/icons';
import { useLoading } from 'hooks/useLoading';
import { useDeleteAdGroupMutation } from 'stores/adGroupsSlice';

import { IconInsideButton } from '../../../AudienceBuilder/Campaigns/campaignTree/Common';

export const DeleteAdGroup = ({ id }: { id: string }): Node => {
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(false);
  const [deleteAdGroup] = useDeleteAdGroupMutation();

  return (
    <ExclusiveInlineLoadingContainer>
      <Popconfirm
        title="Are you sure to want to delete"
        onConfirm={async () => {
          doAction(async () => {
            try {
              await deleteAdGroup(id).unwrap();
              message.success('Ad group deleted');
            } catch (e) {
              message.error('Unable to delete ad group');
            }
          });
        }}
      >
        <div>
          <IconInsideButton>
            <Delete />
          </IconInsideButton>
        </div>
      </Popconfirm>
    </ExclusiveInlineLoadingContainer>
  );
};
