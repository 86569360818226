import { useState, type Node } from 'react';

import { useIntl } from 'react-intl';
import type { Match } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { BigSubMenu } from 'components/layout/BigSubMenu';
import { MenuItem } from 'components/layout/MenuItem';
import { C6_NAVBAR_HEIGHT } from 'consts/consts';
import { ASIN_AUDIT_MANAGE, useFeatureFlag } from 'lib/configcat';
import { strings } from 'locales/strings';

import { AuditHistoryView } from './AuditHistoryView.jsx';
import { AuditResultsView } from './AuditResultsView';
import { CONTAINER_PADDING } from './constants';
import { NewAuditView } from './NewAuditView';

const Style = styled.div`
  background: #fafafa;
  border-top: 1px solid #f2f2f2;
  height: calc(100% - ${C6_NAVBAR_HEIGHT}px);
  display: grid;
  grid-template-rows: max-content 1fr;
  grid-template-areas:
    'tabs'
    'content';
`;

const TabsContainer = styled.div`
  grid-area: tabs;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  background-color: #fff;

  .content__submenu > ul a {
    font-size: 14px;
    font-weight: 500;
    border-bottom-width: 2px;
    height: 48px;
    border: none;
    &.current {
      padding: 0;
    }
  }
  .content__submenu > ul > li {
    margin: 0;
    padding: 0 1.5rem;
    &:has(.current) {
      border-bottom: 2px solid var(--pixelme-color);
    }
  }
`;

const ContentContainer = styled.div`
  grid-area: content;
  padding: ${CONTAINER_PADDING}px;
  overflow: auto;
  height: 100%;
`;

export const AsinAuditPage = ({ match }: { match: Match }): Node => {
  const [isAsinAuditEnabled, isFlagLoading] = useFeatureFlag(ASIN_AUDIT_MANAGE);
  const [persistedAuditId, setPersistedAuditId] = useState();
  const { messages } = useIntl();

  if (isFlagLoading) {
    return null;
  } else if (!isAsinAuditEnabled) {
    return <Redirect to="/" />;
  }

  return (
    <Style>
      <TabsContainer>
        <BigSubMenu>
          <MenuItem label={messages.asinAudit.newAudit.title} to={`${match.url}/new`} />
          <MenuItem label={messages.asinAudit.auditResults.title} to={`${match.url}/results`} />
          <MenuItem label={messages.asinAudit.auditHistory.title} to={`${match.url}/history`} />
        </BigSubMenu>
      </TabsContainer>

      <ContentContainer>
        <Switch>
          <Route path={`${match.url}/new`}>
            <NewAuditView />
          </Route>
          <Route path={`${match.url}/results`}>
            <AuditResultsView persistedAuditId={persistedAuditId} setPersistedAuditId={setPersistedAuditId} />
          </Route>
          <Route path={`${match.url}/history`}>
            <AuditHistoryView />
          </Route>
          <Redirect to={`${match.url}/new`} />
        </Switch>
      </ContentContainer>
    </Style>
  );
};
