import _ from 'lodash';
import numeral from 'numeral';

import { s } from 'lib/safe';
import { getIntlStrings } from 'locales/i18n/localeHelpers';

export function isVisible(props) {
  return (
    !props.config ||
    props.forceDisplay ||
    !props.config.selectedColumns ||
    !props.config.selectedColumns.length ||
    props.config.selectedColumns.indexOf(props.name) !== -1
  );
}

// Automatically choose correct currency, and format it.
export function handleSpent(eurSpent, usdSpent) {
  if (eurSpent && eurSpent !== 0) {
    return `${numeral(eurSpent).format('0,0.00')}€`;
  } else if (usdSpent && usdSpent !== 0) {
    return `$${numeral(usdSpent).format('0,0.00')}`;
  } else {
    return '-';
  }
}

export function isSortedAsc(sort: string) {
  if (!sort) {
    return false;
  }

  if (typeof sort === 'string') {
    return !sort.startsWith('-');
  } else {
    // sorterion
    sort[0] = sort[0] || { direction: '' };

    return sort[0].direction.toLowerCase().indexOf('asc') !== -1;
  }
}

export function isSortedBy(columnName: string, sort: string) {
  if (!columnName) {
    throw new Error("Can't detect sortedBy on empty column name");
  }

  if (!sort) {
    return false;
  }

  if (!columnName) {
    throw new Error("Can't detect sortedBy on empty column name");
  }

  if (!sort) {
    return false;
  }

  let sort2 = sort;

  if (typeof sort === 'string') {
    if (sort.startsWith('-')) {
      sort2 = sort.substring(1, sort.length);
    }
  } else {
    // sorterion
    sort2 = s(sort[0]).column;
  }

  const events = /^[-+]?(events)?\[?'([^[\]]*)'\]?$/gm.exec(sort) || ['', '', sort];

  return events[2] === columnName || sort2 === columnName;
}

export function isSortedByColumnAndAsc(columnName: string, sort: string) {
  return isSortedBy(columnName, sort) && isSortedAsc(sort);
}

export function isSortedByColumnAndDesc(columnName: string, sort: string) {
  return isSortedBy(columnName, sort) && !isSortedAsc(sort);
}

/**
 * Toggle sort column
 * @param columnName
 * @param lastSort
 * @returns {string}
 */
export function toggleSortForStringBasedSort(columnName: string, lastSort: string) {
  if (!columnName) {
    return '';
  }

  let sort: string;

  if (lastSort && isSortedBy(columnName, lastSort)) {
    if (isSortedAsc(lastSort)) {
      sort = `-${columnName}`;
    } else {
      sort = columnName;
    }
  } else {
    if (columnName === 'clicks' || columnName.startsWith('events') || columnName.startsWith('adsData')) {
      sort = `-${columnName}`;
    } else {
      sort = columnName;
    }
  }

  return sort;
}

export function getColumnPosition(props) {
  if (props.config && props.config.availableColumns) {
    const currentColumn = _.find(props.config.availableColumns, (column) => column.id === props.name);

    if (currentColumn) {
      return currentColumn.position;
    }
  }

  if (props.name === 'Drag' || props.name === 'Check') {
    return -1;
  }

  if (props.name === 'Title') {
    return 0;
  }

  return 100;
}

export function getGridTemplate(length: number) {
  return `repeat(${length}, min-content)`;
}

/**
 * Return display name for group tab
 * @param view
 * @returns {string}
 */
export function getViewDisplayName(view) {
  return getIntlStrings(`conversions.nameColumnName.${view}`);
}
