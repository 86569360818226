import React from 'react';

import { Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { Repeat } from 'components/common/icons';
import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';

import type { adsCampaignT } from '../../../../../../flow-typed/pixelme/adsPlateform';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';

export function CloneCampaign(props: { account: accountT, campaign: adsCampaignT }) {
  const history = useHistory();

  return (
    <IconInsideButton
      onClick={() =>
        history.push(
          `/${props.account.id}/adsprovider/new-${props.campaign.providerType.toLowerCase()}-campaign/${
            props.campaign.id
          }`,
        )
      }
    >
      <Tooltip title="Clone Campaign" placement="bottom">
        <Repeat />
      </Tooltip>
    </IconInsideButton>
  );
}
