import React from 'react';

import * as _ from 'lodash';

import { Objective } from 'components/common/icons/Objective';
import { updateUrlFromUtms } from 'lib/utms';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { handleChangeUrl } from './composerEditUtils';
import { EditRedirectCTATooltip } from '../../../../../common/tooltip';
import { InputWithLabel, LockedInputWithLabel } from '../InputWithLabel';

export function ComposerEditUrl(props) {
  const { redirect, limitations } = props;
  const isEditMode = redirect && redirect.id;

  const prefixUrl = (value) => {
    if (!_.startsWith(value, `http://`) && !_.startsWith(value, `https://`)) {
      value = `https://${value}`;
    }

    return value;
  };

  if (redirect.url === undefined) {
    redirect.url = '';
  }

  if (isEditMode && !limitations.allowEditRedirect) {
    return (
      <div className="composer__url locked">
        <EditRedirectCTATooltip>
          <LockedInputWithLabel
            name="url"
            displayLabel
            disabled
            isBig
            value=""
            title={getIntlStrings('redirect.url.input.title')}
            placeholder={redirect.url}
          />
        </EditRedirectCTATooltip>
      </div>
    );
  }

  return (
    <div className="composer__url">
      <InputWithLabel
        name="url"
        displayLabel
        isBig
        renderIcon={() => <Objective />}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          handleChangeUrl(e.target, props);
        }}
        onBlur={(e) => {
          e.target.value = prefixUrl(e.target.value);
          handleChangeUrl(e.target, props);
        }}
        value={updateUrlFromUtms({
          url: redirect.url,
          utms: redirect.utms,
        })}
        title={getIntlStrings('redirect.url.input.title')}
        placeholder={getIntlStrings('redirect.url.input.placeholder')}
      />
    </div>
  );
}
