import { Select } from 'antd';

import { getIntlStrings } from 'locales/i18n/localeHelpers';

const PERFORMANCE_GOALS = {
  OFFSITE_CONVERSIONS: 'OFFSITE_CONVERSIONS',
  VALUE: 'VALUE',
  LINK_CLICKS: 'LINK_CLICKS',
};

const options = [
  {
    label: getIntlStrings('facebook.performanceGoals.offsiteConversions'),
    value: PERFORMANCE_GOALS.OFFSITE_CONVERSIONS,
  },
  // { label: strings.get('facebook.performanceGoals.value'), value: PERFORMANCE_GOALS.VALUE }, Disabled for now
  { label: getIntlStrings('facebook.performanceGoals.linkClicks'), value: PERFORMANCE_GOALS.LINK_CLICKS },
];

export const FacebookPerformanceGoalSelector = (props) => (
  <Select
    size="large"
    placeholder={getIntlStrings('facebook.performanceGoals.placeholder')}
    {...props}
    defaultValue={props.defaultGoal ? PERFORMANCE_GOALS.LINK_CLICKS : PERFORMANCE_GOALS.OFFSITE_CONVERSIONS}
  >
    {options.map((option) => (
      <Select.Option
        key={option.value}
        value={option.value}
        disabled={!!(props.defaultGoal && option.value === PERFORMANCE_GOALS.OFFSITE_CONVERSIONS)}
      >
        {option.label}
      </Select.Option>
    ))}
  </Select>
);
