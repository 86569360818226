import { EyeOutlined } from '@ant-design/icons';
import { Image } from 'antd';

import { AmazonWhite } from 'components/layout/Bar/Items/AmazonWhite';

export const ProductImage = ({ src, width, height, ...props }) => {
  if (!src) {
    return <AmazonWhite style={{ width, height, objectFit: 'contain', padding: '0.25rem' }} />;
  }

  return (
    <Image
      src={src}
      width={width}
      height={height}
      preview={{ mask: <EyeOutlined /> }}
      style={{ objectFit: 'contain' }}
      {...props}
    />
  );
};
