import _ from 'lodash';

import { apiSlice, TAGS } from './apiSlice';

const { AUDITS } = TAGS;

// $FlowFixMe
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAudits: builder.query({
      query: () => ({ url: '/audits', method: 'get' }),
      extraOptions: { includeAccountId: false },
      transformResponse: (res) => _.orderBy(res.items, ['createdAt'], ['desc']),
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: AUDITS, id })), { type: AUDITS, id: 'LIST' }]
          : [{ type: AUDITS, id: 'LIST' }],
    }),
    getAudit: builder.query({
      query: (id) => ({ url: `/audits/${id}`, method: 'get' }),
      extraOptions: { includeAccountId: false },
      providesTags: (result, error, id) => [{ type: AUDITS, id }],
    }),
    getPublicAudit: builder.query({
      query: (id) => ({ url: `/public/audits/${id}`, method: 'get' }),
      extraOptions: { secure: false, includeAccountId: false },
    }),
    createAudit: builder.mutation({
      query: (data) => ({ url: '/audits', method: 'post', data }),
      extraOptions: { includeAccountId: false },
      invalidatesTags: [{ type: AUDITS, id: 'LIST' }],
    }),
    updateAudit: builder.mutation({
      query: ({ id, ...data }) => ({ url: `/audits/${id}`, method: 'patch', data }),
      extraOptions: { includeAccountId: false },
      invalidatesTags: (result, error, { id }) => [{ type: AUDITS, id }],
    }),
    sendAudit: builder.mutation({
      query: ({ id, ...data }) => ({ url: `/audits/${id}/send`, method: 'post', data }),
      extraOptions: { includeAccountId: false },
    }),
    updateAuditKeywords: builder.mutation({
      query: ({ id, asin, ...data }) => ({ url: `/audits/${id}/${asin}/keywords`, method: 'patch', data }),
      extraOptions: { includeAccountId: false },
      invalidatesTags: (result, error, { id }) => [{ type: AUDITS, id }],
    }),
  }),
});

export const {
  useGetAuditsQuery,
  useGetAuditQuery,
  useGetPublicAuditQuery,
  useCreateAuditMutation,
  useUpdateAuditMutation,
  useSendAuditMutation,
  useUpdateAuditKeywordsMutation,
} = extendedApiSlice;
