import _ from 'lodash';

import { store } from 'stores';

import { s, sArray } from './safe';
import {
  ALL_REDIRECTS,
  ALL_REDIRECTS_FOR_CAMPAIGN,
  UNCLASSIFIED_REDIRECTS,
  UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN,
} from '../consts/consts';

export function isARealCampaign(campaign: campaignT | subCampaignT) {
  return campaign !== undefined && campaign.id !== ALL_REDIRECTS && campaign.id !== UNCLASSIFIED_REDIRECTS;
}

export function isARealCampaignId(campaignId: string) {
  return campaignId !== undefined && campaignId !== ALL_REDIRECTS && campaignId !== UNCLASSIFIED_REDIRECTS;
}

export function fillSubCampaignsWithAllAndNone(campaigns) {
  const allCampaignsData = {
    id: ALL_REDIRECTS,
    subCampaigns: [{ id: ALL_REDIRECTS_FOR_CAMPAIGN }],
  };
  const campaignsToLoadStats = [];

  campaignsToLoadStats.push(allCampaignsData);
  campaigns.forEach((campaign: campaignT) => {
    const subCampaignsData = [];

    campaign.subCampaigns = campaign.subCampaigns || [];
    campaign.subCampaigns.forEach((subcampaign: subCampaignT) => {
      subCampaignsData.push({ id: subcampaign.id });
    });
    subCampaignsData.push({ id: ALL_REDIRECTS_FOR_CAMPAIGN });

    if (campaign.id !== ALL_REDIRECTS) {
      subCampaignsData.push({
        id: UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN,
      });
    }

    const campaignData = {
      id: campaign.id,
      subCampaigns: subCampaignsData,
    };

    campaignsToLoadStats.push(campaignData);
  });

  return campaignsToLoadStats;
}

export function updateCampaignStats(campaigns: campaignT[], stats: campaignStatsT[]): campaignT[] {
  const result = [...campaigns];

  for (const stat of sArray(stats)) {
    let applied = false;

    result
      .filter((campaign) => stat.campaignId === campaign.id)
      .forEach((campaign) => {
        if (stat.subCampaignId) {
          campaign.subCampaigns
            .filter((subCampaign) => stat.subCampaignId === subCampaign.id)
            .forEach((subCampaign) => {
              subCampaign.stats = { ...campaign.stats, ALL: stat };
              applied = true;
            });
        }

        if (
          stat.campaignId === campaign.id &&
          (stat.subCampaignId === ALL_REDIRECTS_FOR_CAMPAIGN || !stat.subCampaignId)
        ) {
          campaign.stats = { ...campaign.stats, ALL: stat };
          applied = true;
        }

        if (stat.campaignId === campaign.id && stat.subCampaignId === UNCLASSIFIED_REDIRECTS_INTO_SUBCAMPAIGN) {
          campaign.stats = { ...campaign.stats, NONE: stat };
          applied = true;
        }
      });

    if (!applied) {
      console.error('Unable to apply stat ', stat, campaigns);
    }
  }

  return result;
}

export function getSelectedCampaignStats(state: stateT): campaignT {
  const campaignStats = getCampaignFromId(state.campaigns.selectedCampaignId, state.campaigns).stats;

  const subCampaignStats = getSubCampaignFromId(state.campaigns.selectedSubCampaignId, state.campaigns).stats;
  const { selectedSubCampaignId } = state.campaigns;

  return (
    s(subCampaignStats).ALL ||
    (selectedSubCampaignId === ALL_REDIRECTS_FOR_CAMPAIGN ? s(campaignStats).ALL : s(campaignStats).NONE)
  );
}

export function getCampaignFromId(id: string, state: stateT): campaignT {
  state = state || store.getState().campaigns;
  const { campaigns } = state;

  if (campaigns) {
    return campaigns.find((campaign) => campaign.id === id) || {};
  } else {
    return {};
  }
}

export function getSubCampaignFromId(id: string, state: stateT): campaignT {
  state = state || store.getState().campaigns;
  const { campaigns } = state;

  if (campaigns && typeof campaigns.flatMap === 'function') {
    return campaigns.flatMap((campaign) => campaign.subCampaigns).find((subCampaign) => subCampaign.id === id) || {};
  } else {
    return {};
  }
}

// TOTO TU
export function getOldCampaignStats(campaignId: string, state: stateT) {
  const campaign = getCampaignFromId(campaignId, state);

  if (campaign) {
    return campaign.stats;
  } else {
    return undefined;
  }
}

// TOTO TU
export function getOldSubCampaignStats(subCampaignId: string, state: stateT) {
  const subCampaign = getSubCampaignFromId(subCampaignId, state);

  if (subCampaign) {
    return subCampaign.stats;
  } else {
    return undefined;
  }
}

export function getStats(campaignId: string, subCampaignId: string, state: stateT) {
  let stats;

  if (subCampaignId) {
    stats = s(
      s(
        s(
          _.flatMap(state.campaigns.campaigns, (curCampaign) => curCampaign.subCampaigns).find(
            (curCampaign) => curCampaign.id === subCampaignId,
          ),
        ).stats,
      ).ALL,
    );
  } else if (campaignId) {
    stats = s(s(s(state.campaigns.campaigns.find((curCampaign) => curCampaign.id === campaignId)).stats).ALL);
  }

  return stats;
}
