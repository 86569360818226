import React, { Fragment, useEffect, useState } from 'react';

import { Transfer, Tree } from 'antd';
import Search from 'antd/lib/input/Search';
import { TransferDirection } from 'antd/lib/transfer';
import _, { debounce } from 'lodash';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import { ChangeEventExtra } from 'rc-tree-select/lib/interface';
import type { ValueType } from 'react-select/src/types';
import styled from 'styled-components';

import { FormInputGroup } from '../../../../common/antd/FormInputGroup';
import { SmallCompactPlaceholder } from '../../../../common/Placeholders';
import { deepFilterOnTree } from '../buildTree';

const Style = styled.div`
  .ant-tree-indent-unit {
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    height: 29px;
  }

  .ant-transfer-list-header {
    display: none;
  }

  .ant-btn {
    min-width: 0 !important;
  }
`;

/**
 * Tiktok value selector
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TiktokGenericSelector(props: {
  account: any,
  value: any,
  onChange: any,
  defaultValue: any,
  adsProvider: adsProviderT,
  treeData: any[],
  flatData: any[],
}) {
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [treeData, setTreeData] = useState(props.treeData);

  const isChecked = (selectedKeys, eventKey) => selectedKeys.indexOf(eventKey) !== -1;

  async function onChange(targetKeys: string[], direction: TransferDirection, moveKeys: string[]) {
    props.onChange(_.uniq(targetKeys));
  }

  const debouncedOnChange = debounce(onChange, 100);

  async function load() {
    setLoading(true);
    setLoading(false);
  }

  function search(searchValue) {
    if (!searchValue) {
      setTreeData(props.treeData);
    } else {
      const filteredTreeData = deepFilterOnTree(props.treeData, 'title', (o) =>
        o.title.toLowerCase().startsWith(searchValue.toLowerCase()),
      );

      setTreeData(filteredTreeData);
    }
  }
  const debouncedSearch = debounce(search, 500);

  useEffect(() => {
    load().then();
  }, []);

  return (
    <Style>
      <FormInputGroup>
        <Transfer
          style={{ width: '100%' }}
          listStyle={{ minWidth: '150px' }}
          showSelectAll={false}
          className="tree-transfer"
          targetKeys={props.value}
          onChange={debouncedOnChange}
          render={(item) => <Fragment>{_.get(item, 'title') || _.get(item, 'key') || '-'}</Fragment>}
          dataSource={props.flatData}
        >
          {({ direction, onItemSelect, selectedKeys }) => {
            const checkedKeys = [...selectedKeys];

            if (direction === 'left') {
              return (
                <Fragment>
                  <Search
                    style={{ marginBottom: 8 }}
                    placeholder="Search"
                    onSearch={debouncedSearch}
                    onChange={(e) => {
                      debouncedSearch(e.target.value);
                    }}
                  />
                  <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                    {loading && <SmallCompactPlaceholder />}
                    {!loading && (
                      <Tree
                        checkable
                        loading={loading}
                        allowClear={true}
                        showAction="click"
                        checkedKeys={checkedKeys}
                        onExpand={(
                          expandedKeys: Key[],
                          info: {
                            node: EventDataNode,
                            expanded: boolean,
                            nativeEvent: MouseEvent,
                          },
                        ) => {
                          setExpandedKeys(expandedKeys);
                        }}
                        onCheck={(_, { node: { key } }) => {
                          onItemSelect(key, !isChecked(checkedKeys, key));
                        }}
                        onSelect={(_, { node: { key } }) => {
                          onItemSelect(key, !isChecked(checkedKeys, key));
                        }}
                        onChange={(value: ValueType, labelList: React.ReactNode[], extra: ChangeEventExtra) => {
                          const s = [];

                          for (const v of value) {
                            const vv = props.flatData.filter((el) => el.key === v);

                            if (vv) {
                              s.push(vv.title);
                            } else {
                              console.log('Unable to find:', value);
                            }
                          }
                          onItemSelect(s);
                        }}
                        defaultExpandedKeys={expandedKeys}
                        expandedKeys={expandedKeys}
                        style={{ width: '100%%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={treeData}
                        defaultExpandAll={false}
                      />
                    )}
                  </div>
                </Fragment>
              );
            }
          }}
        </Transfer>
      </FormInputGroup>
    </Style>
  );
}
