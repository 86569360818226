import React from 'react';

import { Form, Switch } from 'antd';

export const PerformanceBoosterToggle = (props: {
  account: accountT,
  spaConnected: boolean,
  disabled?: boolean,
  defaultChecked?: boolean,
}) => {
  const defaultChecked = props.defaultChecked || false;

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <div>
        <Form.Item
          name="performanceBooster"
          label="Performance Booster"
          valuePropName="checked"
          disabled={props.disabled}
        >
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            disabled={props.disabled}
            defaultChecked={defaultChecked}
          />
        </Form.Item>
      </div>
      {!props.spaConnected && (
        <div style={{ fontWeight: 600 }}>
          In order to enable Performance Booster, <br /> please connect{' '}
          <a href={`/${props.account.id}/amazon/account`} target="_blank" rel="noreferrer">
            Amazon Seller Central API
          </a>
        </div>
      )}
    </div>
  );
};
