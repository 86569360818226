import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Export } from 'components/common/icons/Export';

import { Actions } from '../../../../../actions';
import { Buttonize } from '../campaignTree/Common';

const ExportButtonContainer = styled(Buttonize).withConfig({
  displayName: 'ExportButtonContainer',
})`
  width: 32px;
  height: 35px;
  border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
  border: var(--input-border);
  border-radius: 0.2rem;
  padding: 6px;
  margin-right: 3px;
  flex-shrink: 0;
  flex-grow: 0;
`;

type internalPropsT = { config: columnsConfigT, selectAction: WorkerAc };
type internalActionsT = { onSelect: Function };
type internalStateT = { config: columnsConfigT };
const defaultState: internalStateT = {
  config: {},
};

class AbstractExportRedirects extends Component<internalPropsT | internalActionsT, internalStateT> {
  state = defaultState;

  render() {
    const { exportRedirects } = this.props;

    return (
      <>
        <ExportButtonContainer>
          <Export onClick={exportRedirects} />
        </ExportButtonContainer>
      </>
    );
  }
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  config: state.front.exportRedirectsModalConfig,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  showExport: (config) => dispatch(Actions.front.audienceBuilder.redirect.export.show.request({ config })),
  exportRedirects: (_) => dispatch(Actions.front.audienceBuilder.redirect.export.show.request({})),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) =>
  _.isEqual(prev.front.exportRedirectsModalConfig, next.front.exportRedirectsModalConfig);

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const ExportRedirects = reduxWrapper(AbstractExportRedirects);
