import { Actions } from 'actions';
import { notifySSL } from 'lib/api';
import axios from 'lib/axios.factory';
import { selectCurrentAccountId } from 'stores/accounts';

export default (store: any) => (next: any) => async (action: any) => {
  next(action);

  const accountFilter = { accountId: selectCurrentAccountId(store.getState()) };

  switch (action.type) {
    case Actions.api.domain.notify.request().type:
      notifySSL(localStorage, axios, accountFilter, action.payload)
        .then((data) => {
          store.dispatch(action.workerAction.success(data));
        })
        .catch((error) => {
          store.dispatch(action.workerAction.error({ error }));
        });
      break;
    case Actions.api.domain.notify.success().type:
      console.log('la demande ssl à bien été envoyé');
      break;
    default:
      break;
  }
};
