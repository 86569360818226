import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';

import App from './App';
import 'react-dates/initialize';
import './Bootstrap.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import 'normalize.css/normalize.css';
import './Main.css';

import { LocalizationWrapper } from './locales/LocalizationWrapper';

const redirectUri = `${window.location.origin}/sign-in`;

/**
 * `returnTo` is the first url that was hit when the app was loaded (undefined if url is `/sign-out`)
 * @type {undefined|string}
 */
export const returnTo = window.location.pathname === '/sign-out' ? undefined : window.location.href;

if (process.env.NODE_ENV === 'production') {
  console.debug = function () {}; // eslint-disable-line no-console
  console.log = function () {}; // eslint-disable-line no-console
}

const root = createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain={window.env.REACT_APP_AUTH0_DOMAIN}
    clientId={window.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      audience: window.env.REACT_APP_AUTH0_AUDIENCE,
      redirect_uri: redirectUri,
    }}
    useRefreshTokens={true}
    useRefreshTokensFallback={true}
    skipRedirectCallback={window.location.pathname !== '/sign-in'}
  >
    <LocalizationWrapper>
      <App />
    </LocalizationWrapper>
  </Auth0Provider>,
);
