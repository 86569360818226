import React from 'react';

import styled from 'styled-components';

export function RoundWithIcon({ children, ...props }) {
  const Round = styled.div`
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    cursor: pointer;
    border: 2px solid black;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 1);
  `;

  return <Round {...props}>{children}</Round>;
}
