import React, { type Node } from 'react';

import { Form, Input } from 'antd';

const FacebookConversionSettings = (): Node => (
  <div>
    <Form.Item label="Pixel ID" name="facebookPixelId" style={{ marginBottom: '1rem' }}>
      <Input placeholder="Pixel ID ex: 10153742239885299" id="facebookPixelId" />
    </Form.Item>
    <div>
      <a
        style={{ lineHeight: '28px' }}
        href="https://www.carbon6.io/pixelme-help/how-to-verify-your-domain-on-facebook-and-use-it-on-pixelme-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        Verify your Domain before using a <b>Facebook pixel</b>. Learn more.
      </a>
    </div>
    <div>
      <a
        href="https://www.carbon6.io/pixelme-help/how-do-i-find-and-add-my-facebook-pixel-id"
        target="_blank"
        rel="noopener noreferrer"
      >
        How do I find my pixel ID?
      </a>
    </div>
    <Form.Item
      label="Facebook Conversions Access Token"
      name="facebookConversionApiAccessToken"
      style={{ marginTop: '1rem' }}
    >
      <Input.Password id="facebookConversionApiAccessToken" visibilityToggle={false} allowClear />
    </Form.Item>
    <div>
      <a
        href="https://developers.facebook.com/docs/marketing-api/conversions-api/get-started/"
        target="_blank"
        rel="noopener noreferrer"
      >
        How do I get my FB Conversions API Access Token?
      </a>
    </div>
  </div>
);

export default FacebookConversionSettings;
