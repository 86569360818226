export const CALLOUTS = {
  CALLOUT_1: 'Free Shipping',
  CALLOUT_2: '24 7 Customer Service',
  CALLOUT_3: 'Price Match Guaranteed',
  CALLOUT_4: 'Black Friday Sale!!',
};

export const BIDDING_DROPDOWN_OPTIONS = [
  'Target CPA Portfolio',
  'Target CPA Standard',
  'Target ROAS Portfolio',
  'Target ROAS Standard',
  'Maximize Conversions Standard',
];

export const BIDDING_STRATEGY_METRICS = [
  {
    biddingStrategyType: 'Target ROAS Standard',
    status: 'Enabled',
    impressions: 1089,
    clicks: 150,
    costPerConversion: 17.21,
    conversions: 11,
    currencyCode: 'USD',
    avgCPC: 1.26,
    cost: 189.34,
  },
  {
    biddingStrategyType: 'Target CPA Standard',
    status: 'Enabled',
    impressions: 4035,
    clicks: 300,
    costPerConversion: 13.87,
    conversions: 29,
    currencyCode: 'USD',
    avgCPC: 1.34,
    cost: 402.23,
  },
];

export const SEARCH_TERMS_DATA = [
  {
    'searchTerm': 'second stud gold earrings price',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold ball hoops',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "children's 14 karat gold earrings",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'real gold earrings for women',
    'matchType': 'Exact match',
    'added/Excluded': 'Added',
    'clicks': 1,
    'impressions': 4,
    'CTR': '25.00%',
    'currencyCode': 'USD',
    'avgCPC': 1.01,
    'cost': 1.01,
  },
  {
    'searchTerm': '14kt white gold ball stud earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '10k gold ball stud earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k huggie earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon white gold stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon childrens necklaces',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'jewish jewelry amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon sterling silver earrings studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'van cleef dupes amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'earrings gold earrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'aretes amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'lv stud earrings gold',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'star studs gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold stud earrings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 2,
    'impressions': 4,
    'CTR': '50.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.64,
    'cost': 1.27,
  },
  {
    'searchTerm': 'cartier amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 2,
    'CTR': '50.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.98,
    'cost': 0.98,
  },
  {
    'searchTerm': "women's gold ball earrings",
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'pandora earrings gold studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'mens stud earrings gold',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold crystal studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold earrings for mom',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 2,
    'CTR': '50.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.69,
    'cost': 0.69,
  },
  {
    'searchTerm': '10k gold earrings canada',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold ball earrings 14k',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 2,
    'impressions': 17,
    'CTR': '11.76%',
    'currencyCode': 'USD',
    'avgCPC': 1.06,
    'cost': 2.12,
  },
  {
    'searchTerm': 'gold pearl earrings stud',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'aretes de oro para mujer 14k',
    'matchType': 'Exact match',
    'added/Excluded': 'Added',
    'clicks': 0,
    'impressions': 5,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'stud gold earrings for women',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold studs',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 9,
    'CTR': '11.11%',
    'currencyCode': 'USD',
    'avgCPC': 1.01,
    'cost': 1.01,
  },
  {
    'searchTerm': '14k rose gold earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'screw back earrings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 3,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon gold stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'walmart 14 karat gold earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold earrings 585',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon canada jewellery',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold stud earring',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'real gold ball earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'how much do 14k earrings cost',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'macy gold earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "women's stud earrings gold",
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "amazon children's earrings",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'platinum ball stud earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon huggie',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14 kt gold earrings studs',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'tanishq sui dhaga earrings gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'cute nipple rings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 2,
    'CTR': '50.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.66,
    'cost': 0.66,
  },
  {
    'searchTerm': 'snake eyes tongue piercing jewelry amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon com jewelry making supplies',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold bead stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "stud women's gold earrings",
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 1,
    'cost': 1,
  },
  {
    'searchTerm': 'aretes para hombre amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.7,
    'cost': 0.7,
  },
  {
    'searchTerm': 'gold stud for men',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k yellow gold stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14 karat earrings studs',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'fake ear cuffs amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'dillards gold earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'long gold ball earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.67,
    'cost': 0.67,
  },
  {
    'searchTerm': 'diamond earrings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'toe rings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'little girl earrings 14k gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon nose rings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'death note necklace amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon celtic jewelry',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'tanishq earrings studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold earring studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'tragus earrings 14k gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'van cleef necklace dupe amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold warings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'earring amazon',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "men's ear studs gold",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gemstone 14kt white gold stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'stud gold earrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'coral stud earrings gold',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "macy's silver ball earrings",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold nugget earrings 24k',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'aretes de oro 14k',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold ball earrings for men',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'pavoi 14k gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold plated earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'buy gold earrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold earrings for women with price',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14 karat gold earrings for girls',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'feng shui ring amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 5,
    'CTR': '20.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.34,
    'cost': 0.34,
  },
  {
    'searchTerm': "macy's gold stud earrings",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold tragus earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold round stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14 kt gold earrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 1.01,
    'cost': 1.01,
  },
  {
    'searchTerm': 'ball earrings gold',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'little girl earrings 14k gold amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14kt ball earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'qvc 14k gold earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold star stud',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'friendship rings for 3 amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold cross necklace amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold hoop earrings canada',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 2,
    'CTR': '50.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.66,
    'cost': 0.66,
  },
  {
    'searchTerm': 'stud earings gold',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "macy's 14 karat gold earrings on sale",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'golden studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.99,
    'cost': 0.99,
  },
  {
    'searchTerm': 'amazon ring sets',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '10k gold ball earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold 10mm ball stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'white gold black diamond stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '6mm 14k gold hoop earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'jtv 14k gold hoop earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold stud earrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 5,
    'CTR': '20.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.96,
    'cost': 0.96,
  },
  {
    'searchTerm': '14k ear rings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'jcpenny gold earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'how much is 14k earrings worth',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold ear gauges',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon com pandora',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'jcpenney 14k gold earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 5,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon gold earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 3,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold earring tops',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "macy's gold earrings",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 4,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'breast milk jewelry kit amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon chains',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 3,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'stud earrings gold',
    'matchType': 'Exact match',
    'added/Excluded': 'Added',
    'clicks': 0,
    'impressions': 15,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14 kt gold ball stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'joyería amazon méxico',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 3,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'earrings in gold',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 3,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gucci stud earrings gold',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon ankle bracelets gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'simple gold stud earrings designs with price',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'belk gold earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon thick gold hoops',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'kalyan jewellers stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon 14k gold necklace',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'sui dhaga earrings gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold flat stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '24 ct gold earrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon cartilage earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'non pierced earrings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'american diamond earrings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.68,
    'cost': 0.68,
  },
  {
    'searchTerm': "women's rose gold stud earrings",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 2,
    'CTR': '50.00%',
    'currencyCode': 'USD',
    'avgCPC': 1.01,
    'cost': 1.01,
  },
  {
    'searchTerm': 'aretes oro 14k',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'red stone stud earrings gold',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "women's gold earrings design latest",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14 karat gold studs',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon nose rings gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'zales earrings gold',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'qvc 14 karat gold hoop earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '18k gold stud earrings canada',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold studs',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'necklace amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon jewelry sale',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon 14k gold earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 3,
    'impressions': 3,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.72,
    'cost': 2.15,
  },
  {
    'searchTerm': 'simply vera vera wang 14k white gold 1 2 carat t w diamond stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold earrings for women',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'diamond ball studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon earing backs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'cuff earrings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.67,
    'cost': 0.67,
  },
  {
    'searchTerm': 'nipple jewelry amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 4,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon jewellery display stands',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'jade rings amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'amazon prime jewelry deals',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 2,
    'CTR': '50.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.72,
    'cost': 0.72,
  },
  {
    'searchTerm': 'amazon tongue ring',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'christian necklaces amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 1,
    'impressions': 1,
    'CTR': '100.00%',
    'currencyCode': 'USD',
    'avgCPC': 0.65,
    'cost': 0.65,
  },
  {
    'searchTerm': 'goldearrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'tiffany and co gold stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'ball stud earrings gold',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold hug ring amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'drop gold ball earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold ball post earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k earrings canada',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': "tanishq women's gold earrings under 10000",
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '14k gold ball earrings 10mm',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'kay hoop earrings 14k yellow',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold cross earrings studs',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '5mm gold ball earrings',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'tanishq gold earrings for girls',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': '2nd stud earrings gold',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'gold stud ball earrings',
    'matchType': 'Exact match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'white gold stud earrings canada',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 2,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'belk 14k gold hoop earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'mens jade ring amazon',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'letter stud earrings gold',
    'matchType': 'Phrase match',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
  {
    'searchTerm': 'qvc gold stud earrings',
    'matchType': 'Phrase match (close variant)',
    'added/Excluded': 'None',
    'clicks': 0,
    'impressions': 1,
    'CTR': '0.00%',
    'currencyCode': 'USD',
    'avgCPC': 0,
    'cost': 0,
  },
];
