import React, { useEffect, useState } from 'react';

import rename from 'deep-rename-keys';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokTools } from 'lib/tiktokPlateformApi';

import { TiktokGenericSelector } from './TiktokGenericSelector';
import { buildGeoTargetsTree } from '../buildTree';

/**
 * Tiktok GeoTargets selector
 * https://ads.tiktok.com/marketing_api/docs?id=1703879996663810
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function GeoTargetsSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  value: any,
  onChange: any,
  defaultValue: any,
}) {
  const [treeData, setTreeData] = useState([]);
  const [flatData, setFlatData] = useState([]);
  const { doAction, ExclusiveInlineLoadingContainer } = useLoading(true);

  async function load() {
    const placement = '["PLACEMENT_TIKTOK"]';
    const objectiveType = 'TRAFFIC';
    const genericResponse = await getTiktokTools(
      localStorage,
      axios,
      props.adsProvider,
      {
        accountId: props.account.id,
        placement,
        objectiveType,
      },
      'regions',
    );
    const d = buildGeoTargetsTree(genericResponse.regionInfo);

    setFlatData(genericResponse.regionInfo);
    setTreeData(d);
  }
  useEffect(() => {
    doAction(load);
  }, [JSON.stringify(props.adsProvider)]);

  return (
    <ExclusiveInlineLoadingContainer>
      <TiktokGenericSelector
        account={props.account}
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        flatData={rename(flatData, (key) => (key === 'locationId' ? 'key' : key === 'name' ? 'title' : key))}
        treeData={rename(treeData, (key) => (key === 'locationId' ? 'key' : key === 'name' ? 'title' : key))}
        adsProvider={props.adsProvider}
      />
    </ExclusiveInlineLoadingContainer>
  );
}
