import moment from 'moment';

function extractDateFromTemporaryRule(redirect) {
  const timestamp = redirect.dynamicUrls ? redirect.dynamicUrls[0].rule.query.split('>')[1].trim() * 1000 : 1;
  const end = moment(new Date(timestamp));

  return end;
}

export function hasExpired(redirect) {
  const end = extractDateFromTemporaryRule(redirect);
  const now = moment(new Date());

  return now.isAfter(end);
}

export function TTLInMinutes(redirect) {
  const end = extractDateFromTemporaryRule(redirect);
  const now = moment(new Date());
  const duration = moment.duration(now.diff(end));
  const minutes = Math.abs(Math.floor(duration.asMinutes()));

  return minutes;
}
