import './DropDown.css';
import type { Node } from 'react';
import React, { Component } from 'react';

import { isSelected } from './drop-down';
import { DropDownElement } from './DropDownElement';
import { DropDownElementText } from './DropDownElementText';
import { DropDownSearch } from './DropDownSearch';
import { SortableDropDownBullet } from './SortableDropDownBullet';
import { SortableDropDownElement } from './SortableDropDownElement';

type internalPropsT = {
  id: string,
  items: Array<{ id: string, value: string }>,
  onSelect: (Array<string>) => void,
  selected: Array<string>,
  disabled?: boolean,
  open?: boolean,
  multi?: boolean,
  adding?: boolean,
  sortValues?: boolean,
  searchable?: boolean,
  scrollable?: boolean,
  className?: string,
  placeholder?: string,
  renderArrow?: () => Node,
  renderTitle?: () => Node,
  renderItem?: ({}) => Node,
  renderAddItem?: () => Node,
  onClose?: () => void,
  onAddItem?: (string) => void,
  handleClose: (void) => void,
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xlarge' | 'auto',
  position?: 'left' | 'right',
};

type internalStateT = {};
const defaultState: internalStateT = {};

export class DropDownContent extends Component<internalPropsT, internalStateT> {
  state = defaultState;

  constructor(props: internalPropsT) {
    super(props);
    this.state = { open: this.props.open, search: '', addedValue: '' };
  }

  isSelected(id) {
    return isSelected(id, this.props.selected);
  }

  render() {
    const {
      multi,
      renderItem,
      renderAddItem,
      searchable = false,
      adding = false,
      sortable = false,
      sortValues = true,
    } = this.props;

    let { items } = this.props;

    const { search, addedValue } = this.state;

    // if multi sort, selected first, alpha after
    if (multi && sortValues) {
      items.sort((a, b) => {
        const sortedAValue = `${this.isSelected(a.id) < 0 ? 1 : 0}
        ${a.value}`;
        const sortedBValue = `${this.isSelected(b.id) < 0 ? 1 : 0}
        ${b.value}`;

        return sortedAValue.localeCompare(sortedBValue);
      });
    }

    if (searchable && search.length > 0) {
      items = items.filter((v) => v.value.toLowerCase().match(search.toLowerCase()));
    }

    return (
      <ul>
        {searchable && <DropDownSearch value={search} onChange={this.search} adding={adding} addedValue={addedValue} />}
        {items.map((val) => {
          const { id } = val;
          const { value } = val;
          const key = val.id;
          const { position } = val;

          if (sortable) {
            return (
              <SortableDropDownElement index={position} onClick={() => this.handleSelect(id)} key={key}>
                <SortableDropDownBullet />
                {multi && this.renderCheckBox(this.isSelected(id) >= 0)}
                <DropDownElementText>{renderItem ? renderItem(val) : value}</DropDownElementText>
              </SortableDropDownElement>
            );
          } else {
            return (
              <DropDownElement onClick={() => this.handleSelect(id)} key={key}>
                {multi && this.renderCheckBox(this.isSelected(id) >= 0)}
                <DropDownElementText>{renderItem ? renderItem(val) : value}</DropDownElementText>
              </DropDownElement>
            );
          }
        })}
        {renderAddItem && <li>{renderAddItem()}</li>}
      </ul>
    );
  }

  componentDidMount() {
    this.props.onAddItem && document.addEventListener('keyup', this.handleKey, false);
  }
  componentWillUnmount() {
    this.props.onAddItem && document.removeEventListener('keyup', this.handleKey, false);
  }

  search = (event: SyntheticEvent<HTMLInputElement>) => {
    const { onAddItem, adding } = this.props;

    if (onAddItem && !adding) {
      const target = (event.currentTarget: HTMLInputElement);
      const { value } = target;

      this.setState({
        search: value,
      });
    }
  };

  handleKey = (event: KeyboardEvent) => {
    const { onAddItem, adding, multi } = this.props;

    if (onAddItem && !adding) {
      const { search } = this.state;

      switch (event.key.toLowerCase()) {
        case 'enter':
          if (search.length > 0) {
            this.props.onAddItem(search);
            this.setState({ search: '', addedValue: search, open: multi });
          }

          break;
        default:
      }
    }
  };

  renderCheckBox(checked: boolean) {
    if (checked) {
      return (
        <svg
          style={{
            marginRight: '0.4rem',
            flexShrink: '0',
          }}
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Group 3.1">
            <g id="Rectangle 13">
              <rect width="15" height="15" rx="2" fill="white" />
              <rect x="0.5" y="0.5" width="14" height="14" rx="1.5" stroke="#2E89E2" />
            </g>
            <path id="Vector 5" d="M0 5L3 7.5L8.5 0" transform="translate(3 3.5)" stroke="#2E89E2" />
          </g>
        </svg>
      );
    }

    return (
      <svg
        style={{
          marginRight: '0.4rem',
          flexShrink: '0',
        }}
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Rectangle 13.1">
          <rect width="15" height="15" rx="2" fill="white" />
          <rect x="0.5" y="0.5" width="14" height="14" rx="1.5" stroke="#BDBDBD" />
        </g>
      </svg>
    );
  }
  handleSelect = (id: string) => {
    const { selected, onSelect, multi = false, handleClose } = this.props;

    if (multi) {
      const index = this.isSelected(id);

      if (index === -1) {
        onSelect([...selected, id]);
      } else {
        const newSelected = [...selected];

        newSelected.splice(index, 1);
        onSelect(newSelected);
      }
    } else {
      onSelect([id]);
      handleClose();
    }
  };
}
