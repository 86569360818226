import React from 'react';

import { Divider, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component';

import { AdsProviderLogo } from './AdsProviderLogo';
import { FloatingKeywordCellAction } from './FloatingKeywordCellAction';
import { Copy } from '../../../common/icons';
import { AmazonWhite } from '../../../layout/Bar/Items/AmazonWhite';
import { IconInsideButton } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLineElements';
import { amazonAttributionIds } from '../../oauth/AmazonAttributionIds';

const Provider = {
  Amazon: 'Amazon',
  Google: 'Google',
};

export function KeywordFloatingCellAction(props: { keyword: string, marketplaceId: string }) {
  return (
    <>
      {props.keyword}
      <FloatingKeywordCellAction
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <IconInsideButton>
          <CopyToClipboard
            text={props.keyword}
            onCopy={() => {
              message.success(`Your keyword is copied to clipboard.`);
            }}
          >
            <Copy />
          </CopyToClipboard>
        </IconInsideButton>
        <Divider type="vertical"></Divider>
        <SearchKeywords logo={Provider.Amazon} keyword={props.keyword} marketplaceId={props.marketplaceId} />
        <Divider type="vertical"></Divider>
        <SearchKeywords logo={Provider.Google} keyword={props.keyword} marketplaceId={props.marketplaceId} />
      </FloatingKeywordCellAction>
    </>
  );
}

function SearchKeywords(props: { keyword: string, logo: string, marketplaceId: string }) {
  const { domainExtension } = amazonAttributionIds.filter((x) => x.id === props.marketplaceId)[0];

  switch (props.logo) {
    case Provider.Google: {
      return (
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${encodeURIComponent(props.keyword)}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          rel="noreferrer"
        >
          <IconInsideButton>
            <AdsProviderLogo style={{ height: 16, with: 16 }} providerType="GOOGLE" />
          </IconInsideButton>
        </a>
      );
    }
    case Provider.Amazon: {
      return (
        <a
          target="_blank"
          href={`https://www.amazon${domainExtension}/s?k=${encodeURIComponent(props.keyword)}`}
          onClick={(e) => {
            e.stopPropagation();
          }}
          rel="noreferrer"
        >
          <IconInsideButton>
            <AmazonWhite width={16} height={16}></AmazonWhite>
          </IconInsideButton>
        </a>
      );
    }
    default: {
      return <></>;
    }
  }
}
