import produce from 'immer';

import { getFacets, getRedirectsByIds, getTimeline } from 'lib/api';

import { Actions } from '../actions';
import { exportTimeline } from '../lib/api';

export type queryT = {
  id: string,
  accountId: string,
  period: string,
};

type stateT = {
  +asError: boolean,
  +errorMessage: string,
  +loading: boolean,
  +loadingFacets: boolean,
  +query: queryT,
  +exportStatsModalConfirmedConfig: {
    open: boolean,
  },
  +stats: Array<{ date: number, value: number }>,
  +total: number,
  +facets: {
    [key: string]: Array<{ id: string, label?: string, value: number }>,
  },
  +redirect?: redirectT,
};

// ACTIONS
export type errorT = { message: string };
export type actionT =
  | { type: 'LOAD_ANALYTICS_AND_FACETS', query: queryT, redirect?: redirectT }
  | {
      type: 'LOAD_ANALYTICS_SUCCESS',
      query: queryT,
      stats: Array<{ date: number, value: number }>,
    }
  | { type: 'LOAD_ANALYTICS_ERROR', error: errorT, query: queryT }
  | {
      type: 'LOAD_FACETS_SUCCESS',
      query: queryT,
      facets: {},
    }
  | { type: 'LOAD_FACETS_ERROR', error: errorT, query: queryT };

export function loadAnalyticsAndFacets({ query, redirect }: { query: queryT, redirect?: redirectT }): actionT {
  return {
    type: 'LOAD_ANALYTICS_AND_FACETS',
    query,
    redirect,
  };
}

export function loadAnalyticsSuccess({
  query,
  stats,
}: {
  query: queryT,
  stats: Array<{ date: number, value: number }>,
}): actionT {
  return {
    type: 'LOAD_ANALYTICS_SUCCESS',
    query,
    stats,
  };
}

export function loadAnalyticsError({ query, error }: { query: queryT, error: errorTT }): actionT {
  return {
    type: 'LOAD_ANALYTICS_ERROR',
    query,
    error,
  };
}

export function loadFacetsError({ query, error }: { query: queryT, error: errorT }): actionT {
  return {
    type: 'LOAD_FACETS_ERROR',
    query,
    error,
  };
}

export function loadFacetsSuccess({ query, facets }: { query: queryT, facets: {} }): actionT {
  return {
    type: 'LOAD_FACETS_SUCCESS',
    query,
    facets,
  };
}

// STORE
const initialRedirectsState: stateT = {
  asError: false,
  errorMessage: '',
  loading: true,
  loadingFacets: true,
  exportStatsModalConfirmedConfig: {
    open: false,
  },
  query: { id: '', accountId: '', period: '7d' },
  stats: [],
  total: -1,
  facets: {},
};

export function analytics(state: stateT = initialRedirectsState, action: actionT): state {
  switch (action.type) {
    case Actions.api.audienceBuilder.redirect.stats.export.success().type: {
      return produce(state, (draft) => {
        draft.exportStatsModalConfirmedConfig = {
          open: true,
        };
      });
    }
    case Actions.api.audienceBuilder.redirect.stats.export.error().type: {
      return produce(state, (draft) => {
        draft.exportStatsModalConfirmedConfig = {
          open: true,
          error: true,
        };
      });
    }
    case Actions.api.audienceBuilder.redirect.stats.export.confirmed().type: {
      return produce(state, (draft) => {
        draft.exportStatsModalConfirmedConfig = {
          open: false,
        };
      });
    }
    case 'LOAD_ANALYTICS_AND_FACETS':
      return {
        ...initialRedirectsState,
        query: action.query,
        redirect: action.redirect,
      };
    case 'LOAD_ANALYTICS_SUCCESS':
      const { query, stats } = action;

      let total = 0;

      stats.forEach((s) => (total += s.value));

      const { query: curQuery } = state;

      if (curQuery && curQuery.id === query.id) {
        return {
          ...state,
          loading: false,
          query,
          stats,
          total,
        };
      }

      return state;
    case 'LOAD_ANALYTICS_ERROR':
      const { query: errorQuery } = action;
      const { query: curErrorQuery } = state;

      if (curErrorQuery && curErrorQuery.id === errorQuery.id) {
        return {
          ...initialRedirectsState,
          loading: false,
          asError: true,
          errorMessage: action.error.message,
        };
      }

      return state;
    case 'LOAD_FACETS_SUCCESS':
      const { query: queryF, facets } = action;
      const { query: curQueryF } = state;

      if (curQueryF && curQueryF.id === queryF.id) {
        return {
          ...state,
          loadingFacets: false,
          query: queryF,
          facets,
        };
      }

      return state;
    case 'LOAD_FACETS_ERROR':
      const { query: errorQueryF } = action;
      const { query: curErrorQueryF } = state;

      if (curErrorQueryF && curErrorQueryF.id === errorQueryF.id) {
        return {
          ...initialRedirectsState,
          loadingFacets: false,
          asError: true,
          errorMessage: action.error.message,
        };
      }

      return state;
    default:
      return state;
  }
}

// Thunks
export function loadAnalyticsAndFacetsThunk({ query, redirect }: { query: queryT, redirect?: redirectT }) {
  return function (dispatch, getState, axios) {
    query.id = generareUID();
    dispatch(loadAnalyticsAndFacets({ query, redirect }));
    let redirectId = undefined;

    if (redirect) {
      redirectId = redirect.id;
    }

    getTimeline(localStorage, axios, { ...query, redirectId }).then(
      (result) => {
        const { stats, total } = result;

        dispatch(loadAnalyticsSuccess({ stats, total, query }));
        getFacets(localStorage, axios, { ...query, redirectId }).then(
          (result) => {
            const { facets } = result;

            dispatch(loadFacetsSuccess({ facets, query }));
          },
          // function(err) {
          //   loadFacetsError(err.error);
          // }
        );
      },
      // function(err) {
      //   loadAnalyticsError(err.error);
      // }
    );
  };
}

// Thunks
export function exportAnalyticsAndFacetsThunk({ query, redirect }: { query: queryT, redirect?: redirectT }) {
  return async function (dispatch, getState, axios) {
    query.id = generareUID();
    let redirectId = undefined;

    if (redirect) {
      redirectId = redirect.id;
    }

    try {
      await exportTimeline(localStorage, axios, {
        ...query,
        redirectId,
      });
      dispatch(Actions.api.audienceBuilder.redirect.stats.export.success());
    } catch (e) {
      dispatch(Actions.api.audienceBuilder.redirect.stats.export.error());
    }
  };
}

export function loadAnalyticsAndFacetsForRedirectThunk({
  redirectId,
  accountId,
}: {
  redirectId: string,
  accountId: string,
}) {
  return function (dispatch, getState, axios) {
    getRedirectsByIds(localStorage, axios, {
      accountId,
      redirectsIds: [redirectId],
    }).then((result) => {
      if (result.redirects && result.redirects.length > 0) {
        const { query } = getState().analytics;

        dispatch(
          loadAnalyticsAndFacetsThunk({
            query: { ...query, accountId },
            redirect: result.redirects[0],
          }),
        );
      }
    });
  };
}

// Selectors
export function sortOnValue(
  data: Array<{ id: string, label: string, value: number, uniqueValue: number }>,
): Array<{ id: string, label: string, value: number, uniqueValue: number }> {
  return data.sort((entry1, entry2) => entry2.value - entry1.value);
}

export function totalValue(data: Array<{ id: string, label: string, value: number, uniqueValue: number }>): number {
  let total = 0;

  data.forEach((entry) => {
    total += entry.uniqueValue;
  });

  return total;
}

export function maxValue(data: Array<{ id: string, label: string, value: number, uniqueValue: number }>): number {
  let max = 0;

  data.forEach((entry) => {
    max = Math.max(max, entry.uniqueValue);
  });

  return max;
}

// Utils
function generareUID() {
  const now = new Date();

  return `${now.valueOf()}`;
}
