import React, { useState } from 'react';

import { Form, Select } from 'antd';

import type { accountT } from '../../../../../flow-typed/pixelme/types';
import { getAmazonProfiles } from '../../../../lib/amazon';

/**
 * Selector for Amazon provider
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AmazonProviderSelector(props: { account: accountT, marketplaceId: string }) {
  const [provider, setProvider] = useState(props.account.productProviders[0]);

  const availableProviders = getAmazonProfiles(props.account).filter(
    (p: productProviderT) => props.marketplaceId || provider.amazonMarketplaceId === provider.amazonMarketplaceId,
  );

  return (
    <>
      <Form.Item required label="Amazon account" name="productProviderId" rules={[{ required: true }]}>
        <Select
          size="large"
          onChange={(pid) => setProvider(getAmazonProfiles(props.account).find((p) => p.id === pid))}
        >
          {availableProviders.map((p: productProviderT) => (
            <Select.Option key={`provider_${p.id}`} disabled={p.id === 0} value={p.id}>
              {p.externalAccountDescriptiveName || p.id}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
}
