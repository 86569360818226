import React from 'react';

import IconContainer from './IconContainer';

export function Columns(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          opacity="0.5"
          x="13.5"
          y="0.5"
          width="2"
          height="9"
          transform="rotate(90 13.5 0.5)"
          fill="#6E6E6E"
          stroke="#6E6E6E"
        />
        <rect
          opacity="0.5"
          x="2.5"
          y="0.5"
          width="2"
          height="2"
          transform="rotate(90 2.5 0.5)"
          fill="#6E6E6E"
          stroke="#6E6E6E"
        />
        <rect
          opacity="0.5"
          x="2.5"
          y="4.5"
          width="2"
          height="2"
          transform="rotate(90 2.5 4.5)"
          fill="#6E6E6E"
          stroke="#6E6E6E"
        />
        <rect
          opacity="0.5"
          x="2.5"
          y="8.5"
          width="2"
          height="2"
          transform="rotate(90 2.5 8.5)"
          fill="#6E6E6E"
          stroke="#6E6E6E"
        />
        <rect
          opacity="0.5"
          x="13.5"
          y="4.5"
          width="2"
          height="9"
          transform="rotate(90 13.5 4.5)"
          fill="#6E6E6E"
          stroke="#6E6E6E"
        />
        <rect
          opacity="0.5"
          x="13.5"
          y="8.5"
          width="2"
          height="9"
          transform="rotate(90 13.5 8.5)"
          fill="#6E6E6E"
          stroke="#6E6E6E"
        />
      </svg>
    </IconContainer>
  );
}
