import './Top.css';
import React from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

import { updateAccount } from 'stores/accounts';
import type { action } from 'stores/accounts';
import { updateUser } from 'stores/user';

import DropDownMenu from './DropDownMenu';
import UpgradeButton from './UpgradeButton';
import Usage from './Usage';

const LeftDiv = styled.div.withConfig({
  displayName: 'LeftDiv',
})`
  display: inline-flex;
  position: relative;
  top: -3px;
  align-items: center;
`;

const RightDiv = styled.div.withConfig({
  displayName: 'RightDiv',
})`
  display: inline-flex;
  align-items: center;
`;

function Top({
  user,
  title,
  renderSubMenu,
  accounts,
  account,
  usage,
  limitations,
  signOut,
  switchAccount,
  updateAccount,
  updateUser,
  displayTypeform,
  hideUsage,
  hideAccountSwitcher,
}: {
  user: userT,
  title: string | Function,
  renderSubMenu: Function,
  accounts: Array<accountT>,
  account: accountT,
  usage: { workspaces: number },
  limitations: { workspaces: number },
  signOut: () => void,
  switchAccount: (string) => action,
  updateAccount: (accountT) => *,
  updateUser: (userT) => *,
  displayTypeform: boolean,
  +hideUsage: boolean,
  +hideAccountSwitcher: boolean,
}) {
  let titleToDisplay;

  if (typeof title === 'function') {
    titleToDisplay = title();
  } else {
    titleToDisplay = title;
  }

  return (
    <div className="top">
      <LeftDiv>
        {!!titleToDisplay && (
          <div className="top__title">
            <div className="top__title__label">{titleToDisplay}</div>
          </div>
        )}
        {renderSubMenu && <div className="top__sub-menu">{renderSubMenu()}</div>}
      </LeftDiv>
      <RightDiv>
        <UpgradeButton />
        {!hideUsage && <Usage />}
        {!hideAccountSwitcher && (
          <DropDownMenu
            updateAccount={updateAccount}
            updateUser={updateUser}
            user={user}
            switchAccount={switchAccount}
            signOut={signOut}
            account={account}
            accounts={accounts}
            usage={usage}
            limitations={limitations}
          />
        )}
      </RightDiv>
    </div>
  );
}

const stateToProps = function (state): internalPropsT {
  return {
    limitations: state.accounts.limitations,
    usage: state.accounts.usage,
  };
};

const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  updateAccount: (args) => dispatch(updateAccount(args)),
  updateUser: (args) => dispatch(updateUser(args)),
});

export default connect(stateToProps, dispatchToProps)(Top);
