import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Spinner } from '@blueprintjs/core';
import './PageSetting.css';
import { Form } from 'antd';
import Switch from 'antd/lib/switch';
import { useSelector } from 'react-redux';

import { s } from 'lib/safe';

const Style = styled.div`
  grid-area: builder;
`;

export function PageSetting({ savePage, updateState, state }) {
  useEffect(() => {
    savePage(state.page);
  }, [state.page.username, state.page.title, state.page.hidePix]);

  const { limitations } = useSelector((state) => state.accounts);

  if (!state.page) {
    return <Spinner />;
  }

  return (
    <Style>
      <div className="page-setting__block">
        <p>URL of your micro landing page</p>
        <hr />
        {state.page.username.length !== 0 ? (
          <></>
        ) : (
          <span style={{ color: 'red', fontSize: '12px' }}>empty username are not allowed</span>
        )}
        <div className="page-setting__input">
          <span style={{ color: '#B0B0B0' }}>https://pxlme.co/</span>
          <input
            title="Eight or more characters"
            name="username"
            placeholder="yournamehere"
            onChange={(event) => {
              if (event.target) {
                const v = event.target.value.replace(/[^a-zA-Z\d\s_]/, '');

                updateState((draft) => {
                  draft.page.username = v.trim();
                });
              }
            }}
            value={state.page.username}
          />
        </div>
      </div>
      <div className="page-setting__block" style={{ marginTop: '0.5rem' }}>
        <p>Page title</p>
        <hr />
        <input
          className="page-setting__input-title"
          title="URL of your micro landing page"
          name="title"
          placeholder="Url shortener for savvy marketers"
          onChange={(event) => {
            if (event.target) {
              const v = event.target.value;

              updateState((draft) => {
                draft.page.title = v;
              });
            }
          }}
          value={state.page.title}
        />
      </div>

      {s(limitations).allowHidePixLogo && (
        <div className="page-setting__block" style={{ marginTop: '0.5rem' }}>
          <p>PixelMe Logo</p>
          <hr />
          <Form.Item label="Hide the PixelMe logo">
            <Switch
              checked={state.page.hidePix}
              onChange={(v) => {
                updateState((draft) => {
                  draft.page.hidePix = v;
                });
              }}
            ></Switch>
          </Form.Item>
        </div>
      )}
    </Style>
  );
}
