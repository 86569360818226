import React, { type Node } from 'react';

import { Input, Modal, Form } from 'antd';
import { useIntl } from 'react-intl';

import { strings } from 'locales/strings';

import { authorizedTextInKeywordsWithNewline } from '../AdsProviders/constants';

type propsT = {
  isModalVisible: boolean,
  onFinish: () => void,
  data: {
    title: string,
    keywords: auditKeywordT[],
  }[],
  onClose: () => void,
};

export const AddCustomKeywordsModal = ({ isModalVisible, onFinish, data, onClose }: propsT): Node => {
  const [form] = Form.useForm();
  const { messages } = useIntl();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = (values) => {
    const { keywords } = values;

    onFinish(
      keywords
        .split('\n')
        .map((kw) => kw.trim())
        .filter(Boolean),
    );
    handleClose();
  };

  return (
    <Modal
      visible={isModalVisible}
      title={messages.asinAudit.auditImprovement.addKeywords}
      okText={messages.asinAudit.auditImprovement.addToTopKeywords}
      onOk={form.submit}
      onCancel={handleClose}
      destroyOnClose // Allows input to auto focus on subsequent opens
    >
      <Form form={form} initialValues={{ keywords: '' }} onFinish={handleSubmit} layout="vertical" requiredMark={false}>
        <Form.Item
          label={messages.campaigns.keywords.label}
          name="keywords"
          rules={[
            {
              required: true,
              pattern: authorizedTextInKeywordsWithNewline,
              min: 1,
              max: 100,
              message: messages.campaigns.keywords.invalid,
            },
            {
              required: true,
              validator: (_, keywords) => {
                const existingKeywords = data[0].keywords.map((kw) => kw.keyword);
                const newKeywords = keywords
                  .split('\n')
                  .map((kw) => kw.trim())
                  .filter(Boolean);
                const duplicateKeywords = newKeywords.filter((kw) => existingKeywords.includes(kw));

                if (duplicateKeywords.length > 0) {
                  return Promise.reject(
                    new Error(`The following keywords already exist: ${duplicateKeywords.join(', ')}`),
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.TextArea
            placeholder={messages.asinAudit.auditImprovement.newKeywordsDescription}
            autoSize={{ minRows: 5, maxRows: 8 }}
            autoFocus
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
