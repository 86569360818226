import React from 'react';

import { AmazonWhite } from '../../../layout/Bar/Items/AmazonWhite';
import { IconInsideButton } from '../../AudienceBuilder/Campaigns/campaigns/RedirectsListLineElements';

export function ShowProduct(props: { product: { productUrl: string } }) {
  return (
    <div>
      <a
        target="_blank"
        href={props.product.productUrl}
        onClick={(e) => {
          e.stopPropagation();
        }}
        rel="noreferrer"
      >
        <IconInsideButton>
          <AmazonWhite width={16} height={16}></AmazonWhite>
        </IconInsideButton>
      </a>
    </div>
  );
}
