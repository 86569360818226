/**
 * Return google ads providers for account
 * @param account
 * @returns {adsProviderT[]|*[]}
 */
export function getAdsProviders(account: accountT): adsProviderT[] {
  if (account && account.adsProviders) {
    return account.adsProviders;
  } else {
    return [];
  }
}

/**
 * Return default google provider
 * @param providers
 * @returns {*}
 */
export function getDefaultAdsProvider(providers: adsProviderT[]): ?adsProviderT {
  return (providers || [])[0];
}

/**
 * Return active google provider
 * @param adsProviders
 * @returns {*|unknown[]|undefined}
 */
export function getActiveGoogleProvider(adsProviders): ?(adsProviderT[]) {
  return adsProviders;
}

/**
 * Return true if there is active ads provider for google
 * @param adsProviders
 * @returns {boolean}
 */
export function hasActiveGoogleProvider(adsProviders: any): boolean {
  return getActiveGoogleProvider(adsProviders).length > 0;
}
