export function redirectFromState(redirect) {
  if (!redirect.rotatorUrls) {
    return {
      ...redirect,
      rotatorUrls: [{ url: redirect.url, weight: 1 }],
    };
  }

  return redirect;
}

export function updateUrlInRedirect(redirect, idx, { url, percent }) {
  const curPercent = percentToVal(percent);

  if (isNaN(curPercent)) {
    return redirect;
  }

  if (redirect.rotatorUrls && redirect.rotatorUrls[idx]) {
    const compensationIdx = idx + 1 >= redirect.rotatorUrls.length ? idx - 1 : idx + 1;
    const newRotatorUrls = computePercents(redirect.rotatorUrls);

    newRotatorUrls[idx] = {
      url,
      weight: curPercent,
      percent: `${curPercent}%`,
    };
    const compensatedSumWeight = compensationSumWeight(compensationIdx, newRotatorUrls);

    newRotatorUrls[compensationIdx] = {
      url: newRotatorUrls[compensationIdx].url,
      weight: compensatedSumWeight,
      percent: `${compensatedSumWeight}%`,
    };

    return { ...redirect, rotatorUrls: newRotatorUrls };
  }

  return redirect;
}

export function computePercents(rotatorUrls) {
  if (rotatorUrls) {
    const sum = sumWeight(rotatorUrls);
    const newRotatorUrls = [];

    for (const r of rotatorUrls) {
      const percent = Math.floor((100 * r.weight) / sum);

      newRotatorUrls.push({
        ...r,
        percent: `${percent}%`,
        weight: percent,
      });
    }

    return newRotatorUrls;
  }

  return rotatorUrls;
}

export function addRotatorInRedirect(redirect) {
  if (redirect.rotatorUrls) {
    const newRotatorUrls = [];

    for (const r of redirect.rotatorUrls) {
      newRotatorUrls.push({
        url: r.url,
        weight: 1,
      });
    }
    newRotatorUrls.push({
      url: '',
      weight: 1,
    });

    return { ...redirect, rotatorUrls: computePercents(newRotatorUrls) };
  }

  return redirect;
}

export function delRotatorInRedirect(redirect, idx: number) {
  if (redirect.rotatorUrls && idx >= 0 && idx < redirect.rotatorUrls.length && redirect.rotatorUrls.length > 1) {
    const newRotatorUrls = [];

    for (let cIdx = 0; cIdx < redirect.rotatorUrls.length; cIdx++) {
      if (cIdx !== idx) {
        newRotatorUrls.push({
          url: redirect.rotatorUrls[cIdx].url,
          weight: 1,
        });
      }
    }

    return { ...redirect, rotatorUrls: computePercents(newRotatorUrls) };
  }

  return redirect;
}

function sumWeight(rotatorUrls) {
  let sum = 0;

  for (const r of rotatorUrls) {
    sum += r.weight;
  }

  return sum;
}

function compensationSumWeight(compensationIdx: number, rotatorUrls) {
  let sum = 0;

  for (let idx = 0; idx < rotatorUrls.length; idx++) {
    if (idx !== compensationIdx) {
      sum += rotatorUrls[idx].weight;
    }
  }

  return 100 - sum;
}

const percentRE = /^[0-9]*%$/;

export function percentToVal(percentStr: string): number {
  if (!percentRE.test(percentStr)) {
    return NaN;
  }

  const val = parseInt(percentStr);

  if (isNaN(val)) {
    return NaN;
  }

  if (val > 100 || val < 0) {
    return NaN;
  }

  return val;
}
