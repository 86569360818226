import './DismissableInfo.css';
import type { Node } from 'react';
import React from 'react';

import { InfoCircleFilled } from '@ant-design/icons';

import { useLocalStorage } from '../../hooks/useLocalStorage';

export default (props: { style?: any, name: string, children: Node, learnMore?: string }) => {
  const [closed, setClosed] = useLocalStorage(`di-${props.name}`, false);

  const close = () => {
    setClosed(true);
  };

  if (closed) {
    return <></>;
  }

  return (
    <div className="dismissable-info" style={props.style || {}}>
      <div className="dismissable-info__icon">
        <InfoCircleFilled style={{ fontSize: '18px', color: '#0043CE' }} />
      </div>

      <div className="dismissable-info__content">
        {props.children}

        <p className="dismissable-info__close">
          <span className="dismissable-info__close-btn" onClick={close}>
            Do not show this again
          </span>
        </p>
      </div>

      {props.learnMore && (
        <div className="dismissable-info__learn-more">
          <a href={props.learnMore} target="_blank" rel="noreferrer">
            Learn more
          </a>
        </div>
      )}
    </div>
  );
};
