import './NewDropDown.css';
import type { Node } from 'react';
import React, { Component } from 'react';

import { Button, MenuItem } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import { Polygon } from '../icons/Polygon';

type propsT = {
  id: string,
  values: Array<any>,
  onSelect: (Array<string>) => void,
  disabled?: boolean,
  open?: boolean,
  renderArrow?: () => Node,
  renderTitle?: () => Node,
  renderItem?: ({}) => Node,
  onClose?: () => void,
  onAddItem?: (string) => void,
  createNewItemRenderer?: ({}) => Node,
  createNewItemFromQuery?: (string) => Object,
};

export default class NewDropDown extends Component<
  props,
  { open: boolean, search: string, addedValue: string, values: any },
> {
  constructor(props: propsT) {
    super(props);
    this.state = { open: this.props.open };
  }

  render() {
    let {
      id,
      renderTitle,
      renderArrow,
      renderItem,
      onSelect,
      onKeyUp,
      onActiveItemChange,
      disabled = false,
      noBorder = false,
    } = this.props;

    const { open } = this.state;

    const values = this.state.values || this.props.values;

    if (!renderArrow) {
      renderArrow = this.renderArrow;
    }

    return (
      <Select
        id={id}
        items={values}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={onSelect}
        onKeyUp={onKeyUp}
        onActiveItemChange={onActiveItemChange}
        itemRenderer={renderItem}
        resetOnQuery={true}
        resetOnSelect={true}
        resetOnClose={true}
        popoverProps={{ minimal: true }}
        {...this.props}
      >
        <Button
          className={`
          drop-down${open ? ' drop-down--open' : ''}
          ${disabled ? ' drop-down--disabled' : ''}
          ${noBorder ? 'drop-down--no-border' : ''}
          `}
        >
          <div className="drop-down__title">{renderTitle ? renderTitle() : ''}</div>
          {renderArrow()}
        </Button>
      </Select>
    );
  }

  handleClose = () => {
    this.setState({ open: false, search: '' });

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  renderArrow = (): Node => {
    const { open } = this.state;
    const graph = open ? <Polygon /> : <Polygon />;

    return <div style={{ height: '11px', width: '11px' }}>{graph}</div>;
  };
}
