export function isSelected(id, selected) {
  if (!selected || id === undefined) {
    return -1;
  }

  if (selected instanceof Array) {
    return selected.findIndex((item) => item && id.toLowerCase() === item.toLowerCase());
  } else {
    return selected.toLowerCase() === id.toLowerCase() ? 1 : -1;
  }
}
