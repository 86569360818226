import React, { useEffect } from 'react';

import { Spinner } from '@blueprintjs/core';
import { omit } from 'lodash';
import styled from 'styled-components';

import { Theme } from './Theme';
import { trackedEvents } from '../../../../config/trackedEvents.config';
import { track } from '../../../../lib/analytics';
import Box from '../../../common/Box';

const Style = styled.div`
  overflow: auto;
  text-align: center;
  display: flex;
  width: 100%;
  grid-area: builder;
`;

export function ThemesChooser({ page, updateState, handleChange }) {
  const themes = [
    { previewUrl: '/images/theme/1.png', name: '1' },
    { previewUrl: '/images/theme/2.png', name: '2' },
    { previewUrl: '/images/theme/3.png', name: '3' },
    { previewUrl: '/images/theme/4.png', name: '4' },
    { previewUrl: '/images/theme/5.png', name: '5' },
    { previewUrl: '/images/theme/6.png', name: '6' },
    { previewUrl: '/images/theme/7.png', name: '7' },
    { previewUrl: '/images/theme/8.png', name: '8' },
    { previewUrl: '/images/theme/9.png', name: '9' },
    { previewUrl: '/images/theme/10.png', name: '10' },
    { previewUrl: '/images/theme/11.png', name: '11' },
  ];

  useEffect(() => {
    handleChange(omit(page, 'avatar'));
  }, [JSON.stringify(page)]);

  if (!page) {
    return <Spinner />;
  }

  return (
    <Style>
      <Box style={{ width: '100%', marginTop: '10px' }} className="" header="Themes">
        <div
          style={{
            display: 'flex',
            maxWidth: '100%',
            overflow: 'auto',
            cursor: 'pointer',
          }}
        >
          {themes.map((t, i) => (
            <Theme
              selected={page.theme === t.name}
              onClick={() =>
                updateState((draft) => {
                  track(trackedEvents.changeTheme, t);
                  draft.page.theme = t.name;
                  draft.page.css = {};
                  draft.page.blocks.forEach((b) => {
                    b.css = {};
                  });
                })
              }
              key={i}
              theme={t}
            />
          ))}
        </div>
      </Box>
    </Style>
  );
}
