import './SearchRedirects.css';
import React, { Component } from 'react';

import { connect } from 'react-redux';
import type { filter } from 'store/audienceBuilder/redirects';

import Button, { CancelButton } from 'components/common/Button';
import Combo from 'components/common/Combo';
import DropDown from 'components/common/dropDown/DropDown';
import { TagsDropDown } from 'components/common/dropDown/DropDowns';
import { sd } from 'lib/safe';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { Actions } from '../../../actions';

const cookieConsentDropDownValues = [
  { id: 'any', value: 'Any' },
  { id: 'true', value: 'Active' },
  { id: 'false', value: 'Disable' },
];

type State = {
  sort: string,
  search: string,
  searchField: string,
  open: boolean,
  tags: Array<string>,
  cookieConsent: 'any' | 'true' | 'false',
  searchDetails: Array<string>,
};

const initialState: State = {
  sort: '-createdAt',
  searchField: 'all',
  search: '',
  open: false,
  tags: [],
  cookieConsent: 'any',
  searchDetails: [],
};

class SearchRedirects extends Component<{ account: accountT, filter: filter }, State> {
  constructor(props: { account: accountT, filter: filter }) {
    super(props);
    this.state = { ...initialState, ...props.filter };
  }

  handleSearchChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const { value } = (event.currentTarget: HTMLInputElement);

    this.setState({ search: value, open: true });
  };

  handleMaskClick = (event: MouseEvent) => {
    this.setState({ open: false });
  };
  handleSearchClick = (event: MouseEvent) => {
    if (
      this.state.open &&
      event.target &&
      event.target.className &&
      event.target.className.indexOf('drop-down__title') >= 0
    ) {
      this.setState({ open: false });
    }
  };

  render() {
    const { style, filterConfig } = this.props;

    return (
      <div className="search-redirects__container" style={style}>
        {!!filterConfig.open && this.renderContent()}
        {this.renderSearchSubTitle()}
      </div>
    );
  }

  updateSearchDetails = (_) => {
    const { searchField, search, tags, cookieConsent } = this.state;
    const searchDetails: Array<string> = [];

    if (search.length > 0 || tags.length > 0 || cookieConsent !== 'any') {
      if (search.length > 0) {
        searchDetails.push(`${search}${searchField === 'all' ? '' : ` on ${searchField}`}`);
      }

      if (tags.length > 0) {
        searchDetails.push('tags');
      }

      if (cookieConsent !== 'any') {
        searchDetails.push(`GDPR consent ${cookieConsent === 'true' ? 'active' : 'disable'}`);
      }
    }

    this.setState({ searchDetails });
  };

  renderContent() {
    const { open } = this.state;

    return (
      <>
        <div className="search-redirects" onClick={this.handleSearchClick}>
          {this.renderSearch()}
        </div>
        {open && this.renderDropdown()}
        {open && (
          <div
            id="search-redirects__dropdown__mask"
            onClick={this.handleMaskClick}
            // onClick={() => this.setState({ open: false })}
          />
        )}
      </>
    );
  }

  renderSearchSubTitle = () => {
    const { searchDetails, open } = this.state;

    if (!open && searchDetails.length > 0) {
      return (
        <div className="search-redirects__subtitle">
          <div>Results for: {searchDetails.join(' and ')}</div>
          <a onClick={() => this.setState({ ...initialState }, () => this.handleSearch())}>Clear</a>
        </div>
      );
    }

    return null;
  };

  renderSearch = () => {
    const { search } = this.state;

    return (
      <input
        className="search-redirects__search"
        placeholder="Search redirects..."
        type="text"
        value={search}
        onChange={this.handleSearchChange}
        onClick={() => this.setState({ open: true })}
      />
    );
  };
  renderDropdown = () => {
    const { account } = this.props;
    const { tags, searchField, cookieConsent } = this.state;

    return (
      <div className="search-redirects__dropdown">
        <div className="search-redirects__dropdown__line">
          Search by
          <Combo handleClick={() => this.setState({ searchField: 'all' })} selected={searchField === 'all'}>
            All
          </Combo>
          <Combo handleClick={() => this.setState({ searchField: 'title' })} selected={searchField === 'title'}>
            Title
          </Combo>
          <Combo handleClick={() => this.setState({ searchField: 'url' })} selected={searchField === 'url'}>
            {getIntlStrings('redirects.redirect.url')}
          </Combo>
          <Combo handleClick={() => this.setState({ searchField: 'slug' })} selected={searchField === 'slug'}>
            Slug
          </Combo>
        </div>
        <div className="search-redirects__dropdown__line search-redirects__dropdown__line--justify">
          <TagsDropDown
            placeholder="Filter by tag"
            account={account}
            selectedTags={tags}
            onSelect={(tags) => this.setState({ tags })}
          />
          <div>GDPR consent</div>
          <DropDown
            id="cookie-consent"
            onSelect={(cookieConsent) => this.setState({ cookieConsent: cookieConsent[0] })}
            selected={[cookieConsent]}
            values={cookieConsentDropDownValues}
            size="small"
          />
        </div>
        <div className="search-redirects__dropdown__line">
          <CancelButton handleClick={() => this.setState({ ...initialState }, () => this.handleSearch())}>
            Cancel
          </CancelButton>
          <Button handleClick={this.handleSearch}>Apply search</Button>
        </div>
      </div>
    );
  };

  componentDidMount() {
    this.handleSearch();
    document.addEventListener('keyup', this.handleKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKey, false);
  }

  handleSearch = () => {
    const { account, updateFilterAndLoad, filter } = this.props;

    const { search, searchField, sort, tags, cookieConsent } = this.state;

    updateFilterAndLoad({
      ...filter,
      accountId: account.id,
      search,
      searchField,
      sort,
      tags,
      cookieConsent,
    });
    this.setState({ open: false }, this.updateSearchDetails());
  };

  handleKey = (event: KeyboardEvent) => {
    const { open } = this.state;

    if (open) {
      const { search } = this.state;

      if (search.length > 0) {
        switch (event.key.toLowerCase()) {
          case 'enter':
            this.handleSearch();
            break;
          default:
        }
      }
    }
  };
}

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  updateFilterAndLoad: (filter) => dispatch(Actions.api.audienceBuilder.redirect.search.request(filter)),
});

const mapStateToProps = function (state): {} {
  state.abRedirects = sd(state.abRedirects, []);

  return {
    filter: state.abRedirects.filter,
    filterConfig: state.front.filterConfig,
  };
};

export default connect(mapStateToProps, dispatchToProps)(SearchRedirects);
