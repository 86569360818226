import './TagsList.css';

import React from 'react';

function TagsList({ tags, label = false }: { tags: Array<string>, label: boolean }) {
  return (
    <div className="tags-list ellipsis">
      {tags.length > 0 && label && `Tags: ${tags.join(', ')}`}
      {tags.length > 0 && !label && tags.join(', ')}
      {tags.length === 0 && 'No tags'}
    </div>
  );
}

export default TagsList;
