type stateT = {
  notices: Array<noticeT>,
};

// ACTIONS
export type actionT = { type: 'ADD_NOTICE', notice: noticeT } | { type: 'REMOVE_NOTICE', id: string };

export function removeNotice(id: string): action {
  return {
    type: 'REMOVE_NOTICE',
    id,
  };
}

// STORE
const initalNoticesState: state = {
  notices: [],
  //   {
  //     id: "1",
  //     message: "Your pixel has been added!",
  //     type: "ok"
  //   },
  //   {
  //     id: "2",
  //     message: "Whoops! This redirect can’t be shortened due to an error.",
  //     type: "error"
  //   }
  // ]
};

export function notices(state: stateT = initalNoticesState, action: actionT): state {
  const { notices } = state;

  switch (action.type) {
    case 'ADD_NOTICE':
      return {
        notices: [...notices, action.notice],
      };
    case 'REMOVE_NOTICE':
      const noticeId: * = action.id;

      return {
        notices: notices.filter((n: noticeT) => n.id !== noticeId),
      };
    default:
      return state;
  }
}

// Thunks
export function addNotice({
  message,
  type = 'ok',
  duration = 2000,
}: {
  message: string,
  type?: 'error' | 'ok' | 'warning',
  duration?: number,
}) {
  if (message === undefined || message.length === 0) {
    message = 'Unexpected error';
  }

  return function (dispatch: (action) => void, getState: {}, axios: () => void) {
    const notice: noticeT = { message, type, id: generateUID(message) };

    dispatch({
      type: 'ADD_NOTICE',
      notice,
    });
    setTimeout(() => {
      dispatch(removeNotice(notice.id));
    }, duration);
  };
}

// helpers
function generateUID(message: string): string {
  let hash: number = 0;
  let i: number;
  let chr: number;

  if (message.length === 0) {
    return `${Date.now()}${hash}`;
  }

  for (i = 0; i < message.length; i++) {
    chr = message.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return `${Date.now()}${hash}`;
}
