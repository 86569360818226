import React, { useContext, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, message, Popover } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import fileDownload from 'react-file-download';

import { AccountsFilterContext } from './AccountsFilterProvider.jsx';
import { exportAllMetrics } from '../../../lib/export';

export const AgencyExportButton = () => {
  const { ctx } = useContext(AccountsFilterContext);

  const [downloading, setDownloading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const download = async () => {
    setOpen(false);

    if (downloading) {
      return;
    }

    setDownloading(true);

    try {
      let from = _.get(ctx, ['dateRange', 0]);

      if (from) {
        from = moment(from).toISOString();
      }

      let to = _.get(ctx, ['dateRange', 1]);

      if (to) {
        to = moment(to).toISOString();
      }

      const data = await exportAllMetrics({
        from: from || moment().utc().startOf('day').subtract(90, 'd').toISOString(),
        to,
      });

      const date = new Date().toJSON().slice(0, 10);

      fileDownload(data, `${date}_product_level.csv`);
    } catch (e) {
      console.log('err exporting metrics', e); // eslint-disable-line no-console
      message.error(
        'Unable to export data at the moment, please try again later. If the issue persists, please contact our support.',
      );
    } finally {
      setDownloading(false);
    }
  };

  const options = (
    <div style={{ display: 'flex', gap: '3px', justifyContent: 'center', alignItems: 'center' }}>
      <b>Export Grouped By:&nbsp;</b>
      <Button onClick={() => download('product')}>Product</Button>
    </div>
  );

  return (
    <Popover
      placement="bottomRight"
      content={options}
      trigger="hover"
      visible={open}
      onVisibleChange={handleOpenChange}
    >
      <Button loading={downloading}>
        <DownloadOutlined />
      </Button>
    </Popover>
  );
};
