export type Definition = {
  name: string,
};
export type Execution = {
  // eslint-disable-next-line no-use-before-define
  +waitFor: ?WorkerAction,
};
export type Extension = {
  name: string,
};
export type State = ['REQUEST', 'SUCCESS', 'UPDATE', 'ERROR'];

export type ExtraOpts = {
  saveStore: boolean,
};

/**
 * Represent a workeraction
 */
export class WorkerAction {
  definition: Definition;
  +execution: Execution;
  +extensions: Extension[];
  +extraOpts: ExtraOpts;
  +state: State;

  /**
   *
   * @param definition
   * @param execution
   * @param extensions
   */
  constructor(
    definition: Definition,
    execution: ?Execution = {},
    extensions: ?(Extension[]) = [],
    extraOpts: ?ExtraOpts,
  ) {
    this.extraOps = this.definition = definition;
    this.execution = execution;
    this.extensions = extensions;
    this.extraOpts = { saveStore: true, ...extraOpts };
    const workerAction = this;

    extensions &&
      extensions.forEach((extension: Extension) => {
        if (!extension.name) {
          throw new Error('WorkerAction extension must have a name property');
        }

        // $FlowFixMe
        workerAction[extension.name] = () => `${workerAction.definition.name}_${extension.name.toUpperCase()}`;
      });
  }

  do(payload: any): reduxActionT {
    return {
      workerAction: this,
      payload,
      definition: this.definition,
      type: this.definition.name,
    };
  }

  request(payload: Object): reduxActionT {
    const result = this.do();

    result.type += '_REQUEST';
    result.payload = payload;

    return result;
  }

  success(payload: Object): reduxActionT {
    const result = this.do();

    result.type += '_SUCCESS';
    result.payload = payload;

    return result;
  }

  error(payload: Object): reduxActionT {
    const result = this.do();

    result.type += '_ERROR';
    result.payload = payload;

    return result;
  }

  update(payload: Object): reduxActionT {
    const result = this.do();

    result.type += '_UPDATE';
    result.payload = payload;

    return result;
  }

  confirmed(payload: Object): reduxActionT {
    const result = this.do();

    result.type += '_CONFIRMED';
    result.payload = payload;

    return result;
  }
}
