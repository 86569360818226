export const METRICS = {
  CLICKS: 'Clicks',
  ADD_TO_CARTS: 'Add to Cart',
  IMPRESSIONS: 'Impressions',
  PURCHASES: 'Purchases',
  AD_SPEND: 'PixelMe Ad Spend',
  REVENUE: 'Revenue',
  ORGANIC_SALES: 'On-Amazon Organic Sales',
  BSR: 'BSR',
  CLICK_ASIN_SHARE: 'Click-ASIN Share',
  NET_MARGIN: 'Net Margin',
  REFERRAL_BONUS: 'Average Brand Referral Bonus',
  AMAZON_AD_SPEND: 'Amazon Ad Spend',
  TOTAL_AD_SPEND: 'Total Ad Spend',
  TOTAL_AMAZON_SALES: 'Total Amazon Sales',
  PRICE: 'Price',
  ACOS: 'ACOS',
  BLENDED_ACOS: 'Blended ACOS',
  ROAS: 'ROAS',
  BLENDED_ROAS: 'Blended ROAS',
  TACOS: 'TACOS',
  TROAS: 'TROAS',
};

export const MONETARY_METRICS = [
  METRICS.REVENUE,
  METRICS.AD_SPEND,
  METRICS.ORGANIC_SALES,
  METRICS.NET_MARGIN,
  METRICS.REFERRAL_BONUS,
  METRICS.AMAZON_AD_SPEND,
  METRICS.TOTAL_AD_SPEND,
  METRICS.TOTAL_AMAZON_SALES,
  METRICS.PRICE,
];

export const PERCENT_METRICS = [METRICS.ACOS, METRICS.TACOS, METRICS.BLENDED_ACOS, METRICS.CLICK_ASIN_SHARE];

export const FIXED_DECIMAL_METRICS = [METRICS.ROAS, METRICS.BLENDED_ROAS, METRICS.TROAS];

export const TOOLTIPS = {
  ACOS: `ACOS (Advertising Cost of Sale) = Ad Spend / Attribution Sales.
    The percentage of attribution sales spent on attribution campaigns.`,
  BLENDED_ACOS: `Ad Spend / (Attribution Sales + Incremental Sales).
    The percentage of sales including incremental sales spent on attribution campaigns.`,
  ROAS: `ROAS (Return on Advertising Spend) = Attribution Sales / Ad Spend.
    The sales generated from every dollar spent on Ads.`,
  BLENDED_ROAS: `(Attribution Sales + Incremental Sales) / Ad Spend.
    The sales generated include Incremental Sales for every dollar spent on attribution campaign.`,
  CLICK_ASIN_SHARE: `ASIN Clicks / Total Clicks.
    The percentage of clicks an ASIN receives compared to total clicks of all competing ASINs.`,
  TACOS: `TACOS (Total Advertising Cost of Sale) = (Ad Spend + Amazon Ad Spend) / Total Sales.
    The percentage of total sales spent on advertising both on and off Amazon.`,
  TROAS: `TROAS (Total Return on Ad Spend) = Total Sales / (Ad Spend + Amazon Ad Spend).
    The total sales generated for every dollar spent on advertising.`,
  TOTAL_AD_SPEND: 'Total Amount Spent on Amazon and Off Amazon',
  TOTAL_AMAZON_SALES: 'Total Sales for this product and its variations',
  ORGANIC_SALES: 'On Amazon sales that are attributed to organic searches',

  IMPRESSIONS: 'The number of times ads were displayed',
  CLICKS: 'The number of times your ads were clicked',
  REVENUE: 'Attribution Sales generated from these attribution campaigns',
  NET_MARGIN: 'Data calculated by PixelMe based on the product created',
  REFERRAL_BONUS: 'On average, Brands can earn a 10% bonus from their qualifying sales',

  METRICS_MAX: `Max metrics selected. Deselect another to select this one.`,
  KEYWORDS_MAX: `Max keywords selected. Deselect another to select this one.`,

  FILTER_STATUS_WARNING:
    'Paused campaigns are hidden by default. Change filter settings above to view paused campaigns.',
  PRICE: 'The historical sales price of the product',
};

export const MAX_METRICS_SELECTIONS = 4;

/** ROAS is considered infinite if the value is above this limit */
export const ROAS_MAX = 1000000;
const INFINITY_CHAR = '∞';

export const isRoasInfinite = (value: number): boolean => value < 0 || value > ROAS_MAX;

export const VARIATIONS = {
  KEYWORD: 'KEYWORD',
  CREATIVE: 'CREATIVE',
};

export const fixedDecimalFormatter = (value: number): string => (value / 100).toFixed(2);

export const roasFormatter = (value: number, toDecimal?: boolean = true): string => {
  if (isRoasInfinite(value)) {
    return INFINITY_CHAR;
  }

  return toDecimal ? fixedDecimalFormatter(value) : value.toFixed(2);
};

export const roasComparisonFormatter = (value: number): string => {
  const roas = roasFormatter(value);

  return roas === INFINITY_CHAR ? '' : roas;
};
