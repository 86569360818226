import type { axiosParams } from './api';
import { apiCall, apiCallNoErrorHandler } from './api';

/**
 * Return product that exists on amazon for this account
 * @param localStorage
 * @param axios
 * @param params
 * @param productProviderId
 * @returns {Promise<*>}
 */
export function getProductsOnProvider(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  productProviderId,
): Promise<{
  productsMetadata: any[],
}> {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/${productProviderId}/products-metadata`,
    params,
  });
}

export function importProductCampaigns(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  params: { accountId: string },
  productProviderId: string,
  productASIN: string,
): Promise<{
  campaigns: { id: string, status: string }[],
}> {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'post',
    url: `product-providers/${productProviderId}/products/${productASIN}/campaigns/import`,
    params,
  });
}

/**
 * Get product provider SPA details
 * @param localStorage
 * @param axios
 * @param productProviderId
 * @param params
 * @returns {Promise<*>}
 */
export function getProductProviderSPAStatus(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  productProviderId: string,
  params: { accountId: string },
): Promise<spaT> {
  // $FlowFixMe
  return apiCallNoErrorHandler({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `product-providers/${productProviderId}/spa`,
    params,
  });
}
