import React, { useContext } from 'react';

import { Checkbox, Select } from 'antd';
import styled from 'styled-components';

import { MultiSelect } from 'components/common/antd/MultiSelect';

const StyledCheckbox = styled(Checkbox)`
  padding: 5px 12px;
`;

const MenuColumns = styled.div`
  .rc-virtual-list-holder-inner {
    columns: ${(props) => props.$columns};
    display: block !important;
  }
`;

export const CheckboxMultiSelect = ({
  options = [],
  value = [],
  onChange = () => {},
  placeholder = '',
  hasDarkPlaceholder = true,
  hideTags = true,
  context,
  selectAllLabel,
  columns = 1,
  footer,
  dontShowSelectAllOption = false,
  ...props
}) => (
  <MultiSelect
    placeholder={placeholder}
    hasDarkPlaceholder={hasDarkPlaceholder}
    hideTags={hideTags}
    value={value}
    onChange={(v) => {
      if (dontShowSelectAllOption) {
        onChange(v);
      } else {
        v.length === options.length ? onChange([]) : onChange(v);
      }
    }}
    dropdownRender={(menu) => (
      <>
        {!dontShowSelectAllOption && (
          <StyledCheckbox
            checked={value.length === 0}
            onChange={() => {
              onChange([]);
            }}
          >
            {selectAllLabel ?? placeholder}
          </StyledCheckbox>
        )}
        {columns === 1 ? menu : <MenuColumns $columns={columns}>{menu}</MenuColumns>}
        {footer}
      </>
    )}
    {...props}
  >
    {options.map((item) => (
      <Select.Option key={item.value} value={item.value} label={item.label}>
        <Checkbox indeterminate={value.length <= 0} checked={value.includes(item.value)} />
        &nbsp;
        {item.label}
      </Select.Option>
    ))}
  </MultiSelect>
);
