import React, { Fragment } from 'react';

import { MenuItem } from '@blueprintjs/core';
import * as _ from 'lodash';
import styled from 'styled-components';

import { pixelsFromAccount } from '../../../../../../lib/pixels';
import { sArray } from '../../../../../../lib/safe';
import { getListItems, getListItemsFromString } from '../../../../../../selectors/pixels.selector';
import NewMultiDropDown from '../../../../../common/dropDown/NewMultiDropDown';
import Pixel from '../../../../../common/Pixel';

const Style = styled.div.attrs({
  displayName: 'ComposerPixelsMultiSelectStyle',
})`
  .pixel {
    margin-right: 0.4rem;
    flex-shrink: 0;
  }
`;

export function ComposerPixelsMultiSelect({
  account,
  redirect,
  onChange,
}: {
  account: accountT,
  redirect: redirectT,
  onChange: () => void,
}) {
  const allPixelsListItems = getListItems(pixelsFromAccount(account));
  const selectedPixelListItems = getListItemsFromString(allPixelsListItems, sArray(redirect.pixelsIds));
  const availablePixelsListItems = _.differenceBy(allPixelsListItems, selectedPixelListItems, 'id');

  return (
    <Style>
      <div className="dropdown_inline">
        <div className="dropdowns__header">
          <label htmlFor="pixel-provider">Select your pixel(s)</label>
        </div>
        <NewMultiDropDown
          id="pixel-provider"
          placeholder="Select pixels"
          values={availablePixelsListItems.length > 0 ? availablePixelsListItems : []}
          onSelect={(pixelObjectToSelect, event) => {
            if (event.type === 'keyup') {
              return;
            }

            const pixelObjectToSelectAlreadySelected = _.find(
              redirect.pixelsIds,
              (pixelId) => pixelId === pixelObjectToSelect.id,
            );

            if (!pixelObjectToSelectAlreadySelected) {
              redirect.pixelsIds.push(pixelObjectToSelect.id);
              onChange({ redirect });
            }
          }}
          onRemove={(pixelTagFragmentToRemove) => {
            redirect.pixelsIds = _.remove(redirect.pixelsIds, (pixelId) => pixelTagFragmentToRemove.key !== pixelId);
            onChange({ redirect });
          }}
          selected={selectedPixelListItems}
          renderTag={(pixel) => (
            <Fragment key={pixel.id}>
              <div style={{ display: 'flex' }}>
                <Pixel provider={pixel.providerKey} />
                {pixel.value}
              </div>
            </Fragment>
          )}
          renderItem={(pixel, { handleClick, query }) => {
            if (query && pixel.value.toLowerCase().indexOf(query.toLowerCase()) === -1) {
              return null;
            }

            return (
              <MenuItem
                icon={<Pixel provider={pixel.providerKey} />}
                key={pixel.value}
                text={pixel.value}
                onClick={handleClick}
              />
            );
          }}
          noResults={() => <MenuItem icon="add" text="Add a new Pixel" href={`/${account.id}/ab/settings/pixels`} />}
          createNewItemRenderer={(query: string, active: boolean) => (
            <MenuItem
              icon="add"
              className="bp3-add-option"
              text={`Add "${query}"`}
              active={active}
              href={`/${account.id}/ab/settings/pixels`}
              shouldDismissPopover={false}
            />
          )}
          createNewItemFromQuery={(pixel) => ({ name: pixel.trim() })}
        />
      </div>
    </Style>
  );
}
