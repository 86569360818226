import React, { useState, type Node } from 'react';

import { Form, Input } from 'antd';

import { MatchTypeLabel } from '../Google/constants';
import { MatchTypeSelect } from '../Google/MatchTypeSelect';

export function NegativeKeywordsInput(): Node {
  const [negativeKeyword, setNegativeKeyword] = useState('');

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '1em',
        }}
      >
        <Form.Item style={{ flex: '1', marginBottom: 12 }} label="Negative Keyword">
          <Input size="large" value={negativeKeyword} onChange={(e) => setNegativeKeyword(e.target.value)}></Input>
        </Form.Item>
        <Form.Item
          required
          style={{ flex: '0 0 165px', marginBottom: 12 }}
          label={<MatchTypeLabel />}
          rules={[{ required: true }]}
        >
          {MatchTypeSelect}
        </Form.Item>
      </div>
    </>
  );
}
