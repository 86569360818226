import React, { useState } from 'react';

import * as _ from 'lodash';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Actions } from '../../../actions';
import Box from '../../common/Box';
import DeleteItem from '../../common/DeleteItem';
import Modal from '../../common/Modal';

const Style = styled.div``;

export const UtmsCampaigns = ({ account }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [selected, setSelected] = useState();
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const utms = _.sortBy(account.utmsValues.campaign, 'value');
  const dispatch = useDispatch();

  const renderHeader = () => (
    <div className="settings-utms__list__title">
      <div>UTM campaigns</div>
    </div>
  );

  const renderNoUtms = () => (
    <div className="no-utms">
      <p>There is no campaign in your account.</p>
      <p>To add some, please create at least one redirect using the new campaign.</p>
    </div>
  );
  const renderUtms = () =>
    utms.map((utm, index) => {
      let classes = 'settings-utms__list__line';

      if (index % 2 === 1) {
        classes = `${classes} settings-utms__list__line--odd`;
      }

      return (
        <div key={utm.name} className={classes}>
          <div className="settings-utms__list--name">{utm.value}</div>
          <div className="settings-utms__list--del">
            <DeleteItem
              disabled={isDeleting}
              handleClick={() => {
                setIsModalDisplayed(true);
                setSelected(utm);
              }}
            />
          </div>
        </div>
      );
    });

  const displayModal = () => {
    if (isModalDisplayed) {
      const message = isDeleting ? 'Deleting...' : "Yes I'm sure";

      return (
        <Modal
          disabled={isDeleting}
          okMessage={message}
          handleCancel={() => {
            setIsModalDisplayed(false);
            setIsDeleting(false);
          }}
          handleOk={() =>
            dispatch(
              Actions.api.account.utms.campaigns.remove.request({
                campaign: selected,
              }),
            ).then(() => {
              setIsModalDisplayed(false);
              setIsDeleting(false);
              setSelected(undefined);
            })
          }
        >
          Are you sure you want to delete utm "{selected.value}"?
        </Modal>
      );
    }

    return null;
  };

  return (
    <Style>
      <Box className="settings-utms__list" header={renderHeader()}>
        {!utms.length && renderNoUtms()}
        {!!utms.length && (
          <>
            <div className="settings-utms__list__headers settings-utms__list__line">
              <div className="settings-utms__list--name">Name</div>
              <div className="settings-utms__list--del">Delete</div>
            </div>
            {renderUtms()}
          </>
        )}
      </Box>
      {displayModal()}
    </Style>
  );
};
