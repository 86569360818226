import React, { useState } from 'react';

import { Form, Input, message, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { getAccount, transferProduct } from '../../../../../lib/adminTools';
import axios from '../../../../../lib/axios.factory';

export function TransferProductModal(props: {
  account: accountT,
  product: {
    id: string,
    name: string,
  },
  onFinish: Function,
  onCancel: Function,
}) {
  const [form] = useForm();
  const [loadingAdProviders, setLoadingAdProviders] = useState(false);
  const [transferringProduct, setTransferringProduct] = useState(false);
  const [availableAdsProviders, setAvailableAdsProviders] = useState([]);
  const [accountDescription, setAccountDescription] = useState(null);

  const loadAccountAdsProviders = async function (accountID) {
    setAvailableAdsProviders([]);
    setAccountDescription(null);

    if (!validMongoID(accountID)) {
      return;
    }

    setLoadingAdProviders(true);

    try {
      const resp = await getAccount(localStorage, axios, accountID, {
        with: ['ad_providers', 'owner'],
      });

      setAccountDescription(`${resp.data.name} - ${resp.data.owner.name} (${resp.data.owner.email})`);
      setAvailableAdsProviders(
        resp.data.adProviders.filter((p) => p.type === 'GOOGLE').map((p) => ({ value: p.id, label: p.name })),
      );
    } catch (e) {
      message.error('Failed to load target account');

      throw e;
    } finally {
      setLoadingAdProviders(false);
    }
  };

  return (
    <Modal
      visible={props.visible}
      title="Transfer Product"
      width="750px"
      loading={loadingAdProviders || transferringProduct}
      okButtonProps={{ loading: loadingAdProviders || transferringProduct }}
      onCancel={props.onCancel}
      onOk={form.submit}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          productName: props.product.name,
          sourceProductId: props.product.id,
          targetAccountId: '',
          adsProviderId: '',
        }}
        onFinish={async (values) => {
          setTransferringProduct(true);

          try {
            await transferProduct(localStorage, axios, values);
            props.onFinish();
          } catch (e) {
            message.error(e.errorMessage);
          } finally {
            setTransferringProduct(false);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
          }}
        >
          <div style={{ display: 'block', width: '100%' }}>
            <Form.Item label="Product ID" name="sourceProductId" style={{ display: 'none' }}>
              <Input type="text" />
            </Form.Item>
            <Form.Item label="Product Name" name="productName">
              <Input size="large" disabled={true} />
            </Form.Item>
            <Form.Item
              label="Target Account ID"
              name="targetAccountId"
              validateFirst={true}
              rules={[
                { required: true, type: 'string' },
                {
                  message: 'Invalid Account ID',
                  validator: (_, id) => {
                    if (validMongoID(id)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('');
                    }
                  },
                },
              ]}
            >
              <Input
                size="large"
                onChange={(e) => loadAccountAdsProviders(e.target.value)}
                suffix={<small>{accountDescription}</small>}
              />
            </Form.Item>
            <Form.Item
              label="Target Ad Provider"
              name="adsProviderId"
              validateFirst={true}
              rules={[{ required: true, type: 'string' }]}
            >
              <Select size="large" options={availableAdsProviders} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

function validMongoID(s: string): boolean {
  return s && s.match(/^[0-9a-fA-F]{24}$/);
}
