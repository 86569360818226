import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { s } from 'lib/safe';

import { Toggle } from '../common/icons/Toggle';

const Title = styled.div.withConfig({ displayName: 'Title' })`
  font-size: 20px;
  font-weight: 200;
  // margin-right: 1rem;
  // font-size: var(--title-size);
  // font-weight: 500;
  // font-size: 19px;
  // margin-right: 1rem;
  // color: #071629;
  // flex-grow: 1;
`;

const ToggleButton = styled(Toggle).withConfig({ displayName: 'Toggle' })`
  cursor: pointer;
  padding-right: 16px;
  align-items: center;
  display: flex;
  width: 25px;
  height: 33px;

  position: relative;
  right: ${(props) => (props.isExpanded ? '-10px' : '')};
  top: 3px;
`;

const HeaderContainer = styled.div.withConfig({
  displayName: 'HeaderContainer',
})`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.8rem;
  height: 65px;
  align-items: center;
  justify-content: space-between;
`;
const ContentContainer = styled.div.withConfig({
  displayName: 'ContentContainer',
})`
  flex: 1;
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

class LeftSidePanelNoStyle extends Component<{}, { width: number, expanded: boolean }> {
  static FULL_WIDTH = 219;
  static SMALL_WIDTH = 66;
  constructor(props: {}) {
    super(props);
    this.state = {
      width: LeftSidePanelNoStyle.FULL_WIDTH,
      expanded: true,
    };
  }
  toggle() {
    if (this.state.width === LeftSidePanelNoStyle.FULL_WIDTH) {
      this.setState({
        width: LeftSidePanelNoStyle.SMALL_WIDTH,
        expanded: false,
      });
    } else {
      this.setState({ width: LeftSidePanelNoStyle.FULL_WIDTH, expanded: true });
    }
  }
  render() {
    const allowedURLs = ['ab/campaigns'];
    const pathName = s(this.props.location.pathname);

    const isAllowed = allowedURLs.find((el) => pathName.includes(el));

    if (!isAllowed) {
      return '';
    }

    const { className, children } = this.props;

    return (
      <div
        className={className}
        style={{
          width: `${this.state.width}px`,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <HeaderContainer>
          {/* {!!this.state.expanded && <Title />} */}
          {!!this.state.expanded && <Title> Link Manager </Title>}
          <ToggleButton
            onClick={this.toggle.bind(this)}
            isExpanded={this.state.expanded}
            style={{
              transform: `rotate(${this.state.expanded ? '0deg' : '180deg'})`,
            }}
          />
        </HeaderContainer>
        {!!this.state.expanded && <ContentContainer>{children}</ContentContainer>}
      </div>
    );
  }
}

export const LeftSidePanel = withRouter(styled(LeftSidePanelNoStyle).withConfig({
  displayName: 'LeftSidePanel',
})`
  transition: width 0.1s;
  grid-area: left;
  box-shadow: 2px 0px 9px rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  flex-direction: column;
`);
