export function pixelsFromAccount(account: accountT): Array<{
  id: string,
  value: string,
  providerKey: string,
  hasAccessToken?: boolean,
}> {
  const providersMap = {};

  account.providers.forEach(({ id, name }) => (providersMap[id] = ` - ${name}`));
  const pixels = account.pixels.map(({ id, name, providerKey, hasAccessToken }) => ({
    id,
    value: `${name} ${providersMap[providerKey] ? providersMap[providerKey] : 'Unknown'}`,
    providerKey,
    hasAccessToken,
  }));

  if (account.customHeader.length > 0) {
    pixels.push({ id: 'cst_h_', value: 'Custom Header', providerKey: '' });
  }

  if (account.customBody.length > 0) {
    pixels.push({ id: 'cst_b_', value: 'Custom Body', providerKey: '' });
  }

  return pixels;
}
