import { type Node } from 'react';

import { Spinner, SpinnerSize } from '@blueprintjs/core';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useQueryParams } from 'hooks/useQueryParams';
import { ASIN_AUDIT, useFeatureFlag } from 'lib/configcat';
import { useLocale } from 'locales/LocalizationWrapper';
import { useGetPublicAuditQuery } from 'stores/auditsSlice';

import { AuditPreviewTable } from './AuditPreviewTable';
import { BookCallButton } from './BookCallButton';
import { AUDIT_STATUS, STRATEGIES } from './constants';
import { PageNotAvailable } from './PageNotAvailable';

const Style = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  > .ant-alert {
    .ant-alert-icon {
      color: #041521;
    }
  }
`;

const Description = styled.p`
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
`;

const StyledBookCallButton = styled(BookCallButton)`
  &.ant-btn {
    display: flex;
    align-items: center;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem !important;
    margin: 0 auto;
    height: fit-content;
    > span {
      white-space: pre-wrap;
    }
  }
`;

export const AuditPreviewPage = (): Node => {
  const [isAsinAuditEnabled, isFlagLoading] = useFeatureFlag(ASIN_AUDIT);
  const query = useQueryParams();
  const auditId = query.get('id');
  const { messages } = useIntl();
  const { getIntlStrings } = useLocale();

  const {
    previewData = [],
    status,
    error,
    isLoading: previewLoading,
  } = useGetPublicAuditQuery(auditId, {
    skip: !auditId,
    selectFromResult: ({ data, ...rest }) => ({
      ...rest,
      status: data?.status,
      previewData: data?.results
        ?.filter((x) => !x.isDisqualified)
        .sort((a, b) => {
          const impactScoreA = a.strategy === STRATEGIES.PROFIT ? a.score?.profit : a.score?.ranking;
          const impactScoreB = b.strategy === STRATEGIES.PROFIT ? b.score?.profit : b.score?.ranking;

          return impactScoreB - impactScoreA;
        }),
    }),
  });

  if (isFlagLoading) {
    return null;
  } else if (!isAsinAuditEnabled || !auditId) {
    return <PageNotAvailable />;
  }

  return (
    <Style>
      <Description>{messages.asinAudit.preview.description}</Description>
      <StyledBookCallButton ghost asins={previewData.map((x) => x.product?.asin)} auditId={auditId}>
        {getIntlStrings(
          status === AUDIT_STATUS.IN_PROGRESS || (status === AUDIT_STATUS.DONE && previewData.length > 0)
            ? 'asinAudit.preview.bookCall'
            : 'asinAudit.preview.bookCallEmpty',
        )}
      </StyledBookCallButton>
      {previewLoading ? (
        <Spinner size={SpinnerSize.STANDARD} />
      ) : (
        <AuditPreviewTable
          data={previewData}
          error={error || status === AUDIT_STATUS.FAILED ? messages.error.apiError : ''}
          isLoading={status === AUDIT_STATUS.IN_PROGRESS}
        />
      )}
    </Style>
  );
};
