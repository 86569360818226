import { useState } from 'react';

import { message, Popover, Spin, Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { Analytics } from 'components/common/icons';
import {
  DisabledIconInsideButton,
  IconInsideButton,
} from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';
import { selectAccountsLimitations } from 'stores/accounts';
import { useEditProductMutation } from 'stores/productsSlice';

import Graph from '../../Ranking/Graph';

type KeywordRankButtonProps = {
  account: accountT,
  product: productT,
  keyword: string,
  rankings: any,
  onProductSave: (product: productT) => void,
};

export const KeywordRankButton = ({ account, product, keyword, rankings, onProductSave }: KeywordRankButtonProps) => {
  const limitations = useSelector(selectAccountsLimitations);
  const [editProduct, { isLoading }] = useEditProductMutation();

  if (!product.keywords.includes(keyword)) {
    if (product.keywords.length >= limitations.limitProductKeyword) {
      return (
        <DisabledIconInsideButton>
          <Tooltip placement="top" title="You have reached your limit of tracked keywords">
            <Analytics color="#d93f21"></Analytics>
          </Tooltip>
        </DisabledIconInsideButton>
      );
    }

    const startTracking = async () => {
      editProduct({
        accountId: account.id,
        product: {
          tags: product.tags,
          name: product.name,
          net_margin_micros: product.netMargin,
          keywords: [...product.keywords, keyword],
        },
        providerId: product.providerId,
        productASIN: product.externalId,
      });
      onProductSave(product);
    };

    return (
      <IconInsideButton>
        <Tooltip placement="top" title={`Start tracking Amazon ranking for "${keyword}"`}>
          {isLoading ? <Spin /> : <Analytics color="#d93f21" onClick={startTracking}></Analytics>}
        </Tooltip>
      </IconInsideButton>
    );
  }

  const graphRankings = rankings[keyword] || [];
  const title = `Organic search ranking for "${keyword}"`;

  return (
    <IconInsideButton>
      <Popover
        placement="bottomRight"
        arrowPointAtCenter={true}
        title={title}
        content={
          <div style={{ width: '500px', maxWidth: '100%' }}>
            <Graph total={1} rankings={graphRankings} rankingMode="" />
          </div>
        }
        trigger="click"
      >
        <Analytics color="#2e89e2" />
      </Popover>
    </IconInsideButton>
  );
};
