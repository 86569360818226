export const amazonAttributionIds = [
  { name: 'Amazon Brazil', id: 'A2Q3Y263D00KWC', disabled: true, domainExtension: '.com.br' },
  { name: 'Amazon Canada', id: 'A2EUQ1WTGCTBG2', domainExtension: '.ca' },
  { name: 'Amazon Mexico', id: 'A1AM78C64UM0Y8', domainExtension: '.com.mx' },
  { name: 'Amazon US', id: 'ATVPDKIKX0DER', domainExtension: '.com' },
  { name: 'Amazon United Arab Emirates (U.A.E.)', id: 'A2VIGQ35RCS4UG', disabled: true, domainExtension: '.ae' },
  { name: 'Amazon Germany', id: 'A1PA6795UKMFR9', domainExtension: '.de' },
  { name: 'Amazon Egypt', id: 'ARBP9OOSHTCHU', disabled: true, domainExtension: '.eg' },
  { name: 'Amazon Spain', id: 'A1RKKUPIHCS9HS', domainExtension: '.es' },
  { name: 'Amazon France', id: 'A13V1IB3VIYZZH', domainExtension: '.fr' },
  { name: 'Amazon United Kingdom', id: 'A1F83G8C2ARO7P', domainExtension: '.co.uk' },
  { name: 'Amazon India', id: 'A21TJRUUN4KGV', disabled: true, domainExtension: '.in' },
  { name: 'Amazon Italy', id: 'APJ6JRA9NG5V4', domainExtension: '.it' },
  { name: 'Amazon Netherlands', id: 'A1805IZSGTT6HS', disabled: true, domainExtension: '.nl' },
  { name: 'Amazon Poland', id: 'A1C3SOZRARQ6R3', disabled: true, domainExtension: '.pl' },
  { name: 'Amazon Saudi Arabia', id: 'A17E79C6D8DWNP', disabled: true, domainExtension: '.sa' },
  { name: 'Amazon Sweden', id: 'A2NODRKZP88ZB9', disabled: true, domainExtension: '.se' },
  { name: 'Amazon Turkey', id: 'A33AVAJ2PDY3EV', disabled: true, domainExtension: '.com.tr' },
  { name: 'Amazon Singapore', id: 'A19VAU5U5O7RUS', disabled: true, domainExtension: '.sg' },
  { name: 'Amazon Australia', id: '.au	A39IBJ37TRP1C6', disabled: true, domainExtension: '.com.au' },
  { name: 'Amazon Japan', id: 'A1VC38T7YXB528', disabled: true, domainExtension: '.jp' },
];
