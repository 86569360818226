import './SideModal.css';
import type { Node } from 'react';
import React, { Fragment } from 'react';

import Button, { CancelButton } from './Button';
import { Buttonize } from './Buttonize';
import { Close } from './icons/Close';

export default function SideModal({
  children,
  footer,
  handleOk,
  handleCancel,
  okMessage = 'Yes',
  cancelMessage = 'Cancel',
  disabled = false,
  okOnly = false,
  cancelOnly = false,
  displayButtons = true,
  okOnEnterKey = true,
  className = '',
  title,
  okStyle = {},
}: {
  children: Node,
  footer: Node,
  handleOk: () => void,
  handleCancel?: () => void,
  okMessage?: string,
  cancelMessage?: string,
  disabled?: boolean,
  okOnly?: boolean,
  cancelOnly?: boolean,
  displayButtons?: boolean,
  okOnEnterKey?: boolean,
  className?: string,
  title?: string,
  okStyle?: Object,
}) {
  function enterPressed(event) {
    if (okOnEnterKey) {
      const code = event.keyCode || event.which;

      if (code === 13) {
        // 13 is the enter keycode
        handleOk && handleOk();
      }
    }
  }

  const stopPropagation = (e: Event) => {
    /* e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();*/
  };

  return (
    <Fragment>
      <div className={`side-modal-mask ${className}`} onMouseDown={handleCancel}></div>
      <div
        className="side-modal box"
        onClick={stopPropagation}
        onMouseDown={stopPropagation}
        onKeyPress={enterPressed.bind(this)}
      >
        {title && (
          <div className="side-modal__title__container">
            <div className="side-modal__title">{title}</div>
            <Buttonize className="side-modal__close-button">
              <Close onClick={handleCancel} />
            </Buttonize>
          </div>
        )}
        <div className="side-modal__content-under-title">
          <div className="side-modal__content">{children}</div>
        </div>
        <div className="side-modal__footer">
          {!footer && displayButtons && (
            <>
              <div className="side-modal__buttons">
                {!cancelOnly && (
                  <Button style={okStyle} disabled={disabled} handleClick={handleOk} className="side-modal__button">
                    {okMessage}
                  </Button>
                )}
                {!okOnly && (
                  <CancelButton disabled={disabled} handleClick={handleCancel} className="side-modal__button">
                    {cancelMessage}
                  </CancelButton>
                )}
              </div>
            </>
          )}
          {!!footer && footer}
        </div>
      </div>
    </Fragment>
  );
}
