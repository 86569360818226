import React from 'react';

import { Card } from 'antd';
import Button from 'antd/lib/button';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { selectCurrentAccount } from 'stores/accounts';

import { AmazonOAuthErrorContainer } from './AmazonOAuthErrorContainer';

const Style = styled.div`
  .boxed {
    border: 1px solid white;
    border-radius: 2px;
    padding: 0.1em;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

/**
 * Page to display Amazon Login Error
 * It shows when there is no Amazon Attribution profiles in the selected marketplace
 * @returns {JSX.Element}
 * @constructor
 */
export function AmazonOAuthError() {
  const account = useSelector(selectCurrentAccount);

  return (
    <AmazonOAuthErrorContainer title="Unable to connect Amazon" description={<></>}>
      <Style>
        <Card className="card" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div style={{ display: 'flex', gap: '2em', alignItems: 'center' }}>
            <div style={{ textAlign: 'left', flex: '1 0 500px' }}>
              <h1>Oops!</h1>
              <br />
              <div>
                <b>There's no Amazon attribution account detected.</b>
                <br />
                <br />
                Please be sure you are using the right account with the right marketplace.
                <br />
                <br />
                <br />
                No Amazon attribution account yet? <br />
                Please request access <a href="https://sellercentral.amazon.com/attribution">here</a>.
              </div>
              <br />
              <br />
              <br />
              <Button href={`/${_.get(account, 'id')}/amazon/account`}>Go Back</Button>
            </div>
            <div style={{ flex: '1 1 466px' }}>
              <svg width="100%" height="323" viewBox="0 0 466 323" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="clip0">
                  <path
                    d="M333.188 220.859C333.188 250.09 315.825 260.296 294.407 260.296C272.988 260.296 255.625 250.09 255.625 220.859C255.625 191.628 294.407 154.441 294.407 154.441C294.407 154.441 333.188 191.628 333.188 220.859Z"
                    fill="#F8A51B"
                  />
                  <path
                    d="M293.392 231.365L309.922 201.1L293.454 227.527L293.633 216.528L305.025 194.631L293.68 213.616L294.001 193.833L306.201 176.401L294.052 190.722L294.252 154.445L293.046 200.412L280.692 181.487L292.896 204.291L291.741 226.386L291.706 225.799L277.408 205.804L291.663 227.871L291.518 230.634L291.492 230.676L291.505 230.903L288.572 260.387H292.49L292.96 258.005L307.179 235.993L292.995 255.829L293.392 231.365Z"
                    fill="#3F3D56"
                  />
                  <path
                    d="M466.001 126.874C466.001 182.712 432.833 202.209 391.918 202.209C351.004 202.209 317.836 182.712 317.836 126.874C317.836 71.0353 391.918 0 391.918 0C391.918 0 466.001 71.0353 466.001 126.874Z"
                    fill="#F2F2F2"
                  />
                  <path
                    d="M389.218 193.667L389.977 146.936L421.553 89.1218L390.096 139.605L390.437 118.592L412.199 76.7645L390.527 113.032V113.032L391.141 75.2398L414.444 41.9398L391.237 69.2971L391.621 0L389.212 91.7369L389.41 87.9525L365.717 51.6574L389.03 95.2169L386.822 137.424L386.756 136.304L359.443 98.1088L386.674 140.261L386.398 145.54L386.348 145.619L386.371 146.053L380.77 253.135H388.253L389.151 197.825L416.314 155.776L389.218 193.667Z"
                    fill="#3F3D56"
                  />
                  <path
                    d="M462.959 243.785C462.959 260.451 354.934 322.999 228.653 322.999C102.371 322.999 0 290.628 0 273.962C0 257.295 99.8586 277.105 226.14 277.105C352.421 277.105 462.959 227.119 462.959 243.785Z"
                    fill="#3F3D56"
                  />
                  <path
                    opacity="0.1"
                    d="M462.959 243.785C462.959 260.451 354.934 322.999 228.653 322.999C102.371 322.999 0 290.628 0 273.962C0 257.295 99.8586 277.105 226.14 277.105C352.421 277.105 462.959 227.119 462.959 243.785Z"
                    fill="black"
                  />
                  <path
                    d="M462.959 243.787C462.959 260.453 354.934 304.14 228.653 304.14C102.371 304.14 0 290.63 0 273.964C0 257.297 99.8586 258.246 226.14 258.246C352.421 258.246 462.959 227.121 462.959 243.787Z"
                    fill="#3F3D56"
                  />
                  <path
                    opacity="0.1"
                    d="M231.211 284.646C284.837 284.646 328.309 281.281 328.309 277.13C328.309 272.979 284.837 269.613 231.211 269.613C177.586 269.613 134.113 272.979 134.113 277.13C134.113 281.281 177.586 284.646 231.211 284.646Z"
                    fill="black"
                  />
                  <path
                    d="M154.29 126.508C154.29 126.508 182.716 127.035 177.452 132.04C172.188 137.045 152.447 132.04 152.447 132.04L154.29 126.508Z"
                    fill="#A0616A"
                  />
                  <path
                    d="M95.0625 65.7164C95.0625 65.7164 106.539 63.6652 110.53 72.938C114.521 82.2107 122.716 108.656 122.716 108.656C122.716 108.656 144.649 123.186 148.638 122.708C152.627 122.231 159.447 123.205 158.196 127.561C156.945 131.917 157.5 136.856 154.621 137.461C151.741 138.066 147.16 132.288 143.655 135.071C140.15 137.854 108.909 127.689 106.904 125.582C104.898 123.476 88.2305 77.8659 88.2305 77.8659C88.2305 77.8659 88.8972 65.5678 95.0625 65.7164Z"
                    fill="#F8A51B"
                  />
                  <path
                    d="M119.679 261.512L120.995 272.575L109.94 275.473L103.623 272.575V262.829L119.679 261.512Z"
                    fill="#A0616A"
                  />
                  <path
                    d="M89.9364 268.625L91.2524 279.689L80.1979 282.586L73.8809 279.689V269.942L89.9364 268.625Z"
                    fill="#A0616A"
                  />
                  <path
                    d="M120.731 140.601L125.732 158.777L133.102 195.656C133.102 195.656 135.207 204.612 130.996 215.413C126.785 226.213 119.152 261.511 120.731 263.619C122.31 265.726 102.833 267.307 103.096 265.199C103.36 263.092 105.992 234.116 105.992 234.116C105.992 234.116 109.676 208.564 111.519 205.403C113.361 202.242 103.096 194.339 103.096 194.339C103.096 194.339 100.464 224.632 96.5162 227.267C92.5681 229.901 93.0945 270.731 91.2521 271.258C89.4096 271.785 75.9862 273.892 74.407 271.258C72.8277 268.624 74.407 224.632 74.407 224.632C74.407 224.632 83.3559 191.178 81.5135 186.436C79.6711 181.695 80.7239 161.411 80.7239 161.411C80.7239 161.411 73.8805 145.869 77.5654 137.703L120.731 140.601Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M119.941 39.4464C119.941 39.4464 108.886 53.1443 109.413 59.203C109.939 65.2617 92.041 48.1393 92.041 48.1393C92.041 48.1393 104.938 30.7535 104.938 27.0655C104.938 23.3776 119.941 39.4464 119.941 39.4464Z"
                    fill="#A0616A"
                  />
                  <path
                    d="M119.809 44.5842C129.403 44.5842 137.181 36.8003 137.181 27.1984C137.181 17.5964 129.403 9.8125 119.809 9.8125C110.215 9.8125 102.438 17.5964 102.438 27.1984C102.438 36.8003 110.215 44.5842 119.809 44.5842Z"
                    fill="#A0616A"
                  />
                  <path
                    d="M76.0307 130.748C75.2438 133.682 74.2673 137.009 74.4068 139.812C74.4963 141.627 79.3103 142.818 85.9668 143.597C92.1389 144.322 99.8956 144.69 106.947 144.88C114.351 145.08 120.97 145.08 124.153 145.08C133.628 145.08 125.995 139.022 122.31 136.124C118.625 133.226 119.415 92.1325 119.678 86.8641C119.941 81.5956 117.309 66.3171 117.309 63.4195C117.309 60.5218 111.116 52.959 111.116 52.959C111.116 52.959 109.676 57.0974 103.886 51.5655C98.0953 46.0337 90.4623 44.4531 90.4623 44.4531C84.6718 46.5605 74.9332 69.7416 74.4068 73.693C74.1936 75.2972 74.3699 83.5133 74.7279 92.8938C75.2464 106.605 76.1518 122.813 76.7756 124.533C77.2678 125.89 76.723 128.166 76.0307 130.748Z"
                    fill="#F8A51B"
                  />
                  <path
                    d="M104.151 146.133L105.993 152.718L98.8863 160.094L96.5176 149.821L104.151 146.133Z"
                    fill="#A0616A"
                  />
                  <path
                    d="M106.517 269.675C106.517 269.675 112.045 275.997 116.256 271.783C120.467 267.568 120.204 264.934 120.994 264.934C121.783 264.934 133.628 275.47 133.628 275.47C133.628 275.47 153.894 280.212 142.84 283.637C131.785 287.061 100.464 284.69 100.464 283.637C100.464 282.583 99.674 268.622 102.569 268.622L106.517 269.675Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M76.7751 276.788C76.7751 276.788 82.3025 283.111 86.5137 278.896C90.725 274.681 90.4618 272.047 91.2514 272.047C92.0411 272.047 103.885 282.584 103.885 282.584C103.885 282.584 124.152 287.325 113.098 290.75C102.043 294.174 70.7214 291.804 70.7214 290.75C70.7214 289.696 69.9318 275.735 72.827 275.735L76.7751 276.788Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M100.307 41.0765C99.7462 41.4393 99.0156 41.807 98.4537 41.4453C96.3647 36.2309 94.8032 30.8201 93.7927 25.2939C93.4379 23.3528 93.1916 21.1949 94.2691 19.5424C94.694 18.8906 95.3058 18.3576 95.6171 17.6444C96.0884 16.5643 95.7782 15.3141 95.9348 14.146C96.2629 11.6987 98.5744 9.99179 100.899 9.16396C103.223 8.33613 105.748 8.07934 107.963 6.99307C110.067 5.96163 111.752 4.25103 113.703 2.95294C115.654 1.65485 118.152 0.767304 120.325 1.64446C122.247 2.42044 123.444 4.38103 125.261 5.3793C126.493 6.05673 127.924 6.24524 129.3 6.53603C133.124 7.34811 136.715 9.01766 139.801 11.4191C140.552 11.9552 141.2 12.6233 141.713 13.3909C144.044 17.2321 139.87 22.4543 141.711 26.5532L137.731 23.4093C136.667 22.4787 135.468 21.716 134.175 21.1479C132.856 20.648 131.295 20.5876 130.097 21.3323C128.416 22.3773 127.96 24.5604 127.373 26.4517C126.786 28.3431 125.487 30.4403 123.509 30.3919C120.819 30.326 119.878 26.5683 117.463 25.3786C115.889 24.603 113.908 25.1138 112.566 26.2449C111.225 27.3759 110.42 29.017 109.829 30.6703C109.46 31.7034 109.114 32.868 108.458 33.7636C107.736 34.7513 106.811 34.8307 106.039 35.5683C104.029 37.4883 102.793 39.469 100.307 41.0765Z"
                    fill="#2F2E41"
                  />
                  <path
                    opacity="0.1"
                    d="M76.0314 130.747C79.303 135.497 82.9905 140.396 85.9674 143.597C92.1396 144.321 99.8963 144.69 106.948 144.88C105.688 143.171 104.113 141.718 102.307 140.602C98.8856 138.494 90.7262 113.469 90.7262 113.469C90.7262 113.469 100.728 87.654 103.36 77.9074C105.992 68.1608 95.7271 62.6289 95.7271 62.6289C90.9894 58.6776 82.83 67.8974 82.83 67.8974C82.83 67.8974 78.6661 80.4679 74.7285 92.8935C75.247 106.605 76.1525 122.813 76.7763 124.533C77.2684 125.89 76.7236 128.166 76.0314 130.747Z"
                    fill="black"
                  />
                  <path
                    d="M94.9364 60.5196C94.9364 60.5196 105.201 66.0514 102.569 75.798C99.9373 85.5447 89.9355 111.36 89.9355 111.36C89.9355 111.36 98.0948 136.385 101.517 138.492C104.938 140.6 109.676 145.605 105.991 148.239C102.306 150.873 99.6741 155.088 97.042 153.771C94.41 152.454 94.41 145.078 89.9355 145.078C85.461 145.078 67.2998 117.682 67.0366 114.785C66.7734 111.887 82.0393 65.788 82.0393 65.788C82.0393 65.788 90.1987 56.5682 94.9364 60.5196Z"
                    fill="#F8A51B"
                  />
                  <path
                    d="M308.386 132.522L305.425 129.559C303.698 127.83 301.355 126.859 298.913 126.859C296.47 126.859 294.128 127.83 292.401 129.559L234.966 187.04L177.532 129.559C175.805 127.83 173.463 126.859 171.02 126.859C168.578 126.859 166.235 127.83 164.508 129.559L161.547 132.522C160.692 133.378 160.013 134.394 159.551 135.513C159.088 136.631 158.85 137.83 158.85 139.04C158.85 140.25 159.088 141.449 159.551 142.567C160.013 143.685 160.692 144.701 161.547 145.557L218.981 203.039L161.547 260.52C160.692 261.376 160.013 262.392 159.551 263.51C159.088 264.629 158.85 265.827 158.85 267.038C158.85 268.248 159.088 269.447 159.551 270.565C160.013 271.683 160.692 272.699 161.547 273.555L164.508 276.519C166.235 278.247 168.578 279.218 171.02 279.218C173.463 279.218 175.805 278.247 177.532 276.519L234.966 219.037L292.401 276.519C294.128 278.247 296.47 279.218 298.913 279.218C301.355 279.218 303.698 278.247 305.425 276.519L308.386 273.555C310.113 271.827 311.083 269.482 311.083 267.038C311.083 264.593 310.113 262.249 308.386 260.52L250.952 203.039L308.386 145.557C310.113 143.829 311.083 141.484 311.083 139.04C311.083 136.595 310.113 134.251 308.386 132.522Z"
                    fill="#FF6584"
                  />
                  <path
                    opacity="0.1"
                    d="M159.498 263.643L215.227 207.869L214.69 207.332L161.548 260.518C160.658 261.408 159.96 262.472 159.498 263.643Z"
                    fill="black"
                  />
                  <path
                    opacity="0.1"
                    d="M160.754 134.391C162.481 132.663 164.823 131.691 167.266 131.691C169.708 131.691 172.051 132.663 173.778 134.391L231.212 191.872L288.646 134.391C290.373 132.663 292.716 131.691 295.158 131.691C297.601 131.691 299.943 132.663 301.67 134.391L304.631 137.355C305.902 138.626 306.772 140.242 307.137 142.003C307.501 143.763 307.342 145.592 306.681 147.264L308.386 145.557C309.242 144.701 309.92 143.685 310.383 142.567C310.846 141.449 311.084 140.25 311.084 139.04C311.084 137.83 310.846 136.631 310.383 135.513C309.92 134.394 309.242 133.378 308.386 132.522L305.425 129.559C303.698 127.83 301.356 126.859 298.913 126.859C296.471 126.859 294.128 127.83 292.401 129.559L234.967 187.04L177.533 129.559C175.806 127.83 173.463 126.859 171.021 126.859C168.578 126.859 166.236 127.83 164.509 129.559L161.548 132.522C160.658 133.412 159.96 134.476 159.498 135.648L160.754 134.391Z"
                    fill="black"
                  />
                  <path
                    opacity="0.1"
                    d="M251.487 203.574L247.195 207.869L304.629 265.351C305.9 266.622 306.771 268.238 307.135 269.999C307.499 271.76 307.341 273.589 306.679 275.261L308.385 273.554C309.24 272.698 309.918 271.682 310.381 270.563C310.844 269.445 311.082 268.247 311.082 267.036C311.082 265.826 310.844 264.627 310.381 263.509C309.918 262.391 309.24 261.375 308.385 260.519L251.487 203.574Z"
                    fill="black"
                  />
                  <path
                    d="M66.876 241.9C66.876 266.178 52.4554 274.655 34.6665 274.655C16.8777 274.655 2.45703 266.178 2.45703 241.9C2.45703 217.623 34.6665 186.738 34.6665 186.738C34.6665 186.738 66.876 217.623 66.876 241.9Z"
                    fill="#F8A51B"
                  />
                  <path
                    d="M33.824 250.623L47.5527 225.487L33.8758 247.436L34.0242 238.3L43.4858 220.114L34.0635 235.882L34.3302 219.451L44.4617 204.973L34.372 216.867L34.5386 186.738L33.5363 224.915L23.2765 209.198L33.412 228.137L32.4524 246.487L32.424 246L20.5488 229.394L32.3877 247.721L32.2678 250.016L32.2462 250.051L32.2561 250.239L29.8209 274.727H33.0746L33.4651 272.749L45.275 254.467L33.4944 270.941L33.824 250.623Z"
                    fill="#3F3D56"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="466" height="323" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </Card>
      </Style>
    </AmazonOAuthErrorContainer>
  );
}
