let active = true;

export function track(event: string, payload: any) {
  if (!active) {
    console.info('FAKE ANALYTICS => track', event, payload);
  }
}

export function identify(userId: string, payload: any) {
  if (!active) {
    console.info('FAKE ANALYTICS => identify', userId, payload);
  }
}

export function traits(payload: any) {
  if (!active) {
    console.info('FAKE ANALYTICS => org', payload);
  }
}

export function page() {
  if (!active) {
    console.info('FAKE ANALYTICS => page');
  }
}

export function setActive(newActive: boolean) {
  active = newActive;
}
