import React, { Fragment, useEffect } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Modal, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Input from 'antd/lib/input';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { trackedEvents } from '../../../../config/trackedEvents.config';
import { createAdsProvider } from '../../../../lib/adsPlateformApi';
import { track } from '../../../../lib/analytics';
import { LimitedButton } from '../../../common/antd/LimitedButton';
import { AdsProviderLogo } from '../common/AdsProviderLogo';

const ProviderButton = styled(LimitedButton)`
  height: 51px;
  padding: 1em;
  display: flex;
  gap: 1em;
`;
const { confirm } = Modal;

const Style = styled.div`
  a {
    text-decoration: none !important;
  }
  .btn-text {
    width: 180px;
  }
`;

/**
 * Generic Ads Provider Login Button
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function GenericAdsProviderLoginButton(props: { account: accountT }) {
  const [form] = useForm();

  async function load() {}

  useEffect(() => {
    load().then();
  }, []);

  return (
    <Style>
      <a>
        <ProviderButton
          icon={<AdsProviderLogo providerType="Generic" style={{ height: 22 }} />}
          feature="Other"
          limitationTest={(limitations, usage) => true}
          onClick={() => {
            confirm({
              title: 'Add a new ad platform',
              icon: <ExclamationCircleOutlined />,
              onOk: form.submit,
              content: (
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={async (values) => {
                    await createAdsProvider(
                      localStorage,
                      axios,
                      { accountId: props.account.id },
                      {
                        adsProvider: {
                          name: values.externalAccountResourceName,
                          refreshToken: values.externalAccountResourceName,
                          accessToken: values.externalAccountResourceName,
                          externalAccountId: values.externalAccountResourceName,
                          externalAccountDescriptiveName: values.externalAccountDescriptiveName,
                          externalAccountResourceName: values.externalAccountResourceName,
                          providerType: 'GENERIC',
                        },
                      },
                    ).then((_) => {
                      track(trackedEvents.connectGeneric);
                      setTimeout(() => {
                        //  don't use history here, we need to reload the page to load the account
                        window.location.href = `/${props.account.id}/adsprovider`;
                      }, 500);
                    });
                  }}
                >
                  <Fragment>
                    <Form.Item
                      label="Platform type"
                      name="externalAccountDescriptiveName"
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: 'Please select a type of platform',
                        },
                      ]}
                    >
                      <Select size="large">
                        <Select.Option value="TikTok influencer">TikTok influencer</Select.Option>
                        <Select.Option value="Social Media Influencer">Social Media Influencer</Select.Option>
                        <Select.Option value="Pinterest">Pinterest</Select.Option>
                        <Select.Option value="Twitter">Twitter</Select.Option>
                        <Select.Option value="Email">Email</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Provider Name"
                      name="externalAccountResourceName"
                      required={true}
                      rules={[
                        {
                          required: true,
                          message: 'Please set a name! min:8',
                          min: 8,
                          max: 200,
                        },
                      ]}
                    >
                      <Input size="large" placeholder="Twitter" />
                    </Form.Item>
                  </Fragment>
                </Form>
              ),
            });
          }}
        >
          <p className="btn-text">Create a custom platform</p>
        </ProviderButton>
      </a>
    </Style>
  );
}
