import { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { useQueryParams } from 'hooks/useQueryParams';
import { strings } from 'locales/strings';
import { useUpdateAuditKeywordsMutation } from 'stores/auditsSlice';

import { CompetitorList } from './CompetitorList';
import { STRATEGIES, STRATEGIES_LABELS } from './constants';
import { KeywordsAuditModal } from './KeywordsAuditModal';
import { KeywordTable } from './KeywordTable';
import { ProductKeyMetrics } from './ProductKeyMetrics';
import { ASIN_AUDITS_UPDATE, useFeatureFlag } from '../../../lib/configcat';

const Style = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  margin-bottom: 1.5rem;
`;

const StrategyLabel = styled.p`
  margin-bottom: 0.75rem;
  b {
    font-weight: 600;
  }
`;

const SectionHeader = styled.h2`
  font-weight: 600;
  margin-bottom: 0.75rem;
`;

const KeywordsHeaderContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
`;

export const AuditResultsExpanded = ({ auditItem }) => {
  const [isAuditUpdateEnabled] = useFeatureFlag(ASIN_AUDITS_UPDATE);
  const [isKeywordsModalVisible, showKeywordsModal] = useState(false);
  const [addKeywords, setAddKeywords] = useState([]);
  const [removeKeywords, setRemoveKeywords] = useState([]);
  const [updateAuditKeywords] = useUpdateAuditKeywordsMutation();
  const query = useQueryParams();
  const auditId = query.get('id');

  const { messages } = useIntl();

  const tableData = [
    {
      title: 'Top Keywords',
      keywords: auditItem?.keywords,
    },
    {
      title: 'Rejected Keywords',
      keywords: auditItem?.rejectedKeywords?.map((kw) => ({ ...kw, rejected: true })),
    },
  ];

  const onKeywordChange = (row) => {
    if (row.rejected) {
      if (addKeywords.includes(row.keyword)) {
        setAddKeywords(addKeywords.filter((kw) => kw !== row.keyword));
      } else {
        setAddKeywords([...addKeywords, row.keyword]);
      }
    } else {
      if (removeKeywords.includes(row.keyword)) {
        setRemoveKeywords(removeKeywords.filter((kw) => kw !== row.keyword));
      } else {
        setRemoveKeywords([...removeKeywords, row.keyword]);
      }
    }
  };

  const onFinish = async () => {
    if (addKeywords.length > 0 || removeKeywords.length > 0) {
      try {
        await updateAuditKeywords({
          id: auditId,
          asin: auditItem.product.asin,
          addKeywords,
          removeKeywords,
        });
        message.success(messages.asinAudit.auditImprovement.keywordsUpdated);
      } catch (error) {
        message.error(messages.error.apiError);
      }
    }

    showKeywordsModal(false);
    setAddKeywords([]);
    setRemoveKeywords([]);
  };

  return (
    <Style>
      <div>
        {STRATEGIES[auditItem.strategy] && (
          <StrategyLabel>
            {messages.asinAudit.product.strategy}: <b>{STRATEGIES_LABELS[auditItem.strategy]}</b>
          </StrategyLabel>
        )}
        <ProductKeyMetrics auditItem={auditItem} />
      </div>
      {auditItem.keywords?.length > 0 && (
        <div>
          <KeywordsHeaderContainer>
            <SectionHeader>{messages.asinAudit.topKeywords}</SectionHeader>
            {isAuditUpdateEnabled && (
              <Button size="large" icon={<EditOutlined />} onClick={() => showKeywordsModal(true)} />
            )}
          </KeywordsHeaderContainer>
          <KeywordTable keywords={auditItem.keywords} />
        </div>
      )}
      {auditItem.competitors?.length > 0 && (
        <div>
          <SectionHeader>{messages.asinAudit.topCompetitors}</SectionHeader>
          <CompetitorList competitors={auditItem.competitors} />
        </div>
      )}
      {isKeywordsModalVisible && (
        <KeywordsAuditModal
          tableData={tableData}
          addKeywords={addKeywords}
          setAddKeywords={setAddKeywords}
          removeKeywords={removeKeywords}
          isModalVisible={isKeywordsModalVisible}
          onFinish={onFinish}
          onKeywordChange={onKeywordChange}
          onClose={() => showKeywordsModal(false)}
        />
      )}
    </Style>
  );
};
