import React, { Fragment, useState } from 'react';

import { Button, Input } from 'antd';
import styled from 'styled-components';

import { CALLOUTS } from './mockdataRMF';

const StyleBox = styled.div`
  width: 100%;
  padding: 1em;
  margin: 1em;
`;

const StyleHeading = styled.h1`
  font-size: 1.2rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 40%;
`;

export function CalloutExtensions(props) {
  const [calloutTextOne, setCalloutTextOne] = useState(CALLOUTS.CALLOUT_1);
  const [calloutTextTwo, setCalloutTextTwo] = useState(CALLOUTS.CALLOUT_2);
  const [calloutTextThree, setCalloutTextThree] = useState(CALLOUTS.CALLOUT_3);
  const [calloutTextFour, setCalloutTextFour] = useState(CALLOUTS.CALLOUT_4);
  const [saveCallouts, shouldSaveCallouts] = useState(false);

  return (
    <Fragment>
      <StyleBox>
        <StyleHeading>Callout Extensions</StyleHeading>
        <InputContainer>
          <Input
            placeholder="callouts for Google Ads"
            value={calloutTextOne}
            onChange={(e) => {
              setCalloutTextOne(e.target.value);
            }}
          />
          <Input
            placeholder="callouts for Google Ads"
            value={calloutTextTwo}
            onChange={(e) => {
              setCalloutTextTwo(e.target.value);
            }}
          />
          <Input
            placeholder="callouts for Google Ads"
            value={calloutTextThree}
            onChange={(e) => {
              setCalloutTextThree(e.target.value);
            }}
          />
          <Input
            placeholder="callouts for Google Ads"
            value={calloutTextFour}
            onChange={(e) => {
              setCalloutTextFour(e.target.value);
            }}
          />
          <Button type="primary" style={{ width: '30%' }} onClick={() => shouldSaveCallouts(true)}>
            {saveCallouts ? 'Saved!' : 'Save'}
          </Button>
        </InputContainer>
      </StyleBox>
    </Fragment>
  );
}
