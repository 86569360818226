import './Box.css';

import React from 'react';
import type { Node } from 'react';

import Separator from './Separator';

export default function Box({
  header,
  children,
  className = '',
  overlay,
  style,
}: {
  header: Node,
  children?: Node,
  className?: string,
  overlay?: Node,
  style?: any,
}) {
  return (
    <div className={`settings-box box ${className}`} style={style}>
      {overlay ? overlay : null}
      {header ? (
        <>
          <div className="settings-box__header">{header}</div>
          <Separator />
        </>
      ) : null}

      <div className="settings-box__content">{children}</div>
    </div>
  );
}
