import styled from 'styled-components';

export const RedirectsListLineMenu = styled.div.withConfig({
  displayName: 'RedirectsListLineMenu',
})`
  order: 900;
  opacity: 0;
  width: 160px;
  right: 0px;
  position: sticky;
  height: 50px;
  line-height: 50px;
  background-color: #f4f9fd;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 10px;
  display: flex;
  align-items: center;
  span {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const RedirectsListLineMenuSlideAnimation = styled.div.withConfig({
  displayName: 'RedirectsListLineMenuSlideAnimation',
})`
  margin-left: 140px;
  transition: margin-left 0.3s ease-in-out;
`;
