import React from 'react';

import { Keys, MenuItem } from '@blueprintjs/core';
import _ from 'lodash';

import { useLocale } from 'locales/LocalizationWrapper';
import { getListItem, getListItems } from 'selectors/campaigns.selector';
import { store } from 'stores';

import { Actions } from '../../../../../../actions';
import { DropDownWithLabel } from '../DropDownWithLabel';
import { handleChange } from '../inputs/composerEditUtils';

export function ComposerCampaignSelect({ onChange, redirect, campaigns, selectedCampaign, addCampaign }) {
  const { getIntlStrings } = useLocale();
  const selectedCampaignAsListItem = getListItem(selectedCampaign);

  return (
    <DropDownWithLabel
      values={getListItems(campaigns)}
      selected={selectedCampaignAsListItem}
      noResults={
        <MenuItem
          icon="add"
          text={`Add New Campaign"`}
          className="bp3-add-option"
          onClick={() => {
            store.dispatch(Actions.front.audienceBuilder.campaigns.add.show.request({}));
          }}
        />
      }
      createNewItemRenderer={(query: string, active: boolean, handleClick) => (
        <MenuItem
          icon="add"
          className="bp3-add-option"
          text={`Add "${query}"`}
          active={active}
          onClick={() => {
            const newCampaign = { name: query };

            addCampaign({
              campaignOrSub: newCampaign,
            });
          }}
        />
      )}
      createNewItemFromQuery={(c) => ({ value: _.capitalize(c) })}
      title={getIntlStrings('campaigns.select.title')}
      placeholder={getIntlStrings('campaigns.select.placeholder')}
      onKeyUp={(event) => {
        const newCampaign = { name: event.target.value };

        if (event.keyCode === Keys.ENTER) {
          addCampaign({
            campaignOrSub: newCampaign,
          });
        }
      }}
      onSelect={(selectedCampaign) => {
        handleChange(
          [
            { name: 'campaignId', value: selectedCampaign.id },
            { name: 'subCampaignId', value: undefined },
          ],
          { onChange, redirect },
        );
      }}
      displayLabel
    />
  );
}
