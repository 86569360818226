import React from 'react';

import { useImmer } from 'use-immer';

import { getDefaultRange } from 'selectors/ranges.selector';

export const DashboardFilterContext = React.createContext();

export const STATUS = {
  PAUSED: 1,
  DELETED: 2,
  REJECTED: 3,
  ACTIVE: 4,
  COMPLETED: 5,
};

const DashboardFilterProvider = ({ children, account, filterStatus }) => {
  const [ctx, updateCtx] = useImmer({
    account,
    adsReportsRange: getDefaultRange(),
    showAttributedData: true,
    selectedTags: [],
    filterStatus: undefined,
    filterCampaignStatus: undefined,
    showRank: false,
    filterProviderType: [],
    filterCampaignId: [],
    search: '',
    filterProductByActiveCampaign: '',
  });
  const value = { ctx, updateCtx };

  return <DashboardFilterContext.Provider value={value}>{children}</DashboardFilterContext.Provider>;
};

export default DashboardFilterProvider;
