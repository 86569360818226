import React, { Fragment, useEffect, useState } from 'react';

import { Select } from 'antd';
import deepmerge from 'deepmerge';
import _ from 'lodash';

import { facebookPlacements } from './facebookPlacements';

/**
 * Placement selector for facebook ads
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookPlacementsSelector(props) {
  const [selectedPlacements, setSelectedPlacements] = useState([]);

  useEffect(() => {
    if (!props.value) {
      return;
    }

    const sel = facebookPlacements.filter((p) =>
      Object.keys(p.value).every((k) => {
        console.log('p.value[k]', _.get(props, `value[${k}]`));

        return p.value[k].every((pp) => _.get(props, `value[${k}]`, []).includes(pp));
      }),
    );

    setSelectedPlacements(sel);
  }, [props.value]);

  return (
    <Fragment>
      {/* <PrettyPrintJson data={value}/>*/}
      <Select
        size="large"
        mode="multiple"
        showArrow={true}
        placeholder="Select at least one placement"
        allowClear
        {..._.omit(props, 'adsProvider', 'initialValue')}
        defaultValue={selectedPlacements.map((p) => p.id)}
        value={selectedPlacements.map((p) => p.id)}
        onChange={(placementsId) => {
          const selectedPlacements = placementsId.map((placementId) =>
            facebookPlacements.find((p) => p.id === placementId),
          );
          const selectedValues = selectedPlacements.reduce((acc, p) => {
            const v = deepmerge(p.value, acc);

            return v;
          }, {});

          Object.keys(selectedValues).forEach((k) => {
            selectedValues[k] = _.uniq(selectedValues[k]);
          });

          if (!selectedValues.facebookPositions) {
            selectedValues.facebookPositions = [];
          }

          if (!selectedValues.instagramPositions) {
            selectedValues.instagramPositions = [];
          }

          if (!selectedValues.instagramPositions) {
            selectedValues.instagramPositions = [];
          }

          if (!selectedValues.publisherPlatforms) {
            selectedValues.publisherPlatforms = [];
          }

          if (!selectedValues.devicePlatforms) {
            selectedValues.devicePlatforms = [];
          }

          props.onChange(selectedValues);
        }}
      >
        <Fragment>
          {facebookPlacements.map((l) => (
            <Select.Option key={l.id} id={l.id} className="visible" value={l.id}>
              {l.name}
            </Select.Option>
          ))}
        </Fragment>
      </Select>
    </Fragment>
  );
}
