import React, { Fragment, useState } from 'react';

import { Table } from 'antd';
import styled from 'styled-components';

import { BIDDING_STRATEGY_METRICS } from './mockdataRMF';

export function BiddingStrategyMetrics(props) {
  const Style = styled.div`
    overflow: auto;
    max-width: 100%;
    padding: 16px;

    .ant-table-pagination.ant-pagination {
      justify-content: left;
      margin-left: 1em;
    }

    .ant-table-thead > tr > th {
      text-align: center;
    }

    .ant-table-column-title {
      font-size: 13px;
    }
  `;
  const columns = [
    {
      title: 'Bidding Strategy Type',
      dataIndex: 'biddingStrategyType',
      key: 'biddingStrategyType',
      width: 250,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      width: 100,
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      width: 120,
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      width: 120,
    },
    {
      title: 'Conversions',
      dataIndex: 'conversions',
      key: 'conversions',
      width: 120,
    },
    {
      title: 'Cost per Conversion (CPC)',
      dataIndex: 'costPerConversion',
      key: 'costPerConversion',
      width: 120,
    },
    {
      title: 'Avg. CPC',
      dataIndex: 'avgCPC',
      key: 'avgCPC',
      width: 120,
    },
  ];

  return (
    <Style>
      <Table
        style={{ cursor: 'pointer' }}
        rowKey="id"
        tableLayout="fixed"
        dataSource={BIDDING_STRATEGY_METRICS}
        pagination={{
          defaultPageSize: 10,
        }}
        columns={columns}
      />
    </Style>
  );
}
