type stateT = {
  +asError: boolean,
  +errorMessage: string,
  +loading: boolean,
  +stats2: Array<{ date: number, value: number }>,
  +stats60: Array<{ date: number, value: number }>,
  +total: number,
  +queryId: string,
};

type errorT = { message: string };

// ACTIONS
export type actionT =
  | { type: 'LOAD_ACCOUNT_STATS', queryId: string }
  | {
      type: 'LOAD_ACCOUNT_STATS_SUCCESS',
      stats2: Array<{ date: number, value: number }>,
      stats60: Array<{ date: number, value: number }>,
      total: number,
      queryId: string,
    }
  | { type: 'LOAD_ACCOUNT_STATS_ERROR', error: errorT, queryId: string };

export function loadAccountStats(queryId: string): actionT {
  return {
    type: 'LOAD_ACCOUNT_STATS',
    queryId,
  };
}

export function loadAccountStatsSuccess({
  stats2,
  stats60,
  total,
  queryId,
}: {
  stats2: Array<{ date: number, value: number }>,
  stats60: Array<{ date: number, value: number }>,
  total: number,
  queryId: string,
}): actionT {
  return {
    type: 'LOAD_ACCOUNT_STATS_SUCCESS',
    stats2,
    stats60,
    total,
    queryId,
  };
}

export function loadAccountStatsError(error: errorT, queryId: string): actionT {
  return {
    type: 'LOAD_ACCOUNT_STATS_ERROR',
    error,
    queryId,
  };
}

// STORE
const initialRedirectsState: stateT = {
  asError: false,
  errorMessage: '',
  loading: true,
  stats2: [],
  stats60: [],
  total: -1,
  queryId: '',
};

export function accountStats(state: stateT = initialRedirectsState, action: actionT): stateT {
  switch (action.type) {
    case 'LOAD_ACCOUNT_STATS':
      const { queryId } = action;

      return {
        ...state,
        queryId,
        // loading: true,
        stats: [],
      };
    case 'LOAD_ACCOUNT_STATS_SUCCESS':
      if (state.queryId === action.queryId) {
        const { stats2, stats60, total } = action;

        return {
          ...state,
          loading: false,
          stats2,
          stats60,
          total,
        };
      }

      return state;
    case 'LOAD_ACCOUNT_STATS_ERROR':
      if (state.queryId === action.queryId) {
        return {
          ...state,
          loading: false,
          asError: true,
          errorMessage: action.error.message,
        };
      }

      return state;
    default:
      return state;
  }
}

// Selectors
export function extractStats({ state, period }: { state: stateT, period: '30d' | '7d' | '14d' | '24h' }): {
  value: number,
  percent: number,
  data: Array<number>,
} {
  switch (period) {
    case '30d':
      return extractStatsUsing({ fullData: state.stats60, period: 30 });
    case '7d':
      return extractStatsUsing({ fullData: state.stats60, period: 7 });
    case '14d':
      return extractStatsUsing({ fullData: state.stats60, period: 14 });
    case '24h':
      return extractStatsUsing({ fullData: state.stats2, period: 24 });
    default:
      return { value: -1, percent: 0, data: [] };
  }
}

function extractStatsUsing({
  fullData,
  period,
}: {
  fullData: Array<{ date: number, value: number }>,
  period: number,
}): {
  value: number,
  percent: number,
  data: Array<number>,
} {
  let value = -1;
  let data: Array<number> = [];
  let percent: number = 0;

  const fullDataLength = fullData.length;

  if (fullDataLength < 2 * period) {
    return { data, value, percent };
  }

  const sortedFullData = fullData.sort((val1, val2) => val1.date - val2.date);
  const current = sortedFullData.slice(fullDataLength - period);
  const before = sortedFullData.slice(fullDataLength - period * 2, fullDataLength - period);

  data = current.map((val) => val.value);
  value = data.reduce((total, val) => total + val, 0);
  const beforeTotal = before.map((val) => val.value).reduce((total, val) => total + val, 0);

  percent = (value - beforeTotal) / beforeTotal;

  return { data, value, percent };
}
