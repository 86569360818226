let currentAssets = '';

export function newVersion(fetch: any): Promise<boolean> {
  if (process.env.NODE_ENV === 'production') {
    return new Promise((resolve, reject) => {
      fetch('/asset-manifest.json', { cache: 'no-store' })
        .then((response) => {
          if (response.ok && response.status === 200) {
            response.json().then((json) => {
              const lastAsssets = JSON.stringify(json);

              if (currentAssets === '') {
                currentAssets = lastAsssets;
                resolve(false);
              } else {
                resolve(currentAssets !== lastAsssets);
                currentAssets = lastAsssets;
              }
            });
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  return Promise.resolve(false);
}
