import React from 'react';

import { HourglassOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import { hasExpired, TTLInMinutes } from '../utils/links.utils';

const Style = styled.div`
  .urgent {
    font-size: 14px;
    color: #be000f;
  }
`;

export function LinkTTL({ redirect }) {
  return (
    <Style>
      <span className="urgent">
        {hasExpired(redirect) && <>Your link has expired</>}
        {!hasExpired(redirect) && (
          <>
            Your link will expire in <b>{TTLInMinutes(redirect)}</b> min <HourglassOutlined />
          </>
        )}
      </span>
    </Style>
  );
}
