import React, { Fragment, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Modal } from 'antd';

import Button from 'components/common/Button';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

import { updateAccountsUsageThunk } from '../../../../../../stores/accounts';
import { MutedText } from '../../../../../common/MutedText';
import { FacebookBlockedAccount } from '../../../../AdsProviders/Facebook/FacebookBlockedAccount';

let notAlertMeAgain = false;

export function ComposerSubmit({ account, calling, isFormValid, handleSubmit, redirect, disabled, editPreview }) {
  const handleSubmitEditPreview = () => {
    handleSubmit({ redirect, preview: false });
  };

  const handleCreateLink = () => {
    handleSubmit({ redirect, preview: true });
  };

  let title;

  if (redirect.id) {
    title = getIntlStrings('composer.redirect.edit.title');
  } else {
    title = getIntlStrings('composer.redirect.save.title');
  }

  if (editPreview) {
    return (
      <>
        <button className="button-edit-preview" disabled={!isFormValid || disabled} onClick={handleSubmitEditPreview}>
          Save changes
        </button>
      </>
    );
  }

  return (
    <>
      {redirect.id ? (
        <div className="composer__line__buttons">
          <Button
            loading={calling}
            loadingLabel="Saving..."
            disabled={!isFormValid || disabled}
            handleClick={(_) => handleSubmit({ redirect, preview: false })}
            className="composer__button--pixelize-me"
            style={{ width: '172px', height: '35px' }}
          >
            {title}
          </Button>
        </div>
      ) : (
        <div
          className="composer__line__buttons"
          style={{
            justifyContent: 'space-around',
            display: 'flex',
            paddingRight: '2rem',
            paddingLeft: '2rem',
          }}
        >
          <button className="button-edit-preview" disabled={!isFormValid || disabled} onClick={handleCreateLink}>
            Edit preview
          </button>
          <Button
            loading={calling}
            loadingLabel="Saving..."
            disabled={!isFormValid || disabled}
            handleClick={async (_) => {
              if (localStorage.getItem('NotAlertWhenAmazonInsideLink') != '1' && redirect.url.includes('amazon')) {
                Modal.confirm({
                  className: 'centered-btn',
                  style: { textAlign: 'center' },
                  cancelTex: 'Cancel',
                  okText: 'Continue',
                  width: 447,
                  title: '',
                  icon: (
                    <svg width="73" height="64" viewBox="0 0 73 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M32.5895 48.9796C32.5895 50.0188 33.0015 51.0155 33.7349 51.7503C34.4682 52.4851 35.4629 52.898 36.5 52.898C37.5371 52.898 38.5318 52.4851 39.2651 51.7503C39.9985 51.0155 40.4105 50.0188 40.4105 48.9796C40.4105 47.9404 39.9985 46.9437 39.2651 46.2089C38.5318 45.4741 37.5371 45.0612 36.5 45.0612C35.4629 45.0612 34.4682 45.4741 33.7349 46.2089C33.0015 46.9437 32.5895 47.9404 32.5895 48.9796ZM33.893 24.1633V39.1837C33.893 39.5429 34.1863 39.8367 34.5448 39.8367H38.4552C38.8137 39.8367 39.107 39.5429 39.107 39.1837V24.1633C39.107 23.8041 38.8137 23.5102 38.4552 23.5102H34.5448C34.1863 23.5102 33.893 23.8041 33.893 24.1633ZM72.6476 60.0816L38.7567 1.30612C38.2516 0.432653 37.3799 0 36.5 0C35.6201 0 34.7403 0.432653 34.2433 1.30612L0.352379 60.0816C-0.649684 61.8286 0.604932 64 2.60906 64H70.3909C72.3951 64 73.6497 61.8286 72.6476 60.0816ZM8.81697 57.8041L36.5 9.78776L64.183 57.8041H8.81697Z"
                        fill="#F8A51B"
                      />
                    </svg>
                  ),
                  content: (
                    <Fragment>
                      <br />
                      <h1 style={{ fontWeight: 400, fontSize: 22, width: 246, margin: 'auto' }}>
                        Are you sure you want to create a link?
                      </h1>
                      <br />
                      <MutedText style={{ textAlign: 'center' }}>
                        Link Manager is meant for <b>non-Amazon Sellers</b>.<br />
                        <br />
                        <b>If you are an Amazon Seller</b> and would like to create a campaign or create custom
                        attribution links,
                        <br />
                        <a href={`/${account.id}/adsprovider`}>click here</a> for Amazon Attribution Campaigns.
                        <br />
                        <br />
                        <Checkbox
                          defaultChecked={notAlertMeAgain}
                          onChange={(e) => {
                            notAlertMeAgain = e.target.checked;
                          }}
                        ></Checkbox>{' '}
                        Do not ask me again
                        <br />
                        <br />
                      </MutedText>
                    </Fragment>
                  ),
                  onOk: async () => {
                    localStorage.setItem('NotAlertWhenAmazonInsideLink', notAlertMeAgain ? 1 : 0);
                    handleSubmit({ redirect, preview: false });
                  },
                });
              } else {
                handleSubmit({ redirect, preview: false });
              }
            }}
            className="composer__button--pixelize-me"
            style={{ width: '172px', height: '35px' }}
          >
            {title}
          </Button>
        </div>
      )}
    </>
  );
}
