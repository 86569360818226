import React from 'react';

export function FakeAdsProviders() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z"
          fill="#3B5998"
        />
        <path
          d="M19.3964 16.1073H16.6306V26.2398H12.4402V16.1073H10.4473V12.5463H12.4402V10.2419C12.4402 8.59406 13.223 6.01367 16.6679 6.01367L19.7719 6.02666V9.4832H17.5198C17.1504 9.4832 16.6309 9.66777 16.6309 10.4538V12.5496H19.7625L19.3964 16.1073Z"
          fill="white"
        />
      </svg>
      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clipPath="url(#clip0)">
          <path
            d="M15.5018 30.9997C24.0622 30.9997 31.0017 24.0602 31.0017 15.4999C31.0017 6.93953 24.0622 0 15.5018 0C6.94148 0 0.00195312 6.93953 0.00195312 15.4999C0.00195312 24.0602 6.94148 30.9997 15.5018 30.9997Z"
            fill="#55ACEE"
          />
          <path
            d="M24.9959 11.1397C24.3317 11.4343 23.6172 11.6335 22.8678 11.7224C23.6329 11.2641 24.22 10.5388 24.4972 9.67312C23.7813 10.0978 22.988 10.4059 22.1445 10.5719C21.4687 9.85216 20.5058 9.40234 19.4395 9.40234C17.3938 9.40234 15.7346 11.0615 15.7346 13.1072C15.7346 13.3976 15.7675 13.6803 15.831 13.9516C12.752 13.7972 10.0218 12.3223 8.19439 10.0801C7.87554 10.6272 7.69263 11.2641 7.69263 11.9429C7.69263 13.228 8.34718 14.3625 9.34075 15.0267C8.73372 15.0076 8.16206 14.841 7.66279 14.5628C7.66251 14.5785 7.66251 14.5943 7.66251 14.6098C7.66251 16.4049 8.94012 17.9022 10.6347 18.2423C10.3241 18.3274 9.99614 18.3724 9.65878 18.3724C9.4195 18.3724 9.18769 18.3495 8.96167 18.3064C9.43304 19.778 10.801 20.8492 12.4226 20.8793C11.1544 21.8731 9.5571 22.4652 7.82083 22.4652C7.52243 22.4652 7.22679 22.4478 6.9375 22.4133C8.57623 23.4649 10.5241 24.078 12.6163 24.078C19.4309 24.078 23.1577 18.4326 23.1577 13.5363C23.1577 13.3758 23.1541 13.2158 23.1469 13.0572C23.8714 12.535 24.4994 11.8827 24.9959 11.1397Z"
            fill="#F1F2F2"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="31" height="31" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
          <path
            d="M20.1191 8.24805H10.8796C9.4049 8.24805 8.20508 9.44787 8.20508 10.9226V20.162C8.20508 21.6368 9.4049 22.837 10.8796 22.837H20.1191C21.5938 22.837 22.794 21.6371 22.794 20.162V10.9226C22.794 9.44787 21.5942 8.24805 20.1191 8.24805ZM15.4994 20.3451C12.8514 20.3451 10.6969 18.1906 10.6969 15.5423C10.6969 12.8944 12.8514 10.7399 15.4994 10.7399C18.1477 10.7399 20.3021 12.8944 20.3021 15.5423C20.3021 18.1903 18.1473 20.3451 15.4994 20.3451ZM20.4565 11.7329C19.8296 11.7329 19.3199 11.2231 19.3199 10.5966C19.3199 9.97008 19.8296 9.46031 20.4565 9.46031C21.083 9.46031 21.5928 9.97008 21.5928 10.5966C21.5928 11.2231 21.083 11.7329 20.4565 11.7329Z"
            fill="#6A453B"
          />
          <path
            d="M15.4999 12.7695C13.9713 12.7695 12.7266 14.0136 12.7266 15.5422C12.7266 17.0715 13.9713 18.3159 15.4999 18.3159C17.0292 18.3159 18.2729 17.0715 18.2729 15.5422C18.2729 14.0139 17.0289 12.7695 15.4999 12.7695Z"
            fill="#6A453B"
          />
          <path
            d="M15.5 0C6.93993 0 0 6.93993 0 15.5C0 24.0601 6.93993 31 15.5 31C24.0601 31 31 24.0601 31 15.5C31 6.93993 24.0601 0 15.5 0ZM24.8237 20.1615C24.8237 22.7556 22.7138 24.8655 20.1197 24.8655H10.8803C8.28654 24.8655 6.17631 22.7556 6.17631 20.1615V10.9221C6.17631 8.32833 8.28654 6.2181 10.8803 6.2181H20.1197C22.7138 6.2181 24.8237 8.32833 24.8237 10.9221V20.1615Z"
            fill="#6A453B"
          />
        </g>
      </svg>
      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clipPath="url(#clip0)">
          <path
            d="M15.5 30.9981C24.0604 30.9981 31 24.0585 31 15.498C31 6.93763 24.0604 -0.00195312 15.5 -0.00195312C6.93959 -0.00195312 0 6.93763 0 15.498C0 24.0585 6.93959 30.9981 15.5 30.9981Z"
            fill="#007AB9"
          />
          <path
            d="M24.7607 16.7454V23.1357H21.0557V17.1736C21.0557 15.6766 20.5208 14.6543 19.1794 14.6543C18.1557 14.6543 17.5475 15.3426 17.279 16.009C17.1814 16.2472 17.1563 16.5779 17.1563 16.912V23.1354H13.4511C13.4511 23.1354 13.5008 13.0377 13.4511 11.9924H17.1566V13.5715C17.1491 13.5839 17.1386 13.5961 17.132 13.6079H17.1566V13.5715C17.6489 12.8139 18.527 11.7308 20.4957 11.7308C22.9332 11.7308 24.7607 13.3234 24.7607 16.7454ZM9.5751 6.62109C8.3077 6.62109 7.47852 7.45304 7.47852 8.54609C7.47852 9.61593 8.28366 10.4719 9.52647 10.4719H9.55051C10.8428 10.4719 11.6463 9.61593 11.6463 8.54609C11.6217 7.45304 10.8428 6.62109 9.5751 6.62109ZM7.69873 23.1357H11.4026V11.9924H7.69873V23.1357Z"
            fill="#F1F2F2"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="31" height="31" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clipPath="url(#clip0)">
          <path
            d="M15.5017 31.0014C24.0619 31.0014 31.0014 24.0619 31.0014 15.5017C31.0014 6.94141 24.0619 0.00195312 15.5017 0.00195312C6.94142 0.00195312 0.00195312 6.94141 0.00195312 15.5017C0.00195312 24.0619 6.94142 31.0014 15.5017 31.0014Z"
            fill="#CB2027"
          />
          <path
            d="M16.7521 20.756C15.5803 20.6654 15.088 20.0843 14.169 19.5265C13.6639 22.1765 13.0467 24.7167 11.2184 26.0438C10.6534 22.0386 12.0468 19.0303 12.6939 15.8371C11.5909 13.9804 12.8265 10.2432 15.1529 11.1641C18.0162 12.2964 12.674 18.0679 16.2603 18.7888C20.0044 19.5414 21.5329 12.2922 19.2109 9.93429C15.8561 6.53003 9.44571 9.85692 10.234 14.7303C10.426 15.9216 11.6572 16.2833 10.7258 17.9273C8.57869 17.4518 7.93823 15.7584 8.02057 13.5007C8.15347 9.80525 11.3411 7.21773 14.5384 6.85965C18.582 6.40707 22.3772 8.34447 22.9011 12.148C23.4904 16.4408 21.0756 21.0898 16.7521 20.756Z"
            fill="#F1F2F2"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="31" height="31" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clipPath="url(#clip0)">
          <path
            d="M6.87025 18.7331L5.79119 22.7614L1.84722 22.8448C0.668559 20.6587 0 18.1574 0 15.4994C0 12.9291 0.625086 10.5053 1.73309 8.37109H1.73394L5.24518 9.01483L6.78331 12.505C6.46138 13.4435 6.28592 14.451 6.28592 15.4994C6.28604 16.6372 6.49214 17.7273 6.87025 18.7331Z"
            fill="#FBBB00"
          />
          <path
            d="M30.7283 12.6035C30.9063 13.5411 30.9991 14.5095 30.9991 15.4991C30.9991 16.6088 30.8824 17.6913 30.6601 18.7354C29.9056 22.2885 27.934 25.391 25.2028 27.5866L25.202 27.5857L20.7794 27.3601L20.1534 23.4527C21.9657 22.3898 23.382 20.7265 24.1281 18.7354H15.8398V12.6035H24.249H30.7283Z"
            fill="#518EF8"
          />
          <path
            d="M25.2032 27.5872L25.2041 27.5881C22.5478 29.7231 19.1736 31.0006 15.5004 31.0006C9.59766 31.0006 4.46564 27.7014 1.84766 22.8461L6.87069 18.7344C8.17965 22.2278 11.5496 24.7146 15.5004 24.7146C17.1986 24.7146 18.7895 24.2556 20.1547 23.4542L25.2032 27.5872Z"
            fill="#28B446"
          />
          <path
            d="M25.3929 3.56839L20.3716 7.67928C18.9587 6.79614 17.2886 6.28598 15.4993 6.28598C11.4591 6.28598 8.02609 8.88689 6.7827 12.5056L1.73327 8.3717H1.73242C4.31208 3.39807 9.50882 0 15.4993 0C19.2602 0 22.7085 1.33966 25.3929 3.56839Z"
            fill="#F14336"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="31" height="31" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
