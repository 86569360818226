import React, { useState } from 'react';

import { Tooltip } from 'antd';

import { Edit } from 'components/common/icons';
import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';

import { CampaignEditModal } from './CampaignEditModal';
import type { accountT } from '../../../../../../flow-typed/pixelme/types';

export function EditCampaign(props: { account: accountT, campaign: campaignT, onUpdate: (c: campaignT) => void }) {
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <CampaignEditModal
        account={props.account}
        campaign={props.campaign}
        visible={visible}
        onFinish={() => setVisible(false)}
        onUpdate={props.onUpdate}
      />

      <IconInsideButton onClick={() => setVisible(true)}>
        <Tooltip title="Edit Campaign" placement="bottom">
          <Edit />
        </Tooltip>
      </IconInsideButton>
    </div>
  );
}
