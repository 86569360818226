/**
 * Return  pre key to use in strings to get label
 * @param config
 * @returns {string}
 */
import { ALL_REDIRECTS } from 'consts/consts';

export function getStringsKey(config) {
  return config.parent && config.parent.id && config.parent.id !== ALL_REDIRECTS ? 'subCampaigns' : 'campaigns';
}
