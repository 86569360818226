import React, { Component } from 'react';

import * as _ from 'lodash';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import BulkEditRedirectModal from 'components/common/modals/BulkEditModal';
import { DateRangeFilter } from 'components/pages/redirects/DateRangeFilter';
import SearchRedirects from 'components/pages/redirects/SearchRedirects';
import { selectProductProvider } from 'stores/accounts';
import { addNotice } from 'stores/notices';

import { CampaignDatatableConfiguration } from './CampaignDatatableConfiguration';
import { RedirectsListDatatable } from './RedirectsListDatatable';
import { RedirectsPaginator } from './RedirectsPaginator';
import { StatsHeader } from './statsHeader/StatsHeader';
import { BulkEditRedirects } from '../bulkEdit/BulkEditRedirects';
import { DeleteRedirects } from '../delete/DeleteRedirects';
import { ExportRedirects } from '../export/ExportRedirects';
import { ExportRedirectsModal } from '../export/ExportRedirectsModal';
import { ExportRedirectsSuccessModal } from '../export/ExportRedirectsSuccessModal';

type internalPropsT = {};
type internalActionsT = {};
type internalStateT = {};
const initialState: State = {};

const RedirectsListHeader = styled.div.withConfig({
  displayName: 'RedirectsListHeader',
})`
  padding-bottom: 18px;
  display: flex;
  align-items: center;
  .drop-down__container {
    height: 35px;
  }
`;
const RedirectsListContainer = styled.div.withConfig({
  displayName: 'RedirectsListContainer',
})`
  height: 100%;
`;

class RedirectsListNoStyle extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = initialState;

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps, this.props)) {
      return false;
    }

    return true;
  }

  render() {
    const { className, account, selectedRedirects } = this.props;

    console.log(
      '%c render front/src/components/pages/AudienceBuilder/Campaigns/campaigns/RedirectsList.jsx',
      'color: red',
    );

    return (
      <>
        <RedirectsListContainer className={className}>
          <RedirectsListHeader>
            <StatsHeader />
            <SearchRedirects account={account} style={{ textAlign: 'right', flex: 1 }} displayCheckAll={false} />
            {selectedRedirects.length > 0 && (
              <>
                <DeleteRedirects />
              </>
            )}
            {selectedRedirects.length > 0 && (
              <>
                <BulkEditRedirects />
              </>
            )}
            <ExportRedirects />
            <DateRangeFilter />
            <ExportRedirectsModal />
            <BulkEditRedirectModal account={account} />
            <ExportRedirectsSuccessModal />
            <CampaignDatatableConfiguration />
          </RedirectsListHeader>
          <RedirectsListDatatable />
          <RedirectsPaginator />
        </RedirectsListContainer>
      </>
    );
  }
}

const mapStateToProps = function (state): internalPropsT {
  const redirectsState = state.abRedirects;
  const campaignsState = state.campaigns;

  return {
    activeAmazonProfile: selectProductProvider(state),
    limitations: state.accounts.limitations,
    selectedModel: redirectsState.filter.model,
    selectedRedirects: campaignsState.selectedRedirects,
  };
};

const mapDispatchToProps = function (dispatch: Dispatch<*>): {} {
  return {
    addNotice: (payload) => dispatch(addNotice(payload)),
    handleSelectModel: (model) => dispatch(Actions.api.audienceBuilder.redirect.search.request({ model })),
  };
};

const RedirectsList = connect(mapStateToProps, mapDispatchToProps)(RedirectsListNoStyle);

export default RedirectsList;
