import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import _ from 'lodash';
import styled from 'styled-components';

import { getIntlStrings } from 'locales/i18n/localeHelpers';

export const STRATEGIES = {
  RANK: 'RANK',
  PROFIT: 'PROFIT',
};

export const STRATEGIES_LABELS = {
  [STRATEGIES.RANK]: getIntlStrings('asinAudit.strategies.rank'),
  [STRATEGIES.PROFIT]: getIntlStrings('asinAudit.strategies.profit'),
};

export const AUDIT_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  FAILED: 'FAILED',
};

export const CONTAINER_PADDING = 24;
export const KEYWORDS_LIMIT = 25;
export const COMPETITORS_LIMIT = 5;
export const SELLER_ID_LIMIT = 1;
export const ASIN_LIMIT = 10;
export const SEARCH_AUDIT_KEYWORDS = 1;

export const IMPRESSIONS = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

export const OPPORTUNITY = {
  VERY_HIGH: 'Very High',
  HIGH: 'High',
  MEDIUM: 'Medium',
};

/** Lower bound thresholds. Match value >= threshold */
export const IMPRESSION_THRESHOLDS = {
  profit: {
    [IMPRESSIONS.POSITIVE]: 8,
    [IMPRESSIONS.NEUTRAL]: 5.5,
    [IMPRESSIONS.NEGATIVE]: 0,
  },
  ranking: {
    [IMPRESSIONS.POSITIVE]: 8.5,
    [IMPRESSIONS.NEUTRAL]: 6,
    [IMPRESSIONS.NEGATIVE]: 0,
  },
  keywordProfit: {
    [IMPRESSIONS.POSITIVE]: 7.5,
    [IMPRESSIONS.NEUTRAL]: 5,
    [IMPRESSIONS.NEGATIVE]: 0,
  },
  keywordRanking: {
    [IMPRESSIONS.POSITIVE]: 7.5,
    [IMPRESSIONS.NEUTRAL]: 5,
    [IMPRESSIONS.NEGATIVE]: 0,
  },
  amazonSearchVolume: {
    [IMPRESSIONS.POSITIVE]: 10000,
    [IMPRESSIONS.NEUTRAL]: 2000,
    [IMPRESSIONS.NEGATIVE]: 0,
  },
  googleSearchVolume: {
    [IMPRESSIONS.POSITIVE]: 10000,
    [IMPRESSIONS.NEUTRAL]: 2000,
    [IMPRESSIONS.NEGATIVE]: 0,
  },
  reviewScore: {
    [IMPRESSIONS.POSITIVE]: 4.5,
    [IMPRESSIONS.NEUTRAL]: 4,
    [IMPRESSIONS.NEGATIVE]: 0,
  },
};

export const getImpression = (value, thresholds = {}) => {
  const impressions = Object.keys(thresholds);

  for (let i = 0; i < impressions.length; i++) {
    if (value >= thresholds[impressions[i]]) {
      return impressions[i];
    }
  }

  return IMPRESSIONS.NEUTRAL;
};

export const getImpressionColors = (impression) => {
  switch (impression) {
    case IMPRESSIONS.POSITIVE:
      return {
        backgroundColor: '#D7F5F2',
        color: '#25A498',
      };
    case IMPRESSIONS.NEGATIVE:
      return {
        backgroundColor: '#F8E2E7',
        color: '#C61640',
      };
    case IMPRESSIONS.NEUTRAL:
    default:
      return {
        backgroundColor: '#ECECF9',
        color: '#6265CF',
      };
  }
};

export const getStatusColors = (status) => {
  switch (status) {
    case AUDIT_STATUS.DONE:
      return `
        background-color: #D7F5F2;
        color: #25A498;
      `;
    case AUDIT_STATUS.IN_PROGRESS:
      return `
        background-color: #D5E3FD;
        color: #1A21FF;
      `;
    case AUDIT_STATUS.FAILED:
    default:
      return `
        background-color: #FFE8E3;
        color: #C61640;
      `;
  }
};

export const getOpportunityColors = (opportunity) => {
  switch (opportunity) {
    case OPPORTUNITY.VERY_HIGH:
      return `
        background-color: #D7F5F2;
        color: #12A496;
      `;
    case OPPORTUNITY.HIGH:
      return `
        background-color: #DBE2FE;
        color: #1A21FF;
      `;
    case OPPORTUNITY.MEDIUM:
    default:
      return `
        background-color: #EDE6FF;
        color: #6855E0;
      `;
  }
};

export const ImpressionEmoji = ({ impression }) => {
  const { color } = getImpressionColors(impression);

  switch (impression) {
    case IMPRESSIONS.POSITIVE:
      return <SmileOutlined style={{ color }} />;
    case IMPRESSIONS.NEGATIVE:
      return <FrownOutlined style={{ color }} />;
    case IMPRESSIONS.NEUTRAL:
    default:
      return <MehOutlined style={{ color }} />;
  }
};

/** Adds ellipis after a certain number of lines */
export const MultilineEllipsis = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.$lines || 3};
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
`;

export const GridCell = styled.div`
  grid-area: ${(props) => props.$name};
`;

export const numberSorter = (path) => (a, b) =>
  (_.get(a, path) ?? Number.MIN_SAFE_INTEGER) - (_.get(b, path) ?? Number.MIN_SAFE_INTEGER);

export const stringSorter = (path) => (a, b) => (_.get(a, path) ?? '').localeCompare(_.get(b, path) ?? '');

export const timestampSorter = (path) => (a, b) => {
  const dateA = new Date(_.get(a, path));
  const dateB = new Date(_.get(b, path));

  return dateA - dateB;
};

/** Regex that checks 10 alphanumeric characters */
export const asinRegex = /^[a-zA-Z0-9]{10}$/;
export const validateAsin = (asin: string): boolean => asinRegex.test(asin);

/** Regex that checks 12-14 alphanumeric characters */
export const validateSellerId = (sellerId: string): boolean => /^[a-zA-Z0-9]{12,14}$/.test(sellerId);

export const sanitizePhoneNumber = (inputString: string): string => inputString.replace(/[^0-9]/g, '');

export const calculateAmazonSearchVolume = (keywords) =>
  keywords?.reduce((acc, keyword) => acc + (keyword.amazonSearchVolume ? keyword.amazonSearchVolume : 0), 0);

export const calculateGoogleSearchVolume = (keywords) =>
  keywords?.reduce((acc, keyword) => acc + (keyword.googleSearchVolume ? keyword.googleSearchVolume : 0), 0);

export const BOOK_CALL_URLS = {
  DEFAULT: 'https://pixelme.app/book-a-call',
  PROSPER: 'https://form.jotform.com/240536489376064',
};
