import React, { useState } from 'react';

import { Select } from 'antd';

export function LanguageSelector({ googleLanguages, onChange, value }) {
  const [languages, setLanguages] = useState(googleLanguages);

  return (
    <Select
      size="large"
      mode="multiple"
      allowClear
      filterOption={false}
      onChange={onChange}
      value={value}
      onSearch={(v) => {
        setLanguages(googleLanguages.filter((l) => !!l && l.name.toLowerCase().includes(v.toLowerCase())));
      }}
      onClear={(v) => {
        setLanguages([]);
      }}
      placeholder="Select at least one language"
    >
      {languages.map((l) => (
        <Select.Option key={l.id} value={l.id}>
          {l.name}
        </Select.Option>
      ))}
    </Select>
  );
}
