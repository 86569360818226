import React from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import * as string from 'locales/strings';
import { strings } from 'locales/strings';

import { LargeModalForRedux } from '../../../../common/ModalForRedux';

const ExportHelpContainer = styled.div.withConfig({
  displayName: 'ExportHelpContainer',
})`
  text-align: left;
  margin-bottom: 10px;
  font-size: var(--small-size);
`;

function AbstractExportRedirectsSuccessModal({ config, handleChange }: { config: configT, handleChange: () => any }) {
  return (
    <LargeModalForRedux
      okOnEnterKey={false}
      okOnly={true}
      okMessage={getIntlStrings('redirect.export.feedback.button.title')}
      actionCancel={() => Actions.front.audienceBuilder.redirect.export.feedback.hide.request()}
      actionSuccess={() =>
        Actions.track.redirect.export.feedback.request({
          feedback: config.feedback,
        })
      }
      config={config}
      title={getIntlStrings('redirect.export.title')}
    >
      <>
        {!!config && (
          <>
            <ExportHelpContainer>{getIntlStrings('redirect.export.success.help')}</ExportHelpContainer>
          </>
        )}
      </>
    </LargeModalForRedux>
  );
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  config: state.front.exportRedirectsSuccessModalConfig,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  handleChange: (event) => {
    const target = (event.currentTarget: HTMLInputElement);
    const feedback = target.value;

    dispatch(
      Actions.front.audienceBuilder.redirect.export.feedback.update.request({
        feedback,
      }),
    );
  },
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) => {};

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export const ExportRedirectsSuccessModal = reduxWrapper(AbstractExportRedirectsSuccessModal);
