import moment from 'moment';

export function nullSafeIso8601(date) {
  if (date) {
    return moment(date).toISOString();
  } else {
    return null;
  }
}

export function nullSafeMoment(date, defautValue) {
  if (date) {
    return moment(date);
  } else {
    return moment(defautValue);
  }
}

export const EPOCH: string = new Date(0).toISOString();
