import styled from 'styled-components';

export const Stats = styled.div.withConfig({
  displayName: 'Stats',
})`
  padding: 0 25px;
  text-align: center;
  display: inline-block;
  // flex-direction: column;
  // justify-content: center;
  border-right: 1px solid #f2f2f2;
`;
