// @flow
import React, { type Node } from 'react';

import styled from 'styled-components';

import { AGENCY_TOOLS, useFeatureFlag } from 'lib/configcat';

import { Redirect, Route, Switch } from 'react-router-dom';
import { AccountsOverview } from './AccountsOverview';
import { Alerts } from './Alerts';

const Style = styled.div`
  background-color: white;
  padding: 1em;
  height: 100%;
`;

type propsT = {
  account: accountT,
  match: any,
};
export const AccountsPage = ({ account, match }: propsT): Node => {
  const [isAgencyToolsEnabled, isFlagLoading] = useFeatureFlag(AGENCY_TOOLS);

  if (isFlagLoading || !isAgencyToolsEnabled) {
    return null;
  }

  return (
    <Style>
      <Switch>
        <Route path={`${match.url}`} exact render={(props) => <Redirect to={`${match.url}/overview`} />} />
        <Route path={`${match.url}/overview`} render={(props) => <AccountsOverview account={account} />} />
        <Route path={`${match.url}/alerts`} render={(props) => <Alerts account={account} />} />
      </Switch>
    </Style>
  );
};
