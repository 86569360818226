import React, { type Node } from 'react';

import { InputNumber } from 'antd';
import { debounce } from 'lodash';
import styled from 'styled-components';

import { getCurrencySymbol } from 'utils/currency';

const StyleInput = styled(InputNumber)`
  width: 110px;
  input {
    outline: none;
    margin-right: 1rem;
    text-align: right;

    &.ant-input-number-input {
      padding-right: 11px;
    }
  }
`;

type propsT = {
  currencyCode: string,
  onChange?: any,
  onBlur?: any,
  unit?: number,
  initialValue?: number,
  value?: number,
  size?: string,
  placeholder?: string,
  disabled?: boolean,
};
export function CurrencyInput({
  currencyCode,
  onChange,
  onBlur,
  unit = 1,
  initialValue = 0,
  value = 0,
  size = 'large',
  disabled = false,
  ...props
}: propsT): Node {
  function handleChange(value) {
    if (onChange) {
      onChange(value * unit);
    }
  }
  function handleBlur(e) {
    if (onBlur) {
      onBlur(parseInt(e.target.value) * unit);
    }
  }

  const defaultValue = initialValue / unit;
  const currentValue = value / unit;
  const currencySymbol = getCurrencySymbol(currencyCode);

  return (
    <StyleInput
      prefix={currencySymbol}
      controls={false}
      min={0}
      placeholder="00.00"
      size={size}
      onBlur={handleBlur}
      value={currentValue}
      onChange={handleChange}
      defaultValue={defaultValue}
      disabled={disabled}
      {...props}
    />
  );
}
