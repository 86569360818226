import React from 'react';

export function Slack() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none" viewBox="0 0 31 31">
      <g clipPath="url(#clip0)" opacity="1">
        <path
          fill="#E91E63"
          d="M11.422 16.342a3.257 3.257 0 00-3.256 3.256v8.145a3.257 3.257 0 006.513 0v-8.145a3.258 3.258 0 00-3.257-3.256zM.025 19.597a3.26 3.26 0 006.518 0v-3.26H3.284a3.26 3.26 0 00-3.259 3.26z"
        ></path>
        <path
          fill="#00BCD4"
          d="M11.427-.002h-.004a3.26 3.26 0 000 6.518h3.256v-3.26-.006a3.254 3.254 0 00-3.252-3.252zM3.263 14.686h8.16a3.26 3.26 0 000-6.518h-8.16a3.26 3.26 0 000 6.518z"
        ></path>
        <path
          fill="#4CAF50"
          d="M27.715 8.168a3.252 3.252 0 00-3.252 3.252v3.266h3.256a3.26 3.26 0 000-6.518h-.004zM16.328 3.258v8.17a3.257 3.257 0 006.513 0v-8.17a3.257 3.257 0 00-6.513 0z"
        ></path>
        <path
          fill="#FF9800"
          d="M22.839 27.74a3.257 3.257 0 00-3.256-3.256h-3.257v3.26a3.256 3.256 0 006.513-.003zM27.744 16.338h-8.16a3.26 3.26 0 000 6.518h8.16a3.26 3.26 0 000-6.518z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H31V31H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
