import { WorkerAction } from '../../framework/workerAction';

export default {
  load: new WorkerAction({ name: 'api.audienceBuilder.campaigns.load' }, {}),
  addLinks: new WorkerAction({
    name: 'api.audienceBuilder.campaigns.addLinks',
  }),
  addRedirects: new WorkerAction({
    name: 'api.audienceBuilder.campaigns.addRedirects',
  }),
  reOrder: new WorkerAction({ name: 'api.audienceBuilder.campaigns.reOrder' }),
  select: new WorkerAction({ name: 'api.audienceBuilder.campaigns.select' }),
  edit: new WorkerAction({ name: 'api.audienceBuilder.campaigns.edit' }, {}),
  delete: new WorkerAction({ name: 'api.audienceBuilder.campaigns.delete' }),
  add: new WorkerAction({ name: 'api.audienceBuilder.campaigns.add' }, {}),
  reloadAllStats: new WorkerAction({ name: 'api.audienceBuilder.campaigns.reloadAllStats' }, {}, [], {
    saveStore: false,
  }),
  loadStats: new WorkerAction({ name: 'api.audienceBuilder.campaigns.loadStats' }, {}, [], { saveStore: false }),
};
