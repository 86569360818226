import React, { useState, type Node } from 'react';

import { ControlOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { IconInsideButton } from 'components/pages/AudienceBuilder/Campaigns/campaignTree/Common';
import { strings } from 'locales/strings';

import { AutomationsModal } from './AutomationsModal';

type propsT = {
  account: accountT,
  campaign: campaignT,
};
export const AutomationsButton = ({ account, campaign }: propsT): Node => {
  const [visible, setVisible] = useState(false);
  const { messages } = useIntl();

  return (
    <div>
      <AutomationsModal account={account} campaign={campaign} visible={visible} onFinish={() => setVisible(false)} />
      <IconInsideButton onClick={() => setVisible(true)}>
        <Tooltip title={messages.campaignAutomations.tooltip} placement="bottom">
          <ControlOutlined style={{ color: '#4689DB' }} />
        </Tooltip>
      </IconInsideButton>
    </div>
  );
};
