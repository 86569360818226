import React from 'react';

import Button from '../../common/Button';

import './onBoarding.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Actions, trackOnboarding } from '../../../actions';
import onBoardingImage from '../../../images/onboarding/onboarding.svg';

const OnBoardingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { accountId, link, domain, pixel, selectedCampaignId, selectedSubCampaignId } = useSelector((state) => ({
    selectedCampaignId: state.campaigns.selectedCampaignId,
    selectedSubCampaignId: state.campaigns.selectedSubCampaignId,
    accountId: state.accounts.selected,
    link: state.accounts.usage.shortens > 0,
    domain: state.accounts.usage.brandedDomains > 0,
    pixel: state.accounts.usage.pixels > 0,
  }));

  const toogleComposer = () => {
    dispatch(
      Actions.front.audienceBuilder.redirect.create.show.request({
        selectedCampaignId,
        selectedSubCampaignId,
      }),
    );
  };

  const onClickonBoarding = (step) => {
    switch (step) {
      case 'link':
        toogleComposer();
        dispatch(trackOnboarding({ action: 'link' }));
        break;
      case 'pixel':
        history.push(`/${accountId}/ab/settings/pixels`);
        dispatch(trackOnboarding({ action: 'pixel' }));
        break;
      case 'domain':
        history.push(`/${accountId}/ab/settings/domains`);
        dispatch(trackOnboarding({ action: 'domain' }));
        break;
      default:
        break;
    }
  };

  return (
    <div className="onboarding">
      <img alt="onboardingimg" src={onBoardingImage} />
      <h1>Welcome to PixelMe!</h1>
      <p>
        Brand, track & share your own branded short URLs and retarget anyone who clicked <br /> on your links!
      </p>
      <div className="onboarding__container_button">
        <div onClick={() => onClickonBoarding('link')}>
          <Button disabled={link} className="onboarding__button-container" style={{ marginRight: '1rem' }}>
            Create your first link
          </Button>
        </div>
        <div onClick={() => onClickonBoarding('pixel')}>
          <Button disabled={pixel} className="onboarding__button-container">
            Add your first retargeting pixel
          </Button>
        </div>
        <div onClick={() => onClickonBoarding('domain')}>
          <Button disabled={domain} className="onboarding__button-container" style={{ marginLeft: '1rem' }}>
            Add a branded domain
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingPage;
