import React from 'react';

import IconContainer from './IconContainer';

export function Up(props) {
  return (
    <IconContainer {...props}>
      <svg width="100%" height="100%" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.00684 2.98535L2.99233 0.999966" stroke="#828282" strokeLinecap="round" />
        <path d="M2.99227 1.00005L1.00689 3.01456" stroke="#828282" strokeLinecap="round" />
      </svg>
    </IconContainer>
  );
}
