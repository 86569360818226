import { useState, type Node } from 'react';

import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Statistic, Table, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import { AuditResultsExpanded } from './AuditResultsExpanded';
import {
  getImpression,
  IMPRESSION_THRESHOLDS,
  MultilineEllipsis,
  numberSorter,
  STRATEGIES_LABELS,
  stringSorter,
} from './constants';
import { ProductImage } from './ProductImage';
import { ScoreBadge } from './ScoreBadge';

const TableContainer = styled.div`
  border-radius: 2px;
  border: 1px solid #d9d9d9;

  .ant-table-sticky-scroll {
    display: none;
  }
  .ant-table-expanded-row-fixed {
    background-color: #f5f7ff;
  }
  .ant-table-placeholder .ant-table-expanded-row-fixed {
    background-color: #fff;
  }
  .results-table-row {
    cursor: pointer;
  }
  thead {
    user-select: none;
  }

  ${(props) =>
    props.$expandedRowKeys?.map(
      (key) => `
        .ant-table-row[data-row-key="${key}"] {
          & > td,
          &:hover > td {
            background-color: #e6ebff;
          }
        }
      `,
    )}
`;

const ProductNameContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const ProductLink = styled(Button).attrs({ type: 'link' })`
  padding: 0;
  flex: 1;
  text-align: left;
  &,
  > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

type propsT = {
  data: auditItemT[],
};
export const AuditResultsTable = ({ data = [], ...props }: propsT): Node => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const { messages } = useIntl();
  const columns = [
    {
      title: 'messages.asinAudit.product.title',
      dataIndex: ['product', 'title'],
      fixed: 'left',
      width: 300,
      render: (val, record) => {
        const ExpandIcon = expandedRowKeys.includes(record.product.asin) ? DownOutlined : RightOutlined;

        return (
          <ProductNameContainer>
            <ExpandIcon />
            <ProductImage src={record.product.imageUrl} alt={val} width={32} height={32} />
            <Tooltip title={val}>
              <ProductLink
                href={record.product.url}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
              >
                <p>{val || messages.asinAudit.product.defaultTitle}</p>
              </ProductLink>
            </Tooltip>
          </ProductNameContainer>
        );
      },
      sorter: stringSorter(['product', 'title']),
    },
    {
      title: messages.asinAudit.product.asin,
      dataIndex: ['product', 'asin'],
      width: 130,
      align: 'center',
      sorter: stringSorter(['product', 'asin']),
    },
    {
      title: messages.asinAudit.product.brand,
      dataIndex: ['product', 'brand'],
      width: 200,
      align: 'center',
      ellipsis: true,
      render: (val) => <Tooltip title={val}>{val || 'N/A'}</Tooltip>,
      sorter: stringSorter(['product', 'brand']),
    },
    {
      title: messages.asinAudit.product.price,
      dataIndex: ['product', 'price'],
      width: 120,
      align: 'center',
      render: (val) => (val == null ? 'N/A' : <Statistic value={val / 100} precision={2} prefix="$" />),
      sorter: numberSorter(['product', 'price']),
    },
    {
      title: messages.asinAudit.product.reviewCount,
      dataIndex: ['product', 'reviewCount'],
      width: 150,
      align: 'center',
      render: (val) => (val == null ? 'N/A' : <Statistic value={val} />),
      sorter: numberSorter(['product', 'reviewCount']),
    },
    {
      title: messages.asinAudit.product.reviewScore,
      dataIndex: ['product', 'reviewScore'],
      width: 145,
      align: 'center',
      render: (val) =>
        val == null ? (
          'N/A'
        ) : (
          <ScoreBadge score={val} impression={getImpression(val, IMPRESSION_THRESHOLDS.reviewScore)} maxScore={4.5} />
        ),
      sorter: numberSorter(['product', 'reviewScore']),
    },
    {
      title: messages.asinAudit.product.keywords,
      dataIndex: 'keywords',
      width: 360,
      align: 'center',
      render: (val) => {
        if (!val?.length) {
          return 'N/A';
        }

        const keywords = val.map((item) => item.keyword);

        return (
          <Tooltip title={keywords.join('\n')} overlayInnerStyle={{ whiteSpace: 'pre-wrap' }}>
            <MultilineEllipsis>{keywords.join(', ')}</MultilineEllipsis>
          </Tooltip>
        );
      },
    },
    {
      title: messages.asinAudit.product.profitScore,
      dataIndex: ['score', 'profit'],
      width: 150,
      align: 'center',
      render: (val) =>
        val == null ? 'N/A' : <ScoreBadge score={val} impression={getImpression(val, IMPRESSION_THRESHOLDS.profit)} />,
      sorter: numberSorter(['score', 'profit']),
    },
    {
      title: messages.asinAudit.product.rankingScore,
      dataIndex: ['score', 'ranking'],
      width: 155,
      align: 'center',
      render: (val) =>
        val == null ? 'N/A' : <ScoreBadge score={val} impression={getImpression(val, IMPRESSION_THRESHOLDS.ranking)} />,
      sorter: numberSorter(['score', 'ranking']),
    },
    {
      title: messages.asinAudit.product.strategy,
      dataIndex: 'strategy',
      width: 100,
      align: 'center',
      render: (val) => STRATEGIES_LABELS[val] || 'N/A',
    },
  ];

  return (
    <TableContainer $expandedRowKeys={expandedRowKeys}>
      <Table
        dataSource={data}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => <AuditResultsExpanded auditItem={record} />,
          expandRowByClick: true,
          expandedRowKeys,
          onExpand: (expanded, record) => {
            setExpandedRowKeys((prevKeys) =>
              expanded ? [...prevKeys, record.product?.asin] : prevKeys.filter((id) => id !== record.product?.asin),
            );
          },
          showExpandColumn: false,
        }}
        bordered
        pagination={false}
        rowClassName="results-table-row"
        rowKey={(record) => record.product?.asin}
        scroll={{ x: 'min-content' }}
        showSorterTooltip={false}
        sticky
        tableLayout="fixed"
        {...props}
      />
    </TableContainer>
  );
};
