import './PixelsList.css';

import React from 'react';

import Pixel from './Pixel';

function PixelsList({ pixels, label = false }: { pixels: Array<pixelT>, label: boolean }) {
  if (pixels && pixels.length > 0) {
    return (
      <div className="pixels-list">
        {label && 'Pixel '}
        {pixels.map((pixel: pixelT) => (
          <Pixel key={pixel.id} provider={pixel.providerKey} />
        ))}
      </div>
    );
  }

  return 'No pixels';
}

export default PixelsList;
