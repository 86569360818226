import type { Node } from 'react';

import { DownOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import styled from 'styled-components';

const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'zh', label: '中文' },
];

const Style = styled.div`
  display: flex;
  width: 110px;
`;

const LanguageSwitcher = ({
  locale,
  updateLocale,
}: {
  locale: string,
  updateLocale: (value: string) => void,
}): Node => (
  <Style>
    <Select defaultValue={locale} suffixIcon={<DownOutlined />} onChange={(value) => updateLocale(value)}>
      {LANGUAGES.map((lang) => (
        <Select.Option key={lang.value} value={lang.value}>
          {lang.label}
        </Select.Option>
      ))}
    </Select>
  </Style>
);

export default LanguageSwitcher;
