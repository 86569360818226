import React, { Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const DisplayHover = styled.div`
  display: block;
  height: 100%;
  position: relative;
  cursor: pointer;
  color: inherit;
  .text {
    text-decoration: none !important;
    transition: opacity 0.5s ease-in-out;
    display: none;
    opacity: 0;
    font-size: 9px;
    margin-top: 5px;
    color: var(--pixelme-color);
  }
  .background {
    margin-left: 10px;
    margin-right: 10px;
  }
  .background svg {
    transition: height 0.5s ease-in-out;
    width: 100%;
  }
  :hover {
    .text {
      display: block;
      opacity: 1;
      width: 100%;
      text-align: center;
    }
    .background svg {
      height: 20px;
      width: 110px;
    }
  }
`;

export function HoverLink(props: { children: React.ReactNode, content: React.ReactNode, +url: string, +onClick: any }) {
  const history = useHistory();

  return (
    <DisplayHover
      onClick={(e) => {
        if (props.url) {
          history.push(props.url);
        }

        if (props.onClick) {
          props.onClick();
        }

        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <div className="background">{props.children}</div>
      <div className="text">{props.content}</div>
    </DisplayHover>
  );
}
