import './Analytics.css';

import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import type { Match } from 'react-router-dom';

import type { queryT } from 'stores/analytics';
import {
  loadAnalyticsAndFacets,
  exportAnalyticsAndFacetsThunk,
  loadAnalyticsAndFacetsForRedirectThunk,
  loadAnalyticsAndFacetsThunk,
  maxValue,
  sortOnValue,
  totalValue,
} from 'stores/analytics';
import { store } from 'stores/index.js';

import type { status } from './BoxSwitcher';
import Donut from './Donut';
import FacetBox, { FacetBoxPlaceholder } from './FacetBox';
import Graph from './Graph';
import ListView from './ListView';
import Map from './Map';
import QueryBuilder from './QueryBuilder';
import { Actions } from '../../../actions';
import Modal from '../../common/Modal';
import { LineGraphPlaceholder } from '../../common/Placeholders';

type props = {
  loading: boolean,
  loadingFacets: boolean,
  query: queryT,
  redirect?: redirectT,
  stats: Array<{ date: number, value: number, uniqueValue: number }>,
  total: number,
  facets: {
    [key: string]: Array<{
      id: string,
      label?: string,
      value: number,
      uniqueValue: number,
    }>,
  },
  match: Match,
};

type actions = {
  loadAnalyticsAndFacetsThunk: ({ query: queryT, redirect?: redirectT }) => void,
  loadAnalyticsAndFacetsForRedirectThunk: ({ redirectId: string, accountId: string }) => void,
  loadAnalyticsAndFacets: ({ query: queryT, redirect?: redirectT }) => void,
};

type state = {
  redirectsView: status,
  mapView: status,
  referersView: status,
  tagsView: status,
  browsersView: status,
  osesView: status,
};

const defaultState: state = {
  redirectsView: 'list',
  mapView: 'map',
  referersView: 'chart',
  tagsView: 'list',
  browsersView: 'chart',
  osesView: 'chart',
};

class Analytics extends Component<{ account: accountT } & props & actions, state> {
  constructor(props: *) {
    super(props);
    this.state = defaultState;
  }

  render() {
    const { loading, stats, total, redirect, account, query, exportStatsModalConfirmedConfig, closeStatsConfirmModal } =
      this.props;

    return (
      <div className={`analytics${redirect ? ' analytics-details' : ''}`}>
        <div className="analytics__header box">
          <QueryBuilder
            account={account}
            redirect={redirect}
            query={query}
            handleChangeQuery={(query) => {
              store.dispatch(loadAnalyticsAndFacetsThunk({ query, redirect }));
            }}
            handleExportStats={(query) => {
              store.dispatch(exportAnalyticsAndFacetsThunk({ query, redirect }));
            }}
          />
        </div>
        {exportStatsModalConfirmedConfig.open && !exportStatsModalConfirmedConfig.error && (
          <Modal handleCancel={closeStatsConfirmModal} handleOk={closeStatsConfirmModal} okMessage="Ok" okOnly>
            Your stats are on their way!
            <br />
            Please check your email :)
          </Modal>
        )}
        {exportStatsModalConfirmedConfig.open && exportStatsModalConfirmedConfig.error && (
          <Modal handleCancel={closeStatsConfirmModal} handleOk={closeStatsConfirmModal} okMessage="Ok" okOnly>
            We are not able to export your stats
            <br />
            Sorry for that :(
          </Modal>
        )}
        <div className="analytics__graph box">
          {!loading && <Graph query={query} total={total} stats={stats} />}
          {loading && <LineGraphPlaceholder />}
        </div>
        {this.renderFacets()}
      </div>
    );
  }

  renderFacets = () => {
    const { loadingFacets, facets, redirect, account } = this.props;

    if (loadingFacets) {
      return renderFacetsPlaceholder();
    }

    const { redirectsView, mapView, referersView, tagsView, osesView, browsersView } = this.state;

    const nextRedirectsView: status = redirectsView === 'list' ? 'chart' : 'list';
    const nextMapView: status = mapView === 'list' ? 'map' : 'list';
    const nextReferersView: status = referersView === 'list' ? 'chart' : 'list';
    const nextTagsView: status = tagsView === 'list' ? 'chart' : 'list';
    const nextBrowsersView: status = browsersView === 'list' ? 'chart' : 'list';
    const nextOsesView: status = osesView === 'list' ? 'chart' : 'list';

    return (
      <Fragment>
        {facets.redirects && !redirect && (
          <FacetBox
            title="Top 10 redirects"
            nextStatus={nextRedirectsView}
            handleSwitchStatus={() => this.setState({ redirectsView: nextRedirectsView })}
          >
            {redirectsView === 'list' && (
              <ListView
                title="Redirect"
                total={totalValue(facets.redirects)}
                values={sortOnValue(facets.redirects).map((f) => ({
                  ...f,
                  to: `/${account.id}/ab/analytics/l/${f.id}`,
                }))}
              />
            )}
            {redirectsView === 'chart' && (
              <Donut nbOfItems={5} total={totalValue(facets.redirects)} values={sortOnValue(facets.redirects)} />
            )}
          </FacetBox>
        )}
        {facets.countries && (
          <FacetBox
            title="Countries"
            nextStatus={nextMapView}
            handleSwitchStatus={() => this.setState({ mapView: nextMapView })}
          >
            {mapView === 'list' && (
              <ListView title="country" total={totalValue(facets.countries)} values={sortOnValue(facets.countries)} />
            )}
            {mapView === 'map' && (
              <Map maxValue={maxValue(facets.countries)} data={exportCountriesArrayToMap(facets.countries)} />
            )}
          </FacetBox>
        )}
        {facets.referers && (
          <FacetBox
            title="Top 10 referers"
            tooltip={`We're only collecting "referrers data" since Sept 5th, 18. So the  total counts might be partial`}
            nextStatus={nextReferersView}
            handleSwitchStatus={() => this.setState({ referersView: nextReferersView })}
          >
            {referersView === 'list' && (
              <ListView title="referers" total={totalValue(facets.referers)} values={sortOnValue(facets.referers)} />
            )}
            {referersView === 'chart' && (
              <Donut nbOfItems={5} total={totalValue(facets.referers)} values={sortOnValue(facets.referers)} />
            )}
          </FacetBox>
        )}
        {facets.tags && (
          <FacetBox
            title="Top 10 tags"
            tooltip={`We're only collecting "tags data" since Sept 5th, 18. So the  total counts might be partial`}
            nextStatus={nextTagsView}
            handleSwitchStatus={() => this.setState({ tagsView: nextTagsView })}
          >
            {tagsView === 'list' && (
              <ListView title="tags" total={totalValue(facets.tags)} values={sortOnValue(facets.tags)} />
            )}
            {tagsView === 'chart' && (
              <Donut nbOfItems={5} total={totalValue(facets.tags)} values={sortOnValue(facets.tags)} />
            )}
          </FacetBox>
        )}
        {facets.browsers && (
          <FacetBox
            title="Top 10 browsers"
            nextStatus={nextBrowsersView}
            handleSwitchStatus={() => this.setState({ browsersView: nextBrowsersView })}
          >
            {browsersView === 'list' && (
              <ListView title="browsers" total={totalValue(facets.browsers)} values={sortOnValue(facets.browsers)} />
            )}
            {browsersView === 'chart' && (
              <Donut nbOfItems={5} total={totalValue(facets.browsers)} values={sortOnValue(facets.browsers)} />
            )}
          </FacetBox>
        )}
        {facets.oses && (
          <FacetBox
            title="Top 10 operating systems"
            nextStatus={nextOsesView}
            handleSwitchStatus={() => this.setState({ osesView: nextOsesView })}
          >
            {osesView === 'list' && (
              <ListView title="Operating system" total={totalValue(facets.oses)} values={sortOnValue(facets.oses)} />
            )}
            {osesView === 'chart' && (
              <Donut nbOfItems={5} total={totalValue(facets.oses)} values={sortOnValue(facets.oses)} />
            )}
          </FacetBox>
        )}
      </Fragment>
    );
  };

  componentDidMount() {
    this.loadStats();
  }

  componentDidUpdate(prevProps: props & { account: accountT }, prevState) {
    const { account, query, match } = this.props;
    const { redirectId } = match.params;
    const { account: prevAccount, query: prevQuery, match: prevMatch } = prevProps;
    const prevRedirectId = prevMatch.params.redirectId;

    if (account.id !== prevAccount.id || query.period !== prevQuery.period || redirectId !== prevRedirectId) {
      this.loadStats();
    }
  }

  loadStats = () => {
    const { loadAnalyticsAndFacetsThunk, loadAnalyticsAndFacetsForRedirectThunk, account, query, redirect, match } =
      this.props;
    // check in URL?
    const { redirectId } = match.params;

    if (redirectId) {
      if ((redirect === undefined && redirectId) || (redirect && redirectId && redirect.id !== redirectId)) {
        loadAnalyticsAndFacetsForRedirectThunk({
          redirectId,
          accountId: account.id,
        });

        return;
      }
    }

    loadAnalyticsAndFacetsThunk({
      query: { ...query, accountId: account.id },
      redirect: redirectId ? redirect : undefined,
    });
  };
}

const mapStateToProps = function (state): props {
  return state.analytics;
};

export default connect(mapStateToProps, {
  loadAnalyticsAndFacetsThunk,
  loadAnalyticsAndFacetsForRedirectThunk,
  loadAnalyticsAndFacets,
  closeStatsConfirmModal: () =>
    function (dispatch: Dispatch<*>, ownProps: Object) {
      dispatch(Actions.api.audienceBuilder.redirect.stats.export.confirmed());
    },
})(Analytics);

function exportCountriesArrayToMap(data: Array<{ id: string, label: string, value: number }>): {
  [key: string]: number,
} {
  const countriesMap = {};

  data.forEach((country) => {
    countriesMap[country.id] = country.value;
  });

  return countriesMap;
}

function renderFacetsPlaceholder() {
  return [
    <FacetBoxPlaceholder key="1" />,
    <FacetBoxPlaceholder key="2" />,
    <FacetBoxPlaceholder key="3" />,
    <FacetBoxPlaceholder key="4" />,
  ];
}
