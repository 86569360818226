import { useMemo } from 'react';

import styled from 'styled-components';

import { OPPORTUNITY, getOpportunityColors } from './constants';

const Style = styled.div`
  width: 76px;
  height: 28px;
  border-radius: 30px;
  > span {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) => getOpportunityColors(props.$opportunity)}
  }
`;

export const OpportunityBadge = ({ score }: { score: number }) => {
  const opportunity = useMemo(() => {
    if (score >= 8) {
      return OPPORTUNITY.VERY_HIGH;
    }

    if (score >= 6.5) {
      return OPPORTUNITY.HIGH;
    }

    return OPPORTUNITY.MEDIUM;
  }, [score]);

  return (
    <Style $opportunity={opportunity}>
      <span>{opportunity}</span>
    </Style>
  );
};
