import { createSelector } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { addApiError, trackFromBackend } from 'actions';
import axios from 'lib/axios.factory';
import { getMessageFromAxiosError } from 'lib/error';
import { toCamelCaseKeys, toSnakeCaseKeys } from 'lib/util';

export const TAGS = {
  AUDITS: 'Audits',
  AD_GROUPS: 'AdGroups',
  VARIATIONS: 'Variations',
  PRODUCTS: 'Products',
  PRODUCTS_FOR_TABLE: 'ProductsForTable',
  CAMPAIGNS: 'Campaigns',
  CAMPAIGN_METRICS: 'CampaignMetrics',
  ALERTS: 'Alerts',
};
const CACHE_EXPIRY = 60 * 30; // 30 minutes

const axiosBaseQuery = async (args, { dispatch, getState }, extraOptions = {}) => {
  const accessToken = localStorage.getItem('access-token');
  const impersonate = localStorage.getItem('impersonate');
  const { url, method, data, params, headers = {} } = args;
  const { secure = true, includeAccountId = true, useDefaultErrorHandling = true } = extraOptions; // Custom args we pass per endpoint
  const accountId = createSelector(
    (state) => state.accounts,
    (root) => root.selected,
  )(getState());

  if (secure) {
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    if (impersonate) {
      headers.Impersonate = impersonate;

      if (localStorage.getItem('impersonate_access_override') === 'true') {
        headers.ImpersonateAccessOverride = 'true';
      }
    }
  }

  try {
    const payload = {
      url,
      method,
      data: toSnakeCaseKeys(data),
      params: toSnakeCaseKeys({
        ...params,
        ...(includeAccountId && accountId ? { accountId } : {}),
      }),
      headers,
    };
    const response = await axios(payload);

    dispatch(trackFromBackend(response.data));

    return { data: toCamelCaseKeys(response.data) };
  } catch (err) {
    if (useDefaultErrorHandling) {
      dispatch(addApiError(err, url, method));
    }

    const errRes = getMessageFromAxiosError(err);

    return {
      error: {
        status: err.response?.status,
        data: { ...err, ...errRes },
      },
    };
  }
};

// $FlowFixMe
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery,
  tagTypes: Object.values(TAGS),
  keepUnusedDataFor: CACHE_EXPIRY,
  endpoints: () => ({
    // Other slices will call `apiSlice.injectEndpoints` to add their endpoints here.
    // This separation is for maintainability, but all endpoints are still under the same api slice.
  }),
});
