export const tiktokVideosInteractionTypes = [
  {
    value: 'WATCHED_TO_END',
    label: 'Watched till',
  },
  {
    value: 'LIKED',
    label: 'Liked',
  },
  {
    value: 'COMMENTED',
    label: 'Commented',
  },
  {
    value: 'SHARED',
    label: 'Shared',
  },
];
