export const tiktokDeliverySpeeds = [
  {
    value: 'PACING_MODE_SMOOTH',
    label: 'Standard delivery',
    description:
      'Your budget and ads will be distributed evenly based on the business of traffic during the selected advertising period. If the traffic is busy, your ads will be sent more frequently, and vice versa.',
  },
  {
    value: 'PACING_MODE_FAST',
    label: 'Accelerated delivery',
    description:
      'When you start to run your ad, the system will help you spend your budget and get your value results as quickly as possible and maximize the effectiveness of reaching your audience.',
  },
];
