import styled from 'styled-components';

import { getStatusColors } from './constants';

const STATUS = {
  DONE: 'Completed',
  IN_PROGRESS: 'In Progress',
  FAILED: 'Failed',
};

const Style = styled.div`
  width: 90px;
  height: 26px;
  border-radius: 20px;
  padding: 0 8px;
  > span {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) => getStatusColors(props.$status)}
`;

export const StatusBadge = ({ status }: { status: string }): React$MixedElement => (
  <Style $status={status}>
    <span>{STATUS[status]}</span>
  </Style>
);
