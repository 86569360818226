import React, { useMemo, type Node } from 'react';

import { Statistic, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { BrbNotOnboarded, BrbValue } from './BrbValue';
import { METRICS, TOOLTIPS, roasFormatter } from './constants';
import { InfoIcon } from './Style';

const Container = styled.div`
  flex: 1;

  div.ant-statistic {
    text-align: left !important;
    border-right: none !important;
    background-color: #fff;
  }
  div.ant-statistic-title {
    font-size: 0.75rem;
    color: #071629;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
    padding: 0.5rem 1rem;
  }
  div.ant-statistic-content {
    margin: 0;
    padding: 0.5rem 1rem;
    display: flex;
    white-space: nowrap;
    > * {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0;
    }
  }
`;

const RowContainer = styled(Container)`
  div.ant-statistic {
    flex-direction: row;
    justify-content: unset;
    align-items: center;
    column-gap: 0.75rem;
  }
  div.ant-statistic-title {
    border: none;
    padding: 0;
  }
  div.ant-statistic-content {
    padding: 0;
  }
`;

const SmallText = styled.span`
  font-size: 0.625rem;
`;

export const AdSpendMetrics = (props: { cost: number, currency: string }): Node => {
  const { messages } = useIntl();

  return (
    <Container>
      <Statistic
        title={`PixelMe ${messages.locales.columns.adSpend}`}
        value={props.cost || 0}
        precision={2}
        prefix={props.currency}
      />
    </Container>
  );
};

export const ImpressionsMetrics = (props: { impressions: number }): Node => {
  const { messages } = useIntl();

  return (
    <Container>
      <Statistic
        title={
          <>
            {messages.locales.columns.impressions}
            <Tooltip title={TOOLTIPS.IMPRESSIONS}>
              <InfoIcon />
            </Tooltip>
          </>
        }
        value={props.impressions || 0}
      />
    </Container>
  );
};

export const ClicksMetrics = (props: { clicks: number }): Node => {
  const { messages } = useIntl();

  return (
    <Container>
      <Statistic
        title={
          <>
            {messages.locales.columns.clicks}
            <Tooltip title={TOOLTIPS.CLICKS}>
              <InfoIcon />
            </Tooltip>
          </>
        }
        value={props.clicks || 0}
      />
    </Container>
  );
};

export const AddToCartMetrics = (props: { adds: number }): Node => {
  const { messages } = useIntl();

  return (
    <Container>
      <Statistic title={messages.locales.columns.addToCart} value={props.adds || 0} precision={0} />
    </Container>
  );
};

export const PurchasesMetrics = (props: { purchases: number }): Node => {
  const { messages } = useIntl();

  return (
    <Container>
      <Statistic title={messages.locales.columns.purchases} value={props.purchases || 0} precision={0} />
    </Container>
  );
};

export const RevenueMetrics = (props: { revenue: number, currency: string }): Node => {
  const { messages } = useIntl();

  return (
    <Container>
      <Statistic
        title={
          <>
            {messages.locales.columns.revenue}
            <Tooltip title={TOOLTIPS.REVENUE}>
              <InfoIcon />
            </Tooltip>
          </>
        }
        value={props.revenue || 0}
        precision={2}
        prefix={props.currency}
      />
    </Container>
  );
};

export const NetMarginMetrics = (props: { netMargin: number, currency: string }): Node => {
  const { messages } = useIntl();

  return (
    <Container>
      <Statistic
        title={
          <>
            {messages.locales.columns.netMargin}
            <Tooltip title={TOOLTIPS.NET_MARGIN}>
              <InfoIcon />
            </Tooltip>
          </>
        }
        value={props.netMargin || 0}
        precision={2}
        prefix={props.currency}
      />
    </Container>
  );
};

export const ReferralBonusMetrics = (props: {
  brbBonusAmount?: number,
  brbBonusAmountCalculated?: number,
  currencySymbol: string,
  currencyCode: string,
  brbEnrolled: boolean,
}) => {
  // TODO change brbBonusAmountCalculated to brbBonusAmount when we will have the explanation from Amazon
  const referralBonus = useMemo(
    () => BrbValue.formatCurrency(props.brbBonusAmountCalculated || 0, props.currencyCode),
    [props.brbBonusAmountCalculated, props.currencyCode],
  );

  if (props.brbEnrolled === false) {
    return (
      <BrbNotOnboarded
        brbBonusAmountCalculated={props.brbBonusAmountCalculated || 0}
        style={{
          color: 'var(--bad-color)',
          width: '120px',
          padding: '0 !important',
          paddingLeft: 5,
          paddingRight: 5,
        }}
        currencyCode={props.currencyCode}
      />
    );
  }

  // props.brbEnrolled  === undefined => non US Marketplace so we display the calculated
  // Amount that is inside brbBonusAmount
  return (
    <Container>
      <Statistic
        title={
          <>
            <SmallText>{METRICS.REFERRAL_BONUS}</SmallText>
            <Tooltip title={TOOLTIPS.REFERRAL_BONUS}>
              <InfoIcon />
            </Tooltip>
          </>
        }
        precision={2}
        value={referralBonus}
      />
    </Container>
  );
};

export const AcosMetrics = ({ acos = 0 }: { acos: number }): Node => (
  <Container>
    <Statistic
      title={
        <>
          {METRICS.ACOS}
          <Tooltip title={TOOLTIPS.ACOS}>
            <InfoIcon />
          </Tooltip>
        </>
      }
      value={acos < 0 ? 0 : acos}
      precision={0}
      suffix="%"
      valueStyle={{ color: acos > 100 ? 'inherit' : '#0F993E' }}
    />
  </Container>
);

export const RoasMetrics = ({ roas = 0 }: { roas: number }): Node => (
  <Container>
    <Statistic
      title={
        <>
          {METRICS.ROAS}
          <Tooltip title={TOOLTIPS.ROAS}>
            <InfoIcon />
          </Tooltip>
        </>
      }
      precision={2}
      valueStyle={{ color: roas >= 0 && roas < 100 ? 'inherit' : '#0F993E' }}
      value={roasFormatter(roas, false)}
    />
  </Container>
);

export const AmazonAdSpendMetrics = (props: { cost: number, currency: string }) => (
  <RowContainer>
    <Statistic title={METRICS.AMAZON_AD_SPEND} value={props.cost || 0} precision={2} prefix={props.currency} />
  </RowContainer>
);

export const TotalAdSpendMetrics = (props: { cost: number, currency: string }): Node => (
  <RowContainer>
    <Statistic
      title={
        <>
          {METRICS.TOTAL_AD_SPEND}
          <Tooltip placement="bottom" title={TOOLTIPS.TOTAL_AD_SPEND}>
            <InfoIcon />
          </Tooltip>
        </>
      }
      value={props.cost || 0}
      precision={2}
      prefix={props.currency}
    />
  </RowContainer>
);

export const TotalAmazonSalesMetrics = (props: { sales: number, currency: string }): Node => (
  <RowContainer>
    <Statistic
      title={
        <>
          {METRICS.TOTAL_AMAZON_SALES}
          <Tooltip placement="bottom" title={TOOLTIPS.TOTAL_AMAZON_SALES}>
            <InfoIcon />
          </Tooltip>
        </>
      }
      value={props.sales || 0}
      precision={2}
      prefix={props.currency}
    />
  </RowContainer>
);

export const OrganicAmazonSalesMetrics = (props: { sales: number, currency: string }): Node => (
  <RowContainer>
    <Statistic
      title={
        <>
          {METRICS.ORGANIC_SALES}
          <Tooltip placement="bottom" title={TOOLTIPS.ORGANIC_SALES}>
            <InfoIcon />
          </Tooltip>
        </>
      }
      value={props.sales || 0}
      precision={2}
      prefix={props.currency}
    />
  </RowContainer>
);
