import React from 'react';

export function Linkedin() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none" viewBox="0 0 16 16">
      <g>
        <circle cx="8" cy="8" r="8" fill="#007BB6"></circle>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M5.187 3.535c-.1-.109-.262-.163-.488-.163-.289 0-.496.086-.623.258-.117.171-.176.415-.176.73v2.37a.168.168 0 01-.054.122.168.168 0 01-.122.055H2.478c-.027 0-.067-.018-.122-.055-.036-.054-.054-.094-.054-.121V2.357c0-.027.018-.068.054-.122.055-.036.095-.054.122-.054h1.205a.24.24 0 01.109.027c.018.009.036.04.054.094v.068l.014.027v.109c.297-.29.708-.434 1.232-.434.587 0 1.038.14 1.354.42.325.298.488.727.488 1.287V6.73a.168.168 0 01-.055.121.168.168 0 01-.122.055H5.485c-.028 0-.068-.018-.122-.055-.036-.054-.054-.094-.054-.121V4.063c0-.226-.041-.402-.122-.528zm-3.752-2.1a.81.81 0 01-.595.244.81.81 0 01-.596-.244A.81.81 0 010 .84C0 .614.081.42.244.257a.818.818 0 011.191 0A.795.795 0 011.68.84a.81.81 0 01-.244.595zm.203.922V6.73c0 .027-.018.067-.054.122-.054.036-.095.054-.122.054H.216c-.027 0-.068-.018-.122-.054C.058 6.798.04 6.758.04 6.73V2.357c0-.027.018-.068.054-.122.054-.036.095-.054.122-.054h1.246c.027 0 .068.018.122.054.036.054.054.095.054.122z"
          clipRule="evenodd"
          transform="translate(4.7 4)"
        ></path>
      </g>
    </svg>
  );
}
