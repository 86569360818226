import React from 'react';

import { Card } from 'antd';
import Progress from 'antd/lib/progress';
import Tag from 'antd/lib/tag';
import moment from 'moment';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';
import { getIntlStrings } from 'locales/i18n/localeHelpers';

import { addCoupon } from '../../../lib/api';
import { s } from '../../../lib/safe';
import { strings } from '../../../locales/strings';
import { isLTDF } from '../../../selectors/sub.selector';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Separator from '../../common/Separator';
import TrialHeader from '../../layout/Banners/TrialHeader';

export function Ltdf({ sub, account, user, updating, limitations, plans }) {
  const [state, updateState] = useImmer({ coupon: undefined });
  const orgId = Object.keys(user.orgsRights)[0];

  if (!plans) {
    return <></>;
  }

  const currentPlans = plans.filter((p) => isLTDF({ plan: p }));

  async function handleAddCoupon(code) {
    await addCoupon(localStorage, axios, { accountId: account.id }, { orgId, code });
    window.location.reload();
  }

  const isTrialEnded = moment(sub.trialEnd) < new moment() && !sub.plan.id.startsWith('lifetimedeal_ltdf_');
  const isNotPaying = sub.status === 'not_paying';

  if (sub.trialEnd && isNotPaying) {
    window.location.href = 'https://pixelme.me/ltdf';

    return <></>;
  }

  return (
    <>
      {sub.plan.id !== 'lifetimedeal_ltdf_2' && (
        <>
          <Card header={`${sub.codes.length} coupon${sub.codes.length > 1 ? 's' : ''} used`}>
            {sub.status === 'revoked' && (
              <>
                <Separator />
                <p className="revoked">{getIntlStrings('endOfTrial.revoked')} Please contact us.</p>
              </>
            )}
            <Separator />
            <form className="coupons__add">
              <Input id="code" value={state.coupon} placeholder="ltdf_xxxx" onChange={handleCouponChange} />
              {updating && <Button disabled>Addi ng coupon...</Button>}
              {!updating && (
                <Button
                  handleClick={(event: SyntheticEvent<HTMLInputElement>) => {
                    event.preventDefault();
                    handleAddCoupon(state.coupon);
                  }}
                >
                  Add coupon
                </Button>
              )}
            </form>
          </Card>
          <br />
        </>
      )}
      <Card
        headStyle={{ color: '#071629', fontSize: '14px' }}
        title={
          <>
            Your lifetime plan:{' '}
            {sub.codes.map((c) => (
              <Tag>{c}</Tag>
            ))}
            {sub.plan.id !== 'lifetimedeal_ltdf_2' ? (
              <a href={currentPlans[2].linkToBuy}>
                <Tag
                  color="var(--pixelme-light-color)"
                  style={{
                    border: 'rgb(217, 217, 217) dashed',
                    color: 'black',
                  }}
                >
                  New Coupon
                </Tag>
              </a>
            ) : (
              <></>
            )}
            <br />
            {sub.plan.id === 'lifetimedeal_ltdf_2' ? (
              <Progress percent={100} size="small" />
            ) : (
              <Progress percent={sub.codes.length * 30} size="small" />
            )}
          </>
        }
      >
        {!isTrialEnded && <TrialHeader sub={sub} account={account} compact={true} />}
        {s(sub.plan).id !== 'lifetimedeal_ltdf_2' && !s(sub.plan).id.startsWith('lifetimedeal_appsumo') && (
          <iframe
            frameBorder={0}
            title="coupon"
            src={(isNotPaying ? sub.plan.linkToBuy : sub.plan.linkToUpgrade) || 'https://pxle.me/growth'}
            width="100%"
            height={800}
          />
        )}
      </Card>
    </>
  );

  function handleCouponChange(event: SyntheticEvent<HTMLInputElement>) {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;

    updateState((draft) => {
      draft.coupon = value;
    });
  }
}
