import React, { Fragment } from 'react';

import { ReloadOutlined } from '@ant-design/icons';
import { Button, Modal, Popover } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { Amazon } from 'components/common/icons/Amazon';
import { DATE_FORMAT_HOURS } from 'consts/consts';
import { useLoading } from 'hooks/useLoading';
import { syncAdsProvider, syncProductProvider } from 'lib/adsPlateformApi';
import axios from 'lib/axios.factory';

import { useDataProviderAllowed } from './useDataProviderAllowed';
import { MANUAL_SYNC, useFeatureFlag } from '../../../../../lib/configcat';
import { AdsProviderLogo } from '../../common/AdsProviderLogo';

const ProductProviderLine = (props: {
  provider: productProviderT,
  account: accountT,
  history: any,
  allowSync: boolean,
}) => {
  const { doAction, loading } = useLoading(false);

  return (
    <Fragment key={props.provider.id}>
      <li
        prefix={<></>}
        style={{
          display: 'flex',
          gap: '1em',
          height: '40px',
          alignItems: 'center',
        }}
      >
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="4" fill="#2EC1B3" />
        </svg>
        <div
          size={{ cursor: 'pointer' }}
          onClick={() => {
            props.history.push(`/${props.account.id}/amazon/reports/${props.provider.id}`);
          }}
        >
          <Amazon style={{ height: 16, width: 16 }} />
        </div>
        <span
          style={{
            maxWidth: 200,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {props.provider.externalAccountDescriptiveName}
        </span>
        <span>
          {props.provider.lastProcessedAt && moment(props.provider.lastProcessedAt).format(DATE_FORMAT_HOURS)}
        </span>
        {props.provider.lastErrorAt && (
          <Popover title="Last Error" content={props.provider.lastError}>
            <div style={{ color: 'red' }}>(Error: {moment(props.provider.lastErrorAt).format(DATE_FORMAT_HOURS)})</div>
          </Popover>
        )}
        {props.provider.countRedirectsForProvider && (
          <span>
            ({`${props.provider.countRedirectsFoundOnProvider || 0}/${props.provider.countRedirectsForProvider || 0}`})
          </span>
        )}
        {props.allowSync && (
          <Button
            size="small"
            type="link"
            loading={loading}
            icon={<ReloadOutlined />}
            onClick={() => {
              doAction(async () => {
                await syncProductProvider(localStorage, axios, { accountId: props.account.id }, props.provider.id);
                props.provider.lastProcessedAt = Date.now();
                window.location.reload();
              });
            }}
          ></Button>
        )}
      </li>
    </Fragment>
  );
};

const AdsProviderLine = (props: { provider: adsProviderT, account: accountT, history: any, allowSync: boolean }) => {
  const { doAction, loading } = useLoading(false);

  return (
    <Fragment key={props.provider.id}>
      <li
        prefix={<></>}
        style={{
          display: 'flex',
          gap: '1em',
          height: '40px',
          alignItems: 'center',
        }}
      >
        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="4" fill="#2EC1B3" />
        </svg>
        <AdsProviderLogo style={{ height: 16, width: 16 }} providerType={`${props.provider.providerType}`} />
        <span
          style={{
            maxWidth: 200,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {props.provider.externalAccountDescriptiveName}
        </span>
        <span>
          {props.provider.campaignStatusProcessingStatus.lastProcessedAt &&
            moment(props.provider.campaignStatusProcessingStatus.lastProcessedAt).format(DATE_FORMAT_HOURS)}
        </span>
        {props.provider.campaignStatusProcessingStatus.lastErrorAt && (
          <Popover title="Last Error" content={props.provider.campaignStatusProcessingStatus.lastError}>
            <div style={{ color: 'red' }}>
              (Error: {moment(props.provider.campaignStatusProcessingStatus.lastErrorAt).format(DATE_FORMAT_HOURS)})
            </div>
          </Popover>
        )}
        <span>
          {props.provider.campaignMetricsProcessingStatus.lastProcessedAt &&
            moment(props.provider.campaignMetricsProcessingStatus.lastProcessedAt).format(DATE_FORMAT_HOURS)}
        </span>
        {props.provider.campaignMetricsProcessingStatus.lastErrorAt && (
          <Popover title="Last Error" content={props.provider.campaignMetricsProcessingStatus.lastError}>
            <div style={{ color: 'red' }}>
              (Error: {moment(props.provider.campaignMetricsProcessingStatus.lastErrorAt).format(DATE_FORMAT_HOURS)})
            </div>
          </Popover>
        )}
        {props.allowSync && (
          <Button
            size="small"
            type="link"
            loading={loading}
            icon={<ReloadOutlined />}
            onClick={() => {
              doAction(async () => {
                await syncAdsProvider(localStorage, axios, { accountId: props.account.id }, props.provider.id);
                props.provider.lastProcessedAt = Date.now();
                window.location.reload();
              });
            }}
          ></Button>
        )}
      </li>
    </Fragment>
  );
};

/**
 * A hook used to open the data provider modal
 * @param {object} { account }
 * @returns {[func,boolean]} A function that opens the data provider modal and a `boolean` of if user is allowed.
 */
export const useDataProviderModal = ({ account }) => {
  const history = useHistory();
  const isAllowed = useDataProviderAllowed();

  const [showManualSyncBtn] = useFeatureFlag(MANUAL_SYNC);

  if (!isAllowed) {
    return [() => {}, false];
  }

  return [
    () =>
      Modal.info({
        width: 900,
        title: 'Data last update',
        content: (
          <div style={{ marginLeft: '-30px', marginTop: 40, maxHeight: '500px', overflow: 'auto' }}>
            <ul>
              {account.productProviders.map((provider) => (
                <ProductProviderLine
                  key={provider.id}
                  provider={provider}
                  account={account}
                  history={history}
                  allowSync={showManualSyncBtn}
                />
              ))}
              {account.adsProviders
                .filter((p) => !p.generated)
                .sort((p1, p2) => (p1.providerType || '').localeCompare(p2.providerType))
                .map((provider) => (
                  <AdsProviderLine
                    key={provider.id}
                    provider={provider}
                    account={account}
                    history={history}
                    allowSync={showManualSyncBtn}
                  />
                ))}
            </ul>
          </div>
        ),
        onOk() {},
      }),
    true,
  ];
};
