import React from 'react';

import type { adsCampaignT, productT } from '../../../../../flow-typed/pixelme/adsPlateform';

export function HideIfNoAmazonData(props: { report: adsCampaignT | productT | variationT, children: any }) {
  if (!props.report.lastClicks) {
    return <></>;
  }

  return props.children;
}
