import React, { type Node } from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  row-gap: 0.25rem;
`;

const Bold = styled.span`
  font-weight: 600;
`;

const CurrentRank = styled.div`
  font-size: 1rem;
`;

const PreviousRank = styled.div`
  font-size: 0.75rem;
  position: relative;
`;

const Caption = styled.span`
  margin-left: 0.5rem;
`;

type propsT = {
  category: string,
  rank: number,
  previousRank: number,
};
export const CategoryRank = ({ category, rank, previousRank }: propsT): Node => {
  let changeText = 'Steady at';

  if (rank > previousRank) {
    changeText = 'Down from';
  } else if (rank < previousRank) {
    changeText = 'Up from';
  }

  return (
    <Container>
      <CurrentRank>
        <Bold>#{rank.toLocaleString()}</Bold> in {category}
      </CurrentRank>
      <PreviousRank>
        <Bold>
          {changeText} #{previousRank.toLocaleString()}
        </Bold>
        <Caption>(original position prior to any PixelMe campaigns)</Caption>
      </PreviousRank>
    </Container>
  );
};
