import React from 'react';

import _ from 'lodash';
import numeral from 'numeral';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import { getStats, isARealCampaignId } from 'lib/campaigns';

import { MAX_REDIRECTS_COUNT } from '../../../../../consts/consts';

const Container = styled.div.withConfig({
  displayName: 'ContainerCampaignRedirectsCount',
})`
  opacity: ${(props) => (props.loading === 'true' ? '0.1' : '1')};
  color: ${(props) =>
    props.selected ? 'var(--pixelme-color) !important' : props.important ? '#071629 !important' : '#828282 !important'};
  margin-right: 15px;
  min-width: 20px;
  text-align: right;
  margin-right: 15px;
`;

function AbstractCampaignRedirectsCount({
  campaignId,
  subCampaignId,
  selectedCampaignId,
  selectedSubCampaignId,
  loading,
  redirectsCount,
}) {
  if (redirectsCount < MAX_REDIRECTS_COUNT) {
    const isSelected = subCampaignId
      ? _.isEqual(selectedSubCampaignId, subCampaignId) && _.isEqual(selectedCampaignId, campaignId)
      : _.isEqual(selectedCampaignId, campaignId) && !isARealCampaignId(selectedSubCampaignId);

    return (
      <Container loading={loading.toString()} selected={isSelected} important={!subCampaignId}>
        {numeral(redirectsCount).format('0,000')}
      </Container>
    );
  }

  return <></>;
}

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT, ownProps) => {
  const stats = getStats(ownProps.campaignId, ownProps.subCampaignId, state);

  return {
    loading: state.campaigns.loading,
    selectedCampaignId: state.campaigns.selectedCampaignId,
    selectedSubCampaignId: state.campaigns.selectedSubCampaignId,
    redirectsCount: stats.nbRedirects,
  };
};

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  selectCampaign: (campaignId, subCampaignId) =>
    dispatch(
      Actions.front.audienceBuilder.campaigns.select.request({
        campaignId,
        subCampaignId,
      }),
    ),
});

const reduxWrapper = connect(stateToProps, dispatchToProps);

export const CampaignRedirectsCount = styled(reduxWrapper(AbstractCampaignRedirectsCount))``;
