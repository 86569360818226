import React from 'react';

import { LockOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Tooltip as ITooltip } from 'react-tippy';
import styled from 'styled-components';

import { capitalizeFirstLetter } from 'lib/util';
import { selectCurrentAccount } from 'stores/accounts';

import { planPath } from '../tooltip';

const Style = styled.div`
  .ant-btn > span[role='img'] {
    font-size: 30px;
  }
`;

export function ILimitedButton({
  account,
  subscription,
  usage,
  limitations,
  limitationTest,
  children,
  style,
  className,
  feature,
  block,
  danger,
  type,
  ...rest
}) {
  const plan = _.get(subscription, ['sub', 'plan']) || { type: '-', name: '-' };

  let text = `${capitalizeFirstLetter(plan.name)} plan doesn’t include this feature (${feature}).`;

  let t = limitationTest(limitations, usage);

  if (typeof t === 'string' || t instanceof String) {
    text = t.replace(':plan:', plan.name);
    t = false;
  }

  if (t) {
    return (
      <Style>
        <Button block={block} danger={danger} type={type} style={style} className={className} {...rest}>
          {children}
        </Button>
      </Style>
    );
  } else {
    return (
      <ITooltip
        arrow="true"
        theme="light"
        size="large"
        position="top-start"
        interactive={true}
        html={
          <div style={{ width: '200px', textAlign: 'left', fontSize: '12px' }}>
            {text}
            <br />
            <br />
            <a href={planPath(account.id)}>UPGRADE</a>
          </div>
        }
      >
        <Style>
          <div>
            <Button
              {..._.omit(rest, 'dispatch')}
              style={style}
              block
              className={className}
              icon={<LockOutlined />}
              disabled={true}
            >
              {children}
            </Button>
          </div>
        </Style>
      </ITooltip>
    );
  }
}

const stateToProps = function (state): internalPropsT {
  return {
    account: selectCurrentAccount(state),
    limitations: state.accounts.limitations,
    subscription: state.subscription,
    usage: state.accounts.usage,
  };
};

export const LimitedButton = connect(stateToProps)(ILimitedButton);
