import styled from 'styled-components';

const getColor = (type) => {
  let color = '';

  switch (type) {
    case 'nbClicks':
    case 'nbRedirects':
      color = 'var(--pixelme-color) !important';
      break;
    default:
      color = 'var(--pixelme-color) !important';
  }

  return color;
};

const getDisplay = (type) => {
  let display = '';

  switch (type) {
    case 'nbClicks':
    case 'nbRedirects':
      display = 'block';
      break;
    default:
      display = 'block';
  }

  return display;
};

export const StatsValue = styled.span.withConfig({
  displayName: 'StatsValue',
})`
  color: ${(props) => getColor(props.type)};
  display: ${(props) => getDisplay(props.type)}
  font-size: 12px;
  padding-top: 10px;
`;
