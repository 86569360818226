import React from 'react';

import styled from 'styled-components';

const StyledDiv = styled.div`
  background: #fafafa;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 24px;
`;

export default () => (
  <StyledDiv>
    We use this information to perform optimizations like pausing ads when a product is not available.
    <br />
    It also allows us to provide reports on organic sales increase, and TACOS calculations.
  </StyledDiv>
);
