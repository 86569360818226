import './Card.css';
import React from 'react';

export default function Card({ card }: { card: cardT }) {
  return (
    <div className="credit-card">
      XXXX XXXX XXXX {card.last4Digits} ({card.brand}) owner {card.name}
    </div>
  );
}
