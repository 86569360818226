import React, { Fragment, useEffect, useState } from 'react';

import { Alert, Spin } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { apiCall } from 'lib/api';
import axios from 'lib/axios.factory';
import { strings } from 'locales/strings';

import { GoogleAdsLogo } from './GoogleAdsLogo';
import { LimitedButton } from '../../../common/antd/LimitedButton';

const Style = styled.div`
  a {
    text-decoration: none !important;
  }
  .btn-text {
    width: 180px;
  }
`;

const ProviderButton = styled(LimitedButton)`
  height: 51px;
  padding: 1em;
  display: flex;
  gap: 1em;
`;

type GoogleLoginButtonProps = {
  account: accountT,
  adsProvider?: adsProviderT,
};
export function GoogleLoginButton({ account, adsProvider, widgetStartType }: GoogleLoginButtonProps) {
  const [url, setUrl] = useState();
  const { messages } = useIntl();

  async function load() {
    if (!url) {
      const response = await apiCall({
        localStorage,
        axios,
        secure: true,
        method: 'get',
        url: '/ads-providers/google/oauth/redirect-url',
        params: { accountId: account.id },
      });

      setUrl(response.redirectUrl);
    }
  }

  useEffect(() => {
    load().then();
  }, []);

  const handleClick = () => {
    if (url) {
      window.location.href = url;
    }
  };

  useEffect(() => {
    if (widgetStartType === 'google' && url) {
      handleClick();
    }
  }, [widgetStartType, url]);

  if (!url) {
    return <Spin tip="Waiting for Google" />;
  }

  return (
    <Style>
      <a onClick={handleClick}>
        <ProviderButton
          icon={<GoogleAdsLogo size={22} />}
          feature={messages.googleAds.title}
          limitationTest={(limitations, usage) => usage.googleAdsProvider < limitations.limitGoogleAdsProvider}
        >
          <p className="btn-text">{adsProvider ? messages.googleAds.reconnect : messages.googleAds.connect}</p>
        </ProviderButton>
      </a>
    </Style>
  );
}
