export const getListItems = (campaigns: Array<campaignT>) =>
  (campaigns || []).map((campaign) => ({ id: campaign.id, value: campaign.name }));

export const getListItem = (campaign: campaignT) => {
  if (!campaign) {
    return campaign;
  }

  return { id: campaign.id, value: campaign.name };
};
