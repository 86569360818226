import './Pixels.css';

import React, { Component } from 'react';

import { CheckCircleTwoTone, WarningTwoTone, StopTwoTone } from '@ant-design/icons';
import { Input as AntInput, Tooltip } from 'antd';
import { connect } from 'react-redux';

import Box from 'components/common/Box';
import Button, { LightButton, CancelButton, LockedButton } from 'components/common/Button';
import DeleteItem from 'components/common/DeleteItem';
import DropDown from 'components/common/dropDown/DropDown';
import Input, { Textarea } from 'components/common/Input';
import Modal from 'components/common/Modal';
import Pixel from 'components/common/Pixel';
import { PixelsCTATooltip, CustomScriptCTATooltip } from 'components/common/tooltip';
import { addPixel, deletePixel, updatePixels, updateCustomPixels } from 'lib/api';
import axios from 'lib/axios.factory';
import { updateAccount, selectCurrentAccount, updateAccountsUsageThunk, updateAccountsUsage } from 'stores/accounts';

class Pixels extends Component<{
  account: accountT,
  limitations: { pixels: number, allowCustomScript: Boolean },
  usage: { pixels: number },
  updateAccount: (accountT) => *,
}> {
  render() {
    const { account } = this.props;
    const providers = account.providers.map(({ id, name }) => ({
      id,
      value: name,
    }));

    return (
      <div className="settings-pixels">
        <AddPixelForm {...this.props} providers={providers} />
        {account.pixels.length > 0 && <EditPixelForm {...this.props} providers={providers} />}
        <CustomForm {...this.props} providers={providers} />
      </div>
    );
  }
}

class AddPixelForm extends Component<
  {
    providers: Array<{ id: string, value: string }>,
    account: accountT,
    limitations: { pixels: number, allowCustomScript: Boolean },
    usage: { pixels: number },
    updateAccount: (accountT) => *,
  },
  { providerId: string, pixelName: string, pixelId: string, calling: boolean, accessToken: string },
> {
  constructor(props) {
    super(props);
    this.state = {
      providerId: this.props.providers[0].id,
      pixelName: '',
      pixelId: '',
      calling: false,
      accessToken: '',
    };
  }
  render() {
    // const {account, providers} = this.props;
    const { providers } = this.props;
    const { providerId, pixelName, pixelId, accessToken } = this.state;

    return (
      <Box className="settings-pixels__add" header="Add new pixel">
        <form id="new-pixel" onSubmit={this.handleSubmit}>
          <DropDown
            size="medium"
            id="pixel-provider"
            placeholder="Select a provider"
            values={providers}
            selected={[providerId]}
            onSelect={(selected) => this.setState({ providerId: selected[0] })}
            renderItem={(item) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Pixel provider={item.id} />
                {item.value}
              </div>
            )}
          />
          <Input value={pixelName} id="pixelName" placeholder="Name your pixel" onChange={this.handleChange} />
          <Input
            value={pixelId}
            id="pixelId"
            placeholder="Pixel ID ex: 10153742239885299"
            onChange={this.handleChange}
          />
          {(this.state.providerId === 'fb' || this.state.providerId === 'tkt') && (
            <AntInput.Password
              name="accessToken"
              visibilityToggle={false}
              allowClear
              value={accessToken}
              onChange={this.handleChange}
              placeholder="Access Token (optional)"
              className="access-token-input"
            />
          )}
          {this.renderButton()}
        </form>
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '400px' }}>
          {this.state.providerId === 'fb' && (
            <a
              style={{ lineHeight: '28px' }}
              href="https://www.carbon6.io/pixelme-help/how-to-verify-your-domain-on-facebook-and-use-it-on-pixelme-2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Verify your Domain before using a <b>Facebook pixel</b>. Learn more.
            </a>
          )}
          <a href="https://www.carbon6.io/pixelme-help?title=pixel+id" target="_blank" rel="noopener noreferrer">
            How do I find my pixel ID?
          </a>
          {this.state.providerId === 'fb' && (
            <a
              href="https://developers.facebook.com/docs/marketing-api/conversions-api/get-started/"
              target="_blank"
              rel="noopener noreferrer"
            >
              How do I get my FB Access Token?
            </a>
          )}
          {this.state.providerId === 'tkt' && (
            <a
              href="https://business-api.tiktok.com/portal/docs?rid=oyn7lhbo6ar&id=1771101130925058"
              target="_blank"
              rel="noopener noreferrer"
            >
              How do I get my TikTok Access Token?
            </a>
          )}
        </div>
      </Box>
    );
  }

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value.trim(),
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (this.validateFields()) {
      this.setState({ calling: true });
      const { account, usage, updateAccountsUsage, updateAccountsUsageThunk } = this.props;
      const { providerId, pixelName, pixelId, accessToken } = this.state;
      const pixelCount = account.pixels.length;

      addPixel(
        localStorage,
        axios,
        { accountId: account.id },
        {
          providerKey: providerId,
          name: pixelName,
          key: pixelId,
          accessToken: providerId === 'fb' || providerId === 'tkt' ? accessToken : undefined,
        },
      )
        .then(({ account }) => {
          this.setState({
            calling: false,
            providerId: this.props.providers[0].id,
            pixelName: '',
            pixelId: '',
            accessToken: '',
          });

          if (account.pixels.length !== pixelCount) {
            updateAccountsUsage({
              usage: { ...usage, pixels: usage.pixels + 1 },
            });
            updateAccountsUsageThunk();
          }

          return this.props.updateAccount(account);
        })
        .catch((error) => {
          this.setState({
            calling: false,
            providerId: this.props.providers[0].id,
            pixelName: '',
            pixelId: '',
            accessToken: '',
          });
        });
    }
  };

  validateFields = () => {
    const { providerId, pixelName, pixelId } = this.state;

    return providerId.length > 0 && pixelName.length > 0 && pixelId.length > 0;
  };

  renderButton = () => {
    const { limitations, usage } = this.props;

    if (usage && limitations.pixels !== -1 && usage.pixels >= limitations.pixels) {
      return (
        <PixelsCTATooltip>
          <LockedButton className="settings-button">Add a new pixel</LockedButton>
        </PixelsCTATooltip>
      );
    }

    if (this.validateFields()) {
      return (
        <Button loading={this.state.calling} loadingLabel="Adding..." className="settings-button">
          Add a new pixel
        </Button>
      );
    }

    return (
      <Button disabled className="settings-button">
        Add a new pixel
      </Button>
    );
  };
}

class EditPixelForm extends Component<
  {
    providers: Array<{ id: string, value: string }>,
    account: accountT,
    usage: { pixels: number },
  },
  {
    displayModal: boolean,
    pixels: Array<pixelT>,
    calling: boolean,
    edit: boolean,
    pixelId: string,
  },
> {
  constructor(props) {
    super(props);
    const { account } = props;

    this.state = {
      displayModal: false,
      pixels: account.pixels.map((pix) => ({ ...pix })),
      pixelId: '',
      edit: false,
      calling: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { account } = this.props;

    if (prevProps.account !== this.props.account || prevState.edit !== this.state.edit) {
      this.setState({ pixels: account.pixels.map((pix) => ({ ...pix })) });
    }
  }
  render() {
    const { account, providers } = this.props;
    const { edit } = this.state;
    const { pixels } = account;
    const provMap = {};

    const statePixMap = {};

    providers.forEach((prov) => (provMap[prov.id] = prov.value));

    if (edit) {
      this.state.pixels.forEach((pix) => (statePixMap[pix.id] = pix));
    }

    return (
      <Box className="settings-pixels__list" header={this.renderHeader()}>
        <div className="settings-pixels__list__headers settings-pixels__list__line">
          <div className="settings-pixels__list--ad">Ad platform</div>
          <div className="settings-pixels__list--name">Name</div>
          <div className="settings-pixels__list--pix">Pixel</div>
          <div className="settings-pixels__list--fbat">API Access Token</div>
          <div className="settings-pixels__list--del">Delete</div>
        </div>
        {pixels.map((pix, index) => {
          let classes = 'settings-pixels__list__line';

          if (index % 2 === 1) {
            classes = `${classes} settings-pixels__list__line--odd`;
          }

          return (
            <div key={pix.id} className={classes}>
              <div className="settings-pixels__list--ad">
                <Pixel provider={pix.providerKey} />
                {provMap[pix.providerKey] || 'Unknown'}
              </div>
              {!edit && <div className="settings-pixels__list--name">{pix.name}</div>}
              {edit && (
                <div className="settings-pixels__list--name">
                  <Input
                    onChange={this.handleChange({
                      id: pix.id,
                      key: 'name',
                    })}
                    id={`${pix.id}_name`}
                    value={(statePixMap[pix.id] && statePixMap[pix.id].name) || ''}
                  />
                </div>
              )}
              {!edit && <div className="settings-pixels__list--pix">{pix.key}</div>}
              {edit && (
                <div className="settings-pixels__list--pix">
                  <Input
                    onChange={this.handleChange({
                      id: pix.id,
                      key: 'key',
                    })}
                    id={`${pix.id}_key`}
                    value={(statePixMap[pix.id] && statePixMap[pix.id].key) || ''}
                  />
                </div>
              )}
              {!edit && (
                <div className="settings-pixels__list--fbat">
                  {pix.providerKey === 'fb' || pix.providerKey === 'tkt' ? (
                    pix.hasAccessToken ? (
                      <Tooltip title="Access Token is provided and has been validated">
                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="No Access Token Provided">
                        <WarningTwoTone twoToneColor="#ff0000" />
                      </Tooltip>
                    )
                  ) : (
                    <Tooltip title="Not Supported">
                      <StopTwoTone twoToneColor="#ff0000" />
                    </Tooltip>
                  )}
                </div>
              )}
              {edit && (
                <div className="settings-pixels__list--fbat">
                  <AntInput.Password
                    onChange={this.handleChange({
                      id: pix.id,
                      key: 'accessToken',
                    })}
                    id={`${pix.id}_accessToken`}
                    value={(statePixMap[pix.id] && statePixMap[pix.id].accessToken) || ''}
                    visibilityToggle={false}
                    allowClear
                    style={{ height: '24px', fontSize: '12px' }}
                    placeholder="Access Token"
                  />
                </div>
              )}
              <div className="settings-pixels__list--del">
                <DeleteItem
                  disabled={edit}
                  handleClick={() => {
                    this.setState({
                      displayModal: true,
                      pixelId: pix.id,
                    });
                  }}
                />
              </div>
            </div>
          );
        })}
        {this.displayModal()}
      </Box>
    );
  }

  handleChange =
    ({ id, key }) =>
    (event: SyntheticEvent<HTMLInputElement>) => {
      const target = (event.currentTarget: HTMLInputElement);
      const { value } = target;
      const newPixels: Array<pixelT> = [];

      this.state.pixels.forEach((pix) => {
        if (pix.id === id) {
          pix[key] = value;
        }

        newPixels.push(pix);
      });
      this.setState({
        pixels: newPixels,
      });
    };

  displayModal = () => {
    const { displayModal, calling, edit } = this.state;

    if (displayModal) {
      if (edit) {
        const message = calling ? 'Updating...' : "Yes let's go";

        return (
          <Modal
            disabled={calling}
            okMessage={message}
            handleCancel={() => this.setState({ displayModal: false })}
            handleOk={() => this.handleUpdate()}
          >
            Are you sure you want to update these pixels?
          </Modal>
        );
      } else {
        const message = calling ? 'Deleting...' : "Yes I'm sure";

        return (
          <Modal
            disabled={calling}
            okMessage={message}
            handleCancel={() => this.setState({ displayModal: false })}
            handleOk={() => this.handleDelete()}
          >
            Are you sure you want to delete this pixel?
          </Modal>
        );
      }
    }

    return null;
  };

  handleDelete = () => {
    this.setState({ calling: true });
    const { account, usage, updateAccountsUsage, updateAccountsUsageThunk } = this.props;
    const pixelCount = account.pixels.length;

    deletePixel(localStorage, axios, { accountId: account.id }, { pixelId: this.state.pixelId }).then(({ account }) => {
      if (account.pixels.length !== pixelCount) {
        updateAccountsUsage({
          usage: { ...usage, pixels: usage.pixels - 1 },
        });
        updateAccountsUsageThunk();
      }

      this.setState({
        displayModal: false,
        pixelId: '',
        calling: false,
      });

      return this.props.updateAccount(account);
    });
  };

  handleUpdate = () => {
    this.setState({ calling: true });
    const { account } = this.props;

    updatePixels(localStorage, axios, { accountId: account.id }, { pixels: this.state.pixels })
      .then(({ account }) => {
        this.setState({
          displayModal: false,
          calling: false,
          edit: false,
        });

        return this.props.updateAccount(account);
      })
      .catch((error) => {
        this.setState({
          displayModal: false,
          calling: false,
          edit: false,
        });
      });
  };

  renderHeader = () => {
    const { edit } = this.state;

    return (
      <div className="settings-pixels__list__title">
        <div>My pixels</div>
        <div>
          {!edit && <LightButton handleClick={() => this.setState({ edit: true })}>Edit</LightButton>}
          {edit && <CancelButton handleClick={() => this.setState({ edit: false })}>Cancel</CancelButton>}
          {edit && <Button handleClick={() => this.setState({ displayModal: true })}>Save changes</Button>}
        </div>
      </div>
    );
  };
}

class CustomForm extends Component<
  {
    account: accountT,
    limitations: { allowCustomScript: boolean },
    subscription: any,
  },
  { customBody: string, customHeader: string, calling: boolean },
> {
  constructor(props: { account: accountT, limitations: { allowCustomScript: boolean }, subscription: any }) {
    super(props);
    const { customBody, customHeader } = props.account;

    this.state = {
      customBody,
      customHeader,
      calling: false,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.account !== this.props.account) {
      const { customBody, customHeader } = this.props.account;

      this.setState({ customHeader, customBody });
    }
  }
  render() {
    const { customBody, customHeader } = this.state;
    const { limitations } = this.props;

    return (
      <Box className="settings-pixels__custom" header="Custom pixel provider">
        <form id="custom-pixels" onSubmit={this.handleSubmit}>
          <Textarea
            onChange={this.handleChange}
            id="customHeader"
            value={customHeader}
            placeholder="Past your html compatible code"
            label="This code will be inserted in page header"
            disabled={!limitations.allowCustomScript}
          />
          <Textarea
            onChange={this.handleChange}
            id="customBody"
            value={customBody}
            placeholder="Past your html compatible code"
            label="This code will be inserted in page body"
            disabled={!limitations.allowCustomScript}
          >
            {customBody}
          </Textarea>
          {this.renderButton()}
        </form>
      </Box>
    );
  }

  validateFields = () => {
    const { customBody, customHeader } = this.props.account;

    return this.state.customBody !== customBody || this.state.customHeader !== customHeader;
  };
  renderButton = () => {
    const { calling } = this.state;
    const { limitations, subscription } = this.props;

    if (!subscription.sub) {
      return null;
    }

    if (!limitations.allowCustomScript) {
      return (
        <CustomScriptCTATooltip>
          <LockedButton>Update custom pixels</LockedButton>
        </CustomScriptCTATooltip>
      );
    }

    if (calling) {
      return <Button disabled>Updating...</Button>;
    }

    if (this.validateFields()) {
      return <Button>Update custom pixels</Button>;
    }

    return <Button disabled>Update custom pixels</Button>;
  };

  handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = (event.currentTarget: HTMLInputElement);
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (this.validateFields()) {
      this.setState({ calling: true });
      const { account } = this.props;
      const { customHeader, customBody } = this.state;

      updateCustomPixels(localStorage, axios, { accountId: account.id }, { customHeader, customBody }).then(
        ({ account }) => {
          this.setState({
            calling: false,
          });

          return this.props.updateAccount(account);
        },
      );
    }
  };
}

const mapStateToProps = function (state) {
  const { limitations, usage } = state.accounts;

  return {
    account: selectCurrentAccount(state),
    limitations,
    usage,
    subscription: state.subscription,
  };
};

export default connect(mapStateToProps, {
  updateAccount,
  updateAccountsUsage,
  updateAccountsUsageThunk,
})(Pixels);
