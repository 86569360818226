import React, { Fragment, useEffect, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Empty, Modal, Select } from 'antd';
import styled from 'styled-components';

import axios from 'lib/axios.factory';

import { getAdsProviderProfiles } from '../../../../lib/adsPlateformApi';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

const { confirm } = Modal;
const Style = styled.div``;

export function Checkurl(text) {
  const url1 = /(^|&lt;|\s)(www\..+?\..+?)(\s|&gt;|$)/g;

  const url2 = /(^|&lt;|\s)(((https?|ftp):\/\/|mailto:).+?)(\s|&gt;|$)/g;

  let html = text;

  if (html) {
    html = html
      .replace(
        url1,
        '$1<br/><a style="color:blue; text-decoration:underline;" target="_blank"  href="http://$2">$2</a><br/>$3',
      )
      .replace(
        url2,
        '$1<br/><a style="color:blue; text-decoration:underline;" target="_blank"  href="$2">$2</a><br/>$5',
      );
  }

  return html.replaceAll(/\.\s/g, '.<br/><br/>');
}

export function GoogleCustomers({
  onChange,
  accountId,
  adsProvider,
}: {
  onChange?: Function,
  accountId: string,
  adsProvider: adsProviderT,
}) {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);

  async function load() {
    setLoading(true);

    try {
      const data = await getAdsProviderProfiles(localStorage, axios, { accountId }, { adsProvider });

      setProfiles(data.adsProviderProfiles);
    } catch (error) {
      if (error.response.status === 403) {
        confirm({
          okText: 'Retry login',
          cancelButtonProps: { disabled: true },
          title: "We can't retrieve your campaign",
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              Please be sure to authorize PixelMe
              <br />
              <span
                dangerouslySetInnerHTML={{
                  __html: Checkurl(error.errorMessage),
                }}
              />
            </div>
          ),
          async onOk() {
            await load();
          },
        });
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    load().then();
  }, []);

  async function onCustomerChange(customerId) {
    const customer = profiles.find((c) => c.id === customerId);

    if (onChange) {
      await onChange(customer);
    }
  }

  if (loading) {
    return <SmallCompactPlaceholder text="We are importing your Google accounts, please wait a few seconds" />;
  }

  return (
    <Style>
      <Fragment>
        {profiles?.length ? (
          <div>
            <Select style={{ width: '400px' }} onChange={onCustomerChange} virtual>
              {profiles.map((c, index) => (
                <Select.Option disabled={c.id === 0 || c.id === '0'} key={index} id={c.id} value={c.id}>
                  {c.id} - {c.descriptiveName || 'No Descriptive Name'}
                </Select.Option>
              ))}
            </Select>
          </div>
        ) : (
          <Fragment>
            <Empty />
            <br />
          </Fragment>
        )}
      </Fragment>
    </Style>
  );
}
