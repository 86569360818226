import './Combo.css';
import React from 'react';
import type { Node } from 'react';

type props = {
  selected: boolean,
  handleClick: () => void,
  children: Node,
  className?: string,
  disabled?: boolean,
};

function Combo({ selected = true, className = '', disabled, children, handleClick }: props) {
  return (
    <div onClick={handleClick} className={`${className} combo ${disabled ? 'combo-disabled' : ''}`}>
      <ComboBox selected={selected} />
      {children}
    </div>
  );
}

function ComboBox({ selected }: { selected?: boolean }) {
  if (selected) {
    return (
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Rectangle 13.2">
          <rect width="15" height="15" rx="7.5" fill="#2E89E2" />
        </g>
        <ellipse id="Ellipse" cx="2.5" cy="2.5" rx="2.5" ry="2.5" transform="translate(5 5)" fill="white" />
      </svg>
    );
  }

  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Rectangle 13.1">
        <g filter="url(#filter0_i)">
          <rect width="15" height="15" rx="7.5" fill="white" />
        </g>
        <rect x="0.5" y="0.5" width="14" height="14" rx="7" stroke="#BDBDBD" />
      </g>
      <defs>
        <filter
          id="filter0_i"
          x="0"
          y="0"
          width="15"
          height="15"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 255 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
      </defs>
    </svg>
  );
}
export default Combo;
