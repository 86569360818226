import React, { Fragment, useEffect, useState } from 'react';

import { notification, Transfer, Tree } from 'antd';
import Search from 'antd/lib/input/Search';
import { TransferDirection } from 'antd/lib/transfer';
import deepmerge from 'deepmerge';
import { debounce } from 'lodash';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import { ChangeEventExtra } from 'rc-tree-select/lib/interface';
import type { ValueType } from 'react-select/src/types';
import styled from 'styled-components';

import axios from 'lib/axios.factory';
import { browseFacebookTargeting, searchFacebookTargeting } from 'lib/facebookPlateformApi';

import { buildTreeDataFromFacebookBrowseAPIResult, deepFilterOnTree } from './buildTree';
import { FormInputGroup } from '../../../common/antd/FormInputGroup';
import { SmallCompactPlaceholder } from '../../../common/Placeholders';

const Style = styled.div`
  .ant-tree-indent-unit {
  }
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button:not(.ant-btn-primary) {
    height: 29px;
  }
  .ant-transfer-list-header {
    display: none;
  }
`;

/**
 * Facebook value selector
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function FacebookTargetingSelector(props: {
  account: any,
  value: any,
  onChange: any,
  adsProvider: adsProviderT,
}) {
  const [allTargetings, setAllTargetings] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(props.value || []);
  const [keysValues, setKeysValues] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const isChecked = (selectedKeys, eventKey) => selectedKeys.indexOf(eventKey) !== -1;

  async function onChange(targetKeys: string[], direction: TransferDirection, moveKeys: string[]) {
    setSelectedKeys(targetKeys);
    props.onChange(targetKeys);
  }

  const debouncedOnChange = debounce(onChange, 100);

  async function load(searchValue) {
    setLoading(true);
    const response = searchValue
      ? await searchFacebookTargeting(localStorage, axios, props.adsProvider, searchValue)
      : await browseFacebookTargeting(localStorage, axios, props.adsProvider, '');
    const { tree, values } = buildTreeDataFromFacebookBrowseAPIResult(response);

    setKeysValues([...keysValues, ...values]);
    setTreeData(tree);
    const allTargetingsU = deepmerge(tree, allTargetings);

    setAllTargetings(allTargetingsU);
    setExpandedKeys(searchValue ? values.map((v) => v.key) : []);
    const selectedKeysU = (selectedKeys || []).filter((k) => !!deepFilterOnTree(allTargetingsU, 'key', k));

    if (selectedKeysU.length !== (selectedKeys || []).length) {
      setSelectedKeys(selectedKeysU);
      props.onChange(selectedKeysU);
      notification.info({
        message: `Old targeting removed, please reselect it!`,
      });
    }

    setLoading(false);
  }

  const debouncedLoad = debounce(load, 500);

  useEffect(() => {
    load().then();
  }, []);

  return (
    <Style>
      {keysValues && (
        <FormInputGroup>
          <Transfer
            style={{ width: '100%' }}
            listStyle={{ minWidth: '250px' }}
            showSelectAll={false}
            className="tree-transfer"
            targetKeys={selectedKeys}
            onChange={debouncedOnChange}
            render={(item) => item.title || item.key}
            dataSource={keysValues}
          >
            {({ direction, onItemSelect, selectedKeys }) => {
              const checkedKeys = [...selectedKeys];

              if (direction === 'left') {
                return (
                  <Fragment>
                    <Search
                      style={{ marginBottom: 8 }}
                      placeholder="Add demographics, interests or behaviors"
                      onSearch={debouncedLoad}
                      onChange={(e) => {
                        const { value } = e.target;

                        debouncedLoad(value);
                      }}
                    />
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                      {loading && <SmallCompactPlaceholder />}
                      {!loading && (
                        <Tree
                          checkable
                          loading={loading}
                          allowClear={true}
                          showAction="click"
                          onExpand={(
                            expandedKeys: Key[],
                            info: {
                              node: EventDataNode,
                              expanded: boolean,
                              nativeEvent: MouseEvent,
                            },
                          ) => {
                            setExpandedKeys(expandedKeys);
                          }}
                          onCheck={(_, { node: { key } }) => {
                            onItemSelect(key, !isChecked(checkedKeys, key));
                          }}
                          onSelect={(_, { node: { key } }) => {
                            onItemSelect(key, !isChecked(checkedKeys, key));
                          }}
                          onChange={(value: ValueType, labelList: React.ReactNode[], extra: ChangeEventExtra) => {
                            const s = [];

                            for (const v of value) {
                              const vv = deepFilterOnTree(allTargetings, 'value', v);

                              if (vv) {
                                s.push(vv.title);
                              } else {
                                console.log('Unable to find:', value);
                              }
                            }
                            onItemSelect(s);
                          }}
                          defaultExpandedKeys={expandedKeys}
                          expandedKeys={expandedKeys}
                          style={{ width: '100%' }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                          treeData={treeData}
                          defaultExpandAll={false}
                        />
                      )}
                    </div>
                  </Fragment>
                );
              }
            }}
          </Transfer>
        </FormInputGroup>
      )}
    </Style>
  );
}
