import React from 'react';

export function NAValue() {
  return (
    <div
      style={{
        color: '#828282',
        textAlign: 'right',
        justifyContent: 'flex-end',
      }}
    >
      n/a
    </div>
  );
}
