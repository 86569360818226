import React, { useEffect, useState, Fragment } from 'react';

import { Table, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';

import { SEARCH_TERMS_DATA } from './mockdataRMF';

export function SearchTermsModal(props: { visible: boolean, onFinish: Function }): React$Element<any> {
  const Style = styled.div`
    overflow: auto;
    max-width: 100%;
    background-color: white;

    .ant-table-pagination.ant-pagination {
      justify-content: left;
      margin-left: 1em;
    }

    .ant-table-thead > tr > th {
      text-align: center;
    }

    .ant-table-column-title {
      font-size: 13px;
    }
  `;
  const columns = [
    {
      title: 'Search Term',
      dataIndex: 'searchTerm',
      key: 'searchTerm',
      width: 250,
    },
    {
      title: 'Match Type',
      dataIndex: 'matchType',
      key: 'matchType',
      width: 100,
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      width: 120,
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      key: 'clicks',
      width: 90,
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      width: 90,
    },
    {
      title: 'Conversions',
      dataIndex: 'conversions',
      key: 'conversions',
      width: 100,
      render: () => <span>0</span>,
    },
  ];

  return (
    <Modal
      visible={props.visible}
      title="View Search Terms for Campaign"
      width="80vw"
      height="100vh"
      onCancel={props.onFinish}
      onOk={props.onFinish}
      footer={
        <Button onClick={props.onFinish} type="primary">
          Close
        </Button>
      }
    >
      <Style>
        <Table
          style={{ cursor: 'pointer' }}
          rowKey="id"
          tableLayout="fixed"
          dataSource={SEARCH_TERMS_DATA}
          pagination={{
            defaultPageSize: 10,
          }}
          columns={columns}
        />
      </Style>
    </Modal>
  );
}
