import React from 'react';

import { InfoCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

export const IconIContainer = styled.span`
  font-size: 8px;
  margin-left: 5px;
  margin-top: -5px;
`;

export function AffixedInfoCircleFilled() {
  return (
    <IconIContainer>
      <InfoCircleFilled color="rgb(248, 165, 27)" style={{ color: 'rgb(248, 165, 27)' }} />
    </IconIContainer>
  );
}
