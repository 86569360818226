import React, { useState } from 'react';

import { Select } from 'antd';
import _ from 'lodash';

import { useLoading } from 'hooks/useLoading';
import axios from 'lib/axios.factory';
import { getTiktokTools } from 'lib/tiktokPlateformApi';

/**
 * Tiktok Interest selector
 * https://ads.tiktok.com/marketing_api/docs?id=1707864878515202
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function InterestKeywordsSelector(props: {
  account: accountT,
  adsProvider: adsProviderT,
  value: any,
  initialTranslate: value,
  onChangeTranslate: any,
  onChange: any,
  defaultValue: any,
}) {
  const [data, setData] = useState(props.initialTranslate || []);
  const [allData, setAllData] = useState(props.initialTranslate || []);
  const { doAction, loading } = useLoading(false);

  async function search(keyword) {
    const keywords = `["${keyword}"]`;
    const genericResponse = await getTiktokTools(
      localStorage,
      axios,
      props.adsProvider,
      { accountId: props.account.id, keywords },
      'interest_keyword|recommend/',
    );
    const nonCompleteKeywords = genericResponse.recommendKeywords;
    const completeResponse = await getTiktokTools(
      localStorage,
      axios,
      props.adsProvider,
      {
        accountId: props.account.id,
        keywordQuery: `[${nonCompleteKeywords.map((k) => `{"id":${k.id}}`).join(',')}]`,
      },
      'interest_keyword|get/',
    );
    const d = completeResponse.keywords
      .filter((k) => k.keywordStatus === 'EFFECTIVE')
      .map((l) => ({ value: l.id, label: l.keyword }));

    if (props.onChangeTranslate) {
      props.onChangeTranslate([...allData, ...d]);
      setAllData([...allData, ...d]);
    }

    setData([...d]);
  }

  const debouncedSearch = _.debounce((e) => doAction(async () => await search(e)), 500);

  return (
    <Select
      mode="multiple"
      loading={loading}
      onSearch={debouncedSearch}
      filterOption={false}
      onInputKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();

          return false;
        }
      }}
      size="large"
      value={props.value}
      defaultValue={props.defaultValue}
      onChange={props.onChange}
      placeholder="Select additional interests"
      allowClear
      options={data}
    />
  );
}
