import React, { useEffect } from 'react';

import { Card, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Input from 'antd/lib/input';
import { navigate } from 'hookrouter';

import axios from 'lib/axios.factory';

import { LabelledCopyPasteRedirect } from './components/LabelledCopyPasteRedirect';
import { LinkPreviewPreviewer } from './components/LinkPreviewer';
import { LinkTTL } from './components/LinkTTL';
import { SkipNextButton } from './components/SkipNextButton';
import { updateRedirect } from '../../../lib/api';
import { ImgUpload } from '../PageBuilder/imgUpload';

// @ts-ignore

export function LinkPreviewForm({ account, state, updateState }) {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(state.redirect);
  }, [JSON.stringify(state.redirect)]);

  async function onChange(changes) {
    const redirect = {
      ...state.redirect,
      ...changes,
      preview: { ...state.redirect.preview, ...changes.preview },
    };

    updateState((draft) => {
      draft.redirect = redirect;
    });
  }

  async function saveLink() {
    await updateRedirect(
      localStorage,
      axios,
      {
        accountId: account.id,
      },
      {
        redirectId: state.redirect.id,
        ...state.redirect,
      },
    );

    navigate(`/easy/link-pixel/${state.redirect.id}`);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={state.redirect}
      defaultValue={state.redirect}
      onValuesChange={onChange}
      onFinish={saveLink}
    >
      <Card className="card" title="Edit your link preview" extra={<LinkTTL redirect={state.redirect} />}>
        <div style={{ display: 'flex', alignItems: 'baseline', gap: '1em' }}>
          <div style={{ minWidth: '377px' }}>
            <Form.Item name="preview">
              <Form.Item name={['preview', 'picture']} label="Image">
                <ImgUpload account={account} previewSize="377px" />
              </Form.Item>
              <Form.Item name={['preview', 'title']} label="Title">
                <Input></Input>
              </Form.Item>
              <Form.Item name={['preview', 'description']} label="Description">
                <Input.TextArea rows={20} style={{ height: '200px' }}></Input.TextArea>
              </Form.Item>
            </Form.Item>
          </div>
          <div>
            <Form.Item label="Your short link" style={{ width: 'fit-content' }}>
              <LabelledCopyPasteRedirect redirect={state.redirect} />
            </Form.Item>
            <LinkPreviewPreviewer style={{ marginTop: '34px' }} redirect={state.redirect}></LinkPreviewPreviewer>
          </div>
        </div>
      </Card>
      <SkipNextButton account={account} step={3} />
    </Form>
  );
}
