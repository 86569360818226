import React from 'react';

import { DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Actions } from 'actions';
import { Plus } from 'components/common/icons/Plus';
import { s } from 'lib/safe';

import CampaignMenu from './CampaignMenu';
import RedirectsListLine from '../campaigns/RedirectsListLine';

export const DropIndicator = styled(Plus).withConfig({
  displayName: 'DropIndicator',
})`
  width: 13px;
  margin-right: 5px;
  margin-top: 2px;
`;

const campaignRedirectsTarget = {
  drop(props, monitor, component) {
    const { moveRedirects, campaign, subCampaign } = props;
    const { redirects } = monitor.getItem();

    moveRedirects(s(campaign).id, s(subCampaign).id, redirects);
  },
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
}

const dragAndDropWrapper = DropTarget(RedirectsListLine.TYPE, campaignRedirectsTarget, collect);

// noinspection JSUnusedLocalSymbols
const stateToProps = (state: stateT) => ({
  campaigns: state.campaigns.campaigns,
});

// noinspection JSUnusedGlobalSymbols
const dispatchToProps = (dispatch: Dispatch<*>, ownProps: Object) => ({
  moveRedirects: (campaignId, subCampaignId, redirects) =>
    dispatch(
      Actions.api.audienceBuilder.campaigns.addRedirects.request({
        campaignId,
        subCampaignId,
        redirects,
      }),
    ),
});

// noinspection JSUnusedLocalSymbols
const areStatesEqual = (prev: stateT, next: stateT) => false;

const reduxWrapper = connect(stateToProps, dispatchToProps, null, {
  areStatesEqual,
});

export default reduxWrapper(
  dragAndDropWrapper(styled(({ connectDropTarget, renderChildren, className, isOver, canDrop }) =>
    connectDropTarget(
      <span style={{ width: '100%' }} className={`${isOver && canDrop ? 'dropping' : ''} ${className}`}>
        {renderChildren(isOver, canDrop)}
      </span>,
    ),
  ).withConfig({ displayName: 'CampaignRedirectsDragDropTarget' })`
    display: contents;
    div {
      font-weight: ${(props) => (props.isOver && props.canDrop ? 'bold' : 'auto')};
    }
    *:hover .${CampaignMenu.styledComponentId} {
      width: 70px;
      opacity: 1;
    }
  `),
);
