import React, { Fragment } from 'react';

import { Steps, Card } from 'antd';
import { useHistory } from 'react-router-dom';

import { getAmazonProfiles, hasActiveAmazonProfile } from '../../../../lib/amazon';
import { BoxShadow } from '../../../BoxShadow';
import { FullAmazon } from '../../../common/icons/Amazon';
import { ImageOnRightSide } from '../../../common/ImageOnRightSide';
import { MutedText } from '../../../common/MutedText';
import SignLayout from '../../../layout/Sign/SignLayout';
import { Signup } from '../../easy/images/Signup';
import { NewAdsProviderButton } from '../common/NewAdsProviderButton';

export function AdsProviderOnBoardingPage(props: { account: accountT }) {
  const history = useHistory();

  let currentStep = 0;

  if (hasActiveAmazonProfile(getAmazonProfiles(props.account))) {
    currentStep = 1;
  }

  return (
    <SignLayout
      left={{ width: '700px' }}
      title={<>Create, launch & track ads for your Amazon products</>}
      header={<></>}
      description={
        <>
          Connect your Amazon attribution account & your ad accounts to launch ads from PixelMe and <br />
          track all the results of your ads at the same place.{' '}
        </>
      }
      footer={
        <span style={{ float: 'left' }}>
          <p>
            <b>No Amazon attribution account yet?</b>{' '}
            <a href="https://sellercentral.amazon.com/attribution">Please request an access here.</a>
          </p>
          <br />
          <p>
            Note: Amazon Attribution is only available for Amazon 3P Sellers <br />
            and Amazon 1P vendors.{' '}
            <a
              style={{ textDecoration: 'none !important' }}
              href="https://www.amazon.com/forms/3635f20b-f743-4f6a-99a1-4150eb5b4d00?ref_=a20m_us_p_attr_gs_sgnup"
            >
              Learn more
            </a>
            .{' '}
          </p>
        </span>
      }
      image={<SVG />}
    >
      <Steps direction="vertical" current={currentStep}>
        <Steps.Step
          key={1}
          title="Step 1: Track results of your campaign with Amazon Attribution"
          description={
            <BoxShadow
              style={{
                maxWidth: '680px',
                cursor: 'pointer',
                opacity: currentStep === 1 ? 0.5 : 1,
              }}
              onClick={() => {
                setTimeout(() => {
                  history.push(`/${props.account.id}/amazon`);
                }, 500);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '3em',
                  alignItems: 'center',
                }}
              >
                <Card bordered={false} title="Connect your Amazon Attribution account">
                  <div style={{ flex: '1' }}>
                    Track ROI & ROAS of your advertising campaign by <br />
                    connecting your Amazon Attribution account to PixelMe
                  </div>
                </Card>
              </div>
            </BoxShadow>
          }
        />
        <Steps.Step
          key={2}
          title="Step 2: Launch ads from PixelMe"
          description={
            <div>
              <NewAdsProviderButton account={props.account} />
            </div>
          }
        />
      </Steps>
    </SignLayout>
  );
}

function SVG() {
  return (
    <svg
      style={{ margin: 'auto' }}
      width="345"
      height="279"
      viewBox="0 0 345 279"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.7866 0L31.9738 44.4191H36.579V80.6411H13.3705L0 106.674L165.67 191.467L220.457 84.7924L54.7866 0Z"
        fill="#F2F2F2"
      />
      <path d="M303.964 28.895H49.97V156.104H303.964V28.895Z" fill="#3F3D56" />
      <path opacity="0.3" d="M273.078 74.604H85.6068V75.0352H273.078V74.604Z" fill="#F8A51B" />
      <path opacity="0.3" d="M273.294 85.3843H85.8228V85.8155H273.294V85.3843Z" fill="#F8A51B" />
      <path opacity="0.3" d="M273.294 106.945H85.8228V107.376H273.294V106.945Z" fill="#F8A51B" />
      <path opacity="0.3" d="M273.294 116.432H85.8228V116.863H273.294V116.432Z" fill="#F8A51B" />
      <path opacity="0.3" d="M273.294 96.1648H85.8228V96.596H273.294V96.1648Z" fill="#F8A51B" />
      <path
        d="M244.785 279C289.158 279 325.13 275.718 325.13 271.669C325.13 267.621 289.158 264.339 244.785 264.339C200.411 264.339 164.44 267.621 164.44 271.669C164.44 275.718 200.411 279 244.785 279Z"
        fill="#3F3D56"
      />
      <path
        d="M91.0238 116.744L90.4277 116.12L118.333 89.5685L142.931 107.232L162.824 85.2158L171.546 92.6172L195.274 78.4052L221.629 96.0889L262.994 72.6011L263.421 73.3505L221.591 97.1027L195.248 79.4268L171.459 93.675L162.903 86.415L143.055 108.383L118.41 90.6867L91.0238 116.744Z"
        fill="#F8A51B"
      />
      <path
        d="M171.384 256.938C173.414 264.43 180.368 269.07 180.368 269.07C180.368 269.07 184.03 261.563 182 254.071C179.97 246.579 173.016 241.939 173.016 241.939C173.016 241.939 169.354 249.446 171.384 256.938Z"
        fill="#3F3D56"
      />
      <path
        d="M174.367 255.328C179.938 260.742 180.618 269.064 180.618 269.064C180.618 269.064 172.267 268.609 166.696 263.195C161.125 257.781 160.444 249.459 160.444 249.459C160.444 249.459 168.796 249.914 174.367 255.328Z"
        fill="#F8A51B"
      />
      <path
        opacity="0.4"
        d="M330.529 37.5194C338.521 37.5194 345 31.0518 345 23.0736C345 15.0955 338.521 8.62793 330.529 8.62793C322.537 8.62793 316.059 15.0955 316.059 23.0736C316.059 31.0518 322.537 37.5194 330.529 37.5194Z"
        fill="#F8A51B"
      />
      <path
        opacity="0.4"
        d="M303.748 40.1067C309.354 40.1067 313.899 35.5698 313.899 29.9732C313.899 24.3766 309.354 19.8396 303.748 19.8396C298.141 19.8396 293.597 24.3766 293.597 29.9732C293.597 35.5698 298.141 40.1067 303.748 40.1067Z"
        fill="#F8A51B"
      />
      <path
        opacity="0.4"
        d="M291.221 221.217C294.203 221.217 296.62 218.804 296.62 215.827C296.62 212.85 294.203 210.437 291.221 210.437C288.239 210.437 285.821 212.85 285.821 215.827C285.821 218.804 288.239 221.217 291.221 221.217Z"
        fill="#F8A51B"
      />
      <path
        opacity="0.4"
        d="M199.213 247.952C201.479 247.952 203.316 246.118 203.316 243.856C203.316 241.593 201.479 239.759 199.213 239.759C196.946 239.759 195.109 241.593 195.109 243.856C195.109 246.118 196.946 247.952 199.213 247.952Z"
        fill="#F8A51B"
      />
      <path
        opacity="0.4"
        d="M185.39 194.913C188.372 194.913 190.79 192.5 190.79 189.523C190.79 186.546 188.372 184.133 185.39 184.133C182.408 184.133 179.991 186.546 179.991 189.523C179.991 192.5 182.408 194.913 185.39 194.913Z"
        fill="#F8A51B"
      />
      <path
        d="M262.711 80.4255C265.813 80.4255 268.327 77.9157 268.327 74.8197C268.327 71.7237 265.813 69.2139 262.711 69.2139C259.61 69.2139 257.096 71.7237 257.096 74.8197C257.096 77.9157 259.61 80.4255 262.711 80.4255Z"
        fill="#F8A51B"
      />
      <path
        d="M226.224 176.356L221.464 176.884C221.464 176.884 219.348 186.389 221.464 199.061C223.58 211.734 226.224 224.406 226.224 224.406C226.224 224.406 228.34 265.592 229.398 266.12C230.456 266.648 239.977 269.816 240.506 266.648C241.035 263.48 241.035 191.141 241.035 191.141L248.44 225.99C248.44 225.99 249.498 269.816 251.613 269.816C253.729 269.816 260.605 269.288 260.605 265.592C260.605 261.896 262.192 213.318 262.192 213.318C262.192 213.318 261.663 177.412 259.548 176.356C257.432 175.3 226.224 176.356 226.224 176.356Z"
        fill="#2F2E41"
      />
      <path
        d="M230.456 264.536C230.456 264.536 225.696 264.008 224.638 264.536C223.58 265.064 221.993 268.76 224.638 270.872C227.282 272.984 234.159 276.152 236.274 276.152C237.741 276.087 239.181 275.728 240.506 275.096L239.448 266.12L230.456 264.536Z"
        fill="#2F2E41"
      />
      <path
        d="M251.614 267.176L252.142 274.568C252.142 274.568 259.019 275.624 259.548 274.568C260.077 273.512 265.366 270.872 265.895 270.344C266.424 269.816 266.953 264.008 263.779 263.48C260.606 262.952 259.548 264.536 259.548 264.536L251.614 267.176Z"
        fill="#2F2E41"
      />
      <path
        d="M207.183 132.002C207.183 132.002 194.488 144.146 196.075 150.483C197.662 156.819 210.356 175.3 210.356 175.3C210.356 175.3 222.522 181.636 224.109 175.3C225.695 168.964 215.646 170.548 215.646 170.548L207.712 151.539L216.704 138.866L207.183 132.002Z"
        fill="#FFB8B8"
      />
      <path
        d="M263.905 113.526C263.905 113.526 275.469 106.102 275.202 101.002C274.934 95.9016 267.963 79.8205 267.963 79.8205C267.963 79.8205 262.509 68.5014 260.341 73.1273C258.173 77.7532 263.191 82.6773 263.191 82.6773L266.429 98.4522L257.627 106.834L263.905 113.526Z"
        fill="#FFB8B8"
      />
      <path
        d="M235.216 102.961C240.475 102.961 244.737 98.7055 244.737 93.4563C244.737 88.2072 240.475 83.9519 235.216 83.9519C229.958 83.9519 225.696 88.2072 225.696 93.4563C225.696 98.7055 229.958 102.961 235.216 102.961Z"
        fill="#FFB8B8"
      />
      <path
        d="M230.456 99.2646L232.043 109.297L243.679 107.185C243.679 107.185 243.15 97.1525 243.15 96.0965C243.15 95.0404 230.456 99.2646 230.456 99.2646Z"
        fill="#FFB8B8"
      />
      <path
        d="M229.927 109.297C229.927 109.297 243.151 106.129 244.737 107.185C246.324 108.241 254.258 109.297 254.258 109.297L263.25 123.026L260.605 140.45C260.605 140.45 260.077 168.436 261.134 173.188C262.192 177.94 268.011 178.468 255.845 179.524C243.679 180.58 241.564 178.996 233.101 181.108C224.638 183.22 217.762 180.052 219.877 177.94C221.993 175.828 225.696 142.035 221.464 138.866C217.233 135.698 215.646 119.33 215.646 119.33C215.646 119.33 229.927 111.937 229.927 109.297Z"
        fill="#D0CDE1"
      />
      <path
        d="M252.671 110.881L254.258 109.297L257.432 106.129C257.432 106.129 267.746 110.617 267.217 112.201C266.688 113.785 263.25 123.026 263.25 123.026L258.49 124.61L252.671 110.881Z"
        fill="#D0CDE1"
      />
      <path
        d="M221.993 120.385L215.646 119.329C215.646 119.329 210.885 124.61 209.298 126.722C207.712 128.834 204.538 134.114 204.538 134.114L215.646 142.034L224.109 133.058L221.993 120.385Z"
        fill="#D0CDE1"
      />
      <path
        d="M238.539 81.9416L238.442 81.1802C237.794 81.1765 237.151 81.2775 236.536 81.4791C236.473 81.02 236.513 80.5526 236.654 80.1109C236.029 80.2713 235.434 80.5296 234.89 80.8763L234.505 79.4868L229.218 82.0883C227.32 83.0221 225.318 84.0483 224.233 85.8618C223.024 87.8837 223.234 90.4821 224.061 92.6878C224.888 94.8934 226.262 96.8453 227.364 98.9279C227.782 99.8234 228.284 100.677 228.864 101.478C230.86 103.962 234.745 104.463 237.559 102.964C238.63 102.449 239.484 101.572 239.969 100.489C240.174 99.8171 240.335 99.1324 240.45 98.4394C240.798 97.0201 241.202 94.0902 242.357 93.1923C242.865 92.7972 243.873 94.107 244.294 93.6204C245.376 92.3701 245.149 90.4417 244.477 88.9316C243.832 87.4149 243.078 85.9467 242.221 84.5385C241.858 83.9724 238.527 81.8503 238.539 81.9416Z"
        fill="#2F2E41"
      />
      <path d="M33.1234 49.5935H5.47778V77.1913H33.1234V49.5935Z" fill="#3F3D56" />
    </svg>
  );
}
