import React, { useRef, useState } from 'react';

import { ComposerTitle } from '../ComposerTitle';

import styled from 'styled-components';

import iconUpload from '../../../../../../images/upload-icon.svg';
import noPicture from '../../../../../../images/no_picture.svg';

import './ComposerEditLink.css';
import { useSelector } from 'react-redux';

import { ComposerEditSlug } from './ComposerEditSlug';
import { handleChangePreview } from './composerEditUtils';

const ComposerEditCampaignContainer = styled.div.withConfig({
  displayName: 'ComposerEditCampaignContainer',
})`
  max-width: 600px;
`;

const TextLoading = () => (
  <div>
    <div className="loading">
      <svg width="100" height="100" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="PixelMe logo">
          <g id="Logo blocks">
            <rect id="rec-container" width="102" height="102" rx="5" fill="white" />
            <rect className="rec" id="rec11" width="15.4545" height="15.4545" x="21.6367" y="21.6367" fill="#2E89E2" />
            <rect className="rec" id="rec12" width="15.4545" height="15.4545" x="43.2734" y="21.6367" fill="#2E89E2" />
            <rect className="rec" id="rec13" width="15.4545" height="15.4545" x="64.9102" y="21.6367" fill="#2E89E2" />
            <rect className="rec" id="rec21" width="15.4545" height="15.4545" x="21.6367" y="43.2734" fill="#2E89E2" />
            <rect className="rec" id="rec22" width="15.4545" height="15.4545" x="43.2734" y="43.2734" fill="#2E89E2" />
            <rect className="rec" id="rec23" width="15.4545" height="15.4545" x="64.9102" y="43.2734" fill="#2E89E2" />
            <rect className="rec" id="rec31" width="15.4545" height="15.4545" x="21.6367" y="64.9102" fill="#2E89E2" />
            <rect className="rec" id="rec32" width="15.4545" height="15.4545" x="43.2734" y="64.9102" fill="#2E89E2" />
            <rect className="rec" id="rec33" width="15.4545" height="15.4545" x="64.9102" y="64.9102" fill="#2E89E2" />
          </g>
        </g>
      </svg>
    </div>
  </div>
);

const ComposerEditLink = (props) => {
  const [isDropDownOpen, setDropDownOpen] = useState(props.isDropDownOpen);
  const inputFile = useRef(null);
  const { redirect } = props;
  const [uploadPicture, setUploadPicture] = useState('');

  if (!redirect.preview) {
    redirect.preview = {};
  }

  const onButtonClickUpload = () => {
    inputFile.current.click();
  };

  const { loading } = useSelector((state) => {
    if (state.front.abComposerModalConfig) {
      return {
        loading: state.front.abComposerModalConfig.loading,
      };
    }
  });

  const editCard = () => (
    <div className="editlink">
      <div className="editlink__container">
        {loading ? (
          <TextLoading />
        ) : (
          <>
            <div className="preview-container" onClick={onButtonClickUpload}>
              {redirect.preview && redirect.preview.picture && !uploadPicture ? (
                <img alt="imagepreview" src={redirect.preview.picture} />
              ) : (
                <img alt="imagepreview" src={uploadPicture ? uploadPicture : noPicture} />
              )}
              <div className="preview-overlay">
                <div className="preview-text">
                  <h2>Upload a new image</h2>
                  <img alt="iconUpload" src={iconUpload} />
                  <form encType="multipart/form-data" action="#" method="post">
                    <input
                      onChange={(e) => {
                        setUploadPicture(URL.createObjectURL(e.target.files[0]));
                        handleChangePreview(e, props, true, false);
                      }}
                      name="file"
                      type="file"
                      id="file"
                      accept=".jpg, .jpeg, .png"
                      ref={inputFile}
                      style={{ display: 'none' }}
                    />
                  </form>
                </div>
              </div>
            </div>
            <div style={{ marginTop: '0.7rem', marginBottom: '0.5rem' }}>
              <span>Title</span>
            </div>
            <input
              type="text"
              name="title"
              value={redirect.preview.title}
              placeholder="Title"
              onChange={(e) => handleChangePreview(e, props, false, props.updateTitle)}
            />
            <div style={{ marginTop: '0.7rem', marginBottom: '0.5rem' }}>
              <span>Description</span>
            </div>
            <textarea
              type="text"
              name="description"
              value={redirect.preview.description}
              onChange={(e) => handleChangePreview(e, props, false, false)}
              placeholder="Description"
            />
            {props.editPreview && (
              <div style={{ maxWidth: '600px' }}>
                <ComposerEditSlug {...props} disabled={true} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );

  if (props.editPreview) {
    return <>{editCard()}</>;
  }

  return (
    <ComposerEditCampaignContainer>
      <div className="composer__line composer__dropdowns" style={{ marginBottom: '0px' }}>
        <ComposerTitle title="Preview" setDropDownOpen={setDropDownOpen} showToggle={true} />
        <div
          className="composer__line__after_title"
          style={{
            alignItems: 'flex-end',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        ></div>
        {isDropDownOpen && <>{editCard()}</>}
      </div>
    </ComposerEditCampaignContainer>
  );
};

export default ComposerEditLink;
