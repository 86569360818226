import React, { Component } from 'react';

import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { withDragDropContext } from 'lib/dnd';
import { getStringsKey } from 'lib/modalConfig';
import { getIntlStrings } from 'locales/i18n/localeHelpers';
import * as string from 'locales/strings';
import { strings } from 'locales/strings';
import { selectCurrentAccount } from 'stores/accounts';
import type { action } from 'stores/audienceBuilder/redirects';
import { loadRedirectsSuccess, toggleRedirect, updateRedirect } from 'stores/audienceBuilder/redirects';
import { updateUser } from 'stores/user';

import { CampaignModal } from './CampaignModal';
import { RedirectsListDatatableContent } from './RedirectsListDatatableContent';
import { RedirectsListNoRedirect } from './RedirectsListNoRedirect';
import { Actions } from '../../../../../actions';
import { LargeModalForRedux } from '../../../../common/ModalForRedux';

const RedirectsListDatatableContainer = styled.div.withConfig({
  displayName: 'RedirectsListDatatableContainer',
})`
  overflow-y: auto;
  margin-left: -16px;
  margin-right: -16px;
  font-size: 12px;
  position: relative;
`;

const CampaignName = styled.span.withConfig({ displayName: 'CampaignName' })`
  color: var(--text-muted);
  margin-bottom: 7px;
  display: inline-block;
`;

type internalPropsT = {
  account: accountT,
  user: userT,
  loading: boolean,
  total: number,
  redirects: Array<redirectT>,
  selected: Array<string>,
  selectedRedirects: Array<redirectT>,
};

type internalActionsT = {
  toggleRedirect: (string, boolean) => action,
  updateRedirect: (redirectT) => action,
  loadRedirectsSuccess: ({ redirects: Array<redirectT> }, { merge: boolean }) => action,
  updateUser: (userT) => void,
};

type internalStateT = { checkAllChecked: boolean };

const initialState: State = {};

class RedirectsListDatatableComponent extends Component<internalPropsT & internalActionsT, internalStateT> {
  state = initialState;

  componentDidMount() {
    this.props.initLoading();
  }

  shouldComponentUpdate(nextProps) {
    if (isEqual(nextProps, this.props)) {
      return false;
    }

    return true;
  }

  render() {
    const {
      redirects,
      selectedCampaignId,
      selectedSubCampaignId,
      editCampaignModalConfig,
      addCampaignModalConfig,
      deleteCampaignModalConfig,
      selectedRedirects,
      toggleAll,
    } = this.props;
    const { checkAllChecked } = this.state;

    console.log(
      '%c render front/src/components/pages/AudienceBuilder/Campaigns/campaigns/RedirectsListDatatable.jsx',
      'color: green',
    );

    return (
      <RedirectsListDatatableContainer>
        <CampaignModal
          mode="add"
          config={addCampaignModalConfig}
          actionSuccess={(payload) => Actions.api.audienceBuilder.campaigns.add.request(payload)}
          actionCancel={(payload) => Actions.front.audienceBuilder.campaigns.add.hide.request(payload)}
        />

        <CampaignModal
          mode="edit"
          config={editCampaignModalConfig}
          actionSuccess={(payload) => Actions.api.audienceBuilder.campaigns.edit.request(payload)}
          actionCancel={(payload) => Actions.front.audienceBuilder.campaigns.edit.hide.request(payload)}
        />

        <LargeModalForRedux
          config={deleteCampaignModalConfig}
          okMessage={getIntlStrings(`${getStringsKey(deleteCampaignModalConfig)}.delete.button`)}
          okStyle={{ backgroundColor: 'var(--bad-color)' }}
          okOnly={true}
          actionSuccess={(_) => Actions.api.audienceBuilder.campaigns.delete.request(deleteCampaignModalConfig)}
          actionCancel={(_) => Actions.front.audienceBuilder.campaigns.delete.hide.request(deleteCampaignModalConfig)}
          title={getIntlStrings(`${getStringsKey(deleteCampaignModalConfig)}.delete.title`)}
        >
          {!!deleteCampaignModalConfig.campaignOrSub && (
            <>
              <span>{`${getIntlStrings(`${getStringsKey(deleteCampaignModalConfig)}.title`)}: `}</span>
              <CampaignName>{deleteCampaignModalConfig.campaignOrSub.name}</CampaignName>
              <div style={{ color: 'var(--text-muted)' }}>
                {getIntlStrings(`${getStringsKey(deleteCampaignModalConfig)}.delete.help`)}
              </div>
            </>
          )}
        </LargeModalForRedux>

        {!!(redirects && redirects.length > 0) && (
          <RedirectsListDatatableContent
            checkAllChecked={checkAllChecked}
            toggleAll={toggleAll}
            redirects={redirects}
            selectedRedirects={selectedRedirects}
          />
        )}
        {!!(!redirects || redirects.length === 0) && (
          <RedirectsListNoRedirect
            selectedCampaignId={selectedCampaignId}
            selectedSubCampaignId={selectedSubCampaignId}
          />
        )}
      </RedirectsListDatatableContainer>
    );
  }
}

const mapStateToProps = function (state): internalPropsT {
  const redirectsState = state.abRedirects;
  const frontState = state.front;
  const campaignsState = state.campaigns;

  return {
    account: selectCurrentAccount(state),
    user: state.user.user,
    redirects: redirectsState.redirects,
    selectedRedirects: campaignsState.selectedRedirects,
    selectedCampaignId: campaignsState.selectedCampaignId,
    selectedSubCampaignId: campaignsState.selectedSubCampaignId,
    loading: redirectsState.loading,
    total: redirectsState.total,
    editCampaignModalConfig: frontState.editCampaignModalConfig,
    addCampaignModalConfig: frontState.addCampaignModalConfig,
    deleteCampaignModalConfig: frontState.deleteCampaignModalConfig,
  };
};

const mapDispatchToProps = function (dispatch: Dispatch<*>): {} {
  return {
    toggleRedirect,
    updateRedirect,
    loadRedirectsSuccess,
    updateUser,
    toggleAll: (redirects) => dispatch(Actions.front.audienceBuilder.campaigns.toggleRedirect.request(redirects)),
    initLoading: () => dispatch(Actions.front.initLoading.request()),
  };
};

export const RedirectsListDatatable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDragDropContext(RedirectsListDatatableComponent));
