import { getIntlStrings } from 'locales/i18n/localeHelpers';
import { strings } from 'locales/strings';

export function redirectColumnsConfig(account, activeAmazonProfile, limitations) {
  let defaultColumns = [
    {
      id: 'Title',
      value: getIntlStrings('redirects.redirect.title'),
      exportable: true,
      visible: true,
      position: 0,
    },
    {
      id: 'Shorten',
      value: getIntlStrings('redirects.redirect.shorten'),
      exportable: true,
      visible: true,
      position: 1,
    },
    {
      id: 'Clicks',
      value: getIntlStrings('redirects.redirect.clicks'),
      exportable: true,
      visible: true,
      position: 2,
    },
  ];

  defaultColumns = defaultColumns.concat([
    {
      id: 'UtmsSource',
      value: getIntlStrings('redirects.redirect.source'),
      exportable: true,
      visible: true,
      position: defaultColumns.length,
    },
    {
      id: 'UtmsMedium',
      value: getIntlStrings('redirects.redirect.medium'),
      exportable: true,
      visible: true,
      position: defaultColumns.length + 1,
    },
    {
      id: 'UtmsCampaign',
      value: getIntlStrings('redirects.redirect.campaign'),
      exportable: true,
      visible: true,
      position: defaultColumns.length + 2,
    },
    {
      id: 'UtmsTerm',
      value: getIntlStrings('redirects.redirect.term'),
      exportable: true,
      visible: true,
      position: defaultColumns.length + 3,
    },
    {
      id: 'UtmsContent',
      value: getIntlStrings('redirects.redirect.content'),
      exportable: true,
      visible: true,
      position: defaultColumns.length + 4,
    },
  ]);

  defaultColumns = defaultColumns.concat([
    {
      id: 'Url',
      value: getIntlStrings('redirects.redirect.url'),
      exportable: true,
      visible: true,
      position: defaultColumns.length,
    },
    {
      id: 'Pixels',
      value: getIntlStrings('redirects.redirect.pixel'),
      exportable: true,
      visible: true,
      position: defaultColumns.length + 1,
    },
    {
      id: 'CookieConsent',
      value: getIntlStrings('redirects.redirect.cookieConsent'),
      exportable: true,
      visible: true,
      position: defaultColumns.length + 2,
    },
  ]);

  defaultColumns = defaultColumns.concat([
    {
      id: 'CreatedAt',
      value: getIntlStrings('redirects.redirect.createdAt'),
      exportable: true,
      visible: true,
      position: defaultColumns.length,
    },
    {
      id: 'Tags',
      value: getIntlStrings('redirects.redirect.tags'),
      exportable: true,
      visible: true,
      position: defaultColumns.length + 1,
    },
  ]);

  return defaultColumns;
}
