export function handleChangeUtm(event: SyntheticEvent<HTMLInputElement>, { onChange, redirect }) {
  const target = (event.currentTarget: HTMLInputElement);
  const value = target.value || '';
  const { name } = target;
  const newRedirect = {
    ...redirect,
    utms: { ...redirect.utms, [name]: value.trim() },
  };

  onChange({ redirect: newRedirect });
}

export function handleChangePreview(event, props, isFile, updateTitle) {
  const { redirect, onChange } = props;
  const target = event.currentTarget;
  const value = target.value || '';
  const { name } = target;

  if (isFile) {
    const file = event.target.files[0];
    const newRedirect = {
      ...redirect,
      preview: {
        ...redirect.preview,
        file,
      },
    };

    onChange({ redirect: newRedirect });
  } else {
    const changes = {};

    if (updateTitle) {
      changes.title = value;
    }

    const newRedirect = {
      ...redirect,
      ...changes,
      preview: { ...redirect.preview, [name]: value },
    };

    onChange({ redirect: newRedirect });
  }
}

export function handleChange(changes, props) {
  const { redirect, onChange } = props;

  let atLeastOneChangeApplied = false;
  let newRedirect = {
    ...redirect,
  };

  for (const change of changes) {
    const { name, value } = change;

    if (!redirect || redirect[name] !== value) {
      atLeastOneChangeApplied = true;
      newRedirect = {
        ...newRedirect,
        [name]: value,
      };
    }
  }

  if (atLeastOneChangeApplied) {
    onChange({ redirect: newRedirect });
  }
}

export function handleChangeUrl(eventTarget, props) {
  const { name, value } = eventTarget;
  const { redirect, onChangeUrl } = props;

  if (!redirect || redirect[name] !== value) {
    const newRedirect = {
      ...redirect,
      [name]: value,
    };

    onChangeUrl({ redirect: newRedirect });
  }
}

export function handleRemove(eventTarget, props) {
  // name is the property name
  // value is the elt to remove from property
  const { name, value } = eventTarget;
  const { redirect, onChange } = props;

  if (!redirect || Array.isArray(redirect[name])) {
    const ind = redirect[name].indexOf(value);

    const newRedirect = {
      ...redirect,
    };

    if (ind !== -1) {
      newRedirect[name] = newRedirect[name].splice(ind);
      onChange({ redirect: newRedirect });
    }
  }
}
