import React from 'react';

import IconContainer from './IconContainer';

export function Megaphone(props) {
  return (
    <IconContainer {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Capa_1"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        width="100%"
        height="100%"
      >
        <g>
          <g>
            <path
              d="M358.4,55.467c-18.825,0-34.133,15.309-34.133,34.133v6.17l-199.441,79.77c-3.234,1.306-5.359,4.437-5.359,7.927v119.467    c0,3.49,2.125,6.622,5.359,7.919l199.441,79.77v6.178c0,18.825,15.309,34.133,34.133,34.133s34.133-15.309,34.133-34.133V89.6    C392.533,70.775,377.225,55.467,358.4,55.467z M375.467,396.8c0,9.412-7.654,17.067-17.067,17.067s-17.067-7.654-17.067-17.067    v-11.947c0-3.499-2.125-6.63-5.367-7.927l-199.433-79.77V189.244l199.433-79.77c3.243-1.306,5.367-4.437,5.367-7.927V89.6    c0-9.412,7.654-17.067,17.067-17.067s17.067,7.654,17.067,17.067V396.8z"
              fill="#2e89e2"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M128,174.933H68.267C30.626,174.933,0,205.559,0,243.2s30.626,68.267,68.267,68.267H128c4.71,0,8.533-3.814,8.533-8.533    V183.467C136.533,178.756,132.71,174.933,128,174.933z M119.467,294.4h-51.2c-28.237,0-51.2-22.963-51.2-51.2    S40.03,192,68.267,192h51.2V294.4z"
              fill="#2e89e2"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M437.897,209.502l-51.2-17.067c-2.611-0.862-5.453-0.427-7.689,1.178c-2.227,1.604-3.541,4.181-3.541,6.921v85.333    c0,2.739,1.314,5.316,3.541,6.929c1.476,1.058,3.226,1.604,4.992,1.604c0.905,0,1.818-0.145,2.697-0.444l51.2-17.067    c3.49-1.152,5.837-4.412,5.837-8.09v-51.2C443.733,213.922,441.387,210.671,437.897,209.502z M426.667,262.647l-34.133,11.375    v-61.653l34.133,11.375V262.647z"
              fill="#2e89e2"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M169.873,409.54l-8.567-8.576l-25.028-100.096c-0.947-3.797-4.361-6.468-8.277-6.468H59.733    c-2.611,0-5.069,1.195-6.69,3.226c-1.621,2.048-2.219,4.719-1.621,7.262l34.133,145.067c0.93,3.917,4.42,6.579,8.303,6.579    c0.538,0,1.075-0.051,1.621-0.162l64.085-12.373c0.085-0.017,0.171-0.034,0.256-0.051c3.755-0.845,7.185-2.722,9.916-5.41    c3.891-3.849,6.05-8.969,6.084-14.447C175.855,418.62,173.756,413.466,169.873,409.54z M157.747,426.385    c-0.435,0.435-0.981,0.734-1.579,0.887l-55.868,10.795l-29.79-126.601h50.825l23.996,95.932c0.375,1.502,1.152,2.876,2.244,3.968    l10.206,10.206c0.862,0.862,0.981,1.877,0.981,2.415C158.754,424.508,158.618,425.523,157.747,426.385z"
              fill="#2e89e2"
            />
          </g>
        </g>
        <g>
          <g>
            <rect x="460.8" y="234.667" width="51.2" height="17.067" fill="#2e89e2" />
          </g>
        </g>
        <g>
          <g>
            <rect
              x="457.249"
              y="145.084"
              transform="matrix(0.8574 -0.5146 0.5146 0.8574 -10.3129 270.0117)"
              width="49.757"
              height="17.066"
              fill="#2e89e2"
            />
          </g>
        </g>
        <g>
          <g>
            <rect
              x="473.655"
              y="307.917"
              transform="matrix(0.5145 -0.8575 0.8575 0.5145 -51.2652 575.049)"
              width="17.067"
              height="49.758"
              fill="#2e89e2"
            />
          </g>
        </g>
        <g>
          <g>
            <rect x="324.267" y="98.133" width="17.067" height="290.133" fill="#2e89e2" />
          </g>
        </g>
        <g>
          <g>
            <rect x="85.333" y="209.067" width="17.067" height="34.133" fill="#2e89e2" />
          </g>
        </g>
        <g>
          <g>
            <rect x="51.2" y="209.067" width="17.067" height="34.133" fill="#2e89e2" />
          </g>
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    </IconContainer>
  );
}
