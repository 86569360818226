import React from 'react';

import styled from 'styled-components';

import hero8 from '../../images/hero8.jpg';

export function ImageOnRightSide({ children, image, header, theme }) {
  const Style = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    /* adsprovider page */
    .ant-card-head {
      border-bottom: none;
      color: var(--text-color);
      font-size: 16px;
      font-weight: 600;
    }
    .ant-card-body {
      padding-top: 0;
    }
    .left-container {
      display: flex;
      flex: 1 1 300px;
      background-color: white;
      padding: 4em 2em;
      height: 100%;
      min-width: fit-content;
      align-items: center;
    }
    .right-container {
      flex: 1 1 0;
      padding: 4em 2em;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: #f3f5f7;
    }
    .right-container.gtm,
    .right-container.sign-up-c6 {
      background: url('${hero8}') no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    .left-container.half,
    .right-container.half {
      flex: 1;
    }
    .header {
      position: absolute;
      padding: 20px;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    #captcha {
      background: #f3f5f7 !important;
    }

    @media (max-width: 1099px) {
      .right-container {
        display: none;
      }
    }
  `;

  return (
    <>
      <Style>
        <div className={`header ${theme}`}>{header}</div>
        <div className={`left-container ${theme}`}>
          <div style={{ height: '100%', width: '100%', overflowY: 'auto', display: 'flex' }}>{children}</div>
        </div>
        <div className={`right-container ${theme}`}>{image}</div>
      </Style>
    </>
  );
}
