import { Form, Input } from 'antd';
import styled from 'styled-components';

import { emailRegex } from 'lib/util';
import { useLocale } from 'locales/LocalizationWrapper';

import { GridCell } from './constants';

const Style = styled.div`
  display: grid;
  gap: 1.5rem;
  align-items: flex-end;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'firstName lastName'
    'email phoneNumber'
    'notes notes';

  .ant-form-item {
    margin-bottom: 0.25rem;
  }
  .ant-form-item-explain {
    margin-top: 0.25rem;
    white-space: nowrap;
  }
`;

const GridCellPhone = styled(GridCell)`
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  > div:nth-child(1),
  > div:nth-child(2) {
    max-width: 105px;
  }
  > div:nth-child(3) {
    flex: 1;
  }
`;

export const ContactInformation = ({ formInstance }) => {
  const { getIntlStrings } = useLocale();

  return (
    <Style>
      <GridCell $name="firstName">
        <Form.Item
          name={['contactInformation', 'firstName']}
          label={getIntlStrings('asinAudit.contactInformation.name')}
          help={getIntlStrings('asinAudit.contactInformation.firstName')}
          colon={false}
        >
          <Input size="large" />
        </Form.Item>
      </GridCell>

      <GridCell $name="lastName">
        <Form.Item
          name={['contactInformation', 'lastName']}
          help={getIntlStrings('asinAudit.contactInformation.lastName')}
          colon={false}
        >
          <Input size="large" />
        </Form.Item>
      </GridCell>

      <GridCell $name="email">
        <Form.Item
          name={['contactInformation', 'email']}
          label={getIntlStrings('asinAudit.contactInformation.email')}
          colon={false}
          help={
            formInstance.getFieldError(['contactInformation', 'email'])[0] ??
            getIntlStrings('asinAudit.contactInformation.emailExample')
          }
          rules={[
            {
              required: false,
              pattern: emailRegex,
              message: getIntlStrings('asinAudit.contactInformation.emailError'),
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input size="large" type="email" />
        </Form.Item>
      </GridCell>

      <GridCellPhone $name="phoneNumber">
        <div>
          <Form.Item
            name={['contactInformation', 'phone', 'countryCode']}
            label={getIntlStrings('asinAudit.contactInformation.phone')}
            help={getIntlStrings('asinAudit.contactInformation.countryCode')}
            colon={false}
          >
            <Input type="tel" size="large" />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name={['contactInformation', 'phone', 'areaCode']}
            help={getIntlStrings('asinAudit.contactInformation.areaCode')}
            colon={false}
          >
            <Input type="tel" size="large" />
          </Form.Item>
        </div>

        <div>
          <Form.Item
            name={['contactInformation', 'phone', 'number']}
            help={getIntlStrings('asinAudit.contactInformation.phone')}
            colon={false}
          >
            <Input type="tel" size="large" />
          </Form.Item>
        </div>
      </GridCellPhone>

      <GridCell $name="notes">
        <Form.Item
          name={['contactInformation', 'notes']}
          label={getIntlStrings('asinAudit.contactInformation.notes')}
          help={getIntlStrings('asinAudit.contactInformation.notePlaceholder')}
          colon={false}
        >
          <Input.TextArea rows={4} maxLength={1000} showCount />
        </Form.Item>
      </GridCell>
    </Style>
  );
};
