import React, { useEffect } from 'react';

import { navigate } from 'hookrouter';
import _ from 'lodash';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

import axios from 'lib/axios.factory';

// @ts-ignore
import { EasySteps } from './components/EasySteps';
import { EasyContainer } from './EsayContainer';
import { LinkPreviewForm } from './LinkPreviewForm';
import { hasExpired } from './utils/links.utils';
import { getRedirectsByIds } from '../../../lib/api';

const Style = styled.div``;

export function LinkPreview({ account, redirectId }: { account: accountT, redirectId: string }) {
  const [state, updateState] = useImmer({ redirect: { url: '', preview: {} } });

  useEffect(() => {
    async function loadRedirect(redirectId) {
      const { redirects } = await getRedirectsByIds(localStorage, axios, {
        accountId: account.id,
        redirectsIds: [redirectId],
      });

      if (hasExpired(_.get(redirects, '0'))) {
        navigate(`/easy/link-expired/${redirects[0].id}`);
      }

      updateState((draft) => {
        draft.redirect = redirects[0];
      });
    }

    if (redirectId) {
      loadRedirect(redirectId).then();
    }
  }, [redirectId]);

  return (
    <EasyContainer
      title="Create the perfect link previews for social media"
      description={
        <>
          PixelMe is a <b>URL shortener</b> that embed retargeting pixels from Facebook, Google, Linkedin and more into
          shortened links. <br />
          Brand, track & share your own <b>branded short URLs</b> and retarget anyone who clicks on your links!
        </>
      }
    >
      <Style>
        <EasySteps current={2} />
        <LinkPreviewForm account={account} updateState={updateState} state={state} />
      </Style>
    </EasyContainer>
  );
}
