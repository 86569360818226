import React from 'react';

import IconContainer from './IconContainer';

export function Analytics(props) {
  const containerProps = { ...props };

  delete containerProps.color;

  return (
    <IconContainer {...containerProps}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{ enableBackground: 'new 0 0 512 512' }}
        xmlSpace="preserve"
        width="100%"
        height="100%"
      >
        <g>
          <g>
            <path
              d="M501.333,490.667H10.667C4.779,490.667,0,495.445,0,501.333C0,507.221,4.779,512,10.667,512h490.667    c5.888,0,10.667-4.779,10.667-10.667C512,495.445,507.221,490.667,501.333,490.667z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M96,362.667H32c-5.888,0-10.667,4.779-10.667,10.667v128C21.333,507.221,26.112,512,32,512h64    c5.888,0,10.667-4.779,10.667-10.667v-128C106.667,367.445,101.888,362.667,96,362.667z M85.333,490.667H42.667V384h42.667    V490.667z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M224,256h-64c-5.888,0-10.667,4.779-10.667,10.667v234.667c0,5.888,4.779,10.667,10.667,10.667h64    c5.888,0,10.667-4.779,10.667-10.667V266.667C234.667,260.779,229.888,256,224,256z M213.333,490.667h-42.667V277.333h42.667    V490.667z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M352,298.667h-64c-5.888,0-10.667,4.779-10.667,10.667v192c0,5.888,4.779,10.667,10.667,10.667h64    c5.888,0,10.667-4.779,10.667-10.667v-192C362.667,303.445,357.888,298.667,352,298.667z M341.333,490.667h-42.667V320h42.667    V490.667z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M480,170.667h-64c-5.888,0-10.667,4.779-10.667,10.667v320c0,5.888,4.779,10.667,10.667,10.667h64    c5.888,0,10.667-4.779,10.667-10.667v-320C490.667,175.445,485.888,170.667,480,170.667z M469.333,490.667h-42.667V192h42.667    V490.667z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M64,192c-23.531,0-42.667,19.136-42.667,42.667c0,23.531,19.136,42.667,42.667,42.667    c23.531,0,42.667-19.136,42.667-42.667C106.667,211.136,87.531,192,64,192z M64,256c-11.776,0-21.333-9.579-21.333-21.333    c0-11.755,9.557-21.333,21.333-21.333s21.333,9.579,21.333,21.333C85.333,246.421,75.776,256,64,256z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M192,85.333c-23.531,0-42.667,19.136-42.667,42.667c0,23.531,19.136,42.667,42.667,42.667s42.667-19.136,42.667-42.667    C234.667,104.469,215.531,85.333,192,85.333z M192,149.333c-11.776,0-21.333-9.579-21.333-21.333    c0-11.755,9.557-21.333,21.333-21.333s21.333,9.579,21.333,21.333C213.333,139.755,203.776,149.333,192,149.333z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M320,128c-23.531,0-42.667,19.136-42.667,42.667c0,23.531,19.136,42.667,42.667,42.667    c23.531,0,42.667-19.136,42.667-42.667C362.667,147.136,343.531,128,320,128z M320,192c-11.776,0-21.333-9.579-21.333-21.333    c0-11.755,9.557-21.333,21.333-21.333s21.333,9.579,21.333,21.333C341.333,182.421,331.776,192,320,192z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M448,0c-23.531,0-42.667,19.136-42.667,42.667c0,23.531,19.136,42.667,42.667,42.667    c23.531,0,42.667-19.136,42.667-42.667C490.667,19.136,471.531,0,448,0z M448,64c-11.776,0-21.333-9.579-21.333-21.333    c0-11.755,9.557-21.333,21.333-21.333s21.333,9.579,21.333,21.333C469.333,54.421,459.776,64,448,64z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M432.939,57.728c-4.16-4.16-10.923-4.16-15.083,0l-82.773,82.773c-4.16,4.16-4.16,10.923,0,15.083    c2.091,2.069,4.821,3.115,7.552,3.115c2.709,0,5.44-1.024,7.531-3.115l82.773-82.773    C437.099,68.651,437.099,61.888,432.939,57.728z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M294.208,146.048l-68.523-19.541c-5.739-1.664-11.563,1.664-13.163,7.339c-1.621,5.675,1.664,11.563,7.317,13.184    l68.523,19.541c0.981,0.277,1.963,0.405,2.923,0.405c4.651,0,8.917-3.051,10.261-7.744    C303.168,153.579,299.883,147.669,294.208,146.048z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M175.36,141.291c-3.669-4.608-10.368-5.355-14.976-1.707l-80.427,64.128c-4.608,3.691-5.376,10.389-1.685,14.997    c2.112,2.645,5.227,4.011,8.341,4.011c2.325,0,4.672-0.768,6.635-2.304l80.427-64.128    C178.283,152.597,179.051,145.899,175.36,141.291z"
              fill={props.color || '#2e89e2'}
            />
          </g>
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    </IconContainer>
  );
}
