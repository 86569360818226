import './Graph.css';

import React from 'react';

import numeral from 'numeral';
// $FlowFixMe
import { Line } from 'react-chartjs-2';

import type { queryT } from 'stores/analytics';

const lineColor = '#071629';
const graphFillColor = '#f5f9fe';
const options = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      title(items) {
        if (items[0].value >= 150) {
          return 'Position >#150';
        }

        return `Position #${items[0].value}`;
      },
      label(tooltipItem, chart) {
        return '';
      },
    },
  },
  elements: {
    point: {
      radius: 2,
      pointStyle: 'circle',
      borderWidth: 2,
      backgroundColor: lineColor,
      borderColor: lineColor,
    },
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },

        type: 'time',
        distribution: 'series',
        time: {
          unit: 'day',
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          borderDash: [2, 2],
        },

        ticks: {
          reverse: true,
          min: 1,
          max: 150,
          callback(value) {
            return `#${value}`;
          },
        },
      },
    ],
  },
};

function formatValueRank(value) {
  if (value == 'Over page 5' || parseInt(value) > 150) {
    return 150;
  } else if (value == 'Pending data refresh') {
    return;
  }

  return value;
}

export default function Graph({
  total,
  rankings,
  query,
  rankingMode,
}: {
  total: number,
  rankings: Array<{ date: number, value: number, rank: number }>,
  query: queryT,
  rankingMode: string,
}) {
  const rankSorted = rankings.sort((a, b) => new Date(b.date) - new Date(a.date));

  const fullData = {
    datasets: [
      {
        data: rankSorted.map((rank) => ({
          x: rank.date,
          y: formatValueRank(rank.rank),
        })),
        backgroundColor: '#ffff',
        pointBackgroundColor: '#007EE6',
        pointBorderColor: '#007EE6',
        borderColor: '#BDDCF9',
      },
    ],
  };

  return (
    <div className="analytics-graph">
      <div className="analytics-graph__title">
        {rankingMode === 'ORGANIC' && <div>Organic search ranking</div>}
        {rankingMode === 'SPONSORED' && <div>Sponsored search ranking</div>}
      </div>
      <div className="analytics-graph__content">
        {/* <Line height={50} options={options} data={fullData} /> */}
        <Line height={200} options={options} data={fullData} />
      </div>
    </div>
  );
}
