import type { axiosParams } from './api';
import { apiCall } from './api';

export function getKeywordSuggestions(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  productProviderID: string,
  productASIN: string,
  params: { accountId: string, ads_provider_id: string, dailyBudget: number, languages: number[], locations: number[] },
): Promise<KeywordSuggestionsResponse> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `/product-providers/${productProviderID}/products/${productASIN}/keyword-suggestions`,
    params: {
      accountId: params.accountId,
      adsProviderId: params.adsProviderId,
      dailyBudget: params.dailyBudget,
      languages: params.languages.join(','),
      locations: params.locations.join(','),
    },
  });
}

export function getAdSuggestions(
  localStorage: any,
  axios: (axiosParams) => Promise<{ data: {} }>,
  productProviderID: string,
  productASIN: string,
  params: { accountId: string, ads_provider_id: string, keywords: string[] },
): Promise<AdSuggestionsResponse> {
  return apiCall({
    localStorage,
    axios,
    secure: true,
    method: 'get',
    url: `/product-providers/${productProviderID}/products/${productASIN}/ad-suggestions`,
    params: {
      accountId: params.accountId,
      adsProviderId: params.adsProviderId,
      keywords: params.keywords.join(','),
    },
  });
}
