import React from 'react';

export function Info(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g fill="#4689DB" clipPath="url(#clip0_2998_51877)">
        <path d="M7 .875a6.125 6.125 0 100 12.25A6.125 6.125 0 007 .876zm0 11.21a5.087 5.087 0 11.002-10.173A5.087 5.087 0 017 12.086z"></path>
        <path d="M6.344 4.594a.656.656 0 101.312 0 .656.656 0 00-1.312 0zm.984 1.531h-.656a.11.11 0 00-.11.11v3.718c0 .06.05.11.11.11h.656c.06 0 .11-.05.11-.11V6.234a.11.11 0 00-.11-.109z"></path>
      </g>
      <defs>
        <clipPath id="clip0_2998_51877">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
