import { type Node } from 'react';

import { Statistic, Table, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { strings } from 'locales/strings';

import { numberSorter, stringSorter } from './constants';

const Range = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.25rem;
  flex-wrap: wrap;
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: var(--text-muted);
  }
`;

type propsT = {
  keywords: Object[],
  limit?: number,
};
export const KeywordTable = ({ keywords = [] }: propsT): Node => {
  const { messages } = useIntl();

  const columns = [
    {
      title: messages.asinAudit.keywords.title,
      dataIndex: 'keyword',
      fixed: 'left',
      width: 220,
      ellipsis: true,
      render: (val) => (
        <Tooltip title={val} placement="topLeft">
          <span className="ellipsis">{val}</span>
        </Tooltip>
      ),
      sorter: stringSorter('keyword'),
    },
    {
      title: messages.asinAudit.keywords.amazonSearchVolume,
      dataIndex: 'amazonSearchVolume',
      width: 150,
      align: 'center',
      render: (val) => (val == null ? 'N/A' : <Statistic value={val} />),
      sorter: numberSorter('amazonSearchVolume'),
    },
    {
      title: messages.asinAudit.keywords.amazonCurrentRank,
      dataIndex: 'amazonCurrentRank',
      width: 140,
      align: 'center',
      render: (val) => (val == null ? 'N/A' : <Statistic value={val} />),
      sorter: numberSorter('amazonCurrentRank'),
    },
    {
      title: messages.asinAudit.keywords.amazonCompetitorRank,
      dataIndex: 'amazonCompetitorRank',
      width: 160,
      align: 'center',
      render: (val) => (val == null ? 'N/A' : <Statistic value={val} />),
      sorter: numberSorter('amazonCompetitorRank'),
    },
    {
      title: messages.asinAudit.keywords.googleSearchVolume,
      dataIndex: 'googleSearchVolume',
      width: 140,
      align: 'center',
      render: (val) => (val == null ? 'N/A' : <Statistic value={val} />),
      sorter: numberSorter('googleSearchVolume'),
    },
    {
      title: messages.asinAudit.keywords.googleEstCostPerClick,
      key: 'googleEstCostPerClick',
      width: 170,
      align: 'center',
      render: (val, record) =>
        record.googleEstCostPerClickLow == null || record.googleEstCostPerClickHigh == null ? (
          'N/A'
        ) : (
          <Range>
            <Statistic value={record.googleEstCostPerClickLow / 100} precision={2} prefix="$" />
            -
            <Statistic value={record.googleEstCostPerClickHigh / 100} precision={2} prefix="$" />
          </Range>
        ),
      sorter: numberSorter('googleEstCostPerClickLow'),
    },
  ];

  return (
    <StyledTable
      dataSource={keywords}
      columns={columns}
      bordered
      pagination={false}
      rowKey="keyword"
      scroll={{ x: 'min-content' }}
      tableLayout="fixed"
    />
  );
};
